import { demandServiceType, demandServiceContentType } from '@/config/options.sales'
import { nameChangeForBB } from '@/utils'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status',
    width: 140
  },
  {
    prop: 'code',
    label: '需求编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    width: 140
  },
  {
    prop: 'content',
    label: '服务内容',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    slotName: 'content'
  },
  {
    prop: 'serviceType',
    label: '服务类型',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    slotName: 'serviceType'
  },
  {
    prop: 'customerName',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'principalNames',
    label: '当前负责人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planStartTime',
    label: '计划执行时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'planStartTime'
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'createTime'
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'dispatchHours',
    label: '派工TAT',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'executeHours',
    label: '执行TAT',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'endWorkHours',
    label: '完工TAT',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'lastApproveHours',
    label: '结案TAT',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'endHours',
    label: '总TAT',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'salesOrderNumber',
    label: '销售订单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'facilityStr',
    label: 'SN',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 210
  }
]
export const addColumns = [
  {
    prop: 'code',
    label: '需求编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    form: { index: 1, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'content',
    label: '服务内容',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: {
      index: 2, tagName: 'el-select', colSpan: 8, options: demandServiceType, on: {
        change: (val) => {
          functionList.contentChange(val)
        }
      }
    }
  },
  {
    prop: 'serviceType',
    label: '服务类型',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 2, tagName: 'el-select', colSpan: 8, options: demandServiceContentType }
  },
  {
    prop: 'customerSiteIdList',
    label: nameChangeForBB() ? '客户/现场/其他' : '客户/现场',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      tagName: 'el-cascader',
      key: 'customerSiteIdList',
      colSpan: 8,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => {
          functionList.mulChange(val)
        }
      }
    }
  },
  // {
  //   prop: 'customerId',
  //   label: '客户',
  //   sortable: false,
  //   hiddenSearch: true,
  //   width: 180,
  //   form: { index: 3, tagName: 'el-select', colSpan: 8, options: [], on: {
  //     change: (val) => {
  //       functionList.serviceChange(val)
  //     }
  //   }}
  // },
  {
    prop: 'linkMan',
    label: '客户联系人',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 4, tagName: 'el-input', colSpan: 8, required: false }
  },
  {
    prop: 'linkPhone',
    label: '联系方式',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 5, tagName: 'el-input', colSpan: 8, required: false }
  },
  {
    prop: 'salesOrderNumber',
    label: nameChangeForBB() ? '出厂编号' : '销售订单号',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: {
      index: 6, tagName: 'el-input', colSpan: 8, required: true, rules: [
        {
          pattern: nameChangeForBB() ? /^BDA0\d{7}$/ : /^XSDD\d{6}$/,
          message: nameChangeForBB() ? '格式错误，应以BDA0开头，后7位数字' : '格式错误，应以XSDD开头，后6位数字',
          trigger: 'blur'
        }
      ]
    }
  },
  {
    prop: 'serviceAddress',
    label: '现场服务地址',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 7,
      tagName: 'div',
      colSpan: 8,
      required: false,
      customSlots: ['serviceAddress']
    }
  },
  {
    prop: 'detailAddress',
    label: '详细地址',
    sortable: false,
    hiddenSearch: true,
    form: { index: 8, tagName: 'el-input', colSpan: 8, required: false }
  },
  {
    prop: 'customerFeedback',
    label: '客户反馈信息',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: {
      index: 9,
      tagName: 'el-input',
      colSpan: 8,
      required: false,
      props: { type: 'textarea', autosize: { minRows: 3 } }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: {
      index: 10,
      tagName: 'el-input',
      colSpan: 8,
      required: false,
      props: { type: 'textarea', autosize: { minRows: 3 } }
    }
  }
]

export const otherAdd = [
  {
    prop: 'happenTime',
    label: '发生时间',
    hiddenSearch: true,
    sortable: false,
    width: 180,
    form: {
      index: 13,
      key: 'happenTime',
      tagName: 'el-date-picker',
      colSpan: 8,
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  }
]

export const userColumns = [
  {
    prop: 'principalId',
    label: '负责人',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      tagName: 'el-select',
      colSpan: 24,
      options: [],
      props: {
        multiple: true
      },
      innerValue: (val) => {
        return `${val['jobNumber']} - ${val['name']}`
      }
    }
  },
  {
    prop: 'planDate',
    label: '计划执行时间',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: {
      index: 2,
      tagName: 'el-date-picker',
      colSpan: 24,
      props: {
        rangeSeparator: '至',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        align: 'center',
        type: 'daterange',
        valueFormat: 'yyyy-MM-dd',
        format: 'yyyy-MM-dd'
        // pickerOptions: {
        //   disabledDate(time) {
        //     return time.getTime() < Date.now() - 8.64e7
        //   }
        // }
      }
    }
  },
  {
    prop: 'remark',
    label: '派工说明',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: {
      index: 3,
      tagName: 'el-input',
      colSpan: 24,
      required: false,
      props: { type: 'textarea', autosize: { minRows: 3 } }
    }
  },
  {
    prop: 'isOnBusiness',
    label: '是否需要填报出差计划',
    sortable: false,
    hiddenSearch: true,
    width: 120,
    form: {
      index: 4,
      required: false,
      colSpan: 24,
      tagName: 'el-switch',
      defaultValue: 0,
      props: {
        activeText: '是',
        inactiveText: '否',
        activeValue: 1,
        inactiveValue: 0
      }
    }
  }
]
export const usersColumns = [
  {
    prop: 'technicalHeadId',
    label: '技术部负责人',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1, tagName: 'el-select', colSpan: 8, options: [], innerValue: (val) => {
        return `${val['jobNumber']} - ${val['name']}`
      }
    }
  },
  {
    prop: 'saleHeadId',
    label: '销售部负责人',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 2, tagName: 'el-select', colSpan: 8, options: [], innerValue: (val) => {
        return `${val['jobNumber']} - ${val['name']}`
      }
    }
  },
  {
    prop: 'rdHeadId',
    label: '研发部负责人',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3, tagName: 'el-select', colSpan: 8, options: [], innerValue: (val) => {
        return `${val['jobNumber']} - ${val['name']}`
      }
    }
  },
  {
    prop: 'executorIds',
    label: '执行工程师',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4, tagName: 'el-select', colSpan: 24, options: [], props: { multiple: true }, innerValue: (val) => {
        return `${val['jobNumber']} - ${val['name']}`
      }
    }
  }
]
export const approvalColumns = [
  {
    prop: 'updateRemark',
    label: '审批意见',
    sortable: true,
    hiddenSearch: true,
    form: {
      required: false,
      tagName: 'el-input',
      colSpan: 24,
      props: {
        type: 'textarea'
      },
      attrs: { placeholder: '不超过500字', maxlength: 500 }
    }
  }
]
export const resetColumns = [
  {
    prop: 'updateRemark',
    label: '退回说明',
    sortable: true,
    hiddenSearch: true,
    form: {
      required: false,
      tagName: 'el-input',
      colSpan: 24,
      props: {
        type: 'textarea'
      },
      attrs: { placeholder: '不超过500字', maxlength: 500 }
    }
  }
]
