<template>
  <div class="procedure-detail-wrappers">
    <!--    <div @click="showlog">123123131</div>-->
    <div class="procedure-detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
    </div>
    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <MFormBuilder ref="baseForm" :form-list="formList" :form-data="baseFormData" label-position="right">
            <div slot="serviceAddress">
              <el-cascader
                size="large"
                style="width:100%"
                placeholder="请选择省/市/区"
                :options="pcaTextArr"
                v-model="serviceAddress"
                @change="handleChange">
              </el-cascader>
            </div>
          </MFormBuilder>
        </div>
      </div>
      <div v-if="isReform" class="detail-card">
        <div class="card-tit">项目成员</div>
        <div class="card-bot">
          <MFormBuilder ref="userForm" :form-list="usersFormList" :form-data="userFormData" label-position="right"/>
        </div>
      </div>
      <div v-if="![4, 5].includes(baseFormData.content)" class="detail-card">
        <div class="card-tit">关联设备</div>
        <div class="card-bot">
          <relationEquipment :customer-id="customerId" :list="facilityMappingList" @facilityInfo="facilityInfo"/>
        </div>
      </div>
      <div v-if="![4, 5].includes(baseFormData.content)" class="detail-card">
        <div class="card-tit">关联工具</div>
        <div class="card-bot">
          <relationTool :toolList="detailToolList" ref="trlationTool"/>
        </div>
      </div>
    </div>
    <div class="order-footer">
      <div class="footer-le"/>
      <div v-if="isReform" class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitReForm">确定</el-button>
      </div>
      <div v-else class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">保存</el-button>
        <el-button v-if="params.type === 'add'" type="primary" size="small" @click="submitForm(1)">保存并派工
        </el-button>
      </div>
    </div>
    <DispatchUser :visible.sync="showDispatch" :dispatch-type="'add'" :info="infos" @refrash="refrashList"/>
  </div>
</template>

<script>
import { demandServiceType, demandServiceTypes } from '@/config/options.sales'
import { getFunctionList } from './columns'
import { formList, usersFormList, otherAddList } from './form-list'
import { Encrypt } from '@/utils/sercet'
import { getUUid, setTreeData } from '@/utils'
import api from '@/api/xiewei/service/demand-center'
import fieldApi from '@/api/xiewei/field/field'
import uApi from '@/api/sets/user/user'
import keApi from '@/api/information/unit'
import DispatchUser from '@/views/service/demand-center/components/dispatch-user'
import relationEquipment from '@/views/service/demand-center/components/relation-equipment'
import relationTool from '@/views/service/demand-center/components/relation-tool'
import { pcaTextArr } from 'element-china-area-data'

export default {
  name: 'AddDemandCenter',
  components: { DispatchUser, relationEquipment, relationTool },
  async created() {
    if (['bbelc'].includes(sessionStorage.getItem('memberCode'))) {
      const had = this.formList.filter(p => p.key === 'happenTime')
      if (!had.length) {
        this.formList.push(otherAddList[0])
      }
    }
    // await handleColumns(this.columnsSetName,formList).then(res => this.formList = [...res])
    // await handleColumns(this.columnsSetName,usersFormList).then(res => this.usersFormList = [...res] )
  },
  data() {
    return {
      columnsSetName: '需求中心',
      demandServiceType,
      demandServiceTypes,
      baseFormData: {
        selecteFacility: ''
      },
      formList,
      usersFormList,
      userFormData: {},
      params: {},
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        viewO: '需求详情',
        addO: '新增需求',
        modifyO: '修改需求'
      },
      buttonLoading: false,
      userData: {},
      selectList: [],
      showDispatch: false,
      userList: [],
      infos: {},
      detailToolList: [],
      facilityMappingList: [],
      customerId: undefined,
      usersList: [],
      isReform: false,
      allCustomer: [],
      pcaTextArr,
      serviceAddress: []
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  beforeMount() {
    getFunctionList({
      mulChange: this.mulChange,
      contentChange: this.contentChange
    })
  },
  mounted() {
    this.params = this.$route.query
    this.getSystemUser()
    this.getAllCustomer()
    if (this.params.type !== 'add') {
      this.getFacilityDemandDetail()
    }
    if (this.params.type !== 'view') {
      this.createFieldCode()
    }
    this.formList[1].props.disabled = this.params.type === 'modify'
    if (this.params.type === 'add') {
      this.formList[1].children[0].options = this.demandServiceType
    }
  },
  methods: {
    showlog() {
      consoleSelf.info('1', this.formList)
    },
    handleChange(value) {
      this.serviceAddress = value
    },
    async createFieldCode() {
      const res = await fieldApi.getFieldIsExist({
        numberVersion: '服务需求'
      })
      if (res && +res === 0) {
        if (this.params.type === 'add') {
          this.$refs.baseForm.setForm({
            code: '系统生成'
          })
        }
        this.formList[0].props.disabled = true
      }
    },
    mulChange(val) {
      const arr = this.allCustomer.filter(item => item.id === this._.first(val))
      const list = this.allCustomer.filter(item => item.id === this._.last(val))
      const id = +list[0].isCustomer === 0 ? list[0].id : arr[0].id
      this.$refs.baseForm.setForm({
        customerId: id,
        customerSiteId: +list[0].isCustomer === 1 ? list[0].id : '',
        customerName: list[0].name
      })
      this.customerId = id
      this.facilityMappingList = []
    },
    facilityInfo(val) {
      this.facilityMappingList = val
    },
    contentChange(val) {
      this.isReform = +val === 3
    },
    // 获取系统用户
    async getSystemUser() {
      const res = await uApi.getUserList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            },
            {
              id: getUUid(),
              fieldName: 'isSystemUser',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.usersList = res
        this.usersFormList[0].children[0].options = res
        this.usersFormList[1].children[0].options = res
        this.usersFormList[2].children[0].options = res
        this.usersFormList[3].children[0].options = res
      }
    },
    // 获取客户
    // async getUser() {
    //   const res = await keApi.getCustomerList({
    //     searchVal: Encrypt(
    //       JSON.stringify([
    //         {
    //           id: getUUid(),
    //           fieldName: 'isEnabled',
    //           fieldType: 'number',
    //           fieldValue: 1,
    //           operator: 'eq'
    //         }
    //       ])
    //     )
    //   })
    //   if (res) {
    //     this.userList = res
    //     this.formList[3].children[0].options = res
    //   }
    // },
    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.formList[3].props.options = setTreeData(data)
      }
    },
    selecteFacilitys(val) {
      this.selecteFacility = val
    },
    returns() {
      this.$router.back()
    },
    async getFacilityDemandDetail() {
      const res = await api.getFacilityDemandDetail(this.$route.query.id)
      if (res) {
        this.isReform = +res.content === 3
        this.detailToolList = res.toolMappingList || []
        consoleSelf.info('工具信息', this.detailToolList)
        this.baseFormData = res
        this.baseFormData.customerSiteIdList = this.baseFormData.customerSiteIdList ? this.baseFormData.customerSiteIdList.split('^').map(item => Number(item)) : []
        this.formList[1].children[0].options = res.content === 4 || res.content === 5 ? this.demandServiceTypes : this.demandServiceType
        this.userFormData = {
          technicalHeadId: res.technicalHeadId,
          technicalHeadName: res.technicalHeadName,
          saleHeadId: res.saleHeadId,
          saleHeadName: res.saleHeadName,
          rdHeadId: res.rdHeadId,
          rdHeadName: res.rdHeadName,
          executorIds: res.executorIds ? res.executorIds.split('^').map(item => Number(item)).filter(item => item) : undefined,
          executorNames: res.executorNames
        }
        this.customerId = res.customerId
        this.serviceAddress = res.serviceAddress.split('/')
        this.facilityMappingList = res.facilityMappingList && res.facilityMappingList.length > 0
          ? res.facilityMappingList.map(item => {
            return {
              ...item,
              code: item.facilityCode,
              name: item.facilityName
            }
          }) : []
      }
    },
    submitForm(val) {
      const that = this
      const { form } = this.$refs.baseForm
      if (val === 1) {
        this.showDispatch = true
        const facilityMappingList = this.facilityMappingList.map(item => {
          return {
            facilityId: item.facilityId,
            facilityCode: item.code,
            facilityName: item.name,
            sn: item.sn,
            model: item.model,
            equipmentClassName: item.equipmentClassName
          }
        })
        // 处理工具信息 ———————Start—————————
        let toolMsg = (this.$refs.trlationTool && this.$refs.trlationTool.showDatas) || []
        if (toolMsg.length) {
          toolMsg = toolMsg.map(k => {
            return {
              toolId: k.toolId,
              quantity: k.num,
              remark: k.remark || ''
            }
          })
        }
        // 处理工具信息 ———————End—————————
        this.infos = {
          ...this.createSetData(form),
          facilityMappingList: facilityMappingList,
          toolMappingList: toolMsg
        }
        return
      }
      this.buttonLoading = true
      this.$refs.baseForm.formRefs().validate(async(valid) => {
        if (valid) {
          that.saveData(form)
        } else {
          this.buttonLoading = false
        }
      })
    },
    refrashList() {
      this.showDispatch = false
      this.$router.push({ name: 'demandCenterList' })
    },
    async saveData(form) {
      let toolMsg = (this.$refs.trlationTool && this.$refs.trlationTool.showDatas) || []
      if (toolMsg.length) {
        toolMsg = toolMsg.map(k => {
          return {
            toolId: k.toolId,
            quantity: k.num,
            remark: k.remark || ''
          }
        })
      }
      const facilityMappingList = this.facilityMappingList.map(item => {
        return {
          facilityId: item.facilityId,
          facilityCode: item.code,
          facilityName: item.name,
          sn: item.sn,
          model: item.model,
          equipmentClassName: item.equipmentClassName
        }
      })
      const res = await api[`${this.params.type}FacilityDemand`](
        this.params.type !== 'modify' ? {
          ...this.createSetData(form),
          facilityMappingList: facilityMappingList,
          toolMappingList: toolMsg
        } : {
          ...this.createResetData(form),
          facilityMappingList: facilityMappingList,
          toolMappingList: toolMsg
        }
      )
      if (res) {
        this.showDispatch = false
        this.$router.push({ name: 'demandCenterList' })
      }
      this.buttonLoading = false
    },
    submitReForm() {
      const { form } = this.$refs.baseForm
      const { form: userForm } = this.$refs.userForm
      this.buttonLoading = true

      this.$refs.baseForm.formRefs().validate(async(valid) => {
        if (valid) {
          this.$refs.userForm.formRefs().validate(async(valids) => {
            if (valids) {
              this.saveData(Object.assign({}, {
                ...form, ...userForm
              }))
            } else {
              this.buttonLoading = false
            }
          })
        } else {
          this.buttonLoading = false
        }
      })
    },
    createSetData(raw) {
      let saleHeadName, technicalHeadName, rdHeadName, executorNames
      if (this.isReform) {
        technicalHeadName = this.usersList.filter(item => item.id === raw.technicalHeadId)[0].name
        saleHeadName = this.usersList.filter(item => item.id === raw.saleHeadId)[0].name
        rdHeadName = this.usersList.filter(item => item.id === raw.rdHeadId)[0].name
        executorNames = this.usersList.filter(item => raw.executorIds.includes(item.id)).map(item => item.name).join('、')
      }
      return {
        code: raw.code === '系统生成' ? -1 : raw.code,
        content: raw.content,
        serviceAddress: this.serviceAddress.join('/'),
        detailAddress: raw.detailAddress,
        serviceType: raw.serviceType,
        customerId: raw.customerId,
        customerName: raw.customerName,
        customerSiteId: raw.customerSiteId,
        customerSiteIdList: raw.customerSiteIdList ? raw.customerSiteIdList.join('^') : '',
        linkMan: raw.linkMan,
        linkPhone: raw.linkPhone,
        remark: raw.remark,
        customerFeedback: raw.customerFeedback,
        technicalHeadId: raw.technicalHeadId,
        technicalHeadName: technicalHeadName,
        saleHeadId: raw.saleHeadId,
        saleHeadName: saleHeadName,
        rdHeadId: raw.rdHeadId,
        rdHeadName: rdHeadName,
        executorIds: this.isReform ? `${raw.executorIds.join('^')}` : null,
        executorNames: executorNames,
        salesOrderNumber: raw.salesOrderNumber,
        happenTime: raw.happenTime || ''
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style lang="scss" scoped>

.procedure-detail-wrappers {
  height: 100%;
  width: 100%;

  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }

    .el-form-item {
      margin-bottom: 15px !important;
    }

    .date-number {
      width: 100%;
      display: flex;

      .el-form-item:first-child {
        width: 138% !important;

        input {
          border-radius: 4px 0 0 4px;
        }
      }

      .el-form-item:last-child {
        width: 62% !important;

        input {
          border-radius: 0 4px 4px 0;
          border-left: 0;
        }
      }
    }
  }

  .procedure-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .procedure-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }

  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #DDE3EB;

    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;

      span {
        margin-right: 45px;
        display: inline-block;
      }
    }

    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.flex-mode {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    margin-right: 20px;
  }

  ::v-deep .el-radio-group {
    vertical-align: initial;
  }

  ::v-deep .el-radio {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456B;
  }
}

.flex-modes {
  display: inline-flex;
  justify-content: flex-start;
  margin-top: 20px;

  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    margin-right: 20px;
  }

  .mode-ri {
    .ri-top {
      display: flex;
      justify-content: flex-start;
      margin: 10px 0;

      .top-tit {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393D60;
        line-height: 36px;
      }

      .ri-num {
        width: 130px;
        margin: 0 20px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #DDE3EB;
      }
    }
  }
}

.bot-tis {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #393D60;
  display: inline-block;
}
</style>
