<template>
  <div class="board-manage-container">
    <div class="left">
      <div class="top" style="word-break: keep-all">自定义报表</div>
      <div class="bottom">
        <template v-for="menu in childrenRouters">
          <div
            :key="menu.name"
            class="menu-item"
            style="position: relative"
          >
            <img :src="menu.meta.icon" style="width: 12px;margin-right: 5px">
            <span style="display: inline-block;word-break: keep-all">{{ menu.meta.title }}</span>
            <add-statistics-card :type-change="typeChange" :custom-statistics-cards-info="customStatisticsCardsInfo" @updateStatisticsCards="getCustomStatisticsCards('add')" />
          </div>
          <template>
            <div :key="menu.name + 1" style="padding: 0;margin: 0">
              <div
                v-for="(child, index) in customStatisticsCards"
                :key="index"
                :class="{ active: index === currentCustomStatisticsIndex }"
                class="menu-item cards-menu-item"
                @click="viewCustomDetail(child, index)"
              >
                <span class="ellipsis" style="width: 125px;display: inline-block;word-break: keep-all;color: #8A8CA5;padding:0 0 10px 25px">{{ child.title }}</span>
                <img src="@/assets/statistics-board/trash.png" alt="" @click.stop="delStatisticsCards(child)">
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <span>自定义报表卡片</span>
        <span>
          <!--          <span style="color: #E02020">提示：</span>以下模块为模板，如需自定义请另存为自定义报表卡片-->
        </span>
      </div>
      <div class="bottom">
        <custom-statistics :id="ids" @updateStatisticsCards="getCustomStatisticsCards" />
        <!-- <Blank /> -->
      </div>
    </div>
  </div>
</template>

<script>
import Blank from '@/layout/blank'
import statisticsBoardRouters from '@/router/statistics-board.router'
import AddStatisticsCard from '@/views/statistics-board/board-manage/components/add-statistics-card/add-statistics-card'
import baseSettingApi from '@/api/sets/base-setting'
import bus from '@/views/statistics-board/bus'
import CustomStatistics from '@/views/statistics-board/board-manage/custom-statistics/custom-statistics.vue'
export default {
  name: 'BoardManage',
  components: { CustomStatistics, AddStatisticsCard, Blank },
  data() {
    return {
      childrenRouters: [{
        path: 'board-manage',
        name: 'boardManage',
        component: () => import('@/views/statistics-board/board-manage/board-manage.vue'),
        meta: { title: '自定义报表', icon: require('@/assets/statistics-board/自定义标题@2x.png'), activeMenu: 'board-manage', canLink: true }
      }],
      customStatisticsCardsInfo: {},
      customStatisticsCards: [],
      currentCustomStatisticsIndex: null,
      isAdd: 0,
      ids: ''
    }
  },
  computed: {
    roles() {
      return this.$store.state.user.roles
    },
    key() {
      return this.$route.name
    },
    currentRouterName() {
      return this.$route.name
    },
    currentRouterTitle() {
      return this.$route.meta.title
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  created() {
    this.getCustomStatisticsCards()
  },
  methods: {
    typeChange(val) {
      this.isAdd = val
    },
    hasAuth(menu) {
      return this.roles.indexOf(menu.name) !== -1
    },
    goPath(menu) {
      if (menu.name === 'customStatisticsCard') return false
      this.currentCustomStatisticsIndex = null
      this.$router.push({ name: menu.name })
    },
    goNewPath(button) {
      this.$router.push({ path: `/auth-dashboard/${this.memberCode}/${button.index}` })
    },
    async getCustomStatisticsCards(type) {
      const res = await baseSettingApi.getCode({ code: 'custom_statistics_card' })
      if (res) {
        this.customStatisticsCardsInfo = res
        if (res.value) {
          this.customStatisticsCards = JSON.parse(res.value)
          // console.log('获取=》', this.customStatisticsCards)
          this.currentCustomStatisticsIndex = this.customStatisticsCards.findIndex(item => item.id === this.ids)
          if (type === 'add') {
            const index = this.customStatisticsCards.length - 1
            this.viewCustomDetail(this.customStatisticsCards[index], index)
          } else {
            // if (+this.isAdd === 0) {
            //   console.log('9089')
            //   if (this.customStatisticsCards && this.customStatisticsCards.length > 0) {
            //     this.viewCustomDetail(this.customStatisticsCards[0], 0)
            //   }
            // }
          }
        }
      }
    },
    delStatisticsCards(child) {
      console.log(this.customStatisticsCards, '结果', child.title)
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await baseSettingApi.modifySingleSetting({
          id: this.customStatisticsCardsInfo.id,
          code: this.customStatisticsCardsInfo.code,
          value: JSON.stringify(this.customStatisticsCards.filter(item => item.title !== child.title))
        })
        if (res) {
          this.$message.success('删除成功')
          this.isAdd = 1
          await this.getCustomStatisticsCards('add')
        }
      })
    },
    viewCustomDetail(child, index) {
      this.isAdd = 1
      if (this.customStatisticsCards.length > 0) {
        this.currentCustomStatisticsIndex = index
        this.ids = child.id
        // this.$router.push({ name: 'customStatisticsCard', query: { id: child.id }})
      } else {
        this.currentCustomStatisticsIndex = null
        this.ids = ''
        // this.$router.push({ name: 'boardManage' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.board-manage-container {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: space-between;
  font-size: 14px;
  .left {
    width: 180px;
    border-right: 1px solid #DDE3EB;
    .top {
      padding: 18px 72px 18px 34px;
      color: #9597AE;
      border-bottom: 1px solid #DDE3EB;
    }
    .bottom {
      padding: 8px;
      >div {
        padding: 5px 8px;
        border-radius: 4px;
        margin-top: 10px;
        color: #393D60;
        cursor: pointer;
        &.active {
          background: #EEF4FF;
          color: #607FFF;
        }
      }
    }
    .cards-menu-item {
      position: relative;
      cursor: pointer;
      border-radius: 4px;
      display: flex;
      align-items: center;
      height: 28px;
      padding:0 0 0 25px;
      span {
        padding: 0 !important;
      }
      img {
        width: 16px;
        position: absolute;
        right: 10px;
        font-size: 18px;
        color: #607FFF;
        cursor: pointer;
      }
      &.active {
        background: #EEF4FF;
        color: #607FFF;
      }
    }
  }
  .right {
    width: calc(100% - 180px);
    .top {
      width: 100%;
      color: #9597AE;
      padding: 18px 20px 18px 20px;
      border-bottom: 1px solid #DDE3EB;
      display: inline-flex;
      justify-content: space-between;
    }
    .bottom {
      height: calc(100% - 55px);
      color: #393D60;
    }
  }
}
</style>
