import _ from 'lodash'
import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'
const newColumns = _.cloneDeep(columns)

export const formList = transformColumnsToForm(newColumns)

export const searchFormList = [
  {
    key: 'customerName',
    tagName: 'el-cascader',
    colSpan: 5,
    props: {
      placeholder: '客户/现场',
      props: {
        label: 'name',
        value: 'id',
        checkStrictly: true
      },
      options: [],
      collapseTags: true,
      filterable: true
    }
  },
  {
    colSpan: 6,
    key: 'executionStartTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '服务开始时间',
      endPlaceholder: '服务结束时间',
      align: 'center',
      type: 'datetimerange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm',
      format: 'yyyy-MM-dd HH:mm',
      defaultTime: ['00:00:00', '23:59:00']
    },
    dayFormat: 'YYYY-MM-DD HH:mm',
    children: []
  },
  {
    colSpan: 4,
    key: 'executor',
    tagName: 'el-select',
    props: {
      placeholder: '执行人',
      multiple: true
    },
    isDelete: true,
    fieldType: 'string',
    children: [
      {
        tagName: 'el-option',
        innerValue: (val) => {
          return `${val['jobNumber']} - ${val['name']}`
        },
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  }
]

