<template>
  <div class="work-order-progress-container">
    <div class="top">
      <div>
        <div class="card-top card-top1">
          待备料
        </div>
        <div class="card-bottom card-bottom1">
          <dv-digital-flop :config="getDigitalConfig(digital.waitQuality, ['#fff', '#fff'])" style="font-size: 50px;margin-top: 20px;height: 80px;" />
        </div>
      </div>
      <div>
        <div class="card-top card-top2">
          备料中
        </div>
        <div class="card-bottom card-bottom2">
          <dv-digital-flop :config="getDigitalConfig(digital.ingQuality, ['#fff', '#fff'])" style="font-size: 50px;margin-top: 20px;height: 80px;" />
        </div>
      </div>
      <div>
        <div class="card-top card-top3">
          今日完成
        </div>
        <div class="card-bottom card-bottom3">
          <dv-digital-flop :config="getDigitalConfig(digital.haveQuality, ['#fff', '#fff'])" style="font-size: 50px;margin-top: 20px;height: 80px;" />
        </div>
      </div>
    </div>
    <div class="bottom">
      <el-popover
        ref="popover"
        v-model="showPopover"
        :width="207"
        :offset="20"
        @show="popoverShowEvent"
      >
        <div class="popover-content-top">
          <el-button @click="cancel">取消</el-button>
          <el-button @click="saveColumns">确定</el-button>
        </div>
        <div class="popover-content-center">
          <el-checkbox v-model="allChecked" @change="selectAllColumns">全选</el-checkbox>
          <el-button class="invert" type="text" @click="invertSelectColumns">
            <img src="@/assets/table_filter_images/fanxuan@2x.png" alt="反选" width="13px">
            <span>反选</span>
          </el-button>
        </div>
        <div class="popover-content-footer" :style="{maxHeight: popoverContentMaxHeight + 'px'}">
          <el-checkbox-group v-model="checkedList" class="check-wrapper">
            <draggable
              v-model="settingColumns"
              v-bind="dragOptions"
              @start="isDragging = true"
              @end="isDragging = false"
            >
              <el-checkbox v-for="item in settingColumns" :key="item.key" :label="item.prop">{{ item.label }}</el-checkbox>
            </draggable>
          </el-checkbox-group>
        </div>
      </el-popover>
      <div class="table-header">
        <img src="@/assets/screen/filter.png" class="img">
        <span ref="columnsSettingBtn" v-popover:popover>表头字段筛选</span>
      </div>
      <scroll-board :config="scrollBoardConfig" :style="{width:'100%', height: tableHeight + 'px'}">
        <div slot="materialPreparationStatus" slot-scope="{ index }" class="status-board" :style="{ background: productionStatus[tableData[index].materialPreparationStatus].color }">{{ productionStatus[tableData[index].materialPreparationStatus].name }}</div>
        <div slot="process" slot-scope="{ index }">
          <el-progress :stroke-width="10" :percentage="fixNumber(tableData[index]) >= 100 ? 100 : fixNumber(tableData[index])" :format="fixNumber(tableData[index], 1)" style="width: 120px" />
        </div>
        <div slot="producePlanPriorityId" slot-scope="{ index }" class="status-board" :style="{ background: priorityStatus[tableData[index].producePlanPriorityId].color }">{{ priorityStatus[tableData[index].producePlanPriorityId].name }}</div>
        <div slot="quantityAvailable" slot-scope="{ index }">{{ tableData[index].quantityAvailable }}</div>
        <div slot="quantityPrepared" slot-scope="{ index }">{{ tableData[index].quantityPrepared }}</div>
      </scroll-board>
    </div>
  </div>
</template>

<script>
import mix from './mixin'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
import { baseUrl } from '@/views/statistics-board/board-detail/components/base-setting'
import CustomProgress from '@/views/statistics-board/board-detail/components/custom-progress'
import api from '@/api/statistics-board/all-statistics'
import dayjs from 'dayjs'
import draggable from 'vuedraggable'
import { columnsAll } from './columns'

const scrollBoardSettingDefault = {
  carousel: 'page',
  headerHeight: 32,
  hoverPause: false,
  waitTime: 10000,
  headerBGC: '#202020',
  oddRowBGC: '#202020',
  evenRowBGC: '#0F173A',
  align: ['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']
}
export default {
  name: 'Warehouse',
  components: { draggable, CustomProgress, scrollBoard },
  mixins: [mix],
  data() {
    return {
      showPopover: false,
      allChecked: false,
      popoverContentMaxHeight: 200,
      settingColumns: columnsAll,
      copySettingColumns: columnsAll,
      checkedList: [],
      isDragging: false,
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      productionStatus: [
        {
          id: 0,
          name: '待下发',
          color: '#460202',
          background: 'rgba(70, 2, 2, 1)'
        },
        {
          id: 1,
          name: '待备料',
          color: '#460202',
          background: 'rgba(70, 2, 2, 1)'
        },
        {
          id: 2,
          name: '备料中',
          color: '#00371A',
          background: 'rgba(0, 171, 41, 0.1)'
        },
        {
          id: 3,
          name: '已备料',
          color: '#9597AE',
          background: 'rgba(149, 151, 174, 0.1)'
        }
      ],
      digital: {
        waitQuality: 0,
        ingQuality: 0,
        haveQuality: 0
      },
      tableHeight: 100,
      scrollBoardConfig: {
        ...scrollBoardSettingDefault,
        columnWidth: [70, 200, 120, 120, 120, 150, 150, 150, 180, 150, 150, 150, 150, 150, 150, 150],
        header: ['序号', '备料单号', '备料状态', '待备数量', '已备数量', '备料进度', '关联工单', '物料名称', '物料编号', '工单数量', '工单交期', '创建日期', '产线', '所属订单', '计划优先级'],
        data: []
      },
      scrollBoardSettingDefault,
      tableData: [],
      keyWidth: [70, 200, 120, 120, 120, 150, 150, 150, 180, 150, 150, 150, 150, 150, 150, 150],
      keyName: ['index', 'materialPreparationNumber', 'slot:materialPreparationStatus', 'slot:quantityPrepared', 'slot:quantityAvailable', 'slot:process', 'produceWorkOrderNumber', 'materialsName', 'materialsCode', 'quantity', 'deliveryTime', 'createTime', 'areaName', 'produceOrderNumber', 'slot:producePlanPriorityId']
    }
  },
  computed: {
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  mounted() {
    this.reloadData()
  },
  methods: {
    popoverShowEvent() {
      // this.settingColumns = this._.cloneDeep(this.copySettingColumns)
      this.checkedList = this._.cloneDeep(this.keyName)
    },
    cancel() {
      this.showPopover = false
      this.allChecked = false
      setTimeout(this.resetSettingFields, 500)
    },
    async saveColumns() {
      const newColumns = this.settingColumns.filter(item => this.checkedList.includes(item.prop))
      this.scrollBoardConfig.header = newColumns.map(item => item.label)
      this.keyName = newColumns.map(item => item.prop)
      this.keyWidth = newColumns.map(item => item.width)
      this.getTablePutData(baseUrl + 'getMaterialPreparationListByLine', {
        idList: this.areaIds ? this.areaIds.split('^') : []
      }, {
        key: 'scrollBoardConfig',
        key2: 'tableData',
        columns: this.scrollBoardConfig.header,
        columnWidth: this.keyWidth,
        rowNum: 10,
        columnsKey: this.keyName
      }, 1)
      this.showPopover = false
    },
    selectAllColumns(val) {
      this.checkedList = val ? this.settingColumns.map(item => item.prop) : []
    },
    invertSelectColumns() {
      this.checkedList = this.settingColumns
        .filter(item => this.checkedList.indexOf(item.prop) === -1)
        .map(item => item.prop)
    },
    fixNumber(row, format) {
      if (!row.quantityAvailable || !row.quantityPrepared) {
        if (format) {
          return () => {
            return 0 + '%'
          }
        } else {
          return 0
        }
      } else {
        if (format) {
          return () => {
            return Number((row.quantityAvailable / row.quantityPrepared * 100).toFixed(0)) + '%'
          }
        } else {
          return Number((row.quantityAvailable / row.quantityPrepared * 100).toFixed(0))
        }
      }
    },
    reloadData(areaIds = '') {
      this.areaIds = areaIds
      this.getTablePutData(baseUrl + 'getMaterialPreparationListByLine', {
        idList: areaIds ? areaIds.split('^') : []
      }, {
        key: 'scrollBoardConfig',
        key2: 'tableData',
        columns: this.scrollBoardConfig.header,
        columnWidth: this.keyWidth,
        rowNum: 10,
        columnsKey: this.keyName
      }, 1)
      this.workOrderQuality()
    },
    async workOrderQuality() {
      const res = await api.getTablePutData(baseUrl + 'getMaterialPreparationStatusCountByLine', {
        idList: this.areaIds ? this.areaIds.split('^') : []
      })
      if (res) {
        this.digital.waitQuality = res[1]
        this.digital.ingQuality = res[2]
        this.digital.haveQuality = res[3]
      }
    },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.tableHeight = (window.innerHeight * 0.7) / scale
    }
  }
}
</script>

<style scoped lang="scss">
@import "./common";
.popover-content-top {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    button {
      padding: 8px 20px;
      border-radius: 4px;
      border-color: #DEDEEE;
      color: #8A8CA5;
      &:last-child {
        border-color: #607FFF;
        color: #607FFF;
      }
    }
  }
  .popover-content-center {
    width: calc(100% + 24px);
    position: relative;
    left: -12px;
    margin-top: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    background: #F8F8FB;
    height: 25px;
    button.invert {
      span {
        color: #8A8CA5;
      }
      img {
        position: relative;
        top: 2px;
        right: 10px;
      }
    }
  }
  .popover-content-footer {
    width: calc(100% + 24px);
    position: relative;
    left: -12px;
    max-height: 380px;
    overflow-y: auto;
    .el-checkbox-group {
      overflow: hidden;
      .el-checkbox {
        width: 100%;
        padding: 8px 8px 8px 28px;
        cursor: pointer;
        &:hover {
          background: #ECF0F4;
        }
      }
    }
  }
.work-order-progress-container {
  padding: 0 20px 20px 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  .top {
    display: flex;
    justify-content: space-between;
    >div {
      width: 580px;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 120px;
      .card-top {
        width: 200px;
        height: 120px;
        color: #fff;
        text-align: center;
        font-size: 27px;
        line-height: 120px;
      }
      .card-top1 {
        background: rgba(157, 71, 20, .4);
      }
      .card-top2 {
        background: rgba(0, 118, 31, .4);
      }
      .card-top3 {
        background: rgba(58, 33, 166, .4);
      }
      .card-bottom {
        width: 380px;
        height: 120px;
        text-align: center;

      }
      .card-bottom1 {
        background: rgba(157, 71, 20, 1);
      }
      .card-bottom2 {
        background: rgba(0, 118, 31, 1);
      }
      .card-bottom3{
        background: rgba(58, 33, 166, 1);
      }

    }
  }

  .bottom {
    margin-top: 18px;
    .table-header {
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3AB2FF;
      line-height: 28px;
      margin-bottom: 18px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      .img {
        width: 28px;
        height: 24px;
        display: block;
        margin-right: 8px;
      }
    }
    .progress-line-wrapper {
      white-space: nowrap;
      .progress-wrap {
        width: 162px;
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        ::v-deep .el-progress {
          position: relative;
          top: 1px;
        }
        .progress-text {
          position: absolute;
          top: 1px;
          left: 0;
          right: 0;
          bottom: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            white-space: nowrap;
            transform: scale(.8);
            color: #607FFF;
          }
        }
        &.active-procedure {
          border: 2px solid #607FFF;
          background: #0F2067;
          .procedure-name {
            color: #FFFFFF;
          }
          .progress-text {
            span {
              color: #FFFFFF;
            }
          }
        }
        &:not(:last-child) {
          &::after {
            content: "";
            display: inline-block;
            height: 1px;
            background: #2E4398;
            width: 88px;
            position: absolute;
            top: 39%;
            left: 118px;
          }
        }
        &.wait-procedure {
          .procedure-name {
            color: #999999 !important;
          }
          .progress-text {
            span {
              color: #999999 !important;
            }
          }
          &:not(:last-child) {
            &::after {
              content: "";
              display: inline-block;
              height: 1px;
              background: #999;
              width: 88px;
              position: absolute;
              top: 39%;
              left: 118px;
            }
          }
        }
      }
      .procedure-name {
        width: 100%;
        font-size: 14px !important;
        text-align: center;
        position: relative;
        top: 1px;
        color: #607FFF;
      }
    }
  }
}
::v-deep .dv-scroll-board .ceil {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  >span {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.status-board {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  text-align: left;
  line-height: 85px;
}
</style>
