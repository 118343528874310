<template>
  <div class="equipment-board-container">
    <div class="top">
      <div class="inspection-finish">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">
          点巡检完成数（近半年）
        </div>
        <ve-line
          height="300px"
          :data="inspectionFinishChartData"
          :settings="lineChartSettings"
          :extend="lineChartExtend"
        />
      </div>
      <div class="equipment-info">
        <div class="card-info">
          <img src="https://oss.mubyte.cn/static/equipmentCount.png" alt="">
          <dv-digital-flop :config="getDigitalConfig(digital.equipmentQuantity, ['#FFF', '#FFF'])" style="width: 162px;" />
          <div>联网设备</div>
        </div>
        <div class="card-info">
          <img src="https://oss.mubyte.cn/static/maintenanceCOunt.png" alt="">
          <dv-digital-flop :config="getDigitalConfig(digital.maintenanceQuantity, ['#FFF', '#FFF'])" style="width: 162px;" />
          <div>维保中数量</div>
        </div>
        <div class="card-info">
          <img src="https://oss.mubyte.cn/static/faultCount.png" alt="">
          <dv-digital-flop :config="getDigitalConfig(digital.faultQuantity, ['#FFF', '#FFF'])" style="width: 162px;" />
          <div>故障总数</div>
        </div>
      </div>
      <div class="bj-sort">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">备件更换排名（年）</div>
        <ve-bar
          height="300px"
          :data="bgSortChartData"
          :settings="barChartSettings"
          :extend="barChartExtend2"
        />
      </div>
    </div>
    <div class="center">
      <div class="inspection-error">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">点巡检异常数（近半年）</div>
        <ve-line
          height="300px"
          :data="inspectionErrorChartData"
          :settings="lineChartSettings"
          :extend="lineChartExtend"
        />
      </div>
      <div class="fault-sort">
        <div class="gradient-title" style="background: linear-gradient(90deg,  #7410FF 0%, rgba(96, 127, 255, 0) 100%);">故障排名（年）</div>
        <ve-bar
          height="300px"
          :data="faultSortChartData"
          :settings="barChartSettings"
          :extend="barChartExtend3"
        />
      </div>
      <div class="fault-sort">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">保养统计分析（近半年）</div>
        <ve-histogram
          height="300px"
          :data="maintenanceChartData"
          :settings="barChartSettings"
          :extend="barChartExtend"
        />
      </div>
    </div>
    <div class="bottom">
      <div class="inspection-task">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);margin-bottom: 10px">点巡检任务<span>（{{ inspectionTaskDataTotal }}）</span></div>
        <scroll-board :config="scrollBoardConfig" :style="{width:'100%', height: tableHeight + 'px'}">
          <span slot="status" slot-scope="{ index }" class="status-board-r" :style="{ background: inspectionTaskData[index].status === 1 ? '#4B1E00' : '#00371A' }">
            {{ inspectionTaskData[index] ? inspectionTaskData[index].status === 1 ? inspectionTaskData[index].spotCheckNumber ? '待点检' : '待巡检' : inspectionTaskData[index].spotCheckNumber ? '点检中' : '巡检中' : '' }}
          </span>
        </scroll-board>
      </div>
      <div class="repair-task">
        <div class="gradient-title" style="background: linear-gradient(90deg,  #7410FF 0%, rgba(96, 127, 255, 0) 100%);margin-bottom: 10px">维修任务<span>（{{ repairTaskDataTotal }}）</span></div>
        <scroll-board :config="scrollBoardConfig1" :style="{width:'100%', height: tableHeight + 'px'}">
          <span slot="status" slot-scope="{ index }" class="status-board" :style="{ background: repairStatus[repairTaskData[index].status].color }">{{ repairStatus[repairTaskData[index].status].name }}</span>
        </scroll-board>
      </div>
      <div class="maintenance-task">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);margin-bottom: 10px">保养任务<span>（{{ maintenanceTaskDataTotal }}）</span></div>
        <scroll-board :config="scrollBoardConfig2" :style="{width:'100%', height: tableHeight + 'px'}">
          <span slot="status" slot-scope="{ index }" class="status-board-r" :style="{ background: maintenanceTaskData[index].status === 1 ? '#4B1E00' : '#00371A' }">
            {{ maintenanceTaskData[index] ? maintenanceTaskData[index].status === 1 ? '待保养' : '保养中' : '' }}
          </span>
        </scroll-board>
      </div>
    </div>
  </div>
</template>

<script>
import mix from '@/views/statistics-board/board-detail/components/mixin'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
import api from '@/api/statistics-board/all-statistics'
import { baseUrl } from '@/views/statistics-board/board-detail/components/base-setting'
import dayjs from 'dayjs'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

const scrollBoardSettingDefault = {
  carousel: 'page',
  headerHeight: 32,
  hoverPause: false,
  waitTime: 10000,
  headerBGC: '#222222',
  oddRowBGC: '#222222',
  evenRowBGC: 'transparent'
}
export default {
  name: 'Equipment',
  components: { scrollBoard },
  mixins: [mix],
  data() {
    return {
      scrollBoardSettingDefault,
      digital: {
        equipmentQuantity: 0,
        maintenanceQuantity: 0,
        faultQuantity: 0
      },
      inspectionFinishChartData: {
        columns: ['月份', '点检', '巡检'],
        rows: []
      },
      inspectionTaskData: [],
      repairTaskData: [],
      maintenanceTaskData: [],
      inspectionErrorChartData: {
        columns: ['月份', '点检', '巡检'],
        rows: []
      },
      lineChartExtend2: [],
      maintenanceChartData: {
        columns: ['月份', '数量'],
        rows: []
      },
      bgSortChartData: {
        columns: ['名称', '数量'],
        rows: []
      },
      barChartExtend2: {},
      faultSortChartData: {
        columns: ['名称', '数量'],
        rows: []
      },
      barChartExtend3: {},
      scrollBoardConfig: {
        ...scrollBoardSettingDefault,
        columnWidth: [60, 100, 350, 110],
        header: ['序号', '类型', '机台', '状态'],
        data: []
      },
      scrollBoardConfig1: {
        ...scrollBoardSettingDefault,
        columnWidth: [60, 300, 100, 150],
        header: ['序号', '机台', '状态', '报修人'],
        data: []
      },
      scrollBoardConfig2: {
        ...scrollBoardSettingDefault,
        columnWidth: [70, 350, 150, 110],
        header: ['序号', '机台', '类型', '状态'],
        data: []
      },
      tableHeight: 100,
      repairStatus: { 2: {
        name: '待接单',
        color: '#4B1E00'
      }, 3: {
        name: '待维修',
        color: '#002C4D'
      }, 4: {
        name: '维修中',
        color: '#00371A'
      }},
      inspectionTaskDataTotal: 0,
      repairTaskDataTotal: 0,
      maintenanceTaskDataTotal: 0
    }
  },
  beforeMount() {
    Object.assign(this.lineChartExtend, {
      color: ['#607FFF', '#44D7B6'],
      series: {
        smooth: false
      }
    })
    this.barChartExtend2 = this._.cloneDeep(this.barChartExtend)
    this.barChartExtend2.series.showBackground = false
    delete this.barChartExtend2.series.barWidth
    this.barChartExtend2.series.itemStyle.color = {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 0,
      colorStops: [{
        offset: 1, color: 'rgba(84, 203, 164, 1)' // 0% 处的颜色
      }, {
        offset: 0, color: 'rgba(45, 45, 189, 1)' // 100% 处的颜色
      }]
    }
    this.barChartExtend3 = this._.cloneDeep(this.barChartExtend2)
    this.barChartExtend3.series.itemStyle.color = {
      type: 'linear',
      x: 0,
      y: 1,
      x2: 1,
      y2: 1,
      colorStops: [{
        offset: 1, color: '#60CFFF' // 0% 处的颜色
      }, {
        offset: 0, color: '#7410FF' // 100% 处的颜色
      }]
    }
  },
  mounted() {
    this.reloadData()
  },
  methods: {
    getDigitalConfig(number, color, content = '{nt}') {
      return {
        number: [Number(number)],
        content,
        formatter: this.formatter,
        style: {
          fontSize: 55,
          gradientColor: color,
          gradientType: 'linear',
          gradientParams: [0, 25, 0, 50],
          gradientWith: 'fill'
        }
      }
    },
    reloadData() {
      this.getInspectionFinishChartData()
      this.getInspectionErrorChartData()
      this.getBgSortChartData()
      this.getFaultSortChartData()
      this.getMaintenanceChartData()
      this.getPointAndInspectionTableData()
      this.getEquipmentInfo()
      this.getTableData(baseUrl + 'getEquipmentRepairTaskList', {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'status',
            fieldType: 'number',
            fieldValue: '2^3^4',
            operator: 'in'
          }]
        ))
      }, {
        repeat: true,
        key: 'scrollBoardConfig1',
        key2: 'repairTaskData',
        columns: this.scrollBoardConfig1.header,
        columnWidth: [60, 300, 100, 150],
        rowNum: 4,
        columnsKey: ['index', 'equipmentName', 'slot:status', 'creator']
      })
      this.getTableData(baseUrl + 'getEquipmentMaintainTaskList', {
        statusList: '1^2'
      }, {
        repeat: true,
        key: 'scrollBoardConfig2',
        key2: 'maintenanceTaskData',
        columns: this.scrollBoardConfig2.header,
        columnWidth: [70, 350, 150, 110],
        rowNum: 4,
        columnsKey: ['index', 'equipmentName', 'checkType', 'slot:status']
      })
    },
    async getPointAndInspectionTableData() {
      this.getHeight()
      this.getPointAndInspectionTableDataPage()
      const res = await api.getTableData(baseUrl + 'getEquipmentCheckTaskList', {
        statusList: '1^2'
      })
      if (res) {
        this.setInspectionData(res)
      }
    },
    async getPointAndInspectionTableDataPage() {
      const res = await api.getTableData(baseUrl + 'getEquipmentCheckTaskListPage', {
        page: 1,
        limit: 4,
        statusList: '1^2'
      })
      if (res) {
        this.inspectionTaskDataTotal = res.total
        this.setInspectionData(res.records)
      }
    },
    setInspectionData(res) {
      const columnsKey = ['index', 'type', 'equipmentName', 'slot:status']
      this.inspectionTaskData = res
      this.scrollBoardConfig = {
        ...this.scrollBoardSettingDefault,
        header: this.scrollBoardConfig.header,
        rowNum: 4,
        columnWidth: [60, 100, 350, 110],
        delayIndex: [],
        data: this.inspectionTaskData.map((item, index) => (columnsKey.map((c, i) => {
          if (c.indexOf('slot:') !== -1) {
            const slots = c.split(':')
            return `${slots[1]}${slots[2] || ''}|${index}`
          }
          if (c === 'index') {
            return index + 1
          }
          return item[c]
        })))
      }
    },
    async getInspectionFinishChartData() {
      const res = await api.getTableData(baseUrl + 'getEquipmentCheckCountResultForDisplayBoard', {
        beginTime: dayjs().subtract(5, 'month').format('YYYY-MM'),
        endTime: dayjs().format('YYYY-MM')
      })
      if (res) {
        this.inspectionFinishChartData.rows = res.map(item => ({
          '月份': dayjs(item.month).format('M月'),
          '点检': item.spotCheckCount,
          '巡检': item.cycleCheckCount
        }))
      }
    },
    async getEquipmentInfo() {
      const res = await api.getTableData(baseUrl + 'getEquipmentCountForDisplayBoard')
      if (res) {
        this.digital = {
          equipmentQuantity: res.equipmentCount,
          maintenanceQuantity: res.maintenanceEquipmentCount,
          faultQuantity: res.faultEquipmentCount
        }
      }
    },
    async getInspectionErrorChartData() {
      const res = await api.getTableData(baseUrl + 'getEquipmentCheckNgResultForDisplayBoard', {
        beginTime: dayjs().subtract(5, 'month').format('YYYY-MM'),
        endTime: dayjs().format('YYYY-MM')
      })
      if (res) {
        this.inspectionErrorChartData.rows = res.map(item => ({
          '月份': dayjs(item.month).format('M月'),
          '点检': item.spotCheckCount,
          '巡检': item.cycleCheckCount
        }))
      }
    },
    async getBgSortChartData() {
      const res = await api.getTableData(baseUrl + 'getSparePartTotalForDisplayBoard', {
        beginTime: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD')
      })
      if (res) {
        this.bgSortChartData.rows = res.map(item => ({
          '名称': item.info,
          '数量': Number(item.quantity)
        }))
      }
    },
    async getFaultSortChartData() {
      const res = await api.getTableData(baseUrl + 'getEquipmentFaultReasonTotalForDisplayBoard', {
        beginTime: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD')
      })
      if (res) {
        this.faultSortChartData.rows = res.map(item => ({
          '名称': item.info,
          '数量': Number(item.quantity)
        }))
      }
    },
    async getMaintenanceChartData() {
      const res = await api.getTableData(baseUrl + 'getEquipmentMaintenanceCountResultForDisplayBoard', {
        beginTime: dayjs().subtract(5, 'month').format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD')
      })
      if (res) {
        this.maintenanceChartData.rows = res.map(item => ({
          '月份': dayjs(item.month).format('M月'),
          '数量': Number(item.maintenanceCount)
        }))
      }
    },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.tableHeight = (window.innerHeight * 0.18) / scale
    }
  }
}
</script>

<style scoped lang="scss">
@import "./common";

.equipment-board-container {
  padding: 0 20px 20px 20px;
  font-family: PingFangSC-Semibold, PingFang SC;

  .top, .center, .bottom {
    display: flex;
    justify-content: space-between;

    > div {
      width: 596px;
    }
  }

  .top {
    .equipment-info {
      display: flex;
      justify-content: space-between;

      .card-info {
        position: relative;
        width: 162px;

        img {
          width: 162px;
        }

        > div {
          color: #FFFFFF;
          font-size: 23px;
          text-align: center;
          padding: 20px 0;
        }

        ::v-deep {
          .dv-digital-flop {
            position: absolute;
            top: 10px;
          }
        }
      }
    }
  }
}
::v-deep .dv-scroll-board .ceil {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  >span {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.status-board {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 44px;
}
.status-board-r {
  display: inline-block;
  width: calc(100% + 10px);
  height: 100%;
  text-align: center;
  line-height: 44px;
}
</style>
