var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-info-wrapper" }, [
    _c(
      "div",
      { staticClass: "form-build-wrapper" },
      [
        _c("MFormBuilder", {
          ref: "formBuild",
          attrs: {
            "form-data": _vm.formData,
            "form-list": _vm.formList,
            disabled: true,
            "label-position": "left"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }