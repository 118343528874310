
export const searchFormListBbelc = [
  {
    key: 'sitWarehouseId',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '线边仓',
      filterable: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  // {
  //   colSpan: 5,
  //   key: 'workOrderId',
  //   tagName: 'el-select',
  //   defaultValue: 1,
  //   props: {
  //     placeholder: '工单',
  //     filterable: true
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'produceWorkOrderNumber',
  //         value: 'id'
  //       },
  //       options: []
  //     }
  //   ]
  // },
  {
    colSpan: 6,
    key: 'materialsType',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '物料类型',
      filterable: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'customerCode',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '客户',
      filterable: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'code'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'width',
    tagName: 'div',
    defaultValue: '',
    domProps: { className: 'customer-content' },
    children: [
      {
        slots: ['width']
      }
    ]
  },
  {
    colSpan: 6,
    key: 'thickness',
    tagName: 'div',
    defaultValue: '',
    domProps: { className: 'customer-content' },
    children: [
      {
        slots: ['thickness']
      }
    ]
  }
]
export const searchFormList = [
  {
    key: 'sitWarehouseId',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '线边仓',
      filterable: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  // {
  //   colSpan: 6,
  //   key: 'workOrderId',
  //   tagName: 'el-select',
  //   defaultValue: 1,
  //   props: {
  //     placeholder: '工单',
  //     filterable: true
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'produceWorkOrderNumber',
  //         value: 'id'
  //       },
  //       options: []
  //     }
  //   ]
  // },
  {
    colSpan: 6,
    key: 'materialsType',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '物料类型',
      filterable: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  }
]
export const searchFormLists = [
  {
    key: 'sitWarehouseId',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '线边仓',
      filterable: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  }
]
