<template>
  <div class="card" @click="toDetail(1)">
    <img src="https://mubyte.oss-cn-shanghai.aliyuncs.com/line/delong/2023/09/screen-1.png">
    <div class="title">车间大屏</div>
  </div>
</template>
<script>
export default {
  name: 'ProdScreen',
  data() {
    return {

    }
  },
  methods: {
    toDetail(type) {
      this.$router.push({ path: `/workshop-board-detail/${type}` })
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  width: fit-content;
  padding: 15px;
  border-radius: 4px;
  background: #fff;
  -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;

  img {
    width: 268px;
    height: 142px;
  }

  .title {
    margin-top: 18px;
    color: #393d60;
    font-weight: 600;
    text-align: center;
  }
}
</style>
