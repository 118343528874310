import api from '@/api/statistics-board/all-statistics'
import { Encrypt } from '@/utils/sercet'

export default {
  data() {
    return {
      show: false,
      transferData: [],
      filterType: '',
      currentKey: '',
      defaultPropsCopy: {
        key: 'id',
        label: 'name'
      },
      defaultProps: {
        key: 'id',
        label: 'name'
      }
    }
  },
  methods: {
    async openFilterModal(header) {
      console.log(header)
      this.filterType = header.filterType
      this.currentKey = header.searchKey || header.prop
      this.defaultProps = header.transferProps || this.defaultPropsCopy
      if (this.filterType === 'transfer') {
        this.loading = true
        let searchObj = {}
        if (Array.isArray(header.dataCondition) && header.dataCondition.length) {
          searchObj = {
            searchVal: Encrypt(JSON.stringify(header.dataCondition))
          }
        } else {
          searchObj = header.dataCondition
        }
        const res = await api.getData(header.dataUrl, searchObj)
        if (res) {
          this.transferData = res
          if (header.transferProps && header.transferProps.key === 'index') {
            this.transferData = JSON.parse(res.value)
            this.transferData.forEach((item, index) => {
              item.index = index
            })
          }
          this.loading = false
          this.show = true
        }
      }
      if (this.filterType === 'materials') {
        this.show = true
      }
    },
    filterMethod(query, item) {
      if (Array.isArray(this.defaultProps.label)) {
        return this.defaultProps.label.some(prop => item[prop].indexOf(query) > -1)
      }
      return item[this.defaultProps.label].indexOf(query) > -1
    },
    submitTransfer(callback) {
      callback()
      this.show = false
      this.refreshTable()
    },
    submitMaterials(val) {
      this.selectObj[this.currentKey] = val
      this.show = false
      this.refreshTable()
    }
  }
}
