
import { status } from '@/config/options.config'
import { formatColumns } from '@/utils'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const columns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    sortable: true,
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'code',
    label: '经验编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    minWidth: 150
  },
  {
    prop: 'name',
    label: '经验名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'faultExperienceClassName',
    label: '分类',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150
  },
  {
    prop: 'equipmentClassNames',
    label: '关联设备类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'faultCode',
    label: '故障编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 120
  }
]
export const addColumns = [
  {
    prop: 'code',
    label: '经验编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    minWidth: 150,
    form: { index: 0, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'name',
    label: '经验名称',
    sortable: false,
    hiddenSearch: true,
    form: { index: 1, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'faultExperienceClassId',
    label: '分类',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: { index: 2, tagName: 'el-select', key: 'faultExperienceClassId', colSpan: 8, props: {
      props: {
        label: 'name',
        value: 'id'
      },
      options: [],
      filterable: true
    }}
  },
  {
    prop: 'equipmentClassNames',
    label: '关联设备类',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      required: false,
      tagName: 'el-cascader',
      key: 'equipmentClassIdList',
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true,
          multiple: true
        },
        options: [],
        filterable: true
      },
      colSpan: 24
    }
  },
  {
    prop: 'faultCode',
    label: '故障编码',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      colSpan: 24,
      tagName: 'el-select',
      required: false,
      props: {
        multiple: true,
        filterable: true,
        allowCreate: true,
        defaultFirstOption: true,
        placeholder: '请输入可能值，敲击回车'
      }
    }
  },
  {
    prop: 'solution',
    label: '解决方法',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 5, colSpan: 24, tagName: 'el-input', required: false, props: { type: 'textarea', autosize: { minRows: 3 }}}
  },
  {
    prop: 'improvementMeasure',
    label: '改善措施',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 6, colSpan: 24, tagName: 'el-input', required: false, props: { type: 'textarea', autosize: { minRows: 3 }}}
  },
  {
    prop: 'attachment',
    label: '附件',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 7, colSpan: 24, required: false, tagName: 'div', customSlots: ['drawing'] }
  }
]
