<template>
  <div class="work-order-progress-container">
    <div class="top">
      <div>
        <div class="card-top card-top1">
          待质检
        </div>
        <div class="card-bottom card-bottom1">
          <dv-digital-flop :config="getDigitalConfig(digital.waitQuality, ['#fff', '#fff'])" style="font-size: 50px;margin-top: 20px;height: 80px;" />
        </div>
      </div>
      <div>
        <div class="card-top card-top2">
          质检中
        </div>
        <div class="card-bottom card-bottom2">
          <dv-digital-flop :config="getDigitalConfig(digital.ingQuality, ['#fff', '#fff'])" style="font-size: 50px;margin-top: 20px;height: 80px;" />
        </div>
      </div>
      <div>
        <div class="card-top card-top3">
          今日完成
        </div>
        <div class="card-bottom card-bottom3">
          <dv-digital-flop :config="getDigitalConfig(digital.haveQuality, ['#fff', '#fff'])" style="font-size: 50px;margin-top: 20px;height: 80px;" />
        </div>
      </div>
    </div>
    <div class="bottom">
      <scroll-board :config="scrollBoardConfig" :style="{width:'100%', height: tableHeight + 'px'}">
        <div slot="quanlityType" slot-scope="{ index, zn }" class="quanlityType">
          <span>{{ zn }}</span>
          <el-popover
            ref="popover"
            v-model="showPopover"
            :width="160"
            :offset="20"
            @show="popoverShowEvent"
          >
            <div class="popover-content-footer" :style="{maxHeight: '300px'}">
              <el-checkbox-group v-model="checkedList" class="check-wrapper">
                <el-checkbox v-for="item in lists" :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="popover-content-top">
              <el-button size="mini" @click="showPopover = false">取消</el-button>
              <el-button size="mini" type="primary" @click="saveColumns">确定</el-button>
            </div>
          </el-popover>
          <img v-popover:popover src="@/assets/screen/filter.png" class="img">
        </div>
        <div slot="status" slot-scope="{ index }" class="status-board" :style="{ background: productionStatus[tableData[index].status].color }">{{ productionStatus[tableData[index].status].name }}</div>
        <span slot="inspectionType" slot-scope="{ index }">{{ types[tableData[index].inspectionType].name }}</span>
      </scroll-board>
    </div>
  </div>
</template>

<script>
import mix from './mixin'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
import { baseUrl } from '@/views/statistics-board/board-detail/components/base-setting'
import CustomProgress from '@/views/statistics-board/board-detail/components/custom-progress'
import api from '@/api/statistics-board/all-statistics'
import dayjs from 'dayjs'
const scrollBoardSettingDefault = {
  carousel: 'page',
  headerHeight: 32,
  hoverPause: false,
  waitTime: 10000,
  headerBGC: '#202020',
  oddRowBGC: '#202020',
  evenRowBGC: '#0F173A',
  align: ['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']
}
export default {
  name: 'QualityNew',
  components: { CustomProgress, scrollBoard },
  mixins: [mix],
  data() {
    return {
      showPopover: false,
      checkedList: [],
      lists: [
        {
          id: 1,
          name: '过程检'
        },
        {
          id: 2,
          name: '来料检'
        },
        {
          id: 3,
          name: '出货检'
        }
      ],
      types: [
        {
          id: 0,
          name: '首检'
        },
        {
          id: 1,
          name: '过程检'
        },
        {
          id: 2,
          name: '来料检'
        },
        {
          id: 3,
          name: '出货检'
        }
      ],
      productionStatus: [
        {
          id: 0,
          name: '待质检',
          color: '#460202',
          background: 'rgba(70, 2, 2, 1)'
        },
        {
          id: 1,
          name: '质检中',
          color: '#00371A',
          background: 'rgba(0, 171, 41, 0.1)'
        },
        {
          id: 2,
          name: '已质检',
          color: '#9597AE',
          background: 'rgba(149, 151, 174, 0.1)'
        }
      ],
      digital: {
        waitQuality: 0,
        ingQuality: 0,
        haveQuality: 0
      },
      tableHeight: 100,
      scrollBoardConfig: {
        ...scrollBoardSettingDefault,
        columnWidth: [70, 200, 150, 160, 200, 200, 200, 150, 220],
        header: ['序号', '质检单号', 'slot:quanlityType:质检类型', '质检状态', '物料编码', '物料名称', '规格', '制单人', '制单时间'],
        data: []
      },
      scrollBoardSettingDefault,
      tableData: []
    }
  },
  mounted() {
    this.reloadData()
  },
  methods: {
    popoverShowEvent() {
      this.checkedList = []
    },
    async saveColumns() {
      await this.list()
      this.showPopover = false
    },
    allProcedureList(index) {
      if (this.tableData[index].allWorkOrderTaskList) {
        return this.tableData[index].allWorkOrderTaskList
      }
      return []
    },
    currentProcedure(index) {
      if (this.tableData[index].currentProcedure) {
        return JSON.parse(this.tableData[index].currentProcedure)[0].procedureUuid
      }
      return ''
    },
    isWaitProcedure(index, procedure) {
      return procedure && procedure.isArrival === 0
    },
    procedurePercentage(index, procedure) {
      if (this.tableData[index].allWorkOrderTaskList) {
        const { reportWorkQuantity, sourceQuantity, isDeparture } = procedure
        if (isDeparture || !reportWorkQuantity || !sourceQuantity) return 0
        return (reportWorkQuantity / sourceQuantity) * 100
      }
      return 0
    },
    getLeft(index) {
      if (this.tableData[index].status === 0) return 0
      if (this.tableData[index].currentProcedure && this.tableData[index].allWorkOrderTaskList) {
        const currentProcedureId = JSON.parse(this.tableData[index].currentProcedure)[0].procedureUuid
        const currentProcedureIndex = this.tableData[index].allWorkOrderTaskList.findIndex(item => item.procedureUuid === currentProcedureId)
        return currentProcedureIndex > 6 ? (currentProcedureIndex - 6) * 162 : 0
      }
      return 0
    },
    reloadData(areaIds = '') {
      this.areaIds = areaIds
      this.list()
      this.workOrderQuality()
    },
    list() {
      this.getTablePutData(baseUrl + 'getInspectionListByLine', {
        idList: this.areaIds ? this.areaIds.split('^') : [],
        inspectionTypeList: this.checkedList
      }, {
        key: 'scrollBoardConfig',
        key2: 'tableData',
        columns: this.scrollBoardConfig.header,
        columnWidth: [70, 200, 150, 160, 200, 200, 200, 150, 220],
        rowNum: 10,
        columnsKey: ['index', 'number', 'slot:inspectionType', 'slot:status', 'materialCode', 'materialName', 'materialSpecifications', 'creator', 'createTime']
      }, 1)
    },
    async workOrderQuality() {
      const res = await api.getTablePutData(baseUrl + 'getInspectionStatusCountByLine', {
        idList: this.areaIds ? this.areaIds.split('^') : []
      })
      if (res) {
        this.digital.waitQuality = res[0]
        this.digital.ingQuality = res[1]
        this.digital.haveQuality = res[2]
      }
    },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.tableHeight = (window.innerHeight * 0.7) / scale
    }
  }
}
</script>

<style scoped lang="scss">
@import "./common";
.popover-content-footer {
  margin-bottom: 10px;
  position: relative;
  left: -12px;
  max-height: 380px;
  overflow-y: auto;
  .el-checkbox-group {
    overflow: hidden;
    .el-checkbox {
      width: 100%;
      padding: 8px 8px 8px 28px;
      cursor: pointer;
      &:hover {
        background: #ECF0F4;
      }
    }
  }
}
.work-order-progress-container {
  padding: 0 20px 20px 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  .quanlityType {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .img {
      width: 20px;
      height: 16px;
      display: block;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    >div {
      width: 580px;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 120px;
      .card-top {
        width: 200px;
        height: 120px;
        color: #fff;
        text-align: center;
        font-size: 27px;
        line-height: 120px;
      }
      .card-top1 {
        background: rgba(157, 71, 20, .4);
      }
      .card-top2 {
        background: rgba(0, 118, 31, .4);
      }
      .card-top3 {
        background: rgba(58, 33, 166, .4);
      }
      .card-bottom {
        width: 380px;
        height: 120px;
        text-align: center;

      }
      .card-bottom1 {
        background: rgba(157, 71, 20, 1);
      }
      .card-bottom2 {
        background: rgba(0, 118, 31, 1);
      }
      .card-bottom3{
        background: rgba(58, 33, 166, 1);
      }

    }
  }
  .bottom {
    margin-top: 23px;
    .progress-line-wrapper {
      white-space: nowrap;
      .progress-wrap {
        width: 162px;
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        ::v-deep .el-progress {
          position: relative;
          top: 1px;
        }
        .progress-text {
          position: absolute;
          top: 1px;
          left: 0;
          right: 0;
          bottom: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            white-space: nowrap;
            transform: scale(.8);
            color: #607FFF;
          }
        }
        &.active-procedure {
          border: 2px solid #607FFF;
          background: #0F2067;
          .procedure-name {
            color: #FFFFFF;
          }
          .progress-text {
            span {
              color: #FFFFFF;
            }
          }
        }
        &:not(:last-child) {
          &::after {
            content: "";
            display: inline-block;
            height: 1px;
            background: #2E4398;
            width: 88px;
            position: absolute;
            top: 39%;
            left: 118px;
          }
        }
        &.wait-procedure {
          .procedure-name {
            color: #999999 !important;
          }
          .progress-text {
            span {
              color: #999999 !important;
            }
          }
          &:not(:last-child) {
            &::after {
              content: "";
              display: inline-block;
              height: 1px;
              background: #999;
              width: 88px;
              position: absolute;
              top: 39%;
              left: 118px;
            }
          }
        }
      }
      .procedure-name {
        width: 100%;
        font-size: 14px !important;
        text-align: center;
        position: relative;
        top: 1px;
        color: #607FFF;
      }
    }
  }
}
::v-deep .dv-scroll-board .ceil {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  >span {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.status-board {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  text-align: left;
  line-height: 85px;
}
</style>
