<template>
  <div class="materials-wrap">
    <div class="bottom">
      <Split :offset.sync="split.offset" :height="splitHeight">
        <template slot="left">
          <FaultClass @nodeClick="setMaterialsClassId" />
        </template>
        <template slot="right">
          <div class="top">
            <SearchForm :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" />
          </div>
          <div class="materials-info">
            <FunctionHeader
              ref="functionHeader"
              v-model="functionHeader.searchVal"
              search-title="经验编号/名称/故障编码"
              :tags="functionHeader.tags"
              @search="$refs.mTable.setTableData(true)"
            />
            <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
              <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
              <el-button slot="code" slot-scope="{ row }" style="white-space: pre-wrap;" type="text" @click="modifyOrCopy(row, 'view')">{{ row.code }}</el-button>
              <div slot="action" slot-scope="{ row }">
                <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
                <el-divider v-if="permission('Modify')" direction="vertical" />
                <el-button v-if="permission('Del')" type="text" @click="del(row)">删除</el-button>
              </div>
            </MTable>
          </div>
        </template>
      </Split>
    </div>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns } from './columns'
import api from '@/api/xiewei/knowledge-base/failure-experience'
import { searchFormList } from './form-list'
import { getUUid, setTreeData } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import Split from '@/components/Split/Split'
import FaultClass from '@/components/FaultClass/FaultClass'
import conventionMixin from '@/mixin/conventional-page'
import uApi from '@/api/sets/user/user'
import equipmentClassApi from '@/api/information/equipment-class'

export default {
  name: 'FailureExperienceList',
  components: { FaultClass, Split, FunctionHeader, SearchForm },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      searchKeyword: true,
      params: {
        radio: 'FaultExperience'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.addFaultExperience
            }
          }
        ]
      },
      columns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      split: {
        offset: 15
      },
      faultExperienceClassId: '',
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      getTableDataParams: {}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 40
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 75
    }
  },
  mounted() {
    this.getUser()
    this.getEquipmentClassList()
  },
  methods: {
    // 获取设备类
    async getEquipmentClassList() {
      const res = await equipmentClassApi.getEquipmentClassList()
      if (res) {
        const equipmentClassList = setTreeData(res)
        this.searchFormList[0].props.options = equipmentClassList
      }
    },
    // 获取系统用户
    async getUser() {
      const res = await uApi.getUserList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            },
            {
              id: getUUid(),
              fieldName: 'isSystemUser',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.searchFormList[1].children[0].options = res
      }
    },
    setMaterialsClassId(id) {
      this.faultExperienceClassId = id
      this.getTableDataParams.classId = id
      this.$refs.mTable.setTableData(true)
    },
    searchTable(data) {
      this.searchFormatData = data.length > 0 ? data.filter(item => item.fieldName !== 'equipmentClassIds') : []
      const ids = data.length > 0 ? data.filter(item => item.fieldName === 'equipmentClassIds') : []
      this.getTableDataParams.equipmentClassIds = ids.length > 0 ? ids[0].fieldValue.map(item => this._.last(item)).join('^') : ''
      this.$refs.mTable.setTableData(true)
    },
    addFaultExperience() {
      this.$router.push({ name: 'addFailureExperience', query: { type: 'add' }})
    },
    modifyOrCopy(row, command) {
      this.$router.push({ name: 'addFailureExperience', query: { id: row.id, type: command }})
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.materials-wrap {
  width: 100%;

  .bottom {
    width: 100%;
    height: 100%;
    padding: 10px;

    .materials-info {
      padding: 10px;
    }
  }
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
::v-deep {
  .params-card {
    .body {
      padding: 0 !important;
    }
    .params {
      width: 100% !important;
    }
    .el-form-item__label {
      padding: 0 12px 0 0 !important;
    }
  }
}
</style>
