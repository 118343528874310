var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "materials-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "FieldSet" } }, [
            _vm._v("设备台账")
          ])
        ],
        1
      ),
      _c("FunctionHeader", {
        attrs: { tags: _vm.functionHeader.tags, "hidden-search": true },
        on: {
          search: function($event) {
            return _vm.$refs.mTable.setTableData(true)
          }
        },
        model: {
          value: _vm.functionHeader.searchVal,
          callback: function($$v) {
            _vm.$set(_vm.functionHeader, "searchVal", $$v)
          },
          expression: "functionHeader.searchVal"
        }
      }),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          "set-data-method": _vm.getTableData,
          "columns-setting": false
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var row = ref.row
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "type",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(
                  " " + _vm._s(row.type === 0 ? "自定义" : "系统预置") + " "
                )
              ])
            }
          },
          {
            key: "filedType",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(" " + _vm._s(_vm.paramsType[row.filedType].name) + " ")
              ])
            }
          },
          {
            key: "search",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(" " + _vm._s(row.search === 1 ? "开启" : "关闭") + " ")
              ])
            }
          },
          {
            key: "required",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(" " + _vm._s(row.required === 1 ? "是" : "否") + " ")
              ])
            }
          },
          {
            key: "isEnabled",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(
                  " " + _vm._s(row.isEnabled === 1 ? "启用" : "停用") + " "
                )
              ])
            }
          },
          {
            key: "defaultValue",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(" " + _vm._s(row.defaultValue || "-") + " ")
              ])
            }
          },
          {
            key: "filedProperty",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(" " + _vm._s(row.filedProperty || "-") + " ")
              ])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.modifyOrCopy(row, "copy")
                        }
                      }
                    },
                    [_vm._v("修改")]
                  ),
                  row.type === 0
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  row.type === 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.dels(row)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType], width: "550px" },
          on: { onOk: _vm.submitForms },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: {
                "label-position": "right",
                "label-width": "100px",
                "form-data": _vm.formData,
                "form-list": _vm.formList,
                "custom-rules": _vm.rules
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "filedProperty" }, slot: "filedProperty" },
                [
                  _vm.planTags.length > 0
                    ? _c(
                        "draggable",
                        _vm._b(
                          {
                            staticClass: "plan-drag-container",
                            on: {
                              start: function($event) {
                                _vm.isDragging = true
                              },
                              end: _vm.dragEnd
                            },
                            model: {
                              value: _vm.planTags,
                              callback: function($$v) {
                                _vm.planTags = $$v
                              },
                              expression: "planTags"
                            }
                          },
                          "draggable",
                          _vm.dragOptions,
                          false
                        ),
                        _vm._l(_vm.planTags, function(dragTag, index) {
                          return _c("div", { key: index }, [
                            _c("div", [
                              _c("i", { staticClass: "el-icon-rank" })
                            ]),
                            _c("div", [_vm._v(_vm._s(dragTag.name))]),
                            _c("div", { staticClass: "del" }, [
                              _c("i", {
                                staticClass: "el-icon-delete icons",
                                on: {
                                  click: function($event) {
                                    return _vm.deleteTag(index)
                                  }
                                }
                              })
                            ])
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticClass: "drag-btns",
                      attrs: {
                        type: "primary",
                        size: "small",
                        disabled: _vm.planTags.length === 20
                      },
                      on: { click: _vm.addName }
                    },
                    [_vm._v("添加选项")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "numberRange" }, slot: "numberRange" },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "43%" },
                    attrs: {
                      max: _vm.numberMax === 0 ? undefined : _vm.numberMax,
                      "controls-position": "right",
                      clearable: ""
                    },
                    model: {
                      value: _vm.numberMin,
                      callback: function($$v) {
                        _vm.numberMin = $$v
                      },
                      expression: "numberMin"
                    }
                  }),
                  _c("span", { staticStyle: { margin: "0 10px" } }, [
                    _vm._v("-")
                  ]),
                  _c("el-input-number", {
                    staticStyle: { width: "43%" },
                    attrs: {
                      min: _vm.numberMin,
                      "controls-position": "right",
                      clearable: ""
                    },
                    model: {
                      value: _vm.numberMax,
                      callback: function($$v) {
                        _vm.numberMax = $$v
                      },
                      expression: "numberMax"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "添加选项", width: "500px" },
          on: { onOk: _vm.submitFormName },
          model: {
            value: _vm.nameVisible,
            callback: function($$v) {
              _vm.nameVisible = $$v
            },
            expression: "nameVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuilds",
            attrs: {
              "label-position": "right",
              "form-data": _vm.formDataName,
              "form-list": _vm.formListName,
              "custom-rules": _vm.nameRules
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }