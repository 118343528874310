export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'workOrderCode',
    label: '工单编号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'workOrderCode'
  },
  {
    prop: 'demandCode',
    label: '关联需求',
    sortable: false,
    hiddenSearch: true,
    slotName: 'demandCode'
  },
  {
    prop: 'consumer',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150
  },
  {
    prop: 'changeType',
    label: '类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'changeType',
    minWidth: 150
  },
  {
    prop: 'equipmentCode',
    label: '客户机台编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentName',
    label: '客户机台名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'upFacilityCode',
    label: '换上设备编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'upFacilityName',
    label: '换上设备名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'upFacilitySn',
    label: '换上设备SN',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'facilityCode',
    label: '换下设备编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'facilityName',
    label: '换下设备名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'facilitySn',
    label: '换下设备SN',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'changeWorkRecord',
    label: '工作记录',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'executionStartTime',
    label: '服务时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'executionStartTime',
    width: 280
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'createTime'
  },
  {
    prop: 'executorName',
    label: '执行人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 180
  }
]
