<template>
  <div class="shift-wrap">
    <div class="top">
      <SearchForm ref="searchForm" :form-list-extend="searchFormList" @search="searchTable" />
    </div>
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入工作内容"
        :tags="!exportSelectionIds.length ? functionHeader.tags : []"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :show-export="!exportSelectionIds.length ? true : false"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all" v-show="exportSelectionIds.length">
          <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
          <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>

        </div>
      </FunctionHeader>
      <MTable ref="mTable" :columns="getColumns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="customerSiteIdList" slot-scope="{ row }">{{ row.customerName }}</div>

        <div v-if="!row.dateType && row.dateType !==0" slot="jobType" slot-scope="{ row }">{{ getJobType(row) }}</div>
        <div v-else slot="jobType" slot-scope="{ row }">{{ getJobType(row) }} - {{ getDateType(row) }}</div>

        <div v-if="permission('SuperPermission')" slot="action" slot-scope="{ row }">
          <el-button type="text" @click="modifys(row, 'modify')">修改</el-button>
          <el-divider direction="vertical" />
          <el-button type="text" @click="del(row)">删除</el-button>
        </div>

        <div v-else-if="fixUsers(row.updater, row.creatorId)" slot="action" slot-scope="{ row }">
          <el-button v-if="permission('Modify')" type="text" @click="modifys(row, 'modify')">修改</el-button>
          <el-divider v-if="permission('Modify')" direction="vertical" />
          <el-button v-if="permission('Del')" type="text" @click="del(row)">删除</el-button>
          <div v-else slot="action">{{ '-' }}</div>
        </div>
        <div v-else slot="action">{{ '-' }}</div>
      </MTable>
    </div>
    <MDialog v-model="visible" :title="messages[currentType]" width="500px" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="getFormList" label-position="right" class="extra-froms">
        <div slot="manHour">{{ manHour || 0 }}小时</div>
      </MFormBuilder>
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/xiewei/work-management/daily-report'
import { getFunctionList, columns } from './columns'
import { formList, searchFormList } from './form-list'
import keApi from '@/api/information/unit'
import { getUUid, setTreeData } from '@/utils'
import SearchForm from '@/components/SearchForm/SearchForm'
import uApi from '@/api/sets/user/user'
import { Encrypt } from '@/utils/sercet'
import { jobTypes, dateTypes } from '@/config/options.sales'
import dayjs from 'dayjs'

export default {
  name: 'DailyReportList',
  components: { FunctionHeader, SearchForm },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      searchFormList,
      params: {
        radio: 'DailyWorkReport'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.adds
            }
          }
        ],
        exportName:'exportDailyWorkReport',
        exportParams: {}
      },
      searchFieldName: 'jobContent',
      extraParams: [
        // {
        //   id: getUUid(),
        //   fieldName: 'orderType',
        //   fieldType: 'number',
        //   fieldValue: 10,
        //   operator: 'eq'
        // }
      ],
      columns,
      visible: false,
      formList: formList,
      formData: {},
      currentType: 'add',
      formOptions: {},
      allCustomer: [],
      jobTypes,
      dateTypes,
      manHour: 0,
      typeValue: '',
      selectionData: [],
      exportSelectionIds: [],
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 25
    },
    getJobType() {
      return row => {
        const { jobType } = row
        return (jobTypes.find(item => item.id === jobType) || {}).name
      }
    },
    getDateType() {
      return row => {
        const { dateType } = row
        return (dateTypes.find(item => item.id === dateType) || {}).name
      }
    },
    getFormList() {
      if (this.typeValue !== 11) {
        return this.formList.filter(item => {
          return item.key !== 'dateType'
        })
      } else {
        return this.formList
      }
    },
    getColumns() {
      return this.columns.filter(item => {
        return item.prop !== 'dateType'
      })
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  beforeMount() {
    getFunctionList({
      mulChange: this.mulChange,
      valueChange: this.valueChange,
      jobChange: this.jobChange
    })
  },
  mounted() {
    this.getAllCustomer()
    this.getSystemUser()
  },
  methods: {
    allMore(command) {
      const functions = {
        batchExport: () => { this.batchExport() },
      }
      functions[command]()
    },
    selectionChange(data) {
      this.selectionData = data
      this.exportSelectionIds = data.map(item => item.id)
    },
    resetSelectData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },

    fixUsers(name, val) {
      if (val !== null && val !== undefined) {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        return +val === userInfo.id
      } else {
        return false
      }
    },
    adds() {
      this.typeValue = ''
      this.manHour = 0
      this.add()
    },
    modifys(row, command) {
      this.formData = {
        ...row,
        customerSiteIdList: row.customerSiteIdList ? row.customerSiteIdList.split('^').map(item => Number(item)) : []
      }
      this.typeValue = row.jobType
      this.continuousAdd = false
      this.currentType = command
      this.manHour = row.manHour
      this.visible = true
    },
    // 获取系统用户
    async getSystemUser() {
      const res = await uApi.getUserList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            },
            {
              id: getUUid(),
              fieldName: 'isSystemUser',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.searchFormList[3].children[0].options = res
      }
    },
    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        let data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        data = [
          {
            id: 0,
            name: '公司',
            isCustomer: 0,
            parentId: -1
          },
          ...data
        ]
        this.allCustomer = data
        this.formList[1].props.options = setTreeData(data)
        this.searchFormList[1].props.options = setTreeData(data)
      }
    },
    mulChange(val) {
      const arr = this.allCustomer.filter(item => item.id === this._.first(val))
      const list = this.allCustomer.filter(item => item.id === this._.last(val))
      this.$refs.formBuild.setForm({
        customerId: +list[0].isCustomer === 0 ? list[0].id : arr[0].id,
        customerSiteId: +list[0].isCustomer === 1 ? list[0].id : '',
        customerName: list[0].name
      })
    },
    valueChange(key) {
      if (key === 'startTime' || key === 'endTime') {
        const { form: formBuild } = this.$refs.formBuild
        const { startTime, endTime } = formBuild
        if (startTime && endTime) {
          const minutes = dayjs(`2023-02-02 ${formBuild.startTime}`).diff(dayjs(`2023-02-02 ${formBuild.endTime}`), 'minutes')
          if (minutes >= 0) {
            this.$refs.formBuild.setForm({
              startTime: endTime,
              endTime: startTime
            })
          }
          const hour = (Math.abs(minutes) / 60).toFixed(1)
          this.$refs.formBuild.setForm({
            manHour: hour
          })
          this.manHour = hour
        } else {
          this.$refs.formBuild.setForm({
            manHour: 0
          })
          this.manHour = 0
        }
      }
    },
    jobChange(val) {
      if (val === 11) {
        this.typeValue = val
      } else {
        this.typeValue = ''
      }
    },
    createSetData(raw) {
      return {
        time: raw.time,
        customerId: raw.customerId,
        customerSiteId: raw.customerSiteId,
        customerSiteIdList: raw.customerSiteIdList ? raw.customerSiteIdList.join('^') : '',
        customerName: raw.customerName,
        jobType: raw.jobType,
        dateType: raw.jobType === 11 ? raw.dateType : null,
        jobContent: raw.jobContent,
        startTime: raw.startTime,
        endTime: raw.endTime,
        manHour: raw.manHour,
        remark: raw.remark
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    searchTable(data) {
      const ids = data.map(item => item.fieldName)
      const hasIndex = ids.indexOf('customerName')
      this.searchFormatData = this._.cloneDeep(data)
      if (hasIndex !== -1) {
        this.searchFormatData.splice(hasIndex, 1)
        const id = data[hasIndex].fieldValue
        const arr = this.allCustomer.filter(item => item.id === this._.first(id))
        const list = this.allCustomer.filter(item => item.id === this._.last(id))
        this.searchFormatData = [
          ...this.searchFormatData,
          {
            id: getUUid(),
            fieldName: list[0].isCustomer === 1 ? 'customerSiteId' : 'customerId',
            fieldType: 'number',
            fieldValue: list[0].isCustomer === 1 ? list[0].id : arr[0].id,
            operator: 'eq'
          }
        ]
      }
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
.extra-froms ::v-deep {
  .el-form-item {
    margin-bottom: 18px !important;
  }
}
.shift-wrap {
  padding: 10px;
  .function-header-wrap {
    padding-top: 0;
  }
  .bottom {
    padding: 10px;
  }
}
</style>
