export const columns = [
  {
    prop: 'workOrderCode',
    label: '工单编号',
    hiddenSearch: true
  },
  {
    prop: 'facilityCode',
    label: '设备编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'facilityName',
    label: '设备',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'facilitySn',
    label: 'SN',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'facilityPlanCode',
    label: '保养计划',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'level',
    label: '保养级别',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planExecutionStartTime',
    label: '计划执行时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]

export const descColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'faultTime',
    label: '故障时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'faultTime',
    width: 250
  },
  {
    prop: 'faultCode',
    label: '故障码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'faultCode',
    width: 250
  },
  {
    prop: 'detailedDeion',
    label: '详细描述',
    sortable: false,
    hiddenSearch: true,
    slotName: 'detailedDeion'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80
  }
]

export const marterialColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsType',
    label: '物料分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    slotName: 'materialsType'
  },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 220,
    slotName: 'materialsSpecifications'

  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'batchNumber',
    label: '批次',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    slotName: 'warehouseName'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 240,
    slotName: 'warehouseLocationName'
  },
  {
    prop: 'quantity',
    label: '可用库存',
    slotName: 'quantity',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'usageQuantity',
    label: '使用数量',
    slotName: 'usageQuantity',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'ownershipSparePartsAssets',
    label: '备件资产所属',
    slotName: 'ownershipSparePartsAssets',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80
  }
]

export const costColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialType',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'usageQuantity',
    label: '使用数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'assetBelongs',
    label: '资产所属',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'costBelongs',
    label: '费用归属',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'followUpProcessing',
    label: '后续处理',
    sortable: false,
    hiddenSearch: true,
    slotName: 'followUpProcessing'
  }
]
