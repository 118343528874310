var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "procedure-detail-wrappers" }, [
    _c("div", { staticClass: "procedure-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "procedure-detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c("div", { staticClass: "card-bot" }, [
          _vm.params.type !== "view"
            ? _c(
                "div",
                [
                  _c(
                    "MFormBuilder",
                    {
                      ref: "baseForm",
                      attrs: {
                        "form-list": _vm.formList,
                        "form-data": _vm.baseFormData,
                        "label-position": "right"
                      }
                    },
                    [
                      _c(
                        "el-upload",
                        _vm._b(
                          {
                            attrs: {
                              slot: "drawing",
                              "file-list": _vm.attachments
                            },
                            slot: "drawing"
                          },
                          "el-upload",
                          _vm.uploadProps2,
                          false
                        ),
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "primary", size: "small" } },
                            [_vm._v("上传附件")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "view-list",
                      staticStyle: { padding: "10px 15px", color: "#393D60" },
                      attrs: { gutter: 24 }
                    },
                    _vm._l(_vm.addColumns, function(item) {
                      return _c(
                        "el-col",
                        {
                          key: item.prop,
                          style: { marginBottom: "20px" },
                          attrs: {
                            span: [
                              "faultCode",
                              "solution",
                              "improvementMeasure",
                              "attachment"
                            ].includes(item.prop)
                              ? 24
                              : 6
                          }
                        },
                        [
                          _c("div", { staticStyle: { color: "#9597AE" } }, [
                            _vm._v(_vm._s(item.label))
                          ]),
                          item.prop === "attachment"
                            ? _c(
                                "div",
                                _vm._l(_vm.attachments, function(items) {
                                  return _c(
                                    "a",
                                    {
                                      key: items.id,
                                      staticStyle: {
                                        display: "block",
                                        color: "#607FFF"
                                      },
                                      attrs: {
                                        href: items.url,
                                        download: items.name,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(_vm._s(items.name))]
                                  )
                                }),
                                0
                              )
                            : _c("div", { staticClass: "bot-tis" }, [
                                _vm._v(_vm._s(_vm.getValue(item) || "-"))
                              ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
        ])
      ])
    ]),
    this.params.type !== "view"
      ? _c("div", { staticClass: "order-footer" }, [
          _c("div", { staticClass: "footer-le" }),
          _c(
            "div",
            { staticClass: "footer-ri" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.returns } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.buttonLoading,
                    size: "small"
                  },
                  on: { click: _vm.submitReForm }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }