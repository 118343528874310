var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-form-inline",
                attrs: {
                  "label-position": "right",
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "98px"
                }
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "订单编号", prop: "code" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.ruleForm.code,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "code", $$v)
                                },
                                expression: "ruleForm.code"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户", prop: "customerId" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.ruleForm.customerId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "customerId", $$v)
                                  },
                                  expression: "ruleForm.customerId"
                                }
                              },
                              _vm._l(_vm.customerIdOptions, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "客户订单号",
                              prop: "customerOrderNumber"
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { maxlength: 30, "show-word-limit": "" },
                              model: {
                                value: _vm.ruleForm.customerOrderNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "customerOrderNumber",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.customerOrderNumber"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "交付类型" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.ruleForm.deliveryType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "deliveryType", $$v)
                                  },
                                  expression: "ruleForm.deliveryType"
                                }
                              },
                              _vm._l(_vm.deliveryTypeOptions, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.ruleForm.deliveryType === 0
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "交付日期",
                                  prop: "deliveryTime"
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "datetime",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    placeholder: "选择日期"
                                  },
                                  model: {
                                    value: _vm.ruleForm.deliveryTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "deliveryTime",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.deliveryTime"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "负责人", prop: "principalId" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.ruleForm.principalId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "principalId", $$v)
                                  },
                                  expression: "ruleForm.principalId"
                                }
                              },
                              _vm._l(_vm.principalIdOptions, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.userFullName,
                                    value: item.id
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                placeholder: "请输入内容"
                              },
                              model: {
                                value: _vm.ruleForm.remark,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "remark", $$v)
                                },
                                expression: "ruleForm.remark"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "bot-li" }, [
                        _c("div", { staticClass: "li-tit" }, [
                          _vm._v("附件：")
                        ]),
                        _c(
                          "div",
                          { staticClass: "li-des" },
                          [
                            _c(
                              "el-upload",
                              _vm._b(
                                {
                                  ref: "upload",
                                  staticClass: "upload-demo",
                                  style: {
                                    width:
                                      _vm.fileList.length > 3
                                        ? "140px"
                                        : "150px"
                                  },
                                  attrs: {
                                    "file-list": _vm.fileList.slice(
                                      0,
                                      _vm.textFlg ? _vm.fileList.length : 3
                                    )
                                  }
                                },
                                "el-upload",
                                _vm.uploadProp,
                                false
                              ),
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "small", type: "primary" } },
                                  [_vm._v("点击上传")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.fileList.length > 3,
                                    expression: "fileList.length > 3"
                                  }
                                ],
                                staticClass: "more",
                                on: {
                                  click: function($event) {
                                    _vm.textFlg = !_vm.textFlg
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.textFlg ? "收起" : "展开") +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("货品清单")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-button",
              {
                staticClass: "e-btn",
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.selectAdd }
              },
              [_vm._v("添加物料")]
            ),
            _c("MTable", {
              ref: "mTable",
              attrs: {
                "only-key": "uuid",
                height: 400,
                "show-page": false,
                data: _vm.orderList,
                columns: _vm.columnsOrder,
                "columns-setting": false
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "code",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c("NewPageOpen", {
                          attrs: {
                            path: {
                              name: "materialsDetail",
                              query: { id: row.id }
                            },
                            text: row.code
                          }
                        })
                      ],
                      1
                    )
                  }
                },
                {
                  key: "orderQuantity",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c("el-input-number", {
                          attrs: {
                            min: 0,
                            "controls-position": "right",
                            step: 1
                          },
                          on: {
                            blur: function($event) {
                              row.orderQuantity = _vm.sliceVal(
                                row.orderQuantity,
                                5
                              )
                            },
                            change: function($event) {
                              return _vm.changeAmount(row)
                            }
                          },
                          model: {
                            value: row.orderQuantity,
                            callback: function($$v) {
                              _vm.$set(row, "orderQuantity", $$v)
                            },
                            expression: "row.orderQuantity"
                          }
                        })
                      ],
                      1
                    )
                  }
                },
                {
                  key: "unitPrice",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c("el-input-number", {
                          attrs: {
                            min: 0,
                            "controls-position": "right",
                            step: 1
                          },
                          on: {
                            blur: function($event) {
                              row.unitPrice = _vm.sliceVal(row.unitPrice, 5)
                            },
                            change: function($event) {
                              return _vm.changeAmount(row)
                            }
                          },
                          model: {
                            value: row.unitPrice,
                            callback: function($$v) {
                              _vm.$set(row, "unitPrice", $$v)
                            },
                            expression: "row.unitPrice"
                          }
                        })
                      ],
                      1
                    )
                  }
                },
                {
                  key: "deliveryTime",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c("el-date-picker", {
                          style: {
                            border: row.isWarning ? "1px solid #F56C6C" : "",
                            borderRadius: "4px"
                          },
                          attrs: {
                            type: "datetime",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            placeholder: "选择日期"
                          },
                          model: {
                            value: row.deliveryTime,
                            callback: function($$v) {
                              _vm.$set(row, "deliveryTime", $$v)
                            },
                            expression: "row.deliveryTime"
                          }
                        })
                      ],
                      1
                    )
                  }
                },
                {
                  key: "remark",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c("el-input", {
                          model: {
                            value: row.remark,
                            callback: function($$v) {
                              _vm.$set(row, "remark", $$v)
                            },
                            expression: "row.remark"
                          }
                        })
                      ],
                      1
                    )
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return _c(
                      "div",
                      {},
                      [
                        _vm.ruleForm.deliveryType !== 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "li-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function() {
                                    return _vm.orderList.splice(
                                      $index + 1,
                                      0,
                                      _vm._.cloneDeep(row)
                                    )
                                  }
                                }
                              },
                              [_vm._v("复制")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticClass: "li-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function() {
                                return _vm.orderList.splice($index, 1)
                              }
                            }
                          },
                          [_vm._v("移除")]
                        )
                      ],
                      1
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v("取消")
            ]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.save } },
              [_vm._v("确定")]
            )
          ],
          1
        )
      ]),
      _c("select-materials", {
        ref: "selectMaterials",
        attrs: {
          "select-materials": _vm.orderList,
          visible: _vm.showMaterials,
          "is-producible": true,
          "is-add": true
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials,
          addMaterial: _vm.addMaterial
        }
      }),
      _c("edit-materials", {
        ref: "editMaterials",
        on: { onSuccess: _vm.onSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }