<template>
  <div class="chart-search-condition">
    <el-button class="search-setting" type="text" @click="showCondition">
      <img :src="isShowCondition ? filterActive : filter" alt="" width="14">
    </el-button>
    <div v-if="isShowCondition" class="condition-wrapper">
      <template v-for="(search, index) in tabInfo.searches">
        <div v-if="search.type === 'select'" :key="index">
          <div class="title">{{ search.placeholder.split('请选择')[1] }}</div>
          <el-select
            v-model="searchForm[search.asKey || search.key]"
            size="small"
            filterable
            clearable
            style="width: 100%"
            :placeholder="search.placeholder"
            v-bind="search.props || {}"
            @change="() => { selectChange(false, search) }"
            @clear="clearSelect(search)"
          >
            <el-option
              v-for="item in search.options || formOptions[search.dataKey]"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <div v-if="search.type === 'materials'" :key="index">
          <div class="title">物料</div>
          <el-button size="small" type="primary" @click="showMaterials(search)">选择物料</el-button>
          <div v-if="searchForm[current.key]">
            <el-tag v-for="(materials, materialsIndex) in searchForm[current.key]" :key="materialsIndex" style="margin: 10px 10px 0 0">{{ materials.name }}</el-tag>
          </div>
        </div>
        <div v-if="search.type === 'workOrder'" :key="index">
          <div class="title">工单</div>
          <el-button size="small" type="primary" @click="showWorkOrder(search)">选择工单</el-button>
          <div v-if="searchForm[current.key]">
            <el-tag v-for="(workOrder, workOrderIndex) in searchForm[current.key]" :key="workOrderIndex" style="margin: 10px 10px 0 0">{{ workOrder.produceWorkOrderNumber }}</el-tag>
          </div>
        </div>
        <div v-if="search.type === 'dateRange'" :key="index">
          <div class="title required-div">日期范围</div>
          <date-range
            :icon-show="false"
            :format-date-type="search.formatDateType"
            :can-no-select="true"
            @dateRangeChange="dateRangeChange"
          />
        </div>
        <div v-if="search.type === 'cascader'" :key="index">
          <div class="title">{{ search.placeholder.split('请选择')[1] }}</div>
          <el-cascader
            ref="cascader"
            v-model="searchForm[search.asKey || search.key]"
            size="mini"
            filterable
            clearable
            style="width: 100%"
            :placeholder="search.placeholder"
            :options="search.options || formOptions[search.dataKey]"
            v-bind="search.props"
            @change="() => { cascaderChange(false, search) }"
          />
        </div>
      </template>
    </div>
    <select-materials
      v-if="materialsLoad"
      :visible.sync="materialsShow"
      :select-materials="searchForm[current.key]"
      @submitForm="materialsChange"
    />
    <select-work-order
      v-if="workOrderLoad"
      :visible.sync="workOrderShow"
      :single="true"
      @submitForm="submitWorkOrder"
    />
  </div>
</template>

<script>
import DateRange from '../custom-table/date-range.vue'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials'
import api from '@/api/statistics-board/all-statistics'
import { setTreeData } from '@/utils'
import filter from '@/assets/statistics-board/高级筛选@2x.png'
import filterActive from '@/assets/statistics-board/高级筛选Active@2x.png'
import SelectWorkOrder from '@/components/SelectWorkOrder/SelectWorkOrder'
export default {
  name: 'ChartSearchConditionHasTitle',
  components: { SelectWorkOrder, SelectMaterials, DateRange },
  props: {
    tabInfo: {
      type: Object,
      default: () => ({
        searches: []
      })
    }
  },
  data() {
    return {
      filter,
      filterActive,
      searchForm: {},
      searchCondition: {},
      formOptions: {},
      materialsShow: false,
      current: {},
      isShowCondition: true,
      workOrderShow: false,
      materialsLoad: false,
      workOrderLoad: false
    }
  },
  beforeMount() {
    this.getOptions()
  },
  methods: {
    getOptions() {
      if (!this.tabInfo.searches) return false
      this.tabInfo.searches.forEach(item => {
        if (item.dataKey) {
          this.setOptions(item)
        }
        // if (item.defaultValue || item.defaultValue === 0) {
        //   this.searchForm[item.asKey || item.key] = item.defaultValue
        // }
      })
    },
    async setOptions(condition) {
      const res = await api.getData(condition.dataUrl, condition.dataCondition)
      if (res) {
        if (condition.type === 'cascader') {
          this.$set(this.formOptions, condition.dataKey, setTreeData(res))
        } else {
          this.$set(this.formOptions, condition.dataKey, res)
        }
        this.$forceUpdate()
      }
    },
    async getEquipmentListByEquipmentClassIds(equipmentClassIds) {
      const res = await api.getData('equipment/private/equipment/getEquipmentList', {
        equipmentClassIdList: equipmentClassIds
      })
      if (res) {
        this.formOptions.equipmentList = res
        this.$forceUpdate()
      }
    },
    showMaterials(search) {
      this.current = search
      this.materialsLoad = true
      this.materialsShow = true
    },
    showWorkOrder(search) {
      this.current = search
      this.workOrderLoad = true
      this.workOrderShow = true
    },
    showCondition() {
      this.isShowCondition = !this.isShowCondition
    },
    dateRangeChange(dateRange) {
      const [beginTime, endTime] = dateRange
      Object.assign(this.searchCondition, {
        beginTime,
        endTime
      })
      this.$emit('conditionChange', this.searchCondition)
    },
    selectChange(bol, search) {
      this.$forceUpdate()
      if (bol) return
      if ('statisticsType' in search) {
        this.searchCondition.statisticsType = this.searchForm[search.asKey || search.key] && this.searchForm[search.asKey || search.key].length ? search.statisticsType : 1
      }
      const ids = search.props && search.props.multiple ? this.searchForm[search.asKey || search.key].join('^') : this.searchForm[search.asKey || search.key]
      const ecSearch = this.tabInfo.searches.find(item => item.asKey === 'equipmentClassIds')
      if (!ids && ecSearch && search.key === 'equipmentIds') {
        return this.cascaderChange(false, ecSearch)
      }
      Object.assign(this.searchCondition, {
        [search.key]: ids
      })
      this.$emit('conditionChange', this.searchCondition)
    },
    clearSelect(search) {
      delete this.searchCondition[search.key]
      if ('statisticsType' in search) {
        this.searchCondition.statisticsType = 1
      }
      const ecSearch = this.tabInfo.searches.find(item => item.asKey === 'equipmentClassIds')
      if (ecSearch) {
        return this.cascaderChange(false, ecSearch)
      }
      this.$emit('conditionChange', this.searchCondition)
    },
    cascaderChange(bol, search) {
      if (bol) return
      // 对故障卡片来源特殊处理
      if (search.options && search.options[0].key) {
        return this.faultOriginCascaderChange(search)
      }
      const ids = search.props && search.props.props && search.props.props.multiple ? Array.from(new Set(this.searchForm[search.asKey || search.key].reduce((a, b) => a.concat([this._.last(b)]), []))).join('^') : this._.last(this.searchForm[search.asKey || search.key])
      if ('statisticsType' in search) {
        this.searchCondition.statisticsType = this.searchForm[search.asKey || search.key] && this.searchForm[search.asKey || search.key].length ? search.statisticsType : 1
      }
      if (search.asKey) {
        this.getEquipmentListByEquipmentClassIds(ids)
        this.searchForm[search.key] = search.props && search.props.props && search.props.props.multiple ? [] : null
      }
      Object.assign(this.searchCondition, {
        [search.key]: ids
      })
      this.$emit('conditionChange', this.searchCondition)
    },
    faultOriginCascaderChange(search) {
      const value = this.searchForm[search.asKey || search.key]
      const f1 = value.filter(item => item.includes('f-1'))
      const f2 = value.filter(item => item.includes('f-2'))
      if (f1.length && f2.length) {
        Object.assign(this.searchCondition, {
          [search.key]: 0,
          faultStatuses: f1.filter(item => item[1]).map(item => item[1]).join('^'),
          quickStatuses: f2.filter(item => item[1]).map(item => item[1]).join('^')
        })
      } else if (f1.length) {
        Object.assign(this.searchCondition, {
          [search.key]: 1,
          faultStatuses: f1.filter(item => item[1]).map(item => item[1]).join('^'),
          quickStatuses: undefined
        })
      } else {
        Object.assign(this.searchCondition, {
          [search.key]: 2,
          faultStatuses: f2.filter(item => item[1]).map(item => item[1]).join('^'),
          quickStatuses: undefined
        })
      }
      if (!value.length) {
        Object.assign(this.searchCondition, {
          [search.key]: undefined,
          faultStatuses: undefined,
          quickStatuses: undefined
        })
      }
      this.$emit('conditionChange', this.searchCondition)
    },
    materialsChange(val) {
      this.searchForm[this.current.key] = val
      Object.assign(this.searchCondition, {
        [this.current.key]: val.map(item => item.id).join('^')
      })
      this.$emit('conditionChange', this.searchCondition)
      this.materialsShow = false
    },
    submitWorkOrder(val) {
      this.searchForm[this.current.key] = val
      Object.assign(this.searchCondition, {
        [this.current.key]: val.map(item => item.id).join('^')
      })
      this.$emit('conditionChange', this.searchCondition)
      this.workOrderShow = false
    }
  }
}
</script>

<style scoped lang="scss">

.chart-search-condition {
  .search-setting {
    position: absolute;
    right: 0;
    top: -62px;
  }
  .condition-wrapper {
    padding-bottom: 15px;
    .title {
      font-weight: 700;
      font-size: 14px;
      padding: 10px 0;
    }
  }
}
</style>
