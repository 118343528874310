export default [
  {
    type: 'selection',
    fixed: 'left',
    minWidth: 80,
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'number',
    label: '单号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180,
    slotName: 'number'
  },
  {
    prop: 'type',
    label: '库存操作类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'type'
  },
  {
    prop: 'orderNumber',
    label: '关联单号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    sortable: false,
    hiddenSearch: true,
    minWidth: 260
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    minWidth: 260
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 130
  }
]
