<template>
  <div class="detail-wrappers">
    <div class="detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
    </div>
    <div class="detail-body">
      <div class="detail-card">
        <div class="card-tit">基础信息</div>
        <div class="card-bot">
          <MFormBuilder ref="baseForm" :form-list="formList" :form-data="baseFormData" label-position="right">
            <div slot="workOrderCode">
              {{ baseFormData.workOrderCode }}
            </div>
            <div slot="demandCode">
              {{ baseFormData.demandCode }}
            </div>
            <div slot="workOrderType">
              {{ baseFormData.workOrderTypeName }}
            </div>
          </MFormBuilder>
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">关联设备</div>
        <div class="card-bot">
          <RelationEquipment :customer-id="customerId" :customer-site-id="customerSiteId" :list="facilityList" @onConfirm="facilityListChange" @facilityInfo="facilityInfo" />
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">点检项目</div>
        <div class="card-bot">
          <ItemsInfo :list="itemMappingList" :customer-id="customerId" :customer-site-id="customerSiteId" @onConfirm="itemMappingListChange" />
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">现场记录</div>
        <div class="card-bot">
          <MFormBuilder ref="localForm" :form-list="localFormList" :form-data="baseFormData.facilityInspectionFieldNotes" label-position="right">
            <div slot="manHour">{{ manHour }}小时</div>

            <div slot="facilityServiceTimeMappingList">
              <div v-for="(item, index) in executorList" :key="index" class="custom-form">
                <div class="custom-form-item">
                  <el-select v-model="item.userIds" multiple placeholder="添加协作人" filterable>
                    <el-option v-for="q in executorUserList" :key="q.id" :label="q.name" :value="q.id" />
                  </el-select>
                </div>
                <div class="custom-form-item">
                  <el-radio-group v-model="item.isSame" @change="onRadioChange(item.isSame, index)">
                    <el-radio :label="1">服务时间同执行人</el-radio>
                    <el-radio :label="0">
                      <el-date-picker
                        v-model="item.date"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="选择服务开始时间"
                        end-placeholder="选择服务结束时间"
                        value-format="yyyy-MM-dd HH:mm"
                        format="yyyy-MM-dd HH:mm"
                        clearable
                        :disabled="item.isSame === 1"
                        :picker-options="pickerOptions"
                        @change="datePickerChange(index)"
                      />
                    </el-radio>
                  </el-radio-group>
                  <span class="hour">
                    <label>服务时长</label>
                    {{ getTime(item) }}小时
                  </span>
                  <el-button type="text" @click="delExecutorList(index)">删除</el-button>
                </div>
              </div>
              <el-button size="small" type="primary" @click="addExecutor">添加</el-button>
            </div>
            <div slot="facilityInspectionSpotDetectioList">
              <div v-for="(item, index) in localAbnormalList" :key="item.id">
                <el-card class="box-card">
                  <div slot="header" class="clearfix">
                    <span>{{ `#${index + 1}` }}</span>
                    <el-button style="float: right; padding: 3px 0" type="text" @click="delFormList(index)">删除</el-button>
                  </div>
                  <MFormBuilder
                    :key="item.id"
                    ref="localAbnormalForm"
                    :form-list="getLocalAbnormalFormList(item.processMode)"
                    :form-data="item"
                    label-position="right"
                  >
                    <el-select slot="facilityId" v-model="item.facilityId" style="width: 100%" placeholder="选择设备" filterable>
                      <el-option
                        v-for="item in getFacilityNameOptions(index)"
                        :key="item.id"
                        :label="`${item.facilityCode} - ${item.facilityName}`"
                        :value="item.facilityId"
                      />
                    </el-select>
                    <el-select slot="abnormalItem" v-model="item.abnormalItem" multiple style="width: 100%" placeholder="选择异常项目（可多选）">
                      <el-option v-for="item in itemMappingList" :key="item.content" :label="item.content" :value="item.content" />
                    </el-select>
                    <el-input slot="description" v-model="item.description" maxlength="200" placeholder="不超过200字" />
                    <el-select slot="processMode" v-model="item.processMode" style="width: 100%" placeholder="选择处理方式">
                      <el-option label="现场解决" value="现场解决" />
                      <el-option label="待解决-需求" value="待解决-需求" />
                      <el-option label="已解决-工单" value="已解决-工单" />
                    </el-select>
                  </MFormBuilder>
                </el-card>
              </div>
              <el-button size="small" type="primary" @click="addFormList">添加</el-button>
            </div>
            <el-upload slot="drawing" :file-list="attachments" v-bind="uploadProps2">
              <el-button type="primary" size="small">上传文件</el-button>
              <div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .jpg...</div>
            </el-upload>
          </MFormBuilder>
        </div>
      </div>
    </div>
    <div class="detail-footer">
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/xiewei/service/spot-check'
import dayjs from 'dayjs'
import userApi from '@/api/sets/user/user'
import keApi from '@/api/information/unit'
import fieldApi from '@/api/xiewei/field/field'
import { getFunctionList, columns } from './columns'
import { formList, localFormList, localAbnormalFormList, getFormFunctionList, executorFormList } from './form-list'
import { getToken } from '@/utils/auth'
import { setTreeData } from '@/utils'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials'
import SelectMaterialsInventory from '@/components/SelectMaterialsInventory/SelectMaterialsInventory'
import RelationEquipment from '@/views/service/spot-check-add/components/relation-equipment'
import ItemsInfo from '@/views/service/spot-check-add/components/items-info'

export default {
  name: 'AddSpotCheck',
  components: { RelationEquipment, ItemsInfo, EditMaterials, SelectMaterials, SelectMaterialsInventory },
  data() {
    return {
      columns,
      formList,
      localFormList,
      localAbnormalFormList,
      executorFormList,
      baseFormData: {
        facilityInspectionFieldNotes: {}
      },
      params: {},
      messages: {
        add: '提交成功',
        modify: '修改成功',
        addO: '新增点检工单',
        modifyO: '修改点检工单'
      },
      buttonLoading: false,
      executorList: [],
      executorUserList: [],
      localAbnormalList: [],
      uploadProps2: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onRemove: this.removeFile,
        onPreview: this.handlePreview
      },
      attachments: [],
      allCustomer: [],
      customerId: undefined,
      facilityList: [],
      itemMappingList: [],
      manHour: 0,
      customerSiteId: undefined
    }
  },

  computed: {
    getLocalAbnormalFormList() {
      return val => {
        if (val !== '已解决-工单') {
          return this.localAbnormalFormList.filter(item => item.key !== 'maintenanceWorkOrder')
        }
        return this.localAbnormalFormList
      }
    },

    getFacilityNameOptions() {
      return index => {
        const ids = this.localAbnormalList.map((item, i) => (i === index ? '' : item.facilityId))
        const filterOptions = this.facilityList.filter(v => !ids.includes(v.facilityId))
        return filterOptions
      }
    },

    getTime() {
      return item => {
        if (item.isSame === 1) {
          return item.manHour || 0
        } else {
          const { date } = item
          if (date && date.length) {
            const minutes = dayjs(date[0]).diff(dayjs(date[1]), 'minutes')
            const hour = (Math.abs(minutes) / 60).toFixed(1)
            return hour
          } else {
            return 0
          }
        }
      }
    },
    pickerOptions() {
      return {
        disabledDate: (time) => {
          const { form: localForm } = this.$refs.localForm
          return dayjs(time).isBefore(localForm.executionStartTime, 'day') || dayjs(time).isAfter(localForm.executionEndTime, 'day')
        }
      }
    }
  },

  watch: {
    manHour(val) {
      this.executorList = this.executorList.map(item => {
        return {
          ...item,
          manHour: item.isSame === 1 ? val : item.manHour
        }
      })
    }
  },

  beforeMount() {
    getFunctionList({
      serviceChange: this.serviceChange
    })
    getFormFunctionList({
      valueChange: this.valueChange
    })
  },

  mounted() {
    this.params = this.$route.query
    this.createFieldCode()
    this.getAllCustomer()
    this.getUserList()
    if (this.params.demandId) {
      const infos = this.params.infos ? JSON.parse(this.params.infos) : {}
      this.baseFormData = {
        ...this.baseFormData,
        demandCode: this.params.code,
        demandId: parseInt(this.params.demandId),
        consumerId: infos.customerId,
        consumer: infos.customerName,
        customerSiteId: infos.customerSiteId,
        customerSiteIdList: infos.customerSiteIdList.split('^').map(item => Number(item))
      }
      this.customerId = infos.customerId
    }

    if (this.params.type === 'modify') {
      this.getDetail()
    }
  },

  methods: {
    returns() {
      this.$router.back()
    },

    async getDetail() {
      const res = await api.getSpotCheckByDetail(this.params.id)
      if (res) {
        this.baseFormData = {
          ...res,
          facilityInspectionFieldNotes: res.facilityInspectionFieldNotes || {},
          customerSiteIdList: res.customerSiteIdList.split('^').map(item => +item)
        }

        const localInfo = this.baseFormData.facilityInspectionFieldNotes
        if (localInfo.executor) {
          localInfo.executor = localInfo.executor.split('^').map(item => +item)
        }
        if (localInfo.facilityInspectionSpotDetectioList) {
          this.localAbnormalList = localInfo.facilityInspectionSpotDetectioList.map(item => {
            const abnormalItem = item.abnormalItem || ''
            return {
              ...item,
              abnormalItem: this.isView ? abnormalItem.split('^').join('、') : abnormalItem.split('^')
            }
          })
        }

        if (localInfo.facilityServiceTimeMappingList) {
          this.executorList = localInfo.facilityServiceTimeMappingList.map(item => {
            return {
              ...item,
              isSame: item.isSame,
              date: item.isSame === 1 ? [] : [item.startTime, item.endTime],
              userIds: item.userIds ? item.userIds.split('^').map(item => +item) : [],
              manHour: item.manHour
            }
          })
        }

        this.customerId = res.consumerId
        this.attachments = JSON.parse(localInfo.affix || '[]')
        this.facilityList = res.facilityInspectionSpotCheckPlanFacilityList || []
        this.itemMappingList = res.facilityInspectionSpotCheckPlanList || []
        this.setExecutionHours(this.baseFormData.facilityInspectionFieldNotes)
      }
    },

    async createFieldCode() {
      if (this.params.type === 'modify') {
        this.formList[0].props.disabled = true
      } else {
        const res = await fieldApi.getFieldIsExist({
          numberVersion: '点检工单'
        })
        if (res && +res === 0) {
          this.$refs.baseForm.setForm({
            workOrderCode: '系统生成'
          })
          this.formList[0].props.disabled = true
        }
      }
    },

    // 获取客户
    async getUserList() {
      const res = await userApi.getUserList()
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.executorUserList = res.filter(item => item.id !== userInfo.id)
    },

    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.formList[1].props.options = setTreeData(data)
      }
    },

    valueChange(key) {
      if (key === 'executionStartTime' || key === 'executionEndTime') {
        const { form: localForm } = this.$refs.localForm
        this.setExecutionHours(localForm)
      }
    },

    setExecutionHours(data) {
      const { executionStartTime, executionEndTime } = data
      if (executionStartTime && executionEndTime) {
        const minutes = dayjs(executionStartTime).diff(dayjs(executionEndTime), 'minutes')
        if (minutes > 0 && this.$refs.localForm) {
          this.$refs.localForm.setForm({
            executionStartTime: executionEndTime,
            executionEndTime: executionStartTime
          })
        }
        this.manHour = (Math.abs(minutes) / 60).toFixed(1)
        this.baseFormData.facilityInspectionFieldNotes.manHour = +this.manHour
      } else {
        this.manHour = 0
        this.baseFormData.facilityInspectionFieldNotes.manHour = 0
      }
    },

    serviceChange(val) {
      const arr = this.allCustomer.filter(item => item.id === this._.first(val))
      const list = this.allCustomer.filter(item => item.id === this._.last(val))
      const consumerId = +list[0].isCustomer === 0 ? list[0].id : arr[0].id

      this.$refs.baseForm.setForm({
        consumerId,
        consumer: list[0].name,
        customerSiteId: +list[0].isCustomer === 1 ? list[0].id : ''
      })
      this.customerId = consumerId
      this.customerSiteId = +list[0].isCustomer === 1 ? list[0].id : undefined
      this.facilityList = []
      this.localAbnormalList = []
    },

    facilityInfo(list) {
      this.facilityList = list
    },

    itemMappingListChange(list) {
      this.itemMappingList = list
    },
    facilityListChange(list) {
      this.facilityList = list
    },
    addExecutor() {
      const { form: localForm } = this.$refs.localForm

      if (!localForm.executionStartTime || !localForm.executionEndTime) {
        return this.$message.error('请先选择服务时间')
      }
      this.executorList.push({
        isSame: 1,
        date: [],
        manHour: this.manHour
      })
    },

    delExecutorList(index) {
      this.executorList.splice(index, 1)
    },

    datePickerChange(index) {
      const date = this.executorList[index].date

      if (date && date.length) {
        const minutes = dayjs(date[0]).diff(dayjs(date[1]), 'minutes')
        if (minutes) {
          this.executorList[index].manHour = (Math.abs(minutes) / 60).toFixed(1)
        }
      }
    },

    onRadioChange(val, index) {
      if (val === 1) {
        this.executorList[index].manHour = this.manHour
      }
      this.executorList[index].date = []
    },

    addFormList() {
      if (!this.facilityList.length) {
        return this.$message.error('请先添加关联设备')
      }
      if (!this.itemMappingList.length) {
        return this.$message.error('请先添加点检项目')
      }
      if (this.localAbnormalList.length >= this.facilityList.length) {
        return this.$message.error(`最多添加${this.facilityList.length}个异常设备`)
      }
      this.localAbnormalList.push({})
    },

    delFormList(index) {
      this.localAbnormalList.splice(index, 1)
    },

    // 上传附件
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.attachments.push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    removeFile(file) {
      this.attachments = this.attachments.filter(item => item.uid !== file.uid)
    },
    handlePreview(file) {
      if (file.url.indexOf('.txt') > 0) {
        saveAs(file.url, '文件')
        return
      }
      window.open(file.url)
    },

    submitForm() {
      this.buttonLoading = true
      const { form: localForm } = this.$refs.localForm

      this.$refs.baseForm.formRefs().validate(async valid => {
        if (valid) {
          if (this.validForm()) {
            const code = await api.checkTimeInfo({
              startTime: localForm.executionStartTime,
              endTime: localForm.executionEndTime
            })
            if (code === 1 || code === 2) {
              let message
              if (code === 1) {
                message = '该工单已跨天执行，是否确认保存？'
              } else {
                message = '该工单的服务时长已超过24h，是否确认保存？'
              }
              this.$confirm(message, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.saveData()
              })
            } else {
              this.saveData()
            }
            this.buttonLoading = false
          }
        } else {
          this.buttonLoading = false
        }
      })
    },

    validForm() {
      const { form: localForm } = this.$refs.localForm

      if (!this.facilityList.length) {
        this.buttonLoading = false
        this.$message.error('请至少添加一个关联设备')
        return
      }

      if (!this.itemMappingList.length) {
        this.buttonLoading = false
        this.$message.error('请至少添加一个点检项目')
        return
      }

      const isTimeNull = !localForm.executionStartTime || !localForm.executionEndTime
      if (isTimeNull) {
        this.buttonLoading = false
        this.$message.error('服务时间不能为空')
        return
      }

      const localAbnormalList = this.localAbnormalList.map(item => {
        return {
          ...item,
          abnormalItem: (item.abnormalItem || []).join('^')
        }
      })
      const isLocalAbnormalNull = localAbnormalList.find(item => !item.facilityId || !item.abnormalItem || !item.processMode)
      if (isLocalAbnormalNull) {
        this.buttonLoading = false
        this.$message.error('现场记录-点检异常有必填项未填')
        return
      }

      const isExecutorNull = this.executorList.find(item => !item.userIds.length)

      if (isExecutorNull) {
        this.buttonLoading = false
        this.$message.error('协作人未选择')
        return
      }

      const isExecutorTimeNull = this.executorList.find(item => item.isSame === 0 && !item.date.length)

      if (isExecutorTimeNull) {
        this.buttonLoading = false
        this.$message.error('协作人服务时间未填写')
        return
      }

      return true
    },

    async saveData() {
      const { form: localForm } = this.$refs.localForm
      const { form: baseForm } = this.$refs.baseForm

      console.log(this.executorList)

      const workOrderCode = baseForm.workOrderCode === '系统生成' ? -1 : baseForm.code

      const facilityList = this.facilityList.map(item => {
        return {
          facilityId: item.facilityId
        }
      })

      const data = {
        ...baseForm,
        workOrderCode,
        customerSiteIdList: baseForm.customerSiteIdList.join('^'),
        executionStartTime: localForm.executionStartTime,
        executionEndTime: localForm.executionEndTime,
        facilityInspectionSpotCheckPlanFacilityList: facilityList,
        facilityInspectionSpotCheckPlanList: this.itemMappingList,
        facilityInspectionFieldNotes: {
          ...localForm,
          manHour: +this.manHour,
          affix: JSON.stringify(this.attachments),
          executor: '',
          facilityInspectionSpotDetectioList: this.localAbnormalList.map(item => {
            return {
              ...item,
              abnormalItem: (item.abnormalItem || []).join('^'),
              facilityName: (this.facilityList.find(v => item.facilityId === v.facilityId) || {}).facilityName
            }
          }),
          facilityServiceTimeMappingList: this.executorList.map(item => {
            return {
              isSame: item.isSame,
              startTime: item.isSame === 1 ? localForm.executionStartTime : item.date[0],
              endTime: item.isSame === 1 ? localForm.executionEndTime : item.date[1],
              userIds: item.userIds.join('^'),
              manHour: +item.manHour
            }
          })
        }
      }

      if (this.params.type === 'modify') {
        data.id = this.params.id
      }

      const res = await api[`${this.params.type}SpotCheck`](data)
      if (res) {
        this.returns()
      }
      this.buttonLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrappers {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
  }

  .card-bot {
    ::v-deep.el-row {
      display: flex;
      flex-wrap: wrap;
    }

    .custom-form {
      display: flex;
      margin-bottom: 15px;
      color: #606266;

      .custom-form-item:nth-child(1) {
        width: calc(25% - 94px);

        .el-select {
          width: 100%;
        }
      }

     .custom-form-item:nth-child(2) {
        width: calc(75% + 94px);
        padding-left: 20px;

        ::v-deep .el-radio__label {
          font-weight: bold;
        }

        .hour {
          display: inline-block;
          padding-right: 20px;

          label {
            padding: 0 12px 0 40px;
          }
        }
      }
    }

    .view-list {
      padding: 0 15px;
      color: #393d60;
      font-size: 14px;

      &__label {
        margin-bottom: 4px;
        color: #9597ae;
      }

      .el-col {
        margin: 10px 0;
      }
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;

      .required-star {
        width: 10px;
        color: #f00;
      }

      .range {
        margin: 0 10px;
      }
    }

    ::v-deep.el-card {
      &.box-card {
        margin-bottom: 10px;
      }

      &__header {
        font-size: 26px;
      }
    }

    .upload-view {
      ::v-deep.el-upload {
        &--text {
          display: none;
        }
      }
    }

    ::v-deep.el-upload {
      &__tip {
        color: #999;
      }
    }
  }

  .detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;

      .desc-table.edit {
        position: relative;
        top: -46px;
        left: 96px;
        width: calc(100% - 96px);
        margin-bottom: -46px;
      }

      .e-btn {
        margin-top: 10px;
      }
    }
  }
  .detail-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
