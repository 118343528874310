var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "board-group-container" },
    _vm._l(_vm.list, function(item, index) {
      return _c("div", { key: index }, [
        _vm.permission("allBoardGroup" + item.key, false)
          ? _c("div", [
              _c("div", { staticClass: "type-name" }, [
                _vm._v(_vm._s(item.name))
              ]),
              _c(
                "div",
                { staticClass: "card-wrapper" },
                _vm._l(item.children, function(child, childIndex) {
                  return _c(
                    "div",
                    {
                      key: "child_" + childIndex,
                      staticClass: "card",
                      attrs: { title: "点击右键可在新标签页打开" },
                      on: {
                        contextmenu: function($event) {
                          return _vm.jumpDetailNewTab(child.id)
                        },
                        click: function($event) {
                          return _vm.jumpDetailCurrentTab(child.id)
                        }
                      }
                    },
                    [
                      _c("div", [
                        _c("img", { attrs: { src: child.img, alt: "" } })
                      ]),
                      _c("div", [_vm._v(_vm._s(child.name))])
                    ]
                  )
                }),
                0
              )
            ])
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }