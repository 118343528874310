import { getUUid, formatColumns } from '@/utils'
import dayjs from 'dayjs'
import { Encrypt } from '@/utils/sercet'
import { faultStatus } from '@/config/options.config'
const quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)
export const getDateRange = (type, formatType = 'YYYY-MM') => {
  let startDate
  let endDate
  // 当天
  if (type === 5) {
    startDate = dayjs().format(formatType)
    endDate = dayjs().format(formatType)
  }
  // 当周
  if (type === 6) {
    const isSunday = dayjs().day()
    if (isSunday === 0) {
      startDate = dayjs(dayjs().subtract(6, 'day')).format(formatType)
      endDate = dayjs().format(formatType)
    } else {
      startDate = dayjs(dayjs().day(1)).format(formatType)
      endDate = dayjs(dayjs().day(7)).format(formatType)
    }
  }
  // 当月
  if (type === 0) {
    startDate = dayjs().startOf('month').format(formatType)
    endDate = dayjs().endOf('month').format(formatType)
  }
  // 季度
  if (type === 1) {
    const currentQuarter = dayjs().quarter()
    const currentYear = dayjs().year()
    const quarterStartAndEndDate = [['01-01', '03-31'], ['04-01', '06-31'], ['07-01', '09-31'], ['10-01', '12-31']]
    startDate = dayjs(`${currentYear}-${quarterStartAndEndDate[currentQuarter - 1][0]}`).format(formatType)
    endDate = dayjs(`${currentYear}-${quarterStartAndEndDate[currentQuarter - 1][1]}`).format(formatType)
  }
  // 半年
  if (type === 2) {
    const currentMonth = dayjs().month()
    const currentYear = dayjs().year()
    if (currentMonth <= 5) {
      endDate = dayjs(`${currentYear}-06-31`).format(formatType)
      startDate = dayjs().startOf('year').format(formatType)
    } else {
      endDate = dayjs().endOf('year').format(formatType)
      startDate = dayjs(`${currentYear}-07-01`).format(formatType)
    }
  }
  // 当年
  if (type === 3) {
    startDate = dayjs().startOf('year').format(formatType)
    endDate = dayjs().endOf('year').format(formatType)
  }
  // 去年同月至当月
  if (type === 4) {
    endDate = dayjs().format(formatType)
    startDate = dayjs().subtract(1, 'year').format(formatType)
  }
  return [startDate, endDate]
}
const defaultTimeDuration = getDateRange(1)
export default [
  {
    name: '生产卡片',
    key: 'product',
    children: [
      {
        id: 1,
        name: '工单进度统计表',
        tooltip: '汇总工单进度、统计工单达成率',
        type: 'table',
        size: 999,
        dataUrl: 'statistics/private/statistics/getProduceWorkOrderListPage',
        dataCondition: { statusList: '0^1'},
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            dateType: true,
            canNoSelect: true,
            placeholder: '选择计划开始时间',
            keys: ['planBeginTimeStart', 'planBeginTimeEnd'],
          },
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            dateType: true,
            canNoSelect: true,
            placeholder: '选择计划结束时间',
            keys: ['planEndTimeStart', 'planEndTimeEnd'],
          },
        ],
        columns: [
          {
            prop: 'areaName',
            searchKey: 'areaIds',
            label: '适用产线',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'area',
            filterType: 'transfer',
            dataUrl: 'basicData/private/area/getAreaList',
            transferProps: {
              key: 'id',
              label: 'name'
            },
            dataCondition: [{
              id: getUUid(),
              fieldName: 'type',
              fieldType: 'number',
              fieldValue: 2,
              operator: 'eq'
            }]
          },
          {
            prop: 'produceWorkOrderNumber',
            searchKey: 'produceWorkOrderIds',
            label: '生产工单号',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'workOrder',
            filterType: 'transfer',
            transferProps: {
              key: 'id',
              label: 'produceWorkOrderNumber'
            },
            dataUrl: 'production/kiki/private/produceWorkOrder/getProduceWorkOrderList'
          },
          {
            prop: 'produceOrderNumber',
            label: '关联生产订单号',
            searchKey: 'produceOrderIds',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'order',
            filterType: 'transfer',
            transferProps: {
              key: 'id',
              label: 'produceOrderNumber'
            },
            dataUrl: 'production/kiki/private/produceOrder/getProduceOrderList'
          },
          {
            prop: 'materialsCode',
            searchKey: 'materialsIds',
            label: '物料编码',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'materialsCode',
            filterType: 'materials'
          },
          {
            prop: 'materialsName',
            label: '物料名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'materialsSpecifications',
            label: '物料规格',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'priority',
            label: '计划优先级',
            sortable: true,
            searchKey: 'priorityList',
            hiddenSearch: true,
            slotName: 'priorityStatus',
            headerSlotName: 'priority',
            filterType: 'transfer',
            transferProps: {
              key: 'index',
              label: 'name'
            },
            dataUrl: 'basicConfig/private/basicConfig/getBasicConfigDetailByCode',
            dataCondition: {
              code: 'plan_config'
            }
          },
          {
            prop: 'quantity',
            label: '工单数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'reportWorkQuantity',
            label: '完工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'repairQuantity',
            label: '返修数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'finishRatio',
            label: '工单达成率',
            sortable: true,
            hiddenSearch: true,
            slotName: 'orderOverPercent'
          },
          {
            prop: 'procedure',
            searchKey: 'currentProcedureIds',
            label: '当前所处工序',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'procedure',
            filterType: 'transfer',
            dataUrl: 'basicData/production/private/procedure/getProcedureList',
            slotName: 'currentProcedure'
          },
          {
            prop: 'procedureGroupName',
            searchKey: 'currentProcedureGroupIds',
            label: '当前所处工序段',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'procedureGroupName',
            filterType: 'transfer',
            dataUrl: 'basicData/production/private/procedureGroup/getProcedureGroupList',
            slotName: 'currentProcedureGroupName'
          },
          {
            prop: 'procedureRatio',
            label: '工序进程',
            sortable: true,
            hiddenSearch: true,
            slotName: 'procedureRatio'
          },
          {
            prop: 'currentProcedureDuration',
            label: '当前工序时长',
            sortable: true,
            hiddenSearch: true,
            slotName: 'currentProcedureDuration'
          },
          {
            prop: 'currentProcedureStation',
            label: '当前工序工位',
            sortable: true,
            hiddenSearch: true,
            slotName: 'currentProcedureStation'
          },
          {
            prop: 'currentProcedurePerson',
            label: '当前工序人员',
            sortable: true,
            hiddenSearch: true,
            slotName: 'currentProcedurePerson'
          },
          {
            prop: 'planStartTime',
            label: '计划开始时间',
            sortable: true,
            hiddenSearch: true,
            slotName: 'planStartTime'
          },
          {
            prop: 'planEndTime',
            label: '计划结束时间',
            sortable: true,
            hiddenSearch: true,
            slotName: 'planEndTime'
          },
          {
            prop: 'overTime',
            label: '距离计划结束时间还剩',
            sortable: false,
            hiddenSearch: true,
            slotName: 'remainPlanTime'
          },
          {
            prop: 'actualStartTime',
            label: '实际开始时间',
            sortable: true,
            hiddenSearch: true,
            slotName: 'actualStartTime'
          },
          {
            prop: 'deliveryTime',
            label: '交期',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'overActualTime',
            label: '距离交期还剩',
            sortable: false,
            hiddenSearch: true,
            slotName: 'remainDeliveryTime'
          },
          {
            prop: 'longTime',
            label: '工单持续时长',
            sortable: false,
            hiddenSearch: true,
            slotName: 'workOrderDuration'
          },
          {
            prop: 'statusList',
            searchKey: 'statusList',
            label: '工单状态',
            sortable: false,
            hiddenSearch: true,
            filters: formatColumns([
              {
                id: 0,
                name: '未开始'
              },
              {
                id: 1,
                name: '进行中'
              }
            ]),
            slotName: 'status'
          }
        ]
      },
      {
        id: 2,
        name: '月度订单达成率统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getProduceOrderMonthTotal',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        columns: [
          {
            prop: 'month',
            label: '月份',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'month',
            filterType: 'dateRange'
          },
          {
            prop: 'total',
            label: '应完成订单数量',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'finishedTotal',
            label: '当月完成订单数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'finishedOnTimeTotal',
            label: '当月准时完成订单数量',
            sortable: true,
            hiddenSearch: true,
            width: 190
          },
          {
            prop: 'finishedRatio',
            label: '订单达成率',
            sortable: true,
            hiddenSearch: true,
            slotName: 'finishedRatio'
          },
          {
            prop: 'finishedOnTimeRatio',
            label: '订单准交率',
            sortable: true,
            hiddenSearch: true,
            slotName: 'finishedOnTimeRatio'
          }
        ]
      },
      {
        id: 3,
        name: '产线计划达成率统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getProducePlanMonthTotal',
        chartDataKey: 'finishedRatio',
        chartType: 'line',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'select',
            key: 'areaIds',
            dataUrl: 'basicData/private/area/getAreaList',
            dataKey: 'areaList',
            props: {
              multiple: true,
              collapseTags: true
            },
            placeholder: '请选择产线',
            dataCondition: {
              searchVal: Encrypt(JSON.stringify([{
                id: getUUid(),
                fieldName: 'type',
                fieldType: 'number',
                fieldValue: 2,
                operator: 'eq'
              }]))
            }
          }
        ]
      },
      {
        id: 4,
        name: '产线计划准交率统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getProducePlanMonthTotal',
        chartDataKey: 'finishedOnTimeRatio',
        chartType: 'line',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'select',
            key: 'areaIds',
            dataUrl: 'basicData/private/area/getAreaList',
            dataKey: 'areaList',
            props: {
              multiple: true,
              collapseTags: true
            },
            placeholder: '请选择产线',
            dataCondition: {
              searchVal: Encrypt(JSON.stringify([{
                id: getUUid(),
                fieldName: 'type',
                fieldType: 'number',
                fieldValue: 2,
                operator: 'eq'
              }]))
            }
          }
        ]
      },
      {
        id: 5,
        name: '物料产量统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getMaterialsOutputTotal',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        columns: [
          {
            prop: 'info.code',
            searchKey: 'materialsIds',
            label: '物料编码',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'materialsCode',
            filterType: 'materials',
            slotName: 'getDeepName'
          },
          {
            prop: 'info.name',
            label: '物料名称',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'info.specifications',
            label: '规格',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'dayOutputTotal',
            label: '当日产量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekOutputTotal',
            label: '当周产量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthOutputTotal',
            label: '当月产量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 6,
        name: '产线产量统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getAreaOutputTotal',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        columns: [
          {
            prop: 'info.name',
            searchKey: 'areaIds',
            label: '产线',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'area',
            filterType: 'transfer',
            dataUrl: 'basicData/private/area/getAreaList',
            dataCondition: [{
              id: getUUid(),
              fieldName: 'type',
              fieldType: 'number',
              fieldValue: 2,
              operator: 'eq'
            }],
            slotName: 'getDeepName'
          },
          {
            prop: 'dayOutputTotal',
            label: '当日产量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekOutputTotal',
            label: '当周产量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthOutputTotal',
            label: '当月产量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 7,
        name: '物料分类产量统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getMaterialsClassOutputTotal',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        columns: [
          {
            prop: 'info.name',
            label: '物料分类',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'area',
            searchKey: 'materialsClassIds',
            filterType: 'transfer',
            dataUrl: 'basicData/materials/private/materialsClass/getMaterialsClassList',
            slotName: 'getDeepName'
          },
          {
            prop: 'dayOutputTotal',
            label: '当日产量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekOutputTotal',
            label: '当周产量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthOutputTotal',
            label: '当月产量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 8,
        name: '物料分类产量统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getMaterialsClassMonthOutput',
        chartDataKey: 'quantity',
        chartType: 'bar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'cascader',
            key: 'materialsClassIds',
            dataUrl: 'basicData/materials/private/materialsClass/getMaterialsClassList',
            dataKey: 'materialsClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '请选择物料分类'
          }
        ]
      },
      {
        id: 9,
        name: '物料产量统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getMaterialsMonthOutput',
        chartDataKey: 'quantity',
        chartType: 'bar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'materials',
            key: 'materialsIds'
          }
        ]
      },
      {
        id: 10,
        name: '产线产量统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getAreaMonthOutput',
        chartDataKey: 'quantity',
        chartType: 'bar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'select',
            key: 'areaIds',
            dataUrl: 'basicData/private/area/getAreaList',
            dataKey: 'areaList',
            props: {
              multiple: true,
              collapseTags: true
            },
            placeholder: '请选择产线',
            dataCondition: {
              searchVal: Encrypt(JSON.stringify([{
                id: getUUid(),
                fieldName: 'type',
                fieldType: 'number',
                fieldValue: 2,
                operator: 'eq'
              }]))
            }
          }
        ]
      },
      {
        id: 18,
        name: '工序产量统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getProcedureMonthOutput',
        chartDataKey: 'quantity',
        chartType: 'bar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'select',
            key: 'procedureIds',
            dataUrl: 'basicData/production/private/procedure/getProcedureList',
            dataKey: 'procedureList',
            props: {
              multiple: true,
              collapseTags: true
            },
            placeholder: '请选择工序'
          }
        ]
      }
    ]
  },
  {
    name: '物料卡片',
    key: 'materials',
    children: [
      {
        id: 11,
        name: '物料库存统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getMaterialsInventoryTotal',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        columns: [
          {
            prop: 'materialsCode',
            searchKey: 'materialsIds',
            label: '物料编码',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'materialsCode',
            filterType: 'materials'
          },
          {
            prop: 'materialsName',
            label: '物料名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'materialsSpecifications',
            label: '规格',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'materialsUnit',
            label: '主单位',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'availableQuantity',
            label: '可用库存数量',
            sortable: true,
            hiddenSearch: true,
            slotName: 'availableQuantity'
          },
          {
            prop: 'currentQuantity',
            label: '当前存货',
            sortable: true,
            hiddenSearch: true,
            slotName: 'currentQuantity'
          },
          {
            prop: 'upperLimit',
            label: '库存上限',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'lowerLimit',
            label: '库存下限',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 12,
        name: '物料订单量统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getMaterialsOrderQuantityTotal',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        columns: [
          {
            prop: 'info.code',
            label: '物料编码',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'materialsCode',
            filterType: 'materials',
            searchKey: 'materialsIds',
            slotName: 'getDeepName'
          },
          {
            prop: 'info.name',
            label: '物料名称',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'info.specifications',
            label: '规格',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'info.mainUnitName',
            label: '主单位',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'weekOrderQuantity',
            label: '本周订单量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthOrderQuantity',
            label: '本月订单量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'lastMonthOrderQuantity',
            label: '上月订单量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'yearOrderQuantity',
            label: '本年订单量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 13,
        name: '物料订单量统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getMaterialsMonthOrderQuantityTotal',
        chartDataKey: 'quantity',
        chartType: 'bar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'materials',
            key: 'materialsIds'
          }
        ]
      }
    ]
  },
  {
    name: '报工卡片',
    key: 'submit',
    children: [
      {
        id: 14,
        name: '员工报工统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getUserReportQuantityTotal',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        columns: [
          {
            prop: 'info.userName',
            searchKey: 'userIds',
            label: '员工工号',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'userName',
            slotName: 'getDeepName',
            filterType: 'transfer',
            dataUrl: 'authority/private/user/getUserList'
          },
          {
            prop: 'info.name',
            label: '员工姓名',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'dayReportQuantity',
            label: '当日报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekReportQuantity',
            label: '当周报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthReportQuantity',
            label: '当月报工数量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 15,
        name: '班组报工统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getUserGroupReportQuantityTotal',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        columns: [
          {
            prop: 'info.name',
            label: '班组/用户组',
            searchKey: 'userGroupIds',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'userGroupCode',
            slotName: 'getDeepName',
            filterType: 'transfer',
            dataUrl: 'basicData/settings/private/userGroup/getUserGroupList'
          },
          {
            prop: 'info.name',
            label: '包含员工',
            sortable: false,
            hiddenSearch: true,
            slotName: 'includeUser'
          },
          {
            prop: 'dayReportQuantity',
            label: '当日报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekReportQuantity',
            label: '当周报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthReportQuantity',
            label: '当月报工数量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 16,
        name: '工位报工统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getStationReportQuantityTotal',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        columns: [
          {
            prop: 'info.code',
            label: '工位编码',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'stationCode',
            searchKey: 'stationIds',
            slotName: 'getDeepName',
            filterType: 'transfer',
            dataUrl: 'basicData/private/station/getStationList'
          },
          {
            prop: 'info.name',
            label: '工位名称',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'info.equipmentName',
            label: '工位设备',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'dayReportQuantity',
            label: '当日报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekReportQuantity',
            label: '当周报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthReportQuantity',
            label: '当月报工数量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 17,
        name: '工序报工统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getProcedureReportQuantityTotal',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        columns: [
          {
            prop: 'info.code',
            label: '工序编码',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'userGroupCode',
            slotName: 'getDeepName',
            searchKey: 'procedureIds',
            filterType: 'transfer',
            dataUrl: 'basicData/production/private/procedure/getProcedureList'
          },
          {
            prop: 'info.name',
            label: '工序名称',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'dayReportQuantity',
            label: '当日报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekReportQuantity',
            label: '当周报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthReportQuantity',
            label: '当月报工数量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      }
    ]
  },
  {
    name: '设备卡片',
    key: 'equipment',
    children: [
      {
        id: 19,
        name: '设备巡检异常趋势图',
        tooltip: '显示所选设备类巡检项的异常数量统计',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getEquipmentCycleCheckNGTotal',
        chartDataKey: 'quantity',
        chartType: 'bar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'name',
        chartXKey: 'month',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'cascader',
            key: 'equipmentIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '请选择设备分类'
          }
        ]
      },
      {
        id: 20,
        name: '设备巡检异常分布图',
        tooltip: '显示所选设备类巡检项的异常类别数量分布',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getEquipmentCycleCheckItemNGTotal',
        chartDataKey: 'quantity',
        chartType: 'pie',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'checkItemName',
        chartXKey: 'checkItemName',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'cascader',
            key: 'equipmentIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '请选择设备分类'
          }
        ]
      },
      {
        id: 21,
        name: '设备巡检异常统计表',
        tooltip: '显示所选设备类巡检项的异常数量统计',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getEquipmentCycleCheckStatisticsTotal',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        columns: [
          {
            prop: 'month',
            label: '月份',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'month',
            filterType: 'dateRange',
            slotName: 'monthRange'
          },
          {
            prop: 'code',
            label: '设备编号',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'name',
            label: '设备名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'className',
            label: '设备类别',
            sortable: false,
            hiddenSearch: true,
            filterType: 'transfer',
            headerSlotName: 'className',
            searchKey: 'equipmentIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList'
          },
          {
            prop: 'taskCount',
            label: '巡检任务数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'finishedTaskCount',
            label: '完成巡检数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'skippedTaskCount',
            label: '跳过巡检数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'finishedCheckItemNGCount',
            label: '巡检异常数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'NGRatio',
            label: '异常率',
            sortable: true,
            hiddenSearch: true,
            slotName: 'NGRatio'
          }
        ]
      },
      {
        id: 22,
        name: '设备巡检计划达成表',
        tooltip: '显示所选设备类巡检计划的完成情况',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getEquipmentCycleCheckPlanRatioTotal',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        columns: [
          {
            prop: 'month',
            label: '月份',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'month',
            filterType: 'dateRange',
            slotName: 'monthRange'
          },
          {
            prop: 'code',
            label: '设备编号',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'name',
            label: '设备名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'className',
            label: '设备类别',
            sortable: false,
            hiddenSearch: true,
            filterType: 'transfer',
            headerSlotName: 'className',
            searchKey: 'equipmentIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList'
          },
          {
            prop: 'taskCount',
            label: '计划巡检次数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'finishedTaskCount',
            label: '完成巡检次数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'undoTaskCount',
            label: '待巡检次数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'delayTaskCount',
            label: '延期巡检次数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'onTimeRatio',
            label: '达成率',
            sortable: true,
            hiddenSearch: true,
            slotName: 'onTimeRatio'
          }
        ]
      },
      {
        id: 23,
        name: '设备点检异常趋势图',
        tooltip: '显示所选设备类点检项的异常数量汇总',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getEquipmentSpotCheckNGTotal',
        chartDataKey: 'quantity',
        chartType: 'bar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'name',
        chartXKey: 'month',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'cascader',
            key: 'equipmentIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '请选择设备分类'
          }
        ]
      },
      {
        id: 24,
        name: '设备点检异常分布图',
        tooltip: '显示所选设备类点检项的异常类别数量分布图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getEquipmentSpotCheckItemNGTotal',
        chartDataKey: 'quantity',
        chartType: 'pie',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'checkItemName',
        chartXKey: 'checkItemName',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'cascader',
            key: 'equipmentIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '请选择设备分类'
          }
        ]
      },
      {
        id: 25,
        name: '设备点检异常统计表',
        tooltip: '显示所选设备类点检项的异常数量和异常率',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getEquipmentSpotCheckStatisticsTotal',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        columns: [
          {
            prop: 'month',
            label: '月份',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'month',
            filterType: 'dateRange',
            slotName: 'monthRange'
          },
          {
            prop: 'code',
            label: '设备编号',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'name',
            label: '设备名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'className',
            label: '设备类别',
            sortable: false,
            hiddenSearch: true,
            filterType: 'transfer',
            headerSlotName: 'className',
            searchKey: 'equipmentIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList'
          },
          {
            prop: 'taskCount',
            label: '点检任务数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'finishedTaskCount',
            label: '完成点检数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'skippedTaskCount',
            label: '跳过点检数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'finishedCheckItemNGCount',
            label: '点检异常数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'NGRatio',
            label: '异常率',
            sortable: true,
            hiddenSearch: true,
            slotName: 'NGRatio'
          }
        ]
      },
      {
        id: 26,
        name: '设备保养次数趋势图',
        tooltip: '显示所选设备类保养次数汇总',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getEquipmentMaintainTreadInfo',
        chartDataKey: 'mainatainCount',
        chartColumns: ['设备', '次数'],
        chartType: 'bar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'equipmentName',
        chartXKey: 'equipmentName',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'cascader',
            key: 'equipmentIds',
            statisticsType: 1,
            asKey: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '请选择设备分类'
          },
          {
            type: 'select',
            key: 'equipmentIds',
            statisticsType: 0,
            dataUrl: 'equipment/private/equipment/getEquipmentList',
            dataKey: 'equipmentList',
            placeholder: '请选择设备',
            props: { multiple: true }
          }
        ]
      },
      {
        id: 27,
        name: '设备备件更换数量统计图',
        tooltip: '显示所选设备类备件的更换数量汇总',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getEquipmentSparePartTotal',
        chartDataKey: 'quantity',
        chartColumns: ['设备', '数量'],
        chartType: 'bar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'name',
        chartXKey: 'name',
        searches: [
          {
            type: 'dateRange'
          },
          {
            type: 'select',
            key: 'sourceType',
            placeholder: '请选择数据来源',
            options: [{
              id: 0,
              name: '设备维修'
            }, {
              id: 1,
              name: '设备保养'
            }]
          },
          {
            type: 'cascader',
            key: 'equipmentIds',
            asKey: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            statisticsType: 1,
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '请选择设备分类'
          },
          {
            type: 'select',
            statisticsType: 0,
            key: 'equipmentIds',
            dataUrl: 'equipment/private/equipment/getEquipmentList',
            dataKey: 'equipmentList',
            placeholder: '请选择设备',
            props: {
              multiple: true
            }
          }
        ]
      },
      {
        id: 28,
        name: '设备保养计划达成情况表',
        tooltip: '显示所选设备类保养计划的完成情况',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getEquipmentMaintainFinishInfo',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        columns: [
          {
            prop: 'month',
            label: '月份',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'month',
            filterType: 'dateRange',
            slotName: 'monthRange'
          },
          {
            prop: 'equipmentCode',
            label: '设备编号',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'equipmentName',
            label: '设备名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'className',
            label: '设备类别',
            sortable: false,
            hiddenSearch: true,
            filterType: 'transfer',
            headerSlotName: 'className',
            searchKey: 'equipmentIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList'
          },
          {
            prop: 'planMaintainCount',
            label: '计划保养次数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'endMaintainCount',
            label: '完成保养次数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'waitMaintainCount',
            label: '待保养次数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'postponeMaintainCount',
            label: '延期保养次数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'maintenanceRate',
            label: '达成率',
            sortable: true,
            hiddenSearch: true,
            slotName: 'NGRatio'
          }
        ]
      },
      {
        id: 29,
        name: '设备保养项保养次数统计表',
        tooltip: '显示所选设备类保养项的完成次数汇总',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getEquipmentMaintenanceItemInfo',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        columns: [
          {
            prop: 'month',
            label: '月份',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'month',
            filterType: 'dateRange',
            slotName: 'monthRange'
          },
          {
            prop: 'equipmentCode',
            label: '设备编号',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'equipmentName',
            label: '设备名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'className',
            label: '设备类别',
            sortable: false,
            hiddenSearch: true,
            filterType: 'cascader',
            headerSlotName: 'className',
            key: 'equipmentIds',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList'
          }
        ]
      },
      {
        id: 30,
        name: '故障原因分布图',
        type: 'chartsAndTable',
        dataUrl: 'statistics/private/statistics/getEquipmentFaultReasonTotal',
        chartDataKey: 'count',
        chartType: 'pie',
        dataCondition: {},
        chartLegendKey: 'name',
        chartXKey: 'name',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'cascader',
            key: 'type',
            placeholder: '请选择数据来源',
            options: [{
              id: 'f-1',
              key: 'faultStatuses',
              name: '故障报修单',
              children: [{ id: 5, name: '待验证' }, { id: 6, name: '已完成' }]
            }, {
              id: 'f-2',
              key: 'quickStatuses',
              name: '快速维修单',
              children: [{ id: 6, name: '已完成' }]
            }],
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            }
          },
          {
            type: 'cascader',
            key: 'equipmentIds',
            asKey: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            statisticsType: 1,
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '请选择设备分类'
          },
          {
            type: 'select',
            key: 'equipmentIds',
            statisticsType: 0,
            dataUrl: 'equipment/private/equipment/getEquipmentList',
            dataKey: 'equipmentList',
            placeholder: '请选择设备',
            props: { multiple: true }
          }
        ],
        columnsType: 'vertical',
        columns: [
          {
            prop: 'faultReason',
            label: '故障原因',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'count',
            label: '次数',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 31,
        name: '故障原因柏拉图',
        type: 'chartsAndTable',
        dataUrl: 'statistics/private/statistics/getEquipmentFaultReasonTotal',
        chartDataKey: 'count',
        chartColumns: ['设备', '次数'],
        chartType: 'bar',
        dataCondition: {},
        chartLegendKey: 'name',
        chartXKey: 'name',
        lineKey: 'totalRatio',
        lineTitle: '累计百分比',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'cascader',
            key: 'type',
            placeholder: '请选择数据来源',
            options: [{
              id: 'f-1',
              key: 'faultStatuses',
              name: '故障报修单',
              children: [{ id: 5, name: '待验证' }, { id: 6, name: '已完成' }]
            }, {
              id: 'f-2',
              key: 'quickStatuses',
              name: '快速维修单',
              children: [{ id: 6, name: '已完成' }]
            }],
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            }
          },
          {
            type: 'cascader',
            key: 'equipmentIds',
            asKey: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            statisticsType: 1,
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '请选择设备分类'
          },
          {
            type: 'select',
            key: 'equipmentIds',
            statisticsType: 0,
            dataUrl: 'equipment/private/equipment/getEquipmentList',
            dataKey: 'equipmentList',
            placeholder: '请选择设备',
            props: { multiple: true }
          }
        ],
        columnsType: 'vertical',
        columns: [
          {
            prop: 'date',
            label: '时间',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'count',
            label: '次数',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'ratio',
            label: '占比（%）',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'totalRatio',
            label: '累计占比（%）',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 32,
        name: '设备故障次数排列图',
        type: 'chartsAndTable',
        dataUrl: 'statistics/private/statistics/getEquipmentFaultCountTotal',
        chartDataKey: 'quantity',
        chartType: 'horizontalBar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'name',
        chartXKey: 'month',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'cascader',
            key: 'type',
            placeholder: '请选择数据来源',
            defaultValue: [['f-1'], ['f-2'], ['f-2', 6], ['f-1', 2], ['f-1', 3], ['f-1', 4], ['f-1', 5], ['f-1', 6]],
            options: [{
              id: 'f-1',
              key: 'faultStatuses',
              name: '故障报修单',
              children: faultStatus.slice(0, 6)
            }, {
              id: 'f-2',
              key: 'quickStatuses',
              name: '快速维修单',
              children: [{ id: 6, name: '已完成' }]
            }],
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            }
          },
          {
            type: 'cascader',
            key: 'equipmentIds',
            asKey: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            statisticsType: 1,
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '请选择设备分类'
          },
          {
            type: 'select',
            key: 'equipmentIds',
            statisticsType: 0,
            dataUrl: 'equipment/private/equipment/getEquipmentList',
            dataKey: 'equipmentList',
            placeholder: '请选择设备',
            props: { multiple: true }
          }
        ],
        columnsType: 'vertical',
        columns: [
          {
            prop: 'date',
            label: '时间',
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 33,
        name: '设备维修用时统计图',
        type: 'chartsAndTable',
        dataUrl: 'statistics/private/statistics/getEquipmentFaultTimeTotal',
        chartDataKey: 'quantity',
        chartType: 'bar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'name',
        chartXKey: 'month',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'cascader',
            key: 'type',
            placeholder: '请选择数据来源',
            defaultValue: [['f-1'], ['f-2'], ['f-2', 6], ['f-1', 5], ['f-1', 6]],
            options: [{
              id: 'f-1',
              key: 'faultStatuses',
              name: '故障报修单',
              children: [{ id: 4, name: '维修中' }, { id: 5, name: '待验证' }, { id: 6, name: '已完成' }]
            }, {
              id: 'f-2',
              key: 'quickStatuses',
              name: '快速维修单',
              children: [{ id: 6, name: '已完成' }]
            }],
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            }
          },
          {
            type: 'cascader',
            key: 'equipmentIds',
            asKey: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            statisticsType: 1,
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '请选择设备分类'
          },
          {
            type: 'select',
            key: 'equipmentIds',
            statisticsType: 0,
            dataUrl: 'equipment/private/equipment/getEquipmentList',
            dataKey: 'equipmentList',
            placeholder: '请选择设备',
            props: { multiple: true }
          }
        ],
        columnsType: 'vertical',
        columns: [
          {
            prop: 'date',
            label: '时间',
            sortable: false,
            hiddenSearch: true
          }
        ]
      }
    ]
  },
  {
    name: '质量卡片',
    key: 'qualityInspection',
    children: [
      {
        id: 34,
        name: '缺陷项分布柏拉图',
        type: 'chartsAndTable',
        dataUrl: 'statistics/private/statistics/getDefectItemDistributionPlato',
        chartDataKey: 'count',
        chartColumns: ['缺陷项', '频数'],
        chartType: 'bar',
        dataCondition: { beginTime: getDateRange(1, 'YYYY-MM-DD')[0], endTime: getDateRange(1, 'YYYY-MM-DD')[1], inspectionType: 1 },
        chartLegendKey: 'defect',
        chartXKey: 'defect',
        lineKey: 'totalRatio',
        lineTitle: '累计百分比',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'select',
            key: 'inspectionType',
            defaultValue: 1,
            placeholder: '请选择质检类型',
            options: [{
              id: 0,
              name: '首检'
            }, {
              id: 1,
              name: '过程检'
            }]
          },
          {
            type: 'select',
            key: 'procedureIds',
            dataUrl: 'basicData/production/private/procedure/getProcedureList',
            dataKey: 'procedureList',
            placeholder: '请选择工序',
            props: { multiple: true }
          }
        ],
        columnsType: 'horizontal',
        columns: [
          {
            prop: 'inspectionProject',
            label: '检验项目',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'inspectionEntry',
            label: '检验条目',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'defect',
            label: '缺陷项',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'count',
            label: '发生次数',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'ratio',
            label: '占比（%）',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'totalRatio',
            label: '累计占比（%）',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 35,
        name: '缺陷原因分布柏拉图',
        type: 'chartsAndTable',
        dataUrl: 'statistics/private/statistics/getDefectReasonDistributionPlato',
        chartDataKey: 'count',
        chartColumns: ['缺陷项', '频数'],
        chartType: 'bar',
        dataCondition: { beginTime: getDateRange(1, 'YYYY-MM-DD')[0], endTime: getDateRange(1, 'YYYY-MM-DD')[1], inspectionType: 1 },
        chartLegendKey: 'defect',
        chartXKey: 'defect',
        lineKey: 'totalRatio',
        lineTitle: '累计百分比',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'select',
            key: 'inspectionType',
            defaultValue: 1,
            placeholder: '请选择质检类型',
            options: [{
              id: 0,
              name: '首检'
            }, {
              id: 1,
              name: '过程检'
            }]
          },
          {
            type: 'select',
            key: 'procedureIds',
            dataUrl: 'basicData/production/private/procedure/getProcedureList',
            dataKey: 'procedureList',
            placeholder: '请选择工序',
            props: { multiple: true }
          }
        ],
        columnsType: 'horizontal',
        columns: [
          {
            prop: 'inspectionProject',
            label: '检验项目',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'inspectionEntry',
            label: '检验条目',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'defect',
            label: '缺陷原因',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'count',
            label: '发生次数',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'ratio',
            label: '占比（%）',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'totalRatio',
            label: '累计占比（%）',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 36,
        name: '工序直通率（按月）',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getProcedureMonthPassRatio',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1], inspectionType: 1 },
        columns: [],
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'select',
            key: 'inspectionType',
            defaultValue: 1,
            placeholder: '请选择质检类型',
            options: [{
              id: 0,
              name: '首检'
            }, {
              id: 1,
              name: '过程检'
            }]
          },
          {
            type: 'select',
            key: 'procedureIds',
            dataUrl: 'basicData/production/private/procedure/getProcedureList',
            dataKey: 'procedureList',
            placeholder: '请选择工序',
            props: { multiple: true }
          }
        ]
      },
      {
        id: 37,
        name: '工序直通率（按工单）',
        type: 'workTaskPass',
        dataUrl: 'statistics/private/statistics/getWorkOrderProcedurePassRatio',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1], inspectionType: 1 },
        columns: [
          {
            prop: 'name',
            label: '工序',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'radio',
            label: '直通率（%）',
            sortable: false,
            hiddenSearch: true
          }
        ],
        searches: [
          {
            type: 'workOrder',
            key: 'produceWorkOrderId',
            dataUrl: 'production/kiki/private/produceWorkOrder/getProduceWorkOrderList',
            dataKey: 'workOrderList',
            placeholder: '请选择工单'
          },
          {
            type: 'select',
            key: 'inspectionType',
            defaultValue: 1,
            placeholder: '请选择质检类型',
            options: [{
              id: 0,
              name: '首检'
            }, {
              id: 1,
              name: '过程检'
            }]
          }
        ]
      },
      {
        id: 38,
        name: '不合格品率',
        type: 'chartsAndTable',
        dataUrl: 'statistics/private/statistics/getProcedureNgRatio',
        chartDataKey: 'ngCount',
        chartColumns: ['月份', '不合格品数'],
        chartType: 'bar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1], inspectionType: 1 },
        chartLegendKey: 'month',
        chartXKey: 'ngCount',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'select',
            key: 'inspectionType',
            defaultValue: 1,
            placeholder: '请选择质检类型',
            options: [{
              id: 0,
              name: '首检'
            }, {
              id: 1,
              name: '过程检'
            }]
          },
          {
            type: 'select',
            key: 'procedureIds',
            dataUrl: 'basicData/production/private/procedure/getProcedureList',
            dataKey: 'procedureList',
            placeholder: '请选择工序',
            props: { multiple: true }
          }
        ],
        columnsType: 'horizontal',
        columns: [
          {
            prop: 'month',
            label: '时间',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'batchCount',
            label: '质检数',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'ngCount',
            label: '不合格品数',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'ngRatio',
            label: '不合格品率（%）',
            vertical: true,
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 39,
        name: '不合格品处理占比（过程检）',
        type: 'chartsAndTable',
        dataUrl: 'statistics/private/statistics/getProcedureNgRatio',
        chartType: 'bar',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartStack: { '类型': ['返修数', '报废数', '让步接收数'] },
        stackColumns: [{ key: 'month', name: '月份' }, { key: 'repairCount', name: '返修数' }, { key: 'scrapCount', name: '报废数' }, { key: 'degradeCount', name: '让步接收数' }],
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'select',
            key: 'procedureIds',
            dataUrl: 'basicData/production/private/procedure/getProcedureList',
            dataKey: 'procedureList',
            placeholder: '请选择工序',
            props: { multiple: true }
          }
        ],
        columnsType: 'horizontal',
        columns: [
          {
            prop: 'month',
            label: '时间',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'repairCount',
            label: '返修数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'scrapCount',
            label: '报废数',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'degradeCount',
            label: '让步接收数',
            sortable: true,
            hiddenSearch: true
          }
        ]
      }
    ]
  }
]
