import { demandServiceType, quanlityInfoFeeType } from '@/config/options.sales'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  {
    prop: 'code',
    label: '服务订单编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    form: {
      index: 1,
      required: true,
      tagName: 'el-input',
      colSpan: 6
    }
  },
  {
    prop: 'orderNumber',
    label: '关联单号',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 2,
      tagName: 'el-input',
      required: false,
      colSpan: 6
    }
  },
  {
    prop: 'serviceContent',
    label: '服务内容',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      tagName: 'el-select',
      colSpan: 6,
      options: demandServiceType,
      props: {
        multiple: true
      }
    }
  },
  {
    prop: 'consumerId',
    label: '客户',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      tagName: 'el-select',
      colSpan: 6,
      options: [],
      on: {
        change: (val) => {
          functionList.serviceChange(val)
        }
      }
    }
  },
  {
    prop: 'guaranteePeriodStart',
    label: '服务期限',
    sortable: false,
    hiddenSearch: true,
    slotName: 'guaranteePeriodStart',
    form: {
      index: 5,
      tagName: 'el-date-picker',
      colSpan: 6,
      props: {
        type: 'daterange',
        valueFormat: 'yyyy-MM-dd ',
        format: 'yyyy-MM-dd ',
        startPlaceholder: '开始时间',
        endPlaceholder: '结束时间'
      }
    }
  },
  {
    prop: 'laborCost',
    label: '人工费归属',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 6,
      tagName: 'el-select',
      options: quanlityInfoFeeType,
      colSpan: 6,
      props: {}
    }
  },
  {
    prop: 'sparePartsCost',
    label: '备件费归属',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 7,
      tagName: 'el-select',
      options: quanlityInfoFeeType,
      colSpan: 6,
      props: {}
    }
  },
  {
    prop: 'consumptionOfProductCost',
    label: '耗品费归属',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 8,
      tagName: 'el-select',
      options: quanlityInfoFeeType,
      colSpan: 6,
      props: {}
    }
  }
]

