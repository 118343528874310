export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'workOrderCode',
    label: '工单编号',
    hiddenSearch: true,
    slotName: 'workOrderCode'
  },
  {
    prop: 'consumer',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true,
    slotName: 'consumer'
  },
  {
    prop: 'source',
    label: '来源',
    sortable: false,
    hiddenSearch: true,
    slotName: 'source'
  },
  {
    prop: 'demandCode',
    label: '关联需求',
    sortable: false,
    hiddenSearch: true,
    slotName: 'demandCode'
  },
  // {
  //   prop: 'executiveOutcome',
  //   label: '执行结果',
  //   sortable: false,
  //   hiddenSearch: true,
  //   slotName: 'executiveOutcome'
  // },
  {
    prop: 'facilitySpotCheckPlanCode',
    label: '点检计划编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'facilitySpotCheckPlanCode'
  },
  {
    prop: 'facilitySpotCheckPlanName',
    label: '计划名称',
    sortable: false,
    hiddenSearch: true
  },
  // {
  //   prop: 'remark',
  //   label: '备注',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  {
    prop: 'planExecutionStartTime',
    label: '计划执行时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'planExecutionStartTime',
    width: 280
  },
  {
    prop: 'executionStartTime',
    label: '服务时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'executionStartTime',
    width: 340
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'createTime'
  },
  {
    prop: 'executorName',
    label: '执行人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 180
  }
]
