var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrap-wrap" },
    [
      _c("div", { staticClass: "containter" }, [
        _c("div", { staticClass: "wrap-left" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "wrap-left-bottom" },
            _vm._l(_vm.initTabs, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class:
                    _vm.currentIdx === index
                      ? "wrap-left-li active"
                      : "wrap-left-li",
                  on: {
                    click: function($event) {
                      return _vm.changeIdx(index)
                    }
                  }
                },
                [
                  _c("span", [_vm._v(_vm._s(item.title))]),
                  _c("span", { staticClass: "tab-right" }, [
                    _vm._v(_vm._s(item.count))
                  ])
                ]
              )
            }),
            0
          )
        ]),
        _c("div", { staticClass: "wrap-right" }, [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SearchForm", {
                ref: "searchForm",
                attrs: {
                  "form-list-extend": _vm.searchFormList,
                  "form-data": _vm.searchFormData
                },
                on: {
                  "update:formData": function($event) {
                    _vm.searchFormData = $event
                  },
                  "update:form-data": function($event) {
                    _vm.searchFormData = $event
                  },
                  search: _vm.searchTable
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom" },
            [
              _c(
                "FunctionHeader",
                {
                  ref: "functionHeader",
                  attrs: {
                    "search-title": _vm.functionHeader.searchTitle,
                    tags: +_vm.currentIdx === 4 ? [] : _vm.functionHeader.tags,
                    "columns-key": _vm.columnsKey,
                    "export-name":
                      +_vm.currentIdx === 4
                        ? _vm.functionHeader.exportName
                        : "",
                    "export-params": _vm.functionHeader.exportParams,
                    "show-export":
                      +_vm.currentIdx === 4 && _vm.selectionData.length
                        ? false
                        : true
                  },
                  on: {
                    search: function($event) {
                      return _vm.$refs.mTable.setTableData(true)
                    }
                  },
                  model: {
                    value: _vm.functionHeader.searchVal,
                    callback: function($$v) {
                      _vm.$set(_vm.functionHeader, "searchVal", $$v)
                    },
                    expression: "functionHeader.searchVal"
                  }
                },
                [
                  _vm.getTableDataParams.searchType === "" &&
                  _vm.selectionData.length
                    ? _c(
                        "div",
                        { attrs: { slot: "all" }, slot: "all" },
                        [
                          _vm.permission("Export")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.allMore("batchExport")
                                    }
                                  }
                                },
                                [_vm._v(" 批量导出 ")]
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                margin: "0 10px",
                                display: "inline-block",
                                "font-size": "12px",
                                color: "#393D60"
                              }
                            },
                            [
                              _vm._v(" 已选 "),
                              _c(
                                "span",
                                { staticStyle: { color: "#607FFF" } },
                                [_vm._v(_vm._s(_vm.getSelectionDataLength))]
                              ),
                              _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: { click: _vm.resetSelectData }
                            },
                            [_vm._v("清空")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  columns: _vm.newColumns,
                  height: _vm.height,
                  "columns-key": _vm.columnsKey,
                  "set-data-method": _vm.getTableData,
                  selectShowFalse: +_vm.currentIdx === 4
                },
                on: { "selection-change": _vm.selectionChange },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "code",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "el-button",
                        {
                          staticStyle: { "white-space": "pre-wrap" },
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.viewDetail(row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.code) + " ")]
                      )
                    }
                  },
                  {
                    key: "content",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.demandServiceTypes[row.content].name) +
                            " "
                        )
                      ])
                    }
                  },
                  {
                    key: "serviceType",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.serviceType || row.serviceType === 0
                                ? _vm.demandServiceContentType[row.serviceType]
                                    .name
                                : ""
                            ) +
                            " "
                        )
                      ])
                    }
                  },
                  {
                    key: "status",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.content === 3
                                ? _vm.reformStatus[row.status].name
                                : row.status === 10
                                ? "已完成"
                                : _vm.demandStatus[row.status].name
                            ) +
                            " "
                        )
                      ])
                    }
                  },
                  {
                    key: "planStartTime",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(
                          _vm._s(
                            _vm.getTimeDescNo(
                              row.planStartTime,
                              row.planEndTime
                            )
                          )
                        )
                      ])
                    }
                  },
                  {
                    key: "createTime",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _vm._v(_vm._s(_vm.getTimeDesc(row.createTime)))
                      ])
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          row.content === 3
                            ? [
                                _vm.getTableDataParams.searchType === 0
                                  ? [
                                      row.status === 0
                                        ? [
                                            _vm.permission("UploadPlan")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.uploadPlan(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("上传技术方案 ")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 2
                                        ? [
                                            _vm.permission("UploadPlanAgain")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.uploadPlan(
                                                          row,
                                                          1
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("继续上传 ")]
                                                )
                                              : _vm._e(),
                                            _vm.permission("UploadPlanAgain")
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission("Save")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.savePlan(
                                                          row,
                                                          2
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("提交")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 3
                                        ? [
                                            _vm.permission("Write")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.writePlan(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("填写")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 4 || row.status === 5
                                        ? [
                                            _vm.permission("Approval")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.approvals(
                                                          row,
                                                          row.status
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("审批 ")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 6
                                        ? [
                                            _vm.permission("Start")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.startPlan(
                                                          row,
                                                          8
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("开始执行")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 7
                                        ? [
                                            _vm.permission("StartAgain")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.startAgain(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("继续执行")]
                                                )
                                              : _vm._e(),
                                            _vm.permission("StartAgain")
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission("Del")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.finish(
                                                          row,
                                                          9
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("已完成")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 8 || row.status === 9
                                        ? [
                                            _vm.permission("Approval")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.approvals(
                                                          row,
                                                          row.status,
                                                          1
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("审批 ")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 1
                                        ? [
                                            _vm.permission("Modify")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.modifys(
                                                          row,
                                                          "modify"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("修改")]
                                                )
                                              : _vm._e(),
                                            _vm.permission("Modify")
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission("Del")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.delMand(row)
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("删除")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ]
                                  : _vm._e(),
                                _vm.getTableDataParams.searchType === 1
                                  ? [
                                      _vm.permission("Withdraw") &&
                                      row.status !== 7
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.withdraw(row, 4)
                                                }
                                              }
                                            },
                                            [_vm._v(" 撤回 ")]
                                          )
                                        : _vm._e()
                                    ]
                                  : _vm._e(),
                                _vm.getTableDataParams.searchType === 3
                                  ? [
                                      row.status === 10
                                        ? [
                                            _vm.permission("Reset")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.withdraw(
                                                          row,
                                                          5
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("退回")]
                                                )
                                              : _vm._e(),
                                            _vm.permission("DelRequirement")
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission("DelRequirement")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.DelRequirement(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("删除 ")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ]
                                  : _vm._e(),
                                _vm.getTableDataParams.searchType === ""
                                  ? [
                                      _vm.permission("Reset")
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.withdraw(row, 5)
                                                }
                                              }
                                            },
                                            [_vm._v("退回")]
                                          )
                                        : _vm._e(),
                                      _vm.permission("DelRequirement")
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" }
                                          })
                                        : _vm._e(),
                                      _vm.permission("DelRequirement")
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.DelRequirement(row)
                                                }
                                              }
                                            },
                                            [_vm._v("删除 ")]
                                          )
                                        : _vm._e()
                                    ]
                                  : _vm._e()
                              ]
                            : [
                                _vm.getTableDataParams.searchType === 0
                                  ? [
                                      row.status === 0
                                        ? [
                                            _vm.permission("Dispatch")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.dispatchs(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("派工")]
                                                )
                                              : _vm._e(),
                                            _vm.permission("Dispatch")
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission("Modify")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.modifys(
                                                          row,
                                                          "modify"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("修改")]
                                                )
                                              : _vm._e(),
                                            _vm.permission("Modify")
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission("Del") &&
                                            [0, 1, 2, 3, 6, 7].includes(
                                              row.content
                                            )
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.delMand(row)
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("删除 ")]
                                                )
                                              : _vm._e(),
                                            _vm.permission("Skip") &&
                                            [4, 5].includes(row.content)
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.savePlan(
                                                          row,
                                                          7
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("跳过 ")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 2
                                        ? [
                                            _vm.permission("AddPlan")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.addPlan(row)
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("填报")]
                                                )
                                              : _vm._e(),
                                            _vm.permission("AddPlan")
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission("Reset")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.withdraw(
                                                          row,
                                                          2
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("退回")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 1
                                        ? [
                                            _vm.permission("ApprovalPlan")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.approvalPlan(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("审批出差计划 ")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 3
                                        ? [
                                            _vm.permission("ModifyPlan")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.approvalPlan(
                                                          row,
                                                          1
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("修改出差计划 ")]
                                                )
                                              : _vm._e(),
                                            _vm.permission("ModifyPlan")
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission("Reset")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.withdraw(
                                                          row,
                                                          2
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("退回")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 4
                                        ? [
                                            _vm.permission("Start")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.startPlan(
                                                          row,
                                                          2
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("开始执行")]
                                                )
                                              : _vm._e(),
                                            _vm.permission("Start")
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission("Reset")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.withdraw(
                                                          row,
                                                          2
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("退回")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 5
                                        ? [
                                            _vm.permission("StartAgain")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.startAgain(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("继续执行")]
                                                )
                                              : _vm._e(),
                                            _vm.permission("StartAgain")
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission("Finish")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.finish(
                                                          row,
                                                          3
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("已完成")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      row.status === 6
                                        ? [
                                            _vm.permission("Approval")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.startAgain(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("审批")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ]
                                  : _vm._e(),
                                _vm.getTableDataParams.searchType === 1
                                  ? [
                                      _vm.permission("Withdraw") &&
                                      (row.status === 2 || row.status === 4)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.withdraw(row, 1)
                                                }
                                              }
                                            },
                                            [_vm._v(" 撤回 ")]
                                          )
                                        : _vm._e()
                                    ]
                                  : _vm._e(),
                                _vm.getTableDataParams.searchType === 3
                                  ? [
                                      row.status === 10
                                        ? [
                                            _vm.permission("Reset")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.withdraw(
                                                          row,
                                                          5
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("退回")]
                                                )
                                              : _vm._e(),
                                            _vm.permission("DelRequirement")
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission("DelRequirement")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.DelRequirement(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("删除 ")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ]
                                  : _vm._e(),
                                _vm.getTableDataParams.searchType === ""
                                  ? [
                                      _vm.permission("Reset")
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.withdraw(row, 5)
                                                }
                                              }
                                            },
                                            [_vm._v("退回")]
                                          )
                                        : _vm._e(),
                                      _vm.permission("DelRequirement")
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" }
                                          })
                                        : _vm._e(),
                                      _vm.permission("DelRequirement")
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.DelRequirement(row)
                                                }
                                              }
                                            },
                                            [_vm._v("删除 ")]
                                          )
                                        : _vm._e()
                                    ]
                                  : _vm._e()
                              ]
                        ],
                        2
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "MDialog",
        {
          attrs: { "append-to-body": true, title: "退回", width: "500px" },
          on: { onOk: _vm.submitFormReset },
          model: {
            value: _vm.resetVisible,
            callback: function($$v) {
              _vm.resetVisible = $$v
            },
            expression: "resetVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "resetForm",
            attrs: { "form-list": _vm.resetFormList, "label-position": "right" }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { "append-to-body": true, title: "审批", width: "500px" },
          model: {
            value: _vm.approvalVisible,
            callback: function($$v) {
              _vm.approvalVisible = $$v
            },
            expression: "approvalVisible"
          }
        },
        [
          _c(
            "div",
            [
              _c("MFormBuilder", {
                ref: "approvalStatusForm",
                staticClass: "extra-froms",
                attrs: {
                  "form-list": _vm.approvalFormList,
                  "form-data": _vm.approvalFormData,
                  "label-position": "right"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "mubai-dialog-footer center-content",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.approvalVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.submitFormApproval(0)
                    }
                  }
                },
                [_vm._v("驳回")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitFormApproval(1)
                    }
                  }
                },
                [_vm._v("通过")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: {
            "append-to-body": true,
            title: "上传技术方案",
            width: "500px"
          },
          on: { onOk: _vm.submitFormPlan },
          model: {
            value: _vm.uploadVisible,
            callback: function($$v) {
              _vm.uploadVisible = $$v
            },
            expression: "uploadVisible"
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-upload",
                _vm._b(
                  {
                    ref: "upload",
                    staticClass: "upload-demo",
                    attrs: { drag: "", multiple: "", "file-list": _vm.fileList }
                  },
                  "el-upload",
                  _vm.uploadProp,
                  false
                ),
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v(" 将文件拖到此处，或 "),
                    _c("em", [_vm._v("点击上传")])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [_vm._v("支持扩展名：.rar .zip .doc .docx .pdf .jpg..")]
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: {
            "append-to-body": true,
            title: _vm.titles[_vm.currentStatus],
            width: "500px"
          },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.delVisible,
            callback: function($$v) {
              _vm.delVisible = $$v
            },
            expression: "delVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "baseStatusForm",
            staticClass: "extra-froms",
            attrs: {
              "form-list":
                _vm.currentStatus === 0
                  ? _vm.statusFormList
                  : _vm.usersFormList,
              "form-data": _vm.statusFormData,
              "label-position": "right"
            }
          })
        ],
        1
      ),
      _c("DispatchUser", {
        attrs: { visible: _vm.showDispatch, "base-info": _vm.baseInfo },
        on: {
          "update:visible": function($event) {
            _vm.showDispatch = $event
          },
          refrash: _vm.refrashList
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wrap-left-top" }, [
      _c("img", {
        staticClass: "img",
        attrs: { src: require("@/assets/l_c_M_images/demand.png") }
      }),
      _c("span", [_vm._v("需求中心")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }