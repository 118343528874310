// 入库
export const InColumns = [
  {
    prop: 'number',
    label: '入库单号',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'outInWarehouseTeypeName',
    label: '入库类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'orderNumber',
    label: '关联单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseName',
    label: '存放仓库',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
// 出库
export const OutColumns = [
  {
    prop: 'number',
    label: '出库单号',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'outInWarehouseTeypeName',
    label: '出库类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'orderNumber',
    label: '关联单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseName',
    label: '出库仓库',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
// 调拨
export const AllocationColumn = [
  {
    prop: 'number',
    label: '调拨单号',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'orderNumber',
    label: '关联单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseName',
    label: '调出仓库',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'inWarehouseName',
    label: '调入仓库',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
// 移库
export const TransferWarehouseColumn = [
  {
    prop: 'number',
    label: '移库单号',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
// 仓库盘点
export const WarehouseInventoryColumn = [
  {
    prop: 'number',
    label: '盘点单号',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'warehouseName',
    label: '盘点仓库',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseLocationIds',
    label: '库位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
// 物料盘点
export const MaterialInventoryColumn = [
  {
    prop: 'number',
    label: '盘点单号',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]

// 物料-入库
export const InMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'code',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseBatchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseBatchNumber'
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'quantity',
    label: '散件入库数量',
    sortable: false,
    hiddenSearch: true,
    width: 150
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseLocationName'
  }
  // {
  //   prop: 'balanceQuantity',
  //   label: '本次结余',
  //   sortable: false,
  //   hiddenSearch: true,
  //   width: 150
  // },
]
// 物料-入库-整件
export const AllInMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'code',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseBatchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseBatchNumber'
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'containerNo',
    label: '容器编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'containerName',
    label: '容器名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'capacity',
    label: '容量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'containerQuantity',
    label: '整件入库数量',
    sortable: false,
    hiddenSearch: true,
    width: 150
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseLocationName'
  }
  // {
  //   prop: 'balanceQuantity',
  //   label: '本次结余',
  //   sortable: false,
  //   hiddenSearch: true,
  //   width: 150
  // },
]
// 物料-出库
export const OutMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseBatchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseBatchNumber'
  },
  {
    prop: 'containerSupport',
    label: '散件可用数量',
    sortable: false,
    hiddenSearch: true,
    width: 150
  },
  // {
  //   prop: 'stockQuantity',
  //   label: '当前库存',
  //   sortable: false,
  //   hiddenSearch: true,
  // },
  {
    prop: 'quantity',
    label: '散件出库数量',
    sortable: false,
    hiddenSearch: true,
    width: 150
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseLocationName'
  },

  // {
  //   prop: 'balanceQuantity',
  //   label: '本次结余',
  //   sortable: false,
  //   hiddenSearch: true,
  //   width: 150
  // },
]
//物料-出库-整件
export const AllOutMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseBatchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseBatchNumber'
  },
  {
    prop: 'containerAvailable',
    label: '整件可用数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'containerQuantity',
    label: '整件出库数量',
    sortable: false,
    hiddenSearch: true,
    width: 150
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseLocationName'
  },

  // {
  //   prop: 'balanceQuantity',
  //   label: '本次结余',
  //   sortable: false,
  //   hiddenSearch: true,
  //   width: 150
  // },
]
// 物料-调拨
export const AllocationMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'stockQuantity',
    label: '当前库存',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '调出数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'inWarehouseLocationName',
    label: '调入库位',
    sortable: false,
    hiddenSearch: true
  }
  // {
  //   prop: 'balanceQuantity',
  //   label: '本次结余',
  //   sortable: false,
  //   hiddenSearch: true,
  // },
]

// 物料-仓库盘点
export const WarehouseMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'stockQuantity',
    label: '盘前库存',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '实际库存',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'profitAndLoss',
    label: '盈/亏',
    sortable: false,
    hiddenSearch: true
  }
]
// 物料-物料盘点
export const MaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'stockQuantity',
    label: '盘前库存',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '实际库存',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'profitAndLoss',
    label: '盈/亏',
    sortable: false,
    hiddenSearch: true
  }
]
// 物料-移库
export const TransferMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'stockQuantity',
    label: '当前库存',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '转移数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'inWarehouseLocationName',
    label: '移入库位',
    sortable: false,
    hiddenSearch: true
  }
  // {
  //   prop: 'balanceQuantity',
  //   label: '本次结余',
  //   sortable: false,
  //   hiddenSearch: true,
  // }
]
