
// 紧急程度
export const emergencyDegreeOptions = [
  { name: '紧急', id: 0 },
  { name: '一般', id: 1 },
  { name: '低', id: 2 }
]
export const emergencyDegreeMap = emergencyDegreeOptions.reduce((obj, item) => {
  obj[item.id] = item.name
  return obj
}, {})
// 处理状态
export const handleStatusOptions = [
  { name: '待判定', id: 0 },
  { name: '处理中', id: 1 },
  { name: '待验收', id: 2 },
  { name: '已关闭', id: 3 }
]
export const handleStatusMap = handleStatusOptions.reduce((obj, item) => {
  obj[item.id] = item.name
  return obj
}, {})
// 判定结果
export const judgeResultOptions = [
  { name: '无需处理，直接关闭', id: 1 },
  { name: '需要处理', id: 0 }
]
export const judgeResultMap = judgeResultOptions.reduce((obj, item) => {
  obj[item.id] = item.name
  return obj
}, {})

// 最终结果
export const finalResultOptions = [
  { name: '已解决', id: 0 },
  { name: '长期跟踪', id: 1 },
  { name: '其他', id: 2 }
]
export const finalResultMap = finalResultOptions.reduce((obj, item) => {
  obj[item.id] = item.name
  return obj
}, {})
// 验收结果
export const checkResultOptions = [
  { name: '驳回，继续处理', id: 0 },
  { name: '通过已关闭', id: 1 }
]
export const checkResultMap = checkResultOptions.reduce((obj, item) => {
  obj[item.id] = item.name
  return obj
}, {})
