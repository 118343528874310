var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MDialog",
    {
      staticClass: "extra-froms",
      attrs: { "append-to-body": true, title: "派工", width: "500px" },
      on: { onOk: _vm.submitFormDel },
      model: {
        value: _vm.scanVisible,
        callback: function($$v) {
          _vm.scanVisible = $$v
        },
        expression: "scanVisible"
      }
    },
    [
      _c("MFormBuilder", {
        ref: "baseForms",
        attrs: {
          "form-list": _vm.userFormList,
          "form-data": _vm.userFormData,
          "label-position": "right"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }