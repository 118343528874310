<template>
  <div class="detail-wrappers">
    <div class="detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
    </div>
    <div class="detail-body">
      <div class="detail-card">
        <div class="card-tit">基础信息</div>
        <div class="card-bot">
          <el-row class="view-list" :gutter="24">
            <el-col v-for="item in columns" :key="item.id" :span="6">
              <div class="view-list__label">{{ item.label }}</div>
              <div class="view-list__value">{{ getValue(item) || '-' }}</div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">服务描述</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in descFormList" :key="item.id" :span="24">
              <div class="view-list__label">{{ item.label }}</div>
              <div v-if="item.key !== 'facilityHistoricalAlarmRecordList'" class="view-list__value">{{ getDescValue(item) || '-' }}</div>
            </el-col>
          </el-row>

          <MFormBuilder
            v-else
            ref="descForm"
            :form-list="descFormList"
            :form-data="baseFormData.facilityServiceDescriptionList"
            label-position="right"
          />

          <div class="desc-table" :class="{ edit: !isView }">
            <MTable ref="descTable" :show-page="false" :height="202" :columns="descColumns" :data="descTableList" :columns-setting="false">
              <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

              <div v-if="isView" slot="faultTime" slot-scope="{ row }">
                <div>{{ row.faultTime }}</div>
              </div>
              <div v-else slot="faultTime" slot-scope="{ row }" class="table-cell">
                <span class="required-star">*</span>
                <el-date-picker
                  v-model="row.faultTime"
                  type="datetime"
                  placeholder="故障时间"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                />
              </div>

              <div v-if="isView" slot="faultCode" slot-scope="{ row }">
                <div>{{ row.faultCode }}</div>
              </div>
              <div v-else slot="faultCode" slot-scope="{ row }" class="table-cell">
                <span class="required-star">*</span>
                <el-input v-model="row.faultCode" placeholder="输入故障码" />
              </div>

              <div v-if="isView" slot="detailedDeion" slot-scope="{ row }">
                <div>{{ row.detailedDeion }}</div>
              </div>
              <div v-else slot="detailedDeion" slot-scope="{ row }" class="table-cell">
                <el-input v-model="row.detailedDeion" placeholder="输入详细描述" />
              </div>

              <div slot="action" slot-scope="{ $index }">
                <template>
                  <el-button type="text" @click="delTableList($index)">移除</el-button>
                </template>
              </div>
            </MTable>
            <el-button v-if="!isView" size="small" type="primary" class="e-btn" @click="addTableList">添加</el-button>
          </div>
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">备件使用</div>
        <div class="card-bot">
          <MTable ref="descTable" :show-page="false" :height="212" :columns="marterialColumns" :data="marterialTableList" :columns-setting="false">
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

            <div v-if="isView" slot="usageQuantity" slot-scope="{ row }">
              <div>{{ row.usageQuantity }}</div>
            </div>
            <div v-else slot="usageQuantity" slot-scope="{ row }" class="table-cell">
              <span class="required-star">*</span>
              <el-input-number v-model="row.usageQuantity" :min="1" controls-position="right" :step="1" />
            </div>
            <div slot="quantity" slot-scope="{ row }">{{ row.quantity || '-' }}</div>
            <div slot="materialsType" slot-scope="{ row }">{{ row.materialsType || '-' }}</div>
            <div slot="materialsSpecifications" slot-scope="{ row }">{{ row.materialsSpecifications || '-' }}</div>
            <div slot="batchNumber" slot-scope="{ row }">{{ row.batchNumber || '-' }}</div>
            <div slot="sequenceNumber" slot-scope="{ row }">{{ row.sequenceNumber || '-' }}</div>
            <div slot="warehouseName" slot-scope="{ row }">{{ row.warehouseName || '-' }}</div>
            <div slot="warehouseLocationName" slot-scope="{ row }">{{ row.warehouseLocationName || '-' }}</div>
            <div v-if="isView" slot="ownershipSparePartsAssets" slot-scope="{ row }">
              <div>{{ getSparePartsDesc(row.ownershipSparePartsAssets) }}</div>
            </div>
            <div v-else slot="ownershipSparePartsAssets" slot-scope="{ row }" class="table-cell">
              <el-select v-model="row.ownershipSparePartsAssets">
                <el-option v-for="item in ownershipOption" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>

            <div slot="action" slot-scope="{ $index }">
              <template>
                <el-button type="text" @click="delMaterial($index)">移除</el-button>
              </template>
            </div>
          </MTable>
          <el-button v-if="!isView" size="small" type="primary" class="e-btn" @click="onSelectMaterialsInventory">使用仓库中物料</el-button>
          <el-button v-if="!isView" size="small" type="primary" class="e-btn" @click="onSelectMaterials">使用其他物料</el-button>
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">现场记录</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in localFormList" :key="item.id" :span="item.colSpan">
              <div class="view-list__label">{{ item.label }}</div>
              <el-upload v-if="item.key == 'affix'" :file-list="attachments" v-bind="uploadProps2" disabled :class="{ 'upload-view': isView }" />
              <div v-else-if="item.key === 'facilityServiceTimeMappingList'">
                <!-- <el-card class="box-card" > -->
                <el-row v-for="item in executorList" :key="item.id" class="view-list" :gutter="24">
                  <el-col :span="6">
                    <!-- <div class="view-list__label">协作人</div> -->
                    <div class="view-list__value">{{ item.userNames || '-' }}</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="view-list__label">服务时间</div>
                    <div class="view-list__value">{{ `${item.startTime} ~ ${item.endTime}` }}</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="view-list__label">服务时长</div>
                    <div class="view-list__value">{{ item.manHour || 0 }}小时</div>
                  </el-col>
                </el-row>
                <!-- </el-card> -->
              </div>
              <div v-else class="view-list__value">
                {{ getLocalValue(item) || '-' }}
              </div>
            </el-col>
          </el-row>
          <MFormBuilder v-else ref="localForm" :form-list="localFormList" :form-data="baseFormData.facilityFieldNotesList" label-position="right">
            <div slot="manHour">{{ manHour }}小时</div>

            <div slot="facilityServiceTimeMappingList">
              <div v-for="(item, index) in executorList" :key="index" class="custom-form">
                <div class="custom-form-item">
                  <el-select v-model="item.userIds" multiple placeholder="添加协作人" filterable>
                    <el-option v-for="q in executorUserList" :key="q.id" :label="q.name" :value="q.id" />
                  </el-select>
                </div>
                <div class="custom-form-item">
                  <el-radio-group v-model="item.isSame" @change="onRadioChange(item.isSame, index)">
                    <el-radio :label="1">服务时间同执行人</el-radio>
                    <el-radio :label="0">
                      <el-date-picker
                        v-model="item.date"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="选择服务开始时间"
                        end-placeholder="选择服务结束时间"
                        value-format="yyyy-MM-dd HH:mm"
                        format="yyyy-MM-dd HH:mm"
                        clearable
                        :disabled="item.isSame === 1"
                        :picker-options="pickerOptions"
                        @change="datePickerChange(index)"
                      />
                    </el-radio>
                  </el-radio-group>
                  <span class="hour">
                    <label>服务时长</label>
                    {{ getTime(item) }}小时
                  </span>
                  <el-button type="text" @click="delExecutorList(index)">删除</el-button>
                </div>
              </div>
              <el-button size="small" type="primary" @click="addExecutor">添加</el-button>
            </div>

            <el-upload slot="drawing" :file-list="attachments" v-bind="uploadProps2" :class="{ 'upload-view': isView }">
              <el-button type="primary" size="small">上传文件</el-button>
            </el-upload>
          </MFormBuilder>
        </div>
      </div>

      <div v-if="isView" class="detail-card">
        <div class="card-tit">成本归属</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col :span="6">
              <div class="view-list__label">人工费归属</div>
              <div v-if="baseFormData.facilityCostOnnershipBean" class="view-list__value">
                {{ baseFormData.facilityCostOnnershipBean.labopCost || '-' }}
              </div>
            </el-col>
          </el-row>
          <MTable
            v-if="baseFormData.facilityCostOnnershipBean"
            ref="costTable"
            :show-page="false"
            :height="202"
            :columns="costColumns"
            :data="baseFormData.facilityCostOnnershipBean.facilityCostOnnershipList || []"
            :columns-setting="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="followUpProcessing" slot-scope="{ row }">{{ row.followUpProcessing || '-' }}</div>
          </MTable>
        </div>
      </div>
    </div>
    <div v-if="!isView" class="detail-footer">
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">确定</el-button>
      </div>
    </div>

    <select-materials
      ref="selectMaterials"
      :visible.sync="showMaterials"
      :single="false"
      :is-add="true"
      :select-materials="selectMaterials"
      @submitForm="submitMaterials"
      @addMaterial="addMaterial"
    />
    <!-- 新增物料 -->
    <edit-materials ref="editMaterials" @onSuccess="onSuccess" />
    <!-- 物料库存 -->
    <select-materials-inventory
      ref="selectMaterialInventory"
      :visible.sync="showMaterialInventory"
      :operate-type="3"
      @submitForm="submitMaterialInventory"
    />
  </div>
</template>

<script>
import api from '@/api/xiewei/service/maintain-order'
import dayjs from 'dayjs'
import userApi from '@/api/sets/user/user'
import { columns, costColumns, descColumns, marterialColumns } from './columns'
import { descFormList, localFormList, ownershipOption, getFormFunctionList, executorFormList } from './form-list'
import { getToken } from '@/utils/auth'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials'
import SelectMaterialsInventory from '@/components/SelectMaterialsInventory/SelectMaterialsInventory'

export default {
  name: 'MaintainDetail',
  components: { EditMaterials, SelectMaterials, SelectMaterialsInventory },
  data() {
    return {
      columns,
      costColumns,
      descFormList,
      localFormList,
      executorFormList,
      ownershipOption,
      baseFormData: {},
      params: {},
      messages: {
        add: '提交成功',
        modify: '修改成功',
        viewO: '保养工单详情',
        addO: '保养工单',
        modifyO: '修改保养工单'
      },
      buttonLoading: false,
      executorList: [],
      executorUserList: [],
      descTableList: [],
      marterialTableList: [],
      showMaterials: false,
      selectMaterials: [],
      showMaterialInventory: false,

      uploadProps2: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onRemove: this.removeFile,
        onPreview: this.handlePreview
      },
      attachments: [],
      manHour: 0
    }
  },

  computed: {
    isView() {
      return this.$route.query.type === 'view'
    },

    descColumns() {
      return this.isView ? descColumns.filter(item => item.slotName !== 'action') : descColumns
    },

    marterialColumns() {
      return this.isView ? marterialColumns.filter(item => item.slotName !== 'action') : marterialColumns
    },

    getLevelDesc() {
      return level => {
        return {
          1: '一级',
          2: '二级',
          3: '三级'
        }[level]
      }
    },

    getDayDesc() {
      return (start, end) => {
        const format = 'YYYY年MM月DD日'
        if (start && end) {
          const isSameYear = dayjs(start).isSame(end, 'year')
          if (isSameYear) {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format('MM月DD日')}`
          } else {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format(format)}`
          }
        } else if (start) {
          return `${dayjs(start).format(format)}`
        } else if (end) {
          return `${dayjs(end).format(format)}`
        }
        return '-'
      }
    },

    getValue() {
      return row => {
        const data = this.baseFormData
        if (row.prop === 'level') {
          return this.getLevelDesc(data[row.prop])
        } else if (row.prop === 'facilityPlanCode') {
          return data[row.prop] ? `${data[row.prop]} ${data.facilityPlanName}` : '-'
        } else if (row.prop === 'planExecutionStartTime') {
          return this.getDayDesc(data.planExecutionStartTime, data.planExecutionEndTime)
        } else if (row.prop === 'facilityName') {
          return `${data.facilityCode} - ${data.facilityName}`
        } else {
          return data[row.prop]
        }
      }
    },

    getDescValue() {
      return row => {
        return (this.baseFormData.facilityServiceDescriptionList || {})[row.key]
      }
    },

    getLocalValue() {
      return row => {
        let { key } = row
        if (row.tagName === 'el-select') {
          key = `${key}Name`
        }
        return (this.baseFormData.facilityFieldNotesList || {})[key]
      }
    },

    getSparePartsDesc() {
      return val => {
        return (this.ownershipOption.find(item => item.value === val) || {})['label']
      }
    },

    getTime() {
      return item => {
        if (item.isSame === 1) {
          return item.manHour || 0
        } else {
          const { date } = item
          if (date && date.length) {
            const minutes = dayjs(date[0]).diff(dayjs(date[1]), 'minutes')
            const hour = (Math.abs(minutes) / 60).toFixed(1)
            return hour
          } else {
            return 0
          }
        }
      }
    },

    pickerOptions() {
      return {
        disabledDate: (time) => {
          const { form: localForm } = this.$refs.localForm
          return dayjs(time).isBefore(localForm.serviceStartTime, 'day') || dayjs(time).isAfter(localForm.serviceEndTime, 'day')
        }
      }
    }
  },

  watch: {
    manHour(val) {
      this.executorList = this.executorList.map(item => {
        return {
          ...item,
          manHour: item.isSame === 1 ? val : item.manHour
        }
      })
    }
  },

  beforeMount() {
    getFormFunctionList({
      valueChange: this.valueChange
    })
  },

  mounted() {
    this.params = this.$route.query
    this.getDetail()
    this.getUserList()
  },

  methods: {
    returns() {
      this.$router.back()
    },

    async getDetail() {
      const res = await api.getMaintainOrderByDetail(this.$route.query.id)
      if (res) {
        const descInfo = res.facilityServiceDescriptionList || {}
        const localInfo = res.facilityFieldNotesList || {}

        if (localInfo.executor) {
          localInfo.executor = localInfo.executor.split('^').map(item => +item)
        }

        if (localInfo.facilityServiceTimeMappingList) {
          this.executorList = localInfo.facilityServiceTimeMappingList.map(item => {
            return {
              ...item,
              isSame: item.isSame,
              date: item.isSame === 1 ? [] : [item.startTime, item.endTime],
              userIds: item.userIds ? item.userIds.split('^').map(item => +item) : [],
              manHour: item.manHour
            }
          })
        }

        this.descTableList = descInfo.facilityHistoricalAlarmRecordList || []
        this.attachments = JSON.parse(localInfo.affix || '[]')
        this.marterialTableList = res.facilitySparePartsList
        this.baseFormData = res
        this.setExecutionHours(this.baseFormData.facilityFieldNotesList)
      }
    },

    async getUserList() {
      const res = await userApi.getUserList()
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.executorUserList = res.filter(item => item.id !== userInfo.id)
    },

    addTableList() {
      this.descTableList.push({})
    },

    delTableList(index) {
      this.descTableList.splice(index, 1)
    },

    onSelectMaterials() {
      this.showMaterials = true
    },

    onSelectMaterialsInventory() {
      this.showMaterialInventory = true
    },
    addExecutor() {
      const { form: localForm } = this.$refs.localForm

      if (!localForm.serviceStartTime || !localForm.serviceEndTime) {
        return this.$message.error('请先选择服务时间')
      }
      this.executorList.push({
        isSame: 1,
        date: [],
        manHour: this.manHour
      })
    },

    delExecutorList(index) {
      this.executorList.splice(index, 1)
    },

    datePickerChange(index) {
      const date = this.executorList[index].date

      if (date && date.length) {
        const minutes = dayjs(date[0]).diff(dayjs(date[1]), 'minutes')
        if (minutes) {
          this.executorList[index].manHour = (Math.abs(minutes) / 60).toFixed(1)
        }
      }
    },

    onRadioChange(val, index) {
      if (val === 1) {
        this.executorList[index].manHour = this.manHour
      }
      this.executorList[index].date = []
    },

    valueChange(key) {
      if (key === 'serviceStartTime' || key === 'serviceEndTime') {
        const { form: localForm } = this.$refs.localForm
        this.setExecutionHours(localForm)
      }
    },

    setExecutionHours(data) {
      if (!data) {
        return
      }
      const { serviceStartTime, serviceEndTime } = data
      if (serviceStartTime && serviceEndTime) {
        const minutes = dayjs(serviceStartTime).diff(dayjs(serviceEndTime), 'minutes')
        if (minutes > 0 && this.$refs.localForm) {
          this.$refs.localForm.setForm({
            serviceStartTime: serviceEndTime,
            serviceEndTime: serviceStartTime
          })
        }
        this.manHour = (Math.abs(minutes) / 60).toFixed(1)
        this.baseFormData.facilityFieldNotesList.manHour = +this.manHour
      } else {
        this.manHour = 0
        this.baseFormData.facilityFieldNotesList.manHour = 0
      }
    },

    submitMaterials(values) {
      this.showMaterials = false

      const list = values.map(item => {
        return {
          id: item.id,
          facilityUpkeepId: this.baseFormData.id,
          materialsId: item.id,
          batchNumber: item.batchNumber,
          sequenceNumber: item.sequenceNumber,
          materialsCode: item.code,
          materialsName: item.name,
          materialsSpecifications: item.specifications,
          materialsType: item.materialsTypeName,
          quantity: item.planQuantity,
          materialsUnit: item.mainUnitName,
          usageQuantity: undefined,
          ownershipSparePartsAssets: 2
        }
      })
      this.marterialTableList = this.marterialTableList.concat(list)
    },

    submitMaterialInventory(values) {
      this.showMaterialInventory = false
      const list = values.map(item => {
        return {
          ...item,
          id: item.materialsId,
          facilityUpkeepId: this.baseFormData.id,
          quantity: item.availableQuantity,
          usageQuantity: undefined,
          ownershipSparePartsAssets: 0
        }
      })
      this.marterialTableList = this.marterialTableList.concat(list)
    },

    addMaterial() {
      this.$refs.editMaterials.add()
    },

    delMaterial(index) {
      this.marterialTableList.splice(index, 1)
    },

    onSuccess() {
      this.$refs.selectMaterials.updateTable()
    },

    // 上传附件
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.attachments.push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    removeFile(file) {
      this.attachments = this.attachments.filter(item => item.uid !== file.uid)
    },
    handlePreview(file) {
      if (file.url.indexOf('.txt') > 0) {
        saveAs(file.url, '文件')
        return
      }
      window.open(file.url)
    },

    submitForm() {
      this.buttonLoading = true
      const { form: descForm } = this.$refs.descForm
      const { form: localForm } = this.$refs.localForm

      const isTimeNull = !localForm.serviceStartTime || !localForm.serviceEndTime
      if (isTimeNull) {
        this.buttonLoading = false
        return this.$message.error('服务时间不能为空')
      }

      const isHistoryWarningNull = this.descTableList.find(item => !item.faultTime || !item.faultCode)
      if (isHistoryWarningNull) {
        this.buttonLoading = false
        return this.$message.error('历史报警记录有必填项未填')
      }

      const isMaterialNumNull = this.marterialTableList.find(item => !item.usageQuantity)
      if (isMaterialNumNull) {
        this.buttonLoading = false
        return this.$message.error('备件使用数量不能为空')
      }

      const isMaterialNumThanAvalid = this.marterialTableList.find(item => item.quantity && item.usageQuantity > item.quantity)
      if (isMaterialNumThanAvalid) {
        this.buttonLoading = false
        return this.$message.error('备件使用数量不能大于备件可用库存')
      }

      const isExecutorNull = this.executorList.find(item => !item.userIds.length)

      if (isExecutorNull) {
        this.buttonLoading = false
        this.$message.error('协作人未选择')
        return
      }

      const isExecutorTimeNull = this.executorList.find(item => item.isSame === 0 && !item.date.length)

      if (isExecutorTimeNull) {
        this.buttonLoading = false
        this.$message.error('协作人服务时间未填写')
        return
      }

      this.$refs.localForm.formRefs().validate(async valid => {
        if (valid) {
          const data = {
            ...this.baseFormData,
            executionStartTime: localForm.serviceStartTime,
            executionEndTime: localForm.serviceEndTime,
            facilityServiceDescriptionList: {
              ...descForm,
              facilityHistoricalAlarmRecordList: this.descTableList
            },
            facilityFieldNotesList: {
              ...localForm,
              manHour: this.manHour,
              affix: JSON.stringify(this.attachments),
              executor: '',
              facilityServiceTimeMappingList: this.executorList.map(item => {
                return {
                  isSame: item.isSame,
                  startTime: item.isSame === 1 ? localForm.serviceStartTime : item.date[0],
                  endTime: item.isSame === 1 ? localForm.serviceEndTime : item.date[1],
                  userIds: item.userIds.join('^'),
                  manHour: +item.manHour
                }
              })
            },
            facilitySparePartsList: this.marterialTableList,
            userList: []
          }

          const code = await api.checkTimeInfo({
            startTime: localForm.serviceStartTime,
            endTime: localForm.serviceEndTime
          })
          if (code === 1 || code === 2) {
            let message
            if (code === 1) {
              message = '该工单已跨天执行，是否确认保存？'
            } else {
              message = '该工单的服务时长已超过24h，是否确认保存？'
            }
            this.$confirm(message, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.saveData(data)
            })
          } else {
            this.saveData(data)
          }
          this.buttonLoading = false
        } else {
          this.buttonLoading = false
        }
      })
    },

    async saveData(form) {
      const res = await api.modifyMaintainOrder(form)
      if (res) {
        this.returns()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrappers {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
  }

  .card-bot {
    ::v-deep.el-row {
      display: flex;
      flex-wrap: wrap;
    }

     .custom-form {
      display: flex;
      margin-bottom: 15px;
      color: #606266;

      .custom-form-item:nth-child(1) {
        width: calc(25% - 94px);

        .el-select {
          width: 100%;
        }
      }

      .custom-form-item:nth-child(2) {
        width: calc(75% + 94px);
        padding-left: 20px;

        ::v-deep .el-radio__label {
          font-weight: bold;
        }

        .hour {
          display: inline-block;
          padding-right: 20px;

          label {
            padding: 0 12px 0 40px;
          }
        }
      }
    }

    .view-list {
      padding: 0 15px;
      color: #393d60;
      font-size: 14px;

      &__label {
        margin-bottom: 4px;
        color: #9597ae;
      }

      .el-col {
        margin: 10px 0;
      }
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;

      .required-star {
        width: 10px;
        color: #f00;
      }

      .range {
        margin: 0 10px;
      }
    }

    .upload-view {
      ::v-deep.el-upload {
        &--text {
          display: none;
        }
      }
    }
  }

  .detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;

      .desc-table.edit {
        position: relative;
        top: -46px;
        left: 96px;
        width: calc(100% - 96px);
        margin-bottom: -46px;
      }

      .e-btn {
        margin-top: 10px;
      }
    }
  }
  .detail-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
