
import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'
import { jobTypes } from '@/config/options.sales'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const formList = [
  ...transformColumnsToForm(columns)
]

export const searchFormList = [
  {
    colSpan: 5,
    key: 'time',
    tagName: 'el-date-picker',
    dateType: 'date',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '日期',
      endPlaceholder: '日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: []
    }
  },
  {
    key: 'customerName',
    tagName: 'el-cascader',
    colSpan: 5,
    props: {
      placeholder: '请选择工时归属',
      props: {
        label: 'name',
        value: 'id',
        checkStrictly: true
      },
      options: [],
      collapseTags: true,
      filterable: true
    }
  },
  {
    key: 'jobType',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择工作类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: jobTypes
      }
    ]
  },
  {
    key: 'creator',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择提交人'
    },
    children: [
      {
        tagName: 'el-option',
        innerValue: (val) => {
          return `${val['jobNumber']} - ${val['name']}`
        },
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  }
]

