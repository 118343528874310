const comForm = [
  {
    key: 'sitWarehouseId',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '线边仓'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    key: 'operator',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择制单人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'operateTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '制单开始时间',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  }
]
export const Materials = [
  comForm[0],
  {
    colSpan: 6,
    key: 'keyword',
    tagName: 'div',
    defaultValue: '',
    domProps: { className: 'customer-content' },
    children: [
      {
        slots: ['account']
      }
    ]
  },
  {
    colSpan: 6,
    key: 'keyword',
    tagName: 'div',
    defaultValue: '',
    domProps: { className: 'customer-content2' },
    children: [
      {
        slots: ['materialsM']
      }
    ]
  },
  comForm[1],
  comForm[2]
]
