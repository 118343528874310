<template>
  <div class="model-wrap">
    <FunctionHeader
      v-model="functionHeader.searchVal"
      search-title="请输入报废原因"
      :tags="functionHeader.tags"
      :export-name="functionHeader.exportName"
      :export-params="functionHeader.exportParams"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable
      ref="mTable"
      :columns="columns"
      :height="height"
      :set-data-method="getTableData"
    >
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
      <div slot="type" slot-scope="{ row }">{{ paramsTypeName[row.type] }}</div>
      <div slot="action" slot-scope="{ row }">
        <!-- <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
        <el-divider v-if="permission('Modify')" direction="vertical" />
        <el-button v-if="permission('Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission('Status')" direction="vertical" />
        <el-dropdown @command="(command) => { more(row, command) }">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
            <el-dropdown-item v-if="permission('Copy')" command="copy">复制</el-dropdown-item>
            <el-dropdown-item v-if="permission('Del')" command="del">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->

        <!-- 新的 ———————Start————————— -->
        <el-button v-if="permission('Copy')" type="text" @click="modifyOrCopy(row, 'copy')">复制</el-button>
        <el-divider v-if="permission('Copy')" direction="vertical" />

        <el-button v-if="permission('Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission('Status')" direction="vertical" />

        <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
        <el-divider v-if="permission('Modify')" direction="vertical" />

        <el-button v-if="permission('Del')" type="text" @click="del(row)">删除</el-button>
        <!-- 新的 ———————End————————— -->
      </div>
    </MTable>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog>

  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/sets/params/scarp-reason'
import { columns } from './columns'
import formList from './form-list'

export default {
  name: 'ScarpReason',
  components: { FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'WasteReason'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ],
        exportName: 'exportWasteReason',
        importName: 'wasteReason',
        exportParams: {}
      },
      columns,
      visible: false,
      formList: formList,
      formData: {},
      currentType: '新增',
      formOptions: {},
      searchFieldName: 'reason'
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    }
  },
  methods: {
    // 更多操作
    more(row, command) {
      const functions = {
        copy: () => { this.modifyOrCopy(row, command) },
        modify: () => { this.modifyOrCopy(row, command) },
        del: () => { this.del(row) }
      }
      functions[command]()
    },
    createSetData(raw) {
      return {
        reason: raw.reason,
        isEnabled: raw.isEnabled,
        remark: raw.remark
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.model-wrap {
  padding: 10px;
  .function-header-wrap {
    padding-top: 0;
  }
}
</style>
