var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "self-center-wrapper" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _c(
          "el-menu",
          {
            staticClass: "left-menu",
            attrs: {
              "default-active": _vm.activeMenu,
              "background-color": "#DDE3EB"
            },
            on: { select: _vm.activeMenuItem }
          },
          _vm._l(_vm.menuList, function(item) {
            return _c(
              "el-menu-item",
              { key: item.name, attrs: { index: item.name } },
              [
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v(_vm._s(item.title))
                ])
              ]
            )
          }),
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "right" }, [
      _c("h3", { staticClass: "header" }, [_vm._v(_vm._s(_vm.activeMenuBane))]),
      _c("div", { staticClass: "router-body" }, [_c("Blank")], 1)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }