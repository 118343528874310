var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-statistics" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              !_vm.editStatus
                ? _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.customStatisticsCardsInfo.title))
                  ])
                : _c("el-input", {
                    attrs: { placeholder: "请输入卡片名称", size: "small" },
                    model: {
                      value: _vm.title,
                      callback: function($$v) {
                        _vm.title = $$v
                      },
                      expression: "title"
                    }
                  }),
              _vm.customStatisticsCardsInfo.title
                ? _c("div", [
                    !_vm.editStatus
                      ? _c("i", {
                          staticClass: "el-icon-edit",
                          on: { click: _vm.modifyTitle }
                        })
                      : _c(
                          "div",
                          { staticStyle: { display: "inline-flex" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    _vm.editStatus = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: { click: _vm.saveTitle }
                              },
                              [_vm._v("保存")]
                            )
                          ],
                          1
                        )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm.customStatisticsCardsInfo.title
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addCard }
                },
                [_vm._v("添加报表卡片")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("add-statistics-card", {
        ref: "addStatisticsCard",
        attrs: {
          "custom-statistics-cards-info": _vm.settingInfo,
          "current-id": _vm.customStatisticsCardsInfo.id
        },
        on: { updateStatisticsCards: _vm.getCustomStatisticsCardss }
      }),
      _c(
        "div",
        { staticClass: "scroll-wrapper", staticStyle: { padding: "10px" } },
        [
          _vm._l(_vm.customStatisticsCardsInfo.children, function(
            child,
            childIndex
          ) {
            return [
              child.name === "工单进度统计表"
                ? _c(
                    "div",
                    { key: child.id + 200 },
                    [
                      _vm._v(" 精简至天： "),
                      _c("el-switch", {
                        on: { change: _vm.setToDay },
                        model: {
                          value: _vm.toDay,
                          callback: function($$v) {
                            _vm.toDay = $$v
                          },
                          expression: "toDay"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-card", { key: child.id, staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "box-card-header",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v(_vm._s(child.name))]),
                        child.tooltip
                          ? _c(
                              "el-tooltip",
                              {
                                staticStyle: { "padding-left": "5px" },
                                attrs: {
                                  content: child.tooltip,
                                  placement: "top"
                                }
                              },
                              [_c("span", { staticClass: "el-icon-question" })]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { style: { position: "relative", right: "26px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.exportTableOrCharts(
                                  child,
                                  childIndex
                                )
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/statistics-board/下载@2x.png"),
                                alt: "",
                                width: "14"
                              }
                            })
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.saveCard(child, childIndex)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/statistics-board/保存@2x.png"),
                                alt: "",
                                width: "14"
                              }
                            })
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.delCard(child)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/statistics-board/del.png"),
                                alt: "",
                                width: "13"
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  [
                    child.type === "table"
                      ? _c("CustomTable", {
                          ref: "customTable",
                          refInFor: true,
                          attrs: { "tab-info": child, "to-day": _vm.toDay }
                        })
                      : _vm._e(),
                    child.type === "charts" || child.type === "chartsAndTable"
                      ? _c("charts", {
                          ref: "customTable",
                          refInFor: true,
                          attrs: { "tab-info": child }
                        })
                      : _vm._e(),
                    child.type === "workTaskPass"
                      ? _c("work-task-pass", { attrs: { "tab-info": child } })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }