import { triggerActionType, notifyChannelType } from '@/config/options.sales'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 50
  },
  {
    prop: 'triggerAction',
    label: '触发动作',
    sortable: false,
    hiddenSearch: true,
    slotName: 'triggerAction',
    form: { index: 1, tagName: 'el-select', colSpan: 8, options: triggerActionType,
      on: { change: (val) => { functionList.triggerActionChange(val) } }
    }
  },
  // {
  //   prop: 'isPeriodicReminder',
  //   label: '是否定期提醒',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'isPeriodicReminder',
  //   minWidth: 180,
  //   form: { index: 2, tagName: 'el-select', colSpan: 8, defaultValue: 0, options: [
  //     {
  //       id: 0,
  //       name: '否'
  //     },
  //     {
  //       id: 1,
  //       name: '是'
  //     }
  //   ],
  //   on: { change: (val) => { functionList.periodicReminderChange(val) } }
  //   }
  // },
  // {
  //   prop: 'reminderCycle',
  //   label: '提醒周期/天',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'reminderCycle',
  //   form: {
  //     hidden: true,
  //     index: 3,
  //     colSpan: 8,
  //     attrs: {
  //       placeholder: '请输入提醒周期（整数天）'
  //     },
  //     tagName: 'el-input-number', props: { controlsPosition: 'right', min: 1 }
  //   }
  // },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 40,
      tagName: 'el-input',
      colSpan: 8
    }
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 45,
      tagName: 'el-input',
      colSpan: 8
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]

export const infoColumns = [
  {
    prop: 'dynamicNotifyUser',
    label: '通知对象/动态',
    sortable: false,
    hiddenSearch: true,
    form: { index: 0, tagName: 'el-select',
      colSpan: 8,
      options: [],
      required: false,
      props: {
        clearable: false,
        multiple: true
      }
    }
  },
  {
    prop: 'staticNotifyUserIds',
    label: '通知对象/固定',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      colSpan: 8,
      options: [],
      props: {
        multiple: true
        // collapseTags: true
      },
      required: false,
      innerValue: (val) => {
        return `${val['jobNumber']} - ${val['name']}`
      }
    }
  },
  {
    prop: 'notifyChannel',
    label: '通知渠道',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      colSpan: 8,
      options: notifyChannelType,
      props: {
        clearable: false,
        multiple: true
      }
    }
  },
  {
    prop: 'messageTemplate',
    label: '消息模板',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 2,
      tagName: 'div',
      key: 'messageTemplate',
      required: false,
      colSpan: 24,
      customSlots: ['messageTemplate']
    }
    // form: {
    //   index: 3,
    //   tagName: 'el-input',
    //   colSpan: 24,
    //   required: false,
    //   props: { type: 'textarea', autosize: { minRows: 3} }
    // }
  }
]
