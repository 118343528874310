var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "all-statistics-card" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _vm._l(_vm.statisticsCard, function(item, index) {
            return [
              _vm.permission("operatingReports" + item.key, false)
                ? _c(
                    "el-tab-pane",
                    {
                      key: item.key,
                      style: {
                        height: _vm.paneHeight + "px",
                        overflow: "auto",
                        width: "100%"
                      },
                      attrs: { label: item.name, name: item.key }
                    },
                    [
                      _vm._l(item.children, function(child, idx) {
                        return [
                          _c(
                            "div",
                            {
                              key: child.id + 200,
                              class: child.fullscreen ? "app-left-full" : ""
                            },
                            [
                              child.name === "工单进度统计表"
                                ? _c(
                                    "div",
                                    [
                                      _vm._v(" 精简至天： "),
                                      _c("el-switch", {
                                        on: { change: _vm.setToDay },
                                        model: {
                                          value: _vm.toDay,
                                          callback: function($$v) {
                                            _vm.toDay = $$v
                                          },
                                          expression: "toDay"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.key === _vm.activeName
                                ? _c(
                                    "el-card",
                                    { key: child.id, staticClass: "box-card" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "box-card-header",
                                          attrs: { slot: "header" },
                                          slot: "header"
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(child.name))
                                              ]),
                                              child.tooltip
                                                ? _c(
                                                    "el-tooltip",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "5px"
                                                      },
                                                      attrs: {
                                                        content: child.tooltip,
                                                        placement: "top"
                                                      }
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "el-icon-question"
                                                      })
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              style: {
                                                position: "relative",
                                                right: "26px",
                                                padding: "12px 0"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "header-ri",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggleFullScreen(
                                                        child,
                                                        index,
                                                        idx
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  !child.fullscreen
                                                    ? _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            content: "放大",
                                                            placement: "top"
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "ri-img",
                                                            attrs: {
                                                              src: require("@/assets/narrow-amplification/amplification.png"),
                                                              alt: ""
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            content: "缩小",
                                                            placement: "top"
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "ri-img",
                                                            attrs: {
                                                              src: require("@/assets/narrow-amplification/narrow.png")
                                                            }
                                                          })
                                                        ]
                                                      )
                                                ],
                                                1
                                              ),
                                              child.type === "table"
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        padding: "0"
                                                      },
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.exportReport(
                                                            child,
                                                            idx
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/statistics-board/下载@2x.png"),
                                                          alt: "",
                                                          width: "14"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    padding: "0 10px"
                                                  },
                                                  attrs: { type: "text" }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/statistics-board/保存@2x.png"),
                                                      alt: "",
                                                      width: "14"
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          child.type === "table"
                                            ? _c("CustomTable", {
                                                ref: "customTable",
                                                refInFor: true,
                                                attrs: {
                                                  "tab-info": child,
                                                  "to-day": _vm.toDay
                                                }
                                              })
                                            : _vm._e(),
                                          child.type === "charts" ||
                                          child.type === "chartsAndTable"
                                            ? _c("charts", {
                                                ref: "customTable",
                                                refInFor: true,
                                                attrs: { "tab-info": child }
                                              })
                                            : _vm._e(),
                                          child.type === "workTaskPass"
                                            ? _c("work-task-pass", {
                                                attrs: { "tab-info": child }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }