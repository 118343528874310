<template>
  <div class="orders">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button label="Active">活跃订单</el-radio-button>
      <el-radio-button label="History">历史订单</el-radio-button>
    </el-radio-group>
    <div class="bottom">
      <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList"
        :form-data="searchFormData"
        style="padding: 0"
        @update:formData="data=>searchFormData=data"
        @search="reSearch"
      />
    </div>
    <FunctionHeader
      ref="functionHeader"
      v-model="functionHeader.searchVal"
      search-title="请输入订单编号/客户单编号"
      :tags="!selectIds.length ? functionHeader.tags :[]"
      style="padding: 10px 0 10px 0;"
      class="func-header"
      @search="$refs.mTable.setTableData(true)"
    >
      <div slot="all" v-show="selectIds.length">
        <el-button v-if="permission('End') && params.radio==='Active'" type="primary" size="small" @click="onHandle(selectIds, 'finish')">批量完结</el-button>
        <el-button v-if="permission('Cancellation') && params.radio==='Active'" size="small" type="primary"  @click="onHandle(selectIds, 'cancellation')">批量作废</el-button>
        <el-button v-if="permission('Del') && params.radio==='History'" type="primary" size="small" @click="onHandle(selectIds, 'del')">批量删除</el-button>
        <el-button v-if="permission('Restart') && params.radio==='History'" size="small" type="primary"  @click="onHandle(selectIds, 'restart')">批量重启</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetData">清空</el-button>
      </div>
    </FunctionHeader>
    <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChanges">
      <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
      <div slot="code" slot-scope="{ row }">
        <NewPageOpen :path="{ name: 'salesOrderDetail', query: {id: row.id}}" :text="row.code" />
      </div>
      <div slot="progress" slot-scope="{ row }">
        <!-- :status="row.progress>=100?'success':''" -->
        <el-progress :stroke-width="10" :percentage="row.progress||0" />
      </div>
      <div slot="deliveryType" slot-scope="{ row }">
        {{ deliveryTypes[row.deliveryType] }}
      </div>
      <div slot="orderSource" slot-scope="{ row }">
        {{ orderSource[row.orderSource] }}
      </div>
      <div slot="action" slot-scope="{ row }">
        <div v-show="params.radio==='Active'">
          <el-button v-if="permission('End')" type="text" :disabled="row.progress<100" @click="onHandle([row.id],'finish')">完结</el-button>
          <el-button v-if="permission('Cancellation')" type="text" @click="onHandle([row.id],'cancellation')">作废</el-button>
          <el-button v-if="permission('Edit')" type="text" @click="jump('edit',row.id)">修改</el-button>
        </div>
        <div v-show="params.radio==='History'">
          <el-button v-if="permission('Restart')" type="text" @click="onHandle([row.id],'restart')">重启</el-button>
          <el-button v-if="permission('Del')" :disabled="!row.allowDeleteStatus" type="text" @click="onHandle([row.id],'del')">删除</el-button>
        </div>
      </div>
    </MTable>
    </div>
  </div>
</template>
<script>
import api from '@/api/production/salesManage'
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import { searchFormList } from './form-list'
import { columnsAll, deliveryTypes } from './columns'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'

export default {
  name: 'SalesOrderList',
  components: { NewPageOpen, FunctionHeader, SearchForm },
  data() {
    return {
      orderSource: { 0: 'MES新增', 1: 'ERP' },
      deliveryTypes,
      params: {
        radio: 'Active'
      },
      searchFormList,
      searchFormData: {},
      functionHeader: {
        searchVal: '',
        exportName: 'exportCalculateListData',
        exportParams: {},
        tags: [
          {
            hidden: !this.permission('Add', true),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF', marginRight: '10px' },
            innerText: '新增',
            on: {
              click: () => this.jump('add')
            }
          }
        ]
      },
      columns: columnsAll,
      searchData: {},
      selectIds: [],
      materialParams: { status: 0, page: 1, size: 10, search: '' },
      total:0,
      selectionData:[]
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 15 - 55 - 44
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },

  created() {
    this.initPage()
  },
  methods: {
    selectionChanges(data) {
      this.selectionData = data
      this.selectIds = data.map(item => item.id)
    },
    resetData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    radioChange() {
      this.$refs.searchForm.reset()
      this.$refs.mTable.setTableData(true)
    },
    reSearch(data, val) {
      if (val === 1) {
        this.searchFormData = {}
      }
      this.$refs.mTable.setTableData(true)
    },
    initPage() {
      // 获取客户列表
      this.getCustomer()
      // 获取负责人列表
      this.getResponsible()
    },

    // 获取客户列表
    async getCustomer(params) {
      const res = await api.getCustomer(params)
      if (res) {
        this.searchFormList[1].children[0].options = res
      }
    },

    // 获取负责人列表
    async getResponsible() {
      const res = await api.getResponsible()
      if (res) {
        this.searchFormList[4].children[0].options = res
      }
    },

    // 新增
    jump(type, id) {
      localStorage.setItem('salesOrderAddType', type === 'add' ? 1 : 2)
      this.$router.push({ path: 'sales-order-add', query: { type, id }})
    },

    allMore(command) {
      const functions = {
        onEnd: () => this.onHandle(this.selectIds, 'finish'),
        onDel: () => this.onHandle(this.selectIds, 'del'),
        onCancellation: () => this.onHandle(this.selectIds, 'cancellation'),
        onRestart: () => this.onHandle(this.selectIds, 'restart')
      }
      functions[command]()
    },

    // 操作
    async onHandle(data, type) {
      const map = {
        finish: { name: '完结', api: api.finish, tip: '完结后该订单将进入归档状态，无法再进行其他相关操作，是否确认？' },
        del: { name: '删除', api: api.delete, tip: '删除后该订单的所有信息将从系统中抹除，并且无法恢复，是否确认？' },
        cancellation: { name: '作废', api: api.cancellation, tip: '作废后该订单将进入归档状态，无法再进行其他相关操作，是否确认？' },
        restart: { name: '重启', api: api.restart, tip: '重启后该订单将回到活跃订单的状态，是否确认？' }
      }
      this.$confirm(`${map[type].tip}`, `${map[type].name}订单`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await map[type].api({ ids: data })
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
        }
      })
    },

    // 列表
    async getTableData(condition, callback) {
      const { page } = condition
      const { orderSource, dateRange, customerId, deliveryType, principalId } = this.searchFormData
      const params = {
        search: this.functionHeader.searchVal,
        orderSource,
        startTime: dateRange ? dateRange[0] : '',
        endTime: dateRange ? dateRange[1] : '',
        customerId,
        deliveryType,
        principalId,
        activeStatus: this.params.radio === 'Active' ? 0 : 1
      }
      // 导出参数
      this.functionHeader.exportParams = params
      const res = await api.getSalesOrderList({
        page: page.currentPage,
        size: page.pageSize,
        ...params
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
.orders {
  .bottom {
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  .faultStatuss {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    height: 22px;
    line-height: 22px;
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
    display: inline-block;
  }
  .li-btn {
    color: #607fff;
    font-weight: 500;
  }
  .color-btns {
    color: #c4c4c4;
  }
  .searchs {
    padding-left: 0;
    padding-top: 0;
  }
  .input-select {
    width: 100px;
    display: block;
  }
  .input-with-select {
    width: 100%;
  }
  .all-operator {
    height: 32px;
    margin-left: 10px;
    ::v-deep {
      .el-button--primary,
      .el-button-group .el-button {
        height: 32px;
      }
    }
  }
  .func-header {
    ::v-deep .el-input--suffix .el-input__inner {
      height: 32px;
    }
  }
}
::v-deep .customer-content div {
  width: 100%;
}
.tip-tit {
  height: 18px;
  line-height: 18px;
}
div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #eceef2;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #393d60;
  margin-left: 10px;
}

div.tooltip:before,
div.tooltip:after {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #eceef2;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #eceef2;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.params-card {
  border: 1px solid #ececf7;
  margin-bottom: 20px;
  .header {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    background: #ececf7;
  }
  .body {
    width: 100%;
    .bot-ti {
      // height: 36px;
      line-height: 36px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      display: inline-block;
      margin-bottom: 16px;
      margin-left: 20px;
      word-break: break-all;
      .imgs {
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 10px;
        img {
          width: 100px;
          height: 100px;
          display: inline-block;
        }
      }
    }
  }
}
</style>
