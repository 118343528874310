<template>
  <div class="role-wrap">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <div class="group">
          <div class="top">
            <div class="title" @click="onAddRole">添加角色</div>
          </div>
          <div class="content">
            <div v-for="(item, index) in list" :key="item.id" class="Warehouse" :class="{active: item.id === currentId}" @click="onChangeRole(item, index)">
              <span class="ellipsis">{{ item.name }}</span>
              <div>
                <i v-if="permission('RoleModify')" class="modify" @click.stop="modifyOrCopy(item, 'modify')" />
                <i v-if="permission('RoleDel')" class="del" @click.stop="delRoles(item)" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="right">
        <div class="model-wrap-right common-tab">
          <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
            <el-radio-button label="Role">功能权限</el-radio-button>
            <el-radio-button label="Data">数据权限</el-radio-button>
            <el-radio-button label="User">关联用户</el-radio-button>
          </el-radio-group>
        </div>
        <div v-if="params.radio === 'Role'" style="margin: 10px 20px;">
          <div class="all-btns">
            <label class="label">网页端功能权限</label>
            <el-button size="small" type="primary" class="role-btn" @click="saveRole">保存</el-button>
          </div>
          <el-tree
            v-if="openPanel"
            ref="tree"
            show-checkbox
            node-key="name"
            :data="constantRoutes"
            :props="defaultProps"
            :default-expanded-keys="['all']"
            :default-checked-keys="auths"
            :render-content="renderContent"
          />
          <div class="el-form-item">
            <label class="el-form-item__label">移动端功能权限</label>
          </div>
          <el-tree
            v-if="openPanelApp"
            ref="treeApp"
            show-checkbox
            node-key="name"
            :data="appMenu"
            :props="defaultProps"
            :default-expanded-keys="['全部']"
            :default-checked-keys="authApp"
            :render-content="renderContentApp"
          />
          <!-- <div class="el-form-item">
            <label class="el-form-item__label">通知权限</label>
          </div>
          <el-radio
            v-model="isEquipmentRepairNotify"
            :label="1"
            style="margin: 0 0 0 20px"
          >接受设备维保通知</el-radio> -->
        </div>
        <div v-if="params.radio === 'Data'" style="margin: 10px 20px;">
          <div class="all-btns" style="justify-content: end;">
            <el-button size="small" type="primary" class="role-btn" @click="saveData">保存</el-button>
          </div>
            <div v-for="item in dataList" :key="item.id">
              <div style="padding: 10px 0;">{{ item.moduleName }}</div> 
              <template>
                <el-table
                  :data="item.submoduleList"
                  style="width: 100%"
                  border
                  :show-header="false">
                  <el-table-column
                    prop="submoduleName"
                    label="模块名称"
                    width="180">
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label="是否全部"
                    width="180">
                    <template slot-scope="scope">
                    <el-switch
                              v-model="scope.row.isAll"
                              active-text="全部"
                              @change="switchDataRule(scope.row)"
                            />
                            </template>
                  </el-table-column>
                  <el-table-column
                    prop="dataRuleGroupList"
                    label="子模块列表">
                    <template slot-scope="scope">
                      <div v-for="items in scope.row.dataRuleGroupList" :key="items.id"  style="display:inline-block;padding-right: 10px;">
                        <el-checkbox @change="(val) => { selectCheck(val, scope.row)}"
                                    v-model="items.checked"  @mouseenter.native="enter(items.id)"
                                  >{{ items.ruleGroupName }}</el-checkbox>
                                  <div style="font-size:12px;color:#607FFF;cursor: pointer;display: inline-block;margin-left: 4px;" v-if="abnormal == items.id && !items.isSystem">
                                    <span @click="editCustom(items)">编辑</span>
                                    <span style="padding:4px">|</span>
                                    <span @click="delCustom(items.id)">删除</span>
                                  </div>
                              </div>
                      <span style="color:red;cursor: pointer;" @click="addCustomDataRuleGroup(scope.row)">自定义</span>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
        </div>
        <div v-if="params.radio === 'User'" style="margin: 10px 20px;">
          <MTable ref="mTableUser" :columns="getColumns" :height="height" :set-data-method="getTableDatas" :columns-setting="false">
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="organizationId" slot-scope="{ row}">{{ row.organizationName }}</div>
            <div slot="isSystemUser" slot-scope="{ row }">{{ +row.isSystemUser === 1 ? '是' : '否' }}</div>
            <!--  查看可执行工位-->
            <el-button slot="station" slot-scope="{ row }" type="text" @click="viewStation(row)">查看</el-button>
            <!-- 查看设备 -->
            <el-button slot="setting" slot-scope="{ row }" type="text" @click="viewSetting(row)">查看</el-button>
            <!-- 角色列表-->
            <div slot="roleList" slot-scope="{ row }">
              <el-tag v-for="item in row.roleList" :key="item.id" color="#F6F7F9" class="role-tags">{{ item.name }}</el-tag>
            </div>
            <div slot="isEnabled" slot-scope="{ row }" class="isEnabled">
              <el-switch
                :value="row.isEnabled === 1"
                active-color="#13ce66"
                inactive-color="#eee"
                @change="switchStatusUser(row, 'User')"
              />
              <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
            </div>
            <div slot="action" slot-scope="{ row }">
              <template v-if="row.isSystem === 0">
                <el-button v-if="permission('UserSetStations') && memberCode!='mom'" type="text" @click="setStation(row)">设置工位</el-button>
                <el-divider v-if="permission('UserSetStations') && memberCode!='mom'" direction="vertical" />
                <el-button v-if="permission('UserSetEquipments') && memberCode!='mom'" type="text" @click="setEquipment(row)">设置设备</el-button>
                <el-divider v-if="permission('UserSetEquipments') && memberCode!='mom'" direction="vertical" />
                <template v-if="memberCode === 'xwhj-sh'">
                  <el-button
                    v-if="permission('UserModify')"
                    type="text"
                    @click="modifyOrCopy(row, 'modify')"
                  >
                    修改
                  </el-button>
                  <el-divider v-if="permission('UserModify')" direction="vertical" />
                  <el-button
                    v-if="permission('UserDel')"
                    type="text"
                    @click="dels(row)"
                  >
                    删除
                  </el-button>
                  <el-divider v-if="permission('UserDel') && isSystem" direction="vertical" />
                  <el-button v-if="isSystem" type="text" @click="resetPassword(row)">重置密码</el-button>
                </template>
                <el-dropdown v-if="memberCode !== 'xwhj-sh'" @command="(command) => { more(row, command) }">
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right" />
                  </span>
                  <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
                    <el-dropdown-item command="modify" v-if="permission('UserModify')">修改</el-dropdown-item>
                    <el-dropdown-item command="del" v-if="permission('UserDel')">删除</el-dropdown-item>
                    <el-dropdown-item v-if="isSystem" command="resetPassword">重置密码</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <template v-else>
                <el-button v-if="isSystem" type="text" @click="resetPassword(row)">重置密码</el-button>
              </template>
            </div>
          </MTable>
        </div>
      </template>
    </Split>

    <MDialog
      v-model="visible"
      :title="messages[currentType]"
      @onOk="submitForm"
    >
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog>
    <!--    设置工位-->
    <MDialog v-model="stationVisible" title="设置工位" @onOk="submitStation">
      <MElTransfer
        v-model="selectStationList"
        :data="formOptions.stationList"
        :props="{key: 'id', label: 'name'}"
        :titles="['源列表', '目的列表']"
        :filterable="true"
        filter-placeholder="请输入名称"
      />
    </MDialog>
    <!-- 关联设备 -->
    <select-setting
      ref="selectSetting"
      :visible.sync="setsVisible"
      :select-materials="selectEquipment"
      @submitForm="submitSetting"
    />
    <MDialog v-model="setVisible" title="查看设备" @onOk="setVisible = false">
      <MTable ref="mTables" :columns="viewSetColumns" :height="320" :data="equipmentList" :show-page="false" :columns-setting="false">
        <div slot="drawing" slot-scope="{ row }">
          <el-image
            v-if="row.drawing"
            :src="row.drawing"
            class="drawing-img"
            :preview-src-list="fixImg(row.drawing)"
          />
        </div>
        <div slot="action" slot-scope="{ row }">
          <el-button v-if="isSystems === 0" type="text" @click="resetEquipment(row)">解绑</el-button>
          <el-button v-else type="text" disabled>解绑</el-button>
        </div>
      </MTable>
    </MDialog>
    <TableDialog title="查看用户" :show.sync="userVisible" :columns="viewTableColumns" :table-data="viewTableData" />
    <MDialog v-model="visibles" title="修改" width="500px" @onOk="submitFormUser">
      <MFormBuilder ref="formBuilds" label-position="right" :form-data="formDataUser" :form-list="formLists['User']" :custom-rules="rules" />
    </MDialog>
    <MDialog v-model="filterVisible" title="筛选配置" @onOk="submitFormFilter">
         <el-input v-model="ruleGroupName" placeholder="请输入名称" style="width:35%"></el-input>
        <div v-for="(item, index) in modules" :key="index">
            <el-form :model="item.customForm" style="display:flex;" ref="ruleForm" :style="{ paddingTop: index === 0 ? '15px' : '' }">
              <el-form-item style="width:35%">
                <el-select style="display: block !important;" v-model="item.customForm.tableColumnInfoId" placeholder="应用字段"
                  clearable @change="(val) => { changeBusinessId(val, index)}">
                  <el-option v-for="items in businessList" :key="items.id" :label="items.businessName" :value="items.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item style="width:20%;padding-left: 10px;">
                <el-select style="display: block !important;" v-model="item.customForm.operatorName" placeholder="运算符"
                  clearable @change="(val) => { changeOperator(val, item,index)}">
                  <el-option v-for="items in item.operatorList" :key="items.operatorName" :label="items.operatorName" :value="items.operatorName">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item style="width:35%;margin-left: 10px;" >
                <el-input v-model="item.customForm.ruleValue" placeholder="请输入" v-if="item.customForm.filterType === 'string'"></el-input>
              <!-- 单选 -->
                <el-select style="display: block !important;" v-model="item.customForm.ruleValue" placeholder="请选择" clearable v-if="!item.isMul && (item.customForm.filterType === 'ass-single-choice' || item.customForm.filterType === 'ass-multiple-choice')">
                  <el-option v-for="items in item.customList" :key="items.id" :label="items.name" :value="items.id">
                  </el-option>
                </el-select>
                <!-- 多选 -->
                <el-select style="display: block !important;" v-model="item.customForm.ruleValueMul" placeholder="请选择" clearable multiple v-if="item.isMul && (item.customForm.filterType === 'ass-single-choice' || item.customForm.filterType === 'ass-multiple-choice')">
                  <el-option v-for="items in item.customList" :key="items.id" :label="items.name" :value="items.id">
                  </el-option>
                </el-select>
                <el-input-number
                v-model="item.customForm.ruleValue"
                style="width:100%"
                v-if="item.customForm.filterType === 'num'"
                controls-position="right"
              />
                <el-date-picker v-model="item.customForm.ruleValue" v-if="item.customForm.filterType === 'time'" style="width:100%" type="datetime" placeholder="请选择" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" />
              </el-form-item>
              <img class="addPro" v-show="modules.length>1" @click="removeThick(index)" src="@/assets/information/procedure/remove.png" alt="" width="16"/>
              </el-form>
          </div>
        <div style="display: flex;align-items: center;">
          <img class="addPro" style="margin-top: 0;" src="@/assets/information/procedure/新增2.png"
             @click="addThick" alt="" width="16" />
             <span style="margin-left: 2px;">且条件</span>
        </div>
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import DelPopover from '@/components/DelPopover/index'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/sets/role/role'
import uApi from '@/api/sets/user/user'
import uapi from '@/api/user'
import baseSetting from '@/api/sets/base-setting'
import { columns, appMenu, viewStations, viewSetColumns, User } from './columns'
import { getFunctionList } from '../user/columns'
import formList from './form-list'
import formLists from '../user/form-list'
import { constantRoutes } from '@/router'
import Split from '@/components/Split/Split'
import { Encrypt } from '@/utils/sercet'
import SelectSetting from '@/components/SelectSetting/SelectSetting'
import stationApi from '@/api/information/production/station'
import MElTransfer from '@/components/EleComponents/transfer/main'
import TableDialog from '@/components/TableDialog/TableDialog'

export default {
  name: 'Role',
  components: { TableDialog, MElTransfer, SelectSetting, Split, FunctionHeader, DelPopover },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      User,
      formLists,
      viewStations,
      viewSetColumns,
      split: {
        offset: 18
      },
      currentId: -1,
      dataList:[],
      params: {
        radio: 'Role'
      },
      columns,
      appMenu,
      visible: false,
      formList: formList,
      formData: {},
      currentType: 'add',
      formOptions: {
        userOptions: [],
        roleOptions: [],
        stationList: []
      },
      searchKeyword: true,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      defaultPropsView: {
        children: 'children',
        label: 'name',
        disabled: 'name'
      },
      constantRoutes: [],
      auths: [],
      authApp: [],
      authVisible: false,
      isEquipmentRepairNotify: 0,
      baseRouters: {},
      list: [],
      isSystem: false,
      selectStationList: [],
      setVisible: false,
      setsVisible: false,
      stationVisible: false,
      formDatas: {},
      selectEquipment: [],
      equipmentList: [],
      selectUserId: undefined,
      formDataUser: {},
      visibles: false,
      userVisible: false,
      viewTableColumns: [],
      viewTableData: [],
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3567894]\d{9}))$/, message: '请输入正确的手机号/电话号', trigger: 'blur'
          }
        ]
      },
      organizationList: [],
      openPanel: false,
      openPanelApp: false,
      filterVisible: false,
      modules: [
        {
          customForm: {
            ruleValue:undefined,
            ruleValueMul:[],
            filterType:'string'
          }
        }],
        ruleGroupName:'',
        businessList:[],
        tableInfoId:'',
        userList:[],
        abnormal:-1,
        type:'add',
        ruleId:''
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192 - 35
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 75
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getColumns() {
        if (this.memberCode != 'mom') {
        return this.User
      } else {
        return this.User.filter(item => {
          return item.prop !== 'station' && item.prop !== 'setting'
        })
      }
      }
  },
  beforeMount() {
    getFunctionList({
      paramsTypeChange: this.paramsTypeChange
    })
    this.constantRoutes = [
      {
        name: 'all',
        meta: { title: '全部' },
        children: constantRoutes.filter((item) => !item.hidden)
      }
    ]
    this.appMenu = [
      {
        name: '全部',
        children: appMenu
      }
    ]
    this.getBaseSetting()
    this.getList()
    this.getStationList()
  },
  methods: {
    changeBusinessId(val,index){
      let targetItem = this.businessList.find(item => item.id === val)
      if (targetItem) {
        this.modules[index].operatorList = targetItem.operatorList
        this.modules[index].customForm = {
          tableColumnInfoId:targetItem.id,
          filterType : targetItem.filterType,
          associationMark:targetItem.associationMark,
          operatorName: '',
          operatorCode: '',
          ruleValue:undefined,
          ruleValueMul:[]
        }
        this.modules[index].customList = targetItem.associationMark === 'User' ? this.userList : targetItem.associationMark === 'Organization' ? this.organizationList : []
      }else{
        this.modules[index].operatorList = []
        this.modules[index].customForm.filterType = 'string'
        this.modules[index].customForm.operatorName = ''
        this.modules[index].customForm.operatorCode = ''
        this.modules[index].customForm.ruleValue = undefined
        this.modules[index].customForm.ruleValueMul = []
        }
    },
    changeOperator(val,item,index){
      let mulList = ['包含','不包含','属于','不属于']
      let targetItem = item.operatorList.find(item => item.operatorName === val)
      if (targetItem) {
        this.modules[index].customForm.operatorCode = targetItem.operatorCode
      }
      this.modules[index].customForm.ruleValue = undefined
      this.modules[index].customForm.ruleValueMul = []
      if(item.customForm.associationMark === 'User' || item.customForm.associationMark === 'Organization'){
        this.modules[index].isMul = mulList.includes(val) ? true : false
      }
    },
    addThick() {
      this.modules.push({
        customForm: {
          ruleValue: undefined,
          ruleValueMul:[],
          filterType:'string'
        }
      })
    },
    removeThick(index) {
      if (this.modules.length <= 1) {
          this.$message.error('不允许删除')
      } else {
          this.modules.splice(index, 1)
      }
  },
    async getDataPermissionList(){
       const res = await api.getDataPermissionList({roleId: this.currentId})
       if(res){
         this.dataList = res.map(item=>{
          return {
            ...item,
            submoduleList:item.submoduleList.map(items =>{
              return {
                ...items,
                isAll:items.dataRuleGroupList.every(child => child.checked === false)
              }
            })
          }
         })
       }
    },
    async addCustomDataRuleGroup(row){
      this.type = 'add'
      this.ruleGroupName = ''
      this.modules = [{
          customForm: {
            filterType:'string',
            ruleValue: undefined,
            ruleValueMul:[]
          }
        }]
      this.tableInfoId = row.tableInfoId 
      const res = await api.getCustomDataRuleInfo({tableInfoId:row.tableInfoId})
      if(res){
        this.businessList = res.columnInfoList
        this.filterVisible = true
      }
     
    },
    switchDataRule(val){
      if(val.isAll){
        val.dataRuleGroupList.forEach(item =>{
        item.checked = false
      })
      }
    },
    selectCheck(val,val1){
      val1.isAll = val1.dataRuleGroupList.every(child => child.checked === false)
    },
    async getStationList() {
      const res = await stationApi.getStationList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.formOptions.stationList = res
      }
    },
    async getOrganizationList() {
      const res = await uApi.getOrganizationList()
      if (res) {
        this.formLists['User'][3].children[0].options = res
        this.organizationList = res
      }
    },
    async getUserGroupList() {
      const res = await uApi.getUserGroupList()
      if (res) {
        // this.formOptions.userGroupOptions = res
        this.formLists.User[6].children[0].options = res
      }
    },
    paramsTypeChange(val) {
      if (val === 0) {
        this.formLists['User'][8].hidden = true
        this.formLists['User'][9].hidden = true
        this.formLists['User'][10].hidden = true
        this.$refs.formBuilds.setForm({
          password: '',
          userName: '',
          roleIdList: []
        })
      } else {
        this.formLists['User'][8].hidden = false
        this.formLists['User'][9].hidden = false
        this.formLists['User'][10].hidden = false
      }
    },
    fixImg(img) {
      return [img]
    },
    submitFormUser(callback) {
      const { form } = this.$refs.formBuilds
      this.$refs.formBuilds.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await uApi[`modifyUser`](
            this.createSetDatas(form, this.formDataUser.id)
          )
          if (res) {
            this.$message.success('修改成功')
            this.$refs.mTableUser.setTableData()
            this.visibles = false
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    async submitFormFilter(callback){
      let arr = this.modules.map(item => {
        return {
          tableColumnInfoId: item.customForm.tableColumnInfoId,
          operatorName: item.customForm.operatorName,
          operatorCode: item.customForm.operatorCode,
          ruleValue: !item.isMul ? item.customForm.ruleValue : '^' + item.customForm.ruleValueMul.join('^') + '^',
        }
      })
      let obj = {
        tableInfoId:this.tableInfoId,
        ruleGroupName:this.ruleGroupName,
        dataRuleList:arr
      }
      let api
      if(this.type === 'add'){
        api = this.api.addCustomDataRuleGroup
      }else{
        api = this.api.updateCustomDataRuleGroup
        Object.assign(obj, {id:this.ruleId})
      }
      const res = await api(obj)
      if(res){
        this.filterVisible = false
        this.abnormal = -1
        this.$message.success(this.type === 'add' ? '新增成功' : '修改成功')
        this.getDataPermissionList()
        callback(true)
      }else{
        callback()
      }
    },
    // 更改状态
    async switchStatusUser(row, path) {
      const res = await uApi.switchStatus(row.isEnabled ? 'disable' : 'enable', path.replace(path[0], path[0].toLowerCase()), row.id)
      if (res) {
        this.$message.success(`${row.name || row.reason}${row.isEnabled ? '停用' : '启用'}成功`)
        this.$refs.mTableUser.setTableData()
      }
    },
    async modifyUser(row) {
      this.formLists['User'][10].children[0].options = this.list
      await this.getOrganizationList()
      await this.getUserGroupList()
      const currentKey = 'roleIdList'
      const res = await api.getRoleListByUserId(row.id)
      if (res) {
        this.formDataUser = {
          ...row,
          [currentKey]: res.map(item => item.id)
        }
        this.visibles = true
        if (row.isSystemUser === 0) {
          this.formLists['User'][8].hidden = true
          this.formLists['User'][9].hidden = true
          this.formLists['User'][10].hidden = true
        } else {
          this.formLists['User'][8].hidden = false
          this.formLists['User'][9].hidden = false
          this.formLists['User'][10].hidden = false
        }
      }
    },
    async resetEquipment(row) {
      const res = await uapi.delUserEquipment(this.selectUserId, row.id)
      if (res) {
        this.getUserEquipments(this.selectUserId)
      }
    },
    async delRoles(row) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await this.api[`delRole`]({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.getList()
        }
      })
    },
    submitForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await this.api[`${this.currentType}${this.params.radio}`](
            this.currentType !== 'modify' ? this.createSetData(form) : this.createResetData(form)
          )
          if (res) {
            this.$message.success(this.getSuccessMessage())
            this.visible = false
            this.getList()
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    more(row, command) {
      const functions = {
        del: () => {
          this.dels(row)
        },
        modify: () => {
          this.modifyUser(row, command)
        },
        resetPassword: () => {
          this.resetPassword(row)
        }
      }
      functions[command]()
    },
    async dels(row) {
      const that = this
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await uApi.delUser({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTableUser.setTableData(true)
        }
      })
    },
    async currentUserIsSystem() {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      const res = await uApi.getUserDetail(userInfo.id)
      if (res) {
        this.isSystem = !!res.isSystem
      }
    },
    // 获取自定义用户
    async getUserList(){
     const res = await uApi.getUserList()
     this.userList = res
     this.userList.unshift({id:-1,name:'登录用户'})
    },
    enter(id){
     this.abnormal = id
    },
    async editCustom(val){
      this.ruleId = val.id
      this.tableInfoId = val.tableInfoId 
      this.type = 'modify'
      let mulList = ['包含','不包含','属于','不属于']
      const res = await api.getDataRuleGroupDetail({id:val.id})
      if(res){
        const res1 = await api.getCustomDataRuleInfo({tableInfoId:val.tableInfoId})
      if(res1){
        this.businessList = res1.columnInfoList
        this.modules = []
        res.dataRuleList.forEach((item, index) => {
          let module = {
            customForm: {
              ruleValue: undefined,
              ruleValueMul: [],
              filterType: 'string'
            }
          };
        this.modules.push(module)
        let targetItem = this.businessList.find(itemss => itemss.id === item.tableColumnInfoId)
      if (targetItem) {
        let filterType = targetItem.filterType;
        let associationMark = targetItem.associationMark;

        this.modules[index].operatorList = targetItem.operatorList
        this.modules[index].customForm = {
          tableColumnInfoId:targetItem.id,
          filterType : filterType,
          associationMark:associationMark,
          operatorName: item.operatorName,
          operatorCode: item.operatorCode,
          ruleValue:((filterType === 'ass-single-choice' || filterType === 'ass-multiple-choice') && item.ruleValue) ? parseInt(item.ruleValue) : item.ruleValue,
          ruleValueMul:[]
        }

        if (associationMark === 'User' || associationMark === 'Organization') {
                this.modules[index].customList = associationMark === 'User' ? this.userList : this.organizationList;
                this.modules[index].isMul = mulList.includes(item.operatorName);

                if (this.modules[index].isMul) {
                    this.modules[index].customForm.ruleValueMul = item.ruleValue.split('^').map(val1 => parseInt(val1)).filter(val1 => !isNaN(val1));
                }
            }
          }
        })
      }
      this.ruleGroupName = res.ruleGroupName
      }
       this.filterVisible = true
    },
    delCustom(val){
      this.$confirm('是否删除此数据权限', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.deleteCustomDataRuleGroup({ id: val })
        if (res) {
          this.$message.success('删除成功')
          this.abnormal = -1
          this.getDataPermissionList()
        }
      })
    },
    // 获取自定义组织
    async getCustomOrganizationList() {
      const res = await uApi.getOrganizationList()
     if(res){
      this.organizationList = res
      this.organizationList.unshift({id:-1,name:'登录用户所属组织'})
     }
      },
    async getTableDatas(condition, callback) {
      const { page, search, order } = condition
      const searchParams = {
        sort: order,
        searchVal: Encrypt(JSON.stringify([...search]))
      }
      const res = await uApi[`getUserListPage`]({
        page: page.currentPage,
        limit: page.pageSize,
        isRole: 1,
        roleId: this.currentId,
        ...searchParams
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    async viewStation(row) {
      const res = await stationApi.getStationListByUserId(row.id)
      if (res && res.length) {
        this.viewTableData = res
        this.viewTableColumns = this.viewStations
        this.userVisible = true
      } else {
        this.$message.info('暂无可执行工位')
      }
    },
    async viewSetting(row) {
      this.selectUserId = row.id
      await this.getUserEquipments(row.id)
      this.setVisible = true
      this.isSystems = row.isSystem
    },
    async submitStation(callback) {
      const res = await uApi.modifyUserStation({
        id: this.formDatas.id,
        userName: this.formDatas.userName,
        stationIdList: this.selectStationList
      })
      if (res) {
        this.$message.success('设置成功')
        this.stationVisible = false
        callback(false)
      }
    },
    async setStation(row) {
      const res = await stationApi.getStationListByUserId(row.id)
      if (res) {
        this.formDatas = row
        this.selectStationList = res.map(item => item.id)
        this.stationVisible = true
      }
    },
    async getUserEquipments(id) {
      const res = await uapi.getUserEquipmentLists(id)
      if (res) {
        this.equipmentList = res
      }
    },
    async submitSetting(data) {
      const list = data.map(item => item.id)
      const res = await uapi.modifyUserEquipment(this.selectUserId, list)
      if (res) {
        this.setsVisible = false
        this.$refs.mTableUser.setTableData()
      }
    },
    async setEquipment(row) {
      this.selectUserId = row.id
      await this.getUserEquipments(row.id)
      this.selectEquipment = this.equipmentList
      this.setsVisible = true
    },
    // 重置密码
    resetPassword(row) {
      this.$confirm(`您确定要重置用户（${row.name}）的密码吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await uApi.resetPassword(row.id)
        if (res) {
          this.$message.success('密码已重置为123')
          this.$refs.mTableUser.setTableData()
        }
      })
    },
    async saveRole() {
      this.currentType = 'modify'
      const checked = this.$refs.tree.getCheckedNodes().map((item) => {
        return item.name
      })
      if (checked.length === 0) {
        return this.$message.error('网页端功能权限不能为空')
      }
      // const checkedApp = this.$refs.treeApp.getCheckedNodes().map((item) => {
      //   return item.name
      // })
      // if (checkedApp.length === 0) {
      //   return this.$message.error('移动端端功能权限不能为空')
      // }
      const reqMsg = this.createResetData(this.formData);
      const finalRouteName = reqMsg.routeMsgForSet;
      // console.log("web端路由名称信息",JSON.stringify(finalRouteName));
      //app端路由处理 ————————Start————————
      const wert = [...JSON.parse(reqMsg.appAuthority ).checkedApp,...JSON.parse(reqMsg.appAuthority ).halfApp]
      const setInfo = new Set();
      wert.forEach(p =>{
        setInfo.add(p);
      })
      const finalRouteAppName = [...setInfo];
      // console.log("app端路由名称信息",JSON.stringify(finalRouteAppName));
      //app端路由处理 ————————End————————
      const res = await this.api.modifyRole(
        reqMsg
      )
      if (res) {
        this.$message.success('修改成功')
        this.getList(1)
      }
    },
    async saveData(){
      const arr = []
      this.dataList.forEach(item => {
        item.submoduleList.forEach(items => {
        if (!items.isAll && items.dataRuleGroupList.every(child => child.checked === false)) {
          arr.push(items.submoduleName)
        }
        })
      })
      if(arr.length){
        return this.$message.error(`${arr.join('、')}的数据权限未配置`)
      }
      const checkedIds = this.dataList.flatMap(item => 
      item.submoduleList.flatMap(items => 
        items.dataRuleGroupList.filter(child => child.checked).map(child => child.id)
      )
    )
      const res = await api.updateDataPermission({
        roleId: this.currentId,
        dataRuleGroupIdList:checkedIds
      }
      )
      if(res){
        this.$message.success('修改成功')
        this.getDataPermissionList()
      }
    },
    radioChange(type) {
      this.params.radio = type
      this.currentUserIsSystem()
      if (this.params.radio === 'Role') {
        const data = this.list.filter(item => item.id === this.currentId)
        this.onChangeRole(data[0])
      }
    },
    onChangeRole(row, idx) {
      this.currentId = row.id
      this.formData = this._.cloneDeep(row)
      setTimeout(() => {
        const auth = row.authority && JSON.parse(row.authority)
        const authApp = row.appAuthority && JSON.parse(row.appAuthority)
        this.auths = auth ? auth.checked : []
        this.authApp = authApp ? authApp.checkedApp : []
        // <el-tree>组件使用v-if重新加载
        this.openPanel = false
        this.openPanelApp = false
        this.$nextTick(() => {
          this.openPanel = true
          this.openPanelApp = true
        })
      }, 100)

      this.isEquipmentRepairNotify = row.isEquipmentRepairNotify
      if (this.$refs.mTableUser !== undefined) {
        this.$refs.mTableUser.setTableData(true)
      }
      this.getDataPermissionList()
      this.getUserList()
      this.getCustomOrganizationList()
    },
    async getList(val) {
      const res = await api.getRoleList()
      if (res) {
        this.list = res
        if (val !== 1 && res.length > 0) {
          this.onChangeRole(res[0], 0)
        }
      }
    },
    onAddRole() {
      this.add()
      this.createCode()
    },
    async createCode() {
      if (sessionStorage.getItem('memberCode') !== 'mubai') return false
      const res = await api.createCode({
        count: 1
      })
      if (res) {
        this.$refs.formBuild.setForm({
          code: res[0]
        })
      }
    },
    getRouterTree(router) {
      const newRouters = []
      router.forEach(item => {
        if (item.children && item.children.length) {
          newRouters.push({
            ...item,
            children: this.getRouterTree(item.children)
          })
        } else if (item.meta && item.meta.buttons) {
          let buts = typeof item.meta.buttons === 'function' ? item.meta.buttons() : item.meta.buttons;
          // 处理按钮 给按钮节点打上标记
          buts.forEach(p => {
            if(!p.children){
              p.btnFlag = true;
            }else{
              p.children.forEach(m => {
                m.btnFlag = true
              })
            }
          })
          newRouters.push({
            ...item,
            children: buts
          })
        } else {
          newRouters.push(item)
        }
      })
      return newRouters
    },
    async getBaseSetting() {
      const res = await baseSetting.getCode({ code: 'base_router' })
      if (res) {
        this.baseRouters = JSON.parse(res.value)
        await this.deepEachTree(this.constantRoutes, this.baseRouters.web)
        this.deepEachTree(this.appMenu, this.baseRouters.app)
        this.constantRoutes[0].children = this.getRouterTree(this.constantRoutes[0].children)
      }
    },
    deepEachTree(data, conditionData) {
      data.forEach(child => {
        if (child.children && child.children.length) {
          child.children = child.children.filter(c => conditionData.indexOf(c.name) !== -1 || c.name === "Fun")
          this.deepEachTree(child.children, conditionData)
        }
      })
    },
    renderContent(h, { data }) {
      return h('span', data.meta ? data.meta.title ? data.meta.title : data.meta.roleTitle : data.title ? data.title : data.roleTitle)
    },
    renderContentApp(h, { data }) {
      return h('span', data.name)
    },
    modifyOrCopy(row, command) {
      this.formData = row
      const auth = row.authority && JSON.parse(row.authority)
      const authApp = row.appAuthority && JSON.parse(row.appAuthority)
      this.auths = auth ? auth.checked : []
      this.authApp = authApp ? authApp.checkedApp : []
      this.isEquipmentRepairNotify = row.isEquipmentRepairNotify
      this.currentType = command
      this.visible = true
    },
    viewAuth(row) {
      const auth = row.authority && JSON.parse(row.authority)
      const authApp = row.appAuthority && JSON.parse(row.appAuthority)
      this.auths = auth ? auth.checked : []
      this.authApp = authApp ? authApp.checkedApp : []
      this.isEquipmentRepairNotify = row.isEquipmentRepairNotify
      this.authVisible = true
    },
    createSetData(raw) {
      if (this.currentType === 'add') {
        return {
          name: raw.name,
          isEnabled: 1
        }
      }
      const checked = this.$refs.tree.getCheckedNodes().map((item) => {
        return item.name
      })
      const half = this.$refs.tree.getHalfCheckedNodes().map((item) => {
        return item.name
      })

      // 拿到去除buttons的 仅仅是路由的name列表
      const checkedForSet = [];
      this.$refs.tree.getCheckedNodes().filter((item) => {
        if(!item.btnFlag){
          checkedForSet.push(item.name);
        }
      })
      const halfForSet = [];
      this.$refs.tree.getHalfCheckedNodes().filter((item) => {
        if(!item.btnFlag){
          halfForSet.push(item.name);
        }
      })
      // console.log('checked :>> ', checked);
      // console.log('half :>> ', half);
      // console.log('checkedForSet :>> ', checkedForSet);
      // console.log('halfForSet :>> ', halfForSet);
      const checkedApp = this.$refs.treeApp.getCheckedNodes().map((item) => {
        return item.name
      })
      const halfApp = this.$refs.treeApp.getHalfCheckedNodes().map((item) => {
        return item.name
      })
      return {
        // code: raw.code,
        name: raw.name,
        authority: JSON.stringify({ checked, half }),
        appAuthority: JSON.stringify({ checkedApp, halfApp }),
        routeMsgForSet:[...checkedForSet,...halfForSet],
        isEnabled: 1,
        remark: raw.remark,
        isEquipmentRepairNotify: this.isEquipmentRepairNotify
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    createSetDatas(raw, id) {
      console.log('ceshi=>', raw.organizationName)
      const organizationName = raw.organizationId ? this.organizationList.filter(item => item.id === raw.organizationId)[0].name : ''
      return {
        id: id,
        name: raw.name,
        phone: raw.phone,
        roleIdList: raw.roleIdList,
        userName: raw.userName,
        isEnabled: 1,
        isSystemUser: raw.isSystemUser,
        jobNumber: raw.jobNumber,
        organizationId: raw.organizationId,
        organizationName: organizationName,
        email: raw.email,
        password: raw.password,
        userType: raw.userType
      }
    }
  }
}
</script>

<style scoped lang="scss">
.role-wrap {
  padding: 10px;
  .addPro {
    margin: 8px 0 0 8px;
    width: 16px;
    height: 16px;
  }
  .role-tags {
    margin: 5px;
    border-color: #dde3eb;
    color: #9597ae;
  }
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }
  .isEnabled {
    display: flex;
    .el-switch {
      margin-right: 10px;
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
  .all-btns {
    display: flex;
    justify-content: space-between;
    .label {
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
      line-height: 40px;
      padding: 0 12px 0 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .role-btn {
      height: 32px;
      margin-top: 4px;
    }
  }
  .group {
    background: #ffffff;
    padding: 10px;
    width: 100%;
    height: 100%;
    overflow: auto;

    .top {
      width: 100%;
      margin: 10px 0;
      padding: 10px;
      cursor: pointer;
      color: #8585F9;
      border: 1px dashed #8585F9;

      .title {
        text-align: center;
      }
    }

    .content {
      height: 360px;

      .Warehouse {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 34px;
        padding: 10px;

        &.active {
          color: #8099ff;
        }

        &:hover {
          background-color: rgba(248, 248, 249, 1);
        }

        .ellipsis {
          display: inline-block;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }

        .modify,
        .del {
          display: inline-block;
          width: 14px;
          height: 14px;
        }

        .modify {
          margin-right: 10px;
          background: url("../../../assets/quality/自定义标题@2x.png");
          background-size: contain;
          cursor: pointer;
          &:hover {
            background: url("../../../assets/quality/自定义标题备份@2x.png");
            background-size: contain;
          }
        }

        .del {
          background: url("../../../assets/quality/3.0-删除@2x.png");
          background-size: contain;
          cursor: pointer;
          &:hover {
            background: url("../../../assets/quality/3.0-删除备份@2x.png");
            background-size: contain;
          }
        }
      }
    }
  }
}
</style>

