
import { formatColumns } from '@/utils'
const types = [{
  id: 0,
  name: '系统生成'
},
{
  id: 1,
  name: '手动输入'
}]
export const columns = [
  {
    prop: 'number',
    label: '编号',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', props: { disabled: true }, colSpan: 24 }
  },
  {
    prop: 'createMode',
    label: '生成方式',
    sortable: false,
    hiddenSearch: true,
    slotName: 'createMode',
    form: {
      tagName: 'el-select',
      colSpan: 24,
      options: types,
      props: {
        clearable: false
      }
    }
  },
  {
    prop: 'numberingRule',
    label: '编号规则',
    sortable: false,
    hiddenSearch: true,
    form: {
      key: 'numberingRule',
      tagName: 'div',
      colSpan: 24,
      required: false,
      customSlots: ['numberingRule']
    }
  },
  {
    prop: 'example',
    label: '示例',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 120
  }
]

export default columns
