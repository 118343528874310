<template>
  <div class="detail-wrappers">
    <div class="detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
    </div>
    <div class="detail-body">
      <div class="detail-card">
        <div class="card-tit">基础信息</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in columns" :key="item.id" :span="6">
              <div v-if="item.prop === 'customerSiteIdList'">
                <div class="view-list__label">{{ item.label }}</div>
                <div class="view-list__value">{{ baseFormData['customerSiteIdNames'] || '-' }}</div>
              </div>
              <div v-else>
                <div class="view-list__label">{{ item.label }}</div>
                <div class="view-list__value">{{ getValue(item) || '-' }}</div>
              </div>
            </el-col>
          </el-row>
          <MFormBuilder v-else ref="baseForm" :form-list="formList" :form-data="baseFormData" label-position="right">
            <div slot="workOrderCode">
              {{ baseFormData.workOrderCode }}
            </div>
            <div slot="demandCode">
              {{ baseFormData.demandCode }}
            </div>
            <div slot="workOrderType">
              {{ baseFormData.workOrderTypeName }}
            </div>
          </MFormBuilder>
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">装机进度</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in processColumns" :key="item.id" :span="6">
              <div class="view-list__label">{{ item.label }}</div>
              <div class="view-list__value">{{ getValueProcess(item) || '-' }}</div>
            </el-col>
          </el-row>
          <MFormBuilder v-else ref="processBaseForm" :form-list="processFormList" :form-data="processFormData" label-position="right" />
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">现场记录</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in localFormList" :key="item.id" :span="item.colSpan">
              <div class="view-list__label">{{ item.label }}</div>
              <el-upload v-if="item.key == 'affix'" :file-list="attachments" v-bind="uploadProps2" disabled :class="{ 'upload-view': isView }" />
              <div v-else-if="item.key === 'facilityServiceTimeMappingList'">
                <!-- <el-card class="box-card" > -->
                <el-row v-for="item in executorList" :key="item.id" class="view-list" :gutter="24">
                  <el-col :span="6">
                    <!-- <div class="view-list__label">协作人</div> -->
                    <div class="view-list__value">{{ item.userNames || '-' }}</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="view-list__label">服务时间</div>
                    <div class="view-list__value">{{ `${item.startTime} ~ ${item.endTime}` }}</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="view-list__label">服务时长</div>
                    <div class="view-list__value">{{ item.manHour || 0 }}小时</div>
                  </el-col>
                </el-row>
                <!-- </el-card> -->
              </div>
              <div v-else class="view-list__value">
                {{ getLocalValue(item) || '-' }}
              </div>
            </el-col>
          </el-row>
          <MFormBuilder v-else ref="localForm" :form-list="localFormList" :form-data="baseFormDatas" label-position="right" class="hours-table">
            <div slot="manHour">{{ manHour }}小时</div>

            <div slot="facilityServiceTimeMappingList">
              <div v-for="(item, index) in executorList" :key="index" class="custom-form">
                <div class="custom-form-item">
                  <el-select v-model="item.userIds" multiple placeholder="添加协作人" filterable>
                    <el-option v-for="q in executorUserList" :key="q.id" :label="q.name" :value="q.id" />
                  </el-select>
                </div>
                <div class="custom-form-item">
                  <el-radio-group v-model="item.isSame" @change="onRadioChange(item.isSame, index)">
                    <el-radio :label="1">服务时间同执行人</el-radio>
                    <el-radio :label="0">
                      <el-date-picker
                        v-model="item.date"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="选择服务开始时间"
                        end-placeholder="选择服务结束时间"
                        value-format="yyyy-MM-dd HH:mm"
                        format="yyyy-MM-dd HH:mm"
                        clearable
                        :disabled="item.isSame === 1"
                        :picker-options="pickerOptions"
                        @change="datePickerChange(index)"
                      />
                    </el-radio>
                  </el-radio-group>
                  <span class="hour">
                    <label>服务时长</label>
                    {{ getTime(item) }}小时
                  </span>
                  <el-button type="text" @click="delExecutorList(index)">删除</el-button>
                </div>
              </div>
              <el-button size="small" type="primary" @click="addExecutor">添加</el-button>
            </div>

            <!-- <div slot="hoursDetail">
              <MTable ref="hoursTable" :show-page="false" :height="208" :columns="hoursColumns" :data="hoursList" :columns-setting="false">
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

                <div v-if="isView" slot="jobType" slot-scope="{ row }">
                  <div>{{ getJobType(row) }}</div>
                </div>
                <div v-else slot="jobType" slot-scope="{ row }" class="table-cell">
                  <span class="required-star">*</span>
                  <el-select v-model="row.jobType" placeholder="请选择工作类型">
                    <el-option v-for="item in jobTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </div>

                <div v-if="isView" slot="hour" slot-scope="{ row }">
                  <div>{{ row.hour }}小时</div>
                </div>
                <div v-else slot="hour" slot-scope="{ row }" class="table-cell">
                  <span class="required-star">*</span>
                  <el-input-number v-model="row.hour" :precision="1" :min="0" controls-position="right" :step="0.1" @change="onHourChange" />
                  <label style="padding-left: 10px">小时</label>
                </div>

                <div slot="action" slot-scope="{ $index }">
                  <template>
                    <el-button type="text" @click="delHours($index)">移除</el-button>
                  </template>
                </div>
              </MTable>
              <el-button class="e-btn" type="primary" size="small" @click="addHours">添加</el-button>
            </div> -->

            <el-upload slot="drawing" :file-list="attachments" v-bind="uploadProps2" :class="{ 'upload-view': isView }">
              <el-button type="primary" size="small">上传文件</el-button>
            </el-upload>
          </MFormBuilder>
        </div>
      </div>
    </div>
    <div v-if="!isView" class="detail-footer">
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/xiewei/service/repairs'
import dayjs from 'dayjs'
import keApi from '@/api/information/unit'
import demandApi from '@/api/xiewei/service/demand-center'
import fieldApi from '@/api/xiewei/field/field'
import userApi from '@/api/sets/user/user'
import facilityListApi from '@/api/xiewei/facility/facility'
import { Encrypt } from '@/utils/sercet'
import { getUUid, setTreeData } from '@/utils'
import { processColumns, columns, hoursColumns, getFunctionList } from './columns'
import { processFormList, formList, localFormList, getFormFunctionList, executorFormList } from './form-list'
import { getToken } from '@/utils/auth'
import { jobTypes } from '@/config/options.sales'

export default {
  name: 'InstallEquipmentDetail',
  components: {},
  data() {
    return {
      columns,
      processColumns,
      formList,
      processFormList,
      localFormList,
      executorFormList,
      processFormData: {},
      baseFormData: {},
      baseFormDatas: {},
      params: {
        type: 'view'
      },
      messages: {
        add: '提交成功',
        modify: '修改成功',
        viewO: '工单详情',
        addO: '创建工单',
        modifyO: '修改工单'
      },
      buttonLoading: false,
      executorList: [],
      executorUserList: [],
      uploadProps2: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onRemove: this.removeFile,
        onPreview: this.handlePreview
      },
      attachments: [],
      isView: this.$route.query.type === 'view',
      facilityList: [],
      consumerId: undefined,
      allCustomer: [],
      jobTypes,
      hoursList: [],
      hoursSum: 0,
      manHour: 0
    }
  },

  computed: {
    getDayDesc() {
      return (start, end) => {
        const format = 'YYYY年MM月DD日'
        if (start && end) {
          const isSameYear = dayjs(start).isSame(end, 'year')
          if (isSameYear) {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format('MM月DD日')}`
          } else {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format(format)}`
          }
        } else if (start) {
          return `${dayjs(start).format(format)}`
        } else if (end) {
          return `${dayjs(end).format(format)}`
        }
        return '-'
      }
    },

    getValue() {
      return row => {
        const result = this.baseFormData[row.prop]
        if (row.form && row.form.options && row.form.options.length > 0) {
          const current = row.form.options.find(item => item.id === result)
          return current && current.name
        }
        if (row.prop === 'facilityName') {
          return `${this.baseFormData.facilityCode} - ${this.baseFormData.facilityName}`
        }
        return result
      }
    },
    getValueProcess() {
      return row => {
        const result = this.processFormData[row.prop]
        if (row.form && row.form.options && row.form.options.length > 0) {
          const current = row.form.options.find(item => item.id === result)
          return current && current.name
        }

        return result
      }
    },

    getLocalValue() {
      return row => {
        let { key } = row
        if (row.tagName === 'el-select') {
          key = `${key}Name`
        }
        return (this.baseFormDatas || {})[key]
      }
    },

    hoursColumns() {
      return this.isView ? hoursColumns.filter(item => item.slotName !== 'action') : hoursColumns
    },

    getJobType() {
      return row => {
        const { jobType } = row
        return jobTypes.find(item => item.id === jobType).name
      }
    },
    getTime() {
      return item => {
        if (item.isSame === 1) {
          return item.manHour || 0
        } else {
          const { date } = item
          if (date && date.length) {
            const minutes = dayjs(date[0]).diff(dayjs(date[1]), 'minutes')
            const hour = (Math.abs(minutes) / 60).toFixed(1)
            return hour
          } else {
            return 0
          }
        }
      }
    },
    pickerOptions() {
      return {
        disabledDate: (time) => {
          const { form: localForm } = this.$refs.localForm
          return dayjs(time).isBefore(localForm.executionStartTime, 'day') || dayjs(time).isAfter(localForm.executionEndTime, 'day')
        }
      }
    }
  },

  watch: {
    manHour(val) {
      this.executorList = this.executorList.map(item => {
        return {
          ...item,
          manHour: item.isSame === 1 ? val : item.manHour
        }
      })
    }
  },

  beforeMount() {
    getFunctionList({
      mulChange: this.mulChange
    })
    getFormFunctionList({
      valueChange: this.valueChange
    })
  },

  async mounted() {
    this.params = this.$route.query
    if (this.params.demandId) {
      const infos = this.params.infos ? JSON.parse(this.params.infos) : {}
      this.baseFormData = {
        demandCode: this.params.code,
        demandId: parseInt(this.params.demandId),
        workOrderType: parseInt(this.params.content),
        workOrderTypeName: '装机',
        consumerId: this.params.type === 'add' ? infos.customerId : '',
        consumer: this.params.type === 'add' ? infos.customerName : '',
        customerSiteId: this.params.type === 'add' ? infos.customerSiteId : '',
        customerSiteIdList: this.params.type === 'add' ? infos.customerSiteIdList.split('^').map(item => Number(item)) : ''
      }
    }
    if (this.params.type !== 'add') {
      this.getDetail()
    }
    await this.getFacilityList()
    this.getAllCustomer()
    // this.getFacilityDemandList()
    this.getUserList()
    if (this.params.type !== 'view') {
      this.createFieldCode()
    }
  },

  methods: {
    mulChange(val) {
      const arr = this.allCustomer.filter(item => item.id === this._.first(val))
      const list = this.allCustomer.filter(item => item.id === this._.last(val))
      this.$refs.baseForm.setForm({
        facilityId: '',
        consumerId: +list[0].isCustomer === 0 ? list[0].id : arr[0].id,
        consumer: list[0].name,
        customerSiteId: +list[0].isCustomer === 1 ? list[0].id : ''
        // customerSiteName: +list[0].isCustomer === 1 ? list[0].name : '',
      })
      this.setFacilityList()
    },

    returns() {
      this.$router.back()
    },

    async getDetail() {
      const res = await api.getWordOrderDetail({
        demandId: parseInt(this.params.demandId),
        type: 2,
        wordOrderId: parseInt(this.params.wordOrderId)
      })
      if (res) {
        const localInfo = res.facilityRepairSite || {}
        this.attachments = JSON.parse(localInfo.affix || '[]')

        if (localInfo.facilityServiceTimeMappingList) {
          this.executorList = localInfo.facilityServiceTimeMappingList.map(item => {
            return {
              ...item,
              isSame: item.isSame,
              date: item.isSame === 1 ? [] : [item.startTime, item.endTime],
              userIds: item.userIds ? item.userIds.split('^').map(item => +item) : [],
              manHour: item.manHour
            }
          })
        }

        this.baseFormDatas = {
          otherBusiness: res.facilityRepairSite.otherBusiness,
          executionStartTime: res.executionStartTime,
          executionEndTime: res.executionEndTime
        }
        this.baseFormData = {
          ...this.baseFormData,
          ...res,
          customerSiteIdNames: `${res.customerSiteName ? res.customerSiteName : res.consumer}`
        }
        this.baseFormData.demandId = this.baseFormData.demandId === -1 ? '' : this.baseFormData.demandId
        this.baseFormData.customerSiteIdList = res.customerSiteIdList ? res.customerSiteIdList.split('^').map(item => Number(item)) : []
        this.setExecutionHours(this.baseFormData)
        this.processFormData = res.facilityInstallWordOrderAssemble
      }
    },

    async createFieldCode() {
      const res = await fieldApi.getFieldIsExist({
        numberVersion: '装机工单'
      })
      if (res && +res === 0) {
        if (this.params.type === 'add') {
          this.$refs.baseForm.setForm({
            workOrderCode: '系统生成'
          })
        }
        this.formList[0].props.disabled = true
      }
    },

    // 获取需求列表
    async getFacilityDemandList() {
      const res = await demandApi.getFacilityDemandList()
      if (res) {
        this.formList[1].children[0].options = res.map(item => {
          return {
            name: item.code,
            id: item.id
          }
        })
      }
    },
    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.formList[3].props.options = setTreeData(data)
      }
    },

    // 获取设备
    async getFacilityList() {
      const search = [
        {
          id: getUUid(),
          fieldName: 'isDeleted',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        }
      ]
      const res = await facilityListApi.getFacilityList({ searchVal: Encrypt(JSON.stringify([...search])) })
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: `${item.code} - ${item.name}`
          }
        })
        this.facilityList = data || []
        this.setFacilityList()
      }
    },

    async getUserList() {
      const res = await userApi.getUserList()
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.localFormList[3].children[0].options = res.filter(item => item.id !== userInfo.id)
      this.executorUserList = res.filter(item => item.id !== userInfo.id)
    },

    setFacilityList() {
      if (this.$refs.baseForm) {
        const { form: baseForm } = this.$refs.baseForm
        const { consumerId } = baseForm
        if (consumerId) {
          this.formList[4].children[0].options = this.facilityList.filter(item => item.customerId === consumerId)
        } else {
          this.formList[4].children[0].options = []
        }
      }
    },

    valueChange(key) {
      if (key === 'executionStartTime' || key === 'executionEndTime') {
        const { form: localForm } = this.$refs.localForm
        this.setExecutionHours(localForm)
      }
    },

    setExecutionHours(data) {
      const { executionStartTime, executionEndTime } = data
      if (executionStartTime && executionEndTime) {
        const minutes = dayjs(executionStartTime).diff(dayjs(executionEndTime), 'minutes')
        if (minutes > 0 && this.$refs.localForm) {
          this.$refs.localForm.setForm({
            executionStartTime: executionEndTime,
            executionEndTime: executionStartTime
          })
        }
        this.manHour = (Math.abs(minutes) / 60).toFixed(1)
        this.baseFormDatas.manHour = +this.manHour
      } else {
        this.manHour = 0
        this.baseFormDatas.manHour = 0
      }
    },

    addHours() {
      this.hoursList.push({})
    },

    delHours(index) {
      this.hoursList.splice(index, 1)
      this.onHourChange()
    },

    onHourChange() {
      this.hoursSum = this.hoursList.reduce((pre, next) => {
        return pre + +(next.hour || 0)
      }, 0)
      document.querySelectorAll('.hours-table thead tr th')[2].innerText = `时长（合计：${this.hoursSum}小时）`
    },

    addExecutor() {
      const { form: localForm } = this.$refs.localForm

      if (!localForm.executionStartTime || !localForm.executionEndTime) {
        return this.$message.error('请先选择服务时间')
      }
      this.executorList.push({
        isSame: 1,
        date: [],
        manHour: this.manHour
      })
    },

    delExecutorList(index) {
      this.executorList.splice(index, 1)
    },

    datePickerChange(index) {
      const date = this.executorList[index].date

      if (date && date.length) {
        const minutes = dayjs(date[0]).diff(dayjs(date[1]), 'minutes')
        if (minutes) {
          this.executorList[index].manHour = (Math.abs(minutes) / 60).toFixed(1)
        }
      }
    },

    onRadioChange(val, index) {
      if (val === 1) {
        this.executorList[index].manHour = this.manHour
      }
      this.executorList[index].date = []
    },

    // 上传附件
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.attachments.push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    removeFile(file) {
      this.attachments = this.attachments.filter(item => item.uid !== file.uid)
    },
    handlePreview(file) {
      if (file.url.indexOf('.txt') > 0) {
        saveAs(file.url, '文件')
        return
      }
      window.open(file.url)
    },

    submitForm() {
      this.buttonLoading = true

      const { form: baseForm } = this.$refs.baseForm
      const { form: localForm } = this.$refs.localForm
      const { form: processBaseForm } = this.$refs.processBaseForm

      this.$refs.baseForm.formRefs().validate(async valid => {
        if (valid) {
          this.$refs.localForm.formRefs().validate(async valids => {
            if (valids) {
              let data
              if (this.params.type === 'add') {
                data = Object.assign({}, this.createSetData(baseForm), this.createSetDatas(localForm), {
                  facilityInstallWordOrderAssemble: processBaseForm
                })
              } else {
                data = Object.assign({}, this.createResetData(baseForm), this.createSetDatas(localForm), {
                  facilityInstallWordOrderAssemble: processBaseForm
                })
              }

              const code = await api.checkTimeInfo({
                startTime: localForm.executionStartTime,
                endTime: localForm.executionEndTime
              })
              if (code === 1 || code === 2) {
                let message
                if (code === 1) {
                  message = '该工单已跨天执行，是否确认保存？'
                } else {
                  message = '该工单的服务时长已超过24h，是否确认保存？'
                }
                this.$confirm(message, '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.saveData(data)
                })
              } else {
                this.saveData(data)
              }
            } else {
              this.buttonLoading = false
            }
          })
        } else {
          this.buttonLoading = false
        }
      })
    },

    async saveData(form) {
      this.buttonLoading = false
      const res = await api[`${this.params.type}WordOrder`](form)
      if (res) {
        this.returns()
      }
    },
    createSetData(raw) {
      return {
        workOrderCode: raw.workOrderCode === '系统生成' ? -1 : raw.workOrderCode,
        workOrderType: 2,
        demandId: raw.demandId,
        demandCode: raw.demandCode || '',
        facilityId: raw.facilityId,
        consumerId: raw.consumerId,
        consumer: raw.consumer,
        customerSiteId: raw.customerSiteId,
        customerSiteIdList: raw.customerSiteIdList ? raw.customerSiteIdList.join('^') : '',
        startDate: raw.startDate,
        debugDate: raw.debugDate,
        manHour: +this.manHour
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    createSetDatas(raw) {
      const { form: localForm } = this.$refs.localForm
      return {
        executionStartTime: raw.executionStartTime,
        executionEndTime: raw.executionEndTime,
        facilityRepairSite: {
          serviceStartTime: localForm.executionStartTime,
          serviceEndTime: localForm.executionEndTime,
          manHour: +this.manHour,
          affix: this.attachments.length > 0 ? JSON.stringify(this.attachments) : '',
          facilityServiceTimeMappingList: this.executorList.map(item => {
            return {
              isSame: item.isSame,
              startTime: item.isSame === 1 ? localForm.executionStartTime : item.date[0],
              endTime: item.isSame === 1 ? localForm.executionEndTime : item.date[1],
              userIds: item.userIds.join('^'),
              manHour: +item.manHour
            }
          }),
          otherBusiness: raw.otherBusiness
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrappers {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
  }

  .card-bot {
    ::v-deep.el-row {
      display: flex;
      flex-wrap: wrap;
    }

    .custom-form {
      display: flex;
      margin-bottom: 15px;
      color: #606266;

      .custom-form-item:nth-child(1) {
        width: calc(25% - 94px);

        .el-select {
          width: 100%;
        }
      }

      .custom-form-item:nth-child(2) {
        width: calc(75% + 94px);
        padding-left: 20px;

        ::v-deep .el-radio__label {
          font-weight: bold;
        }

        .hour {
          display: inline-block;
          padding-right: 20px;

          label {
            padding: 0 12px 0 40px;
          }
        }
      }
    }

    .view-list {
      padding: 0 15px;
      color: #393d60;
      font-size: 14px;

      &__label {
        margin-bottom: 4px;
        color: #9597ae;
      }

      .el-col {
        margin: 10px 0;
      }
    }

    .table-cell {
      display: flex;
      align-items: center;

      .required-star {
        width: 10px;
        color: #f00;
      }

      .range {
        margin: 0 10px;
      }

      .el-select {
        width: 100%;
      }
    }

    .upload-view {
      ::v-deep.el-upload {
        &--text {
          display: none;
        }
      }
    }
  }

  .detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;

      .desc-table.edit {
        position: relative;
        top: -46px;
        left: 96px;
        width: calc(100% - 96px);
        margin-bottom: -46px;
      }

      .e-btn {
        margin-top: 10px;
      }
    }
  }
  .detail-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
