import { transformColumnsToForm } from '@/utils'
import { InColumns, OutColumns, AllocationColumn, TransferWarehouseColumn, WarehouseInventoryColumn, MaterialInventoryColumn } from './columns'

export const inFormList = transformColumnsToForm(_.cloneDeep(InColumns))
export const outFormList = transformColumnsToForm(_.cloneDeep(OutColumns))
export const allocationFormList = transformColumnsToForm(_.cloneDeep(AllocationColumn))
export const transferFormList = transformColumnsToForm(_.cloneDeep(TransferWarehouseColumn))
const newColumn = [...WarehouseInventoryColumn, {
  label: '',
  form: {
    index: 4,
    tagName: 'div',
    customSlots: ['saveBtn'],
    colSpan: 6
  }
}]
export const warehouseFormList = transformColumnsToForm(_.cloneDeep(newColumn))
export const materialFormList = transformColumnsToForm(_.cloneDeep(MaterialInventoryColumn))

