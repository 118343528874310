var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "equipment-board-container" }, [
    _c("div", { staticClass: "top" }, [
      _c(
        "div",
        { staticClass: "inspection-finish" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v(" 点巡检完成数（近半年） ")]
          ),
          _c("ve-line", {
            attrs: {
              height: "300px",
              data: _vm.inspectionFinishChartData,
              settings: _vm.lineChartSettings,
              extend: _vm.lineChartExtend
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "equipment-info" }, [
        _c(
          "div",
          { staticClass: "card-info" },
          [
            _c("img", {
              attrs: {
                src: "https://oss.mubyte.cn/static/equipmentCount.png",
                alt: ""
              }
            }),
            _c("dv-digital-flop", {
              staticStyle: { width: "162px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.equipmentQuantity, [
                  "#FFF",
                  "#FFF"
                ])
              }
            }),
            _c("div", [_vm._v("联网设备")])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "card-info" },
          [
            _c("img", {
              attrs: {
                src: "https://oss.mubyte.cn/static/maintenanceCOunt.png",
                alt: ""
              }
            }),
            _c("dv-digital-flop", {
              staticStyle: { width: "162px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.maintenanceQuantity, [
                  "#FFF",
                  "#FFF"
                ])
              }
            }),
            _c("div", [_vm._v("维保中数量")])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "card-info" },
          [
            _c("img", {
              attrs: {
                src: "https://oss.mubyte.cn/static/faultCount.png",
                alt: ""
              }
            }),
            _c("dv-digital-flop", {
              staticStyle: { width: "162px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.faultQuantity, [
                  "#FFF",
                  "#FFF"
                ])
              }
            }),
            _c("div", [_vm._v("故障总数")])
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "bj-sort" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("备件更换排名（年）")]
          ),
          _c("ve-bar", {
            attrs: {
              height: "300px",
              data: _vm.bgSortChartData,
              settings: _vm.barChartSettings,
              extend: _vm.barChartExtend2
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "center" }, [
      _c(
        "div",
        { staticClass: "inspection-error" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("点巡检异常数（近半年）")]
          ),
          _c("ve-line", {
            attrs: {
              height: "300px",
              data: _vm.inspectionErrorChartData,
              settings: _vm.lineChartSettings,
              extend: _vm.lineChartExtend
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fault-sort" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg,  #7410FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("故障排名（年）")]
          ),
          _c("ve-bar", {
            attrs: {
              height: "300px",
              data: _vm.faultSortChartData,
              settings: _vm.barChartSettings,
              extend: _vm.barChartExtend3
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fault-sort" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("保养统计分析（近半年）")]
          ),
          _c("ve-histogram", {
            attrs: {
              height: "300px",
              data: _vm.maintenanceChartData,
              settings: _vm.barChartSettings,
              extend: _vm.barChartExtend
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "bottom" }, [
      _c(
        "div",
        { staticClass: "inspection-task" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)",
                "margin-bottom": "10px"
              }
            },
            [
              _vm._v("点巡检任务"),
              _c("span", [
                _vm._v("（" + _vm._s(_vm.inspectionTaskDataTotal) + "）")
              ])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(ref) {
                  var index = ref.index
                  return _c(
                    "span",
                    {
                      staticClass: "status-board-r",
                      style: {
                        background:
                          _vm.inspectionTaskData[index].status === 1
                            ? "#4B1E00"
                            : "#00371A"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.inspectionTaskData[index]
                              ? _vm.inspectionTaskData[index].status === 1
                                ? _vm.inspectionTaskData[index].spotCheckNumber
                                  ? "待点检"
                                  : "待巡检"
                                : _vm.inspectionTaskData[index].spotCheckNumber
                                ? "点检中"
                                : "巡检中"
                              : ""
                          ) +
                          " "
                      )
                    ]
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "repair-task" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg,  #7410FF 0%, rgba(96, 127, 255, 0) 100%)",
                "margin-bottom": "10px"
              }
            },
            [
              _vm._v("维修任务"),
              _c("span", [
                _vm._v("（" + _vm._s(_vm.repairTaskDataTotal) + "）")
              ])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig1 },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(ref) {
                  var index = ref.index
                  return _c(
                    "span",
                    {
                      staticClass: "status-board",
                      style: {
                        background:
                          _vm.repairStatus[_vm.repairTaskData[index].status]
                            .color
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.repairStatus[_vm.repairTaskData[index].status]
                            .name
                        )
                      )
                    ]
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "maintenance-task" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)",
                "margin-bottom": "10px"
              }
            },
            [
              _vm._v("保养任务"),
              _c("span", [
                _vm._v("（" + _vm._s(_vm.maintenanceTaskDataTotal) + "）")
              ])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig2 },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(ref) {
                  var index = ref.index
                  return _c(
                    "span",
                    {
                      staticClass: "status-board-r",
                      style: {
                        background:
                          _vm.maintenanceTaskData[index].status === 1
                            ? "#4B1E00"
                            : "#00371A"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.maintenanceTaskData[index]
                              ? _vm.maintenanceTaskData[index].status === 1
                                ? "待保养"
                                : "保养中"
                              : ""
                          ) +
                          " "
                      )
                    ]
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }