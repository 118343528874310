var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-table-container" },
    [
      _c("chart-search-condition", {
        attrs: { "tab-info": _vm.tabInfo },
        on: { conditionChange: _vm.searchConditionChange }
      }),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            display: "flex",
            "flex-direction": "row"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "200px",
                padding: "8px",
                border: "1px solid #EBEEF5",
                "border-radius": "4px",
                "margin-right": "10px",
                display: "flex",
                "align-items": "center"
              }
            },
            [
              _c("div", [
                _c("div", [
                  _vm._v(
                    "工单码：" +
                      _vm._s(_vm.currentWorkOrder.produceWorkOrderNumber)
                  )
                ]),
                _c("div", { staticStyle: { "padding-top": "5px" } }, [
                  _vm._v(
                    "物料编码：" + _vm._s(_vm.currentWorkOrder.materialsCode)
                  )
                ]),
                _c("div", { staticStyle: { "padding-top": "5px" } }, [
                  _vm._v(
                    "物料名称：" + _vm._s(_vm.currentWorkOrder.materialsName)
                  )
                ]),
                _c("div", { staticStyle: { "padding-top": "5px" } }, [
                  _vm._v(
                    "规格：" +
                      _vm._s(_vm.currentWorkOrder.materialsSpecifications)
                  )
                ])
              ])
            ]
          ),
          _c(
            "div",
            { staticStyle: { flex: "1", height: "100%" } },
            [
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  columns: _vm.newColumns,
                  "max-height": 300,
                  "show-page": false,
                  "set-data-method": _vm.getTableData
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }