import { status } from '@/config/options.config'
import { formatColumns } from '@/utils'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
const User = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'jobNumber',
    label: '工号',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 0, tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: '姓名',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 1, tagName: 'el-input' }
  },
  {
    prop: 'phone',
    label: '手机号码',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 3, tagName: 'el-input' }
  },
  {
    prop: 'organizationId',
    label: '组织',
    hiddenSearch: true,
    sortable: false,
    slotName: 'organizationId',
    form: { colSpan: 24, index: 4, key: 'organizationId', tagName: 'el-select', options: [], required: false }
  },
  {
    prop: 'email',
    label: '邮箱',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 5, tagName: 'el-input', required: false }
  },
  {
    prop: 'userType',
    label: '员工类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'userType',
    width: 180,
    form: { colSpan: 24, index: 6, tagName: 'el-select', options: [
      { id: 0, name: '正式' },
      { id: 1, name: '外包' }
    ] }
  },
  {
    prop: 'userGroupIdList',
    label: '用户组',
    hiddenSearch: true,
    sortable: false,
    slotName: 'userGroupIdList',
    form: { colSpan: 24, index: 7, key: 'userGroupIdList', tagName: 'el-select', options: [], required: false, props: { multiple: true, collapseTags: true }}
  },
  {
    prop: 'isSystemUser',
    label: '是否系统用户',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isSystemUser',
    width: 120,
    form: {
      index: 8,
      colSpan: 24,
      tagName: 'el-switch',
      defaultValue: 1,
      props: {
        activeText: '是',
        inactiveText: '否',
        activeValue: 1,
        inactiveValue: 0
      },
      on: { change: (val) => { functionList.paramsTypeChange(val) } }
    }
  },
  {
    prop: 'station',
    label: '可执行工位',
    hiddenSearch: true,
    sortable: false,
    slotName: 'station'
  },
  {
    prop: 'setting',
    label: '我的设备',
    hiddenSearch: true,
    sortable: false,
    slotName: 'setting'
  },
  {
    prop: 'userName',
    label: '用户名',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 10, tagName: 'el-input' }
  },
  {
    prop: 'roleIdList',
    label: '角色',
    hiddenSearch: true,
    sortable: false,
    slotName: 'roleList',
    width: 180,
    form: { colSpan: 24, index: 11, tagName: 'el-select', options: [], props: { multiple: true, collapseTags: true }}
  },
  {
    prop: 'userIdList',
    label: '包含用户组',
    sortable: false,
    hiddenSearch: true,
    slotName: 'userIdList'
  },
  {
    prop: 'isEnabled',
    label: '用户状态',
    slotName: 'isEnabled',
    sortable: false
    // form: {
    //   index: 4,
    //   tagName: 'el-select',
    //   defaultValue: 1,
    //   options: status
    // }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
    // form: { index: 5, tagName: 'el-input', required: false, props: { type: 'textarea' }}
  },
  {
    prop: 'loginTime',
    label: '最近登录',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 240
  }
]
const UserGroup = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '用户组名称',
    sortable: false,
    hiddenSearch: true,
    form: { colSpan: 24, tagName: 'el-input' }
  },
  {
    prop: 'userIdList',
    label: '包含用户',
    sortable: false,
    hiddenSearch: true,
    slotName: 'users',
    form: { colSpan: 24, tagName: 'el-select', options: [], props: { multiple: true, collapseTags: true }}
  },
  {
    prop: 'isEnabled',
    label: '用户组状态',
    slotName: 'isEnabled',
    sortable: false,
    filters: formatColumns(status),
    form: {
      colSpan: 24,
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { colSpan: 24, tagName: 'el-input', required: false, props: { type: 'textarea' }}
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
]

const viewUser = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '用户名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'userName',
    label: '工号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '用户状态',
    slotName: 'isEnabled',
    sortable: false,
    hiddenSearch: true
  }
]
const viewUserGroup = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '用户组名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '用户组状态',
    slotName: 'isEnabled',
    sortable: false,
    hiddenSearch: true
  }
]
const viewStation = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '工位编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '工位名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '工位状态',
    slotName: 'isEnabled',
    sortable: false,
    hiddenSearch: true
  }
]

const viewSetColumns = [
  {
    prop: 'code',
    label: '设备编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'drawing',
    label: '设备图片',
    slotName: 'drawing',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentClassName',
    label: '设备类',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 200
  }
]
export default {
  User,
  UserGroup,
  viewUser,
  viewStation,
  viewSetColumns,
  viewUserGroup
}
