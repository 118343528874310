var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "board-manage-container" }, [
    _c("div", { staticClass: "left" }, [
      _c(
        "div",
        { staticClass: "top", staticStyle: { "word-break": "keep-all" } },
        [_vm._v("自定义报表")]
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _vm._l(_vm.childrenRouters, function(menu) {
            return [
              _c(
                "div",
                {
                  key: menu.name,
                  staticClass: "menu-item",
                  staticStyle: { position: "relative" }
                },
                [
                  _c("img", {
                    staticStyle: { width: "12px", "margin-right": "5px" },
                    attrs: { src: menu.meta.icon }
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "inline-block",
                        "word-break": "keep-all"
                      }
                    },
                    [_vm._v(_vm._s(menu.meta.title))]
                  ),
                  _c("add-statistics-card", {
                    attrs: {
                      "type-change": _vm.typeChange,
                      "custom-statistics-cards-info":
                        _vm.customStatisticsCardsInfo
                    },
                    on: {
                      updateStatisticsCards: function($event) {
                        return _vm.getCustomStatisticsCards("add")
                      }
                    }
                  })
                ],
                1
              ),
              [
                _c(
                  "div",
                  {
                    key: menu.name + 1,
                    staticStyle: { padding: "0", margin: "0" }
                  },
                  _vm._l(_vm.customStatisticsCards, function(child, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "menu-item cards-menu-item",
                        class: {
                          active: index === _vm.currentCustomStatisticsIndex
                        },
                        on: {
                          click: function($event) {
                            return _vm.viewCustomDetail(child, index)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "ellipsis",
                            staticStyle: {
                              width: "125px",
                              display: "inline-block",
                              "word-break": "keep-all",
                              color: "#8A8CA5",
                              padding: "0 0 10px 25px"
                            }
                          },
                          [_vm._v(_vm._s(child.title))]
                        ),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/statistics-board/trash.png"),
                            alt: ""
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.delStatisticsCards(child)
                            }
                          }
                        })
                      ]
                    )
                  }),
                  0
                )
              ]
            ]
          })
        ],
        2
      )
    ]),
    _c("div", { staticClass: "right" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("custom-statistics", {
            attrs: { id: _vm.ids },
            on: { updateStatisticsCards: _vm.getCustomStatisticsCards }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("span", [_vm._v("自定义报表卡片")]),
      _c("span")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }