<template>
  <div class="scrap-wrap">
    <div class="bottom" v-if="permission('List')">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入工具编号/名称"
        :tags="!exportSelectionIds.length ?  functionHeader.tags :[]"
        @search="$refs.mTable.setTableData(true)"
      >
      </FunctionHeader>
      <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <el-button slot="code"
                   slot-scope="{ row }"
                   style="white-space: pre-wrap"
                   type="text"
                   @click="viewDetail(row)">
          {{ row.code }}
        </el-button>
        <div slot="action" slot-scope="{ row,$index }">
          <template v-if="permission('Edit')">
            <el-button type="text" @click="onHandle('edit',row)">编辑</el-button>
          </template>
          <template v-if="permission('Del')&&row.id">
            <el-button type="text" @click="onHandle('del',row)">删除</el-button>
          </template>
        </div>
      </MTable>

      <MDialog v-model="visiable" :title="currentType" @onOk="addOrEditSubmit">
        <MFormBuilder ref="formBuilds" :form-data="dataForAdd" :form-list="formList" />
      </MDialog>

    </div>
  </div>
</template>

<script>
import { columns } from './columns'
import { formList } from './form-list'
// import api from '@/api/sets/shift'
import api from '@/api/toolManagement/toolLeader/index.js'
import conventionMixin from '@/mixin/conventional-page'
import batchExportFront from '@/mixin/batch-export-front'
import FunctionHeader from '@/components/FunctionHeader/index'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'

export default {
  name: 'ToolLedger',
  components: { NewPageOpen, FunctionHeader },
  mixins: [conventionMixin, batchExportFront],
  data() {
    return {
      api,
      visiable:false,
      isProcess: false,
      params: {
        radio: 'ToolLedger'
      },
      columns,
      formList,
      dataForAdd:{},
      searchFormData: {},
      defaultSearchFormData: {
        procedureId: ''
      },
      searchKeyword:true,
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.addOne
            }
          }
        ],
        exportName: 'exportShift',
        exportParams: {}
      },
      formData: {},
      currentType: '新增工具',
      stationVisible: false,
      stationList: [],
      isPageLoaded:false,
      selectionDataList:[],
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 10 + 35
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.exportSelectionList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  watch: {
  },
  created() {
  },
  activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
  },
  mounted() {
    this.isPageLoaded = true
  },
  methods: {
    viewDetail(row){
      this.$router.push({
        name: 'ToolLedgerDetail',
        params: {
          id: row.id
        }
      })
    },
    addOrEditSubmit(callback){
      this.$refs.formBuilds.formRefs().validate(async(valid) => {
        if (valid){
          const { form } = this.$refs.formBuilds
          if (form.id) {
            consoleSelf.info("修改工具数据", form)
            const res = await api.edit({ ...form })
            if (res) {
              this.$message.success('修改成功')
              this.visiable = false
              this.$refs.mTable.setTableData(true)
            }
          } else {
            consoleSelf.info("新增工具数据", form)
            const res = await api.add({ ...form })
            if (res) {
              this.$message.success('新增成功')
              this.visiable = false
             this.$refs.mTable.setTableData(true)
            }
          }
          callback(true)
        }else {
          callback();
        }
      })
    },
    resetSelectData() {
      this.exportSelectionList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
    },
    addOne() {
      this.dataForAdd = {}
      this.addOne = "新增工具"
      this.formList[0].props.disabled = false
      this.visiable = true
    },
    onHandle(type,row){
      if(type==='edit'){
        this.dataForAdd = {...row};
        this.addOne = "修改工具"
        this.formList[0].props.disabled = true
        this.visiable = true
      }else if(type==='del'){
        this.$confirm('确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.del({ id: row.id })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData(true)
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;
  .bottom {
    padding: 10px;
  }
  .m-radio-group {
    margin-left: 10px;
  }
}
::v-deep {
  .e-divider {
    margin: 0px 0 0 0;
  }
  .el-form-item__content {
    height:32px;
  }
}
</style>
