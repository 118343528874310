var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base-print" }, [
    _c(
      "div",
      {
        ref: "qrcodes",
        staticStyle: { display: "none" },
        attrs: { id: "qrcodes" }
      },
      [
        _c(
          "div",
          {
            style: {
              position: "absolute",
              width: _vm.infos.style.width + "px",
              height: _vm.infos.style.height + "px",
              top: _vm.infos.style.top + "px",
              left: _vm.infos.style.left + "px"
            }
          },
          [
            _c("vue-qr", {
              attrs: {
                "correct-level": 3,
                "auto-color": false,
                "color-dark": "#000000",
                text: _vm.infos.texts,
                size: _vm.infos.size,
                margin: 0
              }
            })
          ],
          1
        )
      ]
    ),
    _vm.isEdit
      ? _c(
          "div",
          { staticClass: "kr-designers" },
          [
            _c("viewport", {
              ref: "viewportInit",
              staticClass: "kr-designer-view",
              attrs: { "is-resize": _vm.isResize }
            }),
            _c(
              "div",
              { staticClass: "kr-designer-tool" },
              [
                _c("i", { staticClass: "el-icon-printer icons" }),
                _c(
                  "el-form",
                  {
                    staticClass: "kr-form",
                    attrs: {
                      "label-width": "80px",
                      model: _vm.pageInfo,
                      size: "small"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 15 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "打印模板" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    on: { change: _vm.selectTitles },
                                    model: {
                                      value: _vm.pageInfo.title,
                                      callback: function($$v) {
                                        _vm.$set(_vm.pageInfo, "title", $$v)
                                      },
                                      expression: "pageInfo.title"
                                    }
                                  },
                                  _vm._l(_vm.getTemplateList, function(val) {
                                    return _c("el-option", {
                                      key: val.id,
                                      attrs: {
                                        label: val.name,
                                        value: val.name
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { span: 6 }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: { click: _vm.editTemplate }
                              },
                              [_vm._v("编辑模板")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "all-btns" },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "medium" }, on: { click: _vm.closeIn } },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "medium", type: "primary" },
                        on: { click: _vm.printTemplate }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _c(
          "div",
          { staticStyle: { height: "100%" } },
          [
            _c("print-designer", {
              ref: "printDesigner",
              attrs: {
                "temp-value": _vm.value,
                "widget-options": _vm.projectWidgets,
                "template-id": _vm.templateId
              },
              on: { save: _vm.handleSave, returnTemp: _vm.returnTemp }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }