var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-wrappers" }, [
    _c("div", { staticClass: "detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基础信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _vm.isView
              ? _c(
                  "el-row",
                  { staticClass: "view-list", attrs: { gutter: 24 } },
                  _vm._l(_vm.columns, function(item) {
                    return _c("el-col", { key: item.id, attrs: { span: 6 } }, [
                      item.prop === "customerSiteIdList"
                        ? _c("div", [
                            _c("div", { staticClass: "view-list__label" }, [
                              _vm._v(_vm._s(item.label))
                            ]),
                            _c("div", { staticClass: "view-list__value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.baseFormData["customerSiteIdNames"] || "-"
                                )
                              )
                            ])
                          ])
                        : _c("div", [
                            _c("div", { staticClass: "view-list__label" }, [
                              _vm._v(_vm._s(item.label))
                            ]),
                            _c("div", { staticClass: "view-list__value" }, [
                              _vm._v(_vm._s(_vm.getValue(item) || "-"))
                            ])
                          ])
                    ])
                  }),
                  1
                )
              : _c(
                  "MFormBuilder",
                  {
                    ref: "baseForm",
                    attrs: {
                      "form-list": _vm.formList,
                      "form-data": _vm.baseFormData,
                      "label-position": "right"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        attrs: { slot: "workOrderCode" },
                        slot: "workOrderCode"
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.baseFormData.workOrderCode) + " "
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { attrs: { slot: "demandCode" }, slot: "demandCode" },
                      [_vm._v(" " + _vm._s(_vm.baseFormData.demandCode) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        attrs: { slot: "workOrderType" },
                        slot: "workOrderType"
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.baseFormData.workOrderTypeName) + " "
                        )
                      ]
                    )
                  ]
                )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("装机进度")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _vm.isView
              ? _c(
                  "el-row",
                  { staticClass: "view-list", attrs: { gutter: 24 } },
                  _vm._l(_vm.processColumns, function(item) {
                    return _c("el-col", { key: item.id, attrs: { span: 6 } }, [
                      _c("div", { staticClass: "view-list__label" }, [
                        _vm._v(_vm._s(item.label))
                      ]),
                      _c("div", { staticClass: "view-list__value" }, [
                        _vm._v(_vm._s(_vm.getValueProcess(item) || "-"))
                      ])
                    ])
                  }),
                  1
                )
              : _c("MFormBuilder", {
                  ref: "processBaseForm",
                  attrs: {
                    "form-list": _vm.processFormList,
                    "form-data": _vm.processFormData,
                    "label-position": "right"
                  }
                })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("现场记录")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _vm.isView
              ? _c(
                  "el-row",
                  { staticClass: "view-list", attrs: { gutter: 24 } },
                  _vm._l(_vm.localFormList, function(item) {
                    return _c(
                      "el-col",
                      { key: item.id, attrs: { span: item.colSpan } },
                      [
                        _c("div", { staticClass: "view-list__label" }, [
                          _vm._v(_vm._s(item.label))
                        ]),
                        item.key == "affix"
                          ? _c(
                              "el-upload",
                              _vm._b(
                                {
                                  class: { "upload-view": _vm.isView },
                                  attrs: {
                                    "file-list": _vm.attachments,
                                    disabled: ""
                                  }
                                },
                                "el-upload",
                                _vm.uploadProps2,
                                false
                              )
                            )
                          : item.key === "facilityServiceTimeMappingList"
                          ? _c(
                              "div",
                              _vm._l(_vm.executorList, function(item) {
                                return _c(
                                  "el-row",
                                  {
                                    key: item.id,
                                    staticClass: "view-list",
                                    attrs: { gutter: 24 }
                                  },
                                  [
                                    _c("el-col", { attrs: { span: 6 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "view-list__value" },
                                        [_vm._v(_vm._s(item.userNames || "-"))]
                                      )
                                    ]),
                                    _c("el-col", { attrs: { span: 6 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "view-list__label" },
                                        [_vm._v("服务时间")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "view-list__value" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.startTime +
                                                " ~ " +
                                                item.endTime
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("el-col", { attrs: { span: 6 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "view-list__label" },
                                        [_vm._v("服务时长")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "view-list__value" },
                                        [
                                          _vm._v(
                                            _vm._s(item.manHour || 0) + "小时"
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _c("div", { staticClass: "view-list__value" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getLocalValue(item) || "-") +
                                  " "
                              )
                            ])
                      ],
                      1
                    )
                  }),
                  1
                )
              : _c(
                  "MFormBuilder",
                  {
                    ref: "localForm",
                    staticClass: "hours-table",
                    attrs: {
                      "form-list": _vm.localFormList,
                      "form-data": _vm.baseFormDatas,
                      "label-position": "right"
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "manHour" }, slot: "manHour" }, [
                      _vm._v(_vm._s(_vm.manHour) + "小时")
                    ]),
                    _c(
                      "div",
                      {
                        attrs: { slot: "facilityServiceTimeMappingList" },
                        slot: "facilityServiceTimeMappingList"
                      },
                      [
                        _vm._l(_vm.executorList, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "custom-form" },
                            [
                              _c(
                                "div",
                                { staticClass: "custom-form-item" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        multiple: "",
                                        placeholder: "添加协作人",
                                        filterable: ""
                                      },
                                      model: {
                                        value: item.userIds,
                                        callback: function($$v) {
                                          _vm.$set(item, "userIds", $$v)
                                        },
                                        expression: "item.userIds"
                                      }
                                    },
                                    _vm._l(_vm.executorUserList, function(q) {
                                      return _c("el-option", {
                                        key: q.id,
                                        attrs: { label: q.name, value: q.id }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "custom-form-item" },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: {
                                        change: function($event) {
                                          return _vm.onRadioChange(
                                            item.isSame,
                                            index
                                          )
                                        }
                                      },
                                      model: {
                                        value: item.isSame,
                                        callback: function($$v) {
                                          _vm.$set(item, "isSame", $$v)
                                        },
                                        expression: "item.isSame"
                                      }
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("服务时间同执行人")
                                      ]),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: 0 } },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "datetimerange",
                                              "range-separator": "至",
                                              "start-placeholder":
                                                "选择服务开始时间",
                                              "end-placeholder":
                                                "选择服务结束时间",
                                              "value-format":
                                                "yyyy-MM-dd HH:mm",
                                              format: "yyyy-MM-dd HH:mm",
                                              clearable: "",
                                              disabled: item.isSame === 1,
                                              "picker-options":
                                                _vm.pickerOptions
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.datePickerChange(
                                                  index
                                                )
                                              }
                                            },
                                            model: {
                                              value: item.date,
                                              callback: function($$v) {
                                                _vm.$set(item, "date", $$v)
                                              },
                                              expression: "item.date"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "hour" }, [
                                    _c("label", [_vm._v("服务时长")]),
                                    _vm._v(
                                      " " + _vm._s(_vm.getTime(item)) + "小时 "
                                    )
                                  ]),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.delExecutorList(index)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.addExecutor }
                          },
                          [_vm._v("添加")]
                        )
                      ],
                      2
                    ),
                    _c(
                      "el-upload",
                      _vm._b(
                        {
                          class: { "upload-view": _vm.isView },
                          attrs: {
                            slot: "drawing",
                            "file-list": _vm.attachments
                          },
                          slot: "drawing"
                        },
                        "el-upload",
                        _vm.uploadProps2,
                        false
                      ),
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", size: "small" } },
                          [_vm._v("上传文件")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
          ],
          1
        )
      ])
    ]),
    !_vm.isView
      ? _c("div", { staticClass: "detail-footer" }, [
          _c(
            "div",
            { staticClass: "footer-ri" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.returns } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.buttonLoading,
                    size: "small"
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }