var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-wrappers" }, [
    _c("div", { staticClass: "detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基础信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-row",
              { staticClass: "view-list", attrs: { gutter: 24 } },
              _vm._l(_vm.getCoulmns, function(item) {
                return _c("el-col", { key: item.id, attrs: { span: 6 } }, [
                  _c("div", { staticClass: "view-list__label" }, [
                    _vm._v(_vm._s(item.label))
                  ]),
                  _c("div", { staticClass: "view-list__value" }, [
                    _vm._v(_vm._s(_vm.getValue(item) || "-"))
                  ])
                ])
              }),
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("关联设备")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("MTable", {
              ref: "shipTable",
              attrs: {
                "show-page": false,
                height: 202,
                columns: _vm.shipColumns,
                data:
                  _vm.baseFormData
                    .facilityInspectionSpotCheckPlanFacilityList || [],
                "columns-setting": false
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "SN",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [_vm._v(_vm._s(row.sn || "-"))])
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("点检项目")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("MTable", {
              ref: "projectTable",
              attrs: {
                "show-page": false,
                height: 202,
                columns: _vm.projectColumns,
                data:
                  _vm.baseFormData.facilityInspectionSpotCheckPlanList || [],
                "columns-setting": false
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("现场记录")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _vm.isView
              ? _c(
                  "el-row",
                  { staticClass: "view-list", attrs: { gutter: 24 } },
                  _vm._l(_vm.localFormList, function(item) {
                    return _c(
                      "el-col",
                      { key: item.id, attrs: { span: item.colSpan } },
                      [
                        _c("div", { staticClass: "view-list__label" }, [
                          _vm._v(_vm._s(item.label))
                        ]),
                        item.key === "affix"
                          ? _c(
                              "el-upload",
                              _vm._b(
                                {
                                  class: { "upload-view": _vm.isView },
                                  attrs: {
                                    "file-list": _vm.attachments,
                                    disabled: ""
                                  }
                                },
                                "el-upload",
                                _vm.uploadProps2,
                                false
                              )
                            )
                          : item.key === "facilityServiceTimeMappingList"
                          ? _c(
                              "div",
                              _vm._l(_vm.executorList, function(item) {
                                return _c(
                                  "el-row",
                                  {
                                    key: item.id,
                                    staticClass: "view-list",
                                    attrs: { gutter: 24 }
                                  },
                                  [
                                    _c("el-col", { attrs: { span: 6 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "view-list__value" },
                                        [_vm._v(_vm._s(item.userNames || "-"))]
                                      )
                                    ]),
                                    _c("el-col", { attrs: { span: 6 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "view-list__label" },
                                        [_vm._v("服务时间")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "view-list__value" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.startTime +
                                                " ~ " +
                                                item.endTime
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("el-col", { attrs: { span: 6 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "view-list__label" },
                                        [_vm._v("服务时长")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "view-list__value" },
                                        [
                                          _vm._v(
                                            _vm._s(item.manHour || 0) + "小时"
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : item.key === "facilityInspectionSpotDetectioList"
                          ? _c(
                              "div",
                              _vm._l(_vm.localAbnormalList, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-card",
                                  { key: item.id, staticClass: "box-card" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "clearfix",
                                        attrs: { slot: "header" },
                                        slot: "header"
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s("#" + (index + 1)))
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "el-row",
                                      {
                                        staticClass: "view-list",
                                        attrs: { gutter: 24 }
                                      },
                                      _vm._l(
                                        _vm.localAbnormalFormList,
                                        function(v) {
                                          return _c(
                                            "el-col",
                                            {
                                              key: v.prop,
                                              attrs: { span: v.colSpan }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "view-list__label"
                                                },
                                                [_vm._v(_vm._s(v.label))]
                                              ),
                                              v.key === "facilityId"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "view-list__value"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.facilityName ||
                                                            "-"
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "view-list__value"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item[v.key] || "-"
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _c("div", { staticClass: "view-list__value" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getLocalValue(item) || "-") +
                                  " "
                              )
                            ])
                      ],
                      1
                    )
                  }),
                  1
                )
              : _c(
                  "MFormBuilder",
                  {
                    ref: "localForm",
                    attrs: {
                      "form-list": _vm.localFormList,
                      "form-data":
                        _vm.baseFormData.facilityInspectionFieldNotes,
                      "label-position": "right"
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "manHour" }, slot: "manHour" }, [
                      _vm._v(_vm._s(_vm.manHour) + "小时")
                    ]),
                    _c(
                      "div",
                      {
                        attrs: { slot: "facilityServiceTimeMappingList" },
                        slot: "facilityServiceTimeMappingList"
                      },
                      [
                        _vm._l(_vm.executorList, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "custom-form" },
                            [
                              _c(
                                "div",
                                { staticClass: "custom-form-item" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        multiple: "",
                                        placeholder: "添加协作人",
                                        filterable: ""
                                      },
                                      model: {
                                        value: item.userIds,
                                        callback: function($$v) {
                                          _vm.$set(item, "userIds", $$v)
                                        },
                                        expression: "item.userIds"
                                      }
                                    },
                                    _vm._l(_vm.executorUserList, function(q) {
                                      return _c("el-option", {
                                        key: q.id,
                                        attrs: { label: q.name, value: q.id }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "custom-form-item" },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: {
                                        change: function($event) {
                                          return _vm.onRadioChange(
                                            item.isSame,
                                            index
                                          )
                                        }
                                      },
                                      model: {
                                        value: item.isSame,
                                        callback: function($$v) {
                                          _vm.$set(item, "isSame", $$v)
                                        },
                                        expression: "item.isSame"
                                      }
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("服务时间同执行人")
                                      ]),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: 0 } },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "datetimerange",
                                              "range-separator": "至",
                                              "start-placeholder":
                                                "选择服务开始时间",
                                              "end-placeholder":
                                                "选择服务结束时间",
                                              "value-format":
                                                "yyyy-MM-dd HH:mm",
                                              format: "yyyy-MM-dd HH:mm",
                                              clearable: "",
                                              disabled: item.isSame === 1,
                                              "picker-options":
                                                _vm.pickerOptions
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.datePickerChange(
                                                  index
                                                )
                                              }
                                            },
                                            model: {
                                              value: item.date,
                                              callback: function($$v) {
                                                _vm.$set(item, "date", $$v)
                                              },
                                              expression: "item.date"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "hour" }, [
                                    _c("label", [_vm._v("服务时长")]),
                                    _vm._v(
                                      " " + _vm._s(_vm.getTime(item)) + "小时 "
                                    )
                                  ]),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.delExecutorList(index)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.addExecutor }
                          },
                          [_vm._v("添加")]
                        )
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        attrs: { slot: "facilityInspectionSpotDetectioList" },
                        slot: "facilityInspectionSpotDetectioList"
                      },
                      [
                        _vm._l(_vm.localAbnormalList, function(item, index) {
                          return _c(
                            "div",
                            { key: item.id },
                            [
                              _c(
                                "el-card",
                                { staticClass: "box-card" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clearfix",
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s("#" + (index + 1)))
                                      ]),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            float: "right",
                                            padding: "3px 0"
                                          },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.delFormList(index)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "MFormBuilder",
                                    {
                                      key: item.id,
                                      ref: "localAbnormalForm",
                                      refInFor: true,
                                      attrs: {
                                        "form-list": _vm.getLocalAbnormalFormList(
                                          item.processMode
                                        ),
                                        "form-data": item,
                                        "label-position": "right"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            slot: "facilityId",
                                            placeholder: "选择设备"
                                          },
                                          slot: "facilityId",
                                          model: {
                                            value: item.facilityId,
                                            callback: function($$v) {
                                              _vm.$set(item, "facilityId", $$v)
                                            },
                                            expression: "item.facilityId"
                                          }
                                        },
                                        _vm._l(
                                          _vm.getFacilityNameOptions(index),
                                          function(item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.facilityName,
                                                value: item.facilityId
                                              }
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            slot: "abnormalItem",
                                            multiple: "",
                                            placeholder:
                                              "选择异常项目（可多选）"
                                          },
                                          slot: "abnormalItem",
                                          model: {
                                            value: item.abnormalItem,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "abnormalItem",
                                                $$v
                                              )
                                            },
                                            expression: "item.abnormalItem"
                                          }
                                        },
                                        _vm._l(
                                          _vm.abnormalItemOptions,
                                          function(item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.content,
                                                value: item.content
                                              }
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _c("el-input", {
                                        attrs: {
                                          slot: "description",
                                          maxlength: "200",
                                          placeholder: "不超过200字"
                                        },
                                        slot: "description",
                                        model: {
                                          value: item.description,
                                          callback: function($$v) {
                                            _vm.$set(item, "description", $$v)
                                          },
                                          expression: "item.description"
                                        }
                                      }),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            slot: "processMode",
                                            placeholder: "选择处理方式"
                                          },
                                          slot: "processMode",
                                          model: {
                                            value: item.processMode,
                                            callback: function($$v) {
                                              _vm.$set(item, "processMode", $$v)
                                            },
                                            expression: "item.processMode"
                                          }
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "现场解决",
                                              value: "现场解决"
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "待解决-需求",
                                              value: "待解决-需求"
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "已解决-工单",
                                              value: "已解决-工单"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.addFormList }
                          },
                          [_vm._v("添加")]
                        )
                      ],
                      2
                    ),
                    _c(
                      "el-upload",
                      _vm._b(
                        {
                          class: { "upload-view": _vm.isView },
                          attrs: {
                            slot: "drawing",
                            "file-list": _vm.attachments
                          },
                          slot: "drawing"
                        },
                        "el-upload",
                        _vm.uploadProps2,
                        false
                      ),
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", size: "small" } },
                          [_vm._v("上传文件")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip"
                          },
                          [
                            _vm._v(
                              "支持扩展名：.rar .zip .doc .docx .pdf .jpg..."
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
          ],
          1
        )
      ])
    ]),
    !_vm.isView
      ? _c("div", { staticClass: "detail-footer" }, [
          _c(
            "div",
            { staticClass: "footer-ri" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.returns } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.buttonLoading,
                    size: "small"
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }