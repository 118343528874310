<template>
  <div class="unit-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button label="Organization">组织架构</el-radio-button>
      <el-radio-button label="OrganizationType">组织类型</el-radio-button>
    </el-radio-group>
    <div class="top">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="请输入组织名称"
        :hidden-title-search="params.radio === 'OrganizationType'"
        :radio="params.radio"
        :tags="functionHeader[`${params.radio}Tags`]"
        :columns-key="columnsKey"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        ref="mTable"
        :columns="columns[params.radio]"
        :is-tree-data="true"
        :show-page="false"
        :height="height"
        :columns-key="columnsKey"
        :set-data-method="getTableData"
        :columns-setting="false"
        default-expand-all
        @selection-change="selectionChange"
      >
        <div slot="remark" slot-scope="{ row }">
          <div>{{ row.remark || '-' }}</div>
        </div>
        <div slot="action" slot-scope="{ row }">
          <template>
            <el-button v-if="row.isSystem !== 1 || params.radio === 'Organization'" type="text" @click="modify(row)">修改</el-button>
            <el-divider v-if="row.isSystem !== 1" direction="vertical" />
            <DelPopover v-if="row.isSystem !== 1" @onOk="callback => dels(row, callback)" />
          </template>
        </div>
      </MTable>
      <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="formData" :form-list="dialogFormList" />
      </MDialog>
    </div>
  </div>
</template>

<script>
import DelPopover from '@/components/DelPopover/index'
import FunctionHeader from '@/components/FunctionHeader/index'
import { Organization, OrganizationType } from './columns'
import formList from './form-list'
import batchExportFront from '@/mixin/batch-export-front'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/sets/framework'
import userApi from '@/api/sets/user/user'

export default {
  name: 'ConfigurationParameter',
  components: { DelPopover, FunctionHeader },
  mixins: [batchExportFront, conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'Organization'
      },
      columns: {
        Organization,
        OrganizationType
      },
      selectionData: [],
      functionHeader: {
        searchVal: '',
        OrganizationTags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增组织',
            on: {
              click: this.adds
            }
          }
        ],
        OrganizationTypeTags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增类型',
            on: {
              click: this.adds
            }
          }
        ]
      },
      visible: false,
      currentType: 'add',
      formList,
      formData: {},
      dialogFormList: [],
      organizationList: [],
      organizationTypeList: [],
      userList: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 245
    },
    searchKeyword() {
      return this.params.radio === 'Organization'
    }
  },
  mounted() {
    this.getOrganizationTypeList()
    this.getOrganizationList()
    this.getUserList()
  },

  methods: {
    async getTableData(condition, callback) {
      const params = {}
      if (this.searchKeyword) {
        params.searchKeyword = this.functionHeader.searchVal
      }
      const res = await this.api[`get${this.params.radio}List`]({
        ...params
      })

      if (res) {
        callback({
          total: (res || []).length,
          content: res
        })
      }
      callback()
    },

    // 获取组织类型
    async getOrganizationTypeList() {
      const res = await api.getOrganizationTypeList()
      this.organizationTypeList = res
      this.formList.Organization[1].children[0].options = res
    },

    // 获取上级组织
    async getOrganizationList() {
      const res = await api.getOrganizationList()
      this.organizationList = res
      this.formList.Organization[2].children[0].options = res
    },

    // 获取负责人
    async getUserList() {
      const res = await userApi.getUserList()
      this.userList = res
      this.formList.Organization[3].children[0].options = res
    },

    radioChange() {
      this.$refs.mTable.setTableData(true)
    },

    adds() {
      this.dialogFormList = formList[this.params.radio]
      this.params.radio === 'Organization' && this.getOrganizationTypeList()
      this.add()
      this.getOrganizationList()
    },

    modify(row) {
      const list = formList[this.params.radio]
      if (row.parentId === -1) {
        this.dialogFormList = list.filter(item => item.key === 'userId')
      } else {
        this.dialogFormList = list
      }
      this.formList.Organization[2].children[0].options = this.organizationList.filter(item => item.id !== row.id)
      this.modifyOrCopy(row, 'modify')
      this.getOrganizationList()
    },

    createSetData(row) {
      const typeName = (this.organizationTypeList.find(item => item.id === row.typeId) || {}).name
      const userName = (this.userList.find(item => item.id === row.userId) || {}).name
      return {
        ...row,
        typeName,
        userName
      }
    },

    createResetData(row) {
      return Object.assign(this.createSetData(row), {
        id: row.id
      })
    },

    dels(row) {
      this.currentType = 'del'
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await this.api[`del${this.params.radio}`]({ id: row.id, userName: row.userName })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    },

    delBatchTemplates(row) {
      this.$confirm('确定要删除选择模板吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        if (this.params.radio === 'Organization') {
          const res = await api.delBatchTemplates({ ids: this.exportSelectionIds.join('^') })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData(true)
          }
        } else {
          const res = await api.delBatchParameter({ ids: this.exportSelectionIds.join('^') })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData(true)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.unit-wrap {
  padding: 10px;
}
::v-deep {
  .el-table__expand-icon {
    position: relative;
    top: 6px;

    &--expanded {
      top: 9px;
    }
  }
}
</style>
