var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.showDeviceParametersList, function(items) {
      return _c("div", { key: items.name }, [
        items.name != "OEE" && items.name != "设备状态"
          ? _c("div", { staticClass: "centerText" }, [
              _c("div", [_vm._v(_vm._s(items.name) + "：")]),
              _c("div", [
                _c("span", [_vm._v(_vm._s(items.value))]),
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "14px", "margin-left": "2px" }
                  },
                  [_vm._v(_vm._s(items.unit))]
                )
              ])
            ])
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }