var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "switch-shift-wrapper" }, [
    _c(
      "div",
      { staticClass: "form-build-wrapper" },
      [
        _c("MFormBuilder", {
          ref: "formBuild",
          attrs: {
            "form-data": _vm.formData,
            "form-list": _vm.formList,
            "label-position": "left"
          }
        }),
        _c(
          "el-button",
          {
            staticClass: "save-button",
            attrs: { type: "primary" },
            on: { click: _vm.submitForm }
          },
          [_vm._v("保存")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }