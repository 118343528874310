<template>
  <div class="self-center-wrapper">
    <div class="left">
      <el-menu
        :default-active="activeMenu"
        class="left-menu"
        background-color="#DDE3EB"
        @select="activeMenuItem"
      >
        <el-menu-item v-for="item in menuList" :key="item.name" :index="item.name">
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="right">
      <h3 class="header">{{ activeMenuBane }}</h3>
      <div class="router-body">
        <Blank />
      </div>
    </div>
  </div>
</template>

<script>
import Blank from '@/layout/blank'
export default {
  name: 'SelfCenter',
  components: { Blank },
  data() {
    return {
      menuList: [
        {
          name: 'userInfo',
          title: '用户信息'
        },
        {
          name: 'modifyPassword',
          title: '修改密码'
        },
        {
          name: 'switchShift',
          title: '切换班次'
        }
      ]
    }
  },
  computed: {
    activeMenu() {
      return this.$route.name
    },
    activeMenuBane() {
      const current = this.menuList.find(item => item.name === this.$route.name)
      return current.title
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  mounted() {
    if (!['reagold'].includes(sessionStorage.getItem('memberCode'))) {
      this.menuList.push({
        name: 'mineEquipment',
        title: '我的设备'
      })
    }
  },
  methods: {
    activeMenuItem(name) {
      this.$router.push({ name })
    }
  }
}
</script>

<style scoped lang="scss">
.self-center-wrapper {
  width: 100%;
  height: 100%;
  .left {
    width: 173px;
    height: 100%;
    float: left;
    ul.el-menu {
      height: 100%;
      border-right: none
    }
    li.is-active {
      background: #FFFFFF !important;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 100%;
        background: #607FFF;
        position: absolute;
        left: 0;
      }
    }
  }
  .right {
    float: right;
    width: calc(100% - 173px - 40px);
    .header {
      width: 100%;
      border-bottom: 1px solid #DDE3EB;
      margin: 0;
      padding: 18px 0;
    }
    .router-body {
      padding-top: 30px;
    }
  }
}
</style>
