<template>
  <div class="table-slot-list">
    <!--    工单达成率-->
    <div v-if="slotName === 'orderOverPercent'">
      <el-progress :text-inside="true" :stroke-width="15" :percentage="getPercent(row.reportWorkQuantity, row.quantity)" />
    </div>
    <!--    订单/准时达成率-->
    <div v-if="['finishedRatio', 'finishedOnTimeRatio', 'onTimeRatio', 'NGRatio'].includes(slotName)">
      <el-progress :text-inside="true" :stroke-width="15" :percentage="fixNumber2(row[column.columnKey || column.prop])" />
    </div>
    <!--    当前所处工序-->
    <div v-if="slotName === 'currentProcedure'">
      {{ getCurrentProcedure('procedureName') }}
    </div>
    <div v-if="slotName === 'currentProcedureDuration'">
      <mv-count-down
        v-if="!toDay && !getCurrentProcedure('departureTime') && getCurrentProcedure('arrivalTime')"
        :show-delay-time="true"
        :start-time="getCountDownStartTime(getCurrentProcedure('arrivalTime'), false)"
        :end-time="getCountDownEndTime(getCurrentProcedure('arrivalTime'))"
        :day-txt="'天'"
        :hour-txt="'小时'"
        :minutes-txt="'分钟'"
        :seconds-txt="'秒'"
        :is-start="isStart"
        :class="{ isRed }"
      />
      <div v-if="toDay && !getCurrentProcedure('departureTime') && getCurrentProcedure('arrivalTime')">
        <span v-html="calcTime(new Date(), getCurrentProcedure('arrivalTime'))" />
      </div>
      <div v-if="getCurrentProcedure('departureTime') && getCurrentProcedure('arrivalTime')">{{ calcTime(getCurrentProcedure('departureTime'), getCurrentProcedure('arrivalTime')) }}</div>
      <div v-if="!getCurrentProcedure('arrivalTime')">未进站</div>
    </div>
    <div v-if="slotName === 'currentProcedureStation'">
      {{ getCurrentProcedure('checkInStationName') }}
    </div>
    <div v-if="slotName === 'currentProcedurePerson'">
      {{ getCurrentProcedure('checkInUser') }}
    </div>
    <!--    当前所处工序段-->
    <div v-if="slotName === 'currentProcedureGroupName'">
      {{ getCurrentProcedure('procedureGroupName') }}
    </div>
    <!--    ...-->
    <div v-if="['planStartTime','planEndTime','actualStartTime'].includes(slotName)">
      {{ getPlanTime() }}
    </div>
    <!--    距离计划结束时间还剩-->
    <div v-if="slotName === 'remainPlanTime'">
      <mv-count-down
        v-if="!toDay && row.status !== 2 && row.producePlan.planEndTime"
        :show-delay-time="true"
        :start-time="getCountDownStartTime(row.producePlan.planEndTime)"
        :end-time="getCountDownEndTime(row.producePlan.planEndTime)"
        :day-txt="'天'"
        :hour-txt="'小时'"
        :minutes-txt="'分钟'"
        :seconds-txt="'秒'"
        :is-start="isStart"
        :class="{ isRed }"
      />
      <div v-if="toDay && row.status !== 2 && row.producePlan.planEndTime">
        <span v-html="calcTime(row.producePlan.planEndTime, new Date())" />
      </div>
      <div v-if="row.status !== 2 && !row.producePlan.planEndTime" />
      <div v-if="row.status === 2">已完成</div>
    </div>
    <!--    距离交期还剩-->
    <div v-if="slotName === 'remainDeliveryTime'">
      <mv-count-down
        v-if="!toDay && row.status !== 2"
        :show-delay-time="true"
        :start-time="getCountDownStartTime(row.deliveryTime.slice(0, 10) + ' 23:59:59')"
        :end-time="getCountDownEndTime(row.deliveryTime.slice(0, 10) + ' 23:59:59')"
        :day-txt="'天'"
        :hour-txt="'小时'"
        :minutes-txt="'分钟'"
        :seconds-txt="'秒'"
        :is-start="isStart"
        :class="{ isRed }"
      />
      <div v-if="toDay && row.status !== 2">
        <span v-html="calcTime(row.deliveryTime.slice(0, 10) + ' 23:59:59', new Date())" />
      </div>
      <div v-if="row.status !== 2 && !row.producePlan.actualStartTime" />
      <div v-if="row.status === 2">已完成</div>
    </div>
    <div v-if="slotName === 'workOrderDuration'">
      <mv-count-down
        v-if="!toDay && !row.producePlan.actualEndTime && row.producePlan.actualStartTime"
        :show-delay-time="true"
        :start-time="getCountDownStartTime(row.producePlan.actualStartTime, false)"
        :end-time="getCountDownEndTime(row.producePlan.actualStartTime)"
        :day-txt="'天'"
        :hour-txt="'小时'"
        :minutes-txt="'分钟'"
        :seconds-txt="'秒'"
        :is-start="isStart"
        :class="{ isRed }"
      />
      <div v-if="toDay && !row.producePlan.actualEndTime && row.producePlan.actualStartTime">
        <span v-html="calcTime(new Date(), row.producePlan.actualStartTime)" />
      </div>
      <div v-if="row.producePlan.actualEndTime && row.producePlan.actualStartTime">{{ calcTime(row.producePlan.actualEndTime, row.producePlan.actualStartTime) }}</div>
      <div v-if="!row.producePlan.actualStartTime">未开始</div>
    </div>
    <div v-if="slotName === 'status'" :style="{ color: statusColors[row.status] }">{{ row.status | workStatus }}</div>
    <div v-if="slotName === 'getDeepName'">{{ _.get(row, column.columnKey || column.prop) }}</div>
    <div v-if="slotName === 'includeUser'">
      <el-tag v-for="item in row.info.userList" :key="item.id" color="#F6F7F9" class="user-tags">{{ item.name }}</el-tag>
    </div>
    <!--    库存上下限-->
    <template v-if="['availableQuantity','currentQuantity'].includes(slotName)">
      <div
        v-if="slotName === checkNumberKey"
        :slot="checkNumberKey"
        style="width: 100%;display: inline-flex;align-items: center"
      >
        <div style="display: inline-block;">{{ row[checkNumberKey] }}</div>
        <div v-if="row.isLimit && checkNumber(row)" class="tooltip">{{ checkNumber(row) }}</div>
      </div>
      <div v-if="slotName === getOtherKey()" :slot="getOtherKey()">{{ row[getOtherKey()] }}</div>
    </template>
    <div v-if="slotName === 'procedureRatio'" class="flex-cc" style="width: 100%">
      <el-progress type="circle" :percentage="row.procedureRatio ? fixNumber2(row.procedureRatio) : 0" :width="50" :stroke-width="5" color="#607FFF" />
    </div>
    <!--    日期Range-->
    <div v-if="slotName === 'monthRange'">{{ row.beginTime }} ~ {{ row.endTime }}</div>
    <div
      v-if="slotName === 'priorityStatus'"
      :style="{ color: priorityStatus[row.priority].color ? priorityStatus[row.priority].color : 'rgb(74, 79, 118)', background: `${priorityStatus[row.priority].color ? priorityStatus[row.priority].color : 'rgb(74, 79, 118)'}20`}"
      class="tag-priority"
    >
      {{ priorityStatus[row.priority].name ? priorityStatus[row.priority].name : '普通' }}
    </div>

    <!-- UR/h -->
    <div v-if="slotName === 'UrHours'">{{ row.urHours || '-' }}</div>

    <!-- 动态列表数据展示 -->
    <div v-if="slotName.includes('listData')">{{ row[config.listDataKey][config.listIndex][config.listKey || 'quantity'] }}</div>
  </div>
</template>

<script>
import mvCountDown from '../count-down/count-down.vue'
import { productionStatus } from '@/config/options.config'
import { quanlityInfoType } from '@/config/options.sales'

export default {
  name: 'TableSlotList',
  components: { mvCountDown },
  filters: {
    workStatus(id) {
      return productionStatus.find(item => item.id === id).name
    }
  },
  props: {
    slotName: {
      required: true,
      type: String,
      default: ''
    },
    row: {
      required: true,
      type: Object,
      default: () => ({})
    },
    column: {
      required: true,
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    },
    toDay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isStart: true,
      isRed: false,
      statusColors: ['#F96E17', '#00AB29'],
      checkNumberKey: '',
      quanlityInfoType
    }
  },
  computed: {
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  mounted() {
    if (['availableQuantity', 'currentQuantity'].includes(this.slotName)) {
      this.setCheckNumberKey()
    }
  },
  methods: {
    async setCheckNumberKey() {
      const res = sessionStorage.getItem('stockWarnList') && JSON.parse(sessionStorage.getItem('stockWarnList'))
      if (res) {
        switch (res.selectedItemIdList[0]) {
          case 5: this.checkNumberKey = 'currentQuantity'; break
          case 6: this.checkNumberKey = 'availableQuantity'; break
          default: break
        }
      }
    },
    // 安全线判定
    checkNumber(row) {
      let str = ''
      if (row[this.checkNumberKey] < row.lowerLimit) {
        str = `低于库存下限 ${row.lowerLimit}`
      }
      if (row[this.checkNumberKey] > row.upperLimit) {
        str = `高于库存上限 ${row.upperLimit}`
      }
      return str
    },
    getOtherKey() {
      return this.checkNumberKey === 'currentQuantity' ? 'availableQuantity' : 'currentQuantity'
    },
    getPercent(fa, fb) {
      const a = Number(fa)
      const b = Number(fb)
      const c = b === 0 ? 0 : Number(((a / b * 100).toFixed(2)))
      let flagU = -1
      let flagD = -1
      if (this.row.produceWorkOrderTaskList) {
        const { procedureDifferenceType, procedureDifferencePlus, procedureDifferenceMinus } = this.row.produceWorkOrderTaskList[0]
        // 数值
        if (procedureDifferenceType === 0) {
          flagD = b - procedureDifferenceMinus
          flagU = b + procedureDifferencePlus
        }
        // 百分比
        if (procedureDifferenceType === 1) {
          flagD = b - Number((b * procedureDifferenceMinus / 100).toFixed(2))
          flagU = b + Number((b * procedureDifferencePlus / 100).toFixed(2))
        }
      }
      return c > 100 || flagD <= a && a <= flagU ? 100 : c
    },
    fixNumber2(number) {
      const newNumber = Number(number)
      return Number(newNumber.toFixed(2))
    },
    getCurrentProcedure(key) {
      const { produceWorkOrderTaskList } = this.row
      return produceWorkOrderTaskList ? produceWorkOrderTaskList
        .filter(item => item[key])
        .map(item => item[key]).join(' - ') : ''
    },
    getPlanTime() {
      return this.row.producePlan[this.column.columnKey || this.column.prop]
    },
    getCountDownStartTime(time, showDelay = true) {
      const start = new Date().getTime()
      const end = new Date(time).getTime()
      if (showDelay && end < start) {
        this.isRed = true
      }
      return start
    },
    getCountDownEndTime(time) {
      return new Date(time).getTime()
    },
    calcTime(start, end) {
      const msTime = {}
      let timeDistance = new Date(start).getTime() - new Date(end).getTime()
      if (this.toDay) {
        msTime.day = (timeDistance / 86400000).toFixed(1)
        return Number(msTime.day) >= 0 ? `<span>${msTime.day}天</span>` : `<span style="color: red">已延期${-Number(msTime.day)}天</span>`
      }
      msTime.day = Math.floor(timeDistance / 86400000)
      timeDistance -= msTime.day * 86400000
      msTime.hour = Math.floor(timeDistance / 3600000)
      timeDistance -= msTime.hour * 3600000
      msTime.minutes = Math.floor(timeDistance / 60000)
      timeDistance -= msTime.minutes * 60000
      // 是否开启秒表倒计,未完成
      //                    this.secondsFixed ? msTime.seconds = new Number(timeDistance / 1000).toFixed(2) : msTime.seconds = Math.floor( timeDistance / 1000 ).toFixed(0);
      msTime.seconds = Math.floor(timeDistance / 1000).toFixed(0)
      timeDistance -= msTime.seconds * 1000

      if (msTime.hour < 10) {
        msTime.hour = '0' + msTime.hour
      }
      if (msTime.minutes < 10) {
        msTime.minutes = '0' + msTime.minutes
      }
      if (msTime.seconds < 10) {
        msTime.seconds = '0' + msTime.seconds
      }
      return `${msTime.day > 0 ? msTime.day + '天' : ''}${msTime.hour > 0 ? msTime.hour + '小时' : ''}${msTime.minutes > 0 ? msTime.minutes + '分钟' : ''}${msTime.seconds}秒`
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-progress__text {
  color: #607FFF;
}
.table-slot-list {
  ::v-deep {
    .el-progress-bar__inner {
      background: linear-gradient(90deg, #99EAFF 0%, #607FFF 100%);
    }
  }
}
.isRed {
  color: #E02020;
  ::v-deep p:before {
    content: "已延期"
  }
}
.user-tags {
  margin: 5px;
  border-color: #DDE3EB;
  color: #9597AE;
}
div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #DF6A6A;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #ffffff;
  box-shadow: 2px 2px 2px 0 #c1c1c1;
  margin-left: 10px;
}

div.tooltip:before, div.tooltip:after {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #DF6A6A;
  position: absolute;
  left: -6px;
  top: 10px;
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #DF6A6A;
  position: absolute;
  left: -6px;
  top: 10px;
  z-index: 1;
}

</style>
