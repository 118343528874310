export const columns = [
  {
    prop: 'workOrderCode',
    label: '工单编号',
    hiddenSearch: true
  },
  {
    prop: 'facilitySpotCheckPlanName',
    label: '点检计划',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planExecutionStartTime',
    label: '计划执行时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]

export const shipColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'facilityCode',
    label: '设备编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'facilityName',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'SN',
    label: 'SN',
    sortable: false,
    hiddenSearch: true,
    slotName: 'SN'
  },
  {
    prop: 'model',
    label: '型号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentClassName',
    label: '分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'customerName',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true
  }
]

export const projectColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'content',
    label: '项目内容',
    sortable: false,
    hiddenSearch: true
  }
]
