<template>
  <div class="detail-wrappers">
    <div class="detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
    </div>
    <div class="detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in formList" :key="item.id" :span="item.colSpan">
              <div class="view-list__label">{{ item.label }}</div>
              <div v-if="item.key !== 'attachments'" class="view-list__value">{{ getValue(item) || '-' }}</div>
              <el-upload v-else :file-list="attachments" v-bind="uploadProps2" disabled :class="{ 'upload-view': isView }" />
            </el-col>
          </el-row>
          <MFormBuilder v-else ref="baseForm" :form-list="formList" :form-data="baseFormData" label-position="right">
            <el-upload slot="drawing" :file-list="attachments" v-bind="uploadProps2">
              <el-button type="primary" size="small">上传文件</el-button>
            </el-upload>
          </MFormBuilder>
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">关联设备</div>
        <div class="card-bot">
          <relationEquipment :type="isView ? '' : 'new'" :customer-id="customerId" :list="facilityList" @facilityInfo="facilityInfo" />
        </div>
      </div>
    </div>
    <div class="order-footer">
      <div class="footer-le" />
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getFunctionList } from './columns'
import { formList } from './form-list'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { getToken } from '@/utils/auth'
import api from '@/api/xiewei/service/service-order'
import fieldApi from '@/api/xiewei/field/field'
import keApi from '@/api/information/unit'
import relationEquipment from '@/views/service/service-order-detail/components/relation-equipment'
import { demandServiceTypes } from '@/config/options.sales'

export default {
  name: 'AddServiceOrder',
  components: { relationEquipment },
  data() {
    return {
      baseFormData: {},
      formList,
      params: {},
      messages: {
        add: '新增成功',
        modify: '修改成功',
        viewO: '服务订单详情',
        addO: '新增服务订单',
        modifyO: '修改服务订单'
      },
      buttonLoading: false,
      facilityList: [],
      customerId: undefined,
      usersList: [],
      attachments: [],
      uploadProps2: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onRemove: this.removeFile,
        onPreview: this.handlePreview
      },
      demandServiceTypes,
      isView: this.$route.query.type === 'view'
    }
  },
  computed: {
    getValue() {
      return row => {
        const result = this.baseFormData[row.key]
        if (row.children && row.children[0] && row.children[0].options && row.key !== 'serviceContent') {
          const current = row.children[0].options.find(item => item.id === result)
          return current && current.name
        }
        if (row.key === 'guaranteePeriodStart') {
          return (result || []).join('~')
        }
        return result
      }
    }
  },

  beforeMount() {
    getFunctionList({
      serviceChange: this.serviceChange
    })
  },

  mounted() {
    this.params = this.$route.query
    this.getUser()
    if (this.params.type !== 'add') {
      this.getDetail()
    }
    if (this.params.type !== 'view') {
      this.createFieldCode()
    }
  },
  methods: {
    returns() {
      this.$router.back()
    },

    async createFieldCode() {
      const res = await fieldApi.getFieldIsExist({
        numberVersion: '服务订单'
      })
      if (res && +res === 0) {
        if (this.params.type === 'add') {
          this.$refs.baseForm.setForm({
            code: '系统生成'
          })
        }
        this.formList[0].props.disabled = true
      }
    },

    serviceChange(val) {
      this.customerId = val
      this.facilityList = []
    },

    facilityInfo(val) {
      this.facilityList = val
    },

    // 获取客户
    async getUser() {
      const res = await keApi.getCustomerList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.formList[3].children[0].options = res
      }
    },

    async getDetail() {
      const res = await api.getServiceOrderDetail(this.$route.query.id)
      if (res) {
        this.isReform = +res.content === 3
        this.baseFormData = { ...res, guaranteePeriodStart: [res.guaranteePeriodStart, res.guaranteePeriodEnd] }
        const list = this.baseFormData.serviceContent.split('、')
        if (!this.isView) {
          this.baseFormData.serviceContent = this.demandServiceTypes.filter(item => list.includes(item.name)).map(item => item.id)
        }
        this.customerId = res.consumerId
        this.attachments = JSON.parse(res.attachments || '[]')
        this.facilityList =
          res.facilityList && res.facilityList.length > 0
            ? res.facilityList.map(item => {
              return {
                ...item
              }
            })
            : []
      }
    },

    // 上传附件
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.attachments.push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    removeFile(file) {
      this.attachments = this.attachments.filter(item => item.uid !== file.uid)
    },
    handlePreview(file) {
      if (file.url.indexOf('.txt') > 0) {
        saveAs(file.url, '文件')
        return
      }
      window.open(file.url)
    },

    submitForm() {
      const that = this
      const { form } = this.$refs.baseForm
      this.buttonLoading = true
      this.$refs.baseForm.formRefs().validate(async valid => {
        if (valid) {
          that.saveData(form)
        } else {
          this.buttonLoading = false
        }
      })
    },

    async saveData(form) {
      const facilityList = this.facilityList.map(item => {
        return {
          id: item.id,
          code: item.code,
          name: item.name,
          sn: item.sn,
          model: item.model,
          equipmentClassName: item.equipmentClassName
        }
      })
      const res = await api[`${this.params.type}ServiceOrder`](
        this.params.type !== 'modify'
          ? {
            ...this.createSetData(form),
            facilityList: facilityList
          }
          : {
            ...this.createResetData(form),
            facilityList: facilityList
          }
      )
      if (res) {
        this.returns()
      }
      this.buttonLoading = false
    },

    createSetData(raw) {
      const data = this.demandServiceTypes
        .filter(item => raw.serviceContent.includes(item.id))
        .map(item => item.name)
        .join('、')
      return {
        code: raw.code === '系统生成' ? -1 : raw.code,
        orderNumber: raw.orderNumber,
        serviceContent: data,
        consumerId: raw.consumerId,
        guaranteePeriodStart: raw.guaranteePeriodStart[0],
        guaranteePeriodEnd: raw.guaranteePeriodStart[1],
        laborCost: raw.laborCost,
        sparePartsCost: raw.sparePartsCost,
        consumptionOfProductCost: raw.consumptionOfProductCost,
        attachments: JSON.stringify(this.attachments)
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrappers {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
    .date-number {
      width: 100%;
      display: flex;

      .el-form-item:first-child {
        width: 138% !important;

        input {
          border-radius: 4px 0 0 4px;
        }
      }

      .el-form-item:last-child {
        width: 62% !important;

        input {
          border-radius: 0 4px 4px 0;
          border-left: 0;
        }
      }
    }
  }
  .detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }

  .view-list {
    padding: 0 15px;
    color: #393d60;
    font-size: 14px;

    &__label {
      margin-bottom: 4px;
      color: #9597ae;
    }

    .el-col {
      margin: 10px 0;
    }
  }

  .table-cell {
    display: flex;
    align-items: center;
    justify-content: center;

    .required-star {
      width: 10px;
      color: #f00;
    }

    .range {
      margin: 0 10px;
    }
  }

  .upload-view {
    ::v-deep.el-upload {
      &--text {
        display: none;
      }
    }
  }

  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
