var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shift-wrap" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForm", {
            ref: "searchForm",
            attrs: { "form-list-extend": _vm.searchFormList },
            on: { search: _vm.searchTable }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "FunctionHeader",
            {
              ref: "functionHeader",
              attrs: {
                "search-title": "请输入工作内容",
                tags: !_vm.exportSelectionIds.length
                  ? _vm.functionHeader.tags
                  : [],
                "export-name": _vm.functionHeader.exportName,
                "export-params": _vm.functionHeader.exportParams,
                "show-export": !_vm.exportSelectionIds.length ? true : false
              },
              on: {
                search: function($event) {
                  return _vm.$refs.mTable.setTableData(true)
                }
              },
              model: {
                value: _vm.functionHeader.searchVal,
                callback: function($$v) {
                  _vm.$set(_vm.functionHeader, "searchVal", $$v)
                },
                expression: "functionHeader.searchVal"
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.exportSelectionIds.length,
                      expression: "exportSelectionIds.length"
                    }
                  ],
                  attrs: { slot: "all" },
                  slot: "all"
                },
                [
                  _vm.permission("Export")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.allMore("batchExport")
                            }
                          }
                        },
                        [_vm._v("批量导出")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "0 10px",
                        display: "inline-block",
                        "font-size": "12px",
                        color: "#393D60"
                      }
                    },
                    [
                      _vm._v(" 已选 "),
                      _c("span", { staticStyle: { color: "#607FFF" } }, [
                        _vm._v(_vm._s(_vm.getSelectionDataLength))
                      ]),
                      _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: { click: _vm.resetSelectData }
                    },
                    [_vm._v("清空")]
                  )
                ],
                1
              )
            ]
          ),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.getColumns,
              height: _vm.height,
              "set-data-method": _vm.getTableData
            },
            on: { "selection-change": _vm.selectionChange },
            scopedSlots: _vm._u(
              [
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "customerSiteIdList",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [_vm._v(_vm._s(row.customerName))])
                  }
                },
                {
                  key: "jobType",
                  fn: function(ref) {
                    var row = ref.row
                    return !row.dateType && row.dateType !== 0
                      ? _c("div", {}, [_vm._v(_vm._s(_vm.getJobType(row)))])
                      : _c("div", {}, [
                          _vm._v(
                            _vm._s(_vm.getJobType(row)) +
                              " - " +
                              _vm._s(_vm.getDateType(row))
                          )
                        ])
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    return _vm.permission("SuperPermission")
                      ? _c(
                          "div",
                          {},
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.modifys(row, "modify")
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            ),
                            _c("el-divider", {
                              attrs: { direction: "vertical" }
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.del(row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      : _vm.fixUsers(row.updater, row.creatorId)
                      ? _c(
                          "div",
                          {},
                          [
                            _vm.permission("Modify")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.modifys(row, "modify")
                                      }
                                    }
                                  },
                                  [_vm._v("修改")]
                                )
                              : _vm._e(),
                            _vm.permission("Modify")
                              ? _c("el-divider", {
                                  attrs: { direction: "vertical" }
                                })
                              : _vm._e(),
                            _vm.permission("Del")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.del(row)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _c(
                                  "div",
                                  { attrs: { slot: "action" }, slot: "action" },
                                  [_vm._v(_vm._s("-"))]
                                )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { attrs: { slot: "action" }, slot: "action" },
                          [_vm._v(_vm._s("-"))]
                        )
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType], width: "500px" },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              staticClass: "extra-froms",
              attrs: {
                "form-data": _vm.formData,
                "form-list": _vm.getFormList,
                "label-position": "right"
              }
            },
            [
              _c("div", { attrs: { slot: "manHour" }, slot: "manHour" }, [
                _vm._v(_vm._s(_vm.manHour || 0) + "小时")
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }