<template>
  <el-popover placement="bottom">
    <template v-if="iconShow" slot="reference">
      <el-button type="text">
        <img src="@/assets/statistics-board/高级筛选@2x.png" width="14">
      </el-button>
    </template>
    <el-button v-else slot="reference" size="small">
      {{ showTitle }}
    </el-button>
    <el-checkbox-group v-model="checkList" @change="checkboxChange">
      <el-checkbox :label="0" style="padding-bottom: 5px">当月</el-checkbox><br>
      <el-checkbox :label="1" style="padding-bottom: 5px">按季度</el-checkbox><br>
      <el-checkbox :label="2" style="padding-bottom: 5px">按半年</el-checkbox><br>
      <el-checkbox :label="3" style="padding-bottom: 5px">按当年</el-checkbox><br>
      <el-checkbox :label="4" style="padding-bottom: 5px">按最近一年</el-checkbox><br>
      <el-checkbox :label="5">自定义</el-checkbox>
    </el-checkbox-group>
    <el-date-picker
      v-if="checkList[0] === 5"
      v-model="dateRange"
      class="custom-date-picker"
      :type="formatDateType === 'YYYY-MM' ? 'monthrange' : (!dateType ? 'daterange' : 'datetimerange')"
      range-separator="至"
      :value-format="formatDateType === 'YYYY-MM' ? 'yyyy-MM' : (!dateType ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss')"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="dateRangeChange"
    />
  </el-popover>
</template>

<script>

import { getDateRange } from '@/views/statistics-board/board-manage/all-statistics/statistics-card-copy'

export default {
  name: 'DateRange',
  props: {
    iconShow: {
      type: Boolean,
      default: true
    },
    formatDateType: {
      type: String,
      default: 'YYYY-MM'
    },
    canNoSelect: {
      type: Boolean,
      default: false
    },
    tabInfo: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: ''
    },
    dateType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkList: this.canNoSelect ? [] : [1],
      dateRange: [],
      showTitle: !this.canNoSelect ? getDateRange(1, this.formatDateType).join(' 至 ') : (this.placeholder || '选择日期范围')
    }
  },
  mounted() {
    if (this.tabInfo.dataCondition && this.tabInfo.dataCondition.beginTime && this.tabInfo.dataCondition.endTime) {
      const times = [0, 1, 2, 3, 4].map(item => getDateRange(item).join(' 至 '))
      const timeRange = this.tabInfo.dataCondition.beginTime + ' 至 ' + this.tabInfo.dataCondition.endTime
      const index = times.indexOf(timeRange)
      this.showTitle = timeRange
      if (index !== -1) {
        this.checkList = [index]
      } else {
        this.checkList = [5]
        this.dateRange = [this.tabInfo.dataCondition.beginTime, this.tabInfo.dataCondition.endTime]
      }
    }
  },
  methods: {
    checkboxChange(val) {
      if (val.length === 0 && !this.canNoSelect) {
        this.checkList = [1]
      } else {
        this.checkList = [val[val.length - 1]]
      }
      if (this.checkList[0] !== 5) {
        const dateRange = getDateRange(this.checkList[0], this.formatDateType)
        if (!this.iconShow) {
          this.showTitle = dateRange[0] ? dateRange.join(' 至 ') : '选择日期范围'
        }
        this.$emit('dateRangeChange', dateRange)
      }
    },
    dateRangeChange(dateRange) {
      if (!this.iconShow) {
        this.showTitle = dateRange[0] ? dateRange.join(' 至 ') : '选择日期范围'
      }
      this.$emit('dateRangeChange', dateRange)
    }
  }
}
</script>

<style scoped lang="scss">
.custom-date-picker {
  border: none;
  padding: 0;
  width: 150px;
  height: 20px;
  position: relative;
  left: 18px;
  ::v-deep {
    .el-input__icon {
      display: none;
    }
    .el-range-separator {
      width: 10%;
      font-size: 12px;
      line-height: 20px;
      padding: 0;
    }
    input {
      font-size: 12px;
    }
  }
}
</style>
