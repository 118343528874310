import dayjs from 'dayjs'
const quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)
export const getDateRange = (type, formatType = 'YYYY-MM') => {
  let startDate
  let endDate
  // 当天
  if (type === 7) {
    startDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0).format(formatType)
    endDate = dayjs().format(formatType)
  }
  // 当周
  if (type === 6) {
    const isSunday = dayjs().day()
    if (isSunday === 0) {
      startDate = dayjs(dayjs().subtract(6, 'day')).format(formatType)
      endDate = dayjs().format(formatType)
    } else {
      startDate = dayjs(dayjs().day(1)).format(formatType)
      endDate = dayjs(dayjs().day(7)).format(formatType)
    }
  }
  // 当月
  if (type === 0) {
    startDate = dayjs().startOf('month').format(formatType)
    endDate = dayjs().endOf('month').format(formatType)
  }
  // 季度
  if (type === 1) {
    const currentQuarter = dayjs().quarter()
    const currentYear = dayjs().year()
    const quarterStartAndEndDate = [['01-01', '03-31'], ['04-01', '06-31'], ['07-01', '09-31'], ['10-01', '12-31']]
    startDate = dayjs(`${currentYear}-${quarterStartAndEndDate[currentQuarter - 1][0]}`).format(formatType)
    endDate = dayjs(`${currentYear}-${quarterStartAndEndDate[currentQuarter - 1][1]}`).format(formatType)
  }
  // 半年
  if (type === 2) {
    const currentMonth = dayjs().month()
    const currentYear = dayjs().year()
    if (currentMonth <= 5) {
      endDate = dayjs(`${currentYear}-06-31`).format(formatType)
      startDate = dayjs().startOf('year').format(formatType)
    } else {
      endDate = dayjs().endOf('year').format(formatType)
      startDate = dayjs(`${currentYear}-07-01`).format(formatType)
    }
  }
  // 当年
  if (type === 3) {
    startDate = dayjs().startOf('year').format(formatType)
    endDate = dayjs().endOf('year').format(formatType)
  }
  // 去年同月至当月
  if (type === 4) {
    endDate = dayjs().format(formatType)
    startDate = dayjs().subtract(1, 'year').format(formatType)
  }
  return [startDate, endDate]
}
const defaultTimeDuration = getDateRange(1, 'YYYY-MM-DD HH:mm:ss')
const defaultTimeDuration1 = getDateRange(0, 'YYYY-MM-DD HH:mm:ss')
export default [
  {
    name: '管理工作台',
    key: 'workSpace'
  },
  {
    name: '个人工作台',
    key: 'personalWorkbench'
  },
  {
    name: '异常统计',
    key: 'exception',
    children: [
      {
        id: 1,
        name: '数量统计',
        type: 'charts',
        dataUrl: 'abnormal/private/abnormalStatistics/quantityStatistics',
        isAppApi: true,
        chartDataKey: 'quantity',
        chartType: 'pie',
        dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
        chartLegendKey: 'checkItemName',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            canNoSelect: true
          }
        ]
      },
      {
        id: 2,
        name: '当前分布',
        type: 'charts',
        dataUrl: 'abnormal/private/abnormalStatistics/statusDistribution',
        isAppApi: true,
        dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
        chartDataKey: 'distribution',
        chartType: 'bar',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            canNoSelect: true
          }
        ]
      },
      {
        id: 3,
        name: '月度环比（近一年）',
        type: 'charts',
        dataUrl: 'abnormal/private/abnormalStatistics/monthLinkRate',
        chartDataKey: 'monthRatio',
        chartType: 'bar',
        dataCondition: { beginTime: getDateRange(4, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(4, 'YYYY-MM-DD HH:mm:ss')[1] },
        isAppApi: true
      },
      {
        id: 4,
        name: '异常原因占比',
        type: 'charts',
        dataUrl: 'abnormal/private/abnormalStatistics/abnormalReasonRate',
        isAppApi: true,
        chartDataKey: 'quantity',
        chartType: 'pie',
        dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
        chartLegendKey: 'checkItemName',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            canNoSelect: true
          }
        ]
      },
      {
        id: 5,
        name: '响应分析（平均数）',
        type: 'charts',
        dataUrl: 'abnormal/private/abnormalStatistics/responseAnalysis',
        isAppApi: true,
        chartDataKey: 'responseAnalyze',
        chartType: 'bar',
        dataCondition: {beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            canNoSelect: true
          }
        ]
      }
    ]
  },
  {
    name: '工时统计',
    key: 'workHoursStatistic',
    children: [
      {
        id: 1,
        name: '事业部工时占比',
        type: 'charts',
        dataUrl: 'production/private/workHoursStatistics/businessUnitWorkHoursRate',
        chartDataKey: 'BUWorkRate',
        chartType: 'pie',
        dataCondition: {userId: ''},
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            canNoSelect: true
          },
          {
            type: 'select',
            key: 'organizationId',
            dataUrl: 'authority/private/Organization/getSecondLevelList',
            asKey: 'organizationList',
            dataKey: 'organizationList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '请选择业务部门'
          },
          {
            type: 'select',
            key: 'userId',
            dataUrl: 'production/private/productionTask/getUserList',
            dataKey: 'userList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '请选择用户'
          }
        ]
      },
      {
        id: 2,
        name: '工时明细',
        type: 'table',
        isExport:true,
        exportUrl:'production/private/workHoursStatistics/workHoursDetailExport',
        dataUrl: 'production/private/workHoursStatistics/workHoursDetail',
        page: true,
        method: 'post',
        dataCondition: {userId:''},
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            canNoSelect: true
          },
          {
            type: 'select',
            key: 'organizationId',
            dataUrl: 'authority/private/Organization/getSecondLevelList',
            asKey: 'organizationList',
            dataKey: 'organizationList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '请选择业务部门'
          },
          {
            type: 'select',
            key: 'userId',
            dataUrl: 'production/private/productionTask/getUserList',
            dataKey: 'userList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '请选择用户'
          }
        ],
        columns: [
          {
            prop: 'userJobNumber',
            label: '工号',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'userName',
            label: '姓名',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'organizationName',
            label: '组织',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'productionWorkHours',
            label: '生产/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'reworkWorkHours',
            label: '返工/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'fivesWorkHours',
            label: '5s/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'meetingWorkHours',
            label: '会议/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'trainWorkHours',
            label: '培训/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'entryWorkHours',
            label: '入职/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'afterSaleWorkHours',
            label: '售后/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'remouldWorkHours',
            label: '改造/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'labWorkHours',
            label: '实验室/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'otherWorkHours',
            label: '其他/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'totalWorkHours',
            label: '总计/h',
            sortable: 'custom',
            hiddenSearch: true
          }
        ]
      },
      {
        id: 3,
        name: '工时构成',
        type: 'table',
        dataUrl: 'production/private/workHoursStatistics/workHoursConstitute',
        page: true,
        method: 'post',
        dataCondition: {userId:''},
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            canNoSelect: true
          },
          {
            type: 'select',
            key: 'organizationId',
            dataUrl: 'authority/private/Organization/getSecondLevelList',
            asKey: 'organizationList',
            dataKey: 'organizationList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '请选择业务部门'
          },
          {
            type: 'select',
            key: 'userId',
            dataUrl: 'production/private/productionTask/getUserList',
            dataKey: 'userList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '请选择用户'
          }
        ],
        columns: [
          {
            prop: 'userJobNumber',
            label: '工号',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'userName',
            label: '姓名',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'organizationName',
            label: '组织',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'outputWorkHours',
            label: '产出工时/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'outputRate',
            label: '产出比',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'assistWorkHours',
            label: '辅助工时/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'assistRate',
            label: '辅助比',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'wasteWorkHours',
            label: '浪费工时/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'wasteRate',
            label: '浪费比',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'otherWorkHours',
            label: '其他工时/h',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'otherRate',
            label: '其他比',
            sortable: 'custom',
            hiddenSearch: true
          },
          {
            prop: 'totalWorkHours',
            label: '总计/h',
            sortable: 'custom',
            hiddenSearch: true
          }
        ]
      },
      {
        id: 4,
        name: '工时环比（近12月）',
        type: 'charts',
        dataUrl: 'production/private/workHoursStatistics/workHoursLinkRate',
        chartDataKey: 'workHoursRate',
        chartType: 'bar',
        dataCondition: {userId:''},
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            canNoSelect: true
          },
          {
            type: 'select',
            key: 'organizationId',
            dataUrl: 'authority/private/Organization/getSecondLevelList',
            asKey: 'organizationList',
            dataKey: 'organizationList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '请选择业务部门'
          },
          {
            type: 'select',
            key: 'userId',
            dataUrl: 'production/private/productionTask/getUserList',
            dataKey: 'userList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '请选择用户'
          }
        ]
      },
      {
        id: 5,
        name: '个人工时统计',
        type: 'charts',
        dataUrl: 'task/private/procedureTask/getUserWorkRecord',
        chartDataKey: 'personalWork',
        chartType: 'bar',
        dataCondition: {userId:'', beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            canNoSelect: true
          },
          {
            type: 'select',
            key: 'organizationId',
            dataUrl: 'authority/private/Organization/getSecondLevelList',
            asKey: 'organizationList',
            dataKey: 'organizationList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '请选择业务部门'
          },
          {
            type: 'select',
            key: 'userId',
            dataUrl: 'production/private/productionTask/getUserList',
            dataKey: 'userList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '请选择用户'
          }
        ]
      }
    ]
  },
  {
    name: '异常统计',
    key: 'AbnormalStatistics',
    children: [
      {
        id: 1,
        name: '数量统计',
        type: 'charts',
        dataUrl: 'displayBoard/private/displayBoard/quantityByStatusPieChart',
        chartDataKey: 'quantity',
        chartType: 'pie',
        dataCondition: { },
        chartLegendKey: 'checkItemName',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            canNoSelect: true
          },
          {
            type: 'select',
            key: 'calltypeIdList',
            dataUrl: 'notification/private/callType/getPullDownList',
            dataKey: 'pullDownList',
            props: {
              multiple: true
            },
            placeholder: '请选择异常类型'
          }
        ]
      },
      {
        id: 2,
        name: '当前分布',
        type: 'charts',
        dataUrl: 'displayBoard/private/displayBoard/quantityByStatusList',
        chartDataKey: 'distribution',
        chartType: 'bar'
      },
      {
        id: 3,
        name: '异常原因占比',
        type: 'charts',
        dataUrl: 'displayBoard/private/displayBoard/quantityByTypePieChart',
        chartDataKey: 'quantity',
        chartType: 'pie',
        dataCondition: { },
        chartLegendKey: 'checkItemName',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            canNoSelect: true
          }
        ]
      },
      {
        id: 5,
        name: '责任部门占比',
        type: 'charts',
        dataUrl: 'displayBoard/private/displayBoard/quantityByDeptIdPieChart',
        chartDataKey: 'quantity',
        chartType: 'pie',
        dataCondition: { beginTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(0, 'YYYY-MM-DD HH:mm:ss')[1] },
        chartLegendKey: 'checkItemName',
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD HH:mm:ss',
            canNoSelect: true
          }
        ]
      },
      {
        id: 4,
        name: '月度异常次数统计（近一年）',
        type: 'charts',
        dataUrl: 'displayBoard/private/displayBoard/quantityByMonthList',
        chartDataKey: 'monthlyStatistics',
        chartType: 'bar',
        dataCondition: { beginTime: getDateRange(4, 'YYYY-MM-DD HH:mm:ss')[0], endTime: getDateRange(4, 'YYYY-MM-DD HH:mm:ss')[1] },
        searches: [
          {
            type: 'select',
            key: 'calltypeIdList',
            dataUrl: 'notification/private/callType/getPullDownList',
            dataKey: 'pullDownList',
            props: {
              multiple: true
            },
            placeholder: '请选择异常类型'
          }
        ]
      },
    ]
  },
]
