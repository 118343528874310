<template>
  <div class="switch-shift-wrapper">
    <!-- <div class="form-build-wrapper" style="margin-bottom: 20px;">
      <el-checkbox v-model="radio">设备模块只显示以下设备相关任务</el-checkbox>
    </div> -->
    <el-button type="primary" @click="submitForm">选择设备</el-button>
    <MTable ref="mUnitTable" :height="320" :show-page="false" :data="list" :columns="columnsPlan" :columns-setting="false" style="margin-top: 20px;">
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <div slot="action" slot-scope="{ row, $index}">
        <el-button type="text" size="small" @click="delEquipment(row.id)">
          删除
        </el-button>
      </div>
    </MTable>
    <!-- 关联设备 -->
    <select-setting
      ref="selectSetting"
      :visible.sync="visible"
      :select-materials="selectEquipment"
      @submitForm="submitSetting"
    />
  </div>
</template>

<script>
import user from '@/api/user'
import api from '@/api/equipment/equipment'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import SelectSetting from '@/components/SelectSetting/SelectSetting'

export default {
  name: 'MineEquipment',
  components: { SelectSetting },
  data() {
    return {
      visible: false,
      selectEquipment: [],
      list: [],
      data: [],
      radio: false,
      columnsPlan: [{
        prop: 'index',
        label: '序号',
        slotName: 'index',
        hiddenSearch: true,
        sortable: false,
        width: 80
      },
      {
        prop: 'code',
        label: '设备编码',
        hiddenSearch: true,
        sortable: false,
        width: 150
      },
      {
        prop: 'name',
        label: '设备名称',
        hiddenSearch: true,
        sortable: false
      },
      {
        slotName: 'action',
        label: '操作',
        fixed: 'right',
        sortable: false,
        hiddenSearch: true,
        width: 150
      }],
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      }
    }
  },
  computed: {
    userDetail() {
      return this.$store.state.user.userDetail
    }
  },
  watch: {
    radio(val) {
      this.selectStatus()
    }
  },
  mounted() {
    this.getEquipment()
    this.getEquipmentList()
  },
  methods: {
    async delEquipment(id) {
      const res = await user.delEquipment(id)
      if (res) {
        this.getEquipment()
      }
    },
    async submitSetting(data) {
      const list = data.map(item => item.id)
      const res = await user.updateMyEquipmentList(list)
      if (res) {
        this.visible = false
        this.getEquipment()
      }
    },
    filterMethod(query, item) {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
    },
    // async getStatus() {
    //   const res = await user.getEquipmentStatus(this.radio)
    //   if (res) {
    //     this.radio = +res === 1 ? true : false
    //   }
    // },
    async selectStatus() {
      const res = await user.modifyEquipment(this.radio ? 1 : 0)
      if (res) {
        // this.getStatus()
      }
    },
    async getEquipment() {
      const res = await user.getEquipment()
      if (res) {
        this.list = res
      }
    },
    async getEquipmentList() {
      const res = await api.getEquipmentList(this.defaultSearch)
      if (res) {
        this.data = res
      }
    },
    async submitForm() {
      this.selectEquipment = await this.list
      this.visible = true
    }
  }
}
</script>

<style scoped lang="scss">
.switch-shift-wrapper {
  .form-build-wrapper {
    width: 335px;
  }

  .save-button {
    background: #607FFF;
    float: right;
    width: 88px;
  }
}
</style>
