var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "work-order-progress-container" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", [
        _c("div", { staticClass: "card-top card-top1" }, [_vm._v(" 待备料 ")]),
        _c(
          "div",
          { staticClass: "card-bottom card-bottom1" },
          [
            _c("dv-digital-flop", {
              staticStyle: {
                "font-size": "50px",
                "margin-top": "20px",
                height: "80px"
              },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.waitQuality, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        )
      ]),
      _c("div", [
        _c("div", { staticClass: "card-top card-top2" }, [_vm._v(" 备料中 ")]),
        _c(
          "div",
          { staticClass: "card-bottom card-bottom2" },
          [
            _c("dv-digital-flop", {
              staticStyle: {
                "font-size": "50px",
                "margin-top": "20px",
                height: "80px"
              },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.ingQuality, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        )
      ]),
      _c("div", [
        _c("div", { staticClass: "card-top card-top3" }, [
          _vm._v(" 今日完成 ")
        ]),
        _c(
          "div",
          { staticClass: "card-bottom card-bottom3" },
          [
            _c("dv-digital-flop", {
              staticStyle: {
                "font-size": "50px",
                "margin-top": "20px",
                height: "80px"
              },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.haveQuality, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "el-popover",
          {
            ref: "popover",
            attrs: { width: 207, offset: 20 },
            on: { show: _vm.popoverShowEvent },
            model: {
              value: _vm.showPopover,
              callback: function($$v) {
                _vm.showPopover = $$v
              },
              expression: "showPopover"
            }
          },
          [
            _c(
              "div",
              { staticClass: "popover-content-top" },
              [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取消")
                ]),
                _c("el-button", { on: { click: _vm.saveColumns } }, [
                  _vm._v("确定")
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "popover-content-center" },
              [
                _c(
                  "el-checkbox",
                  {
                    on: { change: _vm.selectAllColumns },
                    model: {
                      value: _vm.allChecked,
                      callback: function($$v) {
                        _vm.allChecked = $$v
                      },
                      expression: "allChecked"
                    }
                  },
                  [_vm._v("全选")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "invert",
                    attrs: { type: "text" },
                    on: { click: _vm.invertSelectColumns }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/table_filter_images/fanxuan@2x.png"),
                        alt: "反选",
                        width: "13px"
                      }
                    }),
                    _c("span", [_vm._v("反选")])
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "popover-content-footer",
                style: { maxHeight: _vm.popoverContentMaxHeight + "px" }
              },
              [
                _c(
                  "el-checkbox-group",
                  {
                    staticClass: "check-wrapper",
                    model: {
                      value: _vm.checkedList,
                      callback: function($$v) {
                        _vm.checkedList = $$v
                      },
                      expression: "checkedList"
                    }
                  },
                  [
                    _c(
                      "draggable",
                      _vm._b(
                        {
                          on: {
                            start: function($event) {
                              _vm.isDragging = true
                            },
                            end: function($event) {
                              _vm.isDragging = false
                            }
                          },
                          model: {
                            value: _vm.settingColumns,
                            callback: function($$v) {
                              _vm.settingColumns = $$v
                            },
                            expression: "settingColumns"
                          }
                        },
                        "draggable",
                        _vm.dragOptions,
                        false
                      ),
                      _vm._l(_vm.settingColumns, function(item) {
                        return _c(
                          "el-checkbox",
                          { key: item.key, attrs: { label: item.prop } },
                          [_vm._v(_vm._s(item.label))]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "table-header" }, [
          _c("img", {
            staticClass: "img",
            attrs: { src: require("@/assets/screen/filter.png") }
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "popover",
                  rawName: "v-popover:popover",
                  arg: "popover"
                }
              ],
              ref: "columnsSettingBtn"
            },
            [_vm._v("表头字段筛选")]
          )
        ]),
        _c("scroll-board", {
          style: { width: "100%", height: _vm.tableHeight + "px" },
          attrs: { config: _vm.scrollBoardConfig },
          scopedSlots: _vm._u([
            {
              key: "materialPreparationStatus",
              fn: function(ref) {
                var index = ref.index
                return _c(
                  "div",
                  {
                    staticClass: "status-board",
                    style: {
                      background:
                        _vm.productionStatus[
                          _vm.tableData[index].materialPreparationStatus
                        ].color
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.productionStatus[
                          _vm.tableData[index].materialPreparationStatus
                        ].name
                      )
                    )
                  ]
                )
              }
            },
            {
              key: "process",
              fn: function(ref) {
                var index = ref.index
                return _c(
                  "div",
                  {},
                  [
                    _c("el-progress", {
                      staticStyle: { width: "120px" },
                      attrs: {
                        "stroke-width": 10,
                        percentage:
                          _vm.fixNumber(_vm.tableData[index]) >= 100
                            ? 100
                            : _vm.fixNumber(_vm.tableData[index]),
                        format: _vm.fixNumber(_vm.tableData[index], 1)
                      }
                    })
                  ],
                  1
                )
              }
            },
            {
              key: "producePlanPriorityId",
              fn: function(ref) {
                var index = ref.index
                return _c(
                  "div",
                  {
                    staticClass: "status-board",
                    style: {
                      background:
                        _vm.priorityStatus[
                          _vm.tableData[index].producePlanPriorityId
                        ].color
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.priorityStatus[
                          _vm.tableData[index].producePlanPriorityId
                        ].name
                      )
                    )
                  ]
                )
              }
            },
            {
              key: "quantityAvailable",
              fn: function(ref) {
                var index = ref.index
                return _c("div", {}, [
                  _vm._v(_vm._s(_vm.tableData[index].quantityAvailable))
                ])
              }
            },
            {
              key: "quantityPrepared",
              fn: function(ref) {
                var index = ref.index
                return _c("div", {}, [
                  _vm._v(_vm._s(_vm.tableData[index].quantityPrepared))
                ])
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }