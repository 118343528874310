var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shift-wrap" },
    [
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var row = ref.row
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "createMode",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _c("div", [
                  _vm._v(
                    _vm._s(+row.createMode === 0 ? "系统生成" : "手动输入")
                  )
                ])
              ])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.modifyOrCopys(row, "modify")
                        }
                      }
                    },
                    [_vm._v("修改")]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "MDialog",
        {
          attrs: { title: "修改", width: "700px" },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: {
                "form-data": _vm.formData,
                "form-list": _vm.formList,
                "label-position": "right"
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "numberingRule" }, slot: "numberingRule" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "drag-btns",
                      attrs: {
                        type: "primary",
                        size: "small",
                        disabled: _vm.numberingRuleList.length === 20
                      },
                      on: { click: _vm.addName }
                    },
                    [_vm._v("添加")]
                  ),
                  _vm.formData.numberingRuleList.length > 0
                    ? _c(
                        "draggable",
                        _vm._b(
                          {
                            staticClass: "plan-drag-container",
                            attrs: { handle: ".forbid", move: _vm.onMove },
                            on: {
                              start: function($event) {
                                _vm.isDragging = true
                              },
                              end: _vm.dragEnd
                            },
                            model: {
                              value: _vm.formData.numberingRuleList,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "numberingRuleList", $$v)
                              },
                              expression: "formData.numberingRuleList"
                            }
                          },
                          "draggable",
                          _vm.dragOptions,
                          false
                        ),
                        _vm._l(_vm.formData.numberingRuleList, function(
                          dragTag,
                          index
                        ) {
                          return _c("div", { key: index }, [
                            _c(
                              "div",
                              {
                                class:
                                  dragTag.fieldSetName === "流水号"
                                    ? ""
                                    : "forbid"
                              },
                              [
                                dragTag.fieldSetName !== "流水号"
                                  ? _c("i", { staticClass: "el-icon-rank" })
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "flex-start",
                                  width: "400px"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      placeholder: "请选择",
                                      disabled:
                                        dragTag.fieldSetName === "流水号"
                                    },
                                    model: {
                                      value: dragTag.fieldSetName,
                                      callback: function($$v) {
                                        _vm.$set(dragTag, "fieldSetName", $$v)
                                      },
                                      expression: "dragTag.fieldSetName"
                                    }
                                  },
                                  _vm._l(_vm.fieldList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.filedName,
                                        value: item.filedName
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.selectItem(item, index)
                                        }
                                      }
                                    })
                                  }),
                                  1
                                ),
                                dragTag.fieldSet.filedType === 0
                                  ? _c("el-input", {
                                      ref: "saveTagInput",
                                      refInFor: true,
                                      staticClass: "input-new-tag",
                                      staticStyle: {
                                        margin: "0 10px",
                                        width: "120px"
                                      },
                                      attrs: {
                                        placeholder:
                                          "输入" +
                                          (dragTag.fieldSetName === "流水号"
                                            ? "3位以上起始数值"
                                            : dragTag.fieldSetName),
                                        size: "small"
                                      },
                                      model: {
                                        value: dragTag.value,
                                        callback: function($$v) {
                                          _vm.$set(dragTag, "value", $$v)
                                        },
                                        expression: "dragTag.value"
                                      }
                                    })
                                  : _vm._e(),
                                dragTag.fieldSet.filedType === 2
                                  ? _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          margin: "0 10px",
                                          width: "120px"
                                        },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: dragTag.value,
                                          callback: function($$v) {
                                            _vm.$set(dragTag, "value", $$v)
                                          },
                                          expression: "dragTag.value"
                                        }
                                      },
                                      _vm._l(
                                        _vm.fixData(
                                          dragTag.fieldSet.allFiledProperty
                                        ),
                                        function(items, idx) {
                                          return _c("el-option", {
                                            key: idx,
                                            attrs: {
                                              label: items,
                                              value: items
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            dragTag.fieldSetName !== "流水号"
                              ? _c("div", { staticClass: "del" }, [
                                  _c("i", {
                                    staticClass: "el-icon-delete icons",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteTag(index)
                                      }
                                    }
                                  })
                                ])
                              : _vm._e()
                          ])
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }