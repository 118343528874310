var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mv-count-down" }, [
    _vm.msTime.show
      ? _c("p", [
          _vm.msTime.day > 0
            ? _c("span", [
                _c("span", [_vm._v(_vm._s(_vm.msTime.day))]),
                _c("i", [_vm._v(_vm._s(_vm.dayTxt))])
              ])
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.msTime.hour))]),
          _c("i", [_vm._v(_vm._s(_vm.hourTxt))]),
          _c("span", [_vm._v(_vm._s(_vm.msTime.minutes))]),
          _c("i", [_vm._v(_vm._s(_vm.minutesTxt))]),
          _c("span", [_vm._v(_vm._s(_vm.msTime.seconds))]),
          _c("i", [_vm._v(_vm._s(_vm.secondsTxt))])
        ])
      : _vm._e(),
    !_vm.msTime.show ? _c("p", [_vm._v(_vm._s(_vm.endText))]) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }