/** write by luomingzhi */
import { status } from '@/config/options.config'
import { formatColumns } from '@/utils'

export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'reason',
    label: '报废原因',
    sortable: false,
    hiddenSearch: true,
    form: { index: 1, tagName: 'el-input' }
  },
  {
    prop: 'isEnabled',
    label: '原因状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled',
    filters: formatColumns(status),
    form: {
      index: 4,
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { index: 5,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]
