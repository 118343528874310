<template>
  <div class="materials-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button label="FieldSet">设备台账</el-radio-button>
      <!--<el-radio-button label="UserGroup">状态参数</el-radio-button>-->
    </el-radio-group>
    <FunctionHeader
      v-model="functionHeader.searchVal"
      :tags="functionHeader.tags"
      :hidden-search="true"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" :columns-setting="false">
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <div slot="type" slot-scope="{ row }">
        {{ row.type === 0 ? '自定义' : '系统预置' }}
      </div>
      <div slot="filedType" slot-scope="{ row }">
        {{ paramsType[row.filedType].name }}
      </div>
      <div slot="search" slot-scope="{ row }">
        {{ row.search === 1 ? '开启' : '关闭' }}
      </div>
      <div slot="required" slot-scope="{ row }">
        {{ row.required === 1 ? '是' : '否' }}
      </div>
      <div slot="isEnabled" slot-scope="{ row }">
        {{ row.isEnabled === 1 ? '启用' : '停用' }}
      </div>
      <div slot="defaultValue" slot-scope="{ row }">
        {{ row.defaultValue || '-' }}
      </div>
      <div slot="filedProperty" slot-scope="{ row }">
        {{ row.filedProperty || '-' }}
      </div>
      <!-- <div slot="filedShowName" slot-scope="{ row }">
        {{row.filedShowName ||  '-'}}
      </div> -->
      <div slot="action" slot-scope="{ row }">
        <el-button type="text" @click="modifyOrCopy(row, 'copy')">修改</el-button>
        <el-divider v-if="row.type === 0" direction="vertical" />
        <el-button v-if="row.type === 0" type="text" @click="dels(row)">删除</el-button>
      </div>
    </MTable>
    <MDialog v-model="visible" :title="messages[currentType]" width="550px" @onOk="submitForms">
      <MFormBuilder ref="formBuild" label-position="right" label-width="100px" :form-data="formData" :form-list="formList" :custom-rules="rules">
        <div slot="filedProperty">
          <draggable
            v-if="planTags.length > 0"
            v-model="planTags"
            v-bind="dragOptions"
            class="plan-drag-container"
            @start="isDragging = true"
            @end="dragEnd"
          >
            <div v-for="(dragTag, index) in planTags" :key="index">
              <div><i class="el-icon-rank" /></div>
              <div>{{ dragTag.name }}</div>
              <div class="del"><i class="el-icon-delete icons" @click="deleteTag(index)" /></div>
            </div>
          </draggable>
          <el-button type="primary" size="small" class="drag-btns" :disabled="planTags.length === 20" @click="addName">添加选项</el-button>
        </div>
        <div slot="numberRange">
          <el-input-number v-model="numberMin" :max="numberMax === 0 ? undefined : numberMax" controls-position="right" style="width:43%" clearable />
          <span style="margin: 0 10px;">-</span>
          <el-input-number v-model="numberMax" :min="numberMin" controls-position="right" style="width:43%" clearable />
        </div>
      </MFormBuilder>
    </MDialog>
    <MDialog v-model="nameVisible" title="添加选项" width="500px" @onOk="submitFormName">
      <MFormBuilder ref="formBuilds" label-position="right" :form-data="formDataName" :form-list="formListName" :custom-rules="nameRules" />
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import { User, getFunctionList } from './columns'
import { formList, formListName } from './form-list'
import api from '@/api/xiewei/field/field'
import conventionMixin from '@/mixin/conventional-page'
import { Encrypt } from '@/utils/sercet'
import TableDialog from '@/components/TableDialog/TableDialog'
import DelPopover from '@/components/DelPopover/index'
import draggable from 'vuedraggable'
import { paramsType } from '@/config/options.sales'
import { getUUid } from '@/utils'

export default {
  name: 'FieldSetting',
  components: { TableDialog, FunctionHeader, DelPopover, draggable },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      paramsType,
      params: {
        radio: 'FieldSet'
      },
      searchKeyword: true,
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: false,
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ]
      },
      columns: User,
      viewTableColumns: [],
      viewTableData: [],
      visible: false,
      formList: formList,
      formData: {},
      selectStationList: [],
      currentType: 'add',
      getTableDataParams: {},
      extraParams: [{
        id: getUUid(),
        fieldName: 'isDeleted',
        fieldType: 'number',
        fieldValue: 0,
        operator: 'eq'
      },
      {
        id: getUUid(),
        fieldName: 'tableSign',
        fieldType: 'string',
        fieldValue: 'facility',
        operator: 'eq'
      }],
      userVisible: false,
      selectUserId: undefined,
      isSystems: 0,
      rules: {
      },
      nameRules: {},
      planTags: [],
      isDragging: false,
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      nameVisible: false,
      formDataName: {},
      formListName: formListName,
      numberMax: 0,
      numberMin: 0,
      currentTypes: 0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 10
    }
  },
  beforeMount() {
    getFunctionList({
      paramsTypeChange: this.paramsTypeChange
    })
  },
  mounted() {},
  methods: {
    paramsTypeChange(val) {
      this.currentTypes = val
      this.planTags = []
      // 检验条目类型 0:文本 1:数值 2:单选 3:多选
      this.formList[0].props.disabled = false
      this.formList[1].props.disabled = false
      if (this.$refs.formBuild) {
        this.$refs.formBuild.formRefs().clearValidate()
        this.$refs.formBuild.setForm({
          defaultValue: '',
          filedProperty: ''
        })
        this.numberMax = 0
        this.numberMin = 0
      }
      if (+val === 1) {
        this.formList[3].hidden = false
      } else {
        this.formList[3].hidden = true
      }
      this.changeType(val)
    },
    changeType(val) {
      if (val === 0 || val === 3) {
        this.formList[4].hidden = true
      } else {
        this.formList[4].hidden = false
      }
      if (+val === 0 || +val === 1) {
        this.formList[2].hidden = true
        this.formList.splice(4, 1, {
          label: '默认值',
          key: 'defaultValue',
          hidden: val === 0,
          tagName: +val === 1 ? 'el-input-number' : 'el-input',
          props: +val === 1 ? { controlsPosition: 'right' } : {},
          children: []
        })
      }
      if (val === 2) {
        this.formList[2].hidden = false
        this.formList.splice(4, 1, {
          label: '默认值',
          key: 'defaultValue',
          tagName: 'el-select',
          props: { multiple: val === 3 },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'name'
              },
              options: []
            }
          ]
        })
      }
      if (val === 3) {
        this.formList[2].hidden = false
      }
    },
    async dragEnd() {
      this.isDragging = false
      const arr = this.planTags.length > 0 ? this.planTags.map(item => item.name) : []
      await this.$refs.formBuild.setForm({
        filedProperty: arr.join(',')
      })
    },
    async deleteTag(idx) {
      this.planTags = this.planTags.filter((item, index) => index !== idx)
      if (this.currentTypes === 2) {
        this.formList[4].children[0].options = this.planTags
      }
      const arr = this.planTags.length > 0 ? this.planTags.map(item => item.name) : []
      await this.$refs.formBuild.setForm({
        filedProperty: arr.join(','),
        defaultValue: ''
      })
    },
    dels(node) {
      this.$confirm('请注意！删除后系统中所有该字段数据都会被删除，是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delFieldSet({ id: node.id })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData(true)
        }
      })
    },
    async submitFormName(callback) {
      const { form } = this.$refs.formBuilds
      this.$refs.formBuilds.formRefs().validate(async(valid) => {
        if (valid) {
          const data = this.planTags.length > 0 ? this.planTags.map(item => item.name) : ''
          if (data && data.includes(form.fieldName)) {
            callback()
            return this.$message.error('选项名称已存在')
          }
          this.planTags.push({
            name: form.fieldName
          })
          if (this.currentTypes === 2) {
            this.formList[4].children[0].options = this.planTags
          }
          const arr = this.planTags.length > 0 ? this.planTags.map(item => item.name) : []
          await this.$refs.formBuild.setForm({
            filedProperty: arr.join(',')
          })
          this.nameVisible = false
          callback(true)
        } else {
          callback()
        }
      })
    },
    radioChange(label) {
      this.params.radio = label
      if (this.params.radio === 'FieldSet') {
        this.$refs.mTable.setTableData(true)
      }
    },
    async add() {
      this.formData = {}
      this.planTags = []
      this.paramsTypeChange(0)
      this.currentType = 'add'
      this.visible = true
    },
    async addName() {
      this.formDataName = {}
      this.nameVisible = true
    },
    async modifyOrCopy(row) {
      this.formData = this._.cloneDeep(row)
      this.changeType(this.formData.filedType)
      // 系统不可修改
      if (this.formData.type === 1) {
        this.formList[0].props.disabled = true
        this.formData.defaultValue = +row.defaultValue
      } else {
        this.formList[0].props.disabled = false
      }
      // 数值
      if (this.formData.filedType === 1) {
        const arr = this.formData.filedProperty ? this.formData.filedProperty.split(',') : []
        if (arr.length === 2) {
          this.numberMax = +arr[1].split(':')[1]
          this.numberMin = +arr[0].split(':')[1]
        } else {
          const num = arr.length === 1 ? arr[0].indexOf('最小值') > -1 : false
          const num1 = arr.length === 1 ? arr[0].indexOf('最大值') > -1 : false
          if (num) {
            this.numberMin = +arr[0].split(':')[1]
            this.numberMax = undefined
          }
          if (num1) {
            this.numberMax = +arr[0].split(':')[1]
            this.numberMin = undefined
          }
        }
        this.formList[3].hidden = false
      } else {
        this.formList[3].hidden = true
      }

      if (this.formData.filedType === 2 || this.formData.filedType === 3) {
        this.planTags = row.filedProperty ? row.filedProperty.split(',').map(item => {
          return {
            name: item
          }
        }) : []
      }
      if (this.formData.filedType === 2) {
        this.formList[4].children[0].options = this.planTags
        this.formData.defaultValue = row.defaultValue ? row.defaultValue : ''
      }
      this.formList[1].props.disabled = true
      this.currentType = 'modify'
      this.visible = true
    },
    submitForms(callback) {
      const that = this
      const { form } = this.$refs.formBuild
      if (this.params.radio === 'FieldSet' && form.filedType === 1 &&
        (form.defaultValue < this.numberMin || form.defaultValue > this.numberMax)) {
        callback()
        return this.$message.error('数值不合理')
      }
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          if (this.currentType !== 'modify') {
            that.save(form)
          } else {
            this.$confirm('请注意！修改后将影响系统数据，是否确定修改？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async() => {
              that.save(form)
            })
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    async save(form) {
      const res = await this.api[`${this.currentType}${this.params.radio}`](
        this.currentType !== 'modify' ? this.createSetData(form) : this.createResetData(form)
      )
      if (res) {
        this.$message.success(this.getSuccessMessage())
        this.visible = false
        this.$refs.mTable.setTableData()
      }
    },
    createSetData(raw) {
      const count = (this.numberMin || this.numberMin === 0) && (this.numberMax || this.numberMax === 0)
        ? `最小值:${this.numberMin},最大值:${this.numberMax}`
        : this.numberMin || this.numberMin === 0 ? `最小值:${this.numberMin}` : this.numberMax || this.numberMax === 0 ? `最大值:${this.numberMax}` : ''
      const setData = {
        FieldSet: {
          type: 0,
          tableSign: 'facility',
          filedName: raw.filedName,
          // filedShowName: raw.filedShowName,
          filedType: raw.filedType,
          defaultValue: raw.filedType === 3 && raw.defaultValue ? raw.defaultValue.join(',') : raw.defaultValue,
          required: raw.required,
          search: raw.search,
          isEnabled: raw.isEnabled,
          filedProperty: raw.filedType === 1 ? count
            : raw.filedType === 2 || raw.filedType === 3 ? raw.filedProperty : ''
        }
      }
      return setData[this.params.radio]
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.materials-wrap {
  padding: 10px;
  ::v-deep {
    .el-form-item__label {
      padding: 0 12px 0 0;
    }
  }
}
.drag-btns {
  display: block;
}
.role-tags {
  margin: 5px;
  border-color: #DDE3EB;
  color: #9597AE;
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
::v-deep {
  .el-form-item__content {
    width: 300px;
  }
  .el-form-item {
    margin-bottom: 22px !important;
  }
  .el-switch__label {
    color: #606266;
  }
  .el-switch__label.is-active {
    color: #607FFF !important;
  }
}
.plan-drag-container {
  display: inline-block;
  border: 1px solid #DDE3EB;
  margin-bottom: 10px;
  > div {
    padding: 10px 20px 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:first-child {
      min-width: 30px;
    }
    .del {
      margin-left: 20px;
    }
    i {
      font-size: 20px;
    }
    .icons {
      font-size: 18px;
    }
    &:hover {
      background: #607FFF10;

      i {
        color: #607FFF;
      }
    }
  }
}
</style>
