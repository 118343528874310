var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrap-wrap" },
    [
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "FunctionHeader",
            {
              ref: "functionHeader",
              attrs: {
                "search-title": "请输入工单编号/需求编号",
                tags: !_vm.exportSelectionIds.length
                  ? _vm.functionHeader.tags
                  : [],
                radio: _vm.params.radio,
                "export-name": _vm.functionHeader.exportName,
                "export-params": _vm.functionHeader.exportParams,
                "show-export": !_vm.exportSelectionIds.length ? true : false,
                "columns-key": _vm.columnsKey,
                "hidden-mul-search": true
              },
              on: {
                checkSearch: _vm.checkSearch,
                search: function($event) {
                  return _vm.$refs.mTable.setTableData(true)
                }
              },
              model: {
                value: _vm.functionHeader.searchVal,
                callback: function($$v) {
                  _vm.$set(_vm.functionHeader, "searchVal", $$v)
                },
                expression: "functionHeader.searchVal"
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.exportSelectionIds.length,
                      expression: "exportSelectionIds.length"
                    }
                  ],
                  attrs: { slot: "all" },
                  slot: "all"
                },
                [
                  _vm.tabParams === "SpotCheck"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.allMore("batchSkipping")
                            }
                          }
                        },
                        [_vm._v("批量跳过")]
                      )
                    : _vm._e(),
                  _vm.permission("Export")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.allMore("batchExport")
                            }
                          }
                        },
                        [_vm._v("批量导出")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.allMore("delBatchSpotCheck")
                        }
                      }
                    },
                    [_vm._v("批量删除")]
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "0 10px",
                        display: "inline-block",
                        "font-size": "12px",
                        color: "#393D60"
                      }
                    },
                    [
                      _vm._v(" 已选 "),
                      _c("span", { staticStyle: { color: "#607FFF" } }, [
                        _vm._v(_vm._s(_vm.getSelectionDataLength))
                      ]),
                      _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: { click: _vm.resetSelectData }
                    },
                    [_vm._v("清空")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SearchForm", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isShow,
                    expression: "!isShow"
                  }
                ],
                ref: "searchForm",
                attrs: {
                  "form-list-extend": _vm.getSearchFormList,
                  "form-data": _vm.searchFormData
                },
                on: { search: _vm.searchTable }
              })
            ],
            1
          ),
          _vm.permission("List")
            ? _c("MTable", {
                ref: "mTable",
                attrs: {
                  columns: _vm.getColumns,
                  height: _vm.height,
                  "columns-key": _vm.columnsKey,
                  "set-data-method": _vm.getTableData,
                  "columns-setting": _vm.isShow
                },
                on: { "selection-change": _vm.selectionChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "index",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "workOrderCode",
                      fn: function(ref) {
                        var row = ref.row
                        return _vm.isHistoryTab
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.toDetail(row, "view")
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(row.workOrderCode) + " ")]
                            )
                          : _c("div", {}, [_vm._v(_vm._s(row.workOrderCode))])
                      }
                    },
                    {
                      key: "demandCode",
                      fn: function(ref) {
                        var row = ref.row
                        return row.demandCode && row.isDeleted === 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.toViewDetail(row)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(row.demandCode) + " ")]
                            )
                          : _c("div", {}, [_vm._v(_vm._s(row.demandCode))])
                      }
                    },
                    {
                      key: "facilitySpotCheckPlanCode",
                      fn: function(ref) {
                        var row = ref.row
                        return row.isDeletePlan === 1
                          ? _c("div", {}, [
                              _vm._v(_vm._s(row.facilitySpotCheckPlanCode))
                            ])
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.toFacilityDetail(row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(row.facilitySpotCheckPlanCode) +
                                    " "
                                )
                              ]
                            )
                      }
                    },
                    {
                      key: "source",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(_vm.getSourceDesc(row) || "-"))
                        ])
                      }
                    },
                    {
                      key: "consumer",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(row.consumer || "-"))
                        ])
                      }
                    },
                    {
                      key: "planExecutionStartTime",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(
                            _vm._s(
                              _vm.getDayDesc(
                                row.planExecutionStartTime,
                                row.planExecutionEndTime
                              )
                            )
                          )
                        ])
                      }
                    },
                    {
                      key: "executionStartTime",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(
                            _vm._s(
                              _vm.getTimeDesc(
                                row.executionStartTime,
                                row.executionEndTime
                              )
                            )
                          )
                        ])
                      }
                    },
                    {
                      key: "createTime",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(_vm.getTimeDesc(row.createTime)))
                        ])
                      }
                    },
                    {
                      key: "action",
                      fn: function(ref) {
                        var row = ref.row
                        return !_vm.isHistoryTab
                          ? _c(
                              "div",
                              {},
                              [
                                _vm.permission("Print")
                                  ? _c(
                                      "el-button",
                                      { attrs: { type: "text" } },
                                      [_vm._v("打印工单")]
                                    )
                                  : _vm._e(),
                                _vm.permission("Print")
                                  ? _c("el-divider", {
                                      attrs: { direction: "vertical" }
                                    })
                                  : _vm._e(),
                                _vm.permission("Execu")
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: !row.executor
                                            .split("^")
                                            .includes(_vm.userId),
                                          type: "text"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.toDetail(row, "add")
                                          }
                                        }
                                      },
                                      [_vm._v(" 执行 ")]
                                    )
                                  : _vm._e(),
                                _vm.permission("Execu")
                                  ? _c("el-divider", {
                                      attrs: { direction: "vertical" }
                                    })
                                  : _vm._e(),
                                _vm.permission("Pass")
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.skipping(row)
                                          }
                                        }
                                      },
                                      [_vm._v("跳过")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm.permission("DelWorkOrder")
                          ? _c(
                              "div",
                              {},
                              [
                                [
                                  _vm.permission("Modify") &&
                                  _vm.fixUsers(
                                    row.updater,
                                    row.updatePermitUserId
                                  ) &&
                                  row.isUpdate === 1
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled: row.isDeletePlan === 1,
                                            type: "text"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.toModify(row, "modify")
                                            }
                                          }
                                        },
                                        [_vm._v("修改")]
                                      )
                                    : _vm._e(),
                                  _vm.permission("Modify") &&
                                  _vm.fixUsers(
                                    row.updater,
                                    row.updatePermitUserId
                                  ) &&
                                  row.isUpdate === 1
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.del(row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              ],
                              2
                            )
                          : row.isUpdate === 1
                          ? _c(
                              "div",
                              {},
                              [
                                _vm.permission("Modify") &&
                                _vm.fixUsers(
                                  row.updater,
                                  row.updatePermitUserId
                                )
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: row.isDeletePlan === 1,
                                          type: "text"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.toModify(row, "modify")
                                          }
                                        }
                                      },
                                      [_vm._v("修改")]
                                    )
                                  : _vm._e(),
                                _vm.permission("Modify") &&
                                _vm.fixUsers(
                                  row.updater,
                                  row.updatePermitUserId
                                )
                                  ? _c("el-divider", {
                                      attrs: { direction: "vertical" }
                                    })
                                  : _vm._e(),
                                _vm.permission("Del") &&
                                _vm.fixUsers(
                                  row.updater,
                                  row.updatePermitUserId
                                )
                                  ? _c("DelPopover", {
                                      on: {
                                        onOk: function(callback) {
                                          return _vm.del(row, callback)
                                        }
                                      }
                                    })
                                  : _c(
                                      "div",
                                      {
                                        attrs: { slot: "action" },
                                        slot: "action"
                                      },
                                      [_vm._v(_vm._s("-"))]
                                    )
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { attrs: { slot: "action" }, slot: "action" },
                              [_vm._v(_vm._s("-"))]
                            )
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c("PrintOrder", { ref: "printCodes" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }