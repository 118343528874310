<template>
  <div class="shift-wrap">
    <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <div slot="createMode" slot-scope="{ row }">
        <div>{{ +row.createMode === 0 ? '系统生成' : '手动输入' }}</div>
      </div>
      <div slot="action" slot-scope="{ row }">
        <el-button type="text" @click="modifyOrCopys(row, 'modify')">修改</el-button>
      </div>
    </MTable>

    <MDialog v-model="visible" title="修改" width="700px" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" label-position="right">
        <div slot="numberingRule">
          <el-button type="primary" size="small" class="drag-btns" :disabled="numberingRuleList.length === 20" @click="addName">添加</el-button>
          <draggable
            v-if="formData.numberingRuleList.length > 0"
            v-model="formData.numberingRuleList"
            v-bind="dragOptions"
            class="plan-drag-container"
            @start="isDragging = true"
            @end="dragEnd"
            handle=".forbid"
            :move="onMove"
          >
            <div v-for="(dragTag, index) in formData.numberingRuleList" :key="index">
              <div :class="dragTag.fieldSetName === '流水号'?'':'forbid'"><i v-if="dragTag.fieldSetName !== '流水号'" class="el-icon-rank" /></div>
              <div style="display:flex;justify-content: flex-start;width: 400px">
                <el-select v-model="dragTag.fieldSetName" placeholder="请选择" style="width:100px" :disabled="dragTag.fieldSetName === '流水号'">
                  <el-option
                    v-for="item in fieldList"
                    :key="item.id"
                    :label="item.filedName"
                    :value="item.filedName"
                    @click.native="selectItem(item, index)"
                  />
                </el-select>
                <el-input
                  v-if="dragTag.fieldSet.filedType === 0"
                  ref="saveTagInput"
                  v-model="dragTag.value"
                  class="input-new-tag"
                  :placeholder="`输入${dragTag.fieldSetName === '流水号' ? '3位以上起始数值': dragTag.fieldSetName}`"
                  size="small"
                  style="margin: 0 10px;width: 120px;"
                />
                <el-select v-if="dragTag.fieldSet.filedType === 2" v-model="dragTag.value" placeholder="请选择" style="margin: 0 10px;width: 120px;">
                  <el-option
                    v-for="(items, idx) in fixData(dragTag.fieldSet.allFiledProperty)"
                    :key="idx"
                    :label="items"
                    :value="items"
                  />
                </el-select>
                <!-- <el-input type="number" v-if="dragTag.fieldSetName === '流水号'" placeholder="重置周期" v-model="dragTag.NumberValue" style="width: 190px;padding-right: 0;" >
                  <el-select v-model="dragTag.NumberValueShow" slot="append" style="width: 70px" placeholder="">
                    <el-option label="天" value="天"></el-option>
                    <el-option label="周" value="周"></el-option>
                    <el-option label="月" value="月"></el-option>
                  </el-select>
                </el-input> -->
              </div>
              <div v-if="dragTag.fieldSetName !== '流水号'" class="del"><i class="el-icon-delete icons" @click="deleteTag(index)" /></div>
            </div>
          </draggable>
        </div>
      </MFormBuilder>
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/xiewei/code/code'
import columns from './columns'
import formList from './form-list'
import draggable from 'vuedraggable'

export default {
  name: 'CodeSetting',
  components: { draggable, FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'NumberSet'
      },
      functionHeader: {
        searchVal: ''
      },
      columns,
      visible: false,
      formList: formList,
      formData: {
        numberingRuleList: []
      },
      currentType: 'add',
      formOptions: {},
      fieldList: [],
      isDragging: false,
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      numberingRuleList: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    }
  },
  watch: {
    'formData.numberingRuleList': {
      handler(newVal) {
        const val = newVal.find(item => item.fieldSetName === '流水号')
        if (val) {
          this.fieldList = this.fieldList.filter(item => item.filedName !== '流水号')
        } else {
          this.fieldList = this.numberingRuleList
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.getFieldSetLists()
  },
  methods: {
    fixData(val) {
      return val ? val.split(',') : []
    },
    // 禁止拖动到id为1的对象
    onMove(e) {
      if (e.relatedContext.element.fieldSetName === '流水号') return false
      return true
    },
    async modifyOrCopys(row, command) {
      await this.getNumberSetByDetial(row.id)
      this.visible = true
    },
    selectItem(row, index) {
      this.formData.numberingRuleList[index].fieldSetId = row.id
      this.formData.numberingRuleList[index].fieldSet.filedType = row.filedType
      this.formData.numberingRuleList[index].fieldSet.allFiledProperty = row.allFiledProperty
    },
    addName() {
      const data = this.formData.numberingRuleList || []
      this.formData.numberingRuleList = [{
        fieldSetId: '',
        fieldSetName: '',
        value: '',
        defaultValue: '',
        fieldSet: {
          filedType: ''
        }
      }, ...data]
    },
    async dragEnd() {
      this.isDragging = false
      const arr = this.formData.numberingRuleList.length > 0 ? this.formData.numberingRuleList.map(item => item.filedName) : []
      // await this.$refs.formBuild.setForm({
      //   filedProperty: arr.join(',')
      // })
    },
    async deleteTag(idx) {
      this.formData.numberingRuleList = this.formData.numberingRuleList.filter((item, index) => index !== idx)
    },
    async getFieldSetLists() {
      const res = await api.getFieldSetList()
      if (res) {
        this.numberingRuleList = res
        this.fieldList = res
      }
    },
    async getNumberSetByDetial(id) {
      const res = await api.getNumberSetByDetial(id)
      if (res) {
        let data
        const list = res.numberingRuleList ? res.numberingRuleList.map(item => {
          // data = item.defaultValue ? item.defaultValue.split(',') : []
          return {
            ...item
            // NumberValue: data.length > 0 ? data[0] : '',
            // NumberValueShow: data.length > 1 ? data[1] : ''
          }
        }) : []

        this.formData = Object.assign({}, res, {
          numberingRuleList: list
        })
        this.formList[1].props.disabled = res.isCreateMode === 1
      }
    },
    createSetData(raw) {
      let data = []
      if (this.formData.numberingRuleList && this.formData.numberingRuleList.length > 0) {
        data = this.formData.numberingRuleList.filter(item => item.fieldSetName)
      }
      return {
        number: raw.number,
        createMode: raw.createMode,
        numberingRuleList: data
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    submitForm(callback) {
      const { form } = this.$refs.formBuild
      const isTrue = false
      const data = this.formData.numberingRuleList.map(item => {
        if (item.fieldSetName === '流水号' && item.value.length < 3) {
          return true
        }
      })
      if (isTrue) {
        this.$message.error('流水号小于3位')
        return false
      }
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await api.modifyNumberSet(this.createResetData(form))
          if (res) {
            this.$message.success('修改成功')
            this.visible = false
            this.$refs.mTable.setTableData()
          }
          callback(true)
        } else {
          callback()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.shift-wrap {
  padding: 10px;
  ::v-deep {
    .el-input-group--append .el-input__inner {
      padding-right: 0;
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
  .drag-btns {
    display: block;
    margin-bottom: 10px;
  }
  .plan-drag-container {
    display: inline-block;
    border: 1px solid #DDE3EB;
    margin-bottom: 10px;
    > div {
      padding: 10px 20px 10px 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > div:first-child {
        min-width: 30px;
      }
      .del {
        margin-left: 20px;
      }
      i {
        font-size: 20px;
      }
      .icons {
        font-size: 18px;
      }
      &:hover {
        background: #607FFF10;

        i {
          color: #607FFF;
        }
      }
    }
  }
}
</style>

