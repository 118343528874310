const columns = [
  {
    prop: 'code',
    label: '工序编码',
    sortable: false,
    hiddenSearch: false,
    width: 200
  },
  {
    prop: 'name',
    label: '工序名称',
    sortable: false,
    hiddenSearch: false
  },
  {
    prop: 'issueMode',
    label: '下发设置',
    sortable: false,
    hiddenSearch: true,
    slotName: 'issueMode'
  },
  {
    prop: 'assignMode',
    label: '指派设置',
    sortable: false,
    hiddenSearch: true,
    slotName: 'assignMode'
  },
  {
    prop: 'parallelMode',
    label: '并行设置',
    sortable: false,
    hiddenSearch: true,
    slotName: 'parallelMode'
  }
]

export default columns
