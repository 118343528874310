/** write by luomingzhi */
import { paramsType, status } from '@/config/options.config'
import { formatColumns } from '@/utils'

export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    width: 80,
    slotName: 'index'
  },
  {
    prop: 'code',
    label: '参数编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '参数名称',
    sortable: false,
    hiddenSearch: true,
    form: { index: 1, tagName: 'el-input' }
  },
  {
    prop: 'type',
    label: '参数类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'type',
    filters: formatColumns(paramsType),
    form: {
      index: 2,
      tagName: 'el-select',
      key: 'type',
      options: paramsType
    }
  },
  {
    prop: 'isEnabled',
    label: '参数状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled',
    filters: formatColumns(status),
    form: {
      index: 4,
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { index: 5,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]
