<template>
  <div class="system-wrapper">
    <template v-for="item in paramsList">
      <div v-if="item.isEnabled" :key="item.id" class="common-card">
        <div class="card-tit">
          <div class="card-le">{{ item.name }}</div>
        </div>
        <div class="card-bot">
          <template v-if="item.childrenList">
            <div v-for="child in item.childrenList" :key="child.id" class="item-wrapper">
              <div v-if="child.isEnabled">
                <div class="label">{{ child.name }}：</div>
                <el-radio-group v-model="child.selectedItemIdList[0]" @change="radioChange">
                  <el-radio v-for="radio in child.selectItemList" :key="radio.id" :label="radio.id">{{ radio.name }}
                  </el-radio>
                </el-radio-group>
              </div>
            </div>
          </template>
          <div v-if="item.code === 'preparation_config'">
            <div class="item-wrapper">
              <div class="label">工单下发自动创建备料任务：</div>
              <el-radio-group v-model="assignId" @change="radioAssignChanges()">
                <el-radio v-for="items in assignList" :key="items.id" :label="items.id">
                  {{ items.name }}
                </el-radio>
              </el-radio-group>
            </div>
            <div v-if="assignId === 1" style="margin-left: 197px;margin-top: 10px;">
              <el-radio-group v-model="wtypeId" @change="radioWorkChanges()">
                <el-radio v-for="items in workList" :key="items.id" :label="items.id">
                  {{ items.name }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div v-if="item.code === 'table_page_size'">
            <div class="item-wrapper">
              <div class="label">默认条数：</div>
              <div>
                <CURDSelect
                  ref="CURDSelect"
                  form-type="number"
                  :custom-list="pageSize.value.list"
                  :default-value="pageSize.value.default"
                  placeholder="输入条数"
                  style="width: 300px;display: inline-block"
                  @confirm="confirmPageSize"
                  @del="confirmPageSize"
                  @change="(select) => { confirmPageSize('change', '', select) }"
                />
              </div>
            </div>
          </div>
          <div v-if="item.code === 'plan_config'">
            <div class="plan_header-title">
              1.优先级设置：
            </div>
            <div
              v-for="(tag, index) in planTags"
              :key="tag.name"
              :style="{ color: tag.color, background: `${tag.color}20` }"
              class="flex-cc tag"
            >
              <el-button
                v-if="tag.isSystem !== 1"
                type="text"
                icon="el-icon-edit-outline"
                style="font-size: 16px"
                @click="modifyTag(tag, index)"
              />
              <div>{{ tag.name }}</div>
              <el-button
                v-if="tag.isSystem !== 1"
                type="text"
                icon="el-icon-error"
                style="font-size: 16px;color: #00000051"
                @click="delTag(tag, index)"
              />
            </div>
            <el-button
              type="text"
              icon="el-icon-circle-plus-outline"
              style="font-size: 20px;position: relative;top: 2px"
              @click="addTag"
            />
            <el-popover
              v-model="inputVisible"
              placement="bottom"
              width="200"
              trigger="click"
              @after-enter="afterEnter"
            >
              <div class="title flex-sb">
                <div class="left">新增优先级</div>
                <div class="right">
                  <el-button type="text" icon="el-icon-error" @click="inputVisible = false"/>
                </div>
              </div>
              <el-input
                ref="saveTagInput"
                v-model="inputValue"
                class="input-new-tag"
                placeholder="优先级名称"
                size="small"
                style="width: 100%"
              />
              <div class="color-list flex-sb">
                <div
                  v-for="color in colors"
                  :key="color"
                  class="color flex-cc"
                  :style="{ background: color }"
                  @click="setColor(color)"
                >
                  <i v-if="currentColor === color" class="el-icon-check" style="color: #FFFFFF"/>
                </div>
              </div>
              <div class="footer flex-cc">
                <el-button type="primary" size="small" @click="handleInputConfirm">
                  {{ currentType === 'add' ? '创建' : '修改' }}
                </el-button>
              </div>
            </el-popover>
            <br>
            <h3 class="plan_header-title">优先级顺序：</h3>
            <draggable
              v-model="planTags"
              v-bind="dragOptions"
              class="plan-drag-container"
              @start="isDragging = true"
              @end="dragEnd"
            >
              <div v-for="dragTag in planTags" :key="dragTag.name">
                <div :style="{ color: dragTag.color }">{{ dragTag.name }}</div>
                <div><i class="el-icon-rank"/></div>
              </div>
            </draggable>
            <!-- 工序任务设置 -->
            <div class="plan_header-title" v-if="memberType === 'line'">
              2. 工序任务下发设置
            </div>
            <MTable ref="mTable" :columns="columns" :height="290" :set-data-method="getTableData"
                    :columns-setting="false" v-if="memberType === 'line'">
              <div slot="issueMode" slot-scope="{ row }">
                <el-radio-group
                  v-model="row.issueMode"
                  name="issueGroup"
                  @change="assingChanges(1, row.issueMode, row)"
                >
                  <el-radio v-for="items in issueList" :key="items.id" :label="items.id">{{ items.name }}</el-radio>
                </el-radio-group>
              </div>
              <div slot="assignMode" slot-scope="{ row }">
                <el-radio-group
                  v-model="row.assignMode"
                  name="assingGroup"
                  @change="assingChanges(2, row.assignMode, row)"
                >
                  <el-radio v-for="items in assingList" :key="items.id" :label="items.id">{{ items.name }}</el-radio>
                </el-radio-group>
              </div>
              <div slot="parallelMode" slot-scope="{ row }">
                <el-radio-group
                  v-model="row.parallelMode"
                  name="parallelGroup"
                  @change="assingChanges(3, row.parallelMode, row)"
                >
                  <el-radio v-for="items in parallelList" :key="items.id" :label="items.id">{{ items.name }}</el-radio>
                </el-radio-group>
              </div>
            </MTable>
            <div v-for="item in extraList" :key="item.id" class="item-wrapper" v-if="memberType === 'line'">
              <div class="label">{{ item.name }}：</div>
              <el-radio-group v-model="item.value" :name="item.name" @change="radioChanges(item)">
                <el-radio v-for="items in item.selectItemList" :key="items.value" :label="items.value">{{
                    items.name
                  }}
                </el-radio>
              </el-radio-group>
            </div>
            <!-- 计划总数设置 -->
            <div v-if="memberCode === 'mubai' || memberCode === 'reagold'" class="item-wrapper">
              <div class="label">计划总数是否允许大于订单数：</div>
              <el-radio-group v-model="planId" @change="radioPlanChanges()">
                <el-radio v-for="items in planList" :key="items.id" :label="items.id">{{
                    items.name
                  }}
                </el-radio>
              </el-radio-group>
            </div>
            <!-- 外协申请设置 -->
            <div class="plan_header-title" style="margin-bottom: 0" v-if="memberType === 'line'">
              3. 外协申请设置
            </div>
            <div class="item-wrapper" v-if="memberType === 'line'">
              <div class="label">外协申请审批：</div>
              <el-radio-group v-model="outSourceId" @change="radioOutChanges()">
                <el-radio v-for="items in outSourceList" :key="items.id" :label="items.id">{{
                    items.name
                  }}
                </el-radio>
              </el-radio-group>
            </div>
            <div v-if="memberType === 'line' && +outSourceId === 1" class="item-wrapper">
              <div class="label">审批权限设置：</div>
              <div style="width: 100%">
                <el-select
                  v-model="defaultOutsource"
                  placeholder="请选择"
                  filterable
                  multiple
                  clearable
                  style="width: 100%"
                  @change="modifyDefaultOutsource"
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>
          </div>
          <div v-if="item.code === 'inspection_update_user'">
            <div class="item-wrapper">
              <div class="label">默认质检人员：</div>
              <div style="width: 100%">
                <el-select
                  v-model="defaultInspection"
                  placeholder="请选择"
                  filterable
                  multiple
                  clearable
                  style="width: 100%"
                  @change="modifyDefaultInspection"
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>
            <div class="item-wrapper" v-if="memberType === 'line'">
              <div class="label">质检提交后可修改检验记录人员：</div>
              <div style="width: 100%">
                <el-select
                  v-model="inspection"
                  placeholder="请选择"
                  filterable
                  multiple
                  clearable
                  style="width: 100%"
                  @change="modifyInspection"
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>
            <div class="item-wrapper" v-if="memberType === 'line'">
              <div class="label">可删除质检检验记录人员：</div>
              <div style="width: 100%">
                <el-select
                  v-model="inspectionDeleteUser"
                  placeholder="请选择"
                  filterable
                  multiple
                  clearable
                  style="width: 100%"
                  @change="deleteInspection"
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>
            <div class="item-wrapper" v-if="memberType === 'mom'">
              <div class="label">不良品判定：</div>
              <el-radio-group v-model="isOpenDefectId" @change="radioDefectChanges()">
                <el-radio v-for="items in assignList" :key="items.id" :label="items.id">{{
                    items.name
                  }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div v-if="item.code === 'complete_config'">
            <div v-for="item in completeConfigList" :key="item.id" class="item-wrapper">
              <div class="label">{{ item.name }}：</div>
              <el-radio-group v-model="item.value" :name="item.name" @change="radioChanges(item)">
                <el-radio v-for="items in item.selectItemList" :key="items.value" :label="items.value">{{
                    items.name
                  }}
                </el-radio>
              </el-radio-group>
            </div>
            <div class="item-wrapper">
              <div class="label">库存数据查询最小间隔：</div>
              <div>
                <CURDSelect
                  ref="CURDSelect"
                  form-type="number"
                  :custom-list="stockInterval.value.list"
                  :default-value="stockInterval.value.default"
                  placeholder="输入数值"
                  style="width: 100px;display: inline-block"
                  @confirm="confirmStockInterval"
                  @del="confirmStockInterval"
                  @change="(select) => { confirmStockInterval('change', '', select) }"
                />
              </div>
              <div class="label">分钟</div>
            </div>
          </div>
          <div v-if="item.code === 'production_config'">
            <div v-for="item in productionConfigList" :key="item.id" class="item-wrapper" v-if="!item.allFlag">
              <div class="label">{{ item.name }}：</div>
              <el-radio-group v-model="item.value" :name="item.name" @change="radioChanges(item)">
                <el-radio v-for="items in item.selectItemList" :key="items.value" :label="items.value">{{
                    items.name
                  }}
                </el-radio>
              </el-radio-group>
            </div>

            <div v-for="item in productionConfigList" :key="item.id / 5" class="item-wrapper" v-if="item.allFlag">
              <!-- 结案完成完工入库-->
              <div v-if="item.code === 'close_finish_in_warehouse'">
                <div class="label">{{ item.name }}：</div>
                <el-radio-group v-model="item.value" :name="item.name" @change="radioChanges(item)">
                  <el-radio key="1" label="1">启用</el-radio>
                  <el-radio key="0" label="0">停用</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div v-for="item in productionConfigList" :key="item.id / 6" class="item-wrapper" v-if="item.allFlag">
              <!-- 完工入库仓库-->
              <div v-if="item.code === 'finish_in_warehouse' && oneMsg.value !== '0'">
                <div class="label">{{ item.name }}：</div>
                <el-radio-group v-model="item.value" :name="item.name" @change="radioChanges(item)">
                  <el-radio key="0" label="0" style="min-width: auto">本系统</el-radio>
                  <el-select v-model="warehouseMsg.value" placeholder="请选择仓库" style="width: 200px"
                             @change="radioChanges(warehouseMsg)"
                             :disabled="item.value === '1'">
                    <el-option
                      v-for="itemW in allWarehouseList"
                      :key="itemW.id"
                      :label="itemW.name"
                      :value="itemW.id">
                    </el-option>
                  </el-select>
                  <el-radio key="1" label="1" style="margin-left: 40px">第三方ERP</el-radio>
                </el-radio-group>
              </div>
            </div>

          </div>
          <div v-if="item.code === 'mbom_config'">
            <div v-for="item in mbomList" :key="item.id" class="item-wrapper" v-if="!item.allFlag">
              <div class="label">{{ item.name }}：</div>
              <el-radio-group v-model="item.value" :name="item.name" @change="radioChanges(item)">
                <el-radio v-for="items in item.selectItemList" :key="items.value" :label="items.value">{{
                  items.name
                  }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <!-- <div v-if="item.code === 'inspection_update_user'">
            <div class="item-wrapper">
              <div class="label">水印内容：</div>
              <el-input
                v-model="watermark"
                placeholder="请输入水印内容"
                clearable
                style="width: 50%"
              >
              </el-input>
              <el-button type="primary" size="small">修改</el-button>
            </div>
          </div> -->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import procedureApi from '@/api/information/production/procedure'
import columns from './columns'
import sitApi from '@/api/information/warehouse/model'
import api from '@/api/sets/function/system-params'
import baseSettingApi from '@/api/sets/base-setting'
import draggable from 'vuedraggable'
import {getUUid} from '@/utils'
import {Encrypt} from '@/utils/sercet'
import CURDSelect from '@/components/CURDSelect/CURDSelect'
import userApi from '@/api/sets/user/user'

export default {
  name: 'SystemParams',
  components: {CURDSelect, draggable},
  data() {
    return {
      warehouseMsg:{},
      oneMsg:{},
      columns,
      assignId: '',
      wtypeId: '',
      workParams: [],
      completeConfigList: [],
      productionConfigList: [],
      assignList: [
        {
          id: 1,
          name: '启用'
        },
        {
          id: 0,
          name: '停用'
        }
      ],
      workList: [
        {
          id: 1,
          name: '按工单备料'
        },
        {
          id: 0,
          name: '按工序备料'
        }
      ],
      defaultOutsource: [],
      outsourceList: [],
      outsourceParams: {},
      planId: '',
      planParams: {},
      planList: [
        {
          id: 1,
          name: '是'
        },
        {
          id: 0,
          name: '否'
        }],
      params: {a: 0},
      paramsList: [],
      planTags: [],
      inputValue: '',
      inputVisible: false,
      colors: ['#00AB29', '#F5A623', '#D0021B', '#607FFF', '#BD10E0'],
      currentColor: '',
      currentType: 'add',
      planConfig: {},
      isDragging: false,
      currentData: {},
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      extraList: [],
      issueList: [{
        id: 0,
        name: '无需下发'
      },
        {
          id: 1,
          name: '需要下发'
        }],
      assingList: [{
        id: 0,
        name: '无需指派'
      },
        {
          id: 1,
          name: '需要指派'
        }],
      parallelList: [{
        id: 1,
        name: '可并行'
      },
        {
          id: 0,
          name: '不可并行'
        }],
      outSourceList: [
        {
          id: 1,
          name: '是'
        },
        {
          id: 0,
          name: '否'
        }],
      searchDatas: [],
      issList: [{
        value: '0',
        name: '工序任务无需下发，拥有权限的工位均可操作'
      },
        {
          value: '1',
          name: '工序任务必须下发至拥有权限的工位才可进行操作'
        }
      ],
      assList: [{
        value: '0',
        name: '工序任务无需指派，拥有权限人员均可操作'
      },
        {
          value: '1',
          name: '工序任务必须指派至拥有权限的用户/用户组才可进行操作'
        }
      ],
      completeStockDataSourceList: [
        {
          value: '0',
          name: '本系统'
        },
        {
          value: '1',
          name: '第三方ERP'
        }
      ],
      completeAllocatedList: [
        {
          value: '0',
          name: '按工序计划开始时间'
        },
        {
          value: '1',
          name: '按工单计划开工时间'
        }
      ],
      closeProcedureTaskList: [
        {
          value: '1',
          name: '启用'
        },
        {
          value: '0',
          name: '停用'
        }
      ],
      closeInspectionTaskList: [
        {
          value: '1',
          name: '启用'
        },
        {
          value: '0',
          name: '停用'
        }
      ],
      closeFinishSitMaterialsOutWarehouseList: [
        {
          value: '1',
          name: '启用'
        },
        {
          value: '0',
          name: '停用'
        }
      ],
      closeFinishInWarehouseList: [
        {
          value: '1',
          name: '启用'
        },
        {
          value: '0',
          name: '停用'
        }
      ],
      closeAutoFinishProjectList: [
        {
          value: '1',
          name: '启用'
        },
        {
          value: '0',
          name: '停用'
        }
      ],
      procedureKeyMaterialsList: [
        {
          value: '1',
          name: '启用'
        },
        {
          value: '0',
          name: '禁用 (提示：禁用后MBOM不可定义工序关键料，只能通过物料定义)'
        }
      ],
      pageSize: {
        value: {
          default: 20,
          list: []
        }
      },
      stockInterval: {
        value: {
          default: 60,
          list: []
        }
      },
      inspection: [],
      defaultInspection: [],
      userList: [],
      inspectionParams: {},
      inspectionDefaultParams: {},
      outSourceId: 0,
      outSourceParams: {},
      defectParams: {},
      defaultLine: [],
      lineList: [],
      lineParams: {},
      inspectionDeleteUser: [],
      inspectionDeleteUserParams: {},
      watermark: '',
      isOpenDefectId: 0,
      stockDataQueryInterval: '',
      allWarehouseList:[],
      mbomList: []
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    memberType(){
      return localStorage.getItem('memberType')
    }
  },
  async created() {
    // 获取仓库列表
    const res = await sitApi.getRepositoryModelList()
    this.allWarehouseList = res.filter(item => item.type === 0)
  },
  mounted() {
    if (['mubai', 'reagold'].includes(sessionStorage.getItem('memberCode'))) {
      this.getPlansConfig()
    }
    this.getOutsourceConfig()
    this.getWaiConfig()
    this.getSystemParamsList()
    this.getPlanConfig()
    this.getPageSizeConfig()
    this.getIssueConfig()
    this.getUserList()
    if (!['bbelc', 'jinshi'].includes(sessionStorage.getItem('memberCode'))) {
      this.getInspectionConfig()
      this.getInspectionDefaultConfig()
    }
    if(this.memberType === 'mom'){
      this.getDefectProductConfig()
    }
    // 备料
    this.getBeiConfig()
    this.getInspectionDeleteUserConfig()
    this.getCompleteStockDataSourceConfig()
    this.getCompleteStockIntervalConfig()
    this.getProductionConfig()
    this.getMBomConfig()
  },
  methods: {
    async getBeiConfig() {
      const search = {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'code',
            fieldType: 'string',
            fieldValue: 'automatic_preparation^automatic_preparation_type',
            operator: 'in'
          }]
        ))
      }
      const res = await baseSettingApi.getDetail(search)
      if (res) {
        this.workParams = res
        this.assignId = +res[1].value
        this.wtypeId = +res[0].value
      }
    },
    async radioAssignChanges() {
      await baseSettingApi.modifySingleSetting({
        id: this.workParams[1].id,
        code: this.workParams[1].code,
        value: this.assignId
      })
    },
    async radioWorkChanges() {
      await baseSettingApi.modifySingleSetting({
        id: this.workParams[0].id,
        code: this.workParams[0].code,
        value: this.wtypeId
      })
    },
    async radioOutChanges() {
      await baseSettingApi.modifySingleSetting({
        id: this.outSourceParams.id,
        code: this.outSourceParams.code,
        value: this.outSourceId
      })
    },
    async radioDefectChanges() {
      await baseSettingApi.modifySingleSetting({
        id: this.defectParams.id,
        code: this.defectParams.code,
        value: this.isOpenDefectId
      })
    },

    async radioPlanChanges() {
      await baseSettingApi.modifySingleSetting({
        id: this.planParams.id,
        code: this.planParams.code,
        value: this.planId
      })
    },
    async getUserList() {
      const res = await userApi.getUserList()
      if (res) {
        this.userList = res
      }
    },
    // 修改外协人员
    async modifyDefaultOutsource() {
      const res = await baseSettingApi.modifySingleSetting({
        id: this.outsourceParams.id,
        code: this.outsourceParams.code,
        value: JSON.stringify(this.defaultOutsource)
      })
      if (res) {
        this.$message.success('修改成功')
      }
    },
    // 修改首页设置
    async modifyDefaultLine() {
      const res = await baseSettingApi.modifySingleSetting({
        id: this.lineParams.id,
        code: this.lineParams.code,
        value: JSON.stringify(this.defaultLine)
      })
      if (res) {
        this.$message.success('修改成功')
      }
    },
    // 修改质检设置
    async modifyInspection() {
      const res = await baseSettingApi.modifySingleSetting({
        id: this.inspectionParams.id,
        code: this.inspectionParams.code,
        value: JSON.stringify(this.inspection)
      })
      if (res) {
        this.$message.success('修改成功')
      }
    },
    //修改删除质检设置
    async deleteInspection() {
      const res = await baseSettingApi.modifySingleSetting({
        id: this.inspectionDeleteUserParams.id,
        code: this.inspectionDeleteUserParams.code,
        value: JSON.stringify(this.inspectionDeleteUser)
      })
      if (res) {
        this.$message.success('修改成功')
      }
    },

    // 修改质检设置
    async modifyDefaultInspection() {
      const res = await baseSettingApi.modifySingleSetting({
        id: this.inspectionDefaultParams.id,
        code: this.inspectionDefaultParams.code,
        value: JSON.stringify(this.defaultInspection)
      })
      if (res) {
        this.$message.success('修改成功')
      }
    },
    // 修改列表下发和指派设置
    async assingChanges(type, val, row) {
      let data
      if (type === 1) {
        data = {
          id: row.id,
          issueMode: val
        }
      }
      if (type === 2) {
        data = {
          id: row.id,
          assignMode: val
        }
      }
      if (type === 3) {
        data = {
          id: row.id,
          parallelMode: val
        }
      }
      await procedureApi.modifyProcedure(data)
    },
    // 列表
    async getTableData(condition, callback) {
      const {page, search, order} = condition
      const res = await procedureApi.getProcedureListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchDatas]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    async radioChanges(item) {
      if(item.code === 'close_finish_in_warehouse'){
        this.oneMsg = item
      }
      await baseSettingApi.modifySingleSetting({
        id: item.id,
        code: item.code,
        value: item.value
      })
    },
    async getOutsourceConfig() {
      const res = await baseSettingApi.getCode({code: 'outsource_approver'})
      if (res) {
        this.defaultOutsource = res.value ? JSON.parse(res.value) : []
        this.outsourceParams = res
      }
    },
    async getPlansConfig() {
      const res = await baseSettingApi.getCode({code: 'plan_total_need_review'})
      if (res) {
        this.planId = +res.value
        this.planParams = res
      }
    },
    // 获取外协申请
    async getWaiConfig() {
      const res = await baseSettingApi.getCode({code: 'outsource_request_need_review'})
      if (res) {
        this.outSourceId = +res.value
        this.outSourceParams = res
      }
    },
    // 获取默认下发和指派方式
    async getIssueConfig() {
      const search = {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'code',
            fieldType: 'string',
            fieldValue: 'assign_mode^issue_mode',
            operator: 'in'
          }]
        ))
      }
      const res = await baseSettingApi.getDetail(search)
      if (res) {
        const data = res.map((item, index) => {
          return {
            ...item,
            selectItemList: index === 0 ? this.issList : this.assList,
            selectVal: [parseInt(item.value)]
          }
        })
        this.extraList = data
      }
    },
    // 获取齐套库存数据源和分配优先级
    async getCompleteStockDataSourceConfig() {
      const search = {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'code',
            fieldType: 'string',
            fieldValue: 'complete_stock_data_source^complete_allocate_priority',
            operator: 'in'
          }]
        ))
      }
      const res = await baseSettingApi.getDetail(search)
      if (res) {
        const data = res.map((item, index) => {
          return {
            ...item,
            selectItemList: index === 0 ? this.completeStockDataSourceList : this.completeAllocatedList,
            selectVal: [parseInt(item.value)]
          }
        })
        this.completeConfigList = data
      }
    },
    // 获取装备云生产设置配置
    async getProductionConfig() {
      const search = {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'code',
            fieldType: 'string',
            fieldValue: 'close_procedure_task^close_inspection_task^close_finish_sit_materials_out_warehouse' +
              '^close_finish_in_warehouse^close_auto_finish_project^finish_in_warehouse^warehouse',
            operator: 'in'
          }]
        ))
      }
      const res = await baseSettingApi.getDetail(search)
      if (res) {
        const flagArr = ['close_finish_in_warehouse', 'finish_in_warehouse', 'warehouse']
        const data = res.map((item, index) => {
          if(item.code === 'warehouse'){
            item.value = +item.value
            this.warehouseMsg = item
          }
          if(item.code === 'close_finish_in_warehouse'){
            this.oneMsg = item
          }
          return {
            ...item,
            allFlag: flagArr.includes(item.code),
            selectItemList: index === 0 ? this.closeProcedureTaskList : index === 1 ? this.closeInspectionTaskList :
              index === 2 ? this.closeFinishSitMaterialsOutWarehouseList : index === 3 ? this.closeFinishInWarehouseList : this.closeAutoFinishProjectList,
            selectVal: [parseInt(item.value)]
          }
        })
        // close_finish_in_warehouse 结案完成完工入库
        // finish_in_warehouse 完工入库仓库   warehouse  仓库
        // close_auto_finish_project 结案自动完成项目结案
        this.productionConfigList = data
      }
    },
    // 获取MBom配置
    async getMBomConfig() {
      const search = {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'code',
            fieldType: 'string',
            fieldValue: 'procedure_key_materials_config',
            operator: 'in'
          }]
        ))
      }
      const res = await baseSettingApi.getDetail(search)
      if (res) {
        const data = res.map((item, index) => {
          return {
            ...item,
            selectItemList: index === 0 ? this.procedureKeyMaterialsList : this.procedureKeyMaterialsList,
            selectVal: [parseInt(item.value)]
          }
        })
        this.mbomList = data
      }
    },
    async getPlanConfig() {
      const res = await baseSettingApi.getCode({code: 'plan_config'})
      if (res) {
        this.planConfig = res
        this.planTags = res.value ? JSON.parse(res.value) : []
        this.$store.dispatch('user/priorityStatusList', this.planTags)
      }
    },
    async getPageSizeConfig() {
      const res = await baseSettingApi.getCode({code: 'page_size_config'})
      if (res) {
        const value = JSON.parse(res.value)
        this.pageSize = {
          ...res, value: {
            default: value.default,
            list: value.list.map(item => ({id: item, name: item}))
          }
        }
        this.$store.dispatch('app/updatePageSize')
      }
    },
    async getCompleteStockIntervalConfig() {
      const res = await baseSettingApi.getCodeNoCheck({code: 'complete_stock_interval_config'})
      if (res) {
        const value = JSON.parse(res.value)
        this.stockInterval = {
          ...res, value: {
            default: value.default,
            list: value.list.map(item => ({id: item, name: item}))
          }
        }
      }
    },
    // async getLineConfig() {
    //   const res = await baseSettingApi.getCode({ code: 'home_page_statistics_production_line' })
    //   if (res) {
    //     this.defaultLine = res.value ? JSON.parse(res.value) : []
    //     this.lineParams = res
    //   }
    // },
    async getInspectionConfig() {
      const res = await baseSettingApi.getCode({code: 'inspection_update_user'})
      if (res) {
        this.inspection = res.value ? JSON.parse(res.value) : []
        this.inspectionParams = res
      }
    },
    async getDefectProductConfig() {
      const res = await baseSettingApi.getCode({code: 'defect_product_judgment_is_open'})
      if (res) {
        this.isOpenDefectId = +res.value
        this.defectParams = res
      }
    },
    async getInspectionDefaultConfig() {
      const res = await baseSettingApi.getCode({code: 'inspection_default_user'})
      if (res) {
        this.defaultInspection = res.value ? JSON.parse(res.value) : []
        this.inspectionDefaultParams = res
      }
    },
    async getInspectionDeleteUserConfig() {
      const res = await baseSettingApi.getCode({code: 'inspection_delete_user'})
      if (res) {
        this.inspectionDeleteUser = res.value ? JSON.parse(res.value) : []
        this.inspectionDeleteUserParams = res
      }
    },

    async dragEnd() {
      this.isDragging = false
      const res = await baseSettingApi.modifySingleSetting({
        id: this.planConfig.id,
        code: this.planConfig.code,
        value: JSON.stringify(this.planTags)
      })
      if (res) {
        await this.getPlanConfig()
      }
    },
    setColor(color) {
      this.inputVisible = true
      this.currentColor = color
    },
    addTag() {
      this.inputValue = ''
      this.currentType = 'add'
      this.currentData = {}
      this.currentColor = this.colors[0]
      this.inputVisible = true
    },
    modifyTag(tag, index) {
      this.inputValue = tag.name
      this.currentType = index
      this.currentData = tag
      this.currentColor = tag.color
      this.inputVisible = true
    },
    async delTag(tag, index) {
      const copyPlanTag = this._.cloneDeep(this.planTags)
      copyPlanTag.splice(index, 1)
      const res = await baseSettingApi.modifySingleSetting({
        id: this.planConfig.id,
        code: this.planConfig.code,
        value: JSON.stringify(copyPlanTag)
      })
      if (res) {
        await this.getPlanConfig()
        this.$message.success('删除成功')
      }
    },
    async handleInputConfirm() {
      const inputValue = this.inputValue
      const tagNames = this.planTags.map(item => item.name)
      const index = tagNames.indexOf(inputValue)
      if (!inputValue) {
        return this.$message.info('请输入优先级名称')
      }
      if (index !== -1 && this.currentColor === this.planTags[index].color) {
        return this.$message.info('优先级已存在，请更改优先级名称')
      }
      const tag = {name: inputValue, color: this.currentColor, uuid: this.currentData.uuid || getUUid()}
      const currentType = (typeof this.currentType).toLowerCase() === 'number' ? 'modify' : 'add'
      const copyPlanTag = this._.cloneDeep(this.planTags)
      if (currentType === 'modify') {
        copyPlanTag.splice(this.currentType, 1, tag)
      } else {
        copyPlanTag.push(tag)
      }
      const res = await baseSettingApi.modifySingleSetting({
        id: this.planConfig.id,
        code: this.planConfig.code,
        value: JSON.stringify(copyPlanTag)
      })
      if (res) {
        await this.getPlanConfig()
        this.inputVisible = false
        this.inputValue = ''
        this.$message.success(currentType === 'add' ? '新增成功' : '修改成功')
      }
    },
    afterEnter() {
      this.$nextTick(() => {
        this.$refs.saveTagInput[0].$refs.input.focus()
      })
    },
    async getSystemParamsList() {
      const res = await api.getSystemParamsList()
      if (res) {
        this.paramsList = res
      }
    },
    async radioChange(id) {
      await api.enabledSystemParams({id})
      await this.getSystemParamsList()
    },
    async confirmPageSize(type, value, select) {
      const list = this.pageSize.value.list.map(item => item.id)
      if (list.includes(value) && type !== 'del' && +value !== select.id) {
        return this.$message.success('条数已存在')
      }
      if (type === 'change') {
        this.pageSize.value.default = select.id
      }
      if (type === 'add') {
        list.push(+value)
        list.sort((a, b) => a - b)
      }
      if (type === 'modify') {
        const index = list.findIndex(item => item === select.id)
        list.splice(index, 1, +value)
        list.sort((a, b) => a - b)
        if (this.pageSize.value.default === +select.id) {
          this.pageSize.value.default = list[0]
        }
      }
      if (type === 'del') {
        const index = list.findIndex(item => item === select.id)
        list.splice(index, 1)
        list.sort((a, b) => a - b)
        if (list.length === 0) {
          return this.$message.info('请至少保留一条')
        }
        if (this.pageSize.value.default === select.id) {
          this.pageSize.value.default = list[0]
        }
      }
      const res = await baseSettingApi.modifySingleSetting({
        id: this.pageSize.id,
        code: this.pageSize.code,
        value: JSON.stringify({
          default: +this.pageSize.value.default,
          list
        })
      })
      if (res) {
        await this.getPageSizeConfig()
        this.$refs.CURDSelect[0].resetSelect()
        this.$message.success(type === 'add' ? '新增成功' : '修改成功')
      }
    },
    async confirmStockInterval(type, value, select) {
      const list = this.stockInterval.value.list.map(item => item.id)
      if (list.includes(value) && type !== 'del' && +value !== select.id) {
        return this.$message.success('数值已存在')
      }
      if (type === 'change') {
        this.stockInterval.value.default = select.id
      }
      if (type === 'add') {
        list.push(+value)
        list.sort((a, b) => a - b)
      }
      if (type === 'modify') {
        const index = list.findIndex(item => item === select.id)
        list.splice(index, 1, +value)
        list.sort((a, b) => a - b)
        if (this.stockInterval.value.default === +select.id) {
          this.stockInterval.value.default = list[0]
        }
      }
      if (type === 'del') {
        const index = list.findIndex(item => item === select.id)
        list.splice(index, 1)
        list.sort((a, b) => a - b)
        if (list.length === 0) {
          return this.$message.info('请至少保留一条')
        }
        if (this.stockInterval.value.default === select.id) {
          this.stockInterval.value.default = list[0]
        }
      }
      const res = await baseSettingApi.modifySingleSetting({
        id: this.stockInterval.id,
        code: this.stockInterval.code,
        value: JSON.stringify({
          default: +this.stockInterval.value.default,
          list
        })
      })
      if (res) {
        await this.getCompleteStockIntervalConfig()
        this.$refs.CURDSelect[0].resetSelect()
        this.$message.success(type === 'add' ? '新增成功' : '修改成功')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.system-wrapper {
  //padding: 20px;
  color: #393D60;

  .header-title {
    border-bottom: 1px solid #DDE3EB;
    margin: 0;
    padding: 20px 0;
  }

  .item-wrapper {
    > div {
      display: inline-flex;
      padding: 20px 5px 0 5px;
      align-items: center;
      font-size: 14px;

      .label {
        min-width: 115px;
      }

      .el-radio {
        min-width: 108px;
      }
    }

    ::v-deep .el-radio-group {
      vertical-align: initial;
    }

    ::v-deep .el-radio {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
    }
  }

  .common-card {
    background: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 20px;

    .card-tit {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 22px;
      border-bottom: 1px solid #DDE3EB;
      height: 42px;

      .card-le {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #393D60;
      }

      .card-ri {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #607FFF;
        cursor: pointer;
      }
    }
  }

  .card-bot {
    padding: 0 20px 20px 20px;
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-new-tag {
    vertical-align: bottom;
  }
}

.title {
  width: calc(100% + 24px);
  background: #F6F7F9;
  position: relative;
  left: -12px;
  top: -12px;
  align-items: center;
  padding: 0 12px;

  .right {
    .el-button {
      color: #9597ae;
      font-size: 20px;
    }
  }
}

.color-list {
  width: 100%;
  padding: 20px 3px;

  .color {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    cursor: pointer;

    i {

    }
  }
}

.footer {
  width: calc(100% + 24px);
  border-top: 1px solid #DDE3EB;
  position: relative;
  left: -12px;
  padding-top: 12px;

  ::v-deep .el-button {
    width: 88px;
    height: 32px;
  }
}

.tag {
  padding: 6px 10px;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 14px;

  &:hover {
    .el-button:first-child,
    .el-button:last-child {
      display: block;
    }
  }

  .el-button:first-child {
    color: #9597AE;
    padding: 0 3px;
    display: none;
    position: relative;
    right: 5px;
  }

  .el-button:last-child {
    color: #000000;
    padding: 0 3px;
    display: none;
    position: relative;
    left: 5px;
  }
}

.plan_header-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4A4F76;
  margin-top: 20px;
  margin-bottom: 10px;
}

.plan-drag-container {
  display: inline-block;
  border: 1px solid #DDE3EB;

  > div {
    padding: 10px 20px 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:first-child {
      min-width: 100px;
    }

    i {
      font-size: 20px;
    }

    &:hover {
      background: #607FFF10;

      i {
        color: #607FFF;
      }
    }
  }
}
</style>
