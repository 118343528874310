<template>
  <div class="add-local-development">
    <div class="detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">本地开发申请表</span>
      </div>
    </div>
    <div class="detail-body">
      <div class="detail-card">
        <!-- <div class="card-tit">基础信息</div> -->
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in columns" :key="item.id" :span="item.colSpan || 8">
              <div v-if="item.prop === 'partList'">
                <div class="view-list__label">{{ item.label }}</div>
                <MTable
                  ref="descTable"
                  :show-page="false"
                  :height="height"
                  :columns="getDetailColumns"
                  :data="descTableList"
                  :columns-setting="false"
                >
                  <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

                  <div slot="name" slot-scope="{ row }">
                    <div>{{ row.name }}</div>
                  </div>

                  <div slot="parameter" slot-scope="{ row }">
                    <div>{{ row.parameter || '-' }}</div>
                  </div>

                  <div slot="model" slot-scope="{ row }">
                    <div>{{ row.model || '-' }}</div>
                  </div>

                  <div slot="quantity" slot-scope="{ row }">
                    <div>{{ row.quantity || '-' }}</div>
                  </div>

                  <div slot="unitPrice" slot-scope="{ row }">
                    <div>{{ row.unitPrice || '-' }}</div>
                  </div>

                  <div slot="requestUnitPrice" slot-scope="{ row }">
                    <div>{{ row.requestUnitPrice || '-' }}</div>
                  </div>

                  <div slot="delivery" slot-scope="{ row }">
                    <div>{{ row.delivery || '-' }}</div>
                  </div>
                </MTable>
              </div>
              <div v-else-if="item.prop === 'assembles'">
                <div class="view-list__label">{{ item.label }}</div>
                <el-upload :file-list="getFileListByType()" v-bind="uploadProps" disabled :class="{ 'upload-view': isView }" />
                <el-upload
                  :file-list="getFileListByType(1)"
                  v-bind="uploadProps"
                  disabled
                  :class="{ 'upload-view': isView }"
                  list-type="picture"
                />
              </div>
              <div v-else>
                <div class="view-list__label">{{ item.label }}</div>
                <div class="view-list__value">{{ getValue(item) || '-' }}</div>
              </div>
            </el-col>
          </el-row>
          <MFormBuilder v-else ref="baseForm" :form-list="descFormList" :form-data="baseFormData" label-position="right">
            <div slot="partList" class="desc-table" :class="{ edit: !isView }">
              <MTable ref="descTable" :show-page="false" :height="height" :columns="descColumns" :data="descTableList" :columns-setting="false">
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <div slot="name" slot-scope="{ row }" class="table-cell">
                  <span class="required-star">*</span>
                  <el-input v-model="row.name" placeholder="输入名称" />
                </div>
                <div slot="parameter" slot-scope="{ row }" class="table-cell">
                  <el-input v-model="row.parameter" placeholder="输入型号/参数/PN" />
                </div>
                <div slot="model" slot-scope="{ row }" class="table-cell">
                  <el-input v-model="row.model" placeholder="输入适用SCR机型" />
                </div>
                <div slot="quantity" slot-scope="{ row }" class="table-cell">
                  <el-input v-model="row.quantity" placeholder="输入数量/套" />
                </div>
                <div slot="unitPrice" slot-scope="{ row }" class="table-cell">
                  <el-input v-model="row.unitPrice" placeholder="输入原单价/元" />
                </div>
                <div slot="requestUnitPrice" slot-scope="{ row }" class="table-cell">
                  <el-input v-model="row.requestUnitPrice" placeholder="输入要求单价（含税）/元" />
                </div>
                <div slot="delivery" slot-scope="{ row }" class="table-cell">
                  <el-input v-model="row.delivery" placeholder="输入要求交货期/天" />
                </div>
                <div slot="action" slot-scope="{ $index }">
                  <template>
                    <el-button type="text" @click="delTableList($index)">移除</el-button>
                  </template>
                </div>
              </MTable>
              <el-button v-if="!isView" size="small" type="primary" class="e-btn" @click="addTableList">添加</el-button>
            </div>

            <el-upload slot="drawing" :file-list="attachments" v-bind="uploadProps" :class="{ 'upload-view': isView }">
              <el-button type="primary" size="small">上传文件</el-button>
            </el-upload>
          </MFormBuilder>
        </div>
      </div>
    </div>
    <div class="detail-footer">
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/xiewei/service/demand-center'
import { columns, descColumns } from './columns'
import { formList, descFormList } from './form-list'
import { getToken } from '@/utils/auth'

export default {
  name: 'AddLocalDevelopment',
  data() {
    return {
      columns,
      descColumns,
      formList,
      descFormList,
      params: {},
      baseFormData: {},
      buttonLoading: false,
      descTableList: [],
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onRemove: this.removeFile,
        onPreview: this.handlePreview
      },
      attachments: []
    }
  },

  computed: {
    height() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 138
    },
    isView() {
      return this.$route.query.type === 'view'
    },

    getValue() {
      return row => {
        const result = this.baseFormData[row.prop]
        if (row.form && row.form.options && row.form.options.length > 0) {
          const current = row.form.options.find(item => item.id === result)
          return current && current.name
        }
        return result
      }
    },

    getDetailColumns() {
      return this.descColumns.filter(item => item.prop !== 'action')
    },
    getFileListByType() {
      return type => {
        return this.attachments.filter(item => {
          const isPic = item.url.match(/\.(jpeg|jpg|gif|png|weap)$/)
          return type === 1 ? isPic : !isPic
        })
      }
    }
  },

  mounted() {
    this.params = this.$route.query
    this.baseFormData.facilityDemandId = this.params.id
    if (this.params.type === 'view' || this.params.type === 'modify') {
      this.getDetail()
    }
  },

  methods: {
    returns() {
      this.$router.back()
    },

    async getDetail() {
      const res = await api.getFacilityDemandApplicationDetail(this.$route.query.id)
      if (res) {
        this.baseFormData = {
          ...this.baseFormData,
          ...res
        }
        this.descTableList = res.partList || []
        this.attachments = JSON.parse(res.assembles || '[]')
      }
    },

    addTableList() {
      this.descTableList.push({})
    },

    delTableList(index) {
      this.descTableList.splice(index, 1)
    },

    // 上传附件
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },

    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.attachments.push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },

    removeFile(file) {
      this.attachments = this.attachments.filter(item => item.uid !== file.uid)
    },

    handlePreview(file) {
      if (file.url.indexOf('.txt') > 0) {
        saveAs(file.url, '文件')
        return
      }
      window.open(file.url)
    },

    submitForm() {
      this.buttonLoading = true

      const { form: baseForm } = this.$refs.baseForm

      const isPartListNull = this.descTableList.find(item => !item.name)
      if (isPartListNull) {
        this.buttonLoading = false
        return this.$message.error('零件清单有必填项未填')
      }

      this.$refs.baseForm.formRefs().validate(async valid => {
        if (valid) {
          const data = {
            ...this.baseFormData,
            ...baseForm,
            partList: this.descTableList,
            assembles: JSON.stringify(this.attachments)
          }
          this.saveData(data)
          this.buttonLoading = false
        } else {
          this.buttonLoading = false
        }
      })
    },

    async saveData(form) {
      const res = await api[`${this.params.type}FacilityDemandApplication`](form)
      if (res) {
        this.returns()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-local-development {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
  }

  .card-bot {
    ::v-deep.el-row {
      display: flex;
      flex-wrap: wrap;
    }

    .view-list {
      padding: 0 15px;
      color: #393d60;
      font-size: 14px;

      &__label {
        margin-bottom: 4px;
        color: #9597ae;
      }

      .el-col {
        margin: 10px 0;
      }
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;

      .required-star {
        width: 10px;
        color: #f00;
      }

      .range {
        margin: 0 10px;
      }
    }

    .upload-view {
      ::v-deep.el-upload {
        &--text {
          display: none;
        }
        &--picture {
          display: none;
        }
      }
    }
  }

  .detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;

      .desc-table.edit {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
      }

      .e-btn {
        margin-top: 10px;
      }
    }
  }
  .detail-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
