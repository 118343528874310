<template>
  <div class="equipment-monitoring">
    <div class="detail-cards">
      <div v-if="isTitle" class="card-tit">关联工单</div>
      <div class="card-bots">
        <div v-if="(+type === 3 && status === 7) || ([0, 1, 2, 6,7].includes(type) && status === 5)" style="margin-bottom: 10px;">
          <el-button v-if="isAll === 0" size="small" type="primary" @click="addWork">新增工单</el-button>
          <!-- <el-button size="small" type="primary" @click="addInfo" v-if="type === 0 || type === 1">添加</el-button> -->
        </div>
        <MTable
          ref="plansTable"
          :show-page="false"
          :height="heights"
          :columns="getColumn"
          :data="workList"
          :only-key="'uuid'"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="workOrderCode" slot-scope="{ row }">
            <el-button type="text" @click="viewInfo(row)">{{ row.workOrderCode }}</el-button>
          </div>
          <div slot="changeType" slot-scope="{ row }">{{ getChangeType(row.changeType) }}</div>
          <div slot="executionStartTime" slot-scope="{ row }">{{ getTimeDesc(row.executionStartTime, row.executionEndTime) }}</div>
          <div v-if="isAll === 0" slot="action" slot-scope="{ row, $index }">
            <template v-if="(+type === 3 && status === 7) || ([0, 1, 2, 6].includes(type) && status === 5)">
              <el-button type="text" @click="modifyInfos(row, $index)">修改</el-button>
              <el-divider direction="vertical" />
              <el-button type="text" @click="delInfo(row, $index)">删除</el-button>
            </template>
            <template v-if="([4, 5].includes(type) && status === 5)">
              <el-button type="text" @click="opertorInfos(row, $index)">执行</el-button>
            </template>

          </div>
        </MTable>
      </div>
    </div>
    <select-work
      ref="selectWork"
      :visible.sync="showWork"
      :content="type"
      @submitForm="submitWork"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { workColumns } from './columns'
import { equipmentFormList } from './form-list'
import { getUUid, getTimeDesc } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import SelectWork from './select-work.vue'
import api from '@/api/xiewei/service/demand-center'

export default {
  name: 'RelationWork',
  components: { SelectWork },
  props: {
    isTitle: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default: () => ([])
    },
    selecteFacilityList: {
      type: String,
      default: ''
    },
    demandId: {
      type: Number,
      default: undefined
    },
    demandCode: {
      type: String,
      default: ''
    },
    status: {
      type: Number,
      default: undefined
    },
    height: {
      type: Number,
      default: 300
    },
    isAll: {
      type: Number,
      default: 0
    },
    infos: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      getTimeDesc,
      workList: [],
      date: dayjs().format('YYYY-MM-DD'),
      currentType: 'add',
      workColumns,
      equipmentFormList,
      tableData: [],
      formData: {},
      showWork: false,
      messages: {
        add: '添加',
        modify: '修改'
      }
    }
  },
  computed: {
    heights() {
      if (this.status === 5) {
        return this.$store.state.app.contentHeight - 256 - 220 - 40
      }
      return this.$store.state.app.contentHeight - 256 - 220
    },
    getColumn() {
      if (+this.type === 4 || this.type === 6) {
        return this.workColumns.filter(item =>
          item.prop !== 'facilityCode' && item.prop !== 'facilityName' && item.prop !== 'facilitySn')
      } else {
        return this.workColumns
      }
    }
  },
  watch: {
    demandId(val) {
      if (val) {
        this.getFacilityWordOrderListById()
      }
    }
  },
  methods: {
    getChangeType(val){
      if (val === 0){
        return '换上'
      }else if (val === 1){
        return '换下'
      }else if (val === 2){
        return '换上换下'
      }else {
        return '-'
      }
    },
    opertorInfos(row) {
      if (+this.type === 4) {
        this.$router.push({ name: 'spotCheckDetail', query: { id: row.id, type: 'modify' }})
      }
      if (+this.type === 5) {
        this.$router.push({ name: 'maintainDetail', query: { id: row.id, type: 'modify' }})
      }
    },
    viewInfo(row) {
      if (+this.type === 2) {
        this.$router.push({ name: 'installEquipmentDetail', query: { type: 'view', code: this.demandCode, demandId: this.demandId, wordOrderId: row.id }})
        return
      }
      if (+this.type === 4) {
        this.$router.push({ name: 'spotCheckDetail', query: { id: row.id, type: 'view' }})
        return false
      }
      if (+this.type === 6) {
        this.$router.push({ name: 'spotCheckDetail', query: { type: 'view', id: row.id, source: 0 }})
        return
      }
      if (+this.type === 3) {
        this.$router.push({ name: 'reformDetail', query: { type: 'view', content: this.type, code: this.demandCode, demandId: this.demandId, wordOrderId: row.id }})
        return
      }
      if (+this.type === 7) {
        this.$router.push({ name: 'replaceDetail', query: { type: 'view', content: this.type, code: this.demandCode, demandId: this.demandId, wordOrderId: row.id }})
        return
      }
      this.$router.push({ name: 'repairsDetail', query: { type: 'view', content: this.type, code: this.demandCode, demandId: this.demandId, wordOrderId: row.id, operatorNames: this.infos.operatorNames }})
    },
    modifyInfos(row) {
      if (+this.type === 2) {
        this.$router.push({ name: 'installEquipmentDetail', query: { type: 'modify', code: this.demandCode, demandId: this.demandId, wordOrderId: row.id }})
        return
      }
      if (+this.type === 6) {
        this.$router.push({ name: 'addSpotCheck', query: { type: 'modify', id: row.id }})
        return
      }
      if (+this.type === 3) {
        this.$router.push({ name: 'reformDetail', query: { type: 'modify', content: this.type, code: this.demandCode, demandId: this.demandId, wordOrderId: row.id }})
        return
      }
      if (+this.type === 7) {
        this.$router.push({ name: 'replaceDetail', query: { type: 'modify', content: this.type, code: this.demandCode, demandId: this.demandId, wordOrderId: row.id }})
        return
      }
      this.$router.push({ name: 'repairsDetail', query: { type: 'modify', content: this.type, code: this.demandCode, demandId: this.demandId, wordOrderId: row.id, operatorNames: this.infos.operatorNames }})
    },
    addWork() {
      const infos = {
        customerId: this.infos.customerId || '',
        customerName: this.infos.customerName || '',
        customerSiteId: this.infos.customerSiteId || '',
        customerSiteIdList: this.infos.customerSiteIdList || '',
        remark: this.infos.remark || '',
        operatorNames: this.infos.operatorNames || ''
      }
      if (+this.type === 2) {
        this.$router.push({ name: 'installEquipmentDetail', query: { type: 'add', code: this.demandCode, demandId: this.demandId, infos: JSON.stringify(infos) }})
        return
      }
      if (+this.type === 6) {
        this.$router.push({ name: 'addSpotCheck', query: { type: 'add', code: this.demandCode, demandId: this.demandId, infos: JSON.stringify(infos) }})
        return
      }
      if (+this.type === 3) {
        this.$router.push({ name: 'reformDetail', query: { type: 'add', content: this.type, code: this.demandCode, demandId: this.demandId, infos: JSON.stringify(infos) }})
        return
      }
      if (+this.type === 7) {
        this.$router.push({ name: 'replaceDetail', query: { type: 'add', content: this.type, code: this.demandCode, demandId: this.demandId, infos: JSON.stringify(infos) }})
        return
      }
      this.$router.push({ name: 'repairsDetail', query: { type: 'add', content: this.type, code: this.demandCode, demandId: this.demandId, infos: JSON.stringify(infos) }})
    },
    // 查看工单详情
    async getFacilityWordOrderListById() {
      const res = await api.getFacilityWordOrderListById(this.demandId)
      if (res) {
        this.workList = res
      }
    },
    // 添加工单
    async addFacilityWordOrder(list) {
      const res = await api.addFacilityWordOrder({
        demandId: this.demandId,
        demandCode: this.demandCode,
        type: this.type,
        idList: list
      })
      if (res) {
        this.$message.success('添加成功')
        this.getFacilityWordOrderListById()
      }
    },
    async submitWork(val) {
      const data = val.map(item => item.id)
      await this.addFacilityWordOrder(data)
      this.showWork = false
    },
    addInfo() {
      this.showWork = true
    },
    async delInfos(row, index) {
      this.$confirm('确定要移除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.removeFacilityWordOrder({
          demandId: this.demandId,
          demandCode: this.demandCode,
          type: this.type,
          idList: [row.id]
        })
        if (res) {
          this.$message.success('移除成功')
          this.getFacilityWordOrderListById()
        }
      })
    },
    async delInfo(row, index) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.deleteFacilityWordOrderOne({
          demandId: this.demandId,
          demandCode: this.demandCode,
          type: this.type,
          wordOrderId: row.id
        })
        if (res) {
          this.$message.success('删除成功')
          this.getFacilityWordOrderListById()
        }
      })
    },
    getSuccessMessage() {
      return `${this.messages[this.currentType]}成功`
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-monitoring {
  width: 100%;
  ::v-deep {
    .el-form-item__label {
      padding: 0 12px 0 0;
    }
    // .el-form-item__content {
    //   width: 300px;
    // }
    .el-form-item {
      margin-bottom: 20px!important;
    }
  }
  .card-wrapper {
    border: 1px solid #cccccc;
    border-radius: 4px;
    .top {
      width: 100%;
      padding: 10px;
      height: 53px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      .right {
        height: 32px;
        img {
          width: 30px;
          padding-left: 10px;
          position: relative;
          top: 5px;
        }
      }
    }
    .body {
      padding: 10px;
      .li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .line-wrapper {
    width: calc(100% - 260px);
  }
  .equipment-params {
    width: 250px;
  }
}
</style>
