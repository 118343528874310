import { transformColumnsToForm } from '@/utils'
import { OrganizationType, Organization } from './columns'

const OrganizationList = transformColumnsToForm(Organization)
OrganizationList.splice(2, 0, {
  colSpan: 24,
  index: 3,
  key: 'parentId',
  label: '上级组织',
  tagName: 'el-select',
  required: true,
  props: {
    placeholder: '请选择上级组织'
  },
  children: [
    {
      tagName: 'el-option',
      props: {
        label: 'name',
        value: 'id'
      },
      options: []
    }
  ]
})

const formList = {
  Organization: OrganizationList,
  OrganizationType: transformColumnsToForm(OrganizationType)
}

export default formList
