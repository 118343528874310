var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "procedure-detail-wrappers" },
    [
      _c("div", { staticClass: "procedure-detail-header flex-sbc" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [
            _c("img", {
              staticStyle: { width: "8px" },
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c(
              "span",
              { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
              [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "procedure-detail-body" }, [
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c(
                "MFormBuilder",
                {
                  ref: "baseForm",
                  attrs: {
                    "form-list": _vm.formList,
                    "form-data": _vm.baseFormData,
                    "label-position": "right"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      attrs: { slot: "serviceAddress" },
                      slot: "serviceAddress"
                    },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          size: "large",
                          placeholder: "请选择省/市/区",
                          options: _vm.pcaTextArr
                        },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.serviceAddress,
                          callback: function($$v) {
                            _vm.serviceAddress = $$v
                          },
                          expression: "serviceAddress"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm.isReform
          ? _c("div", { staticClass: "detail-card" }, [
              _c("div", { staticClass: "card-tit" }, [_vm._v("项目成员")]),
              _c(
                "div",
                { staticClass: "card-bot" },
                [
                  _c("MFormBuilder", {
                    ref: "userForm",
                    attrs: {
                      "form-list": _vm.usersFormList,
                      "form-data": _vm.userFormData,
                      "label-position": "right"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        ![4, 5].includes(_vm.baseFormData.content)
          ? _c("div", { staticClass: "detail-card" }, [
              _c("div", { staticClass: "card-tit" }, [_vm._v("关联设备")]),
              _c(
                "div",
                { staticClass: "card-bot" },
                [
                  _c("relationEquipment", {
                    attrs: {
                      "customer-id": _vm.customerId,
                      list: _vm.facilityMappingList
                    },
                    on: { facilityInfo: _vm.facilityInfo }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        ![4, 5].includes(_vm.baseFormData.content)
          ? _c("div", { staticClass: "detail-card" }, [
              _c("div", { staticClass: "card-tit" }, [_vm._v("关联工具")]),
              _c(
                "div",
                { staticClass: "card-bot" },
                [
                  _c("relationTool", {
                    ref: "trlationTool",
                    attrs: { toolList: _vm.detailToolList }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "order-footer" }, [
        _c("div", { staticClass: "footer-le" }),
        _vm.isReform
          ? _c(
              "div",
              { staticClass: "footer-ri" },
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.returns } },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.buttonLoading,
                      size: "small"
                    },
                    on: { click: _vm.submitReForm }
                  },
                  [_vm._v("确定")]
                )
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "footer-ri" },
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.returns } },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.buttonLoading,
                      size: "small"
                    },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v("保存")]
                ),
                _vm.params.type === "add"
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.submitForm(1)
                          }
                        }
                      },
                      [_vm._v("保存并派工 ")]
                    )
                  : _vm._e()
              ],
              1
            )
      ]),
      _c("DispatchUser", {
        attrs: {
          visible: _vm.showDispatch,
          "dispatch-type": "add",
          info: _vm.infos
        },
        on: {
          "update:visible": function($event) {
            _vm.showDispatch = $event
          },
          refrash: _vm.refrashList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }