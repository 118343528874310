<template>
    <div class="work-order-progress-container">
      <div class="top">
        <div>
          <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.projectCount, ['#fff', '#fff'])" style="width: 425px;height: 80px" /></div>
          <div class="card-bottom">
            <span>项目总数</span>
          </div>
        </div>
        <div>
          <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.workOrderCount, ['#fff', '#fff'])" style="width: 425px;height: 80px" /></div>
          <div class="card-bottom">
            <span >工单总数</span>
          </div>
        </div>
        <div>
          <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.delayWorkOrderCount, ['#fff', '#fff'])" style="width: 425px;height: 80px" /></div>
          <div class="card-bottom">
            <span>延期工单数</span>
          </div>
        </div>
        <div>
          <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.yesterdayReportWorkHours, ['#fff', '#fff'])" style="width: 425px;height: 80px" /></div>
          <div class="card-bottom">
            <span>昨日报工工时</span>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-popover
        ref="popover"
        v-model="showPopover"
        :width="207"
        :offset="20"
        @show="popoverShowEvent"
      >
        <div class="popover-content-top">
          <el-button @click="cancel">取消</el-button>
          <el-button @click="saveColumns">确定</el-button>
        </div>
        <div class="popover-content-center">
          <el-checkbox v-model="allChecked" @change="selectAllColumns">全选</el-checkbox>
          <el-button class="invert" type="text" @click="invertSelectColumns">
            <img src="@/assets/table_filter_images/fanxuan@2x.png" alt="反选" width="13px">
            <span>反选</span>
          </el-button>
        </div>
        <div class="popover-content-footer" :style="{maxHeight: popoverContentMaxHeight + 'px'}">
          <el-checkbox-group v-model="checkedList" class="check-wrapper">
            <draggable
              v-model="settingColumns"
              v-bind="dragOptions"
              @start="isDragging = true"
              @end="isDragging = false"
            >
              <el-checkbox v-for="item in settingColumns" :key="item.key" :label="item.prop">{{ item.label }}</el-checkbox>
            </draggable>
          </el-checkbox-group>
        </div>
      </el-popover>
      <div class="table-header">
        <img src="@/assets/screen/filter.png" class="img">
        <span ref="columnsSettingBtn" v-popover:popover>表头字段筛选</span>
      </div>
        <scroll-board :config="scrollBoardConfig" :style="{width:'100%', height: tableHeight + 'px'}">
          <div slot="reportProgress" slot-scope="{ index }">
          <el-progress :stroke-width="10" :percentage="tableData[index].reportProgress" style="width: 130px" />
        </div>
          <span slot="status" slot-scope="{ index }" class="status-board" :style="{ background: productionStatus[tableData[index].status +1].color }">{{ productionStatus[tableData[index].status +1 ].name }}</span>
          <template slot="progress" slot-scope="{ index }">
            <div>
              <div class="progress-line-wrapper" :style="{ position: 'relative', left: -getLeft(index) + 'px' }">
                <div
                  v-for="(item, index2) in allProcedureList(index)"
                  :key="index2"
                  class="progress-wrap wait-procedure"
                >
                  <custom-progress type="circle" :percentage="item.reportProgress" :color="'#607FFF'" :stroke-w="'#999'" :width="70" :show-text="false" />
                  <div class="progress-text"><span>{{ item.reportProgress }}%</span></div>
                  <div class="procedure-name ellipsis">{{ item.procedureName }}</div>
                </div>
              </div>
            </div>
          </template>
        </scroll-board>
      </div>
    </div>
  </template>

  <script>
  import mix from './mixin'
  import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
  import { baseUrl } from '@/views/statistics-board/board-detail/components/base-setting'
  import CustomProgress from '@/views/statistics-board/board-detail/components/custom-progress'
  import api from '@/api/statistics-board/all-statistics'
  import { columnsWorkOrderProgress } from './columns'
  import draggable from 'vuedraggable'
  const scrollBoardSettingDefault = {
    carousel: 'page',
    headerHeight: 32,
    hoverPause: false,
    waitTime: 10000,
    headerBGC: '#0F173A',
    oddRowBGC: '#0F173A',
    evenRowBGC: 'transparent',
    align: ['left', 'left', 'left', 'left', 'left','left', 'left', 'left', 'left','left']
  }
  export default {
    name: 'WorkOrderProgress',
    components: { draggable,CustomProgress, scrollBoard },
    mixins: [mix],
    data() {
      return {
        productionStatus: [
          {
            id: -1,
            name: '未开工',
            color: '#4B1E00',
            background: 'rgba(250, 100, 0, 0.1)'
          },
          {
            id: 0,
            name: '进行中',
            color: '#00371A',
            background: 'rgba(0, 171, 41, 0.1)'
          }
        ],
        digital: {
          projectCount: 0,
          workOrderCount: 0,
          delayWorkOrderCount: 0,
          yesterdayReportWorkHours: 0
        },
        tableHeight: 100,
        scrollBoardConfig: {
          ...scrollBoardSettingDefault,
          columnWidth: [200, 310, 200, 200, 200, 120, 200, 200, 200,2000],
          header: ['工单号', '项目号', '产品名称','产品编号','产品规格', '工单数量', '工单状态', '交期', '工单进度', '工序进度'],
          data: []
        },
        scrollBoardSettingDefault,
        tableData: [],
        settingColumns: columnsWorkOrderProgress,
        copySettingColumns: columnsWorkOrderProgress,
        checkedList: [],
        showPopover: false,
        allChecked: false,
        popoverContentMaxHeight: 200,
        isDragging: false,
        dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      keyWidth: [200, 310, 200, 200, 200, 120, 200, 200, 200,2000],
      keyName: ['workOrderNumber', 'projectNumber', 'materialsName',  'materialsCode', 'specifications', 'quantity', 'slot:status', 'deliveryTime','slot:reportProgress','slot:progress']
      }
    },
    computed: {
      memberCode() {
        return sessionStorage.getItem('memberCode')
      }
    },
    mounted() {
      this.reloadData()

    },
    methods: {
        popoverShowEvent() {
      // this.settingColumns = this._.cloneDeep(this.copySettingColumns)
      this.checkedList = this._.cloneDeep(this.keyName)
    },
    selectAllColumns(val) {
      this.checkedList = val ? this.settingColumns.map(item => item.prop) : []
    },
    cancel() {
      this.showPopover = false
      this.allChecked = false
      setTimeout(this.resetSettingFields, 500)
    },
    async saveColumns() {
      const newColumns = this.settingColumns.filter(item => this.checkedList.includes(item.prop))
      this.scrollBoardConfig.header = newColumns.map(item => item.label)
      this.keyName = newColumns.map(item => item.prop)
      this.keyWidth = newColumns.map(item => item.width)
      // this.keyWidth.pop()
      this.getTableData(baseUrl + 'workOrder/getWorkOrderBoardList', {
      areaIds:this.areaIds
      }, {
        key: 'scrollBoardConfig',
        key2: 'tableData',
        columns: this.scrollBoardConfig.header,
        columnWidth: this.keyWidth,
        rowNum: this.keyName.includes('slot:progress') ? 4 :7 ,
        // repeat: true,
        columnsKey: this.keyName
      })
      this.showPopover = false
    },
    invertSelectColumns() {
      this.checkedList = this.settingColumns
        .filter(item => this.checkedList.indexOf(item.prop) === -1)
        .map(item => item.prop)
    },
      allProcedureList(index) {
        if (this.tableData[index].procedureList) {
          return this.tableData[index].procedureList
        }
        return []
      },
      currentProcedure(index) {
        if (this.tableData[index].currentProcedure) {
          return JSON.parse(this.tableData[index].currentProcedure)[0].procedureUuid
        }
        return ''
      },
      isWaitProcedure(index, procedure) {
        return procedure
      },
      getLeft(index) {
        if (this.tableData[index].currentProcedure && this.tableData[index].allWorkOrderTaskList) {
          const currentProcedureId = JSON.parse(this.tableData[index].currentProcedure)[0].procedureUuid
          const currentProcedureIndex = this.tableData[index].allWorkOrderTaskList.findIndex(item => item.procedureUuid === currentProcedureId)
          return currentProcedureIndex > 5 ? (currentProcedureIndex - 5) * 162 : 0
        }
        return 0
      },
      reloadData(areaIds = '') {
        this.areaIds = areaIds
        this.getTableData(baseUrl + 'workOrder/getWorkOrderBoardList', {areaIds, statusList: '0^1' },
        {
        // repeat: true,
        key: 'scrollBoardConfig',
        key2: 'tableData',
        rowNum: 4,
        columns: this.scrollBoardConfig.header,
        columnWidth: this.keyWidth,
        columnsKey: this.keyName
      })
        this.workOrderQuality()
      },
      async workOrderQuality() {
        const res = await api.getTableData(baseUrl + 'workOrder/getWorkOrderBoardCount', {
          areaIds: this.areaIds
        })
        if (res) {
          this.digital = res
        }
      },
      getHeight() {
        const ww = window.innerWidth / 1920
        const wh = window.innerHeight / 1080
        const scale = ww < wh ? ww : wh
        this.tableHeight = (window.innerHeight * 0.66) / scale
      }
    }
  }
  </script>

  <style scoped lang="scss">
  @import "./common";
  .popover-content-top {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    button {
      padding: 8px 20px;
      border-radius: 4px;
      border-color: #DEDEEE;
      color: #8A8CA5;
      &:last-child {
        border-color: #607FFF;
        color: #607FFF;
      }
    }
  }
  .popover-content-center {
    width: calc(100% + 24px);
    position: relative;
    left: -12px;
    margin-top: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    background: #F8F8FB;
    height: 25px;
    button.invert {
      span {
        color: #8A8CA5;
      }
      img {
        position: relative;
        top: 2px;
        right: 10px;
      }
    }
  }
  .popover-content-footer {
    width: calc(100% + 24px);
    position: relative;
    left: -12px;
    max-height: 380px;
    overflow-y: auto;
    .el-checkbox-group {
      overflow: hidden;
      .el-checkbox {
        width: 100%;
        padding: 8px 8px 8px 28px;
        cursor: pointer;
        &:hover {
          background: #ECF0F4;
        }
      }
    }
  }
  .work-order-progress-container {
    padding: 0 20px 20px 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    .top {
      display: flex;
      justify-content: space-between;
      >div {
        width: 425px;
        border-radius: 4px;
        .card-top {
          display: flex;
          justify-content: center;
        }
        .card-bottom {
          padding: 14px;
          background: rgba(0, 0, 0, 0.29);
          text-align: center;
          span {
            font-size: 23px;
            color: #FFFFFF;
          }
        }
        &:first-child {
          background: #1C2BAB;
        }
        &:nth-child(2) {
          background: #1A8F74;
        }
        &:nth-child(3) {
          background: #393D60;
        }
        &:last-child {
          background: #1A5F8F;
        }
      }
    }
    .bottom {
      margin-top: 23px;
      .table-header {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3AB2FF;
        line-height: 28px;
        margin-bottom: 18px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        .img {
            width: 28px;
            height: 24px;
            display: block;
            margin-right: 8px;
        }
    }
      .progress-line-wrapper {
        white-space: nowrap;
        .progress-wrap {
          width: 120px;
          display: inline-flex;
          position: relative;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          ::v-deep .el-progress {
            position: relative;
            top: 1px;
          }
          .progress-text {
            position: absolute;
            top: 1px;
            left: 0;
            right: 0;
            bottom: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              white-space: nowrap;
              transform: scale(.8);
              color: #607FFF;
            }
          }
          &.active-procedure {
            border: 2px solid #607FFF;
            background: #0F2067;
            .procedure-name {
              color: #FFFFFF;
            }
            .progress-text {
              span {
                color: #FFFFFF;
              }
            }
          }
          &:not(:last-child) {
            &::after {
              content: "";
              display: inline-block;
              height: 1px;
              background: #2E4398;
              width: 50px;
              position: absolute;
              top: 39%;
              left: 95px;
            }
          }
          &.wait-procedure {
            .procedure-name {
              color: #999999 !important;
            }
            .progress-text {
              span {
                color: #999999 !important;
              }
            }
            &:not(:last-child) {
              &::after {
                content: "";
                display: inline-block;
                height: 1px;
                background: #999;
                width: 50px;
                position: absolute;
                top: 39%;
                left: 95px;
              }
            }
          }
        }
        .procedure-name {
          width: 100%;
          font-size: 14px !important;
          text-align: center;
          position: relative;
          top: 1px;
          color: #607FFF;
        }
      }
    }
  }
  ::v-deep .dv-scroll-board .ceil {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    >span {
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .status-board {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 10px;
  }
  ::v-deep .dv-scroll-board .row-item{
    display: flex;
    flex-wrap:wrap;
    align-items: center;
  }

  </style>
