import api from '@/api/statistic-statement/index'
import dayjs from 'dayjs'
import { colorList } from './base-setting'
export default {
  computed: {
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  data() {
    return {
      barChartSettings: {},
      barChartExtend: {
        color: [],
        legend: {
          show: false
        },
        xAxis: {},
        yAxis: {},
        tooltip: { confine: true },
        textStyle: { color: '#fff' },
        grid: { height: '80%', top: 40 },
        series: {
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(255, 255, 255, 0.18)'
          },
          barWidth: 28,
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'rgba(84, 203, 164, 1)' // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(45, 45, 189, 1)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        }
      },
      pieChartSettings: {},
      pieChartExtend: {
        color: colorList,
        legend: {
          itemWidth: 60,
          itemHeight: 25,
          itemGap: 20,
          top: 'center',
          left: '40%',
          show: true,
          orient: 'vertical',
          textStyle: {
            color: '#fff',
            left: 30,
            overflow: 'break',
            rich: {
              a: {
                fontSize: 18,
                width: 120
              }
            }
          }
        },
        series: {
          height: '100%',
          width: '100%',
          radius: ['55%', '85%'],
          center: ['20%', '50%'],
          label: {
            position: 'center',
            formatter: '{a|总数/单}\n{b|2453}',
            rich: {
              a: {
                fontSize: 18,
                lineHeight: 20,
                fontWeight: 'bold',
                color: '#FFF'
              },
              b: {
                fontSize: 40,
                lineHeight: 50,
                fontWeight: 'bold',
                color: '#FFF',
                letterSpacing: 2
              }
            },
            top: '45%',
            left: '50%',
            textAlign: 'center'
          }
        },
        tooltip: {
          trigger: 'item'
          // appendToBody: true
        }
      },
      lineChartSettings: {},
      lineChartExtend: {
        color: ['#607FFF'],
        legend: { show: false },
        xAxis: { boundaryGap: false },
        yAxis: {},
        tooltip: { trigger: 'axis' },
        textStyle: { color: '#fff' },
        grid: { width: '94%', height: '80%', top: 40 },
        series: {
          smooth: false,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#607FFF' // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(96, 127, 255, 0)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        }
      },
      areaIds: ''
    }
  },
  methods: {
    getDigitalConfig(number, color, content = '{nt}') {
      const isDot = number.toString().split('.')
      const fixNumber = isDot[1] ? isDot[1].length > 2 ? 2 : isDot[1].length : 0
      return {
        number: [Number(number)],
        content,
        toFixed: fixNumber,
        formatter: this.formatter,
        style: {
          fontSize: 38,
          gradientColor: color,
          gradientType: 'linear',
          gradientParams: [0, 25, 0, 50],
          gradientWith: 'fill'
        }
      }
    },
    formatter(number) {
      const isDot = number.toString().split('.')
      const numbers = isDot[1] ? isDot[0].split('').reverse() : number.toString().split('').reverse()
      const segs = []
      while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
      const newNumber = segs.join(',').split('').reverse().join('')
      return isDot[1] ? newNumber + '.' + isDot[1] : newNumber
    },
    async getTableData(url, condition, tableInfo, isAppApi = false) {
      this.getHeight()
      if (tableInfo.repeat) this.getTableDataPage(url, condition, tableInfo)
      const http = isAppApi ? api.getTableDataApp : api.getTableData
      const res = await http(url, {
        ...condition,
        day: dayjs().format('YYYY-MM-DD')
      })
      if (res) {
        this.setTableData(res, tableInfo)
      }
    },
    async getTablePutData(url, condition, tableInfo, type) {
      this.getHeight()
      if (tableInfo.repeat) this.getTableDataPage(url, condition, tableInfo)
      const res = await api.getTablePutData(url, {
        ...condition,
        day: dayjs().format('YYYY-MM-DD')
      })
      if (res) {
        if (+type === 1) {
          this.setTableData(res, tableInfo)
        } else {
          this[tableInfo.key2 + 'Total'] = res.count
          this.setTableData(res.listByStatus, tableInfo)
        }
      }
    },
    async getTableDataPage(url, condition, tableInfo) {
      const res = await api.getTableData(url + 'Page', {
        page: 1,
        limit: tableInfo.rowNum || 7,
        ...condition,
        day: dayjs().format('YYYY-MM-DD')
      })
      if (res) {
        this[tableInfo.key2 + 'Total'] = res.total
        this.setTableData(res.records, tableInfo)
      }
    },
    setTableData(res, tableInfo) {
      const { columnsKey, columnWidth, key, key2, rowNum = 7 } = tableInfo
      this[key2] = res[0] && res[0].totalList ? res[0].totalList : res
      this[key] = {
        ...this.scrollBoardSettingDefault,
        header: this[key].header,
        rowNum,
        columnWidth,
        delayIndex: [],
        data: this[key2].map((item, index) => (columnsKey.map((c, i) => {
          if (c.indexOf('slot:') !== -1) {
            const slots = c.split(':')
            return `${slots[1]}${slots[2] || ''}|${index}`
          }
          if (c === 'index') {
            return index + 1
          }
          return item[c]
        })))
      }
    }
  }
}
