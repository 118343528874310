var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-container" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "t-top" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "card-top" },
              [
                _c("dv-digital-flop", {
                  staticStyle: { width: "223px", height: "80px" },
                  attrs: {
                    config: _vm.getDigitalConfig(_vm.digital.dayQuality, [
                      "#A2FFC9",
                      "#009A25"
                    ])
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "card-bottom" }, [
              _c("img", {
                attrs: {
                  src: "https://oss.mubyte.cn/static/%E6%97%A5%402x.png",
                  alt: ""
                }
              }),
              _vm.memberCode === "bbelc"
                ? _c("span", [_vm._v("当天总装配报工数量")])
                : _c("span", [_vm._v("当日产量")])
            ])
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "card-top" },
              [
                _c("dv-digital-flop", {
                  staticStyle: { width: "223px", height: "80px" },
                  attrs: {
                    config: _vm.getDigitalConfig(_vm.digital.weekQuality, [
                      "#B9FFFD",
                      "#009186"
                    ])
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "card-bottom" }, [
              _c("img", {
                attrs: {
                  src: "https://oss.mubyte.cn/static/%E5%91%A8%402x.png",
                  alt: ""
                }
              }),
              _vm.memberCode === "bbelc"
                ? _c("span", [_vm._v("当月总装配报工数量")])
                : _c("span", [_vm._v("当周产量")])
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "t-bottom" },
          [
            _c(
              "div",
              {
                staticClass: "gradient-title",
                staticStyle: {
                  background:
                    "linear-gradient(90deg, #00751F 0%, rgba(96, 127, 255, 0) 100%)"
                }
              },
              [_vm._v("7日内产量趋势图")]
            ),
            _c("ve-histogram", {
              attrs: {
                height: "300px",
                data: _vm.productionChartData,
                settings: _vm.barChartSettings,
                extend: _vm.barChartExtend
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "center" }, [
        _c("div", { staticClass: "t-top" }, [
          _c("div", { staticClass: "work-order-left" }, [
            _vm._v("本周计划完成工单")
          ]),
          _c("div", { staticClass: "work-order-right" }, [
            _c("div", [
              _c("span", [_vm._v("工单数")]),
              _c("span", [
                _c("span", [_vm._v(_vm._s(_vm.digital.finishedCount))]),
                _c("span", [_vm._v("/")]),
                _vm._v(_vm._s(_vm.digital.workOrderCount))
              ])
            ]),
            _c("div", [
              _c("span", [_vm._v("产品量")]),
              _c("span", [
                _c("span", [_vm._v(_vm._s(_vm.digital.finishedQuantity))]),
                _c("span", [_vm._v("/")]),
                _vm._v(_vm._s(_vm.digital.workOrderQuantity))
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "t-bottom" }, [
          _c(
            "div",
            { staticClass: "work-order-delay" },
            [
              _c(
                "div",
                {
                  staticClass: "gradient-title",
                  staticStyle: {
                    background:
                      "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
                  }
                },
                [_vm._v("工单到期情况")]
              ),
              _c("ve-pie", {
                attrs: {
                  height: "300px",
                  settings: _vm.pieChartSettings,
                  extend: _vm.pieChartExtend,
                  data: _vm.workOrderDelayChartData
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "plan-finish-seven" },
            [
              _c(
                "div",
                {
                  staticClass: "gradient-title",
                  staticStyle: {
                    background:
                      "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
                  }
                },
                [_vm._v("7日内计划达成情况")]
              ),
              _c("ve-line", {
                attrs: {
                  height: "300px",
                  data: _vm.planFinishChartData,
                  settings: _vm.lineChartSettings,
                  extend: _vm.lineChartExtend
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "t-top" }, [
          _c("div", { staticClass: "work-order-left" }, [
            _vm._v("本周新增返修工单")
          ]),
          _c("div", { staticClass: "work-order-right" }, [
            _c("div", [
              _c("span", [_vm._v("工单数")]),
              _c("span", [
                _c("span", [_vm._v(_vm._s(_vm.digital.finishedCountRepair))]),
                _c("span", [_vm._v("/")]),
                _vm._v(_vm._s(_vm.digital.workOrderCountRepair))
              ])
            ]),
            _c("div", [
              _c("span", [_vm._v("产品量")]),
              _c("span", [
                _c("span", [
                  _vm._v(_vm._s(_vm.digital.finishedQuantityRepair))
                ]),
                _c("span", [_vm._v("/")]),
                _vm._v(_vm._s(_vm.digital.workOrderQuantityRepair))
              ])
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "t-bottom" },
          [
            _c(
              "div",
              {
                staticClass: "gradient-title",
                staticStyle: {
                  background:
                    "linear-gradient(90deg, #0E8BAF 0%, rgba(96, 127, 255, 0) 100%)"
                }
              },
              [_vm._v("本周生产异常类型分布")]
            ),
            _c("ve-pie", {
              attrs: {
                height: "300px",
                settings: _vm.pieChartSettings2,
                extend: _vm.pieChartExtend2,
                data: _vm.errorTypeChartData
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "bottom" }, [
      _c(
        "div",
        { staticClass: "product-task" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #00751F 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [
              _vm._v("生产任务"),
              _c("span", [
                _vm._v("（" + _vm._s(_vm.productTaskDataTotal) + "）")
              ])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig },
            scopedSlots: _vm._u([
              {
                key: "currentProcedure",
                fn: function(ref) {
                  var index = ref.index
                  return _c("span", {}, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.productTaskData[index].produceWorkOrderTaskList
                            ? _vm.productTaskData[index]
                                .produceWorkOrderTaskList[0].procedureName
                            : ""
                        ) +
                        " "
                    )
                  ])
                }
              },
              {
                key: "priority",
                fn: function(ref) {
                  var index = ref.index
                  return _c("span", {}, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.priorityStatus[
                            _vm.productTaskData[index].priority
                          ].name
                            ? _vm.priorityStatus[
                                _vm.productTaskData[index].priority
                              ].name
                            : "普通"
                        ) +
                        " "
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "error-task" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #0E8BAF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [
              _vm._v("异常任务"),
              _c("span", [_vm._v("（" + _vm._s(_vm.errTaskData.length) + "）")])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig2 },
            scopedSlots: _vm._u([
              {
                key: "emergencyDegree",
                fn: function(ref) {
                  var index = ref.index
                  return _c("span", {}, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.errTaskData[index].emergencyDegree
                            ? "普通"
                            : "紧急"
                        ) +
                        " "
                    )
                  ])
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var index = ref.index
                  return _c(
                    "span",
                    {
                      staticClass: "status-board",
                      style: {
                        background:
                          _vm.statusList[_vm.errTaskData[index].status].color
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.statusList[_vm.errTaskData[index].status].name
                        )
                      )
                    ]
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }