var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "board-manage-container" }, [
    _c("div", { staticClass: "left" }, [
      _c(
        "div",
        { staticClass: "top", staticStyle: { "word-break": "keep-all" } },
        [_vm._v("看板组")]
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _vm._l(_vm.childrenRouters, function(menu) {
            return [
              _vm.hasAuth(menu)
                ? _c(
                    "div",
                    {
                      key: menu.name,
                      staticClass: "menu-item",
                      class: {
                        active:
                          menu.name === _vm.currentRouterName &&
                          menu.name !== "customStatisticsCard"
                      },
                      staticStyle: { position: "relative" },
                      on: {
                        click: function($event) {
                          return _vm.goPath(menu)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "12px", "margin-right": "5px" },
                        attrs: { src: menu.meta.icon }
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            "word-break": "keep-all"
                          }
                        },
                        [_vm._v(_vm._s(menu.meta.title))]
                      ),
                      menu.name === "customStatisticsCard"
                        ? _c("add-statistics-card", {
                            attrs: {
                              "type-change": _vm.typeChange,
                              "custom-statistics-cards-info":
                                _vm.customStatisticsCardsInfo
                            },
                            on: {
                              updateStatisticsCards: function($event) {
                                return _vm.getCustomStatisticsCards("add")
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              menu.name === "allBoardGroup"
                ? [
                    _vm._l(menu.meta.buttons(), function(child) {
                      return [
                        _vm.permission(child.name, false) && child.index < 6
                          ? _c(
                              "div",
                              {
                                key: child.name,
                                staticClass: "menu-item",
                                on: {
                                  click: function($event) {
                                    return _vm.goNewPath(child)
                                  }
                                }
                              },
                              [
                                _c("img", { attrs: { src: "", alt: "" } }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      display: "inline-block",
                                      "word-break": "keep-all",
                                      color: "#8A8CA5",
                                      "padding-left": "25px"
                                    }
                                  },
                                  [_vm._v(_vm._s(child.title))]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    })
                  ]
                : _vm._e()
            ]
          })
        ],
        2
      )
    ]),
    _c("div", { staticClass: "right" }, [
      _vm._m(0),
      _c("div", { staticClass: "bottom" }, [_c("board-group")], 1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("span", [_vm._v("看板组卡片")]),
      _c("span")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }