<template>
  <div class="container" id="qwer">

    <iframe src="http://dev.mubyte.cn:8100/#/de-link/3AU95Q0U" 
    scrolling="auto" frameborder="0" class="trend-container2" id="iframe" 
    :style="{width:width,marginLeft:marginLeft}">
    </iframe>
<!-- 
    <iframe src="http://192.168.0.80:8080/#/de-link/3AU95Q0U" 
    scrolling="auto" frameborder="0" class="trend-container2" id="iframe" 
    :style="{width:width,marginLeft:marginLeft}">
    </iframe> -->

<!-- 
    <iframe src="https://demo.dataease.cn/#/de-link/QqFk543c" 
    scrolling="auto" frameborder="0" class="trend-container2" id="iframe" 
    :style="{width:width,marginLeft:marginLeft}">
    </iframe> -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      width: '90%',
      marginLeft: '5%'
    }
  },
  mounted() {
    this.fullAllScreen()
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.launchFullscreen)
    document.removeEventListener("fullscreenchange", this.changeWidth)
    document.removeEventListener("mozfullscreenchange", this.changeWidth)
    document.removeEventListener("webkitfullscreenchange", this.changeWidth)
    document.removeEventListener("msfullscreenchange", this.changeWidth)
  },
  methods: {
    fullAllScreen() {
      document.body.addEventListener('click', this.launchFullscreen)
      document.addEventListener("fullscreenchange", this.changeWidth)
      document.addEventListener("mozfullscreenchange", this.changeWidth)
      document.addEventListener("webkitfullscreenchange", this.changeWidth)
      document.addEventListener("msfullscreenchange", this.changeWidth)
    },
    changeWidth(){
      if(this.width === '90%'){
        this.width = "100%"
        this.marginLeft ="0"
      }else{
        this.width = '90%',
        this.marginLeft = '5%'
      }
    },
    launchFullscreen() {
      // this.width = "100%"
      // this.marginLeft = "0"
      const element = document.documentElement
      if (element.requestFullscreencreen) {
        element.requestFullScreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen()
      } else if (element.msRequestFullScreen) {
        element.msRequestFullScreen()
      }
    }
  }
}
</script>

<style scoped>
.container{
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 0;
    overflow: hidden;
}
#iframe{
  height:100%;
  box-sizing:border-box;
}
</style>