<template>
  <div class="custom-table-container">
    <chart-search-condition :tab-info="tabInfo" @conditionChange="searchConditionChange" />
    <div style="width: 100%;display: flex;flex-direction: row;">
      <div style="width: 200px;padding: 8px;border: 1px solid #EBEEF5;border-radius: 4px;margin-right: 10px;display: flex;align-items: center">
        <div>
          <div>工单码：{{ currentWorkOrder.produceWorkOrderNumber }}</div>
          <div style="padding-top: 5px">物料编码：{{ currentWorkOrder.materialsCode }}</div>
          <div style="padding-top: 5px">物料名称：{{ currentWorkOrder.materialsName }}</div>
          <div style="padding-top: 5px">规格：{{ currentWorkOrder.materialsSpecifications }}</div>
        </div>
      </div>
      <div style="flex: 1;height: 100%">
        <MTable
          ref="mTable"
          :columns="newColumns"
          :max-height="300"
          :show-page="false"
          :set-data-method="getTableData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChartSearchCondition from '@/views/statistics-board/board-manage/components/charts/chart-search-condition'
import api from '@/api/statistics-board/all-statistics'
export default {
  name: 'WorkTaskPass',
  components: { ChartSearchCondition },
  props: {
    tabInfo: {
      required: true,
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      newColumns: [],
      searchCondition: {},
      currentWorkOrder: {}
    }
  },
  beforeMount() {
    this.newColumns = this.tabInfo.columns
  },
  methods: {
    async getTableData(condition, callback) {
      if (!this.tabInfo.dataUrl) {
        return callback()
      }
      const { search = [] } = condition
      const formatSearch = {}
      const { preDataUrl, preDataCondition, preDataKey, dataUrl, dataCondition } = this.tabInfo
      search.forEach(item => {
        formatSearch[item.fieldName] = item.fieldValue
      })
      let preData
      const newCondition = {}
      if (preDataUrl && !Object.keys(this.searchCondition).length) {
        preData = await api.getTableData(preDataUrl, preDataCondition)
        if (preData) {
          const data = Array.isArray(preData) ? preData : preData.records
          newCondition[preDataKey] = data.map(item => item.id).join('^')
        }
      }
      if (!preData || newCondition[preDataKey]) {
        const res = await api.getTableData(dataUrl, { ...dataCondition, ...this.searchCondition, ...newCondition, ...formatSearch })
        if (res) {
          this.currentWorkOrder = res.info
          callback({
            total: res.procedureRatioList ? res.procedureRatioList.length : 0,
            content: res.procedureRatioList ? res.procedureRatioList.map(item => ({
              name: item.info.name,
              radio: item.okRatio
            })) : []
          })
        }
      }
      callback()
    },
    searchConditionChange(condition) {
      if (!condition.produceWorkOrderId) return this.$message.info('请选择工单')
      this.searchCondition = condition
      this.$refs.mTable.setTableData()
    }
  }
}
</script>

<style scoped lang="scss">
.custom-table-container {
  position: relative;
}
</style>
