export const Organization = [
  {
    prop: 'name',
    label: '组织名称',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      tagName: 'el-input',
      colSpan: 24
    }
  },
  {
    prop: 'typeName',
    label: '组织类型',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 2,
      tagName: 'el-select',
      key: 'typeId',
      options: [],
      colSpan: 24
    }
  },
  {
    prop: 'userName',
    label: '负责人',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      tagName: 'el-select',
      key: 'userId',
      required: false,
      options: [],
      colSpan: 24,
      innerValue: (val) => {
        return `${val['jobNumber']} - ${val['name']}`
      }
    }
  },
  {
    prop: 'count',
    label: '员工数',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]

export const OrganizationType = [
  {
    prop: 'name',
    label: '组织类型',
    sortable: false,
    hiddenSearch: true,
    form: { index: 1, tagName: 'el-input', colSpan: 24 }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    slotName: 'remark',
    form: {
      index: 2,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' },
      colSpan: 24
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 40
  }
]
