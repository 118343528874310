<template>
  <div class="modify-password-wrapper">
    <div class="form-build-wrapper">
      <MFormBuilder
        ref="formBuild"
        :form-data="formData"
        :form-list="formList"
        label-position="left"
      />
      <el-button type="primary" class="save-button" @click="submitForm">保存</el-button>
    </div>
  </div>
</template>

<script>
import user from '@/api/user'
import { Encrypt } from '@/utils/sercet'
import {  removeToken } from '@/utils/auth'

export default {
  name: 'ModifyPassword',
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新的登录密码'))
      } else if (value !== this.$refs.formBuild.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      formData: {},
      formList: [
        {
          tagName: 'el-input',
          colSpan: 24,
          label: '原密码：',
          required: true,
          attrs: {
            type: 'password',
            placeholder: '请输入原登录密码'
          },
          key: 'password'
        },
        {
          tagName: 'el-input',
          label: '新密码：',
          colSpan: 24,
          required: true,
          attrs: {
            type: 'password',
            placeholder: '请输入新的登录密码'
          },
          key: 'newPassword'
        },
        {
          tagName: 'el-input',
          label: '新密码：',
          required: true,
          rules: [{ validator: validatePass2, trigger: 'blur' }],
          colSpan: 24,
          attrs: {
            type: 'password',
            placeholder: '请再次输入新的登录密码'
          },
          key: 'newPasswordCopy'
        }
      ]
    }
  },
  methods: {
    async submitForm() {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        if (valid) {
          const res = await user.modifyPassword({
            id: userInfo.id,
            password: Encrypt(form.password),
            newPassword: Encrypt(form.newPasswordCopy)
          })
          if (res) {
            let second = 3
            const timer = setInterval(() => {
              second -= 1
              const secondLogout = document.querySelector('#secondLogout')
              secondLogout.innerHTML = second
              if (second === 0) {
                clearInterval(timer)
                sessionStorage.clear()
                removeToken() 
                this.$router.push({ name: 'login' })
              }
            }, 1000)
            this.$message.success({
              dangerouslyUseHTMLString: true,
              duration: second * 1000,
              message: `<span>密码修改成功,<span id="secondLogout">${second}</span>秒后将自动退出</span>`
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.modify-password-wrapper {
  .form-build-wrapper {
    width: 335px;
  }

  .save-button {
    background: #607FFF;
    float: right;
    width: 88px;
  }
}
</style>
