import { columns, equipmentColumns } from './columns'
import { transformColumnsToForm } from '@/utils'

export const formList = transformColumnsToForm(columns)
export const equipmentFormList = transformColumnsToForm(equipmentColumns)

export const searchFormList = [
  {
    key: 'customerName',
    tagName: 'el-cascader',
    colSpan: 8,
    props: {
      placeholder: '客户/现场',
      props: {
        label: 'name',
        value: 'id',
        checkStrictly: true
      },
      options: [],
      collapseTags: true,
      filterable: true
    }
  }
]
