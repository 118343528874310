<template>
  <div class="detail-wrappers">
    <div class="detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
    </div>
    <div class="detail-body">
      <div class="detail-card">
        <div class="card-tit">基础信息</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in getColumns" :key="item.id" :span="6">
              <div v-if="item.prop === 'customerSiteIdList'">
                <div class="view-list__label">{{ item.label }}</div>
                <div class="view-list__value">{{ baseFormData['customerSiteIdNames'] || '-' }}</div>
              </div>
              <div v-else>
                <div class="view-list__label">{{ item.label }}</div>
                <div class="view-list__value">{{ getValue(item) || '-' }}</div>
              </div>
            </el-col>
          </el-row>
          <MFormBuilder v-else ref="baseForm" :form-list="getFormList" :form-data="baseFormData" label-position="right">
            <div slot="workOrderCode">
              {{ baseFormData.workOrderCode }}
            </div>
            <div slot="demandCode">
              {{ baseFormData.demandCode }}
            </div>
            <div slot="workOrderType">
              {{ baseFormData.workOrderTypeName }}
            </div>
          </MFormBuilder>
        </div>
      </div>

      <div class="detail-card" v-if="params.content != 7">
        <div class="card-tit">服务描述</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in descFormList" :key="item.id" :span="24">
              <div class="view-list__label">{{ item.label }}</div>
              <div v-if="item.key !== 'facilityRepairHistoryList'" class="view-list__value">{{ getDescValue(item) || '-' }}</div>
            </el-col>
          </el-row>

          <MFormBuilder v-else ref="descForm" :form-list="descFormList" :form-data="baseFormData.facilityRepairService" label-position="right" />

          <div class="desc-table" :class="{ edit: !isView }">
            <MTable ref="descTable" :show-page="false" :height="202" :columns="descColumns" :data="descTableList" :columns-setting="false">
              <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

              <div v-if="isView" slot="faultTime" slot-scope="{ row }">
                <div>{{ row.faultTime }}</div>
              </div>
              <div v-else slot="faultTime" slot-scope="{ row }" class="table-cell">
                <span class="required-star">*</span>
                <el-date-picker
                  v-model="row.faultTime"
                  type="datetime"
                  placeholder="故障时间"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                />
              </div>

              <div v-if="isView" slot="faultCode" slot-scope="{ row }">
                <div>{{ row.faultCode }}</div>
              </div>
              <div v-else slot="faultCode" slot-scope="{ row }" class="table-cell">
                <span class="required-star">*</span>
                <el-input v-model="row.faultCode" placeholder="输入故障码" />
              </div>

              <div v-if="isView" slot="detailedDeion" slot-scope="{ row }">
                <div>{{ row.detailedDeion }}</div>
              </div>
              <div v-else slot="detailedDeion" slot-scope="{ row }" class="table-cell">
                <el-input v-model="row.detailedDeion" placeholder="输入详细描述" />
              </div>

              <div slot="action" slot-scope="{ $index }">
                <template>
                  <el-button type="text" @click="delTableList($index)">移除</el-button>
                </template>
              </div>
            </MTable>
            <el-button v-if="!isView" size="small" type="primary" class="e-btn" @click="addTableList">添加</el-button>
          </div>
        </div>
      </div>

      <div class="detail-card" v-if="params.content != 7">
        <div class="card-tit">备件使用</div>
        <div class="card-bot">
          <MTable ref="descTable" :show-page="false" :height="212" :columns="marterialColumns" :data="marterialTableList" :columns-setting="false">
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

            <div v-if="isView" slot="usageQuantity" slot-scope="{ row }">
              <div>{{ row.usageQuantity }}</div>
            </div>
            <div v-else slot="usageQuantity" slot-scope="{ row }" class="table-cell">
              <span class="required-star">*</span>
              <el-input-number v-model="row.usageQuantity" :min="1" controls-position="right" :step="1" />
            </div>
            <div slot="quantity" slot-scope="{ row }">{{ row.quantity || '-' }}</div>
            <div slot="materialsType" slot-scope="{ row }">{{ row.materialsType || '-' }}</div>
            <div slot="materialsSpecifications" slot-scope="{ row }">{{ row.materialsSpecifications || '-' }}</div>
            <div slot="batchNumber" slot-scope="{ row }">{{ row.batchNumber || '-' }}</div>
            <div slot="sequenceNumber" slot-scope="{ row }">{{ row.sequenceNumber || '-' }}</div>
            <div slot="warehouseName" slot-scope="{ row }">{{ row.warehouseName || '-' }}</div>
            <div slot="warehouseLocationName" slot-scope="{ row }">{{ row.warehouseLocationName || '-' }}</div>
            <div v-if="isView" slot="ownershipSparePartsAssets" slot-scope="{ row }">
              <div>{{ getSparePartsDesc(row.ownershipSparePartsAssets) }}</div>
            </div>
            <div v-else slot="ownershipSparePartsAssets" slot-scope="{ row }" class="table-cell">
              <el-select v-model="row.ownershipSparePartsAssets">
                <el-option v-for="item in ownershipOption" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>

            <div slot="action" slot-scope="{ $index }">
              <template>
                <el-button type="text" @click="delMaterial($index)">移除</el-button>
              </template>
            </div>
          </MTable>
          <el-button v-if="!isView" size="small" type="primary" class="e-btn" @click="onSelectMaterialsInventory">使用仓库中物料</el-button>
          <el-button v-if="!isView" size="small" type="primary" class="e-btn" @click="onSelectMaterials">使用其他物料</el-button>
        </div>
      </div>

      <!-- 针对类型为 ‘更换’  的需求  新增工单需要这部分信息 -->
      <div class="detail-card" v-if="params.content == 7">
        <div class="card-tit">售后记录</div>
        <div class="card-bot">
          <el-form :model="baseFormData" class="view-list" :inline="true" :gutter="24" label-position="right" :rules="rules">
            <el-col :span="6">
              <el-form-item label="类型" prop="changeType">
                <el-select v-model="baseFormData.changeType" placeholder="请选择更换类型" :disabled="params.type=='view'" @change="typeChange">
                  <el-option label="换上换下" value="2"></el-option>
                  <el-option label="换上" value="0"></el-option>
                  <el-option label="换下" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="baseFormData.changeType == '0' || baseFormData.changeType == '2'
            || baseFormData.changeType == '换上' || baseFormData.changeType == '换上换下'">
              <el-form-item label="换上设备" prop="upFacilityId">
                <el-select v-model="baseFormData.upFacilityId" filterable placeholder="请选择换上设备" :disabled="!baseFormData.changeType || params.type=='view'">
                  <el-option v-for="op in upEqu" :label="op.name" :value="op.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6" v-if="baseFormData.changeType == '1' || baseFormData.changeType == '2'
            || baseFormData.changeType == '换下' || baseFormData.changeType == '换上换下'">
              <el-form-item label="换下设备" prop="facilityId">
                <el-select v-model="baseFormData.facilityId" filterable placeholder="请选择换下设备" :disabled="!baseFormData.changeType || params.type=='view'">
                  <el-option v-for="op in downEqu" :label="op.name" :value="op.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="工作记录" prop="changeWorkRecord">
                <el-input :disabled="params.type=='view'" v-model="baseFormData.changeWorkRecord" style="min-width: 150px"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">现场记录</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in localFormList" :key="item.id" :span="item.colSpan">
              <div class="view-list__label">{{ item.label }}</div>
              <el-upload v-if="item.key === 'affix'" :file-list="attachments" v-bind="uploadProps2" disabled :class="{ 'upload-view': isView }" />
              <div v-else-if="item.key === 'facilityServiceTimeMappingList'">
                <!-- <el-card class="box-card" > -->
                <el-row v-for="item in executorList" :key="item.id" class="view-list" :gutter="24">
                  <el-col :span="6">
                    <!-- <div class="view-list__label">协作人</div> -->
                    <div class="view-list__value">{{ item.userNames || '-' }}</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="view-list__label">服务时间</div>
                    <div class="view-list__value">{{ `${item.startTime} ~ ${item.endTime}` }}</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="view-list__label">服务时长</div>
                    <div class="view-list__value">{{ item.manHour || 0 }}小时</div>
                  </el-col>
                </el-row>
                <!-- </el-card> -->
              </div>
              <div v-else class="view-list__value">
                {{ getLocalValue(item) || '-' }}
              </div>
            </el-col>
          </el-row>
          <MFormBuilder v-else ref="localForm" :form-list="localFormList" :form-data="baseFormData.facilityRepairSite" label-position="right">
            <div slot="manHour">{{ manHour }}小时</div>
            <div slot="operatorNames">{{ baseFormData.operatorNames }}</div>

            <div slot="facilityServiceTimeMappingList">
              <div v-for="(item, index) in executorList" :key="index" class="custom-form">
                <div class="custom-form-item">
                  <el-select v-model="item.userIds" multiple placeholder="添加协作人" filterable>
                    <el-option v-for="q in executorUserList" :key="q.id" :label="q.name" :value="q.id" />
                  </el-select>
                </div>
                <div class="custom-form-item">
                  <el-radio-group v-model="item.isSame" @change="onRadioChange(item.isSame, index)">
                    <el-radio :label="1">服务时间同执行人</el-radio>
                    <el-radio :label="0">
                      <el-date-picker
                        v-model="item.date"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="选择服务开始时间"
                        end-placeholder="选择服务结束时间"
                        value-format="yyyy-MM-dd HH:mm"
                        format="yyyy-MM-dd HH:mm"
                        clearable
                        :disabled="item.isSame === 1"
                        :picker-options="pickerOptions"
                        @change="datePickerChange(index)"
                      />
                    </el-radio>
                  </el-radio-group>
                  <span class="hour">
                    <label>服务时长</label>
                    {{ getTime(item) }}小时
                  </span>
                  <el-button type="text" @click="delExecutorList(index)">删除</el-button>
                </div>
              </div>
              <el-button size="small" type="primary" @click="addExecutor">添加</el-button>
            </div>
            <el-upload slot="drawing" :file-list="attachments" v-bind="uploadProps2" :class="{ 'upload-view': isView }">
              <el-button type="primary" size="small">上传文件</el-button>
            </el-upload>
          </MFormBuilder>
        </div>
      </div>
    </div>
    <div v-if="!isView" class="detail-footer">
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">确定</el-button>
      </div>
    </div>

    <select-materials
      ref="selectMaterials"
      :visible.sync="showMaterials"
      :single="false"
      :is-add="true"
      :select-materials="selectMaterials"
      @submitForm="submitMaterials"
      @addMaterial="addMaterial"
    />
    <!-- 新增物料 -->
    <edit-materials ref="editMaterials" @onSuccess="onSuccess" />
    <!-- 物料库存 -->
    <select-materials-inventory
      ref="selectMaterialInventory"
      :visible.sync="showMaterialInventory"
      :operate-type="3"
      :consumer-id="consumerId"
      @submitForm="submitMaterialInventory"
    />
  </div>
</template>

<script>
import api from '@/api/xiewei/service/repairs'
import dayjs from 'dayjs'
import userApi from '@/api/sets/user/user'
import keApi from '@/api/information/unit'
import demandApi from '@/api/xiewei/service/demand-center'
import fieldApi from '@/api/xiewei/field/field'
import facilityListApi from '@/api/xiewei/facility/facility'
import { Encrypt } from '@/utils/sercet'
import { getUUid, setTreeData, nameChangeForBB } from '@/utils'
import { columns, descColumns, marterialColumns, getFunctionList } from './columns'
import { formList, descFormList, localFormList, ownershipOption, getFormFunctionList, executorFormList,formListAftersale } from './form-list'
import { getToken } from '@/utils/auth'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials'
import SelectMaterialsInventory from '@/components/SelectMaterialsInventory/SelectMaterialsInventory'
import { demandServiceType } from '@/config/options.sales'

export default {
  name: 'RepairsDetail',
  components: { EditMaterials, SelectMaterials, SelectMaterialsInventory },
  data() {
    return {
      afterSaleEquMsg:[],
      upEqu:[],
      downEqu:[],
      rules: {
        changeType: [
          { required: true, message: '请选择更换类型', trigger: 'change' }
        ],
        // facilityId: [
        //   { required: true, message: '请选择售后设备', trigger: 'change' }
        // ],
        changeWorkRecord: [
          { required: true, message: '请输入工作记录', trigger: 'blur' }
        ],
      },
      msg:{},
      columns,
      formListAftersale,
      formList,
      descFormList,
      executorFormList,
      ownershipOption,
      baseFormData: {},
      params: {},
      messages: {
        add: '提交成功',
        modify: '修改成功',
        viewO: '工单详情',
        addO: '创建工单',
        modifyO: '修改工单'
      },
      buttonLoading: false,
      descTableList: [],
      executorList: [],
      executorUserList: [],
      marterialTableList: [],
      showMaterials: false,
      selectMaterials: [],
      showMaterialInventory: false,

      uploadProps2: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onRemove: this.removeFile,
        onPreview: this.handlePreview
      },
      attachments: [],
      isView: this.$route.query.type === 'view',
      facilityList: [],
      consumerId: undefined,
      allCustomer: [],
      manHour: 0
    }
  },

  computed: {
    isContent() {
      return !!this.$route.query.content || +this.$route.query.content === 0
    },

    localFormList() {
      if (nameChangeForBB()) {
        if (this.isView) {
          return localFormList
        } else {
          return localFormList.filter((l) => l.key !== 'creator')
        }
      } else {
        return localFormList.filter((l) => l.key !== 'operatorNames' && l.key !== 'creator')
      }
    },

    descColumns() {
      return this.isView ? descColumns.filter(item => item.slotName !== 'action') : descColumns
    },

    marterialColumns() {
      return this.isView ? marterialColumns.filter(item => item.slotName !== 'action') : marterialColumns
    },

    getDayDesc() {
      return (start, end) => {
        const format = 'YYYY年MM月DD日'
        if (start && end) {
          const isSameYear = dayjs(start).isSame(end, 'year')
          if (isSameYear) {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format('MM月DD日')}`
          } else {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format(format)}`
          }
        } else if (start) {
          return `${dayjs(start).format(format)}`
        } else if (end) {
          return `${dayjs(end).format(format)}`
        }
        return '-'
      }
    },

    getValue() {
      return row => {
        const result = this.baseFormData[row.prop]
        if (row.form && row.form.options && row.form.options.length > 0) {
          const current = row.form.options.find(item => item.id === result)
          return current && current.name
        }
        if (row.prop === 'facilityName') {
          return `${this.baseFormData.facilityCode} - ${this.baseFormData.facilityName}`
        }
        return result
      }
    },

    getDescValue() {
      return row => {
        return (this.baseFormData.facilityRepairService || {})[row.key]
      }
    },

    getLocalValue() {
      return row => {
        let { key } = row
        if (row.tagName === 'el-select') {
          key = `${key}Name`
        }
        if(this.params.content == 7){
          return (this.baseFormData.facilityReformSite || {})[key]
        }else {
          return (this.baseFormData.facilityRepairSite || {})[key]
        }
      }
    },

    getSparePartsDesc() {
      return val => {
        return (this.ownershipOption.find(item => item.value === val) || {})['label']
      }
    },

    getColumns() {
      let columns = this._.cloneDeep(this.columns)
      if (+this.$route.query.content === 0) {
        columns = columns.filter(item => {
          return item.prop !== 'faultCode' && item.prop !== 'faultTime'
        })
      }
      if (+this.$route.query.content === 1) {
        return columns.filter(item => {
          return item.prop !== 'workOrderType' && item.prop !== 'faultCode' && item.prop !== 'faultTime' && item.prop !== 'reformType' && item.prop !== 'equipmentId'
        })
      } else if (+this.$route.query.content === 3) {
        return columns.filter(item => {
          return item.prop !== 'workOrderType' && item.prop !== 'faultCode' && item.prop !== 'faultTime' && item.prop !== 'level' && item.prop !== 'equipmentId'
        })
      } else if (+this.$route.query.content === 7) {
        return columns.filter(item => {
          return ["workOrderCode","demandCode","workOrderType","customerSiteIdList","equipmentId","remark"].includes(item.prop)
        })
      } else {
        return columns.filter(item => {
          return item.prop !== 'workOrderType' && item.prop !== 'level' && item.prop !== 'reformType' && item.prop !== 'equipmentId'
        })
      }
    },

    getFormList() {
      if (!this.isView) {
        let formList = this._.cloneDeep(this.formList)
        if (this.isContent) {
          formList[1].tagName = 'div'
          formList[1].children = [
            {
              customSlots: ['demandCode']
            }
          ]
          if (+this.$route.query.content === 0) {
            if (nameChangeForBB()) {
              formList = formList.filter(item => {
                return item.key !== 'faultCode' && item.key !== 'faultTime'
              })
            }
            return formList.filter(item => {
              return item.key !== 'level' && item.key !== 'reformType' && item.key !== 'equipmentId'
            })
          }
          if (+this.$route.query.content === 1) {
            return formList.filter(item => {
              return item.key !== 'faultCode' && item.key !== 'faultTime' && item.key !== 'reformType' && item.key !== 'equipmentId'
            })
          }
          if (+this.$route.query.content === 3) {
            return formList.filter(item => {
              return item.key !== 'faultCode' && item.key !== 'faultTime' && item.key !== 'level' && item.key !== 'equipmentId'
            })
          }else if(+this.$route.query.content === 7){
            return formList.filter(item => {
              return ["workOrderCode","demandId","workOrderType","customerSiteIdList","equipmentId","remark"].includes(item.key)
            })
          } else {
            return formList.filter(item => {
              return item.key !== 'faultCode' && item.key !== 'faultTime' && item.key !== 'level' && item.key !== 'reformType' && item.key !== 'equipmentId'
            })
          }
        } else {
          return formList.filter(item => {
            return item.key !== 'workOrderType' && item.key !== 'level' && item.key !== 'reformType' && item.key !== 'equipmentId'
          })
        }
      }
    },

    getTime() {
      return item => {
        if (item.isSame === 1) {
          return item.manHour || 0
        } else {
          const { date } = item
          if (date && date.length) {
            const minutes = dayjs(date[0]).diff(dayjs(date[1]), 'minutes')
            const hour = (Math.abs(minutes) / 60).toFixed(1)
            return hour
          } else {
            return 0
          }
        }
      }
    },

    pickerOptions() {
      return {
        disabledDate: (time) => {
          const { form: localForm } = this.$refs.localForm
          return dayjs(time).isBefore(localForm.serviceStartTime, 'day') || dayjs(time).isAfter(localForm.serviceEndTime, 'day')
        }
      }
    }
  },

  watch: {
    manHour(val) {
      this.executorList = this.executorList.map(item => {
        return {
          ...item,
          manHour: item.isSame === 1 ? val : item.manHour
        }
      })
    }
  },

  beforeMount() {
    getFunctionList({
      // valueChange: this.valueChange,
      mulChange: this.mulChange,
      equChange:this.equChange,
      codeChange:this.codeChange,
      remarkChange:this.remarkChange,
    })
    getFormFunctionList({
      valueChange: this.valueChange
    })
  },

  async mounted() {
    this.params = this.$route.query
    if (this.params.demandId) {
      const infos = this.params.infos ? JSON.parse(this.params.infos) : {}
      this.baseFormData = {
        remark:infos.remark,
        ...this.baseFormData,
        demandCode: this.params.code,
        demandId: parseInt(this.params.demandId),
        workOrderType: parseInt(this.params.content),
        workOrderTypeName: (demandServiceType.find(item => item.id === parseInt(this.params.content)) || {}).name,
        consumerId: this.params.type === 'add' ? infos.customerId : '',
        consumer: this.params.type === 'add' ? infos.customerName : '',
        customerSiteId: this.params.type === 'add' ? infos.customerSiteId : '',
        customerSiteIdList: this.params.type === 'add' ? infos.customerSiteIdList.split('^').map(item => Number(item)) : '',
        operatorNames: this.params.operatorNames || infos.operatorNames,
      }
    }
    if (this.params.id || this.params.wordOrderId) {
      this.getDetail()
    } else {
      await this.getFacilityList()
    }
    this.getUserList()
    this.getKHJT()
    this.getAllCustomer()
    this.getFacilityDemandList()
    if (this.params.type !== 'view') {
      this.createFieldCode()
    }
    if(this.params.type === 'add'){
      this.getAllEqu(this.baseFormData.consumerId)
    }
    // if (this.params.type === 'add') {
    //   this.setFacilityList()
    // }
  },

  methods: {
    mulChange(val) {
      const arr = this.allCustomer.filter(item => item.id === this._.first(val))
      const list = this.allCustomer.filter(item => item.id === this._.last(val))
      this.$refs.baseForm.setForm({
        facilityId: '',
        upFacilityId:'',
        equipmentId:'',
        consumerId: +list[0].isCustomer === 0 ? list[0].id : arr[0].id,
        consumer: list[0].name,
        customerSiteId: +list[0].isCustomer === 1 ? list[0].id : '',
        customerSiteIdList: val
      })
      this.$set(this.baseFormData,"consumerId",+list[0].isCustomer === 0 ? list[0].id : arr[0].id)
      this.marterialTableList = []
      this.getKHJT();
      this.setFacilityList()
    },
    equChange(val){
      this.$set(this.baseFormData,"equipmentId",val)
      this.$set(this.baseFormData,"changeType","")
      this.$set(this.baseFormData,"facilityId","")
      this.$set(this.baseFormData,"upFacilityId","")
    },
    codeChange(val){
      this.$set(this.baseFormData,"workOrderCode",val)
    },
    remarkChange(val){
      this.$set(this.baseFormData,"remark",val)
    },

    // 根据选择类型的不同 换上/换下  获取不同的售后设备数据 0换上(不传值)  1换下(传设备id)
    async typeChange(val){
      // console.log(val);
      // let res = "";
      this.$set(this.baseFormData,"facilityId","")
      this.$set(this.baseFormData,"upFacilityId","")
      // if(val === '0'){
      //   res = await facilityListApi.getAfterSaleEpuByEquId(null);
      // }else if(val === '1'){
      //   res = await facilityListApi.getAfterSaleEpuByEquId(this.baseFormData.equipmentId);
      // }
      // res = res.map(p => {
      //   return {
      //     id:p.id,
      //     code:p.code,
      //     name:p.name
      //   }
      // })
      // this.afterSaleEquMsg = res;
    },
    async getAllEqu(customerId){
      let up = await facilityListApi.getUpAndDownEquByCustomerid(customerId,'0%5E2%5E5');
      let down = await facilityListApi.getUpAndDownEquByCustomerid(customerId,'1');

      up = up.map(p => {
        return {
          id:p.id,
          code:p.code,
          name:p.name + '—' + p.code + '—' + p.sn
        }
      })

      down = down.map(p => {
        return {
          id:p.id,
          code:p.code,
          name:p.name + '—' + p.code + '—' + p.sn
        }
      })
      this.upEqu = up;
      this.downEqu = down;
    },

    valueChange(key) {
      if (key === 'serviceStartTime' || key === 'serviceEndTime') {
        const { form: localForm } = this.$refs.localForm
        this.setExecutionHours(localForm)
      }
    },

    setExecutionHours(data) {
      const { serviceStartTime, serviceEndTime } = data
      if (serviceStartTime && serviceEndTime) {
        const minutes = dayjs(serviceStartTime).diff(dayjs(serviceEndTime), 'minutes')
        if (minutes > 0 && this.$refs.localForm) {
          this.$refs.localForm.setForm({
            serviceStartTime: serviceEndTime,
            serviceEndTime: serviceStartTime
          })
        }
        this.manHour = (Math.abs(minutes) / 60).toFixed(1)
        if(this.params.content == 7){
          this.baseFormData.facilityReformSite.manHour = +this.manHour
        }else {
          this.baseFormData.facilityRepairSite.manHour = +this.manHour
        }
      } else {
        this.manHour = 0
        if(this.params.content == 7){
          this.baseFormData.facilityReformSite.manHour = 0
        }else {
          this.baseFormData.facilityRepairSite.manHour = 0
        }
      }
    },

    returns() {
      this.$router.back()
    },

    async getDetail() {
      let res
      if (this.isContent) {
        if(+this.params.content === 7){
          res = await api.getReplaceDetail(parseInt(this.params.wordOrderId))
        }else {
          res = await api.getWordOrderDetail({
            demandCode: this.params.code,
            demandId: parseInt(this.params.demandId),
            type: parseInt(this.params.content),
            wordOrderId: parseInt(this.params.wordOrderId)
          })
        }
      } else {
        res = await api.getRepairsByDetail(this.params.id)
      }
      this.getFacilityList()
      consoleSelf.info("订单详情",res);
      if (res) {
        const descInfo = res.facilityRepairService || {}
        const localInfo = res.facilityRepairSite || res.facilityReformSite || {}

        if (localInfo.executor) {
          localInfo.executor = localInfo.executor.split('^').map(item => +item)
        }

        if (localInfo.facilityServiceTimeMappingList) {
          this.executorList = localInfo.facilityServiceTimeMappingList.map(item => {
            return {
              ...item,
              isSame: item.isSame,
              date: item.isSame === 1 ? [] : [item.startTime, item.endTime],
              userIds: item.userIds ? item.userIds.split('^').map(item => +item) : [],
              manHour: item.manHour
            }
          })
        }
        this.descTableList = descInfo.facilityRepairHistoryList || []
        this.attachments = JSON.parse(localInfo.affix || '[]')
        this.marterialTableList = res.facilityRepairSparePartList
        this.baseFormData = {
          ...this.baseFormData,
          ...res,
          facilityRepairSite: {
            ...res.facilityRepairSite,
            operatorNames: this.params.operatorNames,
            creator: res.creator
          },
          customerSiteIdNames: `${res.customerSiteName ? res.customerSiteName : res.consumer}`,
        }
        if(+this.params.content === 7){
          this.baseFormData.workOrderType = (demandServiceType.find(item => item.id === parseInt(this.params.content)) || {}).name
          if(this.baseFormData.changeType == '0'){
            this.baseFormData.changeType = "换上"
          }else if(this.baseFormData.changeType == '1'){
            this.baseFormData.changeType = "换下"
          }else if(this.baseFormData.changeType == '2'){
            this.baseFormData.changeType = "换上换下"
          }
          this.baseFormData.facilityId = this.baseFormData.facilityName || " "
          this.baseFormData.upFacilityId = this.baseFormData.upFacilityName || " "
          this.baseFormData.equipmentId = this.baseFormData.equipmentName || " "
        }
        this.baseFormData.demandId = this.baseFormData.demandId === -1 ? '' : this.baseFormData.demandId
        this.baseFormData.customerSiteIdList = res.customerSiteIdList ? res.customerSiteIdList.split('^').map(item => Number(item)) : []
        if(this.params.content == 7){
          this.setExecutionHours(this.baseFormData.facilityReformSite)
        }else {
          this.setExecutionHours(this.baseFormData.facilityRepairSite)
        }
      }
    },

    async createFieldCode() {
      const res = await fieldApi.getFieldIsExist({
        numberVersion: '维修工单'
      })
      if (res && +res === 0) {
        if (this.params.type === 'add' && +this.params.content !== 7) {
          this.$refs.baseForm.setForm({
            workOrderCode: '系统生成'
          })
        }
        if(+this.params.content !== 7){
          this.formList[0].props.disabled = true
        }else {
          this.formList[0].props.disabled = true
          this.$set(this.baseFormData,"workOrderCode",'系统生成');
          // this.formList[0].required = true
        }
        // 设置备注不可修改
        // this.formList.forEach(p => {
        //   if(p.index === 10 && p.key === "remark"){
        //     p.props.disabled = true
        //   }
        // })
      }
    },

    // 获取需求列表
    async getFacilityDemandList() {
      const res = await demandApi.getFacilityDemandList()
      if (res) {
        this.formList[1].children[0].options = res.map(item => {
          return {
            name: item.code,
            id: item.id
          }
        })
      }
    },

    // 获取客户
    async getUser() {
      const res = await keApi.getCustomerList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.formList[3].children[0].options = res
      }
    },
    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.formList[3].props.options = setTreeData(data)
      }
    },

    // 获取设备
    async getFacilityList() {
      const search = [
        {
          id: getUUid(),
          fieldName: 'isDeleted',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        }
      ]
      const res = await facilityListApi.getFacilityList({ searchVal: Encrypt(JSON.stringify([...search])) })
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: `${item.code} - ${item.name}`
          }
        })
        this.facilityList = data || []
        this.setFacilityList()
      }
    },

    async getUserList() {
      const res = await userApi.getUserList()
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.executorUserList = res.filter(item => item.id !== userInfo.id)
    },

    // 客户机台/生产设备数据查询
    async getKHJT() {
      this.$set(this.baseFormData,"equipmentId","")
      if(this.baseFormData.consumerId){
        let res = await facilityListApi.getEpuByCustomerId(this.baseFormData.consumerId)
        res = res.map(p => {
          return {
            id:p.id,
            code:p.code,
            name:p.name
          }
        })
        this.formList.forEach(p => {
          if(p.index === 9 && p.key === "equipmentId"){
            p.children[0].options = res
          }
        })
      }
    },

    setFacilityList() {
      if (this.$refs.baseForm) {
        const { form: baseForm } = this.$refs.baseForm
        const { consumerId } = baseForm
        if (consumerId) {
          this.formList[4].children[0].options = this.facilityList.filter(item => item.customerId === consumerId)
        } else {
          this.formList[4].children[0].options = []
        }
      }
    },

    addTableList() {
      this.descTableList.push({})
    },

    delTableList(index) {
      this.descTableList.splice(index, 1)
    },

    onSelectMaterials() {
      this.showMaterials = true
    },

    onSelectMaterialsInventory() {
      if (this.$refs.baseForm) {
        const { form: baseForm } = this.$refs.baseForm
        const { consumerId } = baseForm

        if (!consumerId) {
          return this.$message.error('请选择客户')
        }

        this.consumerId = consumerId
        this.showMaterialInventory = true
      }
    },

    submitMaterials(values) {
      this.showMaterials = false
      this.consumerId = undefined

      const list = values.map(item => {
        return {
          id: item.id,
          facilityUpkeepId: this.baseFormData.id,
          materialsId: item.id,
          batchNumber: item.batchNumber,
          sequenceNumber: item.sequenceNumber,
          materialsCode: item.code,
          materialsName: item.name,
          materialsSpecifications: item.specifications,
          materialsType: item.materialsTypeName,
          quantity: item.planQuantity,
          materialsUnit: item.mainUnitName,
          usageQuantity: undefined,
          ownershipSparePartsAssets: 2
        }
      })
      this.marterialTableList = this.marterialTableList.concat(list)
    },

    submitMaterialInventory(values) {
      this.showMaterialInventory = false
      const list = values.map(item => {
        return {
          ...item,
          id: item.materialsId,
          facilityUpkeepId: this.baseFormData.id,
          quantity: item.availableQuantity,
          usageQuantity: undefined,
          ownershipSparePartsAssets: 0
        }
      })
      this.marterialTableList = this.marterialTableList.concat(list)
    },

    addMaterial() {
      this.$refs.editMaterials.add()
    },

    delMaterial(index) {
      this.marterialTableList.splice(index, 1)
    },

    onSuccess() {
      this.$refs.selectMaterials.updateTable()
    },

    addExecutor() {
      const { form: localForm } = this.$refs.localForm

      if (!localForm.serviceStartTime || !localForm.serviceEndTime) {
        return this.$message.error('请先选择服务时间')
      }
      this.executorList.push({
        isSame: 1,
        date: [],
        manHour: this.manHour
      })
    },

    delExecutorList(index) {
      this.executorList.splice(index, 1)
    },

    datePickerChange(index) {
      const date = this.executorList[index].date

      if (date && date.length) {
        const minutes = dayjs(date[0]).diff(dayjs(date[1]), 'minutes')
        if (minutes) {
          this.executorList[index].manHour = (Math.abs(minutes) / 60).toFixed(1)
        }
      }
    },

    onRadioChange(val, index) {
      if (val === 1) {
        this.executorList[index].manHour = this.manHour
      }
      this.executorList[index].date = []
    },

    // 上传附件
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.attachments.push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    removeFile(file) {
      this.attachments = this.attachments.filter(item => item.uid !== file.uid)
    },
    handlePreview(file) {
      if (file.url.indexOf('.txt') > 0) {
        saveAs(file.url, '文件')
        return
      }
      window.open(file.url)
    },

    submitForm() {
      this.buttonLoading = true

      const { form: baseForm } = this.$refs.baseForm
      const { form: localForm } = this.$refs.localForm

      const isTimeNull = !localForm.serviceStartTime || !localForm.serviceEndTime
      if (isTimeNull) {
        this.buttonLoading = false
        return this.$message.error('服务时间不能为空')
      }

      const isHistoryWarningNull = this.descTableList.find(item => !item.faultTime || !item.faultCode)
      if (isHistoryWarningNull) {
        this.buttonLoading = false
        return this.$message.error('历史报警记录有必填项未填')
      }

      const isMaterialNumNull = this.marterialTableList.find(item => !item.usageQuantity)
      if (isMaterialNumNull) {
        this.buttonLoading = false
        return this.$message.error('备件使用数量不能为空')
      }

      const isMaterialNumThanAvalid = this.marterialTableList.find(item => item.quantity && item.usageQuantity > item.quantity)
      if (isMaterialNumThanAvalid) {
        this.buttonLoading = false
        return this.$message.error('备件使用数量不能大于备件可用库存')
      }

      const isExecutorNull = this.executorList.find(item => !item.userIds.length)

      if (isExecutorNull) {
        this.buttonLoading = false
        this.$message.error('协作人未选择')
        return
      }

      const isExecutorTimeNull = this.executorList.find(item => item.isSame === 0 && !item.date.length)

      if (isExecutorTimeNull) {
        this.buttonLoading = false
        this.$message.error('协作人服务时间未填写')
        return
      }

      this.$refs.baseForm.formRefs().validate(async valid => {
        if (valid) {
          this.$refs.localForm.formRefs().validate(async valids => {
            const data = {
              ...baseForm,
              ...this.baseFormData,
              customerSiteIdList: baseForm.customerSiteIdList.join('^'),
              demandCode: baseForm.demandId ? this.baseFormData.demandCode : '',
              executionStartTime: localForm.serviceStartTime,
              executionEndTime: localForm.serviceEndTime,
              // facilityRepairService: {
              //   ...descForm,
              //   facilityRepairHistoryList: this.descTableList
              // },
              facilityRepairSite: {
                ...localForm,
                manHour: +this.manHour,
                affix: JSON.stringify(this.attachments),
                executor: '',
                facilityServiceTimeMappingList: this.executorList.map(item => {
                  return {
                    isSame: item.isSame,
                    startTime: item.isSame === 1 ? localForm.serviceStartTime : item.date[0],
                    endTime: item.isSame === 1 ? localForm.serviceEndTime : item.date[1],
                    userIds: item.userIds.join('^'),
                    manHour: +item.manHour
                  }
                })
              },
              facilityRepairSparePartList: this.marterialTableList
            }
            if(this.params.content != 7){
              const { form: descForm } = this.$refs.descForm
              data.facilityRepairService = {
                ...descForm,
                facilityRepairHistoryList: this.descTableList
              }
            }

            data.workOrderCode = data.workOrderCode && data.workOrderCode !== '系统生成' ? data.workOrderCode : -1
            if(this.params.content == 7){
              data.workOrderCode = null
            }

            const code = await api.checkTimeInfo({
              startTime: localForm.serviceStartTime,
              endTime: localForm.serviceEndTime
            })
            if (code === 1 || code === 2) {
              let message
              if (code === 1) {
                message = '该工单已跨天执行，是否确认保存？'
              } else {
                message = '该工单的服务时长已超过24h，是否确认保存？'
              }
              this.$confirm(message, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.saveData(data)
              })
            } else {
              this.saveData(data)
            }
            this.buttonLoading = false
          })
        } else {
          this.buttonLoading = false
        }
      })
    },

    async saveData(form) {
      const f = this.isContent ? 'WordOrder' : 'Repairs'
      const res = await api[`${this.params.type}${f}`](form)
      if (res) {
        this.returns()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrappers {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
  }

  .card-bot {
    ::v-deep.el-row {
      display: flex;
      flex-wrap: wrap;
    }

    .custom-form {
      display: flex;
      margin-bottom: 15px;
      color: #606266;

      .custom-form-item:nth-child(1) {
        width: calc(25% - 94px);

        .el-select {
          width: 100%;
        }
      }

      .custom-form-item:nth-child(2) {
        width: calc(75% + 94px);
        padding-left: 20px;

        ::v-deep .el-radio__label {
          font-weight: bold;
        }

        .hour {
          display: inline-block;
          padding-right: 20px;

          label {
            padding: 0 12px 0 40px;
          }
        }
      }
    }

    .view-list {
      padding: 0 15px;
      color: #393d60;
      font-size: 14px;

      &__label {
        margin-bottom: 4px;
        color: #9597ae;
      }

      .el-col {
        margin: 10px 0;
      }
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;

      .required-star {
        width: 10px;
        color: #f00;
      }

      .range {
        margin: 0 10px;
      }
    }

    .upload-view {
      ::v-deep.el-upload {
        &--text {
          display: none;
        }
      }
    }
  }

  .detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;

      .desc-table.edit {
        position: relative;
        top: -46px;
        left: 96px;
        width: calc(100% - 96px);
        margin-bottom: -46px;
      }

      .e-btn {
        margin-top: 10px;
      }
    }
  }
  .detail-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
