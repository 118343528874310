import columns from './columns'
import { transformColumnsToForm } from '@/utils'
import _ from 'lodash'

const newColumn = _.cloneDeep(columns.User)
newColumn.splice(7, 0, {
  prop: 'password',
  label: '密码',
  hiddenSearch: true,
  sortable: false,
  form: { colSpan: 24, index: 9, tagName: 'el-input', required: false }
})
newColumn.splice(8, 0, {
  prop: 'picture',
  label: '名片',
  sortable: false,
  hiddenSearch: true,
  form: {
    index: 12,
    colSpan: 24,
    tagName: 'div',
    customSlots: ['picture'],
    required: false
  }
})
const formList = {
  User: transformColumnsToForm(newColumn),
  UserGroup: transformColumnsToForm(columns.UserGroup)
}
export default formList
