<template>
  <div class="shift-wrap">
    <div class="detail-card">
      <div class="card-tit">用户信息</div>
      <div class="card-bot" style="padding: 15px">
        <MTable ref="mTable" :columns="userColumns" :height="300" :set-data-method="getTableDatas">
          <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
          <div slot="isSystemUser" slot-scope="{ row }">{{ +row.isSystemUser === 1 ? '是' : '否' }}</div>
        </MTable>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">路由信息</div>
      <div class="card-bot" style="padding: 15px">
        <el-input
          v-model="routeInfo"
          type="textarea"
          :autosize="{ minRows: 8}"
          placeholder="请输入内容"
        />
        <el-button :loading="loading" type="primary" size="small" style="margin-top: 10px" @click="modifys()">保存</el-button>
      </div>
    </div>
    <!-- <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog> -->
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/tenant'
import { userColumns } from './columns'
import formList from './form-list'
import baseSettingApi from '@/api/sets/base-setting'

export default {
  name: 'TenantDetail',
  data() {
    return {
      list: {
        userList: []
      },
      functionHeader: {
        searchVal: ''
      },
      userColumns,
      visible: false,
      formData: {},
      currentType: 'add',
      formOptions: {},
      routeInfo: '',
      loading: false
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      const res = await api.getTenantDetail({
        id: this.$route.query.id
      })
      if (res) {
        this.list = res.basicConfig
        this.routeInfo = res.basicConfig ? res.basicConfig.value : ''
      }
    },
    async getTableDatas(condition, callback) {
      const { page, search, order } = condition
      const res = await api[`getTenantUserListPage`]({
        page: page.currentPage,
        limit: page.pageSize,
        memberCode: this.$route.query.memberCode
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    async modifys() {
      this.loading = true
      const res = await baseSettingApi.modifySingleSetting({
        id: this.list.id,
        code: this.list.code,
        value: this.routeInfo,
        memberCode: this.$route.query.memberCode
      })
      if (res) {
        this.$message.success('保存成功')
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.shift-wrap {
  background: #eee;
  height: 100%;
  overflow-y: scroll;
  padding: 10px;
  .function-header-wrap {
    padding-top: 0;
  }
}
</style>

