<template>
  <div class="equipment-monitoring">
    <div class="detail-cards">
      <div v-if="isTitle" class="card-tit">关联设备</div>
      <div class="card-bots">
        <div v-if="type === 'new'" style="margin-bottom: 10px">
          <el-button size="small" type="primary" @click="addInfo">添加</el-button>
          <el-button size="small" type="primary" @click="addPlanTemplateInfo">从计划模板中导入</el-button>
        </div>
        <MTable ref="plansTable" :show-page="false" :height="heights" :columns="columns" :data="facilityMappingList" :only-key="'uuid'">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="action" slot-scope="{ row, $index }">
            <template v-if="type === 'new'">
              <el-button type="text" @click="delInfos(row, $index)">移除</el-button>
            </template>
          </div>
        </MTable>
      </div>
    </div>
    <select-facility
      ref="selectFacility"
      :visible.sync="showFacility"
      :select-facilitys="selectFacilitys"
      :customer-id="customerIds"
      @submitForm="submitFacility"
    />
    <MDialog v-model="planVisible" :append-to-body="true" title="从计划模板中导入" width="1000px" @onOk="onPlanConfirm">
      <div class="dialog-wrapper">
        <FunctionHeader
          v-model="functionHeader.searchVal"
          :search-title="'请输入模板名称'"
          :radio="params.radio"
          @search="$refs.mTable.setTableData(true)"
        />
        <MTable
          ref="mTable"
          :height="300"
          :highlight-current-row="true"
          :columns="planTemplateColumns"
          :set-data-method="getTableData"
          :columns-setting="false"
          @current-change="handleCurrentChange"
        >
          <div slot="cycleType" slot-scope="{ row }">
            {{ row.cycleType || row.cycleType === 0 ? maintenanceCycleType[row.cycleType].name : '-' }}
          </div>
          <div slot="lifeCycle" slot-scope="{ row }">
            {{ row.lifeCycle || row.lifeCycle === 0 ? row.lifeCycle : '-' }}
          </div>
        </MTable>
      </div>
    </MDialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { equipmentColumns, planTemplateColumns } from './columns'
import { equipmentFormList } from './form-list'
import { getUUid } from '@/utils'
import SelectFacility from '@/components/SelectFacility/SelectFacility.vue'
import conventionMixin from '@/mixin/conventional-page'
import FunctionHeader from '@/components/FunctionHeader/index'
import api from '@/api/xiewei/facility/point'
import { maintenanceCycleType } from '@/config/options.sales'

export default {
  name: 'RelationEquipment',
  components: { SelectFacility, FunctionHeader },
  mixins: [conventionMixin],
  props: {
    isTitle: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'new'
    },
    list: {
      type: Array,
      default: () => []
    },
    selecteFacilityList: {
      type: String,
      default: ''
    },
    customerId: {
      type: Number,
      default: undefined
    },
    customerSiteId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      api,
      maintenanceCycleType,
      planTemplateColumns,
      functionHeader: {
        searchVal: ''
      },
      facilityMappingList: [],
      date: dayjs().format('YYYY-MM-DD'),
      currentType: 'add',
      equipmentColumns,
      equipmentFormList,
      columns: [],
      tableData: [],
      formData: {},
      showFacility: false,
      messages: {
        add: '添加',
        modify: '修改'
      },
      selectFacilitys: [],
      customerIds: undefined,
      params: {
        radio: 'PlanSpotCheckTemplate'
      },
      planVisible: false,
      selectId: ''
    }
  },
  computed: {
    heights() {
      return 202
    }
  },
  watch: {
    list(val) {
      if (val) {
        this.facilityMappingList = val
      }
      this.selectFacilitys =
        val && val.length > 0
          ? val.map(item => {
            return {
              ...item,
              id: item.facilityId,
              selectable: true
            }
          })
          : []
    },
    customerId(val) {
      this.customerIds = val
    }
  },
  mounted() {
    const newColumn = this._.cloneDeep(this.equipmentColumns)
    newColumn.pop()
    this.columns = this.type === 'new' ? this.equipmentColumns : newColumn
    this.facilityMappingList = this.list
    this.customerIds = this.customerId
  },
  methods: {
    async onPlanConfirm(callback) {
      if (!this.selectId) {
        return this.$message.warning('请单击选择一条数据')
      }
      const res = await api.getPlanSpotCheckTemplateDetail({ id: this.selectId })
      this.$emit('onConfirm', res.facilityPlanMaintenanceTemplateFacilityMappingList || [])
      this.planVisible = false
      callback(true)
    },
    handleCurrentChange(row) {
      this.selectId = row.id
    },
    addPlanTemplateInfo() {
      if (!this.customerIds) {
        return this.$message.error('请选择客户/现场')
      }
      this.extraParams = [
        {
          id: getUUid(),
          fieldName: 'planTemplateSign',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }
      ]
      if (this.customerSiteId) {
        this.getTableDataParams = {
          customerSiteId: this.customerSiteId || ''
        }
      } else {
        this.getTableDataParams = {
          customerId: this.customerIds
        }
      }

      this.planVisible = true
      this.selectId = ''
      this.functionHeader.searchVal = ''
    },
    async submitFacility(val) {
      this.selectFacilitys = val.map(item => {
        return {
          ...item,
          facilityId: item.id,
          facilityName: item.name,
          facilityCode: item.name,
          selectable: true
        }
      })

      this.facilityMappingList = val.map(item => {
        return {
          ...item,
          facilityId: item.id,
          facilityName: item.name,
          facilityCode: item.code,
          uuid: getUUid()
        }
      })
      this.$emit('facilityInfo', this.facilityMappingList)
      this.showFacility = false
    },
    addInfo() {
      if (this.customerIds) {
        this.showFacility = true
        return
      }
      return this.$message.error('请选择客户')
    },

    delInfos(row, index) {
      this.facilityMappingList.splice(index, 1)
      this.selectFacilitys = this.selectFacilitys.filter(item => item.id !== +row.facilityId)
      // this.$emit('facilityInfo', this.facilityMappingList)
    },
    getSuccessMessage() {
      return `${this.messages[this.currentType]}成功`
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-monitoring {
  width: 100%;

  ::v-deep {
    .el-form-item__label {
      padding: 0 12px 0 0;
    }
    // .el-form-item__content {
    //   width: 300px;
    // }
    .el-form-item {
      margin-bottom: 20px !important;
    }
  }
  .card-wrapper {
    border: 1px solid #cccccc;
    border-radius: 4px;
    .top {
      width: 100%;
      padding: 10px;
      height: 53px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      .right {
        height: 32px;
        img {
          width: 30px;
          padding-left: 10px;
          position: relative;
          top: 5px;
        }
      }
    }
    .body {
      padding: 10px;
      .li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .line-wrapper {
    width: calc(100% - 260px);
  }
  .equipment-params {
    width: 250px;
  }
}
::v-deep {
  .el-dialog {
    .dialog-wrapper {
      padding-bottom: 10px;
      border: 1px solid #ddd;
      .m-table {
        margin: 0 10px;
      }
    }
  }
}
</style>
