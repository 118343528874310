import { getUUid, formatColumns } from '@/utils'
import dayjs from 'dayjs'
import { Encrypt } from '@/utils/sercet'
import { faultStatus } from '@/config/options.config'
const quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)
export const getDateRange = (type, formatType = 'YYYY-MM') => {
  let startDate
  let endDate
  // 当天
  if (type === 5) {
    startDate = dayjs().format(formatType)
    endDate = dayjs().format(formatType)
  }
  // 当天
  if (type === 7) {
    startDate = dayjs(dayjs().subtract(1, 'day')).format(formatType)
    endDate = dayjs(dayjs().subtract(1, 'day')).format(formatType)
  }
  // 当周
  if (type === 6) {
    const isSunday = dayjs().day()
    if (isSunday === 0) {
      startDate = dayjs(dayjs().subtract(6, 'day')).format(formatType)
      endDate = dayjs().format(formatType)
    } else {
      startDate = dayjs(dayjs().day(1)).format(formatType)
      endDate = dayjs(dayjs().day(7)).format(formatType)
    }
  }
  // 当月
  if (type === 0) {
    startDate = dayjs().startOf('month').format(formatType)
    endDate = dayjs().endOf('month').format(formatType)
  }
  // 季度
  if (type === 1) {
    const currentQuarter = dayjs().quarter()
    const currentYear = dayjs().year()
    const quarterStartAndEndDate = [['01-01', '03-31'], ['04-01', '06-31'], ['07-01', '09-31'], ['10-01', '12-31']]
    startDate = dayjs(`${currentYear}-${quarterStartAndEndDate[currentQuarter - 1][0]}`).format(formatType)
    endDate = dayjs(`${currentYear}-${quarterStartAndEndDate[currentQuarter - 1][1]}`).format(formatType)
  }
  // 半年
  if (type === 2) {
    const currentMonth = dayjs().month()
    const currentYear = dayjs().year()
    if (currentMonth <= 5) {
      endDate = dayjs(`${currentYear}-06-31`).format(formatType)
      startDate = dayjs().startOf('year').format(formatType)
    } else {
      endDate = dayjs().endOf('year').format(formatType)
      startDate = dayjs(`${currentYear}-07-01`).format(formatType)
    }
  }
  // 当年
  if (type === 3) {
    startDate = dayjs().startOf('year').format(formatType)
    endDate = dayjs().endOf('year').format(formatType)
  }
  // 去年同月至当月
  if (type === 4) {
    endDate = dayjs().format(formatType)
    startDate = dayjs().subtract(1, 'year').format(formatType)
  }
  // 2023年1月至今
  if (type === 8) {
    endDate = dayjs().format(formatType)
    startDate = dayjs('2023-01-01').format(formatType)
  }
  return [startDate, endDate]
}

export const getMonthColumns = (startDate = '2023-01') => {
  const colums = []
  const monthDiff = dayjs().diff(startDate, 'month')
  for (let i = 0; i <= monthDiff; i++) {
    const date = dayjs(startDate).add(i, 'month').format('YYYY年MM月')
    colums.push({
      prop: `listData${i}`,
      listIndex: i,
      listDataKey: 'monthList',
      slotName: `listData${i}`,
      label: date,
      sortable: false,
      hiddenSearch: true
    })
  }
  return colums
}

const defaultTimeDuration = getDateRange(1)
export default [
  {
    name: '工时统计',
    key: 'manHour',
    children: [
      {
        id: 40,
        name: '工时统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getManHourAll',
        size: 999,
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            isStartName: true
          },
          {
            type: 'select',
            key: 'userGroupId',
            dataUrl: 'basicData/settings/private/userGroup/getUserGroupList',
            dataKey: 'userGroupList',
            placeholder: '请选择用户组'
          }
        ],
        columns: [
          {
            prop: 'jobNumber',
            label: '工号',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'userName',
            searchKey: 'userIdListStr',
            label: '姓名',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'userName',
            slotName: 'getDeepName',
            filterType: 'transfer',
            dataUrl: 'authority/private/user/getUserList'
          },
          {
            prop: 'organizationName',
            label: '组织',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'spotCheckHours',
            label: '点检/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'maintainHours',
            label: '保养/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'troubleshoot',
            label: '排故/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'installAndDebugHours',
            label: '安装、调试/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'reportHours',
            label: '报告/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'fiveHours',
            label: '5S/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'meetingHours',
            label: '会议/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'trainHours',
            label: '培训/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'roadHours',
            label: '路途/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'waitHours',
            label: '等待/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'holidayHours',
            label: '休假/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'allHours',
            label: '总计/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'overtimeHours',
            label: '加班/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          }
        ]
      },
      // {
      //   id: 41,
      //   name: '工时统计详细表',
      //   type: 'table',
      //   dataUrl: 'statistics/private/statistics/getManHourDetail',
      //   size: 999,
      //   dataCondition: { day: dayjs().format('YYYY-MM-DD') },
      //   searches: [
      //     {
      //       type: 'dateRange',
      //       formatDateType: 'YYYY-MM-DD',
      //       canNoSelect: true,
      //       isStartName: true
      //     },
      //     {
      //       type: 'select',
      //       key: 'userGroupId',
      //       dataUrl: 'basicData/settings/private/userGroup/getUserGroupList',
      //       dataKey: 'userGroupList',
      //       placeholder: '请选择用户组',
      //     },
      //     {
      //       type: 'dailys',
      //       key: 'days'
      //     }
      //   ],
      //   columns: [
      //     {
      //       prop: 'jobNumber',
      //       label: '工号',
      //       sortable: false,
      //       hiddenSearch: true,
      //       slotName: 'getDeepName'
      //     },
      //     {
      //       prop: 'userName',
      //       searchKey: 'userIdListStr',
      //       label: '姓名',
      //       sortable: false,
      //       hiddenSearch: true,
      //       headerSlotName: 'userName',
      //       slotName: 'getDeepName',
      //       filterType: 'transfer',
      //       dataUrl: 'authority/private/user/getUserList'
      //     },
      //     {
      //       prop: 'organizationName',
      //       label: '组织',
      //       sortable: true,
      //       hiddenSearch: true
      //     },
      //     {
      //       prop: 'urRate',
      //       label: 'UR%',
      //       sortable: true,
      //       hiddenSearch: true,
      //       width: 80
      //     },
      //     {
      //       prop: 'maintainCount',
      //       label: 'PM Qty.',
      //       sortable: true,
      //       hiddenSearch: true,
      //       width: 80
      //     },
      //     {
      //       prop: 'maintainRate',
      //       label: 'PM Efficiency(小时/台）',
      //       sortable: true,
      //       hiddenSearch: true,
      //       width: 80
      //     },
      //     {
      //       prop: 'troubleshootCount',
      //       label: 'CM Qty.',
      //       sortable: true,
      //       hiddenSearch: true,
      //       width: 80
      //     },
      //     {
      //       prop: 'spotCount',
      //       label: '点检 Qty.',
      //       sortable: true,
      //       hiddenSearch: true,
      //       width: 80
      //     },
      //     {
      //       prop: 'installAndDebugCount',
      //       label: '安装调试 Qty.',
      //       sortable: true,
      //       hiddenSearch: true,
      //       width: 80
      //     }
      //   ]
      // },
      {
        id: '41-1',
        name: '月度工时统计',
        type: 'chartsAndTable',
        dataUrl: 'statistics/private/facilityStatistics/getMonthManHourResultList',
        chartDataKey: 'outputHours',
        chartType: 'barAndLine',
        dataCondition: {},
        chartLegendKey: 'time',
        chartXKey: 'time',
        size: 999,
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM',
            canNoSelect: true,
            isStartName: true
          },
          {
            type: 'select',
            key: 'userGroupId',
            dataUrl: 'basicData/settings/private/userGroup/getUserGroupList',
            dataKey: 'userGroupList',
            placeholder: '请选择用户组'
          }
        ],
        columns: [
          {
            prop: 'time',
            label: '月份',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'workingDays',
            label: '应出勤天数/天',
            sortable: true,
            hiddenSearch: true,
            slotName: 'workingDays'
          },
          {
            prop: 'workingHours',
            label: '应出勤总工时/h',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'outputHours',
            label: '产出工时/h',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'assistHours',
            label: '辅助工时/h',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'coordinateHours',
            label: '协调工时/h',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'holidayHours',
            label: '休假工时/h',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'urRate',
            label: 'UR',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'outputRate',
            label: '产出比',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 42,
        name: '工时占比',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getManHourRate',
        chartType: 'pie',
        chartDataKey: 'hours',
        chartLegendKey: 'name',
        chartXKey: 'name',
        chartOverlap: true,
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        // dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'select',
            key: 'userGroupId',
            dataUrl: 'basicData/settings/private/userGroup/getUserGroupList',
            dataKey: 'userGroupList',
            placeholder: '请选择用户组'
          },
          {
            type: 'select',
            key: 'userIdListStr',
            dataUrl: 'authority/private/user/getUserList',
            dataKey: 'userList',
            placeholder: '请选择用户',
            props: {
              multiple: true
            }
          },
          {
            type: 'dailys',
            key: 'days'
          }
        ]
        // columnsType: 'horizontal',
      },
      {
        id: '41-2',
        name: '工时汇总',
        type: 'table',
        dataUrl: 'statistics/private/facilityStatistics/getAllManHourDetailResultList',
        size: 999,
        // dataCondition: { beginTime: getDateRange(1, 'YYYY-MM')[0], endTime: getDateRange(1, 'YYYY-MM')[1] },
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            isStartName: true
          },
          {
            type: 'select',
            key: 'userGroupId',
            dataUrl: 'basicData/settings/private/userGroup/getUserGroupList',
            dataKey: 'userGroupList',
            placeholder: '请选择用户组'
          },
          {
            type: 'dailys',
            key: 'days'
          }
        ],
        columns: [
          {
            prop: 'organizationName',
            // searchKey: 'organizationId',
            label: '组织',
            sortable: false,
            hiddenSearch: true
            // headerSlotName: 'organizationName',
            // slotName: 'getDeepName',
            // filterType: 'transfer',
            // dataUrl: 'authority/private/Organization/getOrganizationList'
          },
          // {
          //   prop: 'userGroupName',
          //   searchKey: 'userGroupId',
          //   label: '用户组',
          //   sortable: false,
          //   hiddenSearch: true,
          //   headerSlotName: 'userGroupName',
          //   slotName: 'getDeepName',
          //   filterType: 'transfer',
          //   dataUrl: 'basicData/settings/private/userGroup/getUserGroupList'
          // },
          {
            prop: 'userName',
            searchKey: 'userIdListStr',
            label: '用户',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'userName',
            slotName: 'getDeepName',
            filterType: 'transfer',
            dataUrl: 'authority/private/user/getUserList'
          },
          {
            prop: 'spotCheckHours',
            label: '点检/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'spotCount',
            label: '点检次数',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'spotRatio',
            label: '点检Hr/time',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'maintainHours',
            label: '保养/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'maintainCount',
            label: '保养次数',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'maintainRatio',
            label: '保养Hr/time',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'troubleshoot',
            label: '排故/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'troubleshootCount',
            label: '排故次数',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'troubleshootRatio',
            label: '排故Hr/time',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'installAndDebugHours',
            label: '安装、调试/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'installAndDebugCount',
            label: '安装、调试次数',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'installAndDebugRatio',
            label: '安装、调试Hr/time',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'reportHours',
            label: '报告/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'fiveHours',
            label: '5S/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'meetingHours',
            label: '会议/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'trainHours',
            label: '培训/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'roadHours',
            label: '路途/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'waitHours',
            label: '等待/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'holidayHours',
            label: '休假/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'allHours',
            label: '总计/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'hoursRegistrationRatio',
            label: '工时登记比%',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'urHours',
            label: 'UR/h%',
            slotName: 'UrHours',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'overtimeHours',
            label: '加班/h',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'outputRatio',
            label: '产出比%',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'assistRatio',
            label: '辅助比%',
            sortable: true,
            hiddenSearch: true,
            width: 80
          },
          {
            prop: 'coordinateRatio',
            label: '协调比%',
            sortable: true,
            hiddenSearch: true,
            width: 80
          }
        ]
      }
    ]
  },
  {
    name: '设备统计',
    key: 'equipmentStatistics',
    children: [
      {
        id: 43,
        name: '客户设备数量',
        type: 'charts',
        dataUrl: 'displayBoard/private/displayBoard/getCustomerByParams',
        chartDataKey: 'quantity',
        chartColumns: ['客户', '次数'],
        chartType: 'bar',
        dataCondition: {},
        chartLegendKey: 'name',
        chartXKey: 'name',
        dataZoom: true,
        searches: [
          {
            type: 'cascader',
            key: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '设备分类'
          }
        ],
        columnsType: 'vertical'
      },
      {
        id: 44,
        name: '服务次数（维修）',
        type: 'table',
        dataUrl: 'displayBoard/private/displayBoard/getFacilityRepair',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            isStartName: true
          },
          {
            type: 'cascader',
            key: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columns: [
          {
            prop: 'code',
            searchKey: 'facilityIdStr',
            label: '设备编号',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'code',
            slotName: 'getDeepName',
            filterType: 'transfer',
            transferProps: {
              key: 'id',
              label: 'code'
            },
            dataUrl: 'field/facility/private/facility/getFacilityList'
          },
          {
            prop: 'name',
            label: '设备名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'quantity',
            label: '次数',
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 45,
        name: '服务次数（保养）',
        type: 'table',
        dataUrl: 'displayBoard/private/displayBoard/getFacilityUpkeep',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            isStartName: true
          },
          {
            type: 'cascader',
            key: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columns: [
          {
            prop: 'code',
            searchKey: 'facilityIdStr',
            label: '设备编号',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'code',
            slotName: 'getDeepName',
            filterType: 'transfer',
            transferProps: {
              key: 'id',
              label: 'code'
            },
            dataUrl: 'field/facility/private/facility/getFacilityList'
          },
          {
            prop: 'name',
            label: '设备名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'quantity',
            label: '次数',
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 46,
        name: '服务次数（点检）',
        type: 'table',
        dataUrl: 'displayBoard/private/displayBoard/getFacilityInspection',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            isStartName: true
          },
          {
            type: 'cascader',
            key: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columns: [
          {
            prop: 'code',
            searchKey: 'facilityIdStr',
            label: '设备编号',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'code',
            slotName: 'getDeepName',
            filterType: 'transfer',
            transferProps: {
              key: 'id',
              label: 'code'
            },
            dataUrl: 'field/facility/private/facility/getFacilityList'
          },
          {
            prop: 'name',
            label: '设备名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'quantity',
            label: '次数',
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: '46-2',
        name: '当前设备分布',
        type: 'charts',
        dataUrl: 'statistics/private/facilityStatistics/getFacilityDistribution',
        chartDataKey: 'count',
        chartColumns: ['状态', '数量'],
        chartType: 'bar',
        chartLegendKey: 'sign',
        chartXKey: 'sign',
        optionKey: 'deviceStatus',
        dataCondition: {},
        searches: [
          {
            type: 'cascader',
            key: 'equipmentClassId',
            asKey: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columnsType: 'vertical'
      },
      {
        id: '46-3',
        name: '设备质保状态',
        type: 'chartsAndTable',
        dataUrl: 'statistics/private/facilityStatistics/getFacilityWarrantyStatusStatisticList',
        chartType: 'pie',
        chartDataKey: 'count',
        chartLegendKey: 'sign',
        chartXKey: 'sign',
        optionKey: 'quanlityInfoType',
        dataCondition: { beginTime: dayjs().format('YYYY-MM-DD'), endTime: dayjs().format('YYYY-MM-DD'), rightBeginTime: dayjs().format('YYYY-MM-DD'), rightEndTime: dayjs().format('YYYY-MM-DD') },
        hiddenInit: true,
        searches: [
          {
            type: 'cascader',
            key: 'equipmentClassId',
            asKey: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          },
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            placeholder: '选择日期/默认当天'
          },
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            placeholder: '选择对比日期/默认当天',
            keys: ['rightBeginTime', 'rightEndTime'],
            className: 'date-right'
          }
        ],
        columns: [
          {
            prop: 'sign',
            label: '质保状态',
            slotName: 'quanlityInfoType',
            sortable: false,
            hiddenSearch: true,
            width: 120
          },
          {
            prop: 'differenceValue',
            label: '差值',
            sortable: false,
            hiddenSearch: true,
            width: 60
          }
        ]
      },
      {
        id: '46-4',
        name: '设备月度环比',
        type: 'charts',
        dataUrl: 'statistics/private/facilityStatistics/getFacilityWarrantyStatusGroupByMonthList',
        chartDataKey: 'count',
        chartColumns: ['时间', '数量'],
        chartType: 'bar',
        chartLegendKey: 'sign',
        optionKey: 'warrantyStatus',
        chartXKey: 'time',
        dataCondition: { beginTime: getDateRange(4, 'YYYY-MM-DD')[0], endTime: getDateRange(4, 'YYYY-MM-DD')[1] },
        searches: [
          {
            type: 'cascader',
            key: 'equipmentClassId',
            asKey: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columnsType: 'vertical'
      }
    ]
  },
  {
    name: '保养统计',
    key: 'maintenanceStatistics',
    children: [
      {
        id: 47,
        name: '保养执行情况',
        type: 'table',
        size: 999,
        dataUrl: 'field/facility/invoices/private/facilityUpkeepWorkOrder/getFacilityUpkeepCount',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            isStartName: true
          },
          {
            type: 'cascader',
            key: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columns: [
          {
            prop: 'code',
            label: '设备编号',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'name',
            label: '设备名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'sn',
            label: 'SN',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'customerName',
            label: '客户/现场',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'quantity',
            label: '计划保养次数',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'actualQuantity',
            label: '实际保养次数',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'carryOut',
            label: '保养执行率',
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 48,
        name: '客户现场保养执行情况',
        type: 'table',
        size: 999,
        dataUrl: 'field/facility/invoices/private/facilityUpkeepWorkOrder/getUpkeepCount',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            isStartName: true
          },
          {
            type: 'cascader',
            key: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columns: [
          {
            prop: 'customerName',
            label: '客户/现场',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'quantity',
            label: '计划保养次数',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'actualQuantity',
            label: '实际保养次数',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'carryOut',
            label: '保养执行率',
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 49,
        name: '保养备件使用情况',
        type: 'charts',
        dataUrl: 'field/facility/invoices/private/facilityUpkeepWorkOrder/getFacilitySpareCount',
        chartDataKey: 'quantity',
        chartColumns: ['设备', '次数'],
        chartType: 'bar',
        dataCondition: {},
        chartLegendKey: 'name',
        chartXKey: 'name',
        dataZoom: true,
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'cascader',
            key: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columnsType: 'vertical'
      }
    ]
  },
  {
    name: '点检统计',
    key: 'spotCheckStatisticss',
    children: [
      {
        id: 50,
        name: '点检执行情况',
        type: 'table',
        dataUrl: 'field/facility/invoices/private/facilityUpkeepWorkOrder/getFacilityInspectionCount',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            isStartName: true
          },
          {
            type: 'cascader',
            key: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columns: [
          {
            prop: 'code',
            searchKey: 'facilityIdStr',
            label: '设备编号',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'code',
            slotName: 'getDeepName',
            filterType: 'transfer',
            transferProps: {
              key: 'id',
              label: 'code'
            },
            dataUrl: 'field/facility/private/facility/getFacilityList'
          },
          {
            prop: 'name',
            label: '设备名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'sn',
            label: 'SN',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'customerName',
            label: '客户/现场',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'quantity',
            label: '计划点检次数',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'actualQuantity',
            label: '实际点检次数',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'carryOut',
            label: '点检执行率',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'exceptionCount',
            label: '异常次数',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'carryOutException',
            label: '点检异常率',
            sortable: false,
            hiddenSearch: true
          }
        ]
      }
      // {
      //   id: 51,
      //   name: '客户/现场点检执行情况',
      //   type: 'table',
      //   dataUrl: 'field/facility/invoices/private/facilityUpkeepWorkOrder/getInspectionCount',
      //   dataCondition: { day: dayjs().format('YYYY-MM-DD') },
      //   searches: [
      //     {
      //       type: 'dateRange',
      //       formatDateType: 'YYYY-MM-DD',
      //       canNoSelect: true,
      //       isStartName: true
      //     },
      //     {
      //       type: 'cascader',
      //       key: 'equipmentClassIds',
      //       dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
      //       dataKey: 'equipmentClassList',
      //       props: {
      //         props: {
      //           label: 'name',
      //           value: 'id',
      //           checkStrictly: true,
      //           multiple: true
      //         }
      //       },
      //       placeholder: '设备分类'
      //     }
      //   ],
      //   columns: [
      //     {
      //       prop: 'customerName',
      //       label: '客户/现场',
      //       sortable: false,
      //       hiddenSearch: true
      //     },
      //     {
      //       prop: 'quantity',
      //       label: '计划点检次数总和',
      //       sortable: false,
      //       hiddenSearch: true
      //     },
      //     {
      //       prop: 'actualQuantity',
      //       label: '实际点检次数总和',
      //       sortable: false,
      //       hiddenSearch: true
      //     },
      //     {
      //       prop: 'carryOut',
      //       label: '点检执行率',
      //       sortable: false,
      //       hiddenSearch: true
      //     },
      //     {
      //       prop: 'exceptionCount',
      //       label: '异常次数',
      //       sortable: false,
      //       hiddenSearch: true
      //     },
      //     {
      //       prop: 'carryOutException',
      //       label: '点检异常率',
      //       sortable: false,
      //       hiddenSearch: true
      //     }
      //   ]
      // }
    ]
  },
  {
    name: '维修统计',
    key: 'repairStatistics',
    children: [
      {
        id: 52,
        name: '维修情况',
        type: 'table',
        size: 999,
        dataUrl: 'field/facility/invoices/private/facilityUpkeepWorkOrder/getFacilityRepairCount',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            isStartName: true
          },
          {
            type: 'cascader',
            key: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columns: [
          {
            prop: 'code',
            searchKey: 'facilityIdStr',
            label: '设备编号',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'code',
            slotName: 'getDeepName',
            filterType: 'transfer',
            transferProps: {
              key: 'id',
              label: 'code'
            },
            dataUrl: 'field/facility/private/facility/getFacilityList'
          },
          {
            prop: 'name',
            label: '设备名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'sn',
            label: 'SN',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'customerName',
            label: '客户/现场',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'quantity',
            label: '维修次数',
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 53,
        name: '设备备件使用情况',
        type: 'charts',
        dataUrl: 'field/facility/invoices/private/facilityUpkeepWorkOrder/getFacilityReformSpareCount',
        chartDataKey: 'quantity',
        chartColumns: ['设备', '次数'],
        chartType: 'bar',
        dataCondition: {},
        chartLegendKey: 'name',
        chartXKey: 'name',
        dataZoom: true,
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'cascader',
            key: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          },
          {
            type: 'select',
            key: 'facilityIdStr',
            dataUrl: 'field/facility/private/facility/getFacilityList',
            dataKey: 'FacilityList',
            placeholder: '请选择设备编号',
            props: {
              multiple: true
            }
          }
        ],
        columnsType: 'vertical'
      }
    ]
  },
  {
    name: '备件统计',
    key: 'sparePartsStatistics',
    children: [
      {
        id: 54,
        name: '用量排行',
        type: 'charts',
        dataUrl: 'field/facility/invoices/private/facilityUpkeepWorkOrder/getUseAnalysis',
        chartDataKey: 'quantity',
        chartColumns: ['物料', '数量'],
        chartType: 'bar',
        dataCondition: { beginTime: getDateRange(0, 'YYYY-MM-DD')[0], endTime: dayjs().format('YYYY-MM-DD') },
        chartLegendKey: 'name',
        chartXKey: 'name',
        dataZoom: true,
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          }
        ],
        columnsType: 'vertical'
      },
      {
        id: 55,
        name: '存量分析',
        type: 'charts',
        dataUrl: 'statistics/private/facilityStatistics/getMaterialsInventoryAnalysisList',
        chartDataKey: 'quantity',
        chartType: 'pie',
        chartLegendKey: 'materialsCode',
        chartXlabels: 'materialsName',
        chartOverlap: true,
        dataCondition: { beginTime: getDateRange(0, 'YYYY-MM-DD')[0], endTime: dayjs().format('YYYY-MM-DD') },
        searches: [
          {
            type: 'select',
            key: 'warehouseIdStr',
            dataUrl: 'warehouse/private/warehouse/getWarehouseList',
            dataKey: 'warehouseIdStr',
            placeholder: '请选择仓库',
            props: {
              multiple: true
            }
          }
        ]
      },
      {
        id: 56,
        name: '备件统计表',
        type: 'table',
        dataUrl: 'warehouse/kiki/private/warehouseMaterialsBatKiki/getWarehouseMaterialsTotalList',
        dataCondition: { beginTime: getDateRange(0, 'YYYY-MM-DD')[0], endTime: dayjs().format('YYYY-MM-DD'), isShowZero: 0 },
        searches: [],
        columns: [
          {
            prop: 'materialsCode',
            label: '物料编号',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'materialsName',
            label: '物料名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'warehouseName',
            searchKey: 'warehouseIds',
            label: '仓库',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'warehouseName',
            slotName: 'getDeepName',
            filterType: 'transfer',
            dataUrl: 'warehouse/private/warehouse/getWarehouseList'
          },
          {
            prop: 'materialsUnit',
            label: '单位',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'availableQuantity',
            label: '可用数量',
            sortable: false,
            hiddenSearch: true
          }
        ]
      },
      {
        id: '56-2',
        name: '备件用量分布',
        type: 'table',
        dataUrl: 'statistics/private/facilityStatistics/getSparePartGroupByMonthList',
        dataCondition: { beginTime: getDateRange(8, 'YYYY-MM-DD')[0], endTime: getDateRange(8, 'YYYY-MM-DD')[1] },
        searches: [
          {
            type: 'select',
            key: 'materialsId',
            dataUrl: 'basicData/materials/private/materials/getMaterialsList',
            dataKey: 'materialsList',
            placeholder: '请选择物料',
            props: {
              multiple: true
            }
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columns: [
          {
            prop: 'materialsCode',
            label: '物料编号',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'materialsName',
            label: '物料名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'customerName',
            label: '客户/现场',
            sortable: false,
            hiddenSearch: true
          },
          ...getMonthColumns()
        ]
      }
    ]
  },
  {
    name: '工单统计',
    key: 'workOrderStatistics',
    children: [
      {
        id: 57,
        name: '新增工单（默认昨日）',
        type: 'charts',
        dataUrl: 'statistics/private/facilityStatistics/getAddWorkOrderCount',
        chartDataKey: 'count',
        chartColumns: ['类型', '数量'],
        chartType: 'bar',
        chartLegendKey: 'sign',
        chartXKey: 'sign',
        dataCondition: { beginTime: getDateRange(7, 'YYYY-MM-DD')[0], endTime: getDateRange(7, 'YYYY-MM-DD')[1] },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'cascader',
            key: 'equipmentClassId',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
                // multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columnsType: 'vertical'
      },
      {
        id: 58,
        name: '工单月度环比',
        type: 'charts',
        dataUrl: 'statistics/private/facilityStatistics/getAddWorkOrderCountGroupByMonth',
        chartDataKey: 'count',
        chartColumns: ['时间', '数量'],
        chartType: 'bar',
        chartLegendKey: 'sign',
        chartXKey: 'key',
        chartListKey: 'monthList',
        dataCondition: { beginTime: getDateRange(4, 'YYYY-MM-DD')[0], endTime: getDateRange(4, 'YYYY-MM-DD')[1] },
        searches: [
          {
            type: 'cascader',
            key: 'equipmentClassId',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
                // multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columnsType: 'vertical'
      },
      {
        id: 59,
        name: 'Failure rate 失效率',
        type: 'charts',
        dataUrl: 'statistics/private/facilityStatistics/getFailureRatioGroupByMonth',
        chartDataKey: 'ratio',
        chartColumns: ['时间', '百分比'],
        chartType: 'bar',
        chartLegendKey: 'time',
        chartXKey: 'time',
        dataCondition: { beginTime: getDateRange(4, 'YYYY-MM-DD')[0], endTime: getDateRange(4, 'YYYY-MM-DD')[1] },
        searches: [
          {
            type: 'cascader',
            key: 'equipmentClassId',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
                // multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columnsType: 'vertical'
      }
    ]
  },
  {
    name: '需求统计',
    key: 'demandStatistics',
    children: [
      {
        id: 60,
        name: '当前需求分布',
        type: 'charts',
        dataUrl: 'statistics/private/facilityStatistics/getCurrentDemandDistributionList',
        chartDataKey: 'count',
        chartColumns: ['状态', '数量'],
        chartType: 'bar',
        chartLegendKey: 'sign',
        chartXKey: 'sign',
        optionKey: 'demandDistribStatus',
        dataCondition: {},
        searches: [],
        columnsType: 'vertical'
      },
      {
        id: 61,
        name: 'TAT月度环比',
        type: 'charts',
        dataUrl: 'statistics/private/facilityStatistics/getCurrentDemandTATGroupByMonth',
        chartDataKey: 'hour',
        chartColumns: ['时间', '数值'],
        chartType: 'bar',
        chartLegendKey: 'sign',
        optionKey: 'tatStatus',
        chartXKey: 'time',
        dataCondition: { beginTime: getDateRange(4, 'YYYY-MM-DD')[0], endTime: getDateRange(4, 'YYYY-MM-DD')[1] },
        searches: [
          {
            type: 'select',
            key: 'type',
            placeholder: '请选择服务内容',
            options: [
              {
                id: 0,
                name: '维修'
              },
              {
                id: 1,
                name: '保养'
              },
              {
                id: 2,
                name: '装机'
              },
              {
                id: 3,
                name: '改造'
              },
              {
                id: 6,
                name: '点检'
              }
            ]
          }
        ],
        columnsType: 'vertical'
      }
    ]
  }
]
