import { transformColumnsToForm } from '@/utils'
import { columns } from './columns'

export const formList = transformColumnsToForm(columns)
export const descFormList = [
  {
    colSpan: 24,
    key: 'remark',
    label: '内容说明',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '内容说明'
    }
  },
  {
    colSpan: 24,
    key: 'partList',
    label: '零件清单',
    tagName: 'div',
    required: false,
    children: [{
      customSlots: ['partList']
    }]
  },
  {
    colSpan: 24,
    key: 'otherRemark',
    label: '其他特殊要求',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过200字'
    }
  },
  {
    colSpan: 8,
    key: 'locale',
    label: '样品完成后测试地点',
    tagName: 'el-input'
  },
  {
    colSpan: 24,
    label: '附件',
    key: 'assembles',
    tagName: 'div',
    required: false,
    children: [{
      customSlots: ['drawing']
    }]
  }
]

