<template>
  <big-screen-container ref="container">
    <dv-loading v-if="loading" />
    <div class="top-container">
      <div class="title-wrapper">
        <span class="title">{{ name[$route.params.type] }}</span>
        <img src="https://oss.mubyte.cn/static/%E5%A4%B4%E9%83%A8%402x.png" alt="">
      </div>
      <div class="info">
        <div class="company-logo">
          <img src="https://oss.mubyte.cn/static/%E5%85%AC%E5%8F%B8%402x.png" alt="">
          <span>{{ companyInfo.name }}</span>
        </div>
        <div class="time-container">
          <img src="https://oss.mubyte.cn/static/date-b.png" alt="">
          <div class="date-time">
            <div class="date">{{ currentDate }}（{{ currentWeek }}）</div>
            <div class="time">{{ currentTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <product v-if="+$route.params.type === 1" ref="board" />
  </big-screen-container>
</template>

<script>
import dayjs from 'dayjs'
import BigScreenContainer from '@/components/BigScreenContainer/BigScreenContainer'
import Product from './components/product'

export default {
  name: 'BoardDetail',
  components: { Product, BigScreenContainer },
  data() {
    return {
      loading: false,
      currentDate: dayjs().format('YYYY-MM-DD'),
      currentTime: dayjs().format('HH:mm:ss'),
      weeks: ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      currentWeek: '',
      companyInfo: sessionStorage.getItem('companyInfo') ? JSON.parse(sessionStorage.getItem('companyInfo')) : {},
      name: { 1: '车间大屏' },
      fullScreen: false
    }
  },
  computed: {
    splitHeight() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 : this.$store.state.app.contentHeight - 289 - 45
    }
  },
  mounted() {
    this.fullAllScreen()
    this.$nextTick(this.$refs.board.getHeight)
    setInterval(this.$refs.board.reloadData, 1000 * 60 * 60)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.launchFullscreen)
  },
  methods: {
    fullAllScreen() {
      document.body.addEventListener('click', this.launchFullscreen)
      this.currentWeek = this.weeks[dayjs().day()]
      setInterval(() => {
        this.currentTime = dayjs().format('HH:mm:ss')
        this.currentDate = dayjs().format('YYYY-MM-DD')
        this.currentWeek = this.weeks[dayjs().day()]
        if (this.$refs.line) {
          this.$refs.line.resize()
        }
        if (this.$refs.histogram) {
          this.$refs.line.resize()
        }
      }, 1000)
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },

    launchFullscreen() {
      if (this.$refs.board !== undefined) {
        this.$refs.board.getHeight()
      }
      const element = document.documentElement
      if (element.requestFullscreencreen) {
        element.requestFullScreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen()
      } else if (element.msRequestFullScreen) {
        element.msRequestFullScreen()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.top-container {
  color: #FFFFFF;

  .title-wrapper {
    text-align: center;
    position: relative;

    .title {
      font-size: 33px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      width: 80%;
      margin: 0 auto;
    }
  }

  .info {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5%;
    position: relative;
    top: -20px;

    .time-container {
      position: relative;
      display: inline-block;
      width: 16%;
      top: 2px;

      img {
        width: 100%;
      }

      .date-time {
        position: absolute;
        top: 55%;
        left: 60%;
        transform: translate(-50%, -50%);
        color: #00B7EF;

        .date {
          font-size: 18px;
          word-wrap: normal;
          white-space: nowrap;
        }

        .time {
          font-size: 27px;
          font-weight: 700;
        }
      }
    }

    .company-logo {
      display: inline-flex;
      align-items: center;

      img {
        width: 28px;
        margin-right: 10px;
      }

      span {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 700;
        color: #CED8FF;
        line-height: 70px;
        background: linear-gradient(180deg, #B8CCFF 0%, #4465EC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.status-board {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 44px;
}

.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .center {
    .checkCard {
      width: 30%;
      height:70px;
      border-radius: 4px;
      border: 1px solid #DDE3EB;
      margin-right: 0px;
      margin-left:3%;
      margin-top: 10px;
      padding: 6px 4px 6px 10px;
      background: #eceef2;
      display:inline-flex;
      cursor: pointer;
      ::v-deep .el-checkbox__input {
        top: 20px;
      }
      ::v-deep .el-checkbox__label {
        display:none;
      }
      ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label{
        color:#606266
      }
      .el-divider--vertical {
        background: #DDE3EB;
        height: 58px;
      }
    }

    .flex-wrapper {
      padding-right: 15px;
      padding-bottom: 15px;
      .title{
       overflow: hidden;
       text-overflow: ellipsis;
       display: -webkit-box;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       font-size: 14px;
      }
      .description {
        font-size: 12px;
        color: #9FA1B7;
        margin-top: 5px;
      }
    }
  }

  .right {
    width: 200px;
    background-color: #DDE3EB;
    padding-left: 15px;
    margin-left:10px;
    overflow:auto;
    .paramType {
      font-size: 14px;
      margin-top: 10px;
      color: #858991;
    }
    }
    .chooseParame{
     .el-checkbox:last-of-type{
      margin-right: 30px;
      margin-bottom: 10px;
    }
  }
}
</style>
