var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-wrappers" },
    [
      _c("div", { staticClass: "detail-header flex-sbc" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [
            _c("img", {
              staticStyle: { width: "8px" },
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c(
              "span",
              { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
              [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "detail-body" }, [
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("基础信息")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c(
                "el-row",
                { staticClass: "view-list", attrs: { gutter: 24 } },
                _vm._l(_vm.columns, function(item) {
                  return _c("el-col", { key: item.id, attrs: { span: 6 } }, [
                    _c("div", { staticClass: "view-list__label" }, [
                      _vm._v(_vm._s(item.label))
                    ]),
                    _c("div", { staticClass: "view-list__value" }, [
                      _vm._v(_vm._s(_vm.getValue(item) || "-"))
                    ])
                  ])
                }),
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("服务描述")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _vm.isView
                ? _c(
                    "el-row",
                    { staticClass: "view-list", attrs: { gutter: 24 } },
                    _vm._l(_vm.descFormList, function(item) {
                      return _c(
                        "el-col",
                        { key: item.id, attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "view-list__label" }, [
                            _vm._v(_vm._s(item.label))
                          ]),
                          item.key !== "facilityHistoricalAlarmRecordList"
                            ? _c("div", { staticClass: "view-list__value" }, [
                                _vm._v(_vm._s(_vm.getDescValue(item) || "-"))
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                : _c("MFormBuilder", {
                    ref: "descForm",
                    attrs: {
                      "form-list": _vm.descFormList,
                      "form-data":
                        _vm.baseFormData.facilityServiceDescriptionList,
                      "label-position": "right"
                    }
                  }),
              _c(
                "div",
                { staticClass: "desc-table", class: { edit: !_vm.isView } },
                [
                  _c("MTable", {
                    ref: "descTable",
                    attrs: {
                      "show-page": false,
                      height: 202,
                      columns: _vm.descColumns,
                      data: _vm.descTableList,
                      "columns-setting": false
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "faultTime",
                          fn: function(ref) {
                            var row = ref.row
                            return _vm.isView
                              ? _c("div", {}, [
                                  _c("div", [_vm._v(_vm._s(row.faultTime))])
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "table-cell" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "required-star" },
                                      [_vm._v("*")]
                                    ),
                                    _c("el-date-picker", {
                                      attrs: {
                                        type: "datetime",
                                        placeholder: "故障时间",
                                        format: "yyyy-MM-dd HH:mm",
                                        "value-format": "yyyy-MM-dd HH:mm"
                                      },
                                      model: {
                                        value: row.faultTime,
                                        callback: function($$v) {
                                          _vm.$set(row, "faultTime", $$v)
                                        },
                                        expression: "row.faultTime"
                                      }
                                    })
                                  ],
                                  1
                                )
                          }
                        },
                        {
                          key: "faultCode",
                          fn: function(ref) {
                            var row = ref.row
                            return _vm.isView
                              ? _c("div", {}, [
                                  _c("div", [_vm._v(_vm._s(row.faultCode))])
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "table-cell" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "required-star" },
                                      [_vm._v("*")]
                                    ),
                                    _c("el-input", {
                                      attrs: { placeholder: "输入故障码" },
                                      model: {
                                        value: row.faultCode,
                                        callback: function($$v) {
                                          _vm.$set(row, "faultCode", $$v)
                                        },
                                        expression: "row.faultCode"
                                      }
                                    })
                                  ],
                                  1
                                )
                          }
                        },
                        {
                          key: "detailedDeion",
                          fn: function(ref) {
                            var row = ref.row
                            return _vm.isView
                              ? _c("div", {}, [
                                  _c("div", [_vm._v(_vm._s(row.detailedDeion))])
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "table-cell" },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "输入详细描述" },
                                      model: {
                                        value: row.detailedDeion,
                                        callback: function($$v) {
                                          _vm.$set(row, "detailedDeion", $$v)
                                        },
                                        expression: "row.detailedDeion"
                                      }
                                    })
                                  ],
                                  1
                                )
                          }
                        },
                        {
                          key: "action",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c(
                              "div",
                              {},
                              [
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.delTableList($index)
                                        }
                                      }
                                    },
                                    [_vm._v("移除")]
                                  )
                                ]
                              ],
                              2
                            )
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  !_vm.isView
                    ? _c(
                        "el-button",
                        {
                          staticClass: "e-btn",
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.addTableList }
                        },
                        [_vm._v("添加")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("备件使用")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c("MTable", {
                ref: "descTable",
                attrs: {
                  "show-page": false,
                  height: 212,
                  columns: _vm.marterialColumns,
                  data: _vm.marterialTableList,
                  "columns-setting": false
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "index",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "usageQuantity",
                      fn: function(ref) {
                        var row = ref.row
                        return _vm.isView
                          ? _c("div", {}, [
                              _c("div", [_vm._v(_vm._s(row.usageQuantity))])
                            ])
                          : _c(
                              "div",
                              { staticClass: "table-cell" },
                              [
                                _c("span", { staticClass: "required-star" }, [
                                  _vm._v("*")
                                ]),
                                _c("el-input-number", {
                                  attrs: {
                                    min: 1,
                                    "controls-position": "right",
                                    step: 1
                                  },
                                  model: {
                                    value: row.usageQuantity,
                                    callback: function($$v) {
                                      _vm.$set(row, "usageQuantity", $$v)
                                    },
                                    expression: "row.usageQuantity"
                                  }
                                })
                              ],
                              1
                            )
                      }
                    },
                    {
                      key: "quantity",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(row.quantity || "-"))
                        ])
                      }
                    },
                    {
                      key: "materialsType",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(row.materialsType || "-"))
                        ])
                      }
                    },
                    {
                      key: "materialsSpecifications",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(row.materialsSpecifications || "-"))
                        ])
                      }
                    },
                    {
                      key: "batchNumber",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(row.batchNumber || "-"))
                        ])
                      }
                    },
                    {
                      key: "sequenceNumber",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(row.sequenceNumber || "-"))
                        ])
                      }
                    },
                    {
                      key: "warehouseName",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(row.warehouseName || "-"))
                        ])
                      }
                    },
                    {
                      key: "warehouseLocationName",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(row.warehouseLocationName || "-"))
                        ])
                      }
                    },
                    {
                      key: "ownershipSparePartsAssets",
                      fn: function(ref) {
                        var row = ref.row
                        return _vm.isView
                          ? _c("div", {}, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getSparePartsDesc(
                                      row.ownershipSparePartsAssets
                                    )
                                  )
                                )
                              ])
                            ])
                          : _c(
                              "div",
                              { staticClass: "table-cell" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: row.ownershipSparePartsAssets,
                                      callback: function($$v) {
                                        _vm.$set(
                                          row,
                                          "ownershipSparePartsAssets",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "row.ownershipSparePartsAssets"
                                    }
                                  },
                                  _vm._l(_vm.ownershipOption, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                      }
                    },
                    {
                      key: "action",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c(
                          "div",
                          {},
                          [
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.delMaterial($index)
                                    }
                                  }
                                },
                                [_vm._v("移除")]
                              )
                            ]
                          ],
                          2
                        )
                      }
                    }
                  ],
                  null,
                  true
                )
              }),
              !_vm.isView
                ? _c(
                    "el-button",
                    {
                      staticClass: "e-btn",
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onSelectMaterialsInventory }
                    },
                    [_vm._v("使用仓库中物料")]
                  )
                : _vm._e(),
              !_vm.isView
                ? _c(
                    "el-button",
                    {
                      staticClass: "e-btn",
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onSelectMaterials }
                    },
                    [_vm._v("使用其他物料")]
                  )
                : _vm._e()
            ],
            1
          )
        ]),
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("现场记录")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _vm.isView
                ? _c(
                    "el-row",
                    { staticClass: "view-list", attrs: { gutter: 24 } },
                    _vm._l(_vm.localFormList, function(item) {
                      return _c(
                        "el-col",
                        { key: item.id, attrs: { span: item.colSpan } },
                        [
                          _c("div", { staticClass: "view-list__label" }, [
                            _vm._v(_vm._s(item.label))
                          ]),
                          item.key == "affix"
                            ? _c(
                                "el-upload",
                                _vm._b(
                                  {
                                    class: { "upload-view": _vm.isView },
                                    attrs: {
                                      "file-list": _vm.attachments,
                                      disabled: ""
                                    }
                                  },
                                  "el-upload",
                                  _vm.uploadProps2,
                                  false
                                )
                              )
                            : item.key === "facilityServiceTimeMappingList"
                            ? _c(
                                "div",
                                _vm._l(_vm.executorList, function(item) {
                                  return _c(
                                    "el-row",
                                    {
                                      key: item.id,
                                      staticClass: "view-list",
                                      attrs: { gutter: 24 }
                                    },
                                    [
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "view-list__value" },
                                          [
                                            _vm._v(
                                              _vm._s(item.userNames || "-")
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "view-list__label" },
                                          [_vm._v("服务时间")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "view-list__value" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.startTime +
                                                  " ~ " +
                                                  item.endTime
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "view-list__label" },
                                          [_vm._v("服务时长")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "view-list__value" },
                                          [
                                            _vm._v(
                                              _vm._s(item.manHour || 0) + "小时"
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _c("div", { staticClass: "view-list__value" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getLocalValue(item) || "-") +
                                    " "
                                )
                              ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c(
                    "MFormBuilder",
                    {
                      ref: "localForm",
                      attrs: {
                        "form-list": _vm.localFormList,
                        "form-data": _vm.baseFormData.facilityFieldNotesList,
                        "label-position": "right"
                      }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "manHour" }, slot: "manHour" },
                        [_vm._v(_vm._s(_vm.manHour) + "小时")]
                      ),
                      _c(
                        "div",
                        {
                          attrs: { slot: "facilityServiceTimeMappingList" },
                          slot: "facilityServiceTimeMappingList"
                        },
                        [
                          _vm._l(_vm.executorList, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "custom-form" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "custom-form-item" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          placeholder: "添加协作人",
                                          filterable: ""
                                        },
                                        model: {
                                          value: item.userIds,
                                          callback: function($$v) {
                                            _vm.$set(item, "userIds", $$v)
                                          },
                                          expression: "item.userIds"
                                        }
                                      },
                                      _vm._l(_vm.executorUserList, function(q) {
                                        return _c("el-option", {
                                          key: q.id,
                                          attrs: { label: q.name, value: q.id }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "custom-form-item" },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: {
                                          change: function($event) {
                                            return _vm.onRadioChange(
                                              item.isSame,
                                              index
                                            )
                                          }
                                        },
                                        model: {
                                          value: item.isSame,
                                          callback: function($$v) {
                                            _vm.$set(item, "isSame", $$v)
                                          },
                                          expression: "item.isSame"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("服务时间同执行人")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [
                                            _c("el-date-picker", {
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder":
                                                  "选择服务开始时间",
                                                "end-placeholder":
                                                  "选择服务结束时间",
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm",
                                                format: "yyyy-MM-dd HH:mm",
                                                clearable: "",
                                                disabled: item.isSame === 1,
                                                "picker-options":
                                                  _vm.pickerOptions
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.datePickerChange(
                                                    index
                                                  )
                                                }
                                              },
                                              model: {
                                                value: item.date,
                                                callback: function($$v) {
                                                  _vm.$set(item, "date", $$v)
                                                },
                                                expression: "item.date"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("span", { staticClass: "hour" }, [
                                      _c("label", [_vm._v("服务时长")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.getTime(item)) +
                                          "小时 "
                                      )
                                    ]),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.delExecutorList(index)
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.addExecutor }
                            },
                            [_vm._v("添加")]
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-upload",
                        _vm._b(
                          {
                            class: { "upload-view": _vm.isView },
                            attrs: {
                              slot: "drawing",
                              "file-list": _vm.attachments
                            },
                            slot: "drawing"
                          },
                          "el-upload",
                          _vm.uploadProps2,
                          false
                        ),
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "primary", size: "small" } },
                            [_vm._v("上传文件")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ]),
        _vm.isView
          ? _c("div", { staticClass: "detail-card" }, [
              _c("div", { staticClass: "card-tit" }, [_vm._v("成本归属")]),
              _c(
                "div",
                { staticClass: "card-bot" },
                [
                  _vm.isView
                    ? _c(
                        "el-row",
                        { staticClass: "view-list", attrs: { gutter: 24 } },
                        [
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "view-list__label" }, [
                              _vm._v("人工费归属")
                            ]),
                            _vm.baseFormData.facilityCostOnnershipBean
                              ? _c("div", { staticClass: "view-list__value" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.baseFormData
                                          .facilityCostOnnershipBean
                                          .labopCost || "-"
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.baseFormData.facilityCostOnnershipBean
                    ? _c("MTable", {
                        ref: "costTable",
                        attrs: {
                          "show-page": false,
                          height: 202,
                          columns: _vm.costColumns,
                          data:
                            _vm.baseFormData.facilityCostOnnershipBean
                              .facilityCostOnnershipList || [],
                          "columns-setting": false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "followUpProcessing",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(_vm._s(row.followUpProcessing || "-"))
                                ])
                              }
                            }
                          ],
                          null,
                          false,
                          2093897838
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      !_vm.isView
        ? _c("div", { staticClass: "detail-footer" }, [
            _c(
              "div",
              { staticClass: "footer-ri" },
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.returns } },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.buttonLoading,
                      size: "small"
                    },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v("确定")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("select-materials", {
        ref: "selectMaterials",
        attrs: {
          visible: _vm.showMaterials,
          single: false,
          "is-add": true,
          "select-materials": _vm.selectMaterials
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials,
          addMaterial: _vm.addMaterial
        }
      }),
      _c("edit-materials", {
        ref: "editMaterials",
        on: { onSuccess: _vm.onSuccess }
      }),
      _c("select-materials-inventory", {
        ref: "selectMaterialInventory",
        attrs: { visible: _vm.showMaterialInventory, "operate-type": 3 },
        on: {
          "update:visible": function($event) {
            _vm.showMaterialInventory = $event
          },
          submitForm: _vm.submitMaterialInventory
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }