<template>
  <div class="auxiliary-detail-wrapper">
    <div class="auxiliary-detail-header flex-sbc">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px" />
        <span style="padding-left: 10px; cursor: pointer">工具详情</span>
      </div>
    </div>
    <div class="auxiliary-detail-body">
      <div class="detail-card">
        <div class="card-tit">工具基本信息</div>
        <div class="card-bot" style="padding: 15px">
          <div>
            <el-row class="view-list" style="padding: 10px 15px; color: #393d60" :gutter="24">
              <el-col v-for="(item, index) in columnss" :key="item.prop" :span="6" style="margin-bottom: 10px">
                <div style="color: #9597ae">{{ item.label }}</div>
                <div class="bot-tis">{{ getValue(item) || '-' }}</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 15px;">
      <el-tabs v-model="params.radio" type="border-card" tab-position="top" @tab-click="tabClick">
        <el-tab-pane label="使用记录" name="ToolLedgerDetail">
          <MTable
            v-if="params.radio === 'ToolLedgerDetail'"
            ref="mTable"
            :height="height"
            :columns="auxiliaryRecordColumns"
            :data="auxiliaryRecordList"
            :showPage="false"
          >
            <el-button slot="code"
                       slot-scope="{ row }"
                       style="white-space: pre-wrap"
                       type="text"
                       @click="viewDetail(row)">
              {{ row.code }}
            </el-button>
            <div slot="content" slot-scope="{ row }">
              {{ serviceType[row.content].name }}
            </div>
            <div slot="status" slot-scope="{ row }">
              {{ row.content === 3 ? reformStatus[row.status].name : row.status === 10 ? '已完成' : demandStatus[row.status].name }}
            </div>
            <div slot="serviceType" slot-scope="{ row }">
              {{ serviceContentType[row.serviceType].name }}
            </div>
          </MTable>
        </el-tab-pane>
      </el-tabs>
    </div>

  </div>
</template>

<script>
const empty = 'https://oss.mubyte.cn/static/empty.png'
import api from '@/api/toolManagement/toolLeader/index.js'
import { columns, auxiliaryRecordColumns,serviceType,serviceContentType,demandStatus, reformStatus } from './columns'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import TableDialog from '@/components/TableDialog/TableDialog'

export default {
  name: 'ToolLedgerDetail',
  components: { TableDialog },
  data() {
    return {
      api,
      columns,
      serviceType,
      serviceContentType,
      auxiliaryRecordColumns,
      reformStatus,
      demandStatus,
      params: {
        radio: 'ToolLedgerDetail'
      },
      columnss: [],
      equipmentDetail: {},
      auxiliaryRecordList: [],
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 148
    }
  },
  mounted() {
    this.getAuxiliaryMaterialDetail()
    this.columnss = this.columns.filter((item, index) => index > 0 && item.prop !== 'action')
  },
  methods: {
    // 查看需求详情
    viewDetail(row) {
      this.$router.push({
        name: 'demandCenterDetail',
        query: { id: row.id, isAll: 0 }
      })
    },
    async getAuxiliaryMaterialDetail() {
      const res = await api.getToolLedgerDetail({
        id: this.$route.params.id
      })
      consoleSelf.info("工具详情信息",res);
      if (res) {
        this.equipmentDetail = res
        this.auxiliaryRecordList = res.facilityDemandList
      }
    },
    getValue(row) {
      const result = this.equipmentDetail[row.prop]
      if (row.form && row.form.options && row.form.options.length > 0) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    back() {
      this.$router.push({ name: 'ToolLedger' })
    },
    tabClick() {}
  }
}
</script>

<style scoped lang="scss">
.auxiliary-detail-wrapper {
  .auxiliary-detail-header {
    width: 100%;
    padding: 10px 0;
  }
  .auxiliary-detail-body {
    padding-top: 10px;
    .card-bot {
      .info {
        padding: 20px;
        border-bottom: 1px solid #dde3eb;
      }
    }
  }

  ::v-deep .configuration-parameter__main {
    padding: 0;
  }
}
</style>
