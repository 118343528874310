<template>
  <div class="equipment-board-container">
    <div class="top">
      <div class="inspection-finish">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">
          日异常发生数趋势图（近7天）
        </div>
        <ve-line
          height="300px"
          :data="inspectionFinishChartData"
          :settings="lineChartSettings"
          :extend="lineChartExtend"
        />
      </div>
      <div class="equipment-info">
        <div class="card-info">
          <img src="https://oss.mubyte.cn/static/havedeal.png" alt="">
          <dv-digital-flop :config="getDigitalConfig(digital.haveQuantity, ['#FFF', '#FFF'])" style="width: 162px;" />
          <div>已处理</div>
        </div>
        <div class="card-info">
          <img src="https://oss.mubyte.cn/static/deal.png" alt="">
          <dv-digital-flop :config="getDigitalConfig(digital.quantity, ['#FFF', '#FFF'])" style="width: 162px;" />
          <div>处理中</div>
        </div>
        <div class="card-info">
          <img src="https://oss.mubyte.cn/static/waitdeal.png" alt="">
          <dv-digital-flop :config="getDigitalConfig(digital.waitQuantity, ['#FFF', '#FFF'])" style="width: 162px;" />
          <div>待处理</div>
        </div>
      </div>
      <div class="bj-sort">
        <div class="gradient-title" style="background: linear-gradient(90deg, #0E8BAF 0%, rgba(96, 127, 255, 0) 100%);">当月异常原因占比</div>
        <ve-pie
          height="300px"
          :settings="pieChartSettings2"
          :extend="pieChartExtend2"
          :data="errorTypeChartData"
        />
      </div>

    </div>
    <div class="center">
      <div class="inspection-error">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">异常平均处理时长（近半年）</div>
        <ve-line
          height="300px"
          :data="inspectionErrorChartData"
          :settings="lineChartSettings"
          :extend="lineChartExtend"
        />
      </div>
      <div class="fault-sort">
        <div class="gradient-title" style="background: linear-gradient(90deg,  #7410FF 0%, rgba(96, 127, 255, 0) 100%);">异常原因排行（本月）</div>
        <ve-bar
          height="300px"
          :data="faultSortChartData"
          :settings="barChartSettings"
          :extend="barChartExtend3"
        />
      </div>
      <div class="fault-sort">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">月度异常次数统计（近半年）</div>
        <ve-histogram
          height="300px"
          :data="maintenanceChartData"
          :settings="barChartSettings"
          :extend="barChartExtend"
        />
      </div>
    </div>
    <div class="bottom">
      <div class="inspection-task">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);margin-bottom: 10px">待处理异常<span>（{{ waitTaskDataTotal }}）</span></div>
        <scroll-board :config="scrollBoardConfig" :style="{width:'100%', height: tableHeight + 'px'}">
          <span slot="status" slot-scope="{ index }" class="status-board-r" :style="{ background: waitTaskData[index] ? waitTaskData[index].status === 0 ? '#4B1E00' : waitTaskData[index].status === 1 ? '#015815': '#343434' : ''}">
            {{ waitTaskData[index] ? waitTaskData[index].status === 0 ? '待处理' : waitTaskData[index].status === 1 ? '处理中' : '已处理' : '' }}
          </span>
        </scroll-board>
      </div>
      <div class="inspection-task">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);margin-bottom: 10px">处理中异常<span>（{{ dealTaskDataTotal }}）</span></div>
        <scroll-board :config="scrollBoardConfig1" :style="{width:'100%', height: tableHeight + 'px'}">
          <span slot="status" slot-scope="{ index }" class="status-board-r" :style="{ background: dealTaskData[index] ? dealTaskData[index].status === 0 ? '#4B1E00' : dealTaskData[index].status === 1 ? '#015815': '#343434' : ''}">
            {{ dealTaskData[index] ? dealTaskData[index].status === 0 ? '待处理' : dealTaskData[index].status === 1 ? '处理中' : '已处理' : '' }}
          </span>
        </scroll-board>
      </div>
      <div class="inspection-task">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);margin-bottom: 10px">已处理异常<span>（{{ haveTaskDataTotal }}）</span></div>
        <scroll-board :config="scrollBoardConfig2" :style="{width:'100%', height: tableHeight + 'px'}">
          <span slot="status" slot-scope="{ index }" class="status-board-r" :style="{ background: haveTaskData[index] ? haveTaskData[index].status === 0 ? '#4B1E00' : haveTaskData[index].status === 1 ? '#015815': '#343434' : ''}">
            {{ haveTaskData[index] ? haveTaskData[index].status === 0 ? '待处理' : haveTaskData[index].status === 1 ? '处理中' : '已处理' : '' }}
          </span>
        </scroll-board>
      </div>
    </div>
  </div>
</template>

<script>
import mix from '@/views/statistics-board/board-detail/components/mixin'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
import api from '@/api/statistics-board/all-statistics'
import { baseUrl, colorList } from '@/views/statistics-board/board-detail/components/base-setting'
import dayjs from 'dayjs'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

const scrollBoardSettingDefault = {
  carousel: 'page',
  headerHeight: 32,
  hoverPause: false,
  waitTime: 10000,
  headerBGC: '#222222',
  oddRowBGC: '#222222',
  evenRowBGC: 'transparent'
}
export default {
  name: 'Equipment',
  components: { scrollBoard },
  mixins: [mix],
  data() {
    return {
      scrollBoardSettingDefault,
      digital: {
        waitQuantity: 0,
        quantity: 0,
        haveQuantity: 0
      },
      pieChartSettings2: {},
      pieChartExtend2: {
        color: colorList,
        legend: {
          top: 15,
          show: true,
          orient: 'horizontal',
          left: 10,
          textStyle: {
            width: 10,
            color: '#fff',
            fontSize: 14,
            left: 20,
            overflow: 'break'
          }
        },
        series: {
          top: 40,
          radius: ['52%', '70%'],
          center: ['50%', '50%'],
          label: {
            formatter: '\n\n {b} ',
            padding: [0, -25],
            alignTo: 'labelLine',
            color: '#fff'
          },
          labelLine: {
            // length2: 40
          }
        },
        tooltip: {
          trigger: 'item'
          // appendToBody: true
        }
      },
      errorTypeChartData: {
        columns: ['类型', '数量'],
        rows: []
      },
      inspectionFinishChartData: {
        columns: ['月份', '数量'],
        rows: []
      },
      waitTaskData: [],
      dealTaskData: [],
      haveTaskData: [],
      waitTaskDataTotal: 0,
      dealTaskDataTotal: 0,
      haveTaskDataTotal: 0,
      inspectionErrorChartData: {
        columns: ['月份', '小时'],
        rows: []
      },
      lineChartExtend2: [],
      maintenanceChartData: {
        columns: ['月份', '数量'],
        rows: []
      },
      bgSortChartData: {
        columns: ['名称', '数量'],
        rows: []
      },
      barChartExtend2: {},
      faultSortChartData: {
        columns: ['名称', '数量'],
        rows: []
      },
      barChartExtend3: {},
      scrollBoardConfig: {
        ...scrollBoardSettingDefault,
        columnWidth: [70, 130, 320, 110, 150],
        header: ['序号', '类型', '描述', '状态', '发起人'],
        data: []
      },
      scrollBoardConfig1: {
        ...scrollBoardSettingDefault,
        columnWidth: [70, 130, 320, 110, 150],
        header: ['序号', '类型', '描述', '状态', '发起人'],
        data: []
      },
      scrollBoardConfig2: {
        ...scrollBoardSettingDefault,
        columnWidth: [70, 130, 320, 110, 150],
        header: ['序号', '类型', '描述', '状态', '发起人'],
        data: []
      },
      areaIds: [],
      tableHeight: 100,
      repairStatus: {
        2: {
          name: '待处理',
          color: '#4B1E00'
        },
        3: {
          name: '处理中',
          color: '#015815'
        },
        4: {
          name: '已处理',
          color: '#343434'
        }
      }

    }
  },
  beforeMount() {
    Object.assign(this.lineChartExtend, {
      color: ['#607FFF', '#44D7B6'],
      series: {
        smooth: false
      }
    })
    this.barChartExtend2 = this._.cloneDeep(this.barChartExtend)
    this.barChartExtend2.series.showBackground = false
    delete this.barChartExtend2.series.barWidth
    this.barChartExtend2.series.itemStyle.color = {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 0,
      colorStops: [{
        offset: 1, color: 'rgba(84, 203, 164, 1)' // 0% 处的颜色
      }, {
        offset: 0, color: 'rgba(45, 45, 189, 1)' // 100% 处的颜色
      }]
    }
    this.barChartExtend3 = this._.cloneDeep(this.barChartExtend2)
    this.barChartExtend3.series.itemStyle.color = {
      type: 'linear',
      x: 0,
      y: 1,
      x2: 1,
      y2: 1,
      colorStops: [{
        offset: 1, color: '#60CFFF' // 0% 处的颜色
      }, {
        offset: 0, color: '#7410FF' // 100% 处的颜色
      }]
    }
  },
  mounted() {
    this.reloadData()
  },
  methods: {
    getDigitalConfig(number, color, content = '{nt}') {
      return {
        number: [Number(number)],
        content,
        formatter: this.formatter,
        style: {
          fontSize: 55,
          gradientColor: color,
          gradientType: 'linear',
          gradientParams: [0, 25, 0, 50],
          gradientWith: 'fill'
        }
      }
    },
    async reloadData(areaIds = '') {
      this.areaIds = areaIds
      // 近7天
      this.getInspectionFinishChartData()
      this.getInspectionErrorChartData()
      // 异常原因排行
      this.getFaultSortChartData()
      // 近半年月度异常
      this.getMaintenanceChartData()
      // 状态数量
      this.getEquipmentInfo()
      // 不同状态列表
      this.getTablePutData(baseUrl + 'getListByStatus', {
        status: 0,
        startTime: dayjs().startOf('month').format('YYYY-MM-DD 00:00:00'),
        endTime: dayjs().endOf('month').format('YYYY-MM-DD 23:59:59'),
        idList: areaIds ? areaIds.split('^') : []
      }, {
        key: 'scrollBoardConfig',
        key2: 'waitTaskData',
        columns: this.scrollBoardConfig.header,
        columnWidth: [70, 130, 320, 110, 150],
        rowNum: 4,
        columnsKey: ['index', 'callTypeName', 'callRemark', 'slot:status', 'creator']
      })
      this.getTablePutData(baseUrl + 'getListByStatus', {
        status: 1,
        startTime: dayjs().startOf('month').format('YYYY-MM-DD 00:00:00'),
        endTime: dayjs().endOf('month').format('YYYY-MM-DD 23:59:59'),
        idList: areaIds ? areaIds.split('^') : []
      }, {
        key: 'scrollBoardConfig1',
        key2: 'dealTaskData',
        columns: this.scrollBoardConfig1.header,
        columnWidth: [70, 130, 320, 110, 150],
        rowNum: 4,
        columnsKey: ['index', 'callTypeName', 'callRemark', 'slot:status', 'creator']
      })
      this.getTablePutData(baseUrl + 'getListByStatus', {
        status: 2,
        startTime: dayjs().startOf('month').format('YYYY-MM-DD 00:00:00'),
        endTime: dayjs().endOf('month').format('YYYY-MM-DD 23:59:59'),
        idList: areaIds ? areaIds.split('^') : []
      }, {
        key: 'scrollBoardConfig2',
        key2: 'haveTaskData',
        columns: this.scrollBoardConfig2.header,
        columnWidth: [70, 130, 320, 110, 150],
        rowNum: 4,
        columnsKey: ['index', 'callTypeName', 'callRemark', 'slot:status', 'creator']
      })
    },
    async getInspectionFinishChartData() {
      const res = await api.getTablePutData(baseUrl + 'getCountByDay', {
        startTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD 00:00:00'),
        endTime: dayjs().format('YYYY-MM-DD 23:59:59'),
        idList: this.areaIds ? this.areaIds.split('^') : []
      })
      if (res) {
        this.inspectionFinishChartData.rows = res.map(item => ({
          '月份': dayjs(item.day).format('M-D'),
          '数量': item.quantity
        }))
      }
    },
    async getEquipmentInfo() {
      const res = await api.getTablePutData(baseUrl + 'getCountByStatus', {
        startTime: dayjs().startOf('month').format('YYYY-MM-DD 00:00:00'),
        endTime: dayjs().endOf('month').format('YYYY-MM-DD 23:59:59'),
        idList: this.areaIds ? this.areaIds.split('^') : []
      })
      if (res) {
        this.digital = {
          waitQuantity: res[0],
          quantity: res[1],
          haveQuantity: res[2]
        }
      }
    },
    async getInspectionErrorChartData() {
      const res = await api.getTablePutData(baseUrl + 'getHandlingTimeByMonth', {
        startTime: dayjs().subtract(5, 'month').format('YYYY-MM-DD 00:00:00'),
        endTime: dayjs().format('YYYY-MM-DD 23:59:59'),
        idList: this.areaIds ? this.areaIds.split('^') : []
      })
      if (res) {
        this.inspectionErrorChartData.rows = res.map(item => ({
          '月份': dayjs(item.day).format('M月'),
          '小时': item.quantity
        }))
      }
    },
    async getFaultSortChartData() {
      const res = await api.getTablePutData(baseUrl + 'getRakingByCallTypeName', {
        startTime: dayjs().startOf('month').format('YYYY-MM-DD 00:00:00'),
        endTime: dayjs().endOf('month').format('YYYY-MM-DD 23:59:59'),
        idList: this.areaIds ? this.areaIds.split('^') : []
      })
      if (res) {
        const arr = []
        for (const key in res) {
          arr.push({
            key: key,
            quantity: res[key]
          })
        }
        this.faultSortChartData.rows = arr.map(item => ({
          '名称': item.key,
          '数量': Number(item.quantity)
        }))
        this.errorTypeChartData.rows = arr.map(item => ({
          '类型': item.key,
          '数量': Number(item.quantity)
        }))
      }
    },
    async getMaintenanceChartData() {
      const res = await api.getTablePutData(baseUrl + 'getCountByMonth', {
        startTime: dayjs().subtract(5, 'month').format('YYYY-MM-DD 00:00:00'),
        endTime: dayjs().format('YYYY-MM-DD 23:59:59'),
        idList: this.areaIds ? this.areaIds.split('^') : []
      })
      if (res) {
        this.maintenanceChartData.rows = res.map(item => ({
          '月份': dayjs(item.day).format('M月'),
          '数量': Number(item.quantity)
        }))
      }
    },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.tableHeight = (window.innerHeight * 0.18) / scale
    }
  }
}
</script>

<style scoped lang="scss">
@import "./common";

.equipment-board-container {
  padding: 0 20px 20px 20px;
  font-family: PingFangSC-Semibold, PingFang SC;

  .top, .center, .bottom {
    display: flex;
    justify-content: space-between;

    > div {
      width: 596px;
    }
  }

  .top {
    .equipment-info {
      display: flex;
      justify-content: space-between;

      .card-info {
        position: relative;
        width: 162px;

        img {
          width: 162px;
        }

        > div {
          color: #FFFFFF;
          font-size: 23px;
          text-align: center;
          padding: 20px 0;
        }

        ::v-deep {
          .dv-digital-flop {
            position: absolute;
            top: 10px;
          }
        }
      }
    }
  }
}
::v-deep .dv-scroll-board .ceil {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  >span {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.status-board {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 44px;
}
.status-board-r {
  display: inline-block;
  width: calc(100% + 10px);
  height: 100%;
  text-align: center;
  line-height: 44px;
}
</style>
