
export const equipmentColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'code',
    label: '设备编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'name',
    label: '设备名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'sn',
    label: 'SN',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'model',
    label: '型号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'equipmentClassName',
    label: '分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]
