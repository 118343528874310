// 列表
export const columnsAll = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    minWidth: 80
  },
  {
    prop: 'code',
    label: '订单编号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'code',
    fixed: 'left',
    minWidth: 150
  },
  {
    prop: 'orderSource',
    label: '订单来源',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150,
    slotName: 'orderSource'
  },
  {
    prop: 'customerName',
    label: '客户',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
    prop: 'customerOrderNumber',
    label: '客户订单号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  // {
  //   prop: 'status',
  //   label: '状态',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'status',
  //   minWidth: 100
  // },
  {
    prop: 'progress',
    label: '发货进度',
    hiddenSearch: true,
    sortable: false,
    slotName: 'progress',
    minWidth: 150
  },
  {
    prop: 'deliveryType',
    label: '交付类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'deliveryType',
    minWidth: 100
  },
  {
    prop: 'deliveryTime',
    label: '交付日期',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'principal',
    label: '负责人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'creator',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 140
  }
]

// 订单产品列表
export const columnsOrder = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'code',
    label: '物料编码',
    slotName: 'code',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'name',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'specifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 100
  },
  {
    prop: 'availableStock',
    label: '库存可用数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'orderQuantity',
    label: '订货数量',
    hiddenSearch: true,
    sortable: false,
    slotName: 'orderQuantity',
    headerSlotName: 'orderQuantityHeader'
  },
  {
    prop: 'unitPrice',
    label: '单价',
    hiddenSearch: true,
    sortable: false,
    slotName: 'unitPrice',
    headerSlotName: 'unitPriceHeader'
  },
  {
    prop: 'totalAmount',
    label: '总金额',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    slotName: 'remark'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 100
  }
]

export const detailColumns = [
  {
    label: '订单编号',
    key: 'code'
  },
  {
    label: '客户',
    key: 'customerName'
  },
  {
    label: '客户订单号',
    key: 'customerOrderNumber'
  },
  {
    label: '交付类型',
    key: 'deliveryType'
  },
  {
    label: '交付日期',
    key: 'deliveryTime'
  },
  {
    label: '负责人',
    key: 'principal'
  },
  {
    label: '备注',
    key: 'remark'
  }
]

export const detailListColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsCode',
    minWidth: 150
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'materialSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'materialUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'orderQuantity',
    label: '订货数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'unitPrice',
    label: '单价',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'totalAmount',
    label: '总金额',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'deliveryTime',
    label: '交付日期',
    hiddenSearch: true,
    sortable: false,
    minWidth: 242
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'shippedQuantity',
    label: '已发货数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'waitShippedQuantity',
    label: '待发货数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  }
]

export const detailLogColumns = [

  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    // fixed: 'left',
    minWidth: 50
  },
  {
    prop: 'warehouseNumber',
    label: '出库单号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'warehouseType',
    label: '出库类型',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'warehouseName',
    label: '出库仓库',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  }
]

export const deliveryTypes = {
  0: '统一交付',
  1: '分批交付'
}
