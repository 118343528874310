<template>
  <div class="all-statistics-card">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <template v-for="(item, index) in statisticsCard">
        <el-tab-pane
          v-if="permission('operatingReports' + item.key, false)"
          :key="item.key"
          :label="item.name"
          :name="item.key"
          :style="{
            height: paneHeight + 'px',
            overflow: 'auto',
            width: '100%'
          }"
        >
          <template v-for="(child, idx) in item.children">
            <div :key="child.id + 200" :class="child.fullscreen ? 'app-left-full' : ''">
              <div v-if="child.name === '工单进度统计表'">
                精简至天：
                <el-switch v-model="toDay" @change="setToDay" />
              </div>
              <el-card v-if="item.key === activeName" :key="child.id" class="box-card">
                <div slot="header" class="box-card-header">
                  <div>
                    <span>{{ child.name }}</span>
                    <el-tooltip v-if="child.tooltip" :content="child.tooltip" placement="top" style="padding-left: 5px">
                      <span class="el-icon-question" />
                    </el-tooltip>
                  </div>
                  <div
                    :style="{
                      position: 'relative',
                      right: '26px',
                      padding: '12px 0'
                    }"
                  >
                    <div class="header-ri" @click="toggleFullScreen(child, index, idx)">
                      <el-tooltip v-if="!child.fullscreen" class="item" content="放大" placement="top">
                        <img class="ri-img" src="@/assets/narrow-amplification/amplification.png" alt="">
                      </el-tooltip>
                      <el-tooltip v-else class="item" content="缩小" placement="top">
                        <img class="ri-img" src="@/assets/narrow-amplification/narrow.png">
                      </el-tooltip>
                    </div>
                    <el-button v-if="child.type === 'table'" type="text" style="padding: 0" @click="exportReport(child, idx)">
                      <img src="@/assets/statistics-board/下载@2x.png" alt="" width="14">
                    </el-button>
                    <el-button type="text" style="padding: 0 10px"><img src="@/assets/statistics-board/保存@2x.png" alt="" width="14"></el-button>
                  </div>
                </div>
                <div>
                  <CustomTable v-if="child.type === 'table'" ref="customTable" :tab-info="child" :to-day="toDay" />
                  <charts v-if="child.type === 'charts' || child.type === 'chartsAndTable'" ref="customTable" :tab-info="child" />
                  <work-task-pass v-if="child.type === 'workTaskPass'" :tab-info="child" />
                </div>
              </el-card>
            </div>
          </template>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import CustomTable from '../components/custom-table/custom-table'
import statisticsCard from './sale-statistics-card'
import Charts from '@/views/statistics-board/board-manage/components/charts/charts'
import systemParams from '@/api/sets/function/system-params'
import WorkTaskPass from '@/views/statistics-board/board-manage/components/work-task-pass/work-task-pass'
import Vue from 'vue'
import { export_json_to_excel2 } from '@/utils/plug/to-excel'

export default {
  name: 'SaleStatisticsCard',
  components: { WorkTaskPass, Charts, CustomTable },
  data() {
    return {
      activeName: 'product',
      statisticsCard,
      showPopover: false,
      allChecked: false,
      popoverContentMaxHeight: 200,
      checkedList: [],
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      settingColumns: [],
      toDay: false,
      fullscreen: true
    }
  },
  computed: {
    paneHeight() {
      return this.$store.state.app.contentHeight - 244
    }
  },
  mounted() {
    this.setCheckNumberKey()
    this.toDay = !!localStorage.getItem('toDay')
    if (this.permission('operatingReportsmanHour', false)) {
      this.activeName = 'manHour'
    } else if (this.permission('operatingReportsequipmentStatistics', false)) {
      this.activeName = 'equipmentStatistics'
    } else if (this.permission('operatingReportsmaintenanceStatistics', false)) {
      this.activeName = 'maintenanceStatistics'
    } else if (this.permission('operatingReportsspotCheckStatisticss', false)) {
      this.activeName = 'spotCheckStatisticss'
    } else if (this.permission('operatingReportsrepairStatistics', false)) {
      this.activeName = 'repairStatistics'
    } else if (this.permission('operatingReportssparePartsStatistics', false)) {
      this.activeName = 'sparePartsStatistics'
    } else if (this.permission('operatingReportsworkOrderStatistics', false)) {
      this.activeName = 'workOrderStatistics'
    } else if (this.permission('operatingReportsdemandStatistics', false)) {
      this.activeName = 'demandStatistics'
    }
  },
  methods: {
    // 全屏
    toggleFullScreen(child, index, idx) {
      this.statisticsCard[index].children[idx].fullscreen = !child.fullscreen
      this.$set(this.statisticsCard[index].children, idx, this.statisticsCard[index].children[idx])
    },
    handleClick(name) {
      while (Vue.Cancel.length > 0) {
        Vue.Cancel.shift()('cancel')
      }
    },
    async setCheckNumberKey() {
      const res = await systemParams.getSystemParamsList({ code: 'stockWarn' })
      if (res && res[0]) {
        sessionStorage.setItem('stockWarnSetting', JSON.stringify(res[0]))
      }
    },
    setToDay() {
      if (this.toDay) {
        localStorage.setItem('toDay', this.toDay)
      } else {
        localStorage.removeItem('toDay')
      }
    },
    exportReport(child, idx) {
      if (child.type === 'table') {
        const tableDom = this.$refs.customTable[idx].tableRefs().tableRefs().$el
        const headerDomList = tableDom.querySelectorAll('.el-table__header-wrapper>table thead .cell>span:first-child')
        const header = Array.from(headerDomList).map(item => item.innerText)
        const bodyTrDomList = tableDom.querySelectorAll('.el-table__body-wrapper>table tbody>tr')
        const exportData = []
        Array.from(bodyTrDomList).forEach(trDom => {
          const values = Array.from(trDom.querySelectorAll('.cell')).map(item => item.outerText)
          const obj = {}
          header.forEach((item, index) => {
            obj[item] = values[index]
          })
          exportData.push(obj)
        })
        const params = {
          key: header,
          header,
          merges: [],
          data: exportData,
          filename: child.name
        }
        export_json_to_excel2(params)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.all-statistics-card {
  padding: 10px;
  position: relative;
  ::v-deep .el-tabs__content {
    padding: 8px;
    height: calc(100% - 56px);
  }
  .box-card {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    &:not(:first-child) {
      margin-top: 15px;
    }
    ::v-deep .el-card__header {
      background: #f6f7f9;
      padding: 0 18px;
      .box-card-header {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    ::v-deep .el-card__body {
      padding: 20px 18px;
    }
  }
  .header-ri {
    margin-right: 20px;
    cursor: pointer;
    display: inline-block;
    img {
      width: 15px;
      height: 15px;
      display: block;
    }
  }
  .app-left-full {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    background: #fff;
    width: 100%;
    z-index: 2000;
    padding: 20px;
  }
}
</style>
