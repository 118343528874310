import { render, staticRenderFns } from "./switch-shift.vue?vue&type=template&id=7922c4cc&scoped=true&"
import script from "./switch-shift.vue?vue&type=script&lang=js&"
export * from "./switch-shift.vue?vue&type=script&lang=js&"
import style0 from "./switch-shift.vue?vue&type=style&index=0&id=7922c4cc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7922c4cc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/mubai-mom-frontend-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7922c4cc')) {
      api.createRecord('7922c4cc', component.options)
    } else {
      api.reload('7922c4cc', component.options)
    }
    module.hot.accept("./switch-shift.vue?vue&type=template&id=7922c4cc&scoped=true&", function () {
      api.rerender('7922c4cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/self-center/switch-shift/switch-shift.vue"
export default component.exports