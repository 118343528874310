export const columns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '工具编码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    form: {
      index: 1,
      required: true,
      tagName: "el-input",
      colSpan: 12,
    },
  },
  {
    prop: 'name',
    label: '工具名称',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 2,
      required: true,
      tagName: "el-input",
      colSpan: 12,
    },
  },
  {
    prop: 'model',
    label: '工具型号',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      required: true,
      tagName: "el-input",
      colSpan: 12,
    },
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      required: false,
      tagName: "el-input",
      colSpan: 12,
    },
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    slotName: 'action'
  }
]

export const auxiliaryRecordColumns = [
  {
    prop: 'id',
    label: '需求ID',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'code',
    label: '需求编码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code'
  },
  {
    prop: 'content',
    label: '服务内容',
    sortable: false,
    hiddenSearch: true,
    slotName: 'content'
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status'
  },
  {
    prop: 'serviceType',
    label: '服务类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'serviceType'
  },
  {
    prop: 'customerName',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'linkMan',
    label: '客户联系人',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'linkPhone',
    label: '联系方式',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'salesOrderNumber',
    label: '销售订单号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'remak',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true,
  }
]

// 服务类型
export const serviceContentType = [
  {
    id: 0,
    name: '质保'
  },
  {
    id: 1,
    name: '收费'
  },
  {
    id: 2,
    name: '服务合同'
  },
  {
    id: 3,
    name: '销售赠送'
  }
]

// 服务内容
export const serviceType = [
  {
    id: 0,
    name: '维修'
  },
  {
    id: 1,
    name: '保养'
  },
  {
    id: 2,
    name: '装机'
  },
  {
    id: 3,
    name: '改造'
  },
  {
    id: 4,
    name: '点检'
  },
  {
    id: 5,
    name: '保养'
  },
  {
    id: 6,
    name: '点检'
  },
  {
    id: 7,
    name: '更换'
  }
]

// 维修、保养
export const demandStatus = [
  {
    id: 0,
    name: '待派工'
  },
  {
    id: 1,
    name: '待审批'
  },
  {
    id: 2,
    name: '待填报出差计划'
  },
  {
    id: 3,
    name: '待修改出差计划'
  },
  {
    id: 4,
    name: '待执行'
  },
  {
    id: 5,
    name: '执行中'
  },
  {
    id: 6,
    name: '待关闭'
  },
  {
    id: 10,
    name: '已完成'
  },
  {
    id: 8,
    name: '重开中'
  }
]
// 改造
export const reformStatus = [
  {
    id: 0,
    name: '待上传技术方案'
  },
  {
    id: 1,
    name: '待处理'
  },
  {
    id: 2,
    name: '上传技术方案中'
  },
  {
    id: 3,
    name: '待填写本地开发申请表'
  },
  {
    id: 4,
    name: '待销售部审批'
  },
  {
    id: 5,
    name: '待研发部审批'
  },
  {
    id: 6,
    name: '待执行'
  },
  {
    id: 7,
    name: '执行中'
  },
  {
    id: 8,
    name: '待审批'
  },
  {
    id: 9,
    name: '待验收'
  },
  {
    id: 10,
    name: '已完成'
  }
]
