// 列表
const Materials = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'customerName',
    label: '客户',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'sitWarehouseName',
    label: '线边仓',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'workOrderCode',
    label: '生产工单号',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 200
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    minWidth: 120,
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsCode'
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'quantity',
    label: '数量',
    hiddenSearch: true,
    sortable: 'custom'
  },
  {
    prop: 'operateTime',
    label: '更新时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'width',
    label: '宽度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'thickness',
    label: '厚度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'convertValue',
    label: '米数',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'steelGrade',
    label: '钢卷号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'coilNo',
    label: '牌号',
    hiddenSearch: true,
    sortable: false
  }
]
const Package = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'sitWarehouseName',
    label: '线边仓',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'containerCode',
    label: '包装码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false
  }
]
const Tray = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'sitWarehouseName',
    label: '线边仓',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'palletCode',
    label: '托盘编码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
    prop: 'containerCode',
    label: '包装码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false
  }
]
export default {
  Materials,
  Package,
  Tray
}
