<template>
  <MDialog v-model="modalShow" width="1000px" :append-to-body="true" :title="title" @onOk="submitForm" @fullScreen="fullScreenChange">
    <div>
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="工单编号/设备编号/设备名称/SN"
        :tags="functionHeader.tags"
        :columns-setting="false"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all">
          <span style="margin-right: 20px;display: inline-block">已选中 <span style="color: #000">{{ getSelectionDataLength }}</span> 项</span>
          <el-button type="text" @click="resetData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable
        ref="mTable"
        :highlight-current-row="single"
        :columns="workMulColumns"
        :height="height"
        :set-data-method="getTableData"
        :columns-setting="false"
        @selection-change="selectionChange"
        @current-change="selectionChange"
        @row-click="handleRowClick"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="executionStartTime" slot-scope="{ row }">{{ getTimeDesc(row.executionStartTime, row.executionEndTime) }}</div>
      </MTable>
      <div style="height: 15px;" />
    </div>
  </MDialog>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/xiewei/SearchForm/SearchForm'
import { workMulColumns, getFunctionList } from './columns'
import { getUUid, getTimeDesc } from '@/utils'
import api from '@/api/xiewei/service/demand-center'
import mapi from '@/api/xiewei/service/maintain-order'
import { Encrypt } from '@/utils/sercet'
import dayjs from 'dayjs'
import { getToken } from '@/utils/auth'

export default {
  name: 'SelectWork',
  components: { SearchForm, FunctionHeader },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    selectFacilitys: {
      type: Array,
      default: () => ([])
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '添加工单'
    },
    customerId: {
      type: Number,
      default: undefined
    },
    content: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      getTimeDesc,
      columns: [],
      selectValue: 'RepairWork',
      modalShow: false,
      split: {
        offset: 20
      },
      workMulColumns,
      functionHeader: {
        searchVal: '',
        tags: []
      },
      searchFormList: [],
      searchFormData: {},
      searchFormatData: [],
      selectionData: [],
      fullScreen: false
    }
  },
  computed: {
    height() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 : this.$store.state.app.contentHeight - 289 - 250
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      if (val) {
        this.echoData()
      }
    },
    modalShow(val) {
      if (!val) {
        this.searchFormData = {}
        this.searchFormatData = []
        this.functionHeader.searchVal = ''
        this.$emit('update:visible', false)
      }
    }
  },
  mounted() {
    this.modalShow = this.visible
    this.init()
    if (this.modalShow) {
      this.echoData()
    }
  },
  methods: {
    resetData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    echoData() {
      this.$nextTick(() => {
        // 数据回显
        this.equipmentClassId = ''
        this.selectionData = this._.cloneDeep(this.selectFacilitys)
        if (this.single) {
          this.$refs.mTable.tableRefs().setCurrentRow(this.selectionData[0])
        } else {
          this.selectionData.forEach(item => {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, true)
          })
        }
      })
    },
    updateTable() {
      this.$refs.mTable.setTableData()
    },
    // 新增
    add() {
      this.$emit('addMaterial')
    },
    // 选中行复选框也会选中
    handleRowClick(row) {
      if (!row.selectable) {
        this.$refs.mTable.tableRefs().toggleRowSelection(row)
      }
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    init() {
      this.searchFormatData = []
      this.searchFormData = {}
    },
    selectionChange(data) {
      if (this.single) {
        this.selectionData = []
        this.selectionData.push(data[data.length - 1])
        return false
      }
      if (Array.isArray(data)) {
        this.selectionData = data
      } else if (!data.selectable) {
        this.selectionData = []
        this.selectionData.push(data)
      }
    },
    setEquipmentClassId(val) {
      this.equipmentClassId = val
      this.$refs.mTable.setTableData(true)
    },
    // 查询物料
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData()
    },
    // 获取数据
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const searchKey = []
      let res
      const params = {
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        demand: '1',
        searchKeyword: this.functionHeader.searchVal,
        searchVal: Encrypt(JSON.stringify([...searchKey]))
      }
      // 维修
      if (this.content === 0) {
        res = await api.getFacilityRepairWordOrderListPage(params)
      }
      // 保养
      if (this.content === 1) {
        res = await mapi.getMaintainOrderListPage(params)
      }
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    submitForm(callback) {
      if (this.selectionData.length === 0) {
        this.$message.info('请至少选择一条数据')
        callback()
        return
      }
      this.$emit('submitForm', this.selectionData)
    }
  }
}
</script>

<style lang="scss" scoped>
.materials-info {
  padding: 10px;
}
.materials-head{
  padding:0 0 10px 0;
}
</style>
