<template>
  <div class="procedure-detail-wrappers">
    <div class="procedure-detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
    </div>
    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <div v-if="params.type !== 'view'">
            <MFormBuilder ref="baseForm" :form-list="formList" :form-data="baseFormData" label-position="right">
              <el-upload slot="drawing" :file-list="attachments" v-bind="uploadProps2">
                <el-button type="primary" size="small">上传附件</el-button>
              </el-upload>
            </MFormBuilder>
          </div>
          <div v-else>
            <el-row class="view-list" style="padding: 10px 15px;color: #393D60;" :gutter="24">
              <el-col
                v-for="(item) in addColumns"
                :key="item.prop"
                :span="['faultCode', 'solution', 'improvementMeasure', 'attachment'].includes(item.prop) ? 24 : 6"
                :style="{marginBottom: '20px' }"
              >
                <div style="color: #9597AE;">{{ item.label }}</div>
                <div v-if="item.prop === 'attachment'">
                  <a v-for="items in attachments" :key="items.id" :href="items.url" :download="items.name" target="_blank" style="display: block;color: #607FFF">{{ items.name }}</a>
                </div>
                <div v-else class="bot-tis">{{ getValue(item) || '-' }}</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.params.type !== 'view'" class="order-footer">
      <div class="footer-le" />
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitReForm">确定</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import { addColumns } from './columns'
import { getFunctionList } from './columns'
import { formList } from './form-list'
import { Encrypt } from '@/utils/sercet'
import { getUUid, setTreeData } from '@/utils'
import api from '@/api/xiewei/knowledge-base/failure-experience'
import { getToken } from '@/utils/auth'
import fieldApi from '@/api/xiewei/field/field'
import equipmentClassApi from '@/api/information/equipment-class'
import classApi from '@/api/xiewei/knowledge-base/failure-experience-class'

export default {
  name: 'AddFailureExperience',
  data() {
    return {
      addColumns,
      baseFormData: {},
      formList,
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        viewO: '故障经验详情',
        addO: '新增故障经验',
        modifyO: '修改故障经验求'
      },
      buttonLoading: false,
      userList: [],
      uploadProps2: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onRemove: this.removeFile
      },
      attachments: [],
      params: {
        type: 'view'
      },
      equipmentClassList: [],
      classList: []
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  mounted() {
    this.params = this.$route.query
    this.getEquipmentClassList()
    this.getFaultExperienceClassList()
    if (this.params.type !== 'add') {
      this.getFaultExperienceDetail()
    }
    if (this.params.type !== 'view') {
      this.createFieldCode()
    }
  },
  methods: {
    // 获取设备类
    async getEquipmentClassList() {
      const res = await equipmentClassApi.getEquipmentClassList()
      if (res) {
        const equipmentClassList = setTreeData(res)
        this.formList[3].props.options = equipmentClassList
        this.equipmentClassList = res
      }
    },
    // 获取分类
    async getFaultExperienceClassList() {
      const res = await classApi.getFaultExperienceClassList()
      if (res) {
        this.classList = res
        this.formList[2].children[0].options = res
      }
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.attachments.push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    removeFile(file) {
      this.attachments = this.attachments.filter(item => item.uid !== file.uid)
    },
    async createFieldCode() {
      const res = await fieldApi.getFieldIsExist({
        numberVersion: '故障经验' // 故障经验
      })
      if (res && +res === 0) {
        if (this.params.type === 'add') {
          this.$refs.baseForm.setForm({
            code: '系统生成'
          })
        }
        this.formList[0].props.disabled = true
      }
    },
    returns() {
      this.$router.back()
    },
    async getFaultExperienceDetail() {
      const res = await api.getFaultExperienceDetail(this.$route.query.id)
      if (res) {
        this.baseFormData = {
          ...res,
          equipmentClassIdList: res.equipmentClassIdList ? JSON.parse(res.equipmentClassIdList) : [],
          faultCode: this.params.type === 'view' ? res.faultCode : res.faultCode ? res.faultCode.split('、') : []
        }
        this.attachments = res.attachment ? JSON.parse(res.attachment) : []
      }
    },
    async saveData(form) {
      const res = await api[`${this.params.type}FaultExperience`](
        this.params.type !== 'modify' ? {
          ...this.createSetData(form)
        } : {
          ...this.createResetData(form)
        }
      )
      if (res) {
        this.$router.back()
      }
      this.buttonLoading = false
    },
    submitReForm() {
      const { form } = this.$refs.baseForm
      // console.log('data=>', form, this.createSetData(form))
      this.buttonLoading = true
      this.$refs.baseForm.formRefs().validate(async(valid) => {
        if (valid) {
          this.saveData(Object.assign({}, {
            ...form
          }))
        } else {
          this.buttonLoading = false
        }
      })
    },
    createSetData(raw) {
      let equipmentClassIds = ''
      let equipmentClassNames = ''
      equipmentClassIds = raw.equipmentClassIdList && raw.equipmentClassIdList.length > 0 ? raw.equipmentClassIdList.map(item => this._.last(item)) : ''
      equipmentClassNames = equipmentClassIds ? this.equipmentClassList.filter(item => equipmentClassIds.includes(item.id)).map(item => item.name).join('、') : ''
      return {
        code: raw.code === '系统生成' ? -1 : raw.code,
        name: raw.name,
        faultExperienceClassId: raw.faultExperienceClassId,
        faultExperienceClassName: this.classList.filter(item => item.id === raw.faultExperienceClassId)[0].name,
        equipmentClassIds: equipmentClassIds ? equipmentClassIds.join('^') : '',
        equipmentClassIdList: raw.equipmentClassIdList ? JSON.stringify(raw.equipmentClassIdList) : '',
        equipmentClassNames: equipmentClassNames,
        faultCode: raw.faultCode && raw.faultCode.length > 0 ? raw.faultCode.join('、') : '',
        solution: raw.solution,
        improvementMeasure: raw.improvementMeasure,
        attachment: this.attachments.length > 0 ? JSON.stringify(this.attachments) : ''
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && row.form.options.length > 0) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>

.procedure-detail-wrappers {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
    .date-number {
      width: 100%;
      display: flex;

      .el-form-item:first-child {
        width: 138% !important;

        input {
          border-radius: 4px 0 0 4px;
        }
      }

      .el-form-item:last-child {
        width: 62% !important;

        input {
          border-radius: 0 4px 4px 0;
          border-left: 0;
        }
      }
    }
  }
  .procedure-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .procedure-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
  .order-footer{
    position: absolute;
    bottom:0;
    left:0;
    height:60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background:#fff;
    border-top:1px solid #DDE3EB;
    .footer-le{
    padding-left:30px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    span{
      margin-right:45px;
      display:inline-block;
    }
    }
    .footer-ri{
      margin-right:30px;
      display:flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.flex-mode {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    margin-right: 20px;
  }
  ::v-deep .el-radio-group {
      vertical-align: initial;
    }
  ::v-deep .el-radio {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456B;
  }
}
.flex-modes {
  display: inline-flex;
  justify-content: flex-start;
  margin-top: 20px;
  .tit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    margin-right: 20px;
  }
  .mode-ri {
    .ri-top {
      display: flex;
      justify-content: flex-start;
      margin: 10px 0;
      .top-tit {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393D60;
        line-height: 36px;
      }
      .ri-num {
        width: 130px;
        margin: 0 20px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #DDE3EB;
      }
    }
  }
}
.bot-tis{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #393D60;
  display:inline-block;
}
</style>
