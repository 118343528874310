export const tableColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
  },
  {
    prop: 'code',
    label: '工具编码',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'name',
    label: '工具名称',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'model',
    label: '工具型号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'num',
    label: '数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'num',
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    slotName: 'remark',
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]
