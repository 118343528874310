var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unit-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _c("el-radio-button", { attrs: { label: "Organization" } }, [
            _vm._v("组织架构")
          ]),
          _c("el-radio-button", { attrs: { label: "OrganizationType" } }, [
            _vm._v("组织类型")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("FunctionHeader", {
            attrs: {
              "search-title": "请输入组织名称",
              "hidden-title-search": _vm.params.radio === "OrganizationType",
              radio: _vm.params.radio,
              tags: _vm.functionHeader[_vm.params.radio + "Tags"],
              "columns-key": _vm.columnsKey
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.columns[_vm.params.radio],
              "is-tree-data": true,
              "show-page": false,
              height: _vm.height,
              "columns-key": _vm.columnsKey,
              "set-data-method": _vm.getTableData,
              "columns-setting": false,
              "default-expand-all": ""
            },
            on: { "selection-change": _vm.selectionChange },
            scopedSlots: _vm._u([
              {
                key: "remark",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", [_vm._v(_vm._s(row.remark || "-"))])
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      [
                        row.isSystem !== 1 ||
                        _vm.params.radio === "Organization"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.modify(row)
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            )
                          : _vm._e(),
                        row.isSystem !== 1
                          ? _c("el-divider", {
                              attrs: { direction: "vertical" }
                            })
                          : _vm._e(),
                        row.isSystem !== 1
                          ? _c("DelPopover", {
                              on: {
                                onOk: function(callback) {
                                  return _vm.dels(row, callback)
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    ],
                    2
                  )
                }
              }
            ])
          }),
          _c(
            "MDialog",
            {
              attrs: { title: _vm.messages[_vm.currentType] },
              on: { onOk: _vm.submitForm },
              model: {
                value: _vm.visible,
                callback: function($$v) {
                  _vm.visible = $$v
                },
                expression: "visible"
              }
            },
            [
              _c("MFormBuilder", {
                ref: "formBuild",
                attrs: {
                  "form-data": _vm.formData,
                  "form-list": _vm.dialogFormList
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }