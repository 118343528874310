import { maintenanceLevel, demandServiceType, demandServiceContentType } from '@/config/options.sales'
import {nameChangeForBB} from '@/utils'

export const columns = [
  {
    prop: 'code',
    label: '需求编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'content',
    label: '服务内容',
    sortable: false,
    hiddenSearch: true,
    form: { index: 2, tagName: 'el-select', colSpan: 8, options: demandServiceType }
  },
  {
    prop: 'serviceType',
    label: '服务类型',
    sortable: false,
    hiddenSearch: true,
    form: { index: 3, tagName: 'el-select', colSpan: 8, options: demandServiceContentType }
  },
  {
    prop: 'customerName',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'linkMan',
    label: '客户联系人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'linkPhone',
    label: '联系方式',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'salesOrderNumber',
    label: nameChangeForBB() ? '出厂编号' :'销售订单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'serviceAddress',
    label: '现场服务地址',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'detailAddress',
    label: '详细地址',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'customerFeedback',
    label: '客户反馈信息',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
export const mulColumns = [
  {
    prop: 'code',
    label: '需求编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'content',
    label: '服务内容',
    sortable: false,
    hiddenSearch: true,
    form: { index: 2, tagName: 'el-select', colSpan: 8, options: demandServiceType }
  },
  {
    prop: 'serviceType',
    label: '服务类型',
    sortable: false,
    hiddenSearch: true,
    form: { index: 3, tagName: 'el-select', colSpan: 8, options: demandServiceContentType }
  },
  {
    prop: 'customerName',
    label: '客户',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'linkMan',
    label: '客户联系人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'linkPhone',
    label: '联系方式',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'planLevel',
    label: '保养级别',
    sortable: false,
    hiddenSearch: true,
    form: { index: 4, tagName: 'el-select', colSpan: 8, options: maintenanceLevel, required: false }
  },
  {
    prop: 'planDate',
    label: '计划执行时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'salesOrderNumber',
    label: '销售订单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'serviceAddress',
    label: '现场服务地址',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
export const modifyColumns = [
  {
    prop: 'updateRemark',
    label: '驳回说明',
    sortable: true,
    hiddenSearch: true,
    form: {
      required: false,
      tagName: 'el-input',
      colSpan: 24,
      props: {
        type: 'textarea'
      },
      attrs: { placeholder: '不超过500字', maxlength: 500 }
    }
  }
]
export const fileColumns = [
  {
    prop: 'fileName',
    label: '文件名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'typeName',
    label: '文件类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'resultName',
    label: '审核结果',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '填写/上传时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '填写/上传人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false
  }
]
export const assembleColumns = [
  {
    prop: 'name',
    label: '装机步骤',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    slotName: 'remark',
    form: { index: 2, tagName: 'el-input', colSpan: 24, required: false, props: { type: 'textarea', autosize: { minRows: 3 }}}
  },
  {
    prop: 'updateTime',
    label: '完成时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'updateTime',
    form: {
      index: 1,
      tagName: 'el-date-picker',
      colSpan: 24,
      props: {
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm',
        format: 'yyyy-MM-dd HH:mm',
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7
          }
        }
      }
    }
  },
  {
    prop: 'updaterName',
    label: '执行人',
    sortable: false,
    hiddenSearch: true,
    slotName: 'updaterName'
  },
  {
    slotName: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false
  }
]

export const toolColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '工具编码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
  },
  {
    prop: 'name',
    label: '工具名称',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'model',
    label: '工具型号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'quantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
  }
]
