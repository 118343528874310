<template>
  <div class="shift-wraps">
    <FunctionHeader
      :tags="functionHeader.tags"
      :hidden-search="true"
    />
    <MTable ref="mTable" :columns="columns" :height="500" :set-data-method="getTableDatas" :columns-setting="false">
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <el-button slot="memberName" slot-scope="{ row }" type="text" @click="viewCheckItem(row)">{{ row.memberName }}</el-button>
      <div slot="action" slot-scope="{ row }">
        <el-button type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
      </div>
    </MTable>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/tenant'
import { columns } from './columns'
import formList from './form-list'

export default {
  name: 'Tenant',
  components: { FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'Tenant'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: false,
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ]
      },
      columns,
      visible: false,
      formList: formList,
      formData: {},
      currentType: 'add',
      formOptions: {},
      getTenantList: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    }
  },
  mounted() {
    // this.getTenantLists()
  },
  methods: {
    viewCheckItem(row) {
      this.$router.push({ name: 'tenantDetail', query: { id: row.id, memberCode: row.memberCode }})
    },
    async getTableDatas(condition, callback) {
      const { page } = condition
      const res = await api[`getTenantListPage`]({
        page: page.currentPage,
        limit: page.pageSize
      })
      if (res) {
        callback({
          total: res.totalElements,
          content: res.content
        })
      }
      callback()
    },
    createSetData(raw) {
      return {
        memberName: raw.memberName,
        memberCode: raw.memberCode,
        url: raw.url
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.shift-wraps {
  padding: 30px;
  .function-header-wrap {
    padding-top: 0;
  }
}
</style>

