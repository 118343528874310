<template>
  <div>
    <el-popover
      ref="popover"
      v-model="showPopover"
      :width="207"
      :offset="20"
      trigger="manual"
      @show="popoverShowEvent"
    >
      <div class="popover-content-top">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="saveColumns">确定</el-button>
      </div>
      <div class="popover-content-center">
        <el-checkbox v-model="allChecked" @change="selectAllColumns">全选</el-checkbox>
        <el-button class="invert" type="text" @click="invertSelectColumns">
          <img src="@/assets/table_filter_images/fanxuan@2x.png" alt="反选" width="13px">
          <span>反选</span>
        </el-button>
      </div>
      <div class="popover-content-footer" :style="{maxHeight: popoverContentMaxHeight + 'px'}">
        <el-checkbox-group
          v-model="checkedList"
          class="check-wrapper"
        >
          <draggable
            v-model="settingColumns"
            v-bind="dragOptions"
            @start="isDragging = true"
            @end="isDragging = false"
          >
            <el-checkbox v-for="item in settingColumns" :key="item.key" :label="item.prop">{{ item.label }}</el-checkbox>
          </draggable>
        </el-checkbox-group>
      </div>
      <el-button ref="columnsSettingBtn" slot="reference" class="columns-setting" type="text" @click.native="onShow">
        <img src="@/assets/statistics-board/设置备份@2x.png" alt="" width="14">
      </el-button>
    </el-popover>
  </div>
</template>

<script>
import $ from 'jquery'
import draggable from 'vuedraggable'

export default {
  name: 'ColumnSetting',
  components: { draggable },
  props: {
    columns: {
      type: [Array, Boolean],
      default: () => ([])
    },
    originData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      treeData: [],
      showPopover: false,
      allChecked: false,
      popoverContentMaxHeight: 200,
      checkedList: [],
      treeCheckList: [],
      dateRangeCheckList: [1],
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      settingColumns: [],
      copySettingColumns: [],
      newColumns: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  mounted() {
    this.getCheckedColumns()
    this.$nextTick(() => {
      this.calcPopoverContentHeight()
    })
  },
  methods: {
    async getCheckedColumns() {
      const columns = this._.cloneDeep(this.columns)
      if (columns) this.settingColumns = columns
      if ('index' in columns[0] && (!this.newColumns || !this.newColumns.length)) {
        this.newColumns = columns.map(item => ({
          checked: item.checked,
          key: item.prop,
          index: item.index
        })).sort((a, b) => a.index - b.index)
      }
      this.copySettingColumns = this._.clone(this.settingColumns)
        .filter(item => item.slotName !== 'action' && item.type !== 'selection')
      if (this.newColumns && this.newColumns.length) {
        const checkedKeys = this.newColumns.filter(item => item.checked).map(item => item.key)
        const columnsProps = this.newColumns.map(item => item.key)
        this.newColumns = columns.filter(item => {
          const checkIndex = checkedKeys.indexOf(item.prop)
          if (checkedKeys.indexOf(item.prop) !== -1) {
            item.index = this.newColumns[checkIndex].index
          } else {
            item.index = this.newColumns[columnsProps.indexOf(item.prop)].index
          }
          if (item.type === 'selection') {
            item.index = 0
          }
          if (item.slotName === 'action') {
            item.index = columns.length
          }
          return checkIndex !== -1 ||
            item.slotName === 'action' ||
            item.type === 'selection'
        })
        this.newColumns.sort((a, b) => a.index - b.index)
        this.settingColumns = columns
          .filter(item => item.slotName !== 'action' && item.type !== 'selection')
          .sort((a, b) => a.index - b.index)
        this.copySettingColumns = this._.clone(this.settingColumns)
        this.checkedList = checkedKeys
        this.$emit('updateColumns', this.newColumns)
        return false
      }
      this.newColumns = columns || []
      this.checkedList = this.settingColumns.map(item => item.prop)
      this.$emit('updateColumns', this.newColumns)
    },
    onShow() {
      this.showPopover = !this.showPopover
    },
    calcPopoverContentHeight() {
      if (!this.$refs.columnsSettingBtn) return false
      // 根据表格列按钮距底部高度计算popover的最大内容高度
      const position = $(this.$refs.columnsSettingBtn.$el).offset()
      const contentHeight = document.documentElement.offsetHeight
      this.popoverContentMaxHeight = contentHeight - position.top - 180
    },
    popoverShowEvent() {
      this.calcPopoverContentHeight()
      this.settingColumns = this._.clone(this.copySettingColumns)
      this.checkedList = this.newColumns
        .filter(item => item.slotName !== 'action' && item.type !== 'selection')
        .map(item => item.prop)
    },
    cancel() {
      this.showPopover = false
      this.allChecked = false
    },
    async saveColumns() {
      this.newColumns = this.settingColumns.map((item, index) => ({
        key: item.prop,
        checked: this.checkedList.indexOf(item.prop) !== -1,
        index
      }))
      this.showPopover = false
      this.allChecked = false
      await this.getCheckedColumns()
    },
    selectAllColumns(val) {
      this.checkedList = val ? this.settingColumns.map(item => item.prop) : []
    },
    invertSelectColumns() {
      this.checkedList = this.settingColumns
        .filter(item => this.checkedList.indexOf(item.prop) === -1)
        .map(item => item.prop)
    }
  }
}
</script>

<style scoped lang="scss">
.columns-setting {
  position: absolute;
  right: 0;
  top: -62px;
}

.popover-content-top {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;

  button {
    padding: 8px 20px;
    border-radius: 4px;
    border-color: #DEDEEE;
    color: #8A8CA5;

    &:last-child {
      border-color: #607FFF;
      color: #607FFF;
    }
  }
}

.popover-content-center {
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  background: #F8F8FB;
  height: 30px;

  button.invert {
    span {
      color: #8A8CA5;
    }

    img {
      position: relative;
      top: 2px;
      right: 10px;
    }
  }
}

.popover-content-footer {
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  max-height: 380px;
  overflow-y: auto;

  .el-checkbox-group {
    overflow: hidden;

    .el-checkbox {
      width: 100%;
      padding: 8px 8px 8px 28px;
      cursor: pointer;

      &:hover {
        background: #ECF0F4;
      }
    }
  }
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
