var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "container", staticStyle: { padding: "0px" } },
      [
        _c("iframe", {
          staticStyle: {
            width: "100vw",
            height: "calc(100vh + 70px)",
            "margin-top": "-70px"
          },
          attrs: {
            src: "https://www.ixms.tech/v3/customeroverview",
            frameborder: "0"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }