var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-travel-plan" },
    [
      _c("div", { staticClass: "detail-header flex-sbc" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [
            _c("img", {
              staticStyle: { width: "8px" },
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c(
              "span",
              { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
              [_vm._v("出差计划")]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "detail-body" }, [
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("基础信息")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _vm.isView
                ? _c(
                    "el-row",
                    { staticClass: "view-list", attrs: { gutter: 24 } },
                    _vm._l(_vm.columns, function(item) {
                      return _c(
                        "el-col",
                        { key: item.id, attrs: { span: 6 } },
                        [
                          _c("div", { staticClass: "view-list__label" }, [
                            _vm._v(_vm._s(item.label))
                          ]),
                          _c("div", { staticClass: "view-list__value" }, [
                            _vm._v(_vm._s(_vm.getValue(item) || "-"))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                : _c(
                    "MFormBuilder",
                    {
                      ref: "baseForm",
                      attrs: {
                        "form-list": _vm.formList,
                        "form-data": _vm.baseFormData,
                        "label-position": "right"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: { slot: "facilityDemandCode" },
                          slot: "facilityDemandCode"
                        },
                        [_vm._v(" " + _vm._s(_vm.baseFormData.code) + " ")]
                      )
                    ]
                  )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("预算申请")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _vm.isView
                ? _c(
                    "el-row",
                    { staticClass: "view-list", attrs: { gutter: 24 } },
                    _vm._l(_vm.descColumns, function(item) {
                      return _c(
                        "el-col",
                        { key: item.id, attrs: { span: 6 } },
                        [
                          _c("div", { staticClass: "view-list__label" }, [
                            _vm._v(_vm._s(item.label))
                          ]),
                          item.key !== "facilityRepairHistoryList"
                            ? _c("div", { staticClass: "view-list__value" }, [
                                _vm._v(_vm._s(_vm.getValue(item) || "-"))
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                : _c(
                    "MFormBuilder",
                    {
                      ref: "descForm",
                      attrs: {
                        "form-list": _vm.descFormList,
                        "form-data": _vm.baseFormData,
                        "label-position": "right"
                      }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "totalFare" }, slot: "totalFare" },
                        [_c("div", [_vm._v(_vm._s(_vm.totalFare))])]
                      )
                    ]
                  )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("出差说明")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _vm.isView
                ? _c(
                    "el-row",
                    { staticClass: "view-list", attrs: { gutter: 24 } },
                    _vm._l(_vm.travelColumns, function(item) {
                      return _c(
                        "el-col",
                        { key: item.id, attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "view-list__label" }, [
                            _vm._v(_vm._s(item.label))
                          ]),
                          item.key !== "facilityRepairHistoryList"
                            ? _c("div", { staticClass: "view-list__value" }, [
                                _vm._v(_vm._s(_vm.getValue(item) || "-"))
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                : _c("MFormBuilder", {
                    ref: "travelForm",
                    attrs: {
                      "form-list": _vm.travelFormList,
                      "form-data": _vm.baseFormData,
                      "label-position": "right"
                    }
                  })
            ],
            1
          )
        ]),
        (_vm.isView || _vm.params.type === "modify") &&
        _vm.baseFormData.updateRemark
          ? _c("div", { staticClass: "detail-card" }, [
              _c("div", { staticClass: "card-tit" }, [_vm._v("修改意见")]),
              _c(
                "div",
                { staticClass: "card-bot", staticStyle: { color: "#393d60" } },
                [_vm._v(" " + _vm._s(_vm.baseFormData.updateRemark) + " ")]
              )
            ])
          : _vm._e()
      ]),
      !_vm.isView
        ? _c("div", { staticClass: "detail-footer" }, [
            _c(
              "div",
              { staticClass: "footer-ri" },
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.returns } },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.buttonLoading,
                      size: "small"
                    },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v("确定")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.params.status === 1
        ? _c("div", { staticClass: "detail-footer" }, [
            _c(
              "div",
              { staticClass: "footer-ri" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger", size: "small" },
                    on: { click: _vm.rejectPlan }
                  },
                  [_vm._v("驳回")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.buttonLoading,
                      size: "small"
                    },
                    on: {
                      click: function($event) {
                        return _vm.passPlan(1)
                      }
                    }
                  },
                  [_vm._v("通过")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "MDialog",
        {
          attrs: { "append-to-body": true, title: "驳回", width: "500px" },
          on: { onOk: _vm.submitFormReject },
          model: {
            value: _vm.dialogVisible,
            callback: function($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "modifyForm",
            attrs: {
              "form-list": _vm.modifyFormList,
              "label-position": "right"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }