var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    { attrs: { placement: "bottom" } },
    [
      _c(
        "template",
        { slot: "reference" },
        [
          _c("el-button", { attrs: { type: "text" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/statistics-board/高级筛选@2x.png"),
                width: "14"
              }
            })
          ])
        ],
        1
      ),
      _c(
        "el-cascader",
        _vm._b(
          {
            ref: "cascader",
            attrs: {
              size: "mini",
              "collapse-tags": "",
              filterable: "",
              placeholder: _vm.tabInfo.placeholder,
              options:
                _vm.tabInfo.options || _vm.formOptions[_vm.tabInfo.dataKey]
            },
            on: {
              change: function() {
                _vm.cascaderChange(false, _vm.tabInfo)
              },
              "remove-tag": function() {
                _vm.cascaderChange(false, _vm.tabInfo)
              }
            },
            model: {
              value: _vm.searchForm[_vm.tabInfo.key],
              callback: function($$v) {
                _vm.$set(_vm.searchForm, _vm.tabInfo.key, $$v)
              },
              expression: "searchForm[tabInfo.key]"
            }
          },
          "el-cascader",
          _vm.tabInfo.props,
          false
        )
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }