import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'

export const formList = [
  ...transformColumnsToForm(columns),
  {
    colSpan: 24,
    label: '附件',
    key: 'attachments',
    tagName: 'div',
    required: false,
    children: [{
      customSlots: ['drawing']
    }]
  }
]

