<template>
  <div class="switch-shift-wrapper">
    <div class="form-build-wrapper">
      <MFormBuilder
        ref="formBuild"
        :form-data="formData"
        :form-list="formList"
        label-position="left"
      />
      <el-button type="primary" class="save-button" @click="submitForm">保存</el-button>
    </div>
  </div>
</template>

<script>
import userManage from '@/api/sets/user-managment'
import sets from '@/api/sets/flight-management'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

export default {
  name: 'SwitchShift',
  data() {
    return {
      formData: {},
      formList: [
        {
          tagName: 'el-select',
          label: '班次：',
          colSpan: 24,
          key: 'frequencyId',
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: []
            }
          ]
        }
      ],
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      }
    }
  },
  computed: {
    userDetail() {
      return this.$store.state.user.userDetail
    }
  },
  watch: {
    userDetail(val) {
      this.formData = val
    }
  },
  mounted() {
    this.getShiftList()
  },
  methods: {
    async getShiftList() {
      const res = await sets.getAllFlightList(this.defaultSearch)
      if (res) {
        this.formList[0].children[0].options = res
        if (this.userDetail) {
          this.formData = this.userDetail
        }
      }
    },
    async submitForm() {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const shifts = this.formList[0].children[0].options
          const postData = {
            id: form.id,
            frequencyId: form.frequencyId,
            userName: form.userName,
            frequencyName: shifts.find(item => item.id === form.frequencyId).name
          }
          const res = await userManage.modifyUserInfo(postData)
          if (res) {
            this.userInfos = postData
            this.visible = false
            await this.$store.dispatch('user/getUserDetail')
            this.$message.success('切换成功')
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.switch-shift-wrapper {
  .form-build-wrapper {
    width: 335px;
  }

  .save-button {
    background: #607FFF;
    float: right;
    width: 88px;
  }
}
</style>
