export const columnsAll = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'name',
    label: '类型',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'type',
    label: '出入库',
    sortable: false,
    hiddenSearch: true,
    slotName: 'type',
    form: {
      tagName: 'el-select',
      options: [{
        id: 0,
        name: '入库'
      },
      {
        id: 1,
        name: '出库'
      }]
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]
