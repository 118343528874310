<template>
  <div class="user-info-wrapper">
    <div class="form-build-wrapper">
      <MFormBuilder
        ref="formBuild"
        :form-data="formData"
        :form-list="formList"
        :disabled="true"
        label-position="left"
      />
    </div>
  </div>
</template>

<script>
import { status } from '@/config/options.config'
import user from '@/api/sets/user-managment/index'
export default {
  name: 'UserInfos',
  data() {
    return {
      formData: {},
      formList: [
        {
          tagName: 'el-input',
          colSpan: 24,
          label: '姓名：',
          key: 'name'
        },
        {
          tagName: 'el-input',
          label: '账号：',
          colSpan: 24,
          key: 'userName'
        },
        {
          tagName: 'el-select',
          label: '角色：',
          colSpan: 24,
          key: 'roleIds',
          props: {
            multiple: true
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: []
            }
          ]
        },
        {
          tagName: 'el-select',
          label: '用户状态：',
          colSpan: 24,
          key: 'isEnabled',
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: status
            }
          ]
        }
      ]
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      const res = await user.getUserDetail({ id: userInfo.id })
      if (res) {
        this.formList[2].children[0].options = res.roleList
        this.formData = {
          ...res,
          roleIds: res.roleList ? res.roleList.map(item => item.id) : []
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.user-info-wrapper {
  .form-build-wrapper {
    width: 335px;
  }
}
</style>
