<template>
  <div class="unit-wrap">
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入工单编号/设备编号/设备名称/SN"
        :tags="functionHeader.tags"
        :columns-key="columnsKey"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :show-export="!exportSelectionIds.length ? true : false"
        :hidden-mul-search="true"
        @search="$refs.mTable.setTableData(true)"
        @checkSearch="checkSearch"
      >
      <div slot="all" v-show="exportSelectionIds.length">
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
        <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
      </div>
      </FunctionHeader>
      <div class="top">
        <SearchForm v-show="!isShow" ref="searchForm" :form-list-extend="getSearchFormList" :form-data="searchFormData" @search="searchTable" />
      </div>
      <MTable
        ref="mTable"
        :columns="columns"
        :height="height"
        :columns-key="columnsKey"
        :set-data-method="getTableData"
        :columns-setting="isShow"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <el-button slot="workOrderCode" slot-scope="{ row }" type="text" @click="viewReform(row)">{{ row.workOrderCode }}</el-button>
        <el-button v-if="row.demandCode" slot="demandCode" slot-scope="{ row }" type="text" @click="viewDemandCenters(row)">
          {{ row.demandCode }}
        </el-button>
        <div v-else slot="demandCode" slot-scope="{ row }" type="text">{{ '-' }}</div>
        <div slot="executionStartTime" slot-scope="{ row }">{{ getTimeDesc(row.executionStartTime, row.executionEndTime) }}</div>
        <div slot="createTime" slot-scope="{ row }">{{ getTimeDesc(row.createTime) }}</div>

        <div v-if="permission('DelWorkOrder')" slot="action" slot-scope="{ row }">
          <template>
            <el-button v-if="permission('Modify') && fixUsers(row.updater, row.updatePermitUserId) && row.isUpdate === 1" type="text" @click="modifyReform(row)">修改</el-button>
            <el-divider v-if="permission('Modify') && fixUsers(row.updater, row.updatePermitUserId) && row.isUpdate === 1" direction="vertical" />
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </div>
        <div v-else-if="row.isUpdate === 1" slot="action" slot-scope="{ row }">
          <template>
            <el-button v-if="permission('Modify') && fixUsers(row.updater, row.updatePermitUserId)" type="text" @click="modifyReform(row)">修改</el-button>
            <el-divider v-if="permission('Modify') && fixUsers(row.updater, row.updatePermitUserId)" direction="vertical" />
            <DelPopover v-if="permission('Del') && fixUsers(row.updater, row.updatePermitUserId)" @onOk="callback => del(row, callback)" />
            <div v-else slot="action">{{ '-' }}</div>
          </template>
        </div>
        <div v-else slot="action" slot-scope="{ row }">{{ '-' }}</div> -->
      </MTable>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DelPopover from '@/components/DelPopover/index'
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns } from './columns'
import { formList, searchFormList } from './form-list'
import batchExportFront from '@/mixin/batch-export-front'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/xiewei/service/repairs'
import userApi from '@/api/sets/user/user'
import keApi from '@/api/information/unit'
import { Encrypt } from '@/utils/sercet'
import { getUUid, setTreeData } from '@/utils'
import fixSearchNum from '@/mixin/fix-search-num'

export default {
  name: 'Reform',
  components: { DelPopover, FunctionHeader, SearchForm },
  mixins: [batchExportFront, conventionMixin, fixSearchNum],
  data() {
    return {
      api,
      params: {
        radio: 'Reform'
      },
      selectionData: [],
      functionHeader: {
        searchVal: '',
        exportName: 'exportReformOrder',
        exportParams: {}
        // tags: [
        // {
        //   tagName: 'el-button',
        //   props: { type: 'primary', size: 'small' },
        //   style: { width: 'auto', background: '#607FFF' },
        //   innerText: '新增',
        //   on: {
        //     click: this.add
        //   }
        // }
        // ]
      },
      // extraParams: [
      //   {
      //     id: getUUid(),
      //     fieldName: 'executiveOutcome',
      //     fieldType: 'number',
      //     fieldValue: '1^2^3',
      //     operator: 'in'
      //   }
      // ],
      visible: false,
      columns,
      formList,
      formData: {},
      searchFormData: {},
      searchFormatData: [],
      searchFormList,
      searchKeyword: true,
      allCustomer: [],
      total: 0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - (this.isShow ? -30 : 32)
    },
    getTimeDesc() {
      return (start, end) => {
        const format = 'YYYY年MM月DD日 HH:mm'
        if (start && end) {
          const isSameDay = dayjs(start).isSame(end, 'day')
          if (isSameDay) {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format('HH:mm')}`
          } else {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format(format)}`
          }
        } else if (start) {
          return `${dayjs(start).format(format)}`
        } else if (end) {
          return `${dayjs(end).format(format)}`
        }
        return '-'
      }
    },
    getSearchFormList() {
      if (!this.isShow) {
        return this.searchFormList
      } else {
        return []
      }
    },
    getTableDataParams() {
      const { form } = this.$refs.searchForm
      return {
        executorIds: (form.executor || []).join(',')
      }
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.exportSelectionList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },

  mounted() {
    this.getUserList()
    this.getAllCustomer()
  },

  methods: {
    allMore(command) {
      const functions = {
        batchExport: () => { this.batchExport() }
      }
      functions[command]()
    },
    resetSelectData() {
      this.exportSelectionList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
    },
    fixUsers(name, val) {
      if (val !== null && val !== undefined) {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        return +val === userInfo.id
      } else {
        return false
      }
    },

    async getUserList() {
      const res = await userApi.getUserList()
      this.searchFormList[2].children[0].options = res
    },

    modifyReform(row) {
      this.$router.push({
        name: 'repairsDetail',
        query: { type: 'modify', content: 3, code: this.demandCode, demandId: this.demandId, wordOrderId: row.id }
      })
    },

    viewReform(row) {
      this.$router.push({
        name: 'reformDetail',
        query: { type: 'view', content: 3, code: this.demandCode, demandId: this.demandId, wordOrderId: row.id }
      })
    },

    viewDemandCenters(row) {
      this.$router.push({ name: 'demandCenterDetail', query: { id: row.demandId }})
    },

    // delBatchReform(row) {
    //   this.$confirm('确定要删除选择工单吗?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(async () => {
    //     const res = await api.delBatchRepairs(this.exportSelectionIds)
    //     if (res) {
    //       this.$message.success('删除成功')
    //       this.$refs.mTable.setTableData(true)
    //     }
    //   })
    // },

    del(row) {
      this.$confirm('删除后该工单不可恢复，是否确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delReform({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData(true)
        }
      })
    },

    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.searchFormList[0].props.options = setTreeData(data)
      }
    },
    searchTable(data) {
      const ids = data.map(item => item.fieldName)
      const hasIndex = ids.indexOf('customerName')
      this.searchFormatData = this._.cloneDeep(data)
      if (hasIndex !== -1) {
        this.searchFormatData.splice(hasIndex, 1)
        const id = data[hasIndex].fieldValue
        const arr = this.allCustomer.filter(item => item.id === this._.first(id))
        const list = this.allCustomer.filter(item => item.id === this._.last(id))
        this.searchFormatData = [
          ...this.searchFormatData,
          {
            id: getUUid(),
            fieldName: list[0].isCustomer === 1 ? 'customerSiteId' : 'consumerId',
            fieldType: 'number',
            fieldValue: list[0].isCustomer === 1 ? list[0].id : arr[0].id,
            operator: 'eq'
          }
        ]
      }
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.unit-wrap {
  padding-top: 10px;

  .bottom {
    padding: 10px;
  }

  .search-form-wrap {
    background: #f0f2f5;
    margin-bottom: 10px;
    border: none;
  }
}
</style>
