<template>
  <div class="equipment-monitoring m-table">
<!--    <div @click="showInfo">12313213213213212313121</div>-->
    <div class="detail-cards">
      <div class="card-bots">
        <div style="margin-bottom: 10px;">
          <el-button size="small" type="primary" @click="addInfo">添加</el-button>
        </div>
        <MTable
          ref="plansTable"
          :show-page="false"
          :height="heights"
          :columns="tableColumns"
          :data="showDatas"
          :only-key="'uuid'"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="num" slot-scope="{ row, $index }">
            <template>
              <el-input
                type="number"
                :min="1"
                v-model="row.num"
                placeholder="请输入数量"
                @input="numChange($event,row,$index)">
              </el-input>
            </template>
          </div>
          <div slot="remark" slot-scope="{ row, $index }">
            <template>
              <el-input
                  type="text"
                  v-model="row.remark"
                  @input="reamrkChange($event,row,$index)">
              </el-input>
            </template>
          </div>
          <div slot="action" slot-scope="{ row, $index }">
            <template>
              <el-button type="text" @click="delInfos(row, $index)">移除</el-button>
            </template>
          </div>
        </MTable>

        <MDialog v-model="visiable" title="添加工具" @onOk="addOrEditSubmit">
          <div class="bottom" style="margin-top: -10px">
            <FunctionHeader
              ref="functionHeader"
              :columnsSetting="false"
              v-model="functionHeader.searchVal"
              search-title="请输入工具编号/名称"
              :tags="functionHeader.tags"
              @search="setTableData(true)"
            />
            <el-table
              ref="multipleTable"
              border
              v-loading="loading"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              :row-style="{height: '30px'}"
              @selection-change="handleSelectionChange">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                prop="code"
                label="工具编码">
              </el-table-column>
              <el-table-column
                prop="name"
                label="工具名称">
              </el-table-column>
              <el-table-column
                prop="model"
                label="工具型号">
              </el-table-column>
              <el-table-column
                prop="remark"
                label="备注">
              </el-table-column>
            </el-table>
            <div class="page-container">
              <el-pagination
                :current-page="page.currentPage"
                :page-sizes="page.pageSizes"
                :page-size="page.pageSize"
                layout="total, sizes, prev, pager, next"
                :pager-count="7"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </MDialog>

      </div>
    </div>
  </div>
</template>

<script>
import {tableColumns} from '@/views/service/demand-center/components/relation-tool-columns.js'
import FunctionHeader from '@/components/FunctionHeader/index'
import { getUUid, setTreeData } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import api from '@/api/toolManagement/toolLeader/index.js'


export default {
  name: 'RelationTool',
  components: { FunctionHeader },
  props: {
    toolList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      input:"",
      loading: false,
      visiable:false,
      tableColumns,
      showDatas:[],
      selectedDatas:[],
      functionHeader: {
        searchVal: '',
        tags: [],
      },
      tableData: [],
      page: {
        default: 20,
        pageSize: 20,
        pageSizes: [10, 20, 50, 100, 150],
        total: 0,
        currentPage: 1
      },
    }
  },
  computed: {
    heights() {
      return this.$store.state.app.contentHeight - 256 - 400
    }
  },
  watch: {
    visiable(val){
      if(val){
        this.selectedDatas = [];
        this.setTableData(true);
      }
    },
    toolList(val){
      consoleSelf.info("传过来的数据",val);
      if(val.length){
        const arr = [...val];
        arr.forEach(item => {
          item.num = item.quantity || 1;
        })
        this.showDatas = arr;
      }
    }
  },
  mounted() {

  },
  methods: {
    numChange(val,row,index){
      if(val < 1) val=1;
      let arr = [...this.showDatas];
      arr[index].num = +val;
      this.showDatas = arr;
    },
    reamrkChange(val,row,index){
      let arr = [...this.showDatas];
      arr[index].remark = val;
      this.showDatas = arr;
    },
    showInfo(){
      consoleSelf.info(this.showDatas);
    },
    addOrEditSubmit(){
      if(this.selectedDatas.length){
        this.selectedDatas.forEach(item => {
          item.num = 1
          item.toolId = item.id;
          item.remark = ""
        })
        this.selectedDatas = this.selectedDatas.filter(item => {
          return !this.showDatas.some( p => p.code === item.code )
        })
        this.showDatas = this.showDatas.concat(this.selectedDatas);
        this.visiable = false;
      }
    },
    // 数据选中
    handleSelectionChange(val) {
      this.selectedDatas = val;
    },
    addInfo() {
      this.visiable = true;
    },
    delInfos(row, index) {
      this.showDatas.splice(index, 1)
    },
    // 每页数据量变化
    handleSizeChange(val) {
      this.page.pageSize = val
      this.setTableData()
    },
    // 当前页变化
    handleCurrentChange(val) {
      this.page.currentPage = val
      this.setTableData()
    },
    // 初始化分页
    resetPagination() {
      this.page.total = 0
      this.page.currentPage = 1
      this.page.pageSize = 20
    },
    // 查询数据
    async setTableData(reset) {
      this.loading = true
      let srcKey = ""
      const page = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
      }
      this.selectedDatas = [];
      if(this.functionHeader.searchVal) srcKey = this.functionHeader.searchVal
      if (reset) this.resetPagination()
      const res = await api.getToolLedgerListPage( {
        page: page.currentPage,
        limit: page.pageSize,
        searchKeyword:srcKey
      })
      if (res) {
        this.page.total = res.total
        this.tableData = res.records;
        this.loading = false;
      }

    },
  }
}
</script>

<style scoped lang="scss">
.equipment-monitoring {
  width: 100%;
  ::v-deep {
    .el-form-item__label {
      padding: 0 12px 0 0;
    }
    // .el-form-item__content {
    //   width: 300px;
    // }
    .el-form-item {
      margin-bottom: 20px!important;
    }
  }
  .card-wrapper {
    border: 1px solid #cccccc;
    border-radius: 4px;
    .top {
      width: 100%;
      padding: 10px;
      height: 53px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      .right {
        height: 32px;
        img {
          width: 30px;
          padding-left: 10px;
          position: relative;
          top: 5px;
        }
      }
    }
    .body {
      padding: 10px;
      .li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .line-wrapper {
    width: calc(100% - 260px);
  }
  .equipment-params {
    width: 250px;
  }
}

.bottom .function-header-wrap .input-wrapper{
  right:5px;
}

.m-table {
  position: relative;
  .num-img {
    width: 14px;
    height: 14px;
    display: inline-block;
    cursor: pointer;
    margin-left: 8px;
  }
  ::v-deep {
    .ellipsis-table {
      .cell{
        padding-left:8px;
        padding-right:8px;
      }
      td:not(:last-child),
      th {
        .cell > button,
        .cell > div > button,
        .cell > span > button {
          width: 100%;
        }
        .cell > div > a > button,
        .cell > div > span > button {
          width: 100%;
          text-align: left;
        }
        .cell,
        .cell > button,
        .cell > div > button,
        .cell > div,
        .cell > div > a > button,
        .cell .tooltip,
        .cell > span > button,
        .cell > div > span > button,
        .cell > div > div:not(.el-image) {
          box-sizing: border-box;
          text-align: left;
          white-space: nowrap !important;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .el-table__append-wrapper{
        position:relative;
        z-index:998
      }
    }
    .hidden-empty{
      .el-table__empty-block{
        display:none;
      }
    }
  }
}
.page-container {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  ::v-deep {
    .el-pager li {
      min-width: 26.5px;
    }
  }
}

.line-height-setting {
  .setting-item {
    padding: 5px 12px;
    display: flex;
    gap: 0 10px;
    align-items: center;
    cursor: pointer;

    &.actived {
      background-color: #E6EAF8;
    }

    img {
      width: 13px;
    }
  }
}
.columns-setting {
  position: absolute;
  right: 0;
  top: -35px;
  cursor: pointer;

  .el-button {
    margin-left: 12px;
  }

  .line-height {
    width: 14px;
  }
  .setting {
    width: 15px;
  }
  .refresh {
    width: 14px;
  }
}
.popover-content-top {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  button {
    padding: 8px 20px;
    border-radius: 4px;
    border-color: #dedeee;
    color: #8a8ca5;
    &:last-child {
      border-color: #607fff;
      color: #607fff;
    }
  }
}
.popover-content-center {
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  background: #f8f8fb;
  height: 25px;
  button.invert {
    span {
      color: #8a8ca5;
    }
    img {
      position: relative;
      top: 2px;
      right: 10px;
    }
  }
}
.popover-content-footer {
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  max-height: 380px;
  overflow-y: auto;
  .el-checkbox-group {
    overflow: hidden;
    .el-checkbox {
      width: 100%;
      padding: 8px 8px 8px 28px;
      cursor: pointer;
      &:hover {
        background: #ecf0f4;
      }
      ::v-deep {
        .el-checkbox__label {
          width:90%;
        }
      }
    }
  }
}
</style>
