var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-setting" },
    [
      _vm._l(_vm.data, function(item, index) {
        return _c("div", { key: item.id, staticClass: "common-card" }, [
          index === 0 && !_vm.isSign
            ? _c("div", { staticClass: "card-tit" }, [
                _c("div", { staticClass: "card-le" }, [
                  _vm._v("系统版本号：" + _vm._s(_vm.version))
                ])
              ])
            : _vm._e(),
          index === 0 && _vm.isSign
            ? _c("div", { staticClass: "card-tit" }, [
                _c("div", { staticClass: "card-le" }, [
                  _vm._v(
                    _vm._s(item.childrenList[1].name) +
                      "：" +
                      _vm._s(item.childrenList[1].value)
                  )
                ])
              ])
            : _vm._e(),
          index > 0
            ? _c("div", { staticClass: "card-tit" }, [
                _c("div", { staticClass: "card-le" }, [
                  _vm._v(_vm._s(item.name))
                ]),
                !_vm.isEdit[index]
                  ? _c(
                      "div",
                      { staticClass: "card-ri" },
                      [
                        item.name === "开票信息"
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:copy",
                                    value: _vm.fixCopy(item.childrenList),
                                    expression: "fixCopy(item.childrenList)",
                                    arg: "copy"
                                  },
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:success",
                                    value: _vm.onCopy,
                                    expression: "onCopy",
                                    arg: "success"
                                  },
                                  {
                                    name: "clipboard",
                                    rawName: "v-clipboard:error",
                                    value: _vm.onError,
                                    expression: "onError",
                                    arg: "error"
                                  }
                                ]
                              },
                              [_vm._v(" 复制 ")]
                            )
                          : _vm._e(),
                        item.isReadOnly === 0 && item.name === "开票信息"
                          ? _c("el-divider", {
                              attrs: { direction: "vertical" }
                            })
                          : _vm._e(),
                        item.isReadOnly === 0
                          ? _c(
                              "span",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.edit(index)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          index === 0
            ? _c("div", { staticClass: "card-bot" }, [
                !_vm.isSign
                  ? _c("div", { staticClass: "bot-tit" }, [
                      _vm._v(
                        _vm._s(item.childrenList[1].name) +
                          "：" +
                          _vm._s(item.childrenList[1].value) +
                          "/" +
                          _vm._s(item.childrenList[0].value)
                      )
                    ])
                  : _vm._e(),
                _vm.isSign
                  ? _c("div", { staticClass: "bot-tit" }, [
                      _vm._v(
                        _vm._s(item.childrenList[2].name) +
                          "：" +
                          _vm._s(item.childrenList[2].value) +
                          "/" +
                          _vm._s(item.childrenList[0].value)
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          index > 0 && !_vm.isEdit[index]
            ? _c(
                "div",
                { staticClass: "card-bot" },
                _vm._l(item.childrenList, function(items) {
                  return _c(
                    "div",
                    { key: items.id, staticClass: "bot-tit" },
                    [
                      _vm._v(" " + _vm._s(items.name) + "： "),
                      items.type !== 4
                        ? [
                            items.type === 1
                              ? _c("img", {
                                  staticClass: "avatars",
                                  attrs: { src: items.value }
                                })
                              : _c("span", [_vm._v(_vm._s(items.value))])
                          ]
                        : [
                            _c(
                              "el-popover",
                              {
                                ref: "popover1",
                                refInFor: true,
                                attrs: {
                                  "popper-class": "popover-class",
                                  placement: "top",
                                  title: "扫码下载",
                                  trigger: "hover"
                                }
                              },
                              [
                                _c("VueQr", {
                                  attrs: {
                                    "correct-level": 3,
                                    "auto-color": false,
                                    "color-dark": "#000000",
                                    text: items.value,
                                    size: 100,
                                    margin: 0
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "popover",
                                    rawName: "v-popover:popover1",
                                    arg: "popover1"
                                  }
                                ],
                                attrs: { type: "text" }
                              },
                              [
                                items.value
                                  ? _c(
                                      "a",
                                      {
                                        staticStyle: { color: "#5878fc" },
                                        attrs: {
                                          href: items.value,
                                          download: "",
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("下载")]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
          index > 0 && _vm.isEdit[index]
            ? _c(
                "div",
                { staticClass: "card-bot" },
                [
                  _vm._l(item.childrenList, function(items, childIndex) {
                    return _c(
                      "div",
                      { key: items.id, staticClass: "bot-edit" },
                      [
                        _c("div", { staticClass: "edtit-le" }, [
                          _vm._v(_vm._s(items.name) + "：")
                        ]),
                        _c(
                          "div",
                          { staticClass: "edtit-ri" },
                          [
                            items.type === 5
                              ? _c("div", [_vm._v(_vm._s(items.value))])
                              : _vm._e(),
                            items.type === 0
                              ? _c("el-input", {
                                  staticClass: "w250",
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: items.value,
                                    callback: function($$v) {
                                      _vm.$set(items, "value", $$v)
                                    },
                                    expression: "items.value"
                                  }
                                })
                              : _vm._e(),
                            items.type === 2
                              ? _c("el-input-number", {
                                  staticClass: "w250",
                                  attrs: {
                                    min: 1,
                                    "controls-position": "right",
                                    step: 1
                                  },
                                  model: {
                                    value: items.value,
                                    callback: function($$v) {
                                      _vm.$set(items, "value", $$v)
                                    },
                                    expression: "items.value"
                                  }
                                })
                              : _vm._e(),
                            items.type === 3
                              ? _c(
                                  "el-select",
                                  {
                                    staticClass: "w250",
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: items.value,
                                      callback: function($$v) {
                                        _vm.$set(items, "value", $$v)
                                      },
                                      expression: "items.value"
                                    }
                                  },
                                  _vm._l(
                                    _vm.fixData(items.possibleValues),
                                    function(iten) {
                                      return _c("el-option", {
                                        key: iten,
                                        attrs: { label: iten, value: iten }
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            items.type === 1
                              ? _c(
                                  "el-upload",
                                  _vm._b(
                                    { staticClass: "avatar-uploader" },
                                    "el-upload",
                                    _vm.uploadProps,
                                    false
                                  ),
                                  [
                                    items.value
                                      ? _c("img", {
                                          staticClass: "avatar",
                                          attrs: { src: items.value }
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "el-icon-plus avatar-uploader-icon"
                                        }),
                                    _c("div", { staticClass: "test" }, [
                                      _c("i", { staticClass: "el-icon-edit" })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            items.type === 4
                              ? _c(
                                  "el-upload",
                                  _vm._b(
                                    {
                                      staticClass: "file-upload",
                                      attrs: {
                                        "before-upload": function(file) {
                                          return _vm.beforeApkUpload(
                                            file,
                                            index
                                          )
                                        },
                                        "on-success": function(res, file) {
                                          return _vm.apkUploadChange(
                                            res,
                                            file,
                                            index,
                                            childIndex
                                          )
                                        },
                                        "on-error": function(res, file) {
                                          return _vm.apkUploadChange(
                                            res,
                                            file,
                                            index,
                                            childIndex
                                          )
                                        }
                                      }
                                    },
                                    "el-upload",
                                    _vm.uploadFileProps,
                                    false
                                  ),
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary"
                                        }
                                      },
                                      [_vm._v("点击上传")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "el-upload__tip",
                                        attrs: { slot: "tip" },
                                        slot: "tip"
                                      },
                                      [
                                        _vm._v(
                                          "只能上传apk文件，且文件名必须与版本号一致"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        item.name === "基本信息"
                          ? _c("div", { staticClass: "edit-radio" })
                          : _vm._e()
                      ]
                    )
                  }),
                  item.name === "基本信息"
                    ? _c("div", { staticClass: "tips" }, [
                        _vm._v(
                          "上传图片建议尺寸：120 像素 * 48 像素，大小不超过500K，格式：PNG（透明底）"
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "edit-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.close(index)
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading },
                          on: {
                            click: function($event) {
                              return _vm.onOk(index)
                            }
                          }
                        },
                        [_vm._v("确 定")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e()
        ])
      }),
      _vm._l(_vm.versionData, function(item) {
        return !_vm.isSign
          ? _c("div", { key: item.id, staticClass: "common-card" }, [
              _c("div", { staticClass: "card-tit" }, [
                _c("div", { staticClass: "card-le" }, [
                  _vm._v(_vm._s(item.name))
                ])
              ]),
              _c(
                "div",
                { staticClass: "card-bot" },
                _vm._l(item.childrenList, function(items) {
                  return _c(
                    "div",
                    { key: items.id, staticClass: "bot-tit" },
                    [
                      _vm._v(" " + _vm._s(items.name) + "： "),
                      items.type !== 4
                        ? [
                            items.type === 1
                              ? _c("img", {
                                  staticClass: "avatars",
                                  attrs: { src: items.value }
                                })
                              : _c("span", [_vm._v(_vm._s(items.value))])
                          ]
                        : [
                            _c(
                              "el-popover",
                              {
                                ref: "popover1",
                                refInFor: true,
                                attrs: {
                                  "popper-class": "popover-class",
                                  placement: "top",
                                  title: "扫码下载",
                                  trigger: "hover"
                                }
                              },
                              [
                                _c("VueQr", {
                                  attrs: {
                                    "correct-level": 3,
                                    "auto-color": false,
                                    "color-dark": "#000000",
                                    text: items.value,
                                    size: 100,
                                    margin: 0
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "popover",
                                    rawName: "v-popover:popover1",
                                    arg: "popover1"
                                  }
                                ],
                                attrs: { type: "text" }
                              },
                              [
                                items.value
                                  ? _c(
                                      "a",
                                      {
                                        staticStyle: { color: "#5878fc" },
                                        attrs: {
                                          href: items.value,
                                          download: "",
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("下载")]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                    ],
                    2
                  )
                }),
                0
              )
            ])
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }