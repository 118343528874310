import { installationProgress } from '@/config/options.sales'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  {
    prop: 'workOrderCode',
    label: '工单编号',
    sortable: false,
    hiddenSearch: true,
    form: { index: 1, required: false, tagName: 'el-input', colSpan: 6 }
  },
  {
    prop: 'demandCode',
    label: '关联需求',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 2,
      tagName: 'div',
      required: false,
      customSlots: ['demandCode'],
      colSpan: 6
    }
  },
  {
    prop: 'workOrderTypeName',
    label: '工单类型',
    sortable: false,
    hiddenSearch: true,
    form: { index: 3, tagName: 'div', key: 'workOrderType', customSlots: ['workOrderType'], colSpan: 6 }
  },
  {
    prop: 'customerSiteIdList',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      index: 4,
      tagName: 'el-cascader',
      key: 'customerSiteIdList',
      colSpan: 6,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.mulChange(val) }
      }
    }
  },
  {
    prop: 'facilityName',
    label: '设备',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 5,
      colSpan: 6,
      tagName: 'el-select',
      key: 'facilityId',
      props: {}
    }
  }
  // {
  //   prop: 'startDate',
  //   label: '开机时间',
  //   sortable: false,
  //   hiddenSearch: true,
  //   width: 280,
  //   form: {
  //     index: 7, tagName: 'el-date-picker', colSpan: 6,
  //     props: {
  //       type: 'datetime',
  //       valueFormat: 'yyyy-MM-dd HH:mm',
  //       format: 'yyyy-MM-dd HH:mm',
  //     }
  //   }
  // },
  // {
  //   prop: 'debugDate',
  //   label: '调试时间',
  //   sortable: false,
  //   hiddenSearch: true,
  //   width: 280,
  //   form: {
  //     index: 7, tagName: 'el-date-picker', colSpan: 6,
  //     props: {
  //       type: 'datetime',
  //       valueFormat: 'yyyy-MM-dd HH:mm',
  //       format: 'yyyy-MM-dd HH:mm',
  //     }
  //   }
  // }
]

export const hoursColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'jobType',
    label: '工作类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'jobType',
    columns: 10
  },
  {
    prop: 'hour',
    label: '时长（合计：小时）',
    sortable: false,
    hiddenSearch: true,
    slotName: 'hour',
    columns: 6
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80
  }
]

export const processColumns = [
  {
    prop: 'moveInTime',
    label: '搬入',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 1,
      colSpan: 6,
      tagName: 'el-select',
      key: 'moveInTime',
      options: installationProgress,
      defaultValue: 0
    }
  },
  {
    prop: 'surveyTime',
    label: '会勘',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 2,
      colSpan: 6,
      tagName: 'el-select',
      key: 'surveyTime',
      options: installationProgress,
      defaultValue: 0
    }
  },
  {
    prop: 'startingUpTime',
    label: '开机',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 3,
      colSpan: 6,
      tagName: 'el-select',
      key: 'startingUpTime',
      options: installationProgress,
      defaultValue: 0
    }
  },
  {
    prop: 'debuggingTime',
    label: '调试',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 4,
      colSpan: 6,
      tagName: 'el-select',
      key: 'debuggingTime',
      options: installationProgress,
      defaultValue: 0
    }
  },
  {
    prop: 'checkAndAcceptTime',
    label: '验收',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 5,
      colSpan: 6,
      tagName: 'el-select',
      key: 'checkAndAcceptTime',
      options: installationProgress,
      defaultValue: 0
    }
  }
]
