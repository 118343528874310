let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
// 入库
export const InColumns = [
  {
    prop: 'number',
    label: '入库单号',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 160,
    form: { index: 0, tagName: 'el-input', colSpan: 6 }
  },
  {
    prop: 'outInWarehouseTeypeId',
    label: '入库类型',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      colSpan: 6,
      tagName: 'el-select',
      options: []
    }
  },
  {
    prop: 'orderNumber',
    label: '关联单号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160,
    form: { index: 2, colSpan: 6, tagName: 'el-input', required: false }
  },
  {
    prop: 'warehouseId',
    label: '存放仓库',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      colSpan: 6,
      tagName: 'el-select',
      options: [],
      on: {
        change: (val) => { functionList.warehousChange(val) }
      }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      colSpan: 6,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  }
]
// 出库
export const OutColumns = [
  {
    prop: 'number',
    label: '出库单号',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 160,
    form: { index: 0, colSpan: 6, tagName: 'el-input' }
  },
  {
    prop: 'outInWarehouseTeypeId',
    label: '出库类型',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      colSpan: 6,
      tagName: 'el-select',
      options: [],
      on: {
        change: (val) => { console.log('val==', val) }
      }
    }
  },
  {
    prop: 'orderNumber',
    label: '关联单号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160,
    form: {
      index: 2,
      key: 'orderNumber',
      required: false,
      colSpan: 6,
      tagName: 'div', customSlots: ['orderNumber']
    }
  },
  {
    prop: 'warehouseId',
    label: '出库仓库',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      colSpan: 6,
      tagName: 'el-select',
      options: [],
      on: {
        change: (val) => { functionList.warehousChange(val) }
      }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  }
]
// 调拨
export const AllocationColumn = [
  {
    prop: 'number',
    label: '调拨单号',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 160,
    form: { index: 0, colSpan: 6, tagName: 'el-input' }
  },
  {
    prop: 'orderNumber',
    label: '关联单号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160,
    form: { index: 1, colSpan: 6, tagName: 'el-input', required: false }
  },
  {
    prop: 'warehouseId',
    label: '调出仓库',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 2,
      colSpan: 6,
      tagName: 'el-select',
      options: [],
      on: {
        change: (val) => { functionList.warehousChange(val) }
      }
    }
  },
  {
    prop: 'inWarehouseId',
    label: '调入仓库',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      colSpan: 6,
      tagName: 'el-select',
      options: [],
      on: {
        change: (val) => { functionList.inWarehousChange(val) }
      }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  }
]
// 移库
export const TransferWarehouseColumn = [
  {
    prop: 'number',
    label: '移库单号',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 160,
    form: { index: 0, tagName: 'el-input', colSpan: 6 }
  },
  {
    prop: 'warehouseId',
    label: '仓库',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      colSpan: 6,
      tagName: 'el-select',
      options: [],
      on: {
        change: (val) => { functionList.warehousChange(val) }
      }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 2,
      colSpan: 6,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  }
]
// 仓库盘点
export const WarehouseInventoryColumn = [
  {
    prop: 'number',
    label: '盘点单号',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 160,
    form: { index: 0, colSpan: 8, tagName: 'el-input' }
  },
  {
    prop: 'warehouseId',
    label: '盘点仓库',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      colSpan: 8,
      tagName: 'el-select',
      options: [],
      on: {
        change: (val) => { functionList.inventoryWarehousChange(val) }
      }
    }
  },
  {
    prop: 'warehouseLocationIds',
    label: '库位',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      colSpan: 8,
      tagName: 'el-cascader',
      key: 'warehouseLocationIds',
      required: false,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true,
          multiple: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.warehousLocationChange(val) }
      }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      colSpan: 8,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  }
]
// 物料盘点
export const MaterialInventoryColumn = [
  {
    prop: 'number',
    label: '盘点单号',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 160,
    form: { index: 0, colSpan: 6, tagName: 'el-input' }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      colSpan: 6,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  }
]

// 物料-入库
export const InMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'code',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseBatchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseBatchNumber'
  },
  {
    prop: 'quantity',
    label: '散件入库数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantity',
    width: 150
  },

  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },

  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseLocationName',
    width: 200
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 150
  }
]
// 物料-出库
export const OutMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseBatchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseBatchNumber'
  },
  {
    prop: 'containerSupport',
    label: '散件可用数量',
    sortable: false,
    hiddenSearch: true,
    width: 150
  },
  {
    prop: 'quantity',
    label: '散件数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantity',
    width: 150
  },

  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true
  },
  // {
  //   prop: 'currentQuantity',
  //   label: '可用库存',
  //   sortable: false,
  //   hiddenSearch: true,
  // },
  {
    prop: 'containerSupport',
    label: '散件可用数量',
    sortable: false,
    hiddenSearch: true,
    width: 150
  },
  {
    prop: 'quantity',
    label: '散件数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantity',
    width: 150
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 150
  }
]
// 物料-调拨
export const AllocationMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'currentQuantity',
    label: '可用库存',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '调出数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantity',
    width: 150
  },
  {
    prop: 'inWarehouseLocationName',
    label: '调入库位',
    sortable: false,
    hiddenSearch: true,
    slotName: 'inWarehouseLocationName'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 150
  }
]
// 物料-移库
export const TransferMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'currentQuantity',
    label: '可用库存',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '转移数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantity',
    width: 150
  },
  {
    prop: 'inWarehouseLocationName',
    label: '移入库位',
    sortable: false,
    hiddenSearch: true,
    slotName: 'inWarehouseLocationName'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 150
  }
]
// 物料-仓库盘点
export const WarehouseMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseLocationName'
  },
  {
    prop: 'currentQuantity',
    label: '盘前库存',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '实际库存',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantity',
    width: 150
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 150
  }
]
// 物料-物料盘点
export const MaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseName'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true,
    slotName: 'materialWarehouseLocationName'
  },
  {
    prop: 'currentQuantity',
    label: '盘前库存',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '实际库存',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantity',
    width: 150
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 150
  }
]

// 物料-整件入库
export const AllInMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'code',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseBatchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseBatchNumber'
  },
  {
    prop: 'containerQuantity',
    label: '整件入库数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'containerQuantity',
    width: 150
  },

  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'containerNo',
    label: '容器编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'containerNo'
  },
  {
    prop: 'containerName',
    label: '容器名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'containerName'
  },
  {
    prop: 'capacity',
    label: '容量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'capacity'
  },
  {
    prop: 'containerQuantity',
    label: '整件入库数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'containerQuantity',
    width: 150
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseLocationName',
    width: 200
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 150
  }
]
// 物料-整件出库
export const AllOutMaterialColumn = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseBatchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseBatchNumber'
  },
  {
    prop: 'containerAvailable',
    label: '整件可用数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'containerQuantity',
    label: '整件数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'containerQuantity',
    width: 150
  },

  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'containerNo',
    label: '容器编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'containerName',
    label: '容器名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'capacity',
    label: '容量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'containerAvailable',
    label: '整件可用数量',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'containerQuantity',
    label: '整件数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'containerQuantity',
    width: 150
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 150
  }
]
