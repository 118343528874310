var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "materials-wrap" }, [
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "Split",
          {
            attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
            on: {
              "update:offset": function($event) {
                return _vm.$set(_vm.split, "offset", $event)
              }
            }
          },
          [
            _c(
              "template",
              { slot: "left" },
              [
                _c("FaultClass", { on: { nodeClick: _vm.setMaterialsClassId } })
              ],
              1
            ),
            _c("template", { slot: "right" }, [
              _c(
                "div",
                { staticClass: "top" },
                [
                  _c("SearchForm", {
                    attrs: {
                      "form-list-extend": _vm.searchFormList,
                      "form-data": _vm.searchFormData
                    },
                    on: { search: _vm.searchTable }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "materials-info" },
                [
                  _c("FunctionHeader", {
                    ref: "functionHeader",
                    attrs: {
                      "search-title": "经验编号/名称/故障编码",
                      tags: _vm.functionHeader.tags
                    },
                    on: {
                      search: function($event) {
                        return _vm.$refs.mTable.setTableData(true)
                      }
                    },
                    model: {
                      value: _vm.functionHeader.searchVal,
                      callback: function($$v) {
                        _vm.$set(_vm.functionHeader, "searchVal", $$v)
                      },
                      expression: "functionHeader.searchVal"
                    }
                  }),
                  _c("MTable", {
                    ref: "mTable",
                    attrs: {
                      columns: _vm.columns,
                      height: _vm.height,
                      "set-data-method": _vm.getTableData
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "code",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "el-button",
                            {
                              staticStyle: { "white-space": "pre-wrap" },
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.modifyOrCopy(row, "view")
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.code))]
                          )
                        }
                      },
                      {
                        key: "action",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              _vm.permission("Modify")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.modifyOrCopy(row, "modify")
                                        }
                                      }
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              _vm.permission("Modify")
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  })
                                : _vm._e(),
                              _vm.permission("Del")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.del(row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }