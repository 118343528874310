import _ from 'lodash'
import { addColumns, userColumns, usersColumns, approvalColumns, resetColumns, otherAdd } from './columns'
import { nameChangeForBB, transformColumnsToForm } from '@/utils'
import { demandServiceType, demandStatus, demandServiceContentType } from '@/config/options.sales'
import dayjs from 'dayjs'

export const formList = [
  ...transformColumnsToForm(addColumns)
]

export const otherAddList = [
  ...transformColumnsToForm(otherAdd)
]
export const userFormList = [
  ...transformColumnsToForm(userColumns)
]
export const resetFormList = transformColumnsToForm(resetColumns)
export const usersFormList = transformColumnsToForm(usersColumns)
export const approvalFormList = transformColumnsToForm(approvalColumns)
export const searchFormList = [
  // {
  //   colSpan: 6,
  //   key: 'status',
  //   tagName: 'el-select',
  //   defaultValue: 1,
  //   props: {
  //     placeholder: '状态'
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'name',
  //         value: 'id'
  //       },
  //       options: demandStatus
  //     }
  //   ]
  // },
  {
    colSpan: 6,
    key: 'content',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '服务内容'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: demandServiceType
      }
    ]
  },
  {
    key: 'customerName',
    tagName: 'el-cascader',
    colSpan: 5,
    props: {
      placeholder: '客户/现场',
      props: {
        label: 'name',
        value: 'id',
        checkStrictly: true
      },
      options: [],
      collapseTags: true,
      filterable: true
    }
  },
  // {
  //   colSpan: 6,
  //   key: 'customerId',
  //   tagName: 'el-select',
  //   defaultValue: 1,
  //   props: {
  //     placeholder: '客户'
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'name',
  //         value: 'id'
  //       },
  //       options: []
  //     }
  //   ]
  // },
  {
    colSpan: 6,
    key: 'serviceType',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '服务类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: demandServiceContentType
      }
    ]
  },
  {
    colSpan: 6,
    key: 'salesOrderNumber',
    tagName: 'el-input',
    props: {
      placeholder: nameChangeForBB() ? '出厂编号' : '销售订单号'
    }
  },
  {
    colSpan: 6,
    key: 'principalNames',
    tagName: 'el-select',
    props: {
      placeholder: '当前负责人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 11,
    key: 'planTimeRange',
    tagName: 'el-date-picker',
    wcFlag: 'RRR',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '计划执行开始时间',
      endPlaceholder: '计划执行结束时间',
      defaultTime: ['00:00:00', '23:59:59'],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = dayjs().format('YYYY-MM-DD 23:59:59')
            const start = dayjs().format('YYYY-MM-DD 00:00:00')
            picker.$emit('pick', [start, end])
          }
        },
          {
            text: '昨天',
            onClick(picker) {
              const end = dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
              const start = dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
      },
      align: 'center',
      type: 'datetimerange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss'
    }
  },
  {
    colSpan: 6,
    key: 'creator',
    tagName: 'el-select',
    props: {
      placeholder: '创建人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 11,
    key: 'createTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '创建开始时间',
      endPlaceholder: '创建结束时间',
      defaultTime: ['00:00:00', '23:59:59'],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = dayjs().format('YYYY-MM-DD 23:59:59')
            const start = dayjs().format('YYYY-MM-DD 00:00:00')
            picker.$emit('pick', [start, end])
          }
        },
          {
            text: '昨天',
            onClick(picker) {
              const end = dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
              const start = dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
      },
      align: 'center',
      type: 'datetimerange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss'
    }
  }
  // {
  //   colSpan: 6,
  //   key: 'createTime',
  //   tagName: 'el-date-picker',
  //   wcFlag:"TTT",
  //   props: {
  //     type: 'date',
  //     align: 'center',
  //     valueFormat: 'yyyy-MM-dd',
  //     placeholder: '创建时间',
  //     format: 'yyyy-MM-dd'
  //   }
  // },
]

