var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "work-order-progress-container" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.workOrderQuality, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        ),
        _vm._m(0)
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.dayQuality, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "card-bottom" }, [
          this.memberCode === "jinshi"
            ? _c("span", [_vm._v("今日报工数")])
            : _c("span", [_vm._v("今日产量")])
        ])
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.delayQuality, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        ),
        _vm._m(1)
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(
                  _vm.digital.outsourceTaskQuantity,
                  ["#fff", "#fff"]
                )
              }
            })
          ],
          1
        ),
        _vm._m(2)
      ])
    ]),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c("scroll-board", {
          style: { width: "100%", height: _vm.tableHeight + "px" },
          attrs: { config: _vm.scrollBoardConfig },
          scopedSlots: _vm._u([
            {
              key: "materialsImg",
              fn: function(ref) {
                var index = ref.index
                return [
                  _vm.tableData[index].materialsPicture
                    ? _c("el-image", {
                        staticStyle: { width: "85px" },
                        attrs: {
                          src: _vm.tableData[index].materialsPicture,
                          "preview-src-list": [
                            _vm.tableData[index].materialsPicture
                          ]
                        }
                      })
                    : _vm._e()
                ]
              }
            },
            {
              key: "status",
              fn: function(ref) {
                var index = ref.index
                return _c(
                  "span",
                  {
                    staticClass: "status-board",
                    style: {
                      background:
                        _vm.productionStatus[_vm.tableData[index].status].color
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.productionStatus[_vm.tableData[index].status].name
                      )
                    )
                  ]
                )
              }
            },
            {
              key: "progress",
              fn: function(ref) {
                var index = ref.index
                return [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "progress-line-wrapper",
                        style: {
                          position: "relative",
                          left: -_vm.getLeft(index) + "px"
                        }
                      },
                      _vm._l(_vm.allProcedureList(index), function(
                        item,
                        index2
                      ) {
                        return _c(
                          "div",
                          {
                            key: index2,
                            staticClass: "progress-wrap",
                            class: {
                              "active-procedure":
                                _vm.tableData[index].status === 1 &&
                                _vm.currentProcedure(index) ===
                                  item.procedureUuid,
                              "wait-procedure": _vm.isWaitProcedure(index, item)
                            }
                          },
                          [
                            _c("custom-progress", {
                              attrs: {
                                type: "circle",
                                percentage: _vm.procedurePercentage(
                                  index,
                                  item
                                ),
                                color: "#99F4FF",
                                "stroke-w": _vm.isWaitProcedure(index, item)
                                  ? "#999"
                                  : "#2E4398",
                                width: 70,
                                "show-text": false
                              }
                            }),
                            _c("div", { staticClass: "progress-text" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.reportWorkQuantity) +
                                    "/" +
                                    _vm._s(item.sourceQuantity)
                                )
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "procedure-name ellipsis" },
                              [_vm._v(_vm._s(item.procedureName))]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-bottom" }, [
      _c("span", [_vm._v("工单总数")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-bottom" }, [
      _c("span", [_vm._v("延期工单数")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-bottom" }, [
      _c("span", [_vm._v("外协任务数")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }