var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "unit-wrap" }, [
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "FunctionHeader",
          {
            ref: "functionHeader",
            attrs: {
              "search-title": "请输入工单编号/设备编号/设备名称/SN",
              tags: _vm.functionHeader.tags,
              "export-name": _vm.functionHeader.exportName,
              "export-params": _vm.functionHeader.exportParams,
              "show-export": !_vm.exportSelectionIds.length ? true : false,
              "hidden-mul-search": true
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              },
              checkSearch: _vm.checkSearch
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.exportSelectionIds.length,
                    expression: "exportSelectionIds.length"
                  }
                ],
                attrs: { slot: "all" },
                slot: "all"
              },
              [
                _vm.permission("Export")
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.allMore("batchExport")
                          }
                        }
                      },
                      [_vm._v("批量导出")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.allMore("delBatchRepairs")
                      }
                    }
                  },
                  [_vm._v("批量删除")]
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      margin: "0 10px",
                      display: "inline-block",
                      "font-size": "12px",
                      color: "#393D60"
                    }
                  },
                  [
                    _vm._v(" 已选 "),
                    _c("span", { staticStyle: { color: "#607FFF" } }, [
                      _vm._v(_vm._s(_vm.getSelectionDataLength))
                    ]),
                    _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: { click: _vm.resetSelectData }
                  },
                  [_vm._v("清空")]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "top" },
          [
            _c("SearchForm", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isShow,
                  expression: "!isShow"
                }
              ],
              ref: "searchForm",
              attrs: {
                "form-list-extend": _vm.getSearchFormList,
                "form-data": _vm.searchFormData
              },
              on: { search: _vm.searchTable }
            })
          ],
          1
        ),
        _c("MTable", {
          ref: "mTable",
          attrs: {
            columns: _vm.columns,
            height: _vm.height,
            "columns-key": _vm.columnsKey,
            "set-data-method": _vm.getTableData,
            "columns-setting": _vm.isShow
          },
          on: { "selection-change": _vm.selectionChange },
          scopedSlots: _vm._u(
            [
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "workOrderCode",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.viewRepairs(row)
                        }
                      }
                    },
                    [_vm._v(_vm._s(row.workOrderCode))]
                  )
                }
              },
              {
                key: "demandCode",
                fn: function(ref) {
                  var row = ref.row
                  return row.demandCode
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.viewDemandCenters(row)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(row.demandCode) + " ")]
                      )
                    : _c("div", { attrs: { type: "text" } }, [
                        _vm._v(_vm._s("-"))
                      ])
                }
              },
              {
                key: "executionStartTime",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      _vm._s(
                        _vm.getTimeDesc(
                          row.executionStartTime,
                          row.executionEndTime
                        )
                      )
                    )
                  ])
                }
              },
              {
                key: "createTime",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm.getTimeDesc(row.createTime)))
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _vm.permission("DelWorkOrder")
                    ? _c(
                        "div",
                        {},
                        [
                          [
                            _vm.permission("Modify") &&
                            _vm.fixUsers(row.updater, row.updatePermitUserId) &&
                            row.isUpdate === 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.modifyRepairs(row)
                                      }
                                    }
                                  },
                                  [_vm._v("修改")]
                                )
                              : _vm._e(),
                            _vm.permission("Modify") &&
                            _vm.fixUsers(row.updater, row.updatePermitUserId) &&
                            row.isUpdate === 1
                              ? _c("el-divider", {
                                  attrs: { direction: "vertical" }
                                })
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.del(row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        ],
                        2
                      )
                    : row.isUpdate === 1
                    ? _c(
                        "div",
                        {},
                        [
                          _vm.permission("Modify") &&
                          _vm.fixUsers(row.updater, row.updatePermitUserId)
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.modifyRepairs(row)
                                    }
                                  }
                                },
                                [_vm._v("修改")]
                              )
                            : _vm._e(),
                          _vm.permission("Modify") &&
                          _vm.fixUsers(row.updater, row.updatePermitUserId)
                            ? _c("el-divider", {
                                attrs: { direction: "vertical" }
                              })
                            : _vm._e(),
                          _vm.permission("Del") &&
                          _vm.fixUsers(row.updater, row.updatePermitUserId)
                            ? _c("DelPopover", {
                                on: {
                                  onOk: function(callback) {
                                    return _vm.del(row, callback)
                                  }
                                }
                              })
                            : _c(
                                "div",
                                { attrs: { slot: "action" }, slot: "action" },
                                [_vm._v(_vm._s("-"))]
                              )
                        ],
                        1
                      )
                    : _c("div", { attrs: { slot: "action" }, slot: "action" }, [
                        _vm._v(_vm._s("-"))
                      ])
                }
              }
            ],
            null,
            true
          )
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }