<template>
  <div class="all-statistics-card">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <div v-for="(item, index) in statisticsCard" :key="index">
        <!-- v-if="permission(item.key, false)" -->
        
        <!-- <el-tab-pane
          :key="item.key"
          :label="item.name"
          :name="item.key"
          :style="{
            height: paneHeight + 'px',
            overflow: 'auto',
            width: '100%'
          }"
        > -->
        <template v-if="activeName === 'workSpace'" >
          <Home/>
        </template>
          <template v-if="activeName === 'personalWorkbench'">
            <PersonalWorkbench/>
          </template>
          <template v-if="activeName === 'workHoursStatistic'">
            <ProjectHourStatistics/>
        </template>
          <template v-else v-for="(child, idx) in item.children">
            <div :key="child.id + 200" :class="child.fullscreen ? 'app-left-full' : ''">
              <el-card v-if="item.key === activeName" :key="child.id" class="box-card">
                <div slot="header" class="box-card-header">
                  <div>
                    <span>{{ child.name }}</span>
                    <el-tooltip v-if="child.tooltip" effect="dark" :content="child.tooltip" placement="top" style="padding-left: 5px">
                      <span class="el-icon-question" />
                    </el-tooltip>
                  </div>
                  <div
                    :style="{
                      position: 'relative',
                      right: '26px',
                      padding: '12px 0'
                    }"
                  >
                    <div class="header-ri" @click="toggleFullScreen(child, index, idx)">
                      <el-tooltip v-if="!child.fullscreen" class="item" effect="dark" content="放大" placement="top">
                        <img class="ri-img" src="@/assets/narrow-amplification/amplification.png" alt="">
                      </el-tooltip>
                      <el-tooltip v-else class="item" effect="dark" content="缩小" placement="top">
                        <img class="ri-img" src="@/assets/narrow-amplification/narrow.png">
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div>
                  <CustomTable v-if="child.type === 'table'" :tab-info="child" :to-day="toDay" />
                  <charts v-if="child.type === 'charts' || child.type === 'chartsAndTable'" :tab-info="child" />
                  <work-task-pass v-if="child.type === 'workTaskPass'" :tab-info="child" />
                </div>
              </el-card>
            </div>
        </template>
        <!-- </el-tab-pane> -->
      </div>
    </el-tabs>
  </div>
</template>

<script>
import CustomTable from './components/custom-table/custom-table'
import statisticsCard from './data'
import Charts from './components/charts/charts'
import systemParams from '@/api/sets/function/system-params'
import WorkTaskPass from './components/work-task-pass/work-task-pass'
import Home from '@/views/home/home'
import PersonalWorkbench from '@/views/project/MRP/projectManagement/personal-workbench/index'
import Vue from 'vue'
import ProjectHourStatistics from '@/views/project/MRP/projectManagement/project-hour-statistics/index'
export default {
  name: 'StatementManage',
  components: { WorkTaskPass, Charts, CustomTable ,Home, PersonalWorkbench,ProjectHourStatistics},
  data() {
    return {
      activeName: 'workSpace',
      statisticsCard,
      showPopover: false,
      allChecked: false,
      popoverContentMaxHeight: 200,
      checkedList: [],
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      settingColumns: [],
      toDay: false,
      fullscreen: true
    }
  },
  created(){
    this.activeName = this.$route.meta.nameFlage ? this.$route.meta.nameFlage : 'workSpace'
    this.statisticsCard = this.statisticsCard.filter(p => p.key === this.activeName)
  },
  computed: {
    paneHeight() {
      return this.$store.state.app.contentHeight - 244
    }
  },
  mounted() {
    this.setCheckNumberKey()
    if (this.$route.params.activeName != null) {
      this.activeName = this.$route.params.activeName
    }
    this.toDay = !!localStorage.getItem('toDay')
  },
  methods: {
    // 全屏
    toggleFullScreen(child, index, idx) {
      this.statisticsCard[index].children[idx].fullscreen = !child.fullscreen
      this.$set(this.statisticsCard[index].children, idx, this.statisticsCard[index].children[idx])
    },
    handleClick(name) {
      console.log(name)
      while (Vue.Cancel.length > 0) {
        Vue.Cancel.shift()('cancel')
      }
    },
    async setCheckNumberKey() {
      const res = await systemParams.getSystemParamsList({ code: 'stockWarn' })
      if (res && res[0]) {
        sessionStorage.setItem('stockWarnSetting', JSON.stringify(res[0]))
      }
    },
    setToDay() {
      if (this.toDay) {
        localStorage.setItem('toDay', this.toDay)
      } else {
        localStorage.removeItem('toDay')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.all-statistics-card {
  padding: 10px;
  position: relative;
  ::v-deep .el-tabs__header {
    display: none;
    margin: 0;
  }
  ::v-deep .el-tabs__content {
    padding: 8px;
    height: calc(100% - 56px);
  }
  .box-card {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    &:not(:first-child) {
      margin-top: 15px;
    }
    ::v-deep .el-card__header {
      background: #f6f7f9;
      padding: 0 18px;
      .box-card-header {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    ::v-deep .el-card__body {
      padding: 20px 18px;
    }
  }
  .header-ri {
    margin-right: 20px;
    cursor: pointer;
    display: inline-block;
    img {
      width: 15px;
      height: 15px;
      display: block;
    }
  }
  .app-left-full {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    background: #fff;
    width: 100%;
    z-index: 2000;
    padding: 20px;
  }
}
</style>
