var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "big-screen-container",
    { ref: "container" },
    [
      _vm.loading ? _c("dv-loading") : _vm._e(),
      _c("div", { staticClass: "top-container" }, [
        _c("div", { staticClass: "title-wrapper" }, [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.name[_vm.$route.params.type]))
          ]),
          _c("img", {
            attrs: {
              src: "https://oss.mubyte.cn/static/%E5%A4%B4%E9%83%A8%402x.png",
              alt: ""
            }
          })
        ]),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "company-logo" }, [
            _c("img", {
              attrs: {
                src: "https://oss.mubyte.cn/static/%E5%85%AC%E5%8F%B8%402x.png",
                alt: ""
              }
            }),
            _c("span", [_vm._v(_vm._s(_vm.companyInfo.name))])
          ]),
          _c("div", { staticClass: "time-container" }, [
            _c("img", {
              attrs: { src: "https://oss.mubyte.cn/static/date-b.png", alt: "" }
            }),
            _c("div", { staticClass: "date-time" }, [
              _c("div", { staticClass: "date" }, [
                _vm._v(
                  _vm._s(_vm.currentDate) +
                    "（" +
                    _vm._s(_vm.currentWeek) +
                    "）"
                )
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.currentTime))
              ])
            ])
          ])
        ])
      ]),
      +_vm.$route.params.type === 1 ? _c("product", { ref: "board" }) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }