import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'

let functionList = {}
export const getFormFunctionList = val => {
  functionList = val
}

export const formList = transformColumnsToForm(columns)

export const descFormList = [
  {
    colSpan: 24,
    key: 'customerDescription',
    label: '客户描述',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过500字'
    }
  },
  {
    colSpan: 24,
    key: 'spotChecking',
    label: '现场检查',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过500字'
    }
  },
  {
    colSpan: 24,
    key: 'takeStep',
    label: '采取措施',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过500字'
    }
  }, {
    colSpan: 24,
    key: 'consequence',
    label: '结果',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过500字'
    }
  }, {
    colSpan: 24,
    key: 'followUpActions',
    label: '后继行动',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过500字'
    }
  }, {
    colSpan: 24,
    key: 'facilityHistoricalAlarmRecordList',
    label: '历史报警记录'
  }
]

export const localFormList = [
  {
    colSpan: 6,
    key: 'executionStartTime',
    label: '服务开始时间',
    tagName: 'el-date-picker',
    required: true,
    props: {
      type: 'datetime',
      valueFormat: 'yyyy-MM-dd HH:mm',
      format: 'yyyy-MM-dd HH:mm'
    },
    attrs: {
      placeholder: '选择服务开始时间'
    },
    on: {
      change: (val) => { functionList.valueChange('executionStartTime', val) }
    }
  },
  {
    colSpan: 6,
    key: 'executionEndTime',
    label: '服务结束时间',
    tagName: 'el-date-picker',
    required: true,
    props: {
      type: 'datetime',
      valueFormat: 'yyyy-MM-dd HH:mm',
      format: 'yyyy-MM-dd HH:mm'
    },
    attrs: {
      placeholder: '服务结束时间'
    },
    on: {
      change: (val) => { functionList.valueChange('executionEndTime', val) }
    }
  },
  {
    colSpan: 12,
    key: 'manHour',
    label: '服务时长',
    tagName: 'div',
    children: [{
      customSlots: ['manHour']
    }]
  },
  {
    colSpan: 24,
    key: 'facilityServiceTimeMappingList',
    label: '协作人',
    tagName: 'div',
    required: false,
    children: [{
      customSlots: ['facilityServiceTimeMappingList']
    }]
  },
  {
    colSpan: 24,
    label: '点检异常',
    key: 'facilityInspectionSpotDetectioList',
    tagName: 'div',
    required: false,
    children: [{
      customSlots: ['facilityInspectionSpotDetectioList']
    }]
  },
  {
    colSpan: 24,
    label: '附件',
    key: 'affix',
    tagName: 'div',
    required: false,
    children: [{
      customSlots: ['drawing']
    }]
  }
]

export const localAbnormalFormList = [
  {
    colSpan: 6,
    key: 'facilityId',
    label: '异常设备',
    tagName: 'div',
    children: [{
      customSlots: ['facilityId']
    }],
    required: true
  },
  {
    colSpan: 6,
    key: 'abnormalItem',
    label: '异常项目',
    tagName: 'div',
    children: [{
      customSlots: ['abnormalItem']
    }],
    required: true
  },
  {
    colSpan: 12,
    key: 'description',
    label: '描述',
    tagName: 'div',
    children: [{
      customSlots: ['description']
    }]
  },
  {
    colSpan: 6,
    key: 'processMode',
    label: '处理方式',
    tagName: 'div',
    children: [{
      customSlots: ['processMode']
    }],
    required: true
  },
  {
    colSpan: 6,
    key: 'maintenanceWorkOrder',
    label: '维修工单',
    tagName: 'el-select',
    props: {
      placeholder: '选择设备',
      clearable: true
    },
    required: true,
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 12,
    key: 'add',
    tagName: 'div'
  }
]

export const executorFormList = [
  {
    colSpan: 6,
    key: 'executor',
    label: '',
    tagName: 'el-select',
    props: {
      placeholder: '添加协作人',
      multiple: true,
      clearable: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'isSame',
    tagName: 'div',
    children: [{
      customSlots: ['isSame']
    }]
  },
  {
    colSpan: 6,
    key: 'date',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: []
    }
  },
  {
    colSpan: 6,
    key: 'manHour',
    label: '服务时长',
    tagName: 'div',
    children: [{
      customSlots: ['manHour']
    }]
  }
]

