<template>
  <div class="equipment-monitoring">
    <div class="detail-cards">
      <div v-if="isTitle" class="card-tit">点检项目</div>
      <div class="card-bots">
        <div style="margin-bottom: 10px">
          <el-button size="small" type="primary" @click="addInfo">添加</el-button>
          <el-button size="small" type="primary" @click="addPlanInfo">从计划中导入</el-button>
          <el-button size="small" type="primary" @click="addPlanTemplateInfo">从计划模板中导入</el-button>
        </div>
        <MTable ref="planTable" :show-page="false" :height="isTitle ? 300 : heights" :columns="columns" :data="itemMappingList">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="action" slot-scope="{ row, $index }">
            <template v-if="type === 'new'">
              <el-button type="text" @click="modifyInfo(row, $index)">修改</el-button>
              <el-button type="text" @click="delInfo($index)">删除</el-button>
            </template>
            <template v-else>
              <el-button type="text" @click="modifyInfos(row)">修改</el-button>
              <el-button type="text" @click="delInfos(row)">删除</el-button>
            </template>
          </div>
        </MTable>
      </div>
    </div>
    <MDialog v-model="visible" :title="messages[currentType]" width="500px" @onOk="submitForm">
      <MFormBuilder ref="formBuild" label-position="right" label-width="100px" :form-data="formData" :form-list="formList" />
    </MDialog>

    <MDialog v-model="planVisible" :append-to-body="true" :title="params.radio === 'BatchPlan' ? '从计划中导入': '从计划模板中导入'" width="1000px" @onOk="onPlanConfirm">
      <div class="dialog-wrapper">
        <SearchForm v-if="params.radio === 'BatchPlan'" ref="searchForm" :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" />
        <FunctionHeader
          v-model="functionHeader.searchVal"
          :search-title="params.radio === 'BatchPlan' ? '请输入点检计划编号/名称' : '请输入模板名称'"
          :radio="params.radio"
          @search="$refs.mTable.setTableData(true)"
        />
        <MTable
          ref="mTable"
          :height="300"
          :highlight-current-row="true"
          :columns="params.radio === 'BatchPlan' ? planColumns : planTemplateColumns"
          :set-data-method="getTableData"
          :columns-setting="false"
          @current-change="handleCurrentChange"
        >
          <div slot="cycleType" slot-scope="{ row }">
            {{ row.cycleType || row.cycleType === 0 ? maintenanceCycleType[row.cycleType].name : '-' }}
          </div>
          <div slot="lifeCycle" slot-scope="{ row }">
            {{ row.lifeCycle || row.lifeCycle === 0 ? row.lifeCycle : '-' }}
          </div>
        </MTable>
      </div>
    </MDialog>

  </div>
</template>

<script>
import dayjs from 'dayjs'
import { getUUid, setTreeData } from '@/utils'
import { columns, planColumns, planTemplateColumns } from './columns'
import { formList, searchFormList } from './form-list'
import api from '@/api/xiewei/facility/point-item'
import mapi from '@/api/xiewei/facility/maintenance-item'
import keApi from '@/api/information/unit'
import papi from '@/api/xiewei/facility/point'
import conventionMixin from '@/mixin/conventional-page'
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import { maintenanceCycleType } from '@/config/options.sales'

export default {
  name: 'ItemsInfo',
  components: { FunctionHeader, SearchForm },
  mixins: [conventionMixin],
  props: {
    isTitle: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'new'
    },
    list: {
      type: Array,
      default: () => []
    },
    facilitySpotCheckPlanId: {
      type: Number,
      default: undefined
    },
    facilityPlanMaintenanceTemplateId: {
      type: Number,
      default: undefined
    },
    isTemplate: {
      type: Boolean,
      default: true
    },
    customerId: {
      type: Number,
      default: undefined
    },
    customerSiteId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      api,
      mapi,
      maintenanceCycleType,
      planTemplateColumns,
      itemMappingList: [],
      date: dayjs().format('YYYY-MM-DD'),
      currentType: 'add',
      columns,
      planColumns,
      formList,
      searchFormList,
      tableData: [],
      formData: {},
      paramsVisible: false,
      visible: false,
      planVisible: false,
      messages: {
        add: '添加项目',
        modify: '修改项目'
      },
      currentIndex: -1,
      functionHeader: {
        searchVal: ''
      },
      params: {
        radio: 'BatchPlan'
      },
      searchFormData: {},
      extraParams: [],
      allCustomer: [],
      searchKeyword: true,
      selectId: '',
      planTemplateVisible: false,
      extraParams: [],
      customerIds: undefined
    }
  },
  computed: {
    heights() {
      return 202
    }
  },
  watch: {
    list(val) {
      if (val) {
        this.itemMappingList = val
      }
    },
    customerId(val) {
      this.customerIds = val
    }
  },
  mounted() {
    this.itemMappingList = this.list
    this.customerIds = this.customerId
  },
  methods: {
    async delInfos(node) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        let res
        if (this.isTemplate) {
          res = await mapi.delFacilityPlanMaintenanceTemplateItem({ id: node.id })
        } else {
          res = await api.delFacilitySpotCheckPlanItem({ id: node.id, facilitySpotCheckPlanId: this.facilitySpotCheckPlanId })
        }
        if (res) {
          this.$message.success('删除成功')
          this.$emit('refrash')
        }
      })
    },

    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.searchFormList[0].props.options = setTreeData(data)
      }
    },

    addInfo() {
      this.currentType = 'add'
      this.formData = {}
      this.visible = true
    },

    modifyInfo(row, index) {
      this.currentType = 'modify'
      this.formData = this._.cloneDeep(row)
      this.currentIndex = index
      this.visible = true
    },

    modifyInfos(row) {
      this.currentType = 'modify'
      this.formData = this._.cloneDeep(row)
      this.visible = true
    },

    delInfo(index) {
      this.itemMappingList.splice(index, 1)
    },

    submitForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async valid => {
        if (valid) {
          if (this.currentType === 'modify') {
            this.$set(this.itemMappingList, this.currentIndex, form)
          } else {
            this.itemMappingList.push({
              content: form.content
            })
          }
          this.$emit('onConfirm', this.itemMappingList)
          this.visible = false
          callback(true)
        } else {
          callback()
        }
      })
    },

    addPlanInfo() {
      this.params.radio = 'BatchPlan'
      this.extraParams = []
      this.planVisible = true
      this.selectId = ''
      this.functionHeader.searchVal = ''
      this.getAllCustomer()
    },

    addPlanTemplateInfo() {
      if (!this.customerIds) {
        return this.$message.error('请选择客户/现场')
      }
      this.params.radio = 'PlanTemplate'
      this.extraParams = [
        {
          id: getUUid(),
          fieldName: 'planTemplateSign',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }
      ]
      if (this.customerSiteId) {
        this.getTableDataParams = {
          customerSiteId: this.customerSiteId || ''
        }
      } else {
        this.getTableDataParams = {
          customerId: this.customerIds
        }
      }
      this.functionHeader.searchVal = ''
      this.planVisible = true
      this.selectId = ''
    },

    handleCurrentChange(row) {
      this.selectId = row.id
    },

    async onPlanConfirm(callback) {
      if (!this.selectId) {
        return this.$message.warning('请单击选择一条数据')
      }
      let res
      if (this.params.radio === 'BatchPlan') {
        res = await api.getSpockPlanList([this.selectId])
        this.$emit('onConfirm', res || [])
      }
      if (this.params.radio === 'PlanTemplate') {
        res = await papi.getPlanSpotCheckTemplateDetail({ id: this.selectId })
        this.$emit('onConfirm', res.itemMappingList || [])
      }
      this.planVisible = false
      callback(true)
    },

    searchTable(data) {
      const ids = data.map(item => item.fieldName)
      const hasIndex = ids.indexOf('customerName')
      this.searchFormatData = this._.cloneDeep(data)
      if (hasIndex !== -1) {
        this.searchFormatData.splice(hasIndex, 1)
        const id = data[hasIndex].fieldValue
        const arr = this.allCustomer.filter(item => item.id === this._.first(id))
        const list = this.allCustomer.filter(item => item.id === this._.last(id))
        this.searchFormatData = [
          ...this.searchFormatData,
          {
            id: getUUid(),
            fieldName: list[0].isCustomer === 1 ? 'customerSiteId' : 'customerId',
            fieldType: 'number',
            fieldValue: list[0].isCustomer === 1 ? list[0].id : arr[0].id,
            operator: 'eq'
          }
        ]
      }
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-monitoring {
  width: 100%;
  ::v-deep {
    .el-form-item__label {
      padding: 0 12px 0 0;
    }
    .el-form-item__content {
      width: 300px;
    }
    .el-form-item {
      margin-bottom: 20px !important;
    }
  }
}

::v-deep {
  .el-dialog {
    .dialog-wrapper {
      padding-bottom: 10px;
      border: 1px solid #ddd;
      .m-table {
        margin: 0 10px;
      }
    }
  }
}
</style>
