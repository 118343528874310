import _ from 'lodash'
import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'
import { demandServiceType } from '@/config/options.sales'
const newColumns = _.cloneDeep(columns)

export const formList = transformColumnsToForm(newColumns)

export const searchFormList = [
  {
    colSpan: 6,
    key: 'serviceContent',
    tagName: 'el-select',
    isDelete: true,
    props: {
      placeholder: '服务内容',
      multiple: true
    },
    fieldType: 'string',
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'name'
        },
        options: demandServiceType
      }
    ]
  },
  {
    colSpan: 6,
    key: 'consumerId',
    tagName: 'el-select',
    props: {
      placeholder: '客户',
      multiple: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'guaranteePeriodStart',
    tagName: 'el-date-picker',
    defaultValue: 1,
    isDelete: true,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '服务期限开始时间',
      endPlaceholder: '服务期限结束时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    dayFormat: 'YYYY-MM-DD',
    children: []
  }

]

