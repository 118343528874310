import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'
export const formList = {
  OutInWarehouseType: transformColumnsToForm(columns)
}

export const searchFormList = [
  // {
  //   colSpan: 6,
  //   key: 'executiveOutcome',
  //   tagName: 'el-select',
  //   defaultValue: 1,
  //   props: {
  //     placeholder: '执行结果',
  //     multiple: true
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'name',
  //         value: 'id'
  //       },
  //       options: [{
  //         name: '已跳过',
  //         id: 1
  //       }, {
  //         name: '已保养',
  //         id: 2
  //       }]
  //     }
  //   ]
  // },
  {
    colSpan: 6,
    key: 'customerName',
    tagName: 'el-cascader',
    props: {
      placeholder: '客户/现场',
      props: {
        label: 'name',
        value: 'id',
        checkStrictly: true
      },
      options: [],
      collapseTags: true,
      filterable: true
    }
  },
  {
    colSpan: 6,
    key: 'source',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '来源',
      multiple: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [{
          name: '用户新增',
          id: 0
        }, {
          name: '保养计划',
          id: 1
        }]
      }
    ]
  },
  {
    colSpan: 6,
    key: 'facilityPlanCode',
    tagName: 'el-input',
    attrs: {
      placeholder: '保养计划编号'
    },
    props: {
      clearable: true
    }
  },
  {
    colSpan: 6,
    key: 'facilityPlanName',
    tagName: 'el-input',
    attrs: {
      placeholder: '计划名称'
    },
    props: {
      clearable: true
    }
  },
  {
    colSpan: 6,
    key: 'level',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '保养级别',
      multiple: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [{
          name: '一级',
          id: 1
        }, {
          name: '二级',
          id: 2
        }, {
          name: '三级',
          id: 3
        }]
      }
    ]
  },
  {
    colSpan: 6,
    key: 'planExecutionStartTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '计划执行开始时间',
      endPlaceholder: '计划执行结束时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd',
      placeholder: '计划执行时间'
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'executionStartTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '实际服务开始时间',
      endPlaceholder: '实际服务结束时间',
      align: 'center',
      type: 'datetimerange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm',
      format: 'yyyy-MM-dd HH:mm',
      defaultTime: ['00:00:00', '23:59:00']
    },
    dayFormat: 'YYYY-MM-DD HH:mm',
    children: []
  },
  {
    colSpan: 6,
    key: 'executor',
    tagName: 'el-select',
    props: {
      placeholder: '执行人',
      multiple: true
    },
    isDelete: true,
    fieldType: 'string',
    children: [
      {
        tagName: 'el-option',
        innerValue: (val) => {
          return `${val['jobNumber']} - ${val['name']}`
        },
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  }
]
