<template>
  <div class="quality-container">
    <div class="top">
      <div>
        <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.processInspectionQuality, ['#fff', '#fff'])" style="width: 425px;height: 80px" /></div>
        <div class="card-bottom">
          <span>过程检任务数</span>
        </div>
      </div>
      <div>
        <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.firstInspectionQuality, ['#fff', '#fff'])" style="width: 425px;height: 80px" /></div>
        <div class="card-bottom">
          <span>首检任务数</span>
        </div>
      </div>
      <div>
        <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.returnQuality, ['#fff', '#fff'])" style="width: 425px;height: 80px" /></div>
        <div class="card-bottom">
          <span>返修任务数</span>
        </div>
      </div>
      <div>
        <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.errQuantity7, ['#fff', '#fff'])" style="width: 425px;height: 80px" /></div>
        <div class="card-bottom">
          <span>7天内新增不合格数</span>
        </div>
      </div>
      <div>
        <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.qualityPassQuantity7, ['#fff', '#fff'], '{nt}%')" style="width: 425px;height: 80px" /></div>
        <div class="card-bottom">
          <span>7天内质检合格率</span>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="process">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">7日内过程检合格率趋势图</div>
        <ve-histogram
          height="300px"
          :data="processInspectionChartData"
          :settings="barChartSettings"
          :extend="barChartExtend"
        />
      </div>
      <div class="first">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">7日内首检合格率趋势图</div>
        <ve-histogram
          height="300px"
          :data="firstInspectionProductionChartData"
          :settings="barChartSettings2"
          :extend="barChartExtend2"
        />
      </div>
      <div class="defects">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">7日内检验缺陷分布图</div>
        <ve-pie
          height="300px"
          :settings="pieChartSettings"
          :extend="pieChartExtend2"
          :data="defectsChartData"
        />
      </div>
    </div>
    <div class="bottom">
      <div class="process">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">过程检任务<span>（{{ processTaskData.length }}）</span></div>
        <scroll-board :config="scrollBoardConfig" :style="{width:'100%', height: tableHeight + 'px'}">
          <span slot="status" slot-scope="{ index }" class="status-board" :style="{ background: processStatus[processTaskData[index].status].color }">{{ processStatus[processTaskData[index].status].name }}</span>
        </scroll-board>
      </div>
      <div class="first">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">首检任务<span>（{{ firstTaskData.length }}）</span></div>
        <scroll-board :config="scrollBoardConfig1" :style="{width:'100%', height: tableHeight + 'px'}">
          <span slot="status" slot-scope="{ index }" class="status-board" :style="{ background: processStatus[firstTaskData[index].status].color }">{{ processStatus[firstTaskData[index].status].name }}</span>
        </scroll-board>
      </div>
      <div class="repair">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">返修待处理<span>（{{ repairTaskData.length }}）</span></div>
        <scroll-board :config="scrollBoardConfig2" :style="{width:'100%', height: tableHeight + 'px'}">
          <span slot="status" slot-scope="{ index }" class="status-board" :style="{ background: repairStatus[repairTaskData[index].status].color }">{{ repairStatus[repairTaskData[index].status].name }}</span>
        </scroll-board>
      </div>
    </div>
  </div>
</template>

<script>
import mix from '@/views/statistics-board/board-detail/components/mixin'
import api from '@/api/statistics-board/all-statistics'
import { baseUrl, colorList } from '@/views/statistics-board/board-detail/components/base-setting'
import dayjs from 'dayjs'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
const scrollBoardSettingDefault = {
  carousel: 'page',
  headerHeight: 33,
  hoverPause: false,
  waitTime: 10000,
  headerBGC: '#222222',
  oddRowBGC: '#222222',
  evenRowBGC: 'transparent'
}
export default {
  name: 'Quality',
  components: { scrollBoard },
  mixins: [mix],
  data() {
    return {
      scrollBoardSettingDefault,
      digital: {
        processInspectionQuality: 0,
        firstInspectionQuality: 0,
        returnQuality: 0,
        errQuantity7: 0,
        qualityPassQuantity7: 0
      },
      processInspectionChartData: {
        columns: ['日期', '数量'],
        rows: []
      },
      firstInspectionProductionChartData: {
        columns: ['日期', '数量'],
        rows: []
      },
      barChartSettings2: {},
      barChartExtend2: {},
      pieChartExtend2: {
        color: colorList,
        legend: {
          top: 15,
          show: true,
          orient: 'horizontal',
          right: 10,
          textStyle: {
            width: 10,
            color: '#fff',
            fontSize: 14,
            left: 20,
            overflow: 'break'
          }
        },
        series: {
          top: 0,
          radius: ['52%', '70%'],
          center: ['50%', '50%'],
          label: {
            formatter: '\n\n {b} ',
            padding: [0, -25],
            alignTo: 'labelLine',
            color: '#fff'
          },
          labelLine: {
          }
        },
        tooltip: {
          trigger: 'item'
        }
      },
      defectsChartData: {
        columns: ['缺陷项', '数量'],
        rows: []
      },
      processTaskData: [],
      firstTaskData: [],
      repairTaskData: [],
      tableHeight: 100,
      scrollBoardConfig: {
        ...scrollBoardSettingDefault,
        columnWidth: [70, 120, 130, 130, 130, 100],
        header: ['序号', '物料编码', '物料名称', '质检方案', '工序', '状态'],
        data: []
      },
      scrollBoardConfig1: {
        ...scrollBoardSettingDefault,
        columnWidth: [70, 120, 130, 130, 130, 100],
        header: ['序号', '物料编码', '物料名称', '质检方案', '工序', '状态'],
        data: []
      },
      scrollBoardConfig2: {
        ...scrollBoardSettingDefault,
        columnWidth: [70, 110, 110, 80, 80, 120, 110],
        header: ['序号', '物料编码', '物料名称', '返修', '计划', '工序', '状态'],
        data: []
      },
      processStatus: [
        {
          id: 0,
          name: '待质检',
          color: '#4B1E00',
          background: 'rgba(250, 100, 0, 0.1)'
        },
        {
          id: 1,
          name: '质检中',
          color: '#00371A',
          background: 'rgba(0, 171, 41, 0.1)'
        }
      ],
      repairStatus: [
        {
          id: 0,
          name: '待处理',
          color: '#4B1E00',
          background: 'rgba(250, 100, 0, 0.1)'
        },
        {
          id: 1,
          name: '部分处理',
          color: '#00371A',
          background: 'rgba(0, 171, 41, 0.1)'
        }
      ]
    }
  },
  beforeMount() {
    this.barChartExtend2 = this._.cloneDeep(this.barChartExtend)
    this.barChartExtend2.series.itemStyle.color.colorStops = [{
      offset: 0, color: '#50D5FF' // 0% 处的颜色
    }, {
      offset: 1, color: '#472DBD' // 100% 处的颜色
    }]
  },
  mounted() {
    this.reloadData()
  },
  methods: {
    reloadData(areaIds = '') {
      this.areaIds = areaIds
      this.getTableData(baseUrl + 'getInspectionSheetList', {
        searchVal: Encrypt(JSON.stringify([{
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: '0^1',
          operator: 'in'
        }, {
          id: getUUid(),
          fieldName: 'inspectionType',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }])),
        areaIds
      }, {
        key: 'scrollBoardConfig',
        key2: 'processTaskData',
        columns: this.scrollBoardConfig.header,
        columnsKey: ['index', 'materialCode', 'materialName', 'inspectionSchemeName', 'procedureName', 'slot:status'],
        columnWidth: this.scrollBoardConfig.columnWidth
      })
      this.getTableData(baseUrl + 'getInspectionSheetList', {
        searchVal: Encrypt(JSON.stringify([{
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: '0^1',
          operator: 'in'
        }, {
          id: getUUid(),
          fieldName: 'inspectionType',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        }])),
        areaIds
      }, {
        key: 'scrollBoardConfig1',
        key2: 'firstTaskData',
        columns: this.scrollBoardConfig1.header,
        columnsKey: ['index', 'materialCode', 'materialName', 'inspectionSchemeName', 'procedureName', 'slot:status'],
        columnWidth: this.scrollBoardConfig1.columnWidth
      })
      this.getTableData(baseUrl + 'getInspectionRepairRecordList', {
        searchVal: Encrypt(JSON.stringify([{
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: '0^1',
          operator: 'in'
        }])),
        areaIds
      }, {
        key: 'scrollBoardConfig2',
        key2: 'repairTaskData',
        columns: this.scrollBoardConfig2.header,
        columnsKey: ['index', 'materialCode', 'materialName', 'repairCount', 'planCount', 'procedureName', 'slot:status'],
        columnWidth: this.scrollBoardConfig2.columnWidth
      })
      this.getInspectionChartData('processInspectionChartData', { inspectionType: 1 })
      this.getInspectionChartData('firstInspectionProductionChartData', { inspectionType: 0 })
      this.getInspectionInfo()
      this.getDefectsChartData()
    },
    async getInspectionChartData(key, condition) {
      const res = await api.getTableData(baseUrl + 'getInspectionSheetOkRatioForDisplayBoard', {
        ...condition,
        beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD'),
        areaIds: this.areaIds
      })
      if (res) {
        this[key].rows = res.map(item => ({
          '日期': item.day.slice(-5),
          '数量': Number(item.okRatio)
        }))
      }
    },
    async getInspectionInfo() {
      const res = await api.getTableData(baseUrl + 'getInspectionSheetStatisticsForDisplayBoard', {
        beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD'),
        areaIds: this.areaIds
      })
      if (res) {
        Object.assign(this.digital, {
          processInspectionQuality: res.processInspectionCount,
          firstInspectionQuality: res.firstInspectionCount,
          returnQuality: res.repairTaskCount,
          errQuantity7: res.ngCount,
          qualityPassQuantity7: res.okRatio
        })
      }
    },
    async getDefectsChartData() {
      const res = await api.getTableData(baseUrl + 'getInspectionDefectForDisplayBoard', {
        beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD'),
        areaIds: this.areaIds
      })
      if (res) {
        this.defectsChartData.rows = res.map(item => ({
          '缺陷项': item.info,
          '数量': Number(item.quantity)
        }))
      }
    },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.tableHeight = (window.innerHeight * 0.32) / scale
    }
  }
}
</script>

<style scoped lang="scss">
@import "./common";
.quality-container {
  padding: 0 20px 20px 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  .top {
    display: flex;
    justify-content: space-between;
    >div {
      width: 338px;
      border-radius: 4px;
      .card-top {
        display: flex;
        justify-content: center;
      }
      .card-bottom {
        padding: 14px;
        background: rgba(0, 0, 0, 0.29);
        text-align: center;
        span {
          font-size: 23px;
          color: #FFFFFF;
        }
      }
      &:first-child {
        background: #1C2BAB;
      }
      &:nth-child(2) {
        background: #1A5F8F;
      }
      &:nth-child(3) {
        background: #393D60;
      }
      &:nth-child(4) {
        background: #8E1700;
      }
      &:last-child {
        background: #1A5F8F;
      }
    }
  }
  .center, .bottom {
    margin-top: 23px;
    display: flex;
    justify-content: space-between;
    >div {
      width: 600px;
    }
  }
  .bottom {
    .gradient-title {
      margin-bottom: 10px;
    }
  }
}
::v-deep .dv-scroll-board .ceil {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  >span {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.status-board {
  display: inline-block;
  width: calc(100% + 10px);
  height: 100%;
  text-align: center;
  line-height: 44px;
}
</style>
