<template>
  <div class="work-order-progress-container">
    <div class="top">
      <div>
        <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.workOrderQuality, ['#fff', '#fff'])" style="width: 425px;height: 80px" /></div>
        <div class="card-bottom">
          <span>工单总数</span>
        </div>
      </div>
      <div>
        <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.dayQuality, ['#fff', '#fff'])" style="width: 425px;height: 80px" /></div>
        <div class="card-bottom">
          <span v-if="this.memberCode === 'jinshi'">今日报工数</span>
          <span v-else>今日产量</span>
        </div>
      </div>
      <div>
        <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.delayQuality, ['#fff', '#fff'])" style="width: 425px;height: 80px" /></div>
        <div class="card-bottom">
          <span>延期工单数</span>
        </div>
      </div>
      <div>
        <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.outsourceTaskQuantity, ['#fff', '#fff'])" style="width: 425px;height: 80px" /></div>
        <div class="card-bottom">
          <span>外协任务数</span>
        </div>
      </div>
    </div>
    <div class="bottom">
      <scroll-board :config="scrollBoardConfig" :style="{width:'100%', height: tableHeight + 'px'}">
        <template slot="materialsImg" slot-scope="{ index }">
          <el-image
            v-if="tableData[index].materialsPicture"
            :src="tableData[index].materialsPicture"
            style="width: 85px"
            :preview-src-list="[tableData[index].materialsPicture]"
          />
        </template>
        <span slot="status" slot-scope="{ index }" class="status-board" :style="{ background: productionStatus[tableData[index].status].color }">{{ productionStatus[tableData[index].status].name }}</span>
        <template slot="progress" slot-scope="{ index }">
          <div>
            <div class="progress-line-wrapper" :style="{ position: 'relative', left: -getLeft(index) + 'px' }">
              <div
                v-for="(item, index2) in allProcedureList(index)"
                :key="index2"
                class="progress-wrap"
                :class="{ 'active-procedure':tableData[index].status === 1 && currentProcedure(index) === item.procedureUuid,'wait-procedure': isWaitProcedure(index, item) }"
              >
                <custom-progress type="circle" :percentage="procedurePercentage(index, item)" :color="'#99F4FF'" :stroke-w="isWaitProcedure(index, item) ? '#999' : '#2E4398'" :width="70" :show-text="false" />
                <div class="progress-text"><span>{{ item.reportWorkQuantity }}/{{ item.sourceQuantity }}</span></div>
                <div class="procedure-name ellipsis">{{ item.procedureName }}</div>
              </div>
            </div>
          </div>
        </template>
      </scroll-board>
    </div>
  </div>
</template>

<script>
import mix from './mixin'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
import { baseUrl } from '@/views/statistics-board/board-detail/components/base-setting'
import CustomProgress from '@/views/statistics-board/board-detail/components/custom-progress'
import api from '@/api/statistics-board/all-statistics'
import dayjs from 'dayjs'
const scrollBoardSettingDefault = {
  carousel: 'page',
  headerHeight: 32,
  hoverPause: false,
  waitTime: 10000,
  headerBGC: '#0F173A',
  oddRowBGC: '#0F173A',
  evenRowBGC: 'transparent',
  align: ['left', 'left', 'left', 'left', 'left', 'left', 'center']
}
export default {
  name: 'WorkOrderProgress',
  components: { CustomProgress, scrollBoard },
  mixins: [mix],
  data() {
    return {
      productionStatus: [
        {
          id: 0,
          name: '未开始',
          color: '#4B1E00',
          background: 'rgba(250, 100, 0, 0.1)'
        },
        {
          id: 1,
          name: '进行中',
          color: '#00371A',
          background: 'rgba(0, 171, 41, 0.1)'
        },
        {
          id: 2,
          name: '已完成',
          color: '#9597AE',
          background: 'rgba(149, 151, 174, 0.1)'
        }
      ],
      digital: {
        workOrderQuality: 0,
        dayQuality: 0,
        delayQuality: 0,
        outsourceTaskQuantity: 0
      },
      tableHeight: 100,
      scrollBoardConfig: {
        ...scrollBoardSettingDefault,
        columnWidth: [120, 80, 150, 150, 120, 100, 130],
        header: ['批次码', '', '产品名称', '物料规格', '工单数量', '工单状态', '交期', '工序进度'],
        data: []
      },
      scrollBoardSettingDefault,
      tableData: []
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  mounted() {
    this.reloadData()
  },
  methods: {
    allProcedureList(index) {
      if (this.tableData[index].allWorkOrderTaskList) {
        return this.tableData[index].allWorkOrderTaskList
      }
      return []
    },
    currentProcedure(index) {
      if (this.tableData[index].currentProcedure) {
        return JSON.parse(this.tableData[index].currentProcedure)[0].procedureUuid
      }
      return ''
    },
    isWaitProcedure(index, procedure) {
      return procedure && procedure.isArrival === 0
    },
    procedurePercentage(index, procedure) {
      if (this.tableData[index].allWorkOrderTaskList) {
        const { reportWorkQuantity, sourceQuantity, isDeparture } = procedure
        if (isDeparture || !reportWorkQuantity || !sourceQuantity) return 0
        return (reportWorkQuantity / sourceQuantity) * 100
      }
      return 0
    },
    getLeft(index) {
      if (this.tableData[index].status === 0) return 0
      console.log('tableData',this.tableData[index])

      if (this.tableData[index].currentProcedure && this.tableData[index].allWorkOrderTaskList) {
        const currentProcedureId = JSON.parse(this.tableData[index].currentProcedure)[0].procedureUuid
        const currentProcedureIndex = this.tableData[index].allWorkOrderTaskList.findIndex(item => item.procedureUuid === currentProcedureId)
        return currentProcedureIndex > 5 ? (currentProcedureIndex - 5) * 162 : 0
      }
      return 0
    },
    reloadData(areaIds = '') {
      this.areaIds = areaIds
      this.getTableData(baseUrl + 'getProduceWorkOrderList', { isGetAllTasks: 1, areaIds, statusList: '0^1' }, {
        repeat: true,
        key: 'scrollBoardConfig',
        key2: 'tableData',
        columns: this.scrollBoardConfig.header,
        columnsKey: ['batchNumber', 'slot:materialsImg', 'materialsName', 'materialsSpecifications', 'quantity', 'slot:status', 'deliveryTime', 'slot:progress'],
        columnWidth: [120, 80, 150, 150, 120, 100, 130]
      })
      this.workOrderQuality()
      this.dayQuality()
      this.outsourceTaskQuantity()
    },
    async workOrderQuality() {
      const res = await api.getTableData(baseUrl + 'getWorkOrderStatusForDisplayBoard', {
        areaIds: this.areaIds
      })
      if (res) {
        this.digital.workOrderQuality = res.doingCount
        if (this.memberCode === 'sitri') {
          this.digital.delayQuality = 0
        } else {
          this.digital.delayQuality = res.delayCount
        }
      }
    },
    async dayQuality() {
      const endpoint = (this.memberCode === 'jinshi') ? 'getReportCountByToday' : 'getReportCountForDisplayBoard';
      const res = await api.getTableData(baseUrl + endpoint, {
        beginTime: dayjs().format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD'),
        areaIds: this.areaIds
      })
      if (res) {
        this.digital.dayQuality = res[0].quantity
      }
    },
    async outsourceTaskQuantity() {
      const res = await api.getTableData(baseUrl + 'getWorkOrderTaskCountForDisplayBoard', {
        areaIds: this.areaIds
      })
      if (res) {
        this.digital.outsourceTaskQuantity = res.doingMap[1]
      }
    },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.tableHeight = (window.innerHeight * 0.7) / scale
    }
  }
}
</script>

<style scoped lang="scss">
@import "./common";
.work-order-progress-container {
  padding: 0 20px 20px 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  .top {
    display: flex;
    justify-content: space-between;
    >div {
      width: 425px;
      border-radius: 4px;
      .card-top {
        display: flex;
        justify-content: center;
      }
      .card-bottom {
        padding: 14px;
        background: rgba(0, 0, 0, 0.29);
        text-align: center;
        span {
          font-size: 23px;
          color: #FFFFFF;
        }
      }
      &:first-child {
        background: #1C2BAB;
      }
      &:nth-child(2) {
        background: #1A8F74;
      }
      &:nth-child(3) {
        background: #393D60;
      }
      &:last-child {
        background: #1A5F8F;
      }
    }
  }
  .bottom {
    margin-top: 23px;
    .progress-line-wrapper {
      white-space: nowrap;
      .progress-wrap {
        width: 162px;
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        ::v-deep .el-progress {
          position: relative;
          top: 1px;
        }
        .progress-text {
          position: absolute;
          top: 1px;
          left: 0;
          right: 0;
          bottom: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            white-space: nowrap;
            transform: scale(.8);
            color: #607FFF;
          }
        }
        &.active-procedure {
          border: 2px solid #607FFF;
          background: #0F2067;
          .procedure-name {
            color: #FFFFFF;
          }
          .progress-text {
            span {
              color: #FFFFFF;
            }
          }
        }
        &:not(:last-child) {
          &::after {
            content: "";
            display: inline-block;
            height: 1px;
            background: #2E4398;
            width: 88px;
            position: absolute;
            top: 39%;
            left: 118px;
          }
        }
        &.wait-procedure {
          .procedure-name {
            color: #999999 !important;
          }
          .progress-text {
            span {
              color: #999999 !important;
            }
          }
          &:not(:last-child) {
            &::after {
              content: "";
              display: inline-block;
              height: 1px;
              background: #999;
              width: 88px;
              position: absolute;
              top: 39%;
              left: 118px;
            }
          }
        }
      }
      .procedure-name {
        width: 100%;
        font-size: 14px !important;
        text-align: center;
        position: relative;
        top: 1px;
        color: #607FFF;
      }
    }
  }
}
::v-deep .dv-scroll-board .ceil {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  >span {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.status-board {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 104px;
}
</style>
