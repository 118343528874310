
import { jobTypes, dateTypes } from '@/config/options.sales'
import { formatColumns } from '@/utils'
import dayjs from 'dayjs'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'time',
    label: '日期',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 1,
      tagName: 'el-date-picker',
      colSpan: 24,
      // defaultValue: dayjs().format('yyyy-MM-dd HH:mm'),
      props: {
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd',
        format: 'yyyy-MM-dd',
        pickerOptions: {
          // disabledDate(time) {
          //   return time.getTime() < Date.now() - 8.64e7
          // }
        }
      }
    }
  },
  {
    prop: 'week',
    label: '周次',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'customerSiteIdList',
    label: '工时归属',
    sortable: false,
    hiddenSearch: true,
    slotName: 'customerSiteIdList',
    form: {
      index: 2,
      tagName: 'el-cascader',
      key: 'customerSiteIdList',
      colSpan: 24,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.mulChange(val) }
      }
    }
  },
  {
    prop: 'jobType',
    slotName: 'jobType',
    label: '工作类型',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      tagName: 'el-select',
      colSpan: 24,
      options: jobTypes,
      on: {
        change: (val) => { functionList.jobChange(val) }
      }
    }
  },
  {
    prop: 'dateType',
    label: '日期类型',
    sortable: false,
    hiddenSearch: true,
    form: { index: 3, tagName: 'el-select', colSpan: 24, options: dateTypes }
  },
  {
    prop: 'jobContent',
    label: '工作内容',
    sortable: false,
    hiddenSearch: true,
    form: { index: 4, tagName: 'el-input', colSpan: 24, props: { type: 'textarea' }}
  },
  {
    prop: 'startTime',
    label: '开始时间',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 5,
      tagName: 'el-time-picker',
      colSpan: 24,
      props: {
        type: 'time',
        valueFormat: 'HH:mm',
        format: 'HH:mm'
      },
      attrs: {
        placeholder: '选择开始时间'
      },
      on: {
        change: (val) => { functionList.valueChange('startTime', val) }
      }
    }
  },
  {
    prop: 'endTime',
    label: '结束时间',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 6,
      tagName: 'el-time-picker',
      colSpan: 24,
      props: {
        type: 'time',
        valueFormat: 'HH:mm',
        format: 'HH:mm'
      },
      attrs: {
        placeholder: '结束时间'
      },
      on: {
        change: (val) => { functionList.valueChange('endTime', val) }
      }
    }
  },
  {
    prop: 'manHour',
    label: '工时/h',
    sortable: false,
    hiddenSearch: true,
    form: { index: 7, tagName: 'div', colSpan: 24, required: false, customSlots: ['manHour'] }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { index: 8, tagName: 'el-input', colSpan: 24, required: false, props: { type: 'textarea' }}
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 150
  }
]
