var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "quality-container" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(
                  _vm.digital.processInspectionQuality,
                  ["#fff", "#fff"]
                )
              }
            })
          ],
          1
        ),
        _vm._m(0)
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(
                  _vm.digital.firstInspectionQuality,
                  ["#fff", "#fff"]
                )
              }
            })
          ],
          1
        ),
        _vm._m(1)
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.returnQuality, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        ),
        _vm._m(2)
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.errQuantity7, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        ),
        _vm._m(3)
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "card-top" },
          [
            _c("dv-digital-flop", {
              staticStyle: { width: "425px", height: "80px" },
              attrs: {
                config: _vm.getDigitalConfig(
                  _vm.digital.qualityPassQuantity7,
                  ["#fff", "#fff"],
                  "{nt}%"
                )
              }
            })
          ],
          1
        ),
        _vm._m(4)
      ])
    ]),
    _c("div", { staticClass: "center" }, [
      _c(
        "div",
        { staticClass: "process" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("7日内过程检合格率趋势图")]
          ),
          _c("ve-histogram", {
            attrs: {
              height: "300px",
              data: _vm.processInspectionChartData,
              settings: _vm.barChartSettings,
              extend: _vm.barChartExtend
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "first" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("7日内首检合格率趋势图")]
          ),
          _c("ve-histogram", {
            attrs: {
              height: "300px",
              data: _vm.firstInspectionProductionChartData,
              settings: _vm.barChartSettings2,
              extend: _vm.barChartExtend2
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "defects" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("7日内检验缺陷分布图")]
          ),
          _c("ve-pie", {
            attrs: {
              height: "300px",
              settings: _vm.pieChartSettings,
              extend: _vm.pieChartExtend2,
              data: _vm.defectsChartData
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "bottom" }, [
      _c(
        "div",
        { staticClass: "process" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [
              _vm._v("过程检任务"),
              _c("span", [
                _vm._v("（" + _vm._s(_vm.processTaskData.length) + "）")
              ])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(ref) {
                  var index = ref.index
                  return _c(
                    "span",
                    {
                      staticClass: "status-board",
                      style: {
                        background:
                          _vm.processStatus[_vm.processTaskData[index].status]
                            .color
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.processStatus[_vm.processTaskData[index].status]
                            .name
                        )
                      )
                    ]
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "first" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [
              _vm._v("首检任务"),
              _c("span", [
                _vm._v("（" + _vm._s(_vm.firstTaskData.length) + "）")
              ])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig1 },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(ref) {
                  var index = ref.index
                  return _c(
                    "span",
                    {
                      staticClass: "status-board",
                      style: {
                        background:
                          _vm.processStatus[_vm.firstTaskData[index].status]
                            .color
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.processStatus[_vm.firstTaskData[index].status]
                            .name
                        )
                      )
                    ]
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "repair" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [
              _vm._v("返修待处理"),
              _c("span", [
                _vm._v("（" + _vm._s(_vm.repairTaskData.length) + "）")
              ])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig2 },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(ref) {
                  var index = ref.index
                  return _c(
                    "span",
                    {
                      staticClass: "status-board",
                      style: {
                        background:
                          _vm.repairStatus[_vm.repairTaskData[index].status]
                            .color
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.repairStatus[_vm.repairTaskData[index].status]
                            .name
                        )
                      )
                    ]
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-bottom" }, [
      _c("span", [_vm._v("过程检任务数")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-bottom" }, [
      _c("span", [_vm._v("首检任务数")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-bottom" }, [
      _c("span", [_vm._v("返修任务数")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-bottom" }, [
      _c("span", [_vm._v("7天内新增不合格数")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-bottom" }, [
      _c("span", [_vm._v("7天内质检合格率")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }