var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "equipment-board-container" }, [
    _c("div", { staticClass: "top" }, [
      _c(
        "div",
        { staticClass: "fault-sort" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [
              _vm._v("用量分析"),
              _c("span", { staticStyle: { "font-size": "14px" } }, [
                _vm._v("(" + _vm._s(_vm.maintenanceData) + ")")
              ])
            ]
          ),
          _c("ve-histogram", {
            attrs: {
              height: "300px",
              data: _vm.maintenanceChartData,
              settings: _vm.barChartSettings,
              extend: _vm.barChartExtend
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "defects" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("存量分析（分类）")]
          ),
          _c("ve-pie", {
            attrs: {
              height: "300px",
              settings: _vm.pieChartSettings,
              extend: _vm.pieChartExtend1,
              data: _vm.defectsChartData
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "defects" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("存量分析（属性）")]
          ),
          _c("ve-pie", {
            attrs: {
              height: "300px",
              settings: _vm.pieChartSettings,
              extend: _vm.pieChartExtend2,
              data: _vm.typeChartData
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "bottom" }, [
      _c(
        "div",
        { staticClass: "inspection-task" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)",
                "margin-bottom": "10px"
              }
            },
            [
              _vm._v("库存流水"),
              _c("span", [_vm._v("（" + _vm._s(_vm.inventoryDataTotal) + "）")])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig1 },
            scopedSlots: _vm._u([
              {
                key: "type",
                fn: function(ref) {
                  var index = ref.index
                  return _c("div", {}, [
                    _vm._v(
                      _vm._s(
                        _vm.getType(
                          _vm.inventoryData[index].type,
                          _vm.inventoryData[index].recordType
                        )
                      )
                    )
                  ])
                }
              },
              {
                key: "recordType",
                fn: function(ref) {
                  var index = ref.index
                  return _c("div", {}, [
                    _vm._v(
                      _vm._s(
                        _vm.inventoryData[index].recordType ? "流出" : "流入"
                      )
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "repair-task" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg,  #7410FF 0%, rgba(96, 127, 255, 0) 100%)",
                "margin-bottom": "10px"
              }
            },
            [
              _vm._v("库存预警"),
              _c("span", [_vm._v("（" + _vm._s(_vm.inventoryAimTotal) + "）")])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig2 },
            scopedSlots: _vm._u([
              {
                key: "sumQuantity",
                fn: function(ref) {
                  var index = ref.index
                  return _c("div", {}, [
                    _vm.inventoryAimData[index].softSign
                      ? _c("i", { staticClass: "icon-upperLimit" })
                      : _c("i", { staticClass: "icon-lowerLimit" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.inventoryAimData[index].sumQuantity) +
                        " "
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }