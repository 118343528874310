var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "equipment-board-container" }, [
    _c("div", { staticClass: "top" }, [
      _c(
        "div",
        { staticClass: "inspection-finish" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v(" 日异常发生数趋势图（近7天） ")]
          ),
          _c("ve-line", {
            attrs: {
              height: "300px",
              data: _vm.inspectionFinishChartData,
              settings: _vm.lineChartSettings,
              extend: _vm.lineChartExtend
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "equipment-info" }, [
        _c(
          "div",
          { staticClass: "card-info" },
          [
            _c("img", {
              attrs: {
                src: "https://oss.mubyte.cn/static/havedeal.png",
                alt: ""
              }
            }),
            _c("dv-digital-flop", {
              staticStyle: { width: "162px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.haveQuantity, [
                  "#FFF",
                  "#FFF"
                ])
              }
            }),
            _c("div", [_vm._v("已处理")])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "card-info" },
          [
            _c("img", {
              attrs: { src: "https://oss.mubyte.cn/static/deal.png", alt: "" }
            }),
            _c("dv-digital-flop", {
              staticStyle: { width: "162px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.quantity, [
                  "#FFF",
                  "#FFF"
                ])
              }
            }),
            _c("div", [_vm._v("处理中")])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "card-info" },
          [
            _c("img", {
              attrs: {
                src: "https://oss.mubyte.cn/static/waitdeal.png",
                alt: ""
              }
            }),
            _c("dv-digital-flop", {
              staticStyle: { width: "162px" },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.waitQuantity, [
                  "#FFF",
                  "#FFF"
                ])
              }
            }),
            _c("div", [_vm._v("待处理")])
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "bj-sort" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #0E8BAF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("当月异常原因占比")]
          ),
          _c("ve-pie", {
            attrs: {
              height: "300px",
              settings: _vm.pieChartSettings2,
              extend: _vm.pieChartExtend2,
              data: _vm.errorTypeChartData
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "center" }, [
      _c(
        "div",
        { staticClass: "inspection-error" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("异常平均处理时长（近半年）")]
          ),
          _c("ve-line", {
            attrs: {
              height: "300px",
              data: _vm.inspectionErrorChartData,
              settings: _vm.lineChartSettings,
              extend: _vm.lineChartExtend
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fault-sort" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg,  #7410FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("异常原因排行（本月）")]
          ),
          _c("ve-bar", {
            attrs: {
              height: "300px",
              data: _vm.faultSortChartData,
              settings: _vm.barChartSettings,
              extend: _vm.barChartExtend3
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fault-sort" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("月度异常次数统计（近半年）")]
          ),
          _c("ve-histogram", {
            attrs: {
              height: "300px",
              data: _vm.maintenanceChartData,
              settings: _vm.barChartSettings,
              extend: _vm.barChartExtend
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "bottom" }, [
      _c(
        "div",
        { staticClass: "inspection-task" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)",
                "margin-bottom": "10px"
              }
            },
            [
              _vm._v("待处理异常"),
              _c("span", [_vm._v("（" + _vm._s(_vm.waitTaskDataTotal) + "）")])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(ref) {
                  var index = ref.index
                  return _c(
                    "span",
                    {
                      staticClass: "status-board-r",
                      style: {
                        background: _vm.waitTaskData[index]
                          ? _vm.waitTaskData[index].status === 0
                            ? "#4B1E00"
                            : _vm.waitTaskData[index].status === 1
                            ? "#015815"
                            : "#343434"
                          : ""
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.waitTaskData[index]
                              ? _vm.waitTaskData[index].status === 0
                                ? "待处理"
                                : _vm.waitTaskData[index].status === 1
                                ? "处理中"
                                : "已处理"
                              : ""
                          ) +
                          " "
                      )
                    ]
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "inspection-task" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)",
                "margin-bottom": "10px"
              }
            },
            [
              _vm._v("处理中异常"),
              _c("span", [_vm._v("（" + _vm._s(_vm.dealTaskDataTotal) + "）")])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig1 },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(ref) {
                  var index = ref.index
                  return _c(
                    "span",
                    {
                      staticClass: "status-board-r",
                      style: {
                        background: _vm.dealTaskData[index]
                          ? _vm.dealTaskData[index].status === 0
                            ? "#4B1E00"
                            : _vm.dealTaskData[index].status === 1
                            ? "#015815"
                            : "#343434"
                          : ""
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dealTaskData[index]
                              ? _vm.dealTaskData[index].status === 0
                                ? "待处理"
                                : _vm.dealTaskData[index].status === 1
                                ? "处理中"
                                : "已处理"
                              : ""
                          ) +
                          " "
                      )
                    ]
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "inspection-task" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)",
                "margin-bottom": "10px"
              }
            },
            [
              _vm._v("已处理异常"),
              _c("span", [_vm._v("（" + _vm._s(_vm.haveTaskDataTotal) + "）")])
            ]
          ),
          _c("scroll-board", {
            style: { width: "100%", height: _vm.tableHeight + "px" },
            attrs: { config: _vm.scrollBoardConfig2 },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(ref) {
                  var index = ref.index
                  return _c(
                    "span",
                    {
                      staticClass: "status-board-r",
                      style: {
                        background: _vm.haveTaskData[index]
                          ? _vm.haveTaskData[index].status === 0
                            ? "#4B1E00"
                            : _vm.haveTaskData[index].status === 1
                            ? "#015815"
                            : "#343434"
                          : ""
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.haveTaskData[index]
                              ? _vm.haveTaskData[index].status === 0
                                ? "待处理"
                                : _vm.haveTaskData[index].status === 1
                                ? "处理中"
                                : "已处理"
                              : ""
                          ) +
                          " "
                      )
                    ]
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }