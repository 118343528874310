<template>
  <VeLine height='140px' :data="chartData" :settings="lineChartSettings" :extend="lineChartExtend"/>
</template>
<script>
import api from '@/api/equipment/equipment-params'
import VeLine from 'v-charts/lib/line.common'
import dayjs from 'dayjs'
import axios from 'axios'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { getToken } from '@/utils/auth'

const token = getToken()
const headersMsg = {}
const tUrl = process.env.VUE_APP_BASE_API + 'api/web/v1/equipment/parameters/private/deviceParametersRecord/getDeviceParametersRecordTrend'
if (token) headersMsg['token'] = token

export default {
  name: 'lineComponent',
  components: {
    VeLine
  },
  props: {
    deviceId: {
      type: Number,
      default: null
    },
    deviceParametersList: {
      type: Array,
      default: []
    }
  },
  mounted() {
    this.getDataPointIdList()

  },
  watch: {
    // 监听父组件传递过来的数据
    deviceParametersList: {
      handler(newValue, oldValue) {
        this.getDataPointIdList()
      }
    }

  },
  data() {
    return {
      date: dayjs().format('YYYY-MM-DD'),

      lineChartSettings: {},
      lineChartExtend: {
        color: ['#607FFF'],
        legend: {
          show: true,
          // top:'-5%',

          textStyle: {
            color: 'white' // 设置文本颜色
            // 其他样式配置项...
          }
        },
        xAxis: { boundaryGap: false },
        yAxis: { splitNumber: 3 },
        tooltip: { trigger: 'axis' },
        textStyle: { color: '#fff' },
        grid: { width: '94%', height: '86%', top: 22 },
        series: {
          smooth: false,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#607FFF' // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(96, 127, 255, 0)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        }
      },
      chartData: {
        columns: ['时间', ''],
        rows: []
      },
      recordListData: [],
      timer: null,
      itemTimer: null

    }
  },
  methods: {
    async getDeviceParametersRecordTrend() {
      let beforeTime = dayjs().subtract(0.5, 'hour').format('YYYY-MM-DD HH:mm:ss')
      const nowTime = dayjs().format('YYYY-MM-DD HH:mm:ss')

      // if (sessionStorage.getItem('memberCode') === 'fsd') {
      //   const equIdArr = [1300, 1299, 1290, 1284]
      //   if (equIdArr.includes(this.deviceId)) {
      //     beforeTime = dayjs().format('YYYY-MM-DD') + ' 08:00:00'
      //   }
      // }

      // fsd要的
      beforeTime = dayjs().format('YYYY-MM-DD') + ' 08:00:00'

      axios.post(tUrl, {
          deviceId: this.deviceId,
          dataPointIdList: this.dataPointIdList || [],
          startTime: beforeTime,
          endTime: nowTime
        },
        { headers: headersMsg })
        .then(response => {
          if (response.data.errorCode === 0) {
            if (response.data.result) {
              this.recordListData = response.data.result
              if (this.deviceId != 1300 && this.deviceId != 1302) {
                const toAObj = {}
                this.recordListData.forEach(item => {
                  toAObj[item.parametersName] = item.recordList[item.recordList.length - 1].value || 0
                })
                this.$emit('gogogo', toAObj)
              } else {
                console.log(this.deviceId)
                const toAObj = {}
                this.recordListData.forEach(item => {
                  if(item.parametersName === '最大重量'){
                    let sbNum = 0
                    item.recordList.forEach(item2 => {
                      if(+item2.value > sbNum){
                        sbNum = item2.value
                      }
                    })
                    toAObj[item.parametersName] = sbNum || 0
                  }else {
                    let allNum = 0
                    item.recordList.forEach(item2 => {
                      allNum += +item2.value
                    })
                    toAObj[item.parametersName] = allNum || 0
                  }
                })
                this.$emit('gogogo', toAObj)
              }
              if (response.data.result.length > 0) {
                this.getVchatData()
              } else {
                this.chartData = {
                  columns: ['时间', ''],
                  rows: []
                }
              }
            }
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    getVchatData() {
      let t = 1

      // 数据处理 ————Start————
      const pName = this.recordListData[0].parametersName
      this.$set(this.chartData, 'columns', ['时间', pName])
      let pArr = this.recordListData[0].recordList.map(item => {
        const obj = { '时间': item.time.slice(10, 20) }
        obj[pName] = item.value
        return obj
      })
      this.$set(this.chartData, 'rows', pArr)

      if (this.itemTimer) {
        clearInterval(this.itemTimer)
      }
      this.itemTimer = setInterval(() => {
        if (t >= this.recordListData.length) {
          t = 0
        }

        // 数据处理 ————Start————
        const pName = this.recordListData[t].parametersName
        this.$set(this.chartData, 'columns', ['时间', pName])
        const pArr = this.recordListData[t].recordList.map(item => {
          const obj = { '时间': item.time.slice(10, 20) }
          obj[pName] = item.value
          return obj
        })
        this.$set(this.chartData, 'rows', pArr)
        // 数据处理 ————End————

        t = t + 1
      }, 10000)
      // 数据处理 ————End————

      // if (this.timer) {
      //   clearInterval(this.timer)
      // }
      // this.timer = setInterval(() => {
      //   this.getDataPointIdList()
      // }, 60000)
    },
    getDataPointIdList() {
      if (this.deviceParametersList && this.deviceParametersList.length > 0) {
        this.dataPointIdList = this.deviceParametersList.map((item => {
          return item.dataPointId
        }))
        this.getDeviceParametersRecordTrend()
      } else {
        return []
      }
    }
    // async getDataPointIdList() {
    //     this.getDeviceParametersRecord()

    // },
    // async getDeviceParametersRecord() {
    //     const res = await api.getEquipmentParamsList({
    //         searchVal: Encrypt(JSON.stringify([
    //             {
    //                 id: getUUid(),
    //                 fieldName: 'deviceId',
    //                 fieldType: 'number',
    //                 fieldValue: this.deviceId,
    //                 operator: 'eq'
    //             }
    //         ]))
    //     })
    //     if (res && res.length > 0) {
    //         this.dataPointIdList = res.map((item => {
    //             return item.dataPointId
    //         }))
    //         this.getDeviceParametersRecordTrend()

    //     } else {
    //         return []
    //     }
    // },
  }
}
</script>
<style scoped lang="scss"></style>
