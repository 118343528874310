<template>
  <div class="custom-statistics">
    <div class="top">
      <div class="left">
        <div v-if="!editStatus" class="title">{{ customStatisticsCardsInfo.title }}</div>
        <el-input v-else v-model="title" placeholder="请输入卡片名称" size="small" />
        <div v-if="customStatisticsCardsInfo.title">
          <i v-if="!editStatus" class="el-icon-edit" @click="modifyTitle" />
          <div v-else style="display: inline-flex">
            <el-button size="mini" style="margin-left: 10px" @click="editStatus = false">取消</el-button>
            <el-button size="mini" type="primary" @click="saveTitle">保存</el-button>
          </div>
        </div>
      </div>
      <el-button v-if="customStatisticsCardsInfo.title" type="primary" size="small" @click="addCard">添加报表卡片</el-button>
    </div>
    <add-statistics-card ref="addStatisticsCard" :custom-statistics-cards-info="settingInfo" :current-id="customStatisticsCardsInfo.id" @updateStatisticsCards="getCustomStatisticsCardss" />
    <div class="scroll-wrapper" style="padding: 10px;">
      <template v-for="(child, childIndex) in customStatisticsCardsInfo.children">
        <div v-if="child.name === '工单进度统计表'" :key="child.id + 200">
          精简至天：
          <el-switch v-model="toDay" @change="setToDay" />
        </div>
        <el-card :key="child.id" class="box-card">
          <div slot="header" class="box-card-header">
            <div>
              <span>{{ child.name }}</span>
              <el-tooltip v-if="child.tooltip" :content="child.tooltip" placement="top" style="padding-left: 5px">
                <span class="el-icon-question" />
              </el-tooltip>
            </div>
            <div :style="{ position: 'relative', right: '26px' }">
              <el-button type="text" @click="exportTableOrCharts(child, childIndex)"><img src="@/assets/statistics-board/下载@2x.png" alt="" width="14"></el-button>
              <el-button type="text" @click="saveCard(child, childIndex)"><img src="@/assets/statistics-board/保存@2x.png" alt="" width="14"></el-button>
              <el-button type="text" @click="delCard(child)"><img src="@/assets/statistics-board/del.png" alt="" width="13"></el-button>
            </div>
          </div>
          <div>
            <CustomTable v-if="child.type === 'table'" ref="customTable" :tab-info="child" :to-day="toDay" />
            <charts v-if="child.type === 'charts' || child.type === 'chartsAndTable'" ref="customTable" :tab-info="child" />
            <work-task-pass v-if="child.type === 'workTaskPass'" :tab-info="child" />
          </div>
        </el-card>
      </template>
    </div>
  </div>
</template>

<script>
import CustomTable from '@/views/statistics-board/board-manage/components/custom-table/custom-table'
import Charts from '@/views/statistics-board/board-manage/components/charts/charts'
import WorkTaskPass from '@/views/statistics-board/board-manage/components/work-task-pass/work-task-pass'
import baseSettingApi from '@/api/sets/base-setting'
import bus from '../../bus'
import AddStatisticsCard from '@/views/statistics-board/board-manage/components/add-statistics-card/add-statistics-card'
import { export_json_to_excel2 } from '@/utils/plug/to-excel'
export default {
  name: 'CustomStatisticsCard',
  components: { AddStatisticsCard, WorkTaskPass, Charts, CustomTable },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      customStatisticsCardsInfo: {},
      toDay: false,
      editStatus: false,
      settingInfo: {},
      title: ''
    }
  },
  watch: {
    id(val) {
      if (val) {
        this.getCustomStatisticsCards()
      }
    }
  },
  mounted() {
    this.toDay = !!localStorage.getItem('toDay')
    if (this.id) {
      this.getCustomStatisticsCards()
    }
  },
  methods: {
    getCustomStatisticsCardss() {
      this.getCustomStatisticsCards()
      this.$emit('updateStatisticsCards')
    },
    async getCustomStatisticsCards() {
      const res = await baseSettingApi.getCode({ code: 'custom_statistics_card' })
      if (res) {
        this.settingInfo = res
        this.customStatisticsCardsInfo = JSON.parse(res.value).find(item => item.id === this.id)
      }
    },
    setToDay() {
      if (this.toDay) {
        localStorage.setItem('toDay', this.toDay)
      } else {
        localStorage.removeItem('toDay')
      }
    },
    modifyTitle() {
      this.title = this.customStatisticsCardsInfo.title
      this.editStatus = true
    },
    async saveTitle() {
      if (!this.title) return this.$message.info('请输入标题')
      let data = []
      if (this.settingInfo.value) {
        data = JSON.parse(this.settingInfo.value)
        const titles = data.map(item => item.title)
        if (titles.includes(this.title)) return this.$message.info('自定义报表名称重复，请修改名称')
        data.forEach(item => {
          if (item.id === this.customStatisticsCardsInfo.id) {
            item.title = this.title
          }
        })
      }
      const res = await baseSettingApi.modifySingleSetting({
        id: this.settingInfo.id,
        code: this.settingInfo.code,
        value: JSON.stringify(data)
      })
      if (res) {
        this.editStatus = false
        this.customStatisticsCardsInfo.title = this.title
        bus.$emit('update')
        this.$message.success('修改成功')
      }
    },
    addCard() {
      this.$refs.addStatisticsCard.addCard()
    },
    delCard(data) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const card = JSON.parse(this.settingInfo.value)
        card.forEach(item => {
          if (item.id === this.customStatisticsCardsInfo.id) {
            item.children = item.children.filter(child => child.id !== data.id)
          }
        })
        const res = await baseSettingApi.modifySingleSetting({
          id: this.settingInfo.id,
          code: this.settingInfo.code,
          value: JSON.stringify(card)
        })
        if (res) {
          this.$message.success('删除成功')
          await this.getCustomStatisticsCards()
        }
      })
    },
    async saveCard(data, index) {
      if (data.searches || this.$refs.customTable[index].searchCondition) {
        const cards = JSON.parse(this.settingInfo.value)
        cards.forEach(item => {
          if (item.id === this.customStatisticsCardsInfo.id) {
            item.children.forEach(child => {
              if (child.id === data.id) {
                if (data.searches) {
                  if (child.dataCondition) {
                    Object.assign(child.dataCondition, this.$refs.customTable[index].searchConditionRef().searchCondition)
                  } else {
                    child.dataCondition = this.$refs.customTable[index].searchConditionRef().searchCondition
                  }
                  child.dataConditionOrigin = this.$refs.customTable[index].searchConditionRef().searchForm
                  const formOptionOrigin = {}
                  data.searches.forEach(c => {
                    const value = child.dataConditionOrigin[c.asKey || c.key]
                    if ((c.type === 'cascader' || c.type === 'select') && (!c.options || !c.options.length) && value && value.length) {
                      const options = this.$refs.customTable[index].searchConditionRef().formOptions[c.dataKey]
                      if (c.type === 'select') formOptionOrigin[c.dataKey] = options.filter(item => value.includes(item.id))
                      if (c.type === 'cascader') {
                        const ids = value.reduce((a, b) => a.concat(b), [])
                        formOptionOrigin[c.dataKey] = options.filter(item => ids.includes(item.id))
                      }
                    }
                  })
                  child.formOptionOrigin = formOptionOrigin
                } else {
                  const { settingColumns, newColumns } = this.$refs.customTable[index].columnSettingRefs()
                  const newColumnsKeys = newColumns.map(item => item.prop)
                  const settingColumnsKeys = settingColumns.map(item => item.prop)
                  if (child.dataCondition) {
                    Object.assign(child.dataCondition, this.$refs.customTable[index].searchCondition)
                  } else {
                    child.dataCondition = this.$refs.customTable[index].searchCondition
                  }
                  child.columns.forEach(column => {
                    column.index = settingColumnsKeys.indexOf(column.prop)
                    column.checked = newColumnsKeys.includes(column.prop)
                  })
                  child.dataConditionOrigin = this.$refs.customTable[index].selectObj
                }
              }
            })
          }
        })
        const res = await baseSettingApi.modifySingleSetting({
          id: this.settingInfo.id,
          code: this.settingInfo.code,
          value: JSON.stringify(cards)
        })
        if (res) {
          this.$message.success('保存成功')
        }
      }
    },
    exportTableOrCharts(child, childIndex) {
      if (child.type === 'table') {
        const tableDom = this.$refs.customTable[childIndex].tableRefs().tableRefs().$el
        const headerDomList = tableDom.querySelectorAll('.el-table__header-wrapper>table thead .cell>span:first-child')
        const header = Array.from(headerDomList).map(item => item.innerText)
        const bodyTrDomList = tableDom.querySelectorAll('.el-table__body-wrapper>table tbody>tr')
        const exportData = []
        Array.from(bodyTrDomList).forEach(trDom => {
          const values = Array.from(trDom.querySelectorAll('.cell')).map(item => item.outerText)
          const obj = {}
          header.forEach((item, index) => {
            obj[item] = values[index]
          })
          exportData.push(obj)
        })
        const params = {
          key: header,
          header,
          merges: [],
          data: exportData,
          filename: child.name
        }
        export_json_to_excel2(params)
      } else {
        this.$refs.customTable[childIndex].exportToPdf()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.custom-statistics {
  height: 100%;
  .top {
    padding: 20px;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .left {
      display: inline-flex;
      align-items: center;
      .title {
        font-size: 18px;
        font-weight: 700;
      }
      i {
        font-size: 18px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  ::v-deep .add-but >button {
    display: none;
  }
  .scroll-wrapper {
    height: calc(100% - 72px);
    overflow-y: auto;
  }
  .box-card {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    &:not(:first-child) {
      margin-top: 15px;
    }
    ::v-deep .el-card__header {
      background: #F6F7F9;
      padding: 0 18px;
      .box-card-header {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    ::v-deep .el-card__body {
      padding: 20px 18px;
    }
  }
}
</style>
