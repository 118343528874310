import { status } from '@/config/options.config'
import { formatColumns } from '@/utils'

export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'memberName',
    label: '名称',
    sortable: false,
    hiddenSearch: true,
    width: 150,
    slotName: 'memberName',
    form: { tagName: 'el-input' }
  },
  {
    prop: 'memberCode',
    label: '工厂代码',
    sortable: false,
    hiddenSearch: true,
    width: 150,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'url',
    label: 'URL',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', props: { type: 'textarea', row: 6, autosize: { minRows: 6, maxRows: 10 }}}
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]
export const userColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {

    prop: 'jobNumber',
    label: '工号',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 0, tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: '姓名',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 1, tagName: 'el-input' }
  },
  {
    prop: 'userName',
    label: '用户名',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 6, tagName: 'el-input' }
  },
  {
    prop: 'phone',
    label: '手机号码',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 3, tagName: 'el-input' }
  },
  {
    prop: 'organizationName',
    label: '组织',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 2, key: 'organizationId', tagName: 'el-select', options: [] }
  },
  {
    prop: 'email',
    label: '邮箱',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 4, tagName: 'el-input', required: false }
  },
  {
    prop: 'isSystemUser',
    label: '是否系统用户',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isSystemUser',
    width: 120,
    form: {
      index: 5,
      colSpan: 24,
      tagName: 'el-switch',
      defaultValue: 1,
      props: {
        activeText: '是',
        inactiveText: '否',
        activeValue: 1,
        inactiveValue: 0
      },
      on: { change: (val) => { functionList.paramsTypeChange(val) } }
    }
  }
]

