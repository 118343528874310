var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-wrappers" }, [
    _c("div", { staticClass: "detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _vm.isView
              ? _c(
                  "el-row",
                  { staticClass: "view-list", attrs: { gutter: 24 } },
                  _vm._l(_vm.formList, function(item) {
                    return _c(
                      "el-col",
                      { key: item.id, attrs: { span: item.colSpan } },
                      [
                        _c("div", { staticClass: "view-list__label" }, [
                          _vm._v(_vm._s(item.label))
                        ]),
                        item.key !== "attachments"
                          ? _c("div", { staticClass: "view-list__value" }, [
                              _vm._v(_vm._s(_vm.getValue(item) || "-"))
                            ])
                          : _c(
                              "el-upload",
                              _vm._b(
                                {
                                  class: { "upload-view": _vm.isView },
                                  attrs: {
                                    "file-list": _vm.attachments,
                                    disabled: ""
                                  }
                                },
                                "el-upload",
                                _vm.uploadProps2,
                                false
                              )
                            )
                      ],
                      1
                    )
                  }),
                  1
                )
              : _c(
                  "MFormBuilder",
                  {
                    ref: "baseForm",
                    attrs: {
                      "form-list": _vm.formList,
                      "form-data": _vm.baseFormData,
                      "label-position": "right"
                    }
                  },
                  [
                    _c(
                      "el-upload",
                      _vm._b(
                        {
                          attrs: {
                            slot: "drawing",
                            "file-list": _vm.attachments
                          },
                          slot: "drawing"
                        },
                        "el-upload",
                        _vm.uploadProps2,
                        false
                      ),
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", size: "small" } },
                          [_vm._v("上传文件")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("关联设备")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("relationEquipment", {
              attrs: {
                type: _vm.isView ? "" : "new",
                "customer-id": _vm.customerId,
                list: _vm.facilityList
              },
              on: { facilityInfo: _vm.facilityInfo }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "order-footer" }, [
      _c("div", { staticClass: "footer-le" }),
      _c(
        "div",
        { staticClass: "footer-ri" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.returns } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.buttonLoading,
                size: "small"
              },
              on: { click: _vm.submitForm }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }