<template>
  <div class="base-setting">
    <div v-for="(item, index) in data" :key="item.id" class="common-card">
      <div v-if="index === 0 && !isSign" class="card-tit">
        <div class="card-le">系统版本号：{{ version }}</div>
      </div>
      <div v-if="index === 0 && isSign" class="card-tit">
        <div class="card-le">{{ item.childrenList[1].name }}：{{ item.childrenList[1].value }}</div>
      </div>
      <div v-if="index > 0" class="card-tit">
        <div class="card-le">{{ item.name }}</div>
        <div v-if="!isEdit[index]" class="card-ri">
          <span
            v-if="item.name === '开票信息'"
            v-clipboard:copy="fixCopy(item.childrenList)"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            复制
          </span>
          <el-divider v-if="item.isReadOnly === 0 && item.name === '开票信息'" direction="vertical" />
          <span v-if="item.isReadOnly === 0" @click="edit(index)">编辑</span>
        </div>
      </div>
      <div v-if="index === 0" class="card-bot">
        <div v-if="!isSign" class="bot-tit">{{ item.childrenList[1].name }}：{{ item.childrenList[1].value }}/{{ item.childrenList[0].value }}</div>
        <div v-if="isSign" class="bot-tit">{{ item.childrenList[2].name }}：{{ item.childrenList[2].value }}/{{ item.childrenList[0].value }}</div>
      </div>
      <div v-if="index > 0 && !isEdit[index]" class="card-bot">
        <div v-for="items in item.childrenList" :key="items.id" class="bot-tit">
          {{ items.name }}：
          <template v-if="items.type !== 4">
            <img v-if="items.type === 1" :src="items.value" class="avatars">
            <span v-else>{{ items.value }}</span>
          </template>
          <template v-else>
            <el-popover ref="popover1" popper-class="popover-class" placement="top" title="扫码下载" trigger="hover">
              <VueQr :correct-level="3" :auto-color="false" color-dark="#000000" :text="items.value" :size="100" :margin="0" />
            </el-popover>

            <el-button v-popover:popover1 type="text">
              <a v-if="items.value" :href="items.value" download target="_blank" style="color: #5878fc">下载</a>
            </el-button>
          </template>
        </div>
      </div>
      <div v-if="index > 0 && isEdit[index]" class="card-bot">
        <div v-for="(items, childIndex) in item.childrenList" :key="items.id" class="bot-edit">
          <div class="edtit-le">{{ items.name }}：</div>
          <div class="edtit-ri">
            <div v-if="items.type === 5">{{ items.value }}</div>
            <!-- 文本 -->
            <el-input v-if="items.type === 0" v-model="items.value" placeholder="请输入" class="w250" />
            <!-- 数字 -->
            <el-input-number v-if="items.type === 2" v-model="items.value" :min="1" controls-position="right" :step="1" class="w250" />
            <!-- 选择 -->
            <el-select v-if="items.type === 3" v-model="items.value" placeholder="请选择" class="w250">
              <el-option v-for="iten in fixData(items.possibleValues)" :key="iten" :label="iten" :value="iten" />
            </el-select>
            <!-- 图片 -->
            <el-upload v-if="items.type === 1" class="avatar-uploader" v-bind="uploadProps">
              <img v-if="items.value" :src="items.value" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon" />
              <div class="test">
                <i class="el-icon-edit" />
              </div>
            </el-upload>
            <!--apk上传-->
            <el-upload
              v-if="items.type === 4"
              class="file-upload"
              :before-upload="file => beforeApkUpload(file, index)"
              :on-success="(res, file) => apkUploadChange(res, file, index, childIndex)"
              :on-error="(res, file) => apkUploadChange(res, file, index, childIndex)"
              v-bind="uploadFileProps"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传apk文件，且文件名必须与版本号一致</div>
            </el-upload>
          </div>
          <div v-if="item.name === '基本信息'" class="edit-radio">
            <!-- <el-radio-group v-model="items.isShow">
              <el-radio :label="1">显示</el-radio>
              <el-radio :label="0">不显示</el-radio>
            </el-radio-group> -->
          </div>
        </div>
        <div v-if="item.name === '基本信息'" class="tips">上传图片建议尺寸：120 像素 * 48 像素，大小不超过500K，格式：PNG（透明底）</div>
        <div class="edit-footer">
          <el-button @click="close(index)">取 消</el-button>
          <el-button type="primary" :loading="loading" @click="onOk(index)">确 定</el-button>
        </div>
      </div>
    </div>
    <div v-for="item in versionData" v-if="!isSign" :key="item.id" class="common-card">
      <div class="card-tit">
        <div class="card-le">{{ item.name }}</div>
      </div>
      <div class="card-bot">
        <div v-for="items in item.childrenList" :key="items.id" class="bot-tit">
          {{ items.name }}：
          <template v-if="items.type !== 4">
            <img v-if="items.type === 1" :src="items.value" class="avatars">
            <span v-else>{{ items.value }}</span>
          </template>
          <template v-else>
            <el-popover ref="popover1" popper-class="popover-class" placement="top" title="扫码下载" trigger="hover">
              <VueQr :correct-level="3" :auto-color="false" color-dark="#000000" :text="items.value" :size="100" :margin="0" />
            </el-popover>

            <el-button v-popover:popover1 type="text">
              <a v-if="items.value" :href="items.value" download target="_blank" style="color: #5878fc">下载</a>
            </el-button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api/sets/base-setting'
  import VueQr from 'vue-qr'
  import { Encrypt } from '@/utils/sercet'
  import { getUUid } from '@/utils'
  import dayjs from 'dayjs'
  import { getToken } from '@/utils/auth'

  export default {
    name: 'BaseSetting',
    components: { VueQr },
    data() {
      return {
        versionData: [],
        version: '',
        uploadProps: {
          action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
          headers: { token: getToken() },
          showFileList: false,
          accept: '.png',
          onSuccess: this.fileUploadChange,
          onError: this.fileUploadChange,
          beforeUpload: this.beforeAvatarUpload
        },
        uploadFileProps: {
          action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
          headers: { token: getToken() },
          limit: 1,
          accept: '.apk'
        },
        formData: {},
        data: [],
        isEdit: [],
        loading: false,
        imageUrl: '',
        copyVal: '',
        currentIndex: 0,
        isSign: false
      }
    },
    mounted() {
      this.getList()
      this.getVersion()
      this.getVersionConfig()
    },
    methods: {
      // 获取版本管理
      async getVersion() {
        const res = await api.getAllSetting()
        if (res) {
          const data = res.length > 0 ? res.filter(item => item.code === 'app_version_setting') : []
          this.versionData = data
        }
      },
      // 获取版本号
      async getVersionConfig() {
        const res = await api.getVersion('version')
        if (res) {
          this.version = res.value ? res.value : ''
        }
      },
      fixCopy(val) {
        if (val) {
          let str = ''
          val.map(item => {
            str += `${item.name}：${item.value} \n`
          })
          return str
        } else {
          return ''
        }
      },
      fixData(val) {
        if (val) {
          return val.split(',')
        } else {
          return []
        }
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/png'
        const isLt2M = file.size / 1024 / 1024 < 20
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 png 格式!')
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 20MB!')
        }
        return isJPG && isLt2M
      },
      apkUploadChange(res, file, index, childIndex) {
        if (res.errorCode === 0) {
          this.data[index].childrenList[childIndex].value = res.result
          if (this.data[index].childrenList[childIndex].code === 'app_version_upload') {
            const timeIndex = this.data[index].childrenList.findIndex(item => item.code === 'app_upload_time')
            this.data[index].childrenList[timeIndex].value = dayjs().format('YYYY-MM-DD HH:mm:ss')
          }
        } else {
          this.$message.error(res.result)
        }
      },
      beforeApkUpload(file, index) {
        const isLt20M = file.size / 1024 / 1024 < 20
        if (!isLt20M) {
          this.$message.error('上传文件大小不能超过 20M!')
          return false
        }
        const fileName = file.name.split('.apk')[0]
        const version = this.data[index].childrenList[0].value
        if (!version) {
          this.$message.error('请先填写版本号')
          return false
        }
        if (fileName !== version) {
          this.$message.error('版本号必须与文件名保持一致')
          return false
        }
        return true
      },
      fileUploadChange(res, file) {
        if (res.errorCode === 0) {
          // this.$message.success('上传成功')
          this.data[1].childrenList[1].value = res.result
        } else {
          this.$message.error(res.result)
        }
      },
      async getList() {
        const res = await api.getList()
        if (res) {
          this.data = res
          this.getDetails()
        }
      },
      async fixList(obj) {
        const res = await api.modifySetting(obj.childrenList)
        if (res) {
          this.$message.success('修改成功')
          this.getList()
          this.isEdit = []
        }
      },
      edit(idx) {
        this.$set(this.isEdit, idx, true)
      },
      close(idx) {
        this.getList()
        this.$set(this.isEdit, idx, false)
      },
      onOk(idx) {
        this.fixList(this.data[idx])
      },
      onCopy: function(e) {
        this.$message.success('复制成功')
      },
      onError: function(e) {
        this.$message.error('复制失败')
      },
      // 获取名称和logo
      async getDetails() {
        const search = {
          searchVal: Encrypt(
            JSON.stringify([
              {
                id: getUUid(),
                fieldName: 'code',
                fieldType: 'string',
                fieldValue: 'companyName^companyLogo',
                operator: 'in'
              }
            ])
          )
        }
        const res = await api.getDetail(search)
        if (res) {
          const info = {
            logo: res[0].value,
            name: res[1].isShow === 1 ? res[1].value : ''
          }
          this.$store.dispatch('user/companyInfo', info)
        }
      }
    }
  }
</script>

<style lang="scss">
  .popover-class {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
</style>
<style scoped lang="scss">
  .base-setting {
    ::v-deep {
      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
      .el-upload {
        display: block;
      }
    }
    .w250 {
      width: 250px;
    }
    .common-img {
      position: relative;
      width: 140px;
      height: 40px;
    }
    .common-card {
      background: #ffffff;
      border-radius: 4px;
      margin-bottom: 20px;
      .card-tit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 22px;
        border-bottom: 1px solid #dde3eb;
        height: 42px;
        .card-le {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #393d60;
        }
        .card-ri {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #607fff;
          cursor: pointer;
        }
      }
      .card-bot {
        padding: 20px;
        .bot-tit {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4a4f76;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 17px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .avatar {
          width: 125px;
          // height: 45px;
          margin: 0 auto;
          display: block;
        }
        .avatars {
          width: 125px;
          // height: 45px;
          display: block;
        }
        .bot-edit {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 20px;
          .edtit-le {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4a4f76;
            line-height: 20px;
            width: 100px;
            text-align: right;
          }
          .edtit-ri {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4a4f76;
            line-height: 20px;
            text-align: right;
          }
          .avatar-uploader {
            width: 140px;
            height: 50px;
            border: 1px solid #dde3eb;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover .test {
              display: block;
            }
            .test {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              cursor: pointer;
              text-align: center;
              color: #fff;
              font-size: 20px;
              background-color: rgba(0, 0, 0, 0.5);
              transition: opacity 0.3s;
              display: none;
              i {
                line-height: 40px;
              }
            }
          }
          .edit-radio {
            margin-left: 30px;
          }
        }
        .tips {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9597ae;
          line-height: 17px;
          margin-left: 100px;
        }
        .edit-footer {
          display: flex;
          justify-content: flex-end;
          height: 56px;
          width: 100%;
          padding: 10px 20px;
          bottom: 0;
          background: #ffffff;
          button {
            padding: 10px 30px !important;
            &:first-child {
              background: #f8f8fb;
              color: #8a8ca5;
              transition: all 0.5s;

              &:hover {
                border-color: #dadaf6;
                background: #f0f0f8;
              }
            }

            &:last-child {
              background: $primary-color;
              color: #ffffff;
              transition: all 0.5s;

              &:hover {
                background: #7691fc;
              }
            }
          }
        }
      }
    }
  }
  ::v-deep {
    .file-upload {
      .el-upload {
        text-align: left;
      }
    }
  }
</style>
