let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const columns = [
  {
    prop: 'workOrderCode',
    label: '工单编号',
    hiddenSearch: true,
    form: {
      index: 1,
      required: true,
      tagName: 'el-input',
      colSpan: 6
    }
  },
  {
    prop: 'customerSiteIdList',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true,
    slotName: 'customerSiteIdList',
    width: 180,
    form: {
      index: 7, tagName: 'el-cascader',
      key: 'customerSiteIdList',
      colSpan: 8,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.serviceChange(val) }
      }
    }
  }
]

export const shipColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'facilityCode',
    label: '设备编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'facilityName',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'SN',
    label: 'SN',
    sortable: false,
    hiddenSearch: true,
    slotName: 'SN'
  },
  {
    prop: 'model',
    label: '型号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentClassName',
    label: '分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'customerName',
    label: '客户',
    sortable: false,
    hiddenSearch: true
  }
]

export const projectColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'content',
    label: '项目内容',
    sortable: false,
    hiddenSearch: true
  }
]
