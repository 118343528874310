<template>
  <MDialog v-model="scanVisible" :append-to-body="true" title="派工" width="500px" class="extra-froms" @onOk="submitFormDel">
    <MFormBuilder ref="baseForms" :form-list="userFormList" :form-data="userFormData" label-position="right" />
  </MDialog>
</template>

<script>
import { userFormList } from '../form-list'
import uApi from '@/api/sets/user/user'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import api from '@/api/xiewei/service/demand-center'

export default {
  name: 'DispatchUser',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    baseInfo: {
      type: Object,
      default: () => ({})
    },
    dispatchType: {
      type: String,
      default: 'list'
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      userFormList,
      userFormData: {},
      scanVisible: false,
      userList: []
    }
  },
  watch: {
    visible(val) {
      this.scanVisible = val
    },
    scanVisible(val) {
      if (!val) {
        this.$emit('update:visible', false)
      }
    }
  },
  mounted() {
    this.scanVisible = this.visible
    this.getUser()
  },
  methods: {
    // 获取系统用户
    async getUser() {
      const res = await uApi.getUserList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            },
            {
              id: getUUid(),
              fieldName: 'isSystemUser',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.userList = res
        this.userFormList[0].children[0].options = res
      }
    },
    async submitFormDel(callback) {
      const { form } = this.$refs.baseForms
      this.$refs.baseForms.formRefs().validate(async(valid) => {
        if (valid) {
          if (this.dispatchType === 'list') {
            const res = await api[`addFacilityDemandDispatch`](
              this.createSetData(form)
            )
            if (res) {
              this.$message.success('派工成功')
              this.$emit('refrash')
            }
            callback(true)
          } else {
            const res = await api[`addFacilityDemandAndAssignWork`](
              {
                ...this.createSetData(form),
                ...this.info
              }
            )
            if (res) {
              this.$message.success('派工成功')
              this.$emit('refrash')
            }
            callback(true)
          }
        } else {
          callback()
        }
      })
    },
    createSetData(raw) {
      // 处理负责人id和负责任名称 ————————Start————————
      let principalId = '', principalName = '',principalNameArr = []
      if (raw.principalId && raw.principalId.length) {
        raw.principalId.forEach(itemId => {
          if (itemId) {
            principalNameArr.push(this.userList.filter(item => item.id === itemId)[0].name)
          }
        })
        principalId = raw.principalId.join('^')
        principalName = principalNameArr.join('、')
      }
      // 处理负责人id和负责任名称 ————————End————————
      if (this.dispatchType === 'list') {
        return {
          facilityDemandCode: this.baseInfo.code,
          facilityDemandId: this.baseInfo.id,
          principalId,
          principalName,
          planStartTime: raw.planDate ? raw.planDate[0] : '',
          planEndTime: raw.planDate ? raw.planDate[1] : '',
          remark: raw.remark,
          isOnBusiness: raw.isOnBusiness
        }
      }
      return {
        facilityDemandCode: this.baseInfo.code,
        facilityDemandId: this.baseInfo.id,
        principalId,
        principalName,
        planStartTime: raw.planDate ? raw.planDate[0] : '',
        planEndTime: raw.planDate ? raw.planDate[1] : '',
        assignWorkRemark: raw.remark,
        isOnBusiness: raw.isOnBusiness
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 .extra-froms ::v-deep {
  .el-form-item {
    margin-bottom: 18px!important;
  }
}

</style>
