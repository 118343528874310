import { paramsType } from '@/config/options.sales'
import { formatColumns } from '@/utils'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const User = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'type',
    label: '分类',
    hiddenSearch: true,
    sortable: false,
    slotName: 'type'
  },
  {
    prop: 'filedName',
    label: '字段名称',
    hiddenSearch: true,
    sortable: false
  },
  // {
  //   prop: 'filedShowName',
  //   label: '显示名称',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'filedShowName'
  // },
  {
    prop: 'filedType',
    label: '字段类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'filedType'
  },
  {
    prop: 'filedProperty',
    label: '字段属性',
    hiddenSearch: true,
    sortable: false,
    slotName: 'filedProperty'
  },
  {
    prop: 'defaultValue',
    label: '默认值',
    hiddenSearch: true,
    sortable: false,
    slotName: 'defaultValue',
    width: 180
  },
  {
    prop: 'required',
    label: '是否必填',
    sortable: false,
    hiddenSearch: true,
    slotName: 'required'
  },
  {
    prop: 'search',
    label: '搜索功能',
    hiddenSearch: true,
    sortable: false,
    slotName: 'search'
  },
  {
    prop: 'isEnabled',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled'
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]
export const formListColumns = [
  {
    prop: 'filedName',
    label: '字段名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', colSpan: 24 }
  },
  // {
  //   prop: 'filedShowName',
  //   label: '显示名称',
  //   sortable: false,
  //   hiddenSearch: true,
  //   form: { tagName: 'el-input', required: false, colSpan: 24, placeholder: '不填写则默认为字段名称' }
  // },
  {
    prop: 'filedType',
    label: '字段类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'filedType',
    form: { defaultValue: 0, tagName: 'el-select', colSpan: 24, props: { clearable: false, disabled: false }, options: paramsType, on: { change: (val) => { functionList.paramsTypeChange(val) } }}
  },
  {
    prop: 'filedProperty',
    label: '选项设置',
    form: {
      hidden: true,
      key: 'filedProperty',
      tagName: 'div',
      required: true,
      colSpan: 24,
      customSlots: ['filedProperty']
    }
  },
  {
    prop: 'numberRange',
    label: '数值范围',
    form: {
      hidden: true,
      key: 'numberRange',
      tagName: 'div',
      required: false,
      colSpan: 24,
      customSlots: ['numberRange']
    }
  },
  {
    prop: 'defaultValue',
    label: '默认值',
    form: { hidden: true, tagName: 'el-input', required: false, colSpan: 24 }
  },
  {
    prop: 'required',
    label: '是否必填',
    sortable: false,
    hiddenSearch: true,
    width: 120,
    form: {
      required: true,
      colSpan: 24,
      tagName: 'el-switch',
      defaultValue: 1,
      props: {
        activeText: '是',
        inactiveText: '否',
        activeValue: 1,
        inactiveValue: 0
      }
    }
  },
  {
    prop: 'search',
    label: '搜索功能',
    sortable: false,
    hiddenSearch: true,
    width: 120,
    form: {
      required: true,
      colSpan: 24,
      tagName: 'el-switch',
      defaultValue: 1,
      props: {
        activeText: '开启',
        inactiveText: '关闭',
        activeValue: 1,
        inactiveValue: 0
      }
    }
  },
  {
    prop: 'isEnabled',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    width: 120,
    form: {
      required: true,
      colSpan: 24,
      tagName: 'el-switch',
      defaultValue: 1,
      props: {
        activeText: '启用',
        inactiveText: '停用',
        activeValue: 1,
        inactiveValue: 0
      }
    }
  }
]

