let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const columns = [
  {
    prop: 'facilityDemandCode',
    label: '需求编号',
    sortable: false,
    hiddenSearch: true,
    form: { index: 1, tagName: 'div', colSpan: 6, customSlots: ['facilityDemandCode'], required: false }
  },
  {
    prop: 'startPoint',
    label: '出发地',
    sortable: false,
    hiddenSearch: true,
    form: { index: 2, tagName: 'el-input', colSpan: 6 }
  },
  {
    prop: 'endPoint',
    label: '目的地',
    sortable: false,
    hiddenSearch: true,
    form: { index: 3, tagName: 'el-input', colSpan: 6 }
  },
  {
    prop: 'vehicle',
    label: '交通工具',
    sortable: false,
    hiddenSearch: true,
    form: { index: 4, tagName: 'el-input', colSpan: 6 }
  },
  {
    prop: 'startTime',
    label: '出发时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'startTime',
    width: 280,
    form: {
      index: 5,
      tagName: 'el-date-picker',
      colSpan: 6,
      props: {
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'arrivalTime',
    label: '预计到达时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'arrivalTime',
    width: 280,
    form: {
      index: 6,
      tagName: 'el-date-picker',
      colSpan: 6,
      required: false,
      props: {
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'togetherUserName',
    label: '同行人',
    sortable: false,
    hiddenSearch: true,
    form: { index: 7, required: false, tagName: 'el-input', colSpan: 12 }
  }
]

export const descColumns = [
  {
    prop: 'carFare',
    label: '交通费/元',
    sortable: true,
    hiddenSearch: true,
    form: {
      index: 1,
      required: false,
      tagName: 'el-input-number',
      colSpan: 6,
      props: {
        controlsPosition: 'right',
        min: 0,
        precision: 2,
        placeholder: '请输入交通费'
      },
      on: {
        change: () => {
          functionList.valueChange()
        }
      }
    }
  },
  {
    prop: 'hotelFare',
    label: '住宿费/元',
    sortable: true,
    hiddenSearch: true,
    form: {
      index: 2,
      required: false,
      tagName: 'el-input-number',
      colSpan: 6,
      props: {
        controlsPosition: 'right',
        min: 0,
        precision: 2,
        placeholder: '请输入住宿费'
      },
      on: {
        change: () => {
          functionList.valueChange()
        }
      }
    }
  },
  {
    prop: 'otherFare',
    label: '其他/元',
    sortable: true,
    hiddenSearch: true,
    form: {
      index: 3,
      required: false,
      tagName: 'el-input-number',
      colSpan: 6,
      props: {
        controlsPosition: 'right',
        min: 0,
        precision: 2,
        placeholder: '请输入其他费用'
      },
      on: {
        change: () => {
          functionList.valueChange()
        }
      }
    }
  },
  {
    prop: 'fareRemark',
    label: '费用说明',
    sortable: true,
    hiddenSearch: true,
    form: { index: 4, required: false, tagName: 'el-input', colSpan: 6 }
  },
  {
    prop: 'totalFare',
    label: '合计/元',
    sortable: true,
    hiddenSearch: true,
    form: { index: 5, required: false, tagName: 'div', customSlots: ['totalFare'], colSpan: 24 }
  }
]

export const travelColumns = [
  {
    prop: 'remark',
    label: '出差说明',
    sortable: true,
    hiddenSearch: true,
    form: {
      required: false,
      tagName: 'el-input',
      colSpan: 24,
      props: {
        type: 'textarea'
      },
      attrs: { placeholder: '不超过500字', maxlength: 500 }
    }
  }
]

export const modifyColumns = [
  {
    prop: 'updateRemark',
    label: '修改意见',
    sortable: true,
    hiddenSearch: true,
    form: {
      required: false,
      tagName: 'el-input',
      colSpan: 24,
      props: {
        type: 'textarea'
      },
      attrs: { placeholder: '不超过500字', maxlength: 500 }
    }
  }
]
