import { maintenanceLevel, reformType } from '@/config/options.sales'
import {nameChangeForBB} from '@/utils/index.js'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  {
    prop: 'workOrderCode',
    label: '工单编号',
    sortable: false,
    hiddenSearch: true,
    form: { index: 1, required: false, tagName: 'el-input', colSpan: 6,
      on: {
        change: (val) => { functionList.codeChange(val) }
      }
    }
  },
  {
    prop: 'demandCode',
    label: '关联需求',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 2,
      tagName: 'el-select',
      required: false,
      key: 'demandId',
      options: [],
      colSpan: 6,
      props: {}
    }
  },
  {
    prop: 'workOrderType',
    label: '工单类型',
    sortable: false,
    hiddenSearch: true,
    form: { index: 3, tagName: 'div', customSlots: ['workOrderType'], colSpan: 6 }
  },
  {
    prop: 'customerSiteIdList',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      index: 4,
      tagName: 'el-cascader',
      key: 'customerSiteIdList',
      colSpan: 6,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.mulChange(val) }
      }
    }
  },
  // {
  //   prop: 'consumer',
  //   label: '客户',
  //   hiddenSearch: true,
  //   sortable: false,
  //   minWidth: 180,
  //   form: {
  //     index: 4,
  //     tagName: 'el-select',
  //     key: 'consumerId',
  //     options: [],
  //     colSpan: 6,
  //     props: {},
  //     on: {
  //       change: () => {
  //         functionList.valueChange()
  //       }
  //     }
  //   }
  // },
  {
    prop: 'facilityName',
    label: nameChangeForBB() ? '出厂编号' : '设备',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 5,
      colSpan: 6,
      tagName: 'el-select',
      key: 'facilityId',
      props: {}
    }
  },
  {
    prop: 'level',
    label: '保养级别',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 5,
      colSpan: 6,
      tagName: 'el-select',
      key: 'level',
      options: maintenanceLevel
    }
  },
  {
    prop: 'faultCode',
    label: nameChangeForBB() ? '故障现象' :  '故障码',
    sortable: false,
    hiddenSearch: true,
    form: { index: 6, tagName: 'el-input', colSpan: 6 }
  },
  {
    prop: 'faultTime',
    label: '故障时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'faultTime',
    width: 280,
    form: {
      index: 7, tagName: 'el-date-picker', colSpan: 6,
      props: {
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'reformType',
    label: '改造类型',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 8,
      colSpan: 6,
      tagName: 'el-select',
      key: 'reformType',
      options: reformType
    }
  },
  {
    prop: 'equipmentId',
    label: '客户机台',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    form: {
      index: 9,
      required: false,
      colSpan: 6,
      tagName: 'el-select',
      key: 'equipmentId',
      props: {},
      on: {
        change: (val) => { functionList.equChange(val) }
      }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { index: 10, required: false,key: 'remark', tagName: 'el-input', colSpan: 6,disabled:true,
      on: {
        change: (val) => { functionList.remarkChange(val) }
      }
    }
  },
]

export const descColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'faultTime',
    label: '故障时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'faultTime',
    width: 250
  },
  {
    prop: 'faultCode',
    label: '故障码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'faultCode',
    width: 250
  },
  {
    prop: 'detailedDeion',
    label: '详细描述',
    sortable: false,
    hiddenSearch: true,
    slotName: 'detailedDeion'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80
  }
]

export const marterialColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsType',
    label: '物料分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    slotName: 'materialsType'
  },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 220,
    slotName: 'materialsSpecifications'
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'batchNumber',
    label: '批次',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200,
    slotName: 'batchNumber'
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200,
    slotName: 'sequenceNumber'
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    slotName: 'warehouseName'
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 240,
    slotName: 'warehouseLocationName'
  },
  {
    prop: 'quantity',
    label: '可用库存',
    slotName: 'quantity',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'usageQuantity',
    label: '使用数量',
    slotName: 'usageQuantity',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'ownershipSparePartsAssets',
    label: '备件资产所属',
    slotName: 'ownershipSparePartsAssets',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80
  }
]
