var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "work-order-progress-container" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", [
        _c("div", { staticClass: "card-top card-top1" }, [
          _vm._v(" 联网设备 ")
        ]),
        _c(
          "div",
          { staticClass: "card-bottom card-bottom1" },
          [
            _c("dv-digital-flop", {
              staticStyle: {
                "font-size": "50px",
                "margin-top": "20px",
                height: "80px"
              },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.haveEquip, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        )
      ]),
      _c("div", [
        _c("div", { staticClass: "card-top card-top2" }, [_vm._v(" 生产中 ")]),
        _c(
          "div",
          { staticClass: "card-bottom card-bottom2" },
          [
            _c("dv-digital-flop", {
              staticStyle: {
                "font-size": "50px",
                "margin-top": "20px",
                height: "80px"
              },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.ingEquip, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        )
      ]),
      _c("div", [
        _c("div", { staticClass: "card-top card-top3" }, [_vm._v(" 待机中 ")]),
        _c(
          "div",
          { staticClass: "card-bottom card-bottom3" },
          [
            _c("dv-digital-flop", {
              staticStyle: {
                "font-size": "50px",
                "margin-top": "20px",
                height: "80px"
              },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.waitEquip, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        )
      ]),
      _c("div", [
        _c("div", { staticClass: "card-top card-top4" }, [_vm._v(" 故障中 ")]),
        _c(
          "div",
          { staticClass: "card-bottom card-bottom4" },
          [
            _c("dv-digital-flop", {
              staticStyle: {
                "font-size": "50px",
                "margin-top": "20px",
                height: "80px"
              },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.breakEquip, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        )
      ]),
      _c("div", [
        _c("div", { staticClass: "card-top card-top5" }, [_vm._v(" 已停机 ")]),
        _c(
          "div",
          { staticClass: "card-bottom card-bottom5" },
          [
            _c("dv-digital-flop", {
              staticStyle: {
                "font-size": "50px",
                "margin-top": "20px",
                height: "80px"
              },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.stopEquip, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "bottom" },
      _vm._l(_vm.equipData, function(item) {
        return _c("div", { key: item.id, staticClass: "allBorder" }, [
          _c("div", { staticStyle: { background: "#000" } }, [
            _c("div", { staticClass: "topBox" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    padding: "10px 15px"
                  }
                },
                [
                  _c("div", { staticStyle: { "font-size": "26px" } }, [
                    _vm._v(_vm._s(item.name))
                  ]),
                  _vm._l(item.deviceParametersList, function(items) {
                    return items.name === "设备状态"
                      ? _c(
                          "div",
                          {
                            key: items.name,
                            staticStyle: { "font-size": "18px" }
                          },
                          [
                            items.value == "0"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "topStatus",
                                    staticStyle: { background: "#9D4714" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("statusName")(items.value)
                                      ) + " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            items.value == "1"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "topStatus",
                                    staticStyle: { background: "#00761F" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("statusName")(items.value)
                                      ) + " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            items.value == "2"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "topStatus",
                                    staticStyle: { background: "#E82308" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("statusName")(items.value)
                                      ) + " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            items.value == "3"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "topStatus",
                                    staticStyle: { background: "#75747B" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("statusName")(items.value)
                                      ) + " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  })
                ],
                2
              ),
              _c("hr")
            ])
          ]),
          _c("div", { staticClass: "centerBox" }, [
            item.deviceParametersList != null
              ? _c(
                  "div",
                  { staticClass: "leftText", staticStyle: { width: "47%" } },
                  [
                    _c("paramsDetail", {
                      attrs: { deviceParametersList: item.deviceParametersList }
                    })
                  ],
                  1
                )
              : _c("div", {
                  staticClass: "leftText",
                  staticStyle: { width: "47%" }
                }),
            _c(
              "div",
              { staticClass: "rightImg" },
              [
                item.drawing != null
                  ? _c("el-image", {
                      staticClass: "img",
                      attrs: {
                        src: item.drawing,
                        "preview-src-list": _vm.fixImg(item.drawing)
                      }
                    })
                  : _c("el-image", {
                      staticClass: "img",
                      attrs: {
                        src: "https://oss.mubyte.cn/static/empty.png",
                        "preview-src-list": _vm.fixImg(
                          "https://oss.mubyte.cn/static/empty.png"
                        )
                      }
                    })
              ],
              1
            )
          ]),
          _c("hr"),
          _c(
            "div",
            { staticClass: "bottomBox" },
            [
              _c("lineComponent", {
                attrs: {
                  deviceId: item.id,
                  deviceParametersList: item.deviceParametersList
                },
                on: {
                  gogogo: function($event) {
                    return _vm.handleGo($event, item)
                  }
                }
              })
            ],
            1
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }