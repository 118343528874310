let functionList = {}
export const getFormFunctionList = val => {
  functionList = val
}

export const descFormList = [
  {
    colSpan: 24,
    key: 'customerDescription',
    label: '客户描述',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过500字'
    }
  },
  {
    colSpan: 24,
    key: 'spotChecking',
    label: '现场检查',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过500字'
    }
  },
  {
    colSpan: 24,
    key: 'takeStep',
    label: '采取措施',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过500字'
    }
  }, {
    colSpan: 24,
    key: 'consequence',
    label: '结果',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过500字'
    }
  }, {
    colSpan: 24,
    key: 'followUpActions',
    label: '后继行动',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过500字'
    }
  }, {
    colSpan: 24,
    key: 'facilityHistoricalAlarmRecordList',
    label: '历史报警记录'
  }
]

export const localFormList = [
  {
    colSpan: 6,
    key: 'serviceStartTime',
    label: '服务开始时间',
    tagName: 'el-date-picker',
    required: true,
    props: {
      type: 'datetime',
      valueFormat: 'yyyy-MM-dd HH:mm',
      format: 'yyyy-MM-dd HH:mm'
    },
    attrs: {
      placeholder: '选择服务开始时间'
    },
    on: {
      change: (val) => { functionList.valueChange('serviceStartTime', val) }
    }
  },
  {
    colSpan: 6,
    key: 'serviceEndTime',
    label: '服务结束时间',
    tagName: 'el-date-picker',
    required: true,
    props: {
      type: 'datetime',
      valueFormat: 'yyyy-MM-dd HH:mm',
      format: 'yyyy-MM-dd HH:mm'
    },
    attrs: {
      placeholder: '服务结束时间'
    },
    on: {
      change: (val) => { functionList.valueChange('serviceEndTime', val) }
    }
  },
  {
    colSpan: 12,
    key: 'manHour',
    label: '服务时长',
    tagName: 'div',
    children: [{
      customSlots: ['manHour']
    }]
  },
  {
    colSpan: 6,
    key: 'customerContact',
    label: '客户联系人',
    tagName: 'el-input'
  },
  {
    colSpan: 6,
    key: 'contact',
    label: '联系方式',
    tagName: 'el-input'
  },
  {
    colSpan: 24,
    key: 'facilityServiceTimeMappingList',
    label: '协作人',
    tagName: 'div',
    required: false,
    children: [{
      customSlots: ['facilityServiceTimeMappingList']
    }]
  },
  {
    colSpan: 24,
    key: 'operationInstruction',
    label: '作业指导书',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过200字'
    }
  },
  {
    colSpan: 24,
    key: 'otherSpecialHazardousWorkPermits',
    label: '其他特种危险作业许可',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过200字'
    }
  },
  {
    colSpan: 24,
    key: 'securityProducts',
    label: '安防用品',
    tagName: 'el-input',
    props: { type: 'textarea' },
    attrs: {
      placeholder: '不超过200字'
    }
  },
  {
    colSpan: 24,
    label: '附件',
    key: 'affix',
    tagName: 'div',
    required: false,
    children: [{
      customSlots: ['drawing']
    }]
  }
]

export const ownershipOption = [
  {
    value: 0,
    label: '本地'
  }, {
    value: 1,
    label: '工厂'
  }, {
    value: 2,
    label: '客户'
  }
]

export const executorFormList = [
  {
    colSpan: 6,
    key: 'executor',
    label: '',
    tagName: 'el-select',
    props: {
      placeholder: '添加协作人',
      multiple: true,
      clearable: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'isSame',
    tagName: 'div',
    children: [{
      customSlots: ['isSame']
    }]
  },
  {
    colSpan: 6,
    key: 'date',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: []
    }
  },
  {
    colSpan: 6,
    key: 'manHour',
    label: '服务时长',
    tagName: 'div',
    children: [{
      customSlots: ['manHour']
    }]
  }
]

