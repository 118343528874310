<template>
  <div class="work-order-progress-container">
    <div class="top">
      <div>
        <div class="card-top card-top1">
          联网设备
        </div>
        <div class="card-bottom card-bottom1">
          <dv-digital-flop
            :config="getDigitalConfig(digital.haveEquip, ['#fff', '#fff'])"
            style="font-size: 50px;margin-top: 20px;height: 80px;"
          />
        </div>
      </div>
      <div>
        <div class="card-top card-top2">
          生产中
        </div>
        <div class="card-bottom card-bottom2">
          <dv-digital-flop
            :config="getDigitalConfig(digital.ingEquip, ['#fff', '#fff'])"
            style="font-size: 50px;margin-top: 20px;height: 80px;"
          />
        </div>
      </div>
      <div>
        <div class="card-top card-top3">
          待机中
        </div>
        <div class="card-bottom card-bottom3">
          <dv-digital-flop
            :config="getDigitalConfig(digital.waitEquip, ['#fff', '#fff'])"
            style="font-size: 50px;margin-top: 20px;height: 80px;"
          />
        </div>
      </div>
      <!-- 故障中 -->
      <div>
        <div class="card-top card-top4">
          故障中
        </div>
        <div class="card-bottom card-bottom4">
          <dv-digital-flop
            :config="getDigitalConfig(digital.breakEquip, ['#fff', '#fff'])"
            style="font-size: 50px;margin-top: 20px;height: 80px;"
          />
        </div>
      </div>
      <!-- 已停机 -->
      <div>
        <div class="card-top card-top5">
          已停机
        </div>
        <div class="card-bottom card-bottom5">
          <dv-digital-flop
            :config="getDigitalConfig(digital.stopEquip, ['#fff', '#fff'])"
            style="font-size: 50px;margin-top: 20px;height: 80px;"
          />
        </div>
      </div>
    </div>
    <div class="bottom">
      <!-- <dv-scroll-board :config="config" :style="{ width: '100%', height: tableHeight + 'px' }"> -->
      <div v-for="item in equipData" :key="item.id" class="allBorder">
        <div style="background:#000">
          <div class="topBox">
            <div style="display:flex;justify-content: space-between;padding: 10px 15px">
              <div style="font-size:26px;">{{ item.name }}</div>

              <div v-for="items in item.deviceParametersList" v-if="items.name==='设备状态'" :key="items.name"
                   style="font-size:18px">
                <div v-if="items.value == '0'" class="topStatus" style="background: #9D4714">{{
                    items.value|statusName
                  }}
                </div>
                <div v-if="items.value == '1'" class="topStatus" style="background: #00761F">{{
                    items.value|statusName
                  }}
                </div>
                <div v-if="items.value == '2'" class="topStatus" style="background: #E82308">{{
                    items.value|statusName
                  }}
                </div>
                <div v-if="items.value == '3'" class="topStatus" style="background: #75747B">{{
                    items.value|statusName
                  }}
                </div>
              </div>

            </div>
            <hr>
          </div>
          <!-- <div class="topBox" style="height:37px;">
            <div v-for="items in item.deviceParametersList" :key="items.name">
              <div v-show="items.name==='OEE'" style="height:37px;font-size:20px;padding:8px 0px 8px 15px;">OEE：{{ items.value }}%</div>
            </div>
          </div> -->
        </div>
        <!-- 设备参数详情 -->
        <div class="centerBox">
          <div v-if="item.deviceParametersList!=null" class="leftText" style="width:47%">
            <!-- <div v-for="items in item.deviceParametersList.slice(0, 4)" v-if="items.name!='OEE'&&items.name!='设备状态'" :key="items.name" class="centerText">
              <div>{{ items.name }}：</div>
              <div>
                <span>{{ items.value }}</span>
                <span style="font-size:14px;margin-left: 2px">{{ items.unit }}</span>
              </div>
            </div> -->
            <paramsDetail :deviceParametersList="item.deviceParametersList"></paramsDetail>

          </div>
          <div v-else class="leftText" style="width:47%"/>
          <div class="rightImg">
            <el-image v-if="item.drawing!=null" :src="item.drawing" class="img"
                      :preview-src-list="fixImg(item.drawing)"/>
            <el-image v-else src="https://oss.mubyte.cn/static/empty.png" class="img"
                      :preview-src-list="fixImg('https://oss.mubyte.cn/static/empty.png')"/>

          </div>
        </div>
        <hr>
        <div class="bottomBox">
          <lineComponent @gogogo="handleGo($event,item)" :deviceId="item.id" :deviceParametersList="item.deviceParametersList"></lineComponent>
        </div>
        <!-- <div v-if="item.deviceParametersList!=null&&item.deviceParametersList.length>4" class="bottomBox">
          <div v-for="items in item.deviceParametersList.slice(4, 9)" v-if="items.name!='OEE'&&items.name!='设备状态'" :key="items.name">
            <span>{{ items.name }}：</span>
            <span>
              <span>{{ items.value }}</span>
              <span style="font-size:14px;margin-left: 2px">{{ items.unit }}</span>
            </span>
          </div>
        </div> -->
      </div>
      <!-- </dv-scroll-board> -->
    </div>
  </div>
</template>

<script>
import mix from './mixin'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
import { baseUrl } from '@/views/statistics-board/board-detail/components/base-setting'
import CustomProgress from '@/views/statistics-board/board-detail/components/custom-progress'
import api from '@/api/statistics-board/equipment-board'

import lineComponent from './line-component.vue'
import paramsDetail from './params-detail.vue'
import dayjs from 'dayjs'

const scrollBoardSettingDefault = {
  hoverPause: false,
  waitTime: 10000

}
export default {
  name: 'EquipmentNew',
  components: { CustomProgress, scrollBoard, lineComponent, paramsDetail },
  filters: {
    statusName(val) {
      switch (val) {
        case '0':
          return '空闲'
        case '1':
          return '生产'
        case '2':
          return '故障'
        case '3':
          return '停机'
      }
    }
  },
  mixins: [mix],
  data() {
    return {
      digital: {
        waitEquip: 0,
        ingEquip: 0,
        breakEquip: 0,
        stopEquip: 0,
        haveEquip: 0
      },
      tableHeight: 100,
      config: {
        ...scrollBoardSettingDefault,
        data: [[]]
      },
      scrollBoardSettingDefault,
      tableData: [],
      equipData: [],
      timer:null
    }
  },
  mounted() {
    this.reloadData()
    this.workOrderEquip()

    this.timer = setInterval(() => {
      this.equipListData()
    }, 180000)
    // this.initWebSocket()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    handleGo(a,b){
      b.deviceParametersList.forEach(item => {
        item.value = a[item.attribute] || "0"
      })
    },
    initWebSocket() {
      const url = process.env.VUE_APP_MSG_API.split('http://')[1]
      const wsuri = `ws://${url}ws/${localStorage.getItem('memberCode')}/0`
      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onopen = this.websocketonopen
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    websocketonopen() {
      // console.log('设备websocket连接成功')
    },
    websocketonerror() {
      this.initWebSocket()
    },
    websocketclose(e) {
      // console.log('断开连接', e)
      this.initWebSocket()
    },
    websocketonmessage(e) {
      const data = e.data.split(':')
      this.equipData.forEach(item => {
        if (item.name === data[0]) {
          item.deviceParametersList.forEach(v => {
            if (v.name === data[1]) {
              this.$set(v, 'value', data[2])
            } else if (data[1] == '设备状态') {
              item.collectStatus = data[2]
            }
          })
        }
      })
      this.digital = {
        waitEquip: this.equipData.filter(item => item.collectStatus == 0).length,
        ingEquip: this.equipData.filter(item => item.collectStatus == 1).length,
        breakEquip: this.equipData.filter(item => item.collectStatus == 2).length,
        stopEquip: this.equipData.filter(item => item.collectStatus == 3).length,
        haveEquip: this.equipData.length
      }
    },
    reloadData() {
      this.equipListData()
      // this.areaIds = areaIds
      this.workOrderEquip()
    },
    fixImg(img) {
      return [img]
    },
    // 获取列表信息
    async equipListData() {
      const res = await api.getEquipmentParametersList()
      if (res) {
        this.$nextTick(() => {
          if (res.length > 3) {
            [res[0], res[1]] = [res[1], res[0]];
            [res[2], res[4]] = [res[4], res[2]];
            [res[4], res[6]] = [res[6], res[4]];
            const T50 = res.pop()
            res.splice(3, 0, T50)
            const T50_2 = res.pop()
            res.splice(4, 0, T50_2)
          }
          let ppp = 0
          const ttt = setInterval(()=>{
            if(ppp > res.length - 1 ){
              ppp = 0
              clearInterval(ttt)
            }else {
              if(this.equipData[ppp] && this.equipData[ppp].id){
                this.$set(this.equipData, ppp, res[ppp])
              }else {
                this.equipData.push(res[ppp])
              }
              ppp++;
            }
          },50)
          // this.equipData = res
        })
        this.$emit('equipList', res)
      }
    },
    async workOrderEquip() {
      const res = await api.getEquipmentStatusList()
      if (res) {
        this.digital.waitEquip = res[0]
        this.digital.ingEquip = res[1]
        this.digital.breakEquip = res[2]
        this.digital.stopEquip = res[3]
        this.digital.haveEquip = res[4]
      }
    },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.tableHeight = (window.innerHeight * 0.7) / scale
    }

  }
}
</script>
<style>
.scale-box {
  overflow-y: auto;
}
</style>
<style scoped lang="scss">
@import "./common";

.popover-content-footer {
  margin-bottom: 10px;
  position: relative;
  left: -12px;
  max-height: 380px;
  overflow-y: auto;

  .el-checkbox-group {
    overflow: hidden;

    .el-checkbox {
      width: 100%;
      padding: 8px 8px 8px 28px;
      cursor: pointer;

      &:hover {
        background: #ECF0F4;
      }
    }
  }
}

.work-order-progress-container {
  padding-bottom: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;

  .quanlityType {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .top {
    padding: 0 30px 20px 30px;
    display: flex;
    justify-content: space-between;

    > div {
      width: 338px;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 120px;

      .card-top {
        width: 200px;
        height: 120px;
        color: #fff;
        text-align: center;
        font-size: 24px;
        line-height: 120px;
      }

      .card-top1 {
        background: rgba(58, 33, 166, .4);
      }

      .card-top2 {
        background: rgba(0, 118, 31, .4);
      }

      .card-top3 {
        background: rgba(157, 71, 20, .4);
      }

      .card-top4 {
        background: #891506;
      }

      .card-top5 {
        background: #454449;
      }

      .card-bottom {
        width: 380px;
        height: 120px;
        text-align: center;

      }

      .card-bottom1 {
        background: rgba(58, 33, 166, 1);
      }

      .card-bottom2 {
        background: rgba(0, 118, 31, 1);
      }

      .card-bottom3 {
        background: rgba(157, 71, 20, 1);
      }

      .card-bottom4 {
        background: #E82308;
      }

      .card-bottom5 {
        background: #75747B;
      }

    }
  }

  .bottom {
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;

    .allBorder {
      width: 24%;
      color: #fff;
      border: 1px solid #3b54b8;
      margin-top: 15px;
      margin-right: 15px;
      border-radius: 4px;
      background: #090c37;

      hr {
        opacity: 0.5;
        border: 1px solid #3b54b8;
        margin: 0px;
      }

      .topBox {
        background: linear-gradient(90deg, #000294 0%, rgba(0, 1, 91, 0.1000) 100%);

        .topStatus {
          border-radius: 4px;
          padding: 5px 20px;
        }
      }

      .centerBox {
        height: 170px;
        display: flex;
        justify-content: space-between;
        padding: 15px;
        font-size: 18px;
        background: #000;

        .centerText {
          padding-top: 10px;
          display: flex;
          justify-content: space-between;
        }
      }

      .img {
        width: 150px;
        height: 140px;
        display: block;
        margin-left: 8px;
        cursor: pointer;
      }

      .bottomBox {
        height: 150px;
        padding: 0px 10px 0px 10px;
        background: #090c37;
        font-size: 18px;
        // >div {
        //   display: flex;
        //   justify-content: space-between;
        //   width: 49%;
        //   padding-left: 15px;
        //   padding-bottom: 8px;
        // }
      }
    }

    .progress-line-wrapper {
      white-space: nowrap;

      .progress-wrap {
        width: 162px;
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        ::v-deep .el-progress {
          position: relative;
          top: 1px;
        }

        .progress-text {
          position: absolute;
          top: 1px;
          left: 0;
          right: 0;
          bottom: 22px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            white-space: nowrap;
            transform: scale(.8);
            color: #607FFF;
          }
        }

        &.active-procedure {
          border: 2px solid #607FFF;
          background: #0F2067;

          .procedure-name {
            color: #FFFFFF;
          }

          .progress-text {
            span {
              color: #FFFFFF;
            }
          }
        }

        &:not(:last-child) {
          &::after {
            content: "";
            display: inline-block;
            height: 1px;
            background: #2E4398;
            width: 88px;
            position: absolute;
            top: 39%;
            left: 118px;
          }
        }

        &.wait-procedure {
          .procedure-name {
            color: #999999 !important;
          }

          .progress-text {
            span {
              color: #999999 !important;
            }
          }

          &:not(:last-child) {
            &::after {
              content: "";
              display: inline-block;
              height: 1px;
              background: #999;
              width: 88px;
              position: absolute;
              top: 39%;
              left: 118px;
            }
          }
        }
      }

      .procedure-name {
        width: 100%;
        font-size: 14px !important;
        text-align: center;
        position: relative;
        top: 1px;
        color: #607FFF;
      }
    }
  }
}

::v-deep .dv-scroll-board .ceil {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.status-board {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  text-align: left;
  line-height: 85px;
}
</style>
