<template>
  <div class="add-but">
    <el-button type="text" icon="el-icon-circle-plus" @click="openModel" />

    <MDialog v-model="nameVisible" width="400px" title="自定义报表" @onOk="submitName">
      <div style="margin-right: 15px">
        <el-input v-model="card.name" placeholder="请输入自定义报表名称" />
      </div>
    </MDialog>
    <MDialog v-model="cardListVisible" title="选择卡片类型" width="900px" :cancel-button-text="cancelButtonText" :custom-cancel="true" @onOk="submitCards" @onCancel="cardDialogCancel">
      <div v-if="step === 1">
        <FunctionHeader
          v-model="functionHeader.searchVal"
          search-title="请输入卡片名称"
          :columns-setting="false"
          @search="searchCard"
        />
        <div class="content">
          <div class="left">
            <div
              v-for="(item, index) in statisticsCards"
              :key="index"
              :class="{ 'type-active': index === currentIndex }"
              @click="getChildCards(item, index)"
            >
              {{ item.name }}（{{ item.children.length }}）
            </div>
          </div>
          <div class="right" :style="{ height: modalHeight + 'px' }">
            <div class="flex-wrapper">
              <div v-for="(item, index) in currentStatisticsCards" :key="index" class="card-wrapper" :class="{'disabled-card': selectedIds.includes(item.id) }" @click="selectCard(item)">
                <div>
                  <div class="title">{{ item.name }}</div>
                  <div class="description">{{ item.tooltip }}</div>
                </div>
                <div>
                  <div v-show="!item.checked" style="border-radius: 30px;width: 19px;height: 19px;border: 1px solid #9597AE" />
                  <div v-show="item.checked">
                    <i class="el-icon-success" style="font-size: 20px;color: #607FFF" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 2">
        <div class="single-card-wrapper">
          <div>
            <div class="title">{{ currentSelectCard.name }}</div>
            <div class="description">{{ currentSelectCard.tooltip }}</div>
          </div>
        </div>
        <chart-search-condition-has-title ref="conditionHasTitle" style="padding-top: 20px" :tab-info="currentSelectCard" @conditionChange="conditionChange" />
      </div>
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import statisticsCards from '../../all-statistics/statistics-card-copy'
import ChartSearchConditionHasTitle
  from '@/views/statistics-board/board-manage/components/charts/chart-search-condition-has-title'
import baseSettingApi from '@/api/sets/base-setting'
import { getUUid } from '@/utils'
export default {
  name: 'AddStatisticsCard',
  components: { ChartSearchConditionHasTitle, FunctionHeader },
  props: {
    customStatisticsCardsInfo: {
      type: Object,
      default: () => ({})
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      nameVisible: false,
      cardListVisible: false,
      card: {
        name: ''
      },
      functionHeader: { searchVal: '' },
      statisticsCards: this._.cloneDeep(statisticsCards),
      currentStatisticsCards: [],
      currentIndex: 0,
      selectedIds: [],
      currentSelectCard: {},
      step: 0,
      cancelButtonText: '取 消',
      condition: {}
    }
  },
  computed: {
    modalHeight() {
      return this.$store.state.app.contentHeight - 289 - 48 - 12 - 35
    }
  },
  methods: {
    openModel() {
      this.card.name = ''
      this.nameVisible = true
    },
    selectCard(item) {
      if (this.selectedIds.includes(item.id)) return
      this.statisticsCards.forEach(item => {
        item.children.forEach(child => {
          if (!this.selectedIds.includes(child.id)) {
            this.$set(child, 'checked', false)
          }
        })
      })
      this.currentSelectCard = item
      this.$set(item, 'checked', !item.checked)
    },
    async submitName(callback) {
      if (!this.card.name) {
        callback()
        return this.$message.info('请输入自定义报表名称')
      }
      if (this.card.name) {
        let cards = []
        if (this.customStatisticsCardsInfo.value) {
          cards = JSON.parse(this.customStatisticsCardsInfo.value)
        }
        if (cards.map(item => item.title).includes(this.card.name)) {
          callback()
          return this.$message.info('自定义报表名称重复，请修改名称')
        }
        cards.push({ id: getUUid(), title: this.card.name, children: [] })
        const res = await baseSettingApi.modifySingleSetting({
          id: this.customStatisticsCardsInfo.id,
          code: this.customStatisticsCardsInfo.code,
          value: JSON.stringify(cards)
        })
        if (res) {
          this.nameVisible = false
          this.$emit('updateStatisticsCards')
          this.$emit('typeChange', 1)
          this.$message.success('保存成功')
        }
        callback()
      }
    },
    getChildCards(item, index) {
      this.currentStatisticsCards = item.children
      this.currentIndex = index
    },
    searchCard() {
      if (this.functionHeader.searchVal) {
        this.currentStatisticsCards = this.currentStatisticsCards.filter(item => item.name.indexOf(this.functionHeader.searchVal) !== -1)
      } else {
        this.currentStatisticsCards = this.statisticsCards[this.currentIndex].children
      }
    },
    async submitCards(callback) {
      if (!this.currentSelectCard.id) {
        callback()
        return this.$message.info('请选择卡片')
      }
      if (this.step === 2 || !this.currentSelectCard.searches || !this.currentSelectCard.searches.length) {
        const bodyData = this._.cloneDeep(this.currentSelectCard)
        if (this.currentSelectCard.searches && this.currentSelectCard.searches.length) {
          const hasRange = bodyData.searches.find(item => item.type === 'dateRange')
          const { beginTime, endTime } = this.condition
          if (hasRange && (!beginTime || !endTime)) {
            callback()
            return this.$message.info('请选择日期范围')
          }
          bodyData.dataCondition = this.condition
          bodyData.dataConditionOrigin = this.$refs.conditionHasTitle.searchForm
          const formOptionOrigin = {}
          this.currentSelectCard.searches.forEach(c => {
            const value = bodyData.dataConditionOrigin[c.asKey || c.key]
            if ((c.type === 'cascader' || c.type === 'select') && (!c.options || !c.options.length) && value && value.length) {
              const options = this.$refs.conditionHasTitle.formOptions[c.dataKey]
              if (c.type === 'select') formOptionOrigin[c.dataKey] = options.filter(item => value.includes(item.id))
              if (c.type === 'cascader') {
                const ids = value.reduce((a, b) => a.concat(b), [])
                formOptionOrigin[c.dataKey] = options.filter(item => ids.includes(item.id))
              }
            }
          })
          bodyData.formOptionOrigin = formOptionOrigin
        }
        let cards = []
        if (this.customStatisticsCardsInfo.value) {
          cards = JSON.parse(this.customStatisticsCardsInfo.value)
        }
        cards.forEach(item => {
          if (item.id === this.currentId) {
            item.children.push(bodyData)
          }
        })
        const res = await baseSettingApi.modifySingleSetting({
          id: this.customStatisticsCardsInfo.id,
          code: this.customStatisticsCardsInfo.code,
          value: JSON.stringify(cards)
        })
        if (res) {
          this.cardListVisible = false
          this.$emit('updateStatisticsCards')
          this.$message.success('添加成功')
        }
        callback()
      } else {
        if (this.currentSelectCard.id) {
          this.step = 2
          this.cancelButtonText = '上一步'
          callback()
        }
      }
    },
    cardDialogCancel() {
      if (this.cancelButtonText === '上一步') {
        this.step = 1
        this.cancelButtonText = '取 消'
      } else {
        this.cardListVisible = false
      }
    },
    conditionChange(val) {
      this.condition = val
    },
    // 新增卡片
    addCard() {
      this.step = 1
      this.currentIndex = 0
      this.currentStatisticsCards = this.statisticsCards[0].children
      this.selectedIds = []
      if (this.customStatisticsCardsInfo.value) {
        this.selectedIds = JSON.parse(this.customStatisticsCardsInfo.value)
          .find(item => item.id === this.currentId)
          .children.map(item => item.id)
      }
      this.statisticsCards.forEach(item => {
        item.children.forEach(child => {
          if (!this.selectedIds.includes(child.id)) {
            this.$set(child, 'checked', false)
          } else {
            this.$set(child, 'checked', true)
          }
        })
      })
      this.cancelButtonText = '取 消'
      this.cardListVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.add-but {
  position: absolute;
  right: 8px;
  top: 2px;
  button {
    font-size: 20px;
    padding: 0;
  }
  .function-header-wrap {
    padding-top: 0;
    padding-bottom: 10px;
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left {
      width: 210px;
      border-radius: 4px;
      border: 1px solid #DDE3EB;
      margin-right: 15px;
      >div {
        padding: 15px 40px;
        cursor: pointer;
        &:not(:last-child) {
          border-bottom: 1px solid #DDE3EB;
        }
        &.type-active {
          background: #F7F9FC;
        }
      }
    }
    .right {
      flex: 1;
      background: #F7F9FC;
      border-radius: 4px 4px 0 0;
      border: 1px solid #ECEEF2;
      overflow-y: auto;
      .flex-wrapper {
        flex-wrap: wrap;
        justify-content: space-between;
        display: flex;
        padding-right: 15px;
        padding-bottom: 15px;
        .card-wrapper {
          width: 47%;
          padding: 17px 0 22px 19px;
          background: #FFFFFF;
          box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          margin: 15px 0 0 15px;
          border: 1px solid #DDE3EB;
          display: flex;
          justify-content: space-between;
          .title {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 700;
          }
          .description {
            padding-top: 10px;
            font-size: 12px;
            color: #9FA1B7;
          }
          >div:last-child {
            padding: 0 20px;
            display: flex;
            align-items: center;
          }
        }
        .disabled-card {
          background: #ECEEF2;
          .title {
            color: #9597AE;
          }
          .description {
            color: #9FA1B7;
          }
          .el-icon-success {
            color: #9597AE !important;
          }
        }
      }
    }
  }
  .single-card-wrapper {
    padding: 17px 0 22px 19px;
    background: #F7F9FC;
    border-radius: 4px 4px 0 0;
    border: 1px solid #ECEEF2;
    .title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 700;
    }
    .description {
      padding-top: 10px;
      font-size: 12px;
      color: #9FA1B7;
    }
  }
  ::v-deep .search-setting {
    display: none;
  }
}
</style>
