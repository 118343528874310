<template>
  <div class="unit-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button v-if="permission('OutInWarehouseType')" label="OutInWarehouseType">出入库类型</el-radio-button>
    </el-radio-group>
    <FunctionHeader
      v-model="functionHeader.searchVal"
      :tags="functionHeader.tags"
      :radio="params.radio"
      :columns-key="columnsKey"
      :hidden-search="true"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable v-if="permission(params.radio + 'List')" ref="mTable" :columns="columnsAll" :height="height" :columns-key="columnsKey" :set-data-method="getTableData" :columns-setting="false">
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <div slot="type" slot-scope="{ row }">
        <div>{{ +row.type === 0 ? '入库' : '出库' }}</div>
      </div>
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission(params.radio + 'Modify') && row.isFixed == 0" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
        <el-divider v-if="permission(params.radio + 'Modify') && row.isFixed == 0" direction="vertical" />
        <!-- <el-button v-if="permission(params.radio + 'Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission(params.radio + 'Status')" direction="vertical" /> -->
        <DelPopover v-if="permission(params.radio + 'Del') && row.isFixed == 0" @onOk="(callback) => del(row, callback)" />
      </div>
    </MTable>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList['OutInWarehouseType']" />
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import DelPopover from '@/components/DelPopover/index'
import { columnsAll } from './columns'
import formList from './form-list'
import api from '@/api/xiewei/spare-part/set'
import conventionMixin from '@/mixin/conventional-page'

export default {
  name: 'SparePartsSet',
  components: { FunctionHeader, DelPopover },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'OutInWarehouseType'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('OutInWarehouseTypeAdd'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: () => {
                this.add()
              }
            }
          }
        ]
      },
      columnsAll,
      visible: false,
      formList: formList,
      formData: {},
      currentType: 'add'
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 10
    }
  },
  methods: {
    radioChange(label) {
      const flag = label === 'OutInWarehouseType'
      this.$refs.mTable.setTableData(true)
    },
    createSetData(raw) {
      return {
        name: raw.name,
        type: raw.type,
        remark: raw.remark,
        isFixed: raw.isFixed // This approach is very SB. The parameter should be checked by the backend based on the ID to determine if it can be modified, rather than having the frontend tell the backend. It lacks proper security awareness.
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.unit-wrap {
  padding: 10px;
}
</style>
