
import { status } from '@/config/options.config'
import { formatColumns } from '@/utils'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'name',
    label: '文件名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'name'
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 150
  }
]

export const resetColumns = [
  {
    prop: 'name',
    label: '文件名称',
    sortable: true,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      colSpan: 24
    }
  },
  {
    prop: 'enterpriseDocumentClassId',
    label: '目录',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: { index: 1, tagName: 'el-select', key: 'enterpriseDocumentClassId', colSpan: 24, props: {
      props: {
        label: 'name',
        value: 'id'
      },
      options: [],
      filterable: true
    }}
  }
]
export const addColumns = [
  {
    prop: 'enterpriseDocumentClassId',
    label: '目录',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: { index: 1, tagName: 'el-select', key: 'enterpriseDocumentClassId', colSpan: 24, props: {
      props: {
        label: 'name',
        value: 'id'
      },
      options: [],
      filterable: true
    }}
  },
  {
    prop: 'drawing',
    label: '附件',
    sortable: false,
    hiddenSearch: true,
    slotName: 'drawing',
    form: {
      index: 2,
      colSpan: 24,
      tagName: 'div',
      customSlots: ['drawing']
    }
  }
]
