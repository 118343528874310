import { status } from '@/config/options.config'
import { columns, addColumns } from './columns'
import { transformColumnsToForm } from '@/utils'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const formList = [
  ...transformColumnsToForm(addColumns)
]

export const searchFormList = [
  {
    key: 'equipmentClassIds',
    tagName: 'el-cascader',
    colSpan: 6,
    props: {
      placeholder: '请选择关联设备类',
      props: {
        label: 'name',
        value: 'id',
        checkStrictly: true,
        multiple: true
      },
      options: [],
      collapseTags: true,
      filterable: true
    }
  },
  {
    key: 'creator',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择创建人'
    },
    children: [
      {
        tagName: 'el-option',
        innerValue: (val) => {
          return `${val['jobNumber']} - ${val['name']}`
        },
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 8,
    key: 'createTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '创建时间',
      endPlaceholder: '创建时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: []
    }
  }
]

