<template>
  <div class="equipment-monitoring">
    <div class="detail-cards">
      <div v-if="isTitle" class="card-tit">关联设备</div>
      <div class="card-bots">
        <div v-if="type === 'new'" style="margin-bottom: 10px">
          <el-button size="small" type="primary" @click="addInfo">添加</el-button>
        </div>
        <MTable ref="plansTable" :show-page="false" :height="heights" :columns="columns" :data="facilityMappingList" :only-key="'uuid'">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="action" slot-scope="{ row, $index }">
            <template v-if="type === 'new'">
              <el-button type="text" @click="delInfos(row, $index)">移除</el-button>
            </template>
          </div>
        </MTable>
      </div>
    </div>
    <select-facility
      ref="selectFacility"
      :visible.sync="showFacility"
      :select-facilitys="selectFacilitys"
      :customer-id="customerIds"
      @submitForm="submitFacility"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { equipmentColumns } from './columns'
import { equipmentFormList } from './form-list'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import SelectFacility from '@/components/SelectFacility/SelectFacility.vue'

export default {
  name: 'RelationEquipment',
  components: { SelectFacility },
  props: {
    isTitle: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'new'
    },
    list: {
      type: Array,
      default: () => []
    },
    selecteFacilityList: {
      type: String,
      default: ''
    },
    customerId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      facilityMappingList: [],
      date: dayjs().format('YYYY-MM-DD'),
      currentType: 'add',
      equipmentColumns,
      equipmentFormList,
      columns: [],
      tableData: [],
      formData: {},
      showFacility: false,
      messages: {
        add: '添加',
        modify: '修改'
      },
      selectFacilitys: [],
      customerIds: undefined
    }
  },
  computed: {
    heights() {
      return this.$store.state.app.contentHeight - 256 - 220
    }
  },
  watch: {
    list(val) {
      if (val) {
        this.facilityMappingList = val
      }
      this.selectFacilitys =
        val && val.length > 0
          ? val.map(item => {
            return {
              ...item,
              id: item.facilityId,
              selectable: true
            }
          })
          : []
    },
    customerId(val) {
      this.customerIds = val
    }
  },
  mounted() {
    const newColumn = this._.cloneDeep(this.equipmentColumns)
    newColumn.pop()
    this.columns = this.type === 'new' ? this.equipmentColumns : newColumn
    this.facilityMappingList = this.list
    this.customerIds = this.customerId
  },
  methods: {
    async submitFacility(val) {
      const arr = val.map(item => {
        return {
          ...item,
          facilityId: item.id,
          selectable: true
        }
      })
      this.selectFacilitys = [...this.selectFacilitys, ...arr]
      const data = val.map(item => {
        return {
          ...item,
          facilityId: item.id,
          uuid: getUUid()
        }
      })
      if (this.type === 'new') {
        this.facilityMappingList = [...this.facilityMappingList, ...data]
        this.$emit('facilityInfo', this.facilityMappingList)
      }
      this.showFacility = false
    },
    addInfo() {
      if (this.customerIds) {
        this.showFacility = true
        return
      }
      return this.$message.error('请选择客户')
    },

    delInfos(row, index) {
      this.facilityMappingList.splice(index, 1)
      this.selectFacilitys = this.selectFacilitys.filter(item => item.id !== +row.facilityId)
      // this.$emit('facilityInfo', this.facilityMappingList)
    },
    getSuccessMessage() {
      return `${this.messages[this.currentType]}成功`
    }
  }
}
</script>

<style scoped lang="scss">
.equipment-monitoring {
  width: 100%;
  ::v-deep {
    .el-form-item__label {
      padding: 0 12px 0 0;
    }
    // .el-form-item__content {
    //   width: 300px;
    // }
    .el-form-item {
      margin-bottom: 20px !important;
    }
  }
  .card-wrapper {
    border: 1px solid #cccccc;
    border-radius: 4px;
    .top {
      width: 100%;
      padding: 10px;
      height: 53px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      .right {
        height: 32px;
        img {
          width: 30px;
          padding-left: 10px;
          position: relative;
          top: 5px;
        }
      }
    }
    .body {
      padding: 10px;
      .li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .line-wrapper {
    width: calc(100% - 260px);
  }
  .equipment-params {
    width: 250px;
  }
}
</style>
