
export const searchFormList = [
  {
    colSpan: 4,
    key: 'orderSource',
    tagName: 'el-select',
    props: {
      placeholder: '订单来源',
      configFilter: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name', // 自定义label需要字段值与configLabel字段值一致
          value: 'id'
        },
        options: [
          { name: 'MES新增', id: 0 },
          { name: 'ERP', id: 1 }
        ]
      }
    ]
  },
  {
    colSpan: 4,
    key: 'customerId',
    tagName: 'el-select',
    props: {
      placeholder: '客户'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [],
        apiName: ''
      }
    ]
  },
  // {
  //   colSpan: 4,
  //   key: 'status',
  //   tagName: 'el-select',
  //   props: {
  //     placeholder: '状态'
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'name',
  //         value: 'id'
  //       },
  //       options: [],
  //       apiName:''
  //     }
  //   ]
  // },

  {
    colSpan: 4,
    key: 'deliveryType',
    tagName: 'el-select',
    props: {
      placeholder: '交付类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [
          { name: '统一交付', id: 0 },
          { name: '分批交付', id: 1 }
        ],
        apiName: ''
      }
    ]
  },
  {
    colSpan: 8,
    key: 'dateRange',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '交付日期开始时间',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd',
      fullTimeVal: ['00:00:00', '23:59:59']
    }
  },

  {
    colSpan: 4,
    key: 'principalId',
    tagName: 'el-select',
    props: {
      placeholder: '负责人'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'userFullName',
          value: 'id'
        },
        options: [],
        apiName: ''
      }
    ]
  }
]
