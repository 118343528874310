var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VeLine", {
    attrs: {
      height: "140px",
      data: _vm.chartData,
      settings: _vm.lineChartSettings,
      extend: _vm.lineChartExtend
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }