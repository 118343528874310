var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "work-order-progress-container" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", [
        _c("div", { staticClass: "card-top card-top1" }, [_vm._v(" 待质检 ")]),
        _c(
          "div",
          { staticClass: "card-bottom card-bottom1" },
          [
            _c("dv-digital-flop", {
              staticStyle: {
                "font-size": "50px",
                "margin-top": "20px",
                height: "80px"
              },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.waitQuality, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        )
      ]),
      _c("div", [
        _c("div", { staticClass: "card-top card-top2" }, [_vm._v(" 质检中 ")]),
        _c(
          "div",
          { staticClass: "card-bottom card-bottom2" },
          [
            _c("dv-digital-flop", {
              staticStyle: {
                "font-size": "50px",
                "margin-top": "20px",
                height: "80px"
              },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.ingQuality, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        )
      ]),
      _c("div", [
        _c("div", { staticClass: "card-top card-top3" }, [
          _vm._v(" 今日完成 ")
        ]),
        _c(
          "div",
          { staticClass: "card-bottom card-bottom3" },
          [
            _c("dv-digital-flop", {
              staticStyle: {
                "font-size": "50px",
                "margin-top": "20px",
                height: "80px"
              },
              attrs: {
                config: _vm.getDigitalConfig(_vm.digital.haveQuality, [
                  "#fff",
                  "#fff"
                ])
              }
            })
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c("scroll-board", {
          style: { width: "100%", height: _vm.tableHeight + "px" },
          attrs: { config: _vm.scrollBoardConfig },
          scopedSlots: _vm._u([
            {
              key: "quanlityType",
              fn: function(ref) {
                var index = ref.index
                var zn = ref.zn
                return _c(
                  "div",
                  { staticClass: "quanlityType" },
                  [
                    _c("span", [_vm._v(_vm._s(zn))]),
                    _c(
                      "el-popover",
                      {
                        ref: "popover",
                        attrs: { width: 160, offset: 20 },
                        on: { show: _vm.popoverShowEvent },
                        model: {
                          value: _vm.showPopover,
                          callback: function($$v) {
                            _vm.showPopover = $$v
                          },
                          expression: "showPopover"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "popover-content-footer",
                            style: { maxHeight: "300px" }
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                staticClass: "check-wrapper",
                                model: {
                                  value: _vm.checkedList,
                                  callback: function($$v) {
                                    _vm.checkedList = $$v
                                  },
                                  expression: "checkedList"
                                }
                              },
                              _vm._l(_vm.lists, function(item) {
                                return _c(
                                  "el-checkbox",
                                  { key: item.id, attrs: { label: item.id } },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "popover-content-top" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    _vm.showPopover = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: { click: _vm.saveColumns }
                              },
                              [_vm._v("确定")]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c("img", {
                      directives: [
                        {
                          name: "popover",
                          rawName: "v-popover:popover",
                          arg: "popover"
                        }
                      ],
                      staticClass: "img",
                      attrs: { src: require("@/assets/screen/filter.png") }
                    })
                  ],
                  1
                )
              }
            },
            {
              key: "status",
              fn: function(ref) {
                var index = ref.index
                return _c(
                  "div",
                  {
                    staticClass: "status-board",
                    style: {
                      background:
                        _vm.productionStatus[_vm.tableData[index].status].color
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.productionStatus[_vm.tableData[index].status].name
                      )
                    )
                  ]
                )
              }
            },
            {
              key: "inspectionType",
              fn: function(ref) {
                var index = ref.index
                return _c("span", {}, [
                  _vm._v(
                    _vm._s(_vm.types[_vm.tableData[index].inspectionType].name)
                  )
                ])
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }