var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-slot-list" },
    [
      _vm.slotName === "orderOverPercent"
        ? _c(
            "div",
            [
              _c("el-progress", {
                attrs: {
                  "text-inside": true,
                  "stroke-width": 15,
                  percentage: _vm.getPercent(
                    _vm.row.reportWorkQuantity,
                    _vm.row.quantity
                  )
                }
              })
            ],
            1
          )
        : _vm._e(),
      [
        "finishedRatio",
        "finishedOnTimeRatio",
        "onTimeRatio",
        "NGRatio"
      ].includes(_vm.slotName)
        ? _c(
            "div",
            [
              _c("el-progress", {
                attrs: {
                  "text-inside": true,
                  "stroke-width": 15,
                  percentage: _vm.fixNumber2(
                    _vm.row[_vm.column.columnKey || _vm.column.prop]
                  )
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.slotName === "currentProcedure"
        ? _c("div", [
            _vm._v(" " + _vm._s(_vm.getCurrentProcedure("procedureName")) + " ")
          ])
        : _vm._e(),
      _vm.slotName === "currentProcedureDuration"
        ? _c(
            "div",
            [
              !_vm.toDay &&
              !_vm.getCurrentProcedure("departureTime") &&
              _vm.getCurrentProcedure("arrivalTime")
                ? _c("mv-count-down", {
                    class: { isRed: _vm.isRed },
                    attrs: {
                      "show-delay-time": true,
                      "start-time": _vm.getCountDownStartTime(
                        _vm.getCurrentProcedure("arrivalTime"),
                        false
                      ),
                      "end-time": _vm.getCountDownEndTime(
                        _vm.getCurrentProcedure("arrivalTime")
                      ),
                      "day-txt": "天",
                      "hour-txt": "小时",
                      "minutes-txt": "分钟",
                      "seconds-txt": "秒",
                      "is-start": _vm.isStart
                    }
                  })
                : _vm._e(),
              _vm.toDay &&
              !_vm.getCurrentProcedure("departureTime") &&
              _vm.getCurrentProcedure("arrivalTime")
                ? _c("div", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.calcTime(
                            new Date(),
                            _vm.getCurrentProcedure("arrivalTime")
                          )
                        )
                      }
                    })
                  ])
                : _vm._e(),
              _vm.getCurrentProcedure("departureTime") &&
              _vm.getCurrentProcedure("arrivalTime")
                ? _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.calcTime(
                          _vm.getCurrentProcedure("departureTime"),
                          _vm.getCurrentProcedure("arrivalTime")
                        )
                      )
                    )
                  ])
                : _vm._e(),
              !_vm.getCurrentProcedure("arrivalTime")
                ? _c("div", [_vm._v("未进站")])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.slotName === "currentProcedureStation"
        ? _c("div", [
            _vm._v(
              " " + _vm._s(_vm.getCurrentProcedure("checkInStationName")) + " "
            )
          ])
        : _vm._e(),
      _vm.slotName === "currentProcedurePerson"
        ? _c("div", [
            _vm._v(" " + _vm._s(_vm.getCurrentProcedure("checkInUser")) + " ")
          ])
        : _vm._e(),
      _vm.slotName === "currentProcedureGroupName"
        ? _c("div", [
            _vm._v(
              " " + _vm._s(_vm.getCurrentProcedure("procedureGroupName")) + " "
            )
          ])
        : _vm._e(),
      ["planStartTime", "planEndTime", "actualStartTime"].includes(_vm.slotName)
        ? _c("div", [_vm._v(" " + _vm._s(_vm.getPlanTime()) + " ")])
        : _vm._e(),
      _vm.slotName === "remainPlanTime"
        ? _c(
            "div",
            [
              !_vm.toDay &&
              _vm.row.status !== 2 &&
              _vm.row.producePlan.planEndTime
                ? _c("mv-count-down", {
                    class: { isRed: _vm.isRed },
                    attrs: {
                      "show-delay-time": true,
                      "start-time": _vm.getCountDownStartTime(
                        _vm.row.producePlan.planEndTime
                      ),
                      "end-time": _vm.getCountDownEndTime(
                        _vm.row.producePlan.planEndTime
                      ),
                      "day-txt": "天",
                      "hour-txt": "小时",
                      "minutes-txt": "分钟",
                      "seconds-txt": "秒",
                      "is-start": _vm.isStart
                    }
                  })
                : _vm._e(),
              _vm.toDay &&
              _vm.row.status !== 2 &&
              _vm.row.producePlan.planEndTime
                ? _c("div", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.calcTime(
                            _vm.row.producePlan.planEndTime,
                            new Date()
                          )
                        )
                      }
                    })
                  ])
                : _vm._e(),
              _vm.row.status !== 2 && !_vm.row.producePlan.planEndTime
                ? _c("div")
                : _vm._e(),
              _vm.row.status === 2 ? _c("div", [_vm._v("已完成")]) : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.slotName === "remainDeliveryTime"
        ? _c(
            "div",
            [
              !_vm.toDay && _vm.row.status !== 2
                ? _c("mv-count-down", {
                    class: { isRed: _vm.isRed },
                    attrs: {
                      "show-delay-time": true,
                      "start-time": _vm.getCountDownStartTime(
                        _vm.row.deliveryTime.slice(0, 10) + " 23:59:59"
                      ),
                      "end-time": _vm.getCountDownEndTime(
                        _vm.row.deliveryTime.slice(0, 10) + " 23:59:59"
                      ),
                      "day-txt": "天",
                      "hour-txt": "小时",
                      "minutes-txt": "分钟",
                      "seconds-txt": "秒",
                      "is-start": _vm.isStart
                    }
                  })
                : _vm._e(),
              _vm.toDay && _vm.row.status !== 2
                ? _c("div", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.calcTime(
                            _vm.row.deliveryTime.slice(0, 10) + " 23:59:59",
                            new Date()
                          )
                        )
                      }
                    })
                  ])
                : _vm._e(),
              _vm.row.status !== 2 && !_vm.row.producePlan.actualStartTime
                ? _c("div")
                : _vm._e(),
              _vm.row.status === 2 ? _c("div", [_vm._v("已完成")]) : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.slotName === "workOrderDuration"
        ? _c(
            "div",
            [
              !_vm.toDay &&
              !_vm.row.producePlan.actualEndTime &&
              _vm.row.producePlan.actualStartTime
                ? _c("mv-count-down", {
                    class: { isRed: _vm.isRed },
                    attrs: {
                      "show-delay-time": true,
                      "start-time": _vm.getCountDownStartTime(
                        _vm.row.producePlan.actualStartTime,
                        false
                      ),
                      "end-time": _vm.getCountDownEndTime(
                        _vm.row.producePlan.actualStartTime
                      ),
                      "day-txt": "天",
                      "hour-txt": "小时",
                      "minutes-txt": "分钟",
                      "seconds-txt": "秒",
                      "is-start": _vm.isStart
                    }
                  })
                : _vm._e(),
              _vm.toDay &&
              !_vm.row.producePlan.actualEndTime &&
              _vm.row.producePlan.actualStartTime
                ? _c("div", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.calcTime(
                            new Date(),
                            _vm.row.producePlan.actualStartTime
                          )
                        )
                      }
                    })
                  ])
                : _vm._e(),
              _vm.row.producePlan.actualEndTime &&
              _vm.row.producePlan.actualStartTime
                ? _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.calcTime(
                          _vm.row.producePlan.actualEndTime,
                          _vm.row.producePlan.actualStartTime
                        )
                      )
                    )
                  ])
                : _vm._e(),
              !_vm.row.producePlan.actualStartTime
                ? _c("div", [_vm._v("未开始")])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.slotName === "status"
        ? _c("div", { style: { color: _vm.statusColors[_vm.row.status] } }, [
            _vm._v(_vm._s(_vm._f("workStatus")(_vm.row.status)))
          ])
        : _vm._e(),
      _vm.slotName === "getDeepName"
        ? _c("div", [
            _vm._v(
              _vm._s(
                _vm._.get(_vm.row, _vm.column.columnKey || _vm.column.prop)
              )
            )
          ])
        : _vm._e(),
      _vm.slotName === "includeUser"
        ? _c(
            "div",
            _vm._l(_vm.row.info.userList, function(item) {
              return _c(
                "el-tag",
                {
                  key: item.id,
                  staticClass: "user-tags",
                  attrs: { color: "#F6F7F9" }
                },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            1
          )
        : _vm._e(),
      ["availableQuantity", "currentQuantity"].includes(_vm.slotName)
        ? [
            _vm.slotName === _vm.checkNumberKey
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "inline-flex",
                      "align-items": "center"
                    },
                    attrs: { slot: _vm.checkNumberKey },
                    slot: _vm.checkNumberKey
                  },
                  [
                    _c("div", { staticStyle: { display: "inline-block" } }, [
                      _vm._v(_vm._s(_vm.row[_vm.checkNumberKey]))
                    ]),
                    _vm.row.isLimit && _vm.checkNumber(_vm.row)
                      ? _c("div", { staticClass: "tooltip" }, [
                          _vm._v(_vm._s(_vm.checkNumber(_vm.row)))
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm.slotName === _vm.getOtherKey()
              ? _c(
                  "div",
                  {
                    attrs: { slot: _vm.getOtherKey() },
                    slot: _vm.getOtherKey()
                  },
                  [_vm._v(_vm._s(_vm.row[_vm.getOtherKey()]))]
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm.slotName === "procedureRatio"
        ? _c(
            "div",
            { staticClass: "flex-cc", staticStyle: { width: "100%" } },
            [
              _c("el-progress", {
                attrs: {
                  type: "circle",
                  percentage: _vm.row.procedureRatio
                    ? _vm.fixNumber2(_vm.row.procedureRatio)
                    : 0,
                  width: 50,
                  "stroke-width": 5,
                  color: "#607FFF"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.slotName === "monthRange"
        ? _c("div", [
            _vm._v(_vm._s(_vm.row.beginTime) + " ~ " + _vm._s(_vm.row.endTime))
          ])
        : _vm._e(),
      _vm.slotName === "priorityStatus"
        ? _c(
            "div",
            {
              staticClass: "tag-priority",
              style: {
                color: _vm.priorityStatus[_vm.row.priority].color
                  ? _vm.priorityStatus[_vm.row.priority].color
                  : "rgb(74, 79, 118)",
                background:
                  (_vm.priorityStatus[_vm.row.priority].color
                    ? _vm.priorityStatus[_vm.row.priority].color
                    : "rgb(74, 79, 118)") + "20"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.priorityStatus[_vm.row.priority].name
                      ? _vm.priorityStatus[_vm.row.priority].name
                      : "普通"
                  ) +
                  " "
              )
            ]
          )
        : _vm._e(),
      _vm.slotName === "UrHours"
        ? _c("div", [_vm._v(_vm._s(_vm.row.urHours || "-"))])
        : _vm._e(),
      _vm.slotName.includes("listData")
        ? _c("div", [
            _vm._v(
              _vm._s(
                _vm.row[_vm.config.listDataKey][_vm.config.listIndex][
                  _vm.config.listKey || "quantity"
                ]
              )
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }