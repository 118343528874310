var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-local-development" }, [
    _c("div", { staticClass: "detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v("本地开发申请表")]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _vm.isView
              ? _c(
                  "el-row",
                  { staticClass: "view-list", attrs: { gutter: 24 } },
                  _vm._l(_vm.columns, function(item) {
                    return _c(
                      "el-col",
                      { key: item.id, attrs: { span: item.colSpan || 8 } },
                      [
                        item.prop === "partList"
                          ? _c(
                              "div",
                              [
                                _c("div", { staticClass: "view-list__label" }, [
                                  _vm._v(_vm._s(item.label))
                                ]),
                                _c("MTable", {
                                  ref: "descTable",
                                  refInFor: true,
                                  attrs: {
                                    "show-page": false,
                                    height: _vm.height,
                                    columns: _vm.getDetailColumns,
                                    data: _vm.descTableList,
                                    "columns-setting": false
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "index",
                                        fn: function(ref) {
                                          var $index = ref.$index
                                          return _c("div", {}, [
                                            _vm._v(_vm._s($index + 1))
                                          ])
                                        }
                                      },
                                      {
                                        key: "name",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return _c("div", {}, [
                                            _c("div", [
                                              _vm._v(_vm._s(row.name))
                                            ])
                                          ])
                                        }
                                      },
                                      {
                                        key: "parameter",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return _c("div", {}, [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(row.parameter || "-")
                                              )
                                            ])
                                          ])
                                        }
                                      },
                                      {
                                        key: "model",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return _c("div", {}, [
                                            _c("div", [
                                              _vm._v(_vm._s(row.model || "-"))
                                            ])
                                          ])
                                        }
                                      },
                                      {
                                        key: "quantity",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return _c("div", {}, [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(row.quantity || "-")
                                              )
                                            ])
                                          ])
                                        }
                                      },
                                      {
                                        key: "unitPrice",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return _c("div", {}, [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(row.unitPrice || "-")
                                              )
                                            ])
                                          ])
                                        }
                                      },
                                      {
                                        key: "requestUnitPrice",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return _c("div", {}, [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  row.requestUnitPrice || "-"
                                                )
                                              )
                                            ])
                                          ])
                                        }
                                      },
                                      {
                                        key: "delivery",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return _c("div", {}, [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(row.delivery || "-")
                                              )
                                            ])
                                          ])
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          : item.prop === "assembles"
                          ? _c(
                              "div",
                              [
                                _c("div", { staticClass: "view-list__label" }, [
                                  _vm._v(_vm._s(item.label))
                                ]),
                                _c(
                                  "el-upload",
                                  _vm._b(
                                    {
                                      class: { "upload-view": _vm.isView },
                                      attrs: {
                                        "file-list": _vm.getFileListByType(),
                                        disabled: ""
                                      }
                                    },
                                    "el-upload",
                                    _vm.uploadProps,
                                    false
                                  )
                                ),
                                _c(
                                  "el-upload",
                                  _vm._b(
                                    {
                                      class: { "upload-view": _vm.isView },
                                      attrs: {
                                        "file-list": _vm.getFileListByType(1),
                                        disabled: "",
                                        "list-type": "picture"
                                      }
                                    },
                                    "el-upload",
                                    _vm.uploadProps,
                                    false
                                  )
                                )
                              ],
                              1
                            )
                          : _c("div", [
                              _c("div", { staticClass: "view-list__label" }, [
                                _vm._v(_vm._s(item.label))
                              ]),
                              _c("div", { staticClass: "view-list__value" }, [
                                _vm._v(_vm._s(_vm.getValue(item) || "-"))
                              ])
                            ])
                      ]
                    )
                  }),
                  1
                )
              : _c(
                  "MFormBuilder",
                  {
                    ref: "baseForm",
                    attrs: {
                      "form-list": _vm.descFormList,
                      "form-data": _vm.baseFormData,
                      "label-position": "right"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "desc-table",
                        class: { edit: !_vm.isView },
                        attrs: { slot: "partList" },
                        slot: "partList"
                      },
                      [
                        _c("MTable", {
                          ref: "descTable",
                          attrs: {
                            "show-page": false,
                            height: _vm.height,
                            columns: _vm.descColumns,
                            data: _vm.descTableList,
                            "columns-setting": false
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "name",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  { staticClass: "table-cell" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "required-star" },
                                      [_vm._v("*")]
                                    ),
                                    _c("el-input", {
                                      attrs: { placeholder: "输入名称" },
                                      model: {
                                        value: row.name,
                                        callback: function($$v) {
                                          _vm.$set(row, "name", $$v)
                                        },
                                        expression: "row.name"
                                      }
                                    })
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "parameter",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  { staticClass: "table-cell" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "输入型号/参数/PN"
                                      },
                                      model: {
                                        value: row.parameter,
                                        callback: function($$v) {
                                          _vm.$set(row, "parameter", $$v)
                                        },
                                        expression: "row.parameter"
                                      }
                                    })
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "model",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  { staticClass: "table-cell" },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "输入适用SCR机型" },
                                      model: {
                                        value: row.model,
                                        callback: function($$v) {
                                          _vm.$set(row, "model", $$v)
                                        },
                                        expression: "row.model"
                                      }
                                    })
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "quantity",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  { staticClass: "table-cell" },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "输入数量/套" },
                                      model: {
                                        value: row.quantity,
                                        callback: function($$v) {
                                          _vm.$set(row, "quantity", $$v)
                                        },
                                        expression: "row.quantity"
                                      }
                                    })
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "unitPrice",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  { staticClass: "table-cell" },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "输入原单价/元" },
                                      model: {
                                        value: row.unitPrice,
                                        callback: function($$v) {
                                          _vm.$set(row, "unitPrice", $$v)
                                        },
                                        expression: "row.unitPrice"
                                      }
                                    })
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "requestUnitPrice",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  { staticClass: "table-cell" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "输入要求单价（含税）/元"
                                      },
                                      model: {
                                        value: row.requestUnitPrice,
                                        callback: function($$v) {
                                          _vm.$set(row, "requestUnitPrice", $$v)
                                        },
                                        expression: "row.requestUnitPrice"
                                      }
                                    })
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "delivery",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  { staticClass: "table-cell" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "输入要求交货期/天"
                                      },
                                      model: {
                                        value: row.delivery,
                                        callback: function($$v) {
                                          _vm.$set(row, "delivery", $$v)
                                        },
                                        expression: "row.delivery"
                                      }
                                    })
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "action",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c(
                                  "div",
                                  {},
                                  [
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.delTableList($index)
                                            }
                                          }
                                        },
                                        [_vm._v("移除")]
                                      )
                                    ]
                                  ],
                                  2
                                )
                              }
                            }
                          ])
                        }),
                        !_vm.isView
                          ? _c(
                              "el-button",
                              {
                                staticClass: "e-btn",
                                attrs: { size: "small", type: "primary" },
                                on: { click: _vm.addTableList }
                              },
                              [_vm._v("添加")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-upload",
                      _vm._b(
                        {
                          class: { "upload-view": _vm.isView },
                          attrs: {
                            slot: "drawing",
                            "file-list": _vm.attachments
                          },
                          slot: "drawing"
                        },
                        "el-upload",
                        _vm.uploadProps,
                        false
                      ),
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", size: "small" } },
                          [_vm._v("上传文件")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "detail-footer" }, [
      _c(
        "div",
        { staticClass: "footer-ri" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.returns } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.buttonLoading,
                size: "small"
              },
              on: { click: _vm.submitForm }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }