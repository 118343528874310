<template>
    <div>
        <div v-for="items in showDeviceParametersList" :key="items.name">
            <div v-if="items.name != 'OEE' && items.name != '设备状态'" class="centerText">
                <div>{{ items.name }}：</div>
                <div>
                    <span>{{ items.value }}</span>
                    <span style="font-size:14px;margin-left: 2px">{{ items.unit }}</span>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    name: 'paramsDetail',
    props: {
        deviceParametersList: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            showDeviceParametersList: [],
            timer: null
        }
    },
    watch: {
        // 监听父组件传递过来的数据
        deviceParametersList: {
            handler(newValue, oldValue) {
                let randomNumber = Math.random();
                // 在这里执行你想要的代码
                // console.log('parentData 变化了:', newValue);
                // console.log('deviceParametersList:', this.deviceParametersList)
                if (this.deviceParametersList.length > 4) {

                    // 初始化索引和定时器
                    let index = 0;
                    const intervalTime = 10000 + (1000 * randomNumber); // 每秒一次
                    const maxItemsPerSecond = 4;
                    if (this.timer) {
                        clearInterval(this.timer)
                    }
                    console.log('setinterval')
                    // 获取当前索引开始的四条数据
                    this.showDeviceParametersList = this.deviceParametersList.slice(index, index + maxItemsPerSecond);
                    // 更新索引
                    index += maxItemsPerSecond;
                    // 如果索引超过数组长度，重置索引
                    if (index >= this.deviceParametersList.length) {
                        index = 0;
                    }
                    this.timer = setInterval(() => {
                        console.log('setinterval')
                        // 获取当前索引开始的四条数据
                        this.showDeviceParametersList = this.deviceParametersList.slice(index, index + maxItemsPerSecond);
                        // 更新索引
                        index += maxItemsPerSecond;
                        // 如果索引超过数组长度，重置索引
                        if (index >= this.deviceParametersList.length) {
                            index = 0;
                        }
                    }, intervalTime);
                } else {
                    this.showDeviceParametersList = this.deviceParametersList
                }
            },
            deep: true
        }

    },
    mounted() {
        let randomNumber = Math.random();
        // 在这里执行你想要的代码
        if (this.deviceParametersList.length > 4) {

            // 初始化索引和定时器
            let index = 0;
            const intervalTime = 10000 + (1000 * randomNumber); // 每秒一次
            const maxItemsPerSecond = 4;
            if (this.timer) {
                clearInterval(this.timer)
            }
            console.log('setinterval')
            // 获取当前索引开始的四条数据
            this.showDeviceParametersList = this.deviceParametersList.slice(index, index + maxItemsPerSecond);
            // 更新索引
            index += maxItemsPerSecond;
            // 如果索引超过数组长度，重置索引
            if (index >= this.deviceParametersList.length) {
                index = 0;
            }
            this.timer = setInterval(() => {
                console.log('setinterval')
                // 获取当前索引开始的四条数据
                this.showDeviceParametersList = this.deviceParametersList.slice(index, index + maxItemsPerSecond);
                // 更新索引
                index += maxItemsPerSecond;
                // 如果索引超过数组长度，重置索引
                if (index >= this.deviceParametersList.length) {
                    index = 0;
                }
            }, intervalTime);
        } else {
            this.showDeviceParametersList = this.deviceParametersList
        }
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }
}
</script>
<style scoped lang="scss">
.centerText {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}
</style>
