import { warehouseRecordTypeAll } from '@/config/options.sales'
export const searchFormList = [
  {
    key: 'type',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '库存操作类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: warehouseRecordTypeAll
      }
    ]
  },
  {
    key: 'orderNumber',
    tagName: 'el-input',
    colSpan: 6,
    attrs: {
      placeholder: '关联单号'
    }
  },
  {
    key: 'warehouseId',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '涉及仓库'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'keyword',
    tagName: 'div',
    defaultValue: '',
    domProps: { className: 'customer-content2' },
    children: [
      {
        slots: ['materialsM']
      }
    ]
  },

]
