<template>
  <div class="product-container">
    <div class="top">
      <div class="left">
        <div class="t-top">
          <div>
            <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.dayQuality, ['#A2FFC9', '#009A25'])" style="width: 223px;height: 80px" /></div>
            <div class="card-bottom">
              <img src="https://oss.mubyte.cn/static/%E6%97%A5%402x.png" alt="">
              <span v-if="memberCode === 'bbelc'">当天总装配报工数量</span>
              <span v-else>当日产量</span>
            </div>
          </div>
          <div>
            <div class="card-top"><dv-digital-flop :config="getDigitalConfig(digital.weekQuality, ['#B9FFFD', '#009186'])" style="width: 223px;height: 80px" /></div>
            <div class="card-bottom">
              <img src="https://oss.mubyte.cn/static/%E5%91%A8%402x.png" alt="">
              <span v-if="memberCode === 'bbelc'">当月总装配报工数量</span>
              <span v-else>当周产量</span>
            </div>
          </div>
        </div>
        <div class="t-bottom">
          <div class="gradient-title" style="background: linear-gradient(90deg, #00751F 0%, rgba(96, 127, 255, 0) 100%);">7日内产量趋势图</div>
          <ve-histogram
            height="300px"
            :data="productionChartData"
            :settings="barChartSettings"
            :extend="barChartExtend"
          />
        </div>
      </div>
      <div class="center">
        <div class="t-top">
          <div class="work-order-left">本周计划完成工单</div>
          <div class="work-order-right">
            <div>
              <span>工单数</span>
              <span><span>{{ digital.finishedCount }}</span><span>/</span>{{ digital.workOrderCount }}</span>
            </div>
            <div>
              <span>产品量</span>
              <span><span>{{ digital.finishedQuantity }}</span><span>/</span>{{ digital.workOrderQuantity }}</span>
            </div>
          </div>
        </div>
        <div class="t-bottom">
          <div class="work-order-delay">
            <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">工单到期情况</div>
            <ve-pie
              height="300px"
              :settings="pieChartSettings"
              :extend="pieChartExtend"
              :data="workOrderDelayChartData"
            />
          </div>
          <div class="plan-finish-seven">
            <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">7日内计划达成情况</div>
            <ve-line
              height="300px"
              :data="planFinishChartData"
              :settings="lineChartSettings"
              :extend="lineChartExtend"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="t-top">
          <div class="work-order-left">本周新增返修工单</div>
          <div class="work-order-right">
            <div>
              <span>工单数</span>
              <span><span>{{ digital.finishedCountRepair }}</span><span>/</span>{{ digital.workOrderCountRepair }}</span>
            </div>
            <div>
              <span>产品量</span>
              <span><span>{{ digital.finishedQuantityRepair }}</span><span>/</span>{{ digital.workOrderQuantityRepair }}</span>
            </div>
          </div>
        </div>
        <div class="t-bottom">
          <div class="gradient-title" style="background: linear-gradient(90deg, #0E8BAF 0%, rgba(96, 127, 255, 0) 100%);">本周生产异常类型分布</div>
          <ve-pie
            height="300px"
            :settings="pieChartSettings2"
            :extend="pieChartExtend2"
            :data="errorTypeChartData"
          />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="product-task">
        <div class="gradient-title" style="background: linear-gradient(90deg, #00751F 0%, rgba(96, 127, 255, 0) 100%);">生产任务<span>（{{ productTaskDataTotal }}）</span></div>
        <scroll-board :config="scrollBoardConfig" :style="{width:'100%', height: tableHeight + 'px'}">
          <span slot="currentProcedure" slot-scope="{ index }">
            {{ productTaskData[index].produceWorkOrderTaskList ? productTaskData[index].produceWorkOrderTaskList[0].procedureName : '' }}
          </span>
          <span slot="priority" slot-scope="{ index }">
            {{ priorityStatus[productTaskData[index].priority].name ? priorityStatus[productTaskData[index].priority].name : '普通' }}
          </span>
        </scroll-board>
      </div>
      <div class="error-task">
        <div class="gradient-title" style="background: linear-gradient(90deg, #0E8BAF 0%, rgba(96, 127, 255, 0) 100%);">异常任务<span>（{{ errTaskData.length }}）</span></div>
        <scroll-board :config="scrollBoardConfig2" :style="{width:'100%', height: tableHeight + 'px'}">
          <span slot="emergencyDegree" slot-scope="{ index }">
            {{ errTaskData[index].emergencyDegree ? '普通' : '紧急' }}
          </span>
          <span slot="status" slot-scope="{ index }" class="status-board" :style="{ background: statusList[errTaskData[index].status].color }">{{ statusList[errTaskData[index].status].name }}</span>
        </scroll-board>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/statistics-board/all-statistics'
import mix from './mixin'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
import dayjs from 'dayjs'
import { baseUrl, colorList } from './base-setting'
import { getDateRange } from '@/views/statistics-board/board-manage/all-statistics/statistics-card-copy'
const scrollBoardSettingDefault = {
  carousel: 'page',
  headerHeight: 33,
  hoverPause: false,
  waitTime: 10000,
  headerBGC: '#222222',
  oddRowBGC: '#222222',
  evenRowBGC: 'transparent'
}
export default {
  name: 'Product',
  components: { scrollBoard },
  mixins: [mix],
  data() {
    return {
      statusList: [
        {
          id: 0,
          name: '待处理',
          color: '#4B1E00',
          background: 'rgba(250, 100, 0, 0.1)'
        },
        {
          id: 1,
          name: '处理中',
          color: '#00371A',
          background: 'rgba(0, 171, 41, 0.1)'
        },
        {
          id: 2,
          name: '已处理',
          color: '#9597AE',
          background: 'rgba(149, 151, 174, 0.1)'
        }
      ],
      digital: {
        dayQuality: 0,
        weekQuality: 0,
        workOrderCount: 0,
        finishedCount: 0,
        workOrderQuantity: 0,
        finishedQuantity: 0,
        workOrderCountRepair: 0,
        finishedCountRepair: 0,
        workOrderQuantityRepair: 0,
        finishedQuantityRepair: 0
      },
      productionChartData: {
        columns: ['日期', '产量'],
        rows: []
      },
      workOrderDelayChartData: {
        columns: ['日期', '数量'],
        rows: []
      },
      planFinishChartData: {
        columns: ['日期', '达成率'],
        rows: []
      },
      pieChartSettings2: {},
      pieChartExtend2: {
        color: colorList,
        legend: {
          top: 15,
          show: true,
          orient: 'horizontal',
          left: 10,
          textStyle: {
            width: 10,
            color: '#fff',
            fontSize: 14,
            left: 20,
            overflow: 'break'
          }
        },
        series: {
          top: 40,
          radius: ['52%', '70%'],
          center: ['50%', '50%'],
          label: {
            formatter: '\n\n {b} ',
            padding: [0, -25],
            alignTo: 'labelLine',
            color: '#fff'
          },
          labelLine: {
            // length2: 40
          }
        },
        tooltip: {
          trigger: 'item'
          // appendToBody: true
        }
      },
      errorTypeChartData: {
        columns: ['类型', '数量'],
        rows: []
      },
      tableHeight: 100,
      scrollBoardConfig: {
        ...scrollBoardSettingDefault,
        columnWidth: [60, 210, 150, 150, 100, 100],
        header: ['序号', '单号', '产品名称', '物料规格', '优先级', '工单数量', '当前工序', '交期'],
        data: []
      },
      scrollBoardConfig2: {
        ...scrollBoardSettingDefault,
        columnWidth: [60, 111, 250, 100, 100],
        header: ['序号', '呼叫类型', '呼叫备注', '紧急程度', '处理状态', '发生时间'],
        data: []
      },
      productTaskData: [],
      errTaskData: [],
      productTaskDataTotal: 0,
      scrollBoardSettingDefault
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  mounted() {
    this.reloadData()
  },
  methods: {
    reloadData(areaIds = '') {
      const date = getDateRange(6, 'YYYY-MM-DD')
      this.getTableData(baseUrl + 'getProduceWorkOrderList', {
        statusList: '0^1',
        areaIds
      }, {
        repeat: true,
        key: 'scrollBoardConfig',
        key2: 'productTaskData',
        columns: this.scrollBoardConfig.header,
        columnsKey: ['index', 'produceWorkOrderNumber', 'materialsName', 'materialsSpecifications', 'slot:priority', 'quantity', 'slot:currentProcedure', 'deliveryTime'],
        columnWidth: [60, 210, 150, 150, 100, 100]
      })
      this.getTableData(baseUrl + 'getCallTypeListForDisplayBoard', {
        beginTime: date[0],
        endTime: date[1],
        areaIds
      }, {
        key: 'scrollBoardConfig2',
        key2: 'errTaskData',
        columns: this.scrollBoardConfig2.header,
        columnsKey: ['index', 'callTypeName', 'callRemark', 'slot:emergencyDegree', 'slot:status', 'createTime'],
        columnWidth: [60, 111, 300, 100, 100]
      })
      this.areaIds = areaIds
      if (this.memberCode === 'bbelc') {
        this.getProductQualitys() // 当月
      }
      this.getProductQuality()
      this.getWorkOrderInfo(date)
      this.getRepairWorkOrderInfo(date)
      this.getWorkOrderDelayInfo()
      this.getPlanFinishInfo()
      this.getErrorTypeChartData(date)
    },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.tableHeight = (window.innerHeight * 0.32) / scale
    },
    async getProductQuality() {
      // 如果是周日dayjs会计算为下一周
      const currentDay = dayjs().day()
      const res = await api.getTableData(baseUrl + 'getReportCountForDisplayBoard', {
        beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        endTime: currentDay === 0 ? dayjs().format('YYYY-MM-DD') : dayjs(dayjs().day(7)).format('YYYY-MM-DD'),
        areaIds: this.memberCode === 'bbelc' ? '164' : this.areaIds
      })
      if (res) {
        const currentDay = dayjs().format('YYYY-MM-DD')
        const currentWeekData = res.slice(-7)
        const currentDayIndex = res.findIndex(item => item.day === currentDay)
        const sevenDayData = res.slice(0, currentDayIndex + 1)
        this.digital.dayQuality = res.find(item => item.day === currentDay).quantity
        if (this.memberCode !== 'bbelc') {
          this.digital.weekQuality = currentWeekData.reduce((a, b) => a + Number(b.quantity), 0)
        }
        this.productionChartData.rows = sevenDayData.map(item => ({
          '日期': item.day.slice(-5),
          '产量': Number(item.quantity)
        }))
        console.log('this.productionChartData==', this.productionChartData)
      }
    },
    async getProductQualitys() {
      const currentDay = dayjs().day()
      const res = await api.getTableData(baseUrl + 'getReportCountForDisplayBoard', {
        beginTime: dayjs().startOf('month').format('YYYY-MM-DD'),
        endTime: dayjs().endOf('month').format('YYYY-MM-DD'),
        areaIds: '164'
      })
      if (res) {
        this.digital.weekQuality = res.reduce((a, b) => a + Number(b.quantity), 0)
      }
    },
    async getWorkOrderInfo(date) {
      const res = await api.getTableData(baseUrl + 'getWorkOrderCountForDisplayBoard', {
        beginTime: date[0],
        endTime: date[1],
        areaIds: this.areaIds
      })
      if (res) {
        Object.assign(this.digital, res)
      }
    },
    async getRepairWorkOrderInfo(date) {
      const res = await api.getTableData(baseUrl + 'getRepairWorkOrderCountForDisplayBoard', {
        beginTime: date[0],
        endTime: date[1],
        areaIds: this.areaIds
      })
      if (res) {
        Object.keys(res).forEach(key => {
          this.digital[key + 'Repair'] = res[key]
        })
      }
    },
    async getWorkOrderDelayInfo() {
      const res = await api.getTableData(baseUrl + 'getWorkOrderStatusForDisplayBoard', {
        areaIds: this.areaIds
      })
      if (res) {
        this.workOrderDelayChartData.rows = [
          { '日期': '延期', '数量': res.delayCount },
          { '日期': '3天后延期', '数量': res.delayCountFor3days }
        ]
      }
    },
    async getPlanFinishInfo() {
      const res = await api.getTableData(baseUrl + 'getWorkOrderRatioForDisplayBoard', {
        beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD'),
        areaIds: this.areaIds
      })
      if (res) {
        this.planFinishChartData.rows = res.map(item => ({
          '日期': item.day.slice(-5),
          '达成率': Number(item.finishedRatio)
        }))
      }
    },
    async getErrorTypeChartData(date) {
      const res = await api.getTableData(baseUrl + 'getCallTypeTotalForDisplayBoard', {
        beginTime: date[0],
        endTime: date[1],
        areaIds: this.areaIds
      })
      if (res) {
        this.errorTypeChartData.rows = res.map(item => ({
          '类型': item.info,
          '数量': Number(item.quantity)
        }))
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./common";
.product-container {
  padding: 0 20px 20px 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  .top {
    display: flex;
    justify-content: space-between;
    .left {
      width: 591px;
      .t-top {
        display: inline-flex;
        >div {
          width: 289px;
          border-radius: 4px;
          .card-top {
            display: flex;
            justify-content: center;
          }
          .card-bottom {
            padding: 14px;
            background: rgba(0, 0, 0, 0.29);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img  {
              width: 34px;
              padding-right: 10px;
            }
            span {
              font-size: 23px;
            }
          }
          &:first-child {
            margin-right: 13px;
            background: #07261D;
            .card-bottom {
              span {
                color: #2CBE4F;
              }
            }
          }
          &:last-child {
            background: #072126;
            .card-bottom {
              span {
                color: #00D3C4;
              }
            }
          }
        }
      }
      .t-bottom {
        margin-top: 38px;
      }
    }
    .center, .right {
      .t-top {
        height: 129px;
        display: flex;
        .work-order-left {
          width: 117px;
          font-size: 19px;
          color: #C3CFFF;
          text-align: center;
          line-height: 24px;
          padding: 38px 13px 0 19px;
          background: #032959;
        }
        .work-order-right {
          div {
            width: 635px;
            height: 50%;
            background: #061E3C;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            >span:first-child {
              color: #C3CFFF;
              font-size: 23px;
            }
            >span {
              font-size: 23px;
              color: rgba(96, 127, 255, 1);
              span:first-child {
                font-size: 30px;
                color: #FFFFFF;
              }
              span:nth-child(2) {
                padding: 0 3px;
              }
            }
            &:first-child {
              border-bottom: 1px solid rgba(96, 127, 255, 1);
            }
          }
        }
      }
      .t-bottom {
        margin-top: 38px;
        display: flex;
        .work-order-delay {
          width: 276px;
        }
        .plan-finish-seven {
          width: 477px;
        }
      }
    }
    .right {
      .t-top {
        .work-order-right {
          div {
            width: 325px !important;
          }
        }
      }
      .t-bottom {
        display: block;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    >div {
      width: 878px;
      .gradient-title {
        margin-bottom: 10px;
        span {
          font-size: 14px;
        }
      }
    }
    .product-task{
      width: 1100px;
    }
  }
}
::v-deep .dv-scroll-board .ceil {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  >span {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.status-board {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 44px;
}
</style>
