export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'code',
    label: '服务订单编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code'
  },
  {
    prop: 'orderNumber',
    label: '关联单号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'orderNumber'
  },
  {
    prop: 'serviceContent',
    label: '服务内容',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'consumer',
    label: '客户',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'guaranteePeriodStart',
    label: '服务期限',
    sortable: false,
    hiddenSearch: true,
    width: 280,
    slotName: 'guaranteePeriodStart'
  },
  {
    prop: 'laborCost',
    label: '人工费归属',
    hiddenSearch: true,
    sortable: false,
    slotName: 'laborCost'
  },
  {
    prop: 'sparePartsCost',
    label: '备件费归属',
    hiddenSearch: true,
    sortable: false,
    slotName: 'sparePartsCost'
  },
  {
    prop: 'consumptionOfProductCost',
    label: '耗品费归属',
    hiddenSearch: true,
    sortable: false,
    slotName: 'consumptionOfProductCost'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 180
  }
]
