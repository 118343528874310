
export const equipmentColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'code',
    label: '设备编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'name',
    label: '设备名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'sn',
    label: 'SN',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'model',
    label: '型号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'equipmentClassName',
    label: '分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]
export const workColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'workOrderCode',
    label: '工单编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    slotName: 'workOrderCode'
  },
  {
    prop: 'workOrderTypeName',
    label: '工单类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'changeType',
    label: '类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'changeType'
  },
  {
    prop: 'upFacilityCode',
    label: '换上设备编号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'upFacilityName',
    label: '换上设备名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'upFacilitySn',
    label: '换上设备SN',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'facilityCode',
    label: '换下设备编号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'facilityName',
    label: '换下设备名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'facilitySn',
    label: '换下设备SN',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'changeWorkRecord',
    label: '工作记录',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'executionStartTime',
    label: '服务时间',
    hiddenSearch: true,
    sortable: false,
    slotName: 'executionStartTime',
    minWidth: 350
  },
  {
    prop: 'executorName',
    label: '执行人',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'creator',
    label: '填单人',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 200
  }
]
export const workMulColumns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'workOrderCode',
    label: '工单编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'facilityCode',
    label: '设备编号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'facilityName',
    label: '设备名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'facilitySn',
    label: 'SN',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'executionStartTime',
    label: '服务时间',
    hiddenSearch: true,
    sortable: false,
    slotName: 'executionStartTime',
    minWidth: 280
  },
  {
    prop: 'executorName',
    label: '执行人',
    hiddenSearch: true,
    sortable: false
  }
]
