<template>
  <div class="memberRouteSetting_container" :style="{height: chromeHeight + 'px'}">
    <div class="container_left">
      <h2>租户信息</h2>
      <template>
        <div v-for="(item,index) in userList" :class="{
          userItem:true,active: index === curtenIndex
        }"
             @click="viewCheckItem(index)">
          {{ item.memberName }} - {{ item.memberCode }}
        </div>
      </template>
    </div>
    <div class="container_right">
      <div class="model-wrap-right common-tab">
        <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
          <el-radio-button label="Role">功能权限</el-radio-button>
          <el-radio-button label="User">关联用户</el-radio-button>
        </el-radio-group>
      </div>
      <div v-if="params.radio === 'Role'" style="margin: 10px 20px;">
        <div class="all-btns">
          <label class="label">网页端功能权限</label>
          <el-button :loading="loading" size="small" type="primary" class="role-btn" @click="saveRole">保存</el-button>
        </div>
        <el-tree
          v-if="openPanel"
          ref="tree"
          show-checkbox
          node-key="name"
          :data="webRoutes"
          :props="defaultProps"
          :default-expanded-keys="['all']"
          :default-checked-keys="authWeb"
          :render-content="renderContent"
        />
        <div class="el-form-item">
          <label class="el-form-item__label">移动端功能权限</label>
        </div>
        <el-tree
          v-if="openPanelApp"
          ref="treeApp"
          show-checkbox
          node-key="name"
          :data="appRoutes"
          :props="defaultProps"
          :default-expanded-keys="['全部']"
          :default-checked-keys="authApp"
          :render-content="renderContentApp"
        />
      </div>
      <div v-if="params.radio === 'User'" style="margin: 10px 20px;">
        <el-table
          :data="showUsers"
          height="700"
          border
          style="width: 100%">
          <el-table-column
            prop="jobNumber"
            label="工号">
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名">
          </el-table-column>
          <el-table-column
            prop="userName"
            label="用户名">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机号码">
          </el-table-column>
          <el-table-column
            prop="email"
            label="邮箱">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/tenant'
import { constantRoutes } from '@/router'
import { appMenu } from '@/views/setting/role/columns'
import baseSettingApi from '@/api/sets/base-setting'
import DownCode from '@/views/login/downCode.vue'
import {setRouteUserNameMd5,setRoutePasswordMd5} from '@/settings'
import { Encrypt } from '@/utils/sercet'

export default {
  name: 'MemberRouteSet',
  components: { DownCode },
  data() {
    return {
      loginForm:{
        userName: '',
        password: ''
      },
      hadLogin: false,
      userList: [],
      curtenIndex: 0,
      webRoutes: [],
      appRoutes: [],
      authWeb: [],
      authApp: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      params: {
        radio: 'Role'
      },
      openPanel: false,
      openPanelApp: false,
      judgeWeb:[],
      judgeApp:[],
      showUsers:[],
      loading: false,
      selectedUserMsg:{}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192 - 35
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    chromeHeight() {
      return document.documentElement.clientHeight
    }
  },
  async created() {
    const res = await api.getTenantListPage2({
      page: 1,
      limit: 100
    })
    if (res) {
      // consoleSelf.info('获取到所有的租户信息', res.content)
      this.userList = res.records
      this.viewCheckItem(0)
    }
  },
  async beforeMount() {
    debugger
    if(localStorage.getItem("wc") === '1'){
      this.webRoutes = [
        {
          name: 'all',
          meta: { title: '全部' },
          children: constantRoutes.filter((item) => !item.hidden)
        }
      ]
      this.webRoutes = JSON.parse(JSON.stringify(this.webRoutes))
      this.handleAll(this.webRoutes)
      consoleSelf.info("web端路由初始化数据",JSON.stringify(this.webRoutes))
      localStorage.removeItem("wc")
    }

    const baseRes = await api.getBaseConfigSelf({
      code:'web_all_route_init'
    })
    if (baseRes) {
      this.webRoutes = JSON.parse(baseRes.value)
    }else {
      this.webRoutes = []
    }

    this.handleWeb(this.webRoutes[0])
    this.appRoutes = [
      {
        name: '全部',
        children: appMenu
      }
    ]
    this.handleApp(this.appRoutes[0])
  },
  methods: {
    handleLogin(){
      console.log(this.loginForm)
      if(!this.loginForm.userName || !this.loginForm.password){
        this.$message.error('请输入账号和密码')
        return false;
      }
      const userName = Encrypt(this.loginForm.userName)
      const userPwd = Encrypt(this.loginForm.password)
      if(userName === setRouteUserNameMd5 && userPwd === setRoutePasswordMd5){
        this.$message.success('登录成功')
        this.hadLogin = true
      }else {
        this.$message.error('账号或密码错误')
        return false;
      }
    },
    handleAll(obj){
      if(obj && obj.length > 0){
        obj.forEach(item => {
          delete item.path
          delete item.redirect
          delete item.hidden
          if(item.meta){
            delete item.meta.activeMenu
            delete item.meta.icon
            delete item.meta.noCache
            delete item.meta.nameFlage
            delete item.meta.canLink
            delete item.meta.nb
            delete item.meta.buttons
          }
          if(item.children){
            this.handleAll(item.children)
          }
        })
      }
    },
    handleWeb(obj){
      if(obj.children && obj.children.length > 0){
        obj.children.forEach(item => {
          this.handleWeb(item)
        })
      }else {
        this.judgeWeb.push(obj.name)
      }
    },
    handleApp(obj){
      if(obj.children && obj.children.length > 0){
        obj.children.forEach(item => {
          this.handleApp(item)
        })
      }else {
        this.judgeApp.push(obj.name)
      }
    },
    renderContent(h, { data }) {
      return h('span', data.meta ? data.meta.title ? data.meta.title : data.meta.roleTitle : data.title ? data.title : data.roleTitle)
    },
    renderContentApp(h, { data }) {
      return h('span', data.name)
    },
    async viewCheckItem(index) {
      this.curtenIndex = index
      const users = await this.getUserList(this.userList[index].id)
      this.showUsers = users
      const userRoute = await this.getTenatDetail(this.userList[index].id)
      this.selectedUserMsg = userRoute
      // consoleSelf.info('获取到当前租户的权限信息', userRoute)
      const originMsg = JSON.parse(userRoute.value) || ''
      if (originMsg) {
        const { web, app } = originMsg
        const finalWeb = web.filter(item => this.judgeWeb.includes(item))
        const finalApp = app.filter(item => this.judgeApp.includes(item))
        this.authWeb = finalWeb
        this.authApp = finalApp
        this.openPanel = false
        this.openPanelApp = false
        this.$nextTick(() => {
          this.openPanel = true
          this.openPanelApp = true
        })
      }
    },
    async getTenatDetail(id) {
      const res = await api.getTenantDetail2({
        id
      })
      if (res) {
        return res.basicConfig
      } else {
        return null
      }
    },
    async getUserList(id) {
      const res = await api.getTenantUserListPage2({
        page: 1,
        limit: 100,
        id
      })
      if (res) {
        return res.records
      } else {
        return []
      }
    },
    radioChange(type) {
      this.params.radio = type
    },
    async saveRole() {
      this.loading = true
      const checked = this.$refs.tree.getCheckedNodes().map((item) => {
        return item.name
      })
      const half = this.$refs.tree.getHalfCheckedNodes().map((item) => {
        return item.name
      })
      const finalRouteWeb = [...checked, ...half]
      // consoleSelf.info('最终web端权限信息', finalRouteWeb)

      const checkedApp = this.$refs.treeApp.getCheckedNodes().map((item) => {
        return item.name
      })
      const halfApp = this.$refs.treeApp.getHalfCheckedNodes().map((item) => {
        return item.name
      })
      const finalRouteApp = [...checkedApp, ...halfApp]
      // consoleSelf.info('最终app端权限信息', finalRouteApp)

      const routeInfo = {
        web: finalRouteWeb,
        app: finalRouteApp
      }
      const res = await baseSettingApi.modifySingleSetting2({
        id: this.selectedUserMsg.id,
        code: this.selectedUserMsg.code,
        value: JSON.stringify(routeInfo),
        memberCode: this.selectedUserMsg.memberCode
      })
      if (res) {
        this.$message.success('保存成功')
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.memberRouteSetting_container {
  width: 100%;
  box-sizing: border-box;
  display: flex;

  .container_left {
    width: 30%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 10px;

    .userItem {
      height: 45px;
      padding-left: 15px;
      font-size: 15px;
      line-height: 45px;
      cursor: pointer;

      &.active {
        color: #FFFFFF;
        background: #717bdf;
        font-weight: bold;
      }
    }
  }

  .container_right {
    width: 70%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;

    .all-btns {
      display: flex;
      justify-content: space-between;

      .label {
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      .role-btn {
        height: 32px;
        margin-top: 4px;
      }
    }
  }
}
</style>

