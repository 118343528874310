<template>
  <div class="unit-wrap">
    <div class="top">
      <SearchForm ref="searchForm" :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" />
    </div>
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入服务订单号/关联单号"
        :tags="!exportSelectionIds.length ? functionHeader.tags : []"
        :columns-key="columnsKey"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all" v-show="exportSelectionIds.length">
          <el-button v-if="permission('Del')" type="primary" size="small" @click="allMore('delBatchServiceOrder')">批量删除</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable
        ref="mTable"
        :columns="columns"
        :height="height"
        :columns-key="columnsKey"
        :set-data-method="getTableData"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <el-button slot="code" slot-scope="{ row }" type="text" @click="viewRepairs(row)">{{ row.code }}</el-button>
        <div v-if="row.orderNumber" slot="orderNumber" slot-scope="{ row }" type="text">{{ row.orderNumber }}</div>
        <div v-else slot="orderNumber" type="text">{{ '-' }}</div>
        <div slot="guaranteePeriodStart" slot-scope="{ row }">{{ getDayDesc(row.guaranteePeriodStart, row.guaranteePeriodEnd) }}</div>
        <div slot="laborCost" slot-scope="{ row }" type="text">{{ quanlityInfoFeeType[row.laborCost].name }}</div>
        <div slot="sparePartsCost" slot-scope="{ row }" type="text">{{ quanlityInfoFeeType[row.sparePartsCost].name }}</div>
        <div slot="consumptionOfProductCost" slot-scope="{ row }" type="text">{{ quanlityInfoFeeType[row.consumptionOfProductCost].name }}</div>

        <div slot="action" slot-scope="{ row }">
          <template>
            <el-button type="text" @click="modifyRepairs(row)">修改</el-button>
            <el-divider direction="vertical" />
            <DelPopover @onOk="callback => del(row, callback)" />
          </template>
        </div>
      </MTable>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DelPopover from '@/components/DelPopover/index'
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns } from './columns'
import { formList, searchFormList } from './form-list'
import batchExportFront from '@/mixin/batch-export-front'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/xiewei/service/service-order'
import keApi from '@/api/information/unit'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { quanlityInfoFeeType } from '@/config/options.sales'

export default {
  name: 'ServiceOrderList',
  components: { DelPopover, FunctionHeader, SearchForm },
  mixins: [batchExportFront, conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'ServiceOrder'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ]
      },
      columns,
      formList,
      formData: {},
      searchFormData: {},
      searchFormatData: [],
      searchFormList,
      searchKeyword: true,
      quanlityInfoFeeType,
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 15
    },
    getDayDesc() {
      return (start, end) => {
        const format = 'YYYY年MM月DD日'
        if (start && end) {
          const isSameYear = dayjs(start).isSame(end, 'year')
          if (isSameYear) {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format('MM月DD日')}`
          } else {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format(format)}`
          }
        } else if (start) {
          return `${dayjs(start).format(format)}`
        } else if (end) {
          return `${dayjs(end).format(format)}`
        }
        return '-'
      }
    },
    getTableDataParams() {
      const { form } = this.$refs.searchForm
      return {
        // executorIds: (form.executor || []).join(','),
        serviceContent: (form.serviceContent || []).join('、'),
        guaranteePeriodStart: (form.guaranteePeriodStart || [])[0],
        guaranteePeriodEnd: (form.guaranteePeriodStart || [])[1]
      }
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.exportSelectionList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },

  mounted() {
    this.getUser()
  },

  methods: {
    resetSelectData() {
      this.exportSelectionList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
    },
    allMore(command) {
      const functions = {
        delBatchServiceOrder: () => {
          this.delBatchServiceOrder()
        }
      }
      functions[command]()
    },

    // 获取客户
    async getUser() {
      const res = await keApi.getCustomerList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.searchFormList[1].children[0].options = res
      }
    },

    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    },

    add() {
      this.$router.push({ name: 'addServiceOrder', query: { type: 'add' }})
    },

    modifyRepairs(row) {
      this.$router.push({ name: 'addServiceOrder', query: { id: row.id, type: 'modify' }})
    },

    viewRepairs(row) {
      this.$router.push({ name: 'addServiceOrder', query: { id: row.id, type: 'view' }})
    },

    // viewDemandCenters(row) {
    //   this.$router.push({ name: 'demandCenterDetail', query: { id: row.id } })
    // },

    delBatchServiceOrder(row) {
      this.$confirm('确定要删除选择工单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delBatchServiceOrder(this.exportSelectionIds)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData(true)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.unit-wrap {
  padding: 0 10px 10px 10px;
}
</style>
