var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          ref: "popover",
          attrs: { width: 207, offset: 20, trigger: "manual" },
          on: { show: _vm.popoverShowEvent },
          model: {
            value: _vm.showPopover,
            callback: function($$v) {
              _vm.showPopover = $$v
            },
            expression: "showPopover"
          }
        },
        [
          _c(
            "div",
            { staticClass: "popover-content-top" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c("el-button", { on: { click: _vm.saveColumns } }, [
                _vm._v("确定")
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "popover-content-center" },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.selectAllColumns },
                  model: {
                    value: _vm.allChecked,
                    callback: function($$v) {
                      _vm.allChecked = $$v
                    },
                    expression: "allChecked"
                  }
                },
                [_vm._v("全选")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "invert",
                  attrs: { type: "text" },
                  on: { click: _vm.invertSelectColumns }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/table_filter_images/fanxuan@2x.png"),
                      alt: "反选",
                      width: "13px"
                    }
                  }),
                  _c("span", [_vm._v("反选")])
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "popover-content-footer",
              style: { maxHeight: _vm.popoverContentMaxHeight + "px" }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  staticClass: "check-wrapper",
                  model: {
                    value: _vm.checkedList,
                    callback: function($$v) {
                      _vm.checkedList = $$v
                    },
                    expression: "checkedList"
                  }
                },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        on: {
                          start: function($event) {
                            _vm.isDragging = true
                          },
                          end: function($event) {
                            _vm.isDragging = false
                          }
                        },
                        model: {
                          value: _vm.settingColumns,
                          callback: function($$v) {
                            _vm.settingColumns = $$v
                          },
                          expression: "settingColumns"
                        }
                      },
                      "draggable",
                      _vm.dragOptions,
                      false
                    ),
                    _vm._l(_vm.settingColumns, function(item) {
                      return _c(
                        "el-checkbox",
                        { key: item.key, attrs: { label: item.prop } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              ref: "columnsSettingBtn",
              staticClass: "columns-setting",
              attrs: { slot: "reference", type: "text" },
              nativeOn: {
                click: function($event) {
                  return _vm.onShow($event)
                }
              },
              slot: "reference"
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/statistics-board/设置备份@2x.png"),
                  alt: "",
                  width: "14"
                }
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }