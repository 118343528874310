<template>
  <div class="product-container">
    <div class="item-box count" :style="{height: tableHeight + 'px'}">
      <div class="count-box" :style="{background:'url('+require('./img/bg-1.png')+') no-repeat'}">
        <div class="title">本月计划完工 | 工单数</div>
        <div class="size-66">{{ weekCount.productionPlan.weekPlanFinishedCount }}<span
          class="size-36"
        >/{{ weekCount.productionPlan.weekPlanCount }}</span>
        </div>
      </div>
      <div class="count-box" :style="{background:'url('+require('./img/bg-2.png')+') no-repeat'}">
        <div class="title">本月计划完工 | 工序数</div>
        <div class="size-66">{{ weekCount.procedure.weekPlanFinishedCount }}<span
          class="size-36"
        >/{{ weekCount.procedure.weekPlanCount }}</span>
        </div>
      </div>
    </div>
    <div class="item-box" :style="{ height: tableHeight + 'px'}">
      <div
        class="gradient-title"
        style="background: linear-gradient(90deg, #FF1010 0%, rgba(255,174,96,0) 100%);"
      >异常追踪
      </div>
      <ve-pie
        :height="tableHeight+'px'"
        :settings="pieChartSettings"
        :extend="pieChartExtend"
        :data="exceptionPieConfig"
      />
    </div>
    <div class="item-box" :style="{ height: tableHeight + 'px'}">
      <div
        class="gradient-title"
        style="background: linear-gradient(90deg, #1053FF 0%, rgba(96,127,255,0) 100%);"
      >
        生产工单<span>({{ prodOrderData.length }})</span>
      </div>
      <scroll-board :config="prodListConfig">
        <div
          slot="status"
          slot-scope="{ index }"
          class="status-board"
          :style="{ background: productionStatus[prodOrderData[index].status].color }"
        >
          {{ productionStatus[prodOrderData[index].status].name }}</div>
        <div slot="productionTempo" slot-scope="{ index }">
          <el-progress
            :stroke-width="10"
            :percentage="prodOrderData[index].productionTempo"
            style="width: 120px"
          />
        </div>
      </scroll-board>
    </div>
    <div class="item-box " :style="{ height: tableHeight + 'px',paddingTop:'46px'}">
      <scroll-board :config="exceptionListConfig" style="height:calc(84% + 40px)">
        <div slot="finalResult" slot-scope="{ index }">
          {{ finalResultMap[exceptionData[index].finalResult]||'-' }}</div>
        <div slot="status" slot-scope="{ index }">
          {{ handleStatusMap[exceptionData[index].status]||'-' }}</div>
      </scroll-board>
    </div>
    <div class="item-box" :style="{ height: tableHeight + 'px'}">
      <div
        class="gradient-title"
        style="background: linear-gradient(90deg, #00751F 0%, rgba(96, 127, 255, 0) 100%);"
      >
        过程检（待检）<span>（{{ waitCheckData.length }}）</span></div>
      <scroll-board :config="waitCheckConfig">
        <span slot="currentProcedure" slot-scope="{ index }">
          {{ productTaskData[index].produceWorkOrderTaskList ? productTaskData[index].produceWorkOrderTaskList[0].procedureName : '' }}
        </span>
        <span slot="priority" slot-scope="{ index }">
          {{ priorityStatus[productTaskData[index].priority].name ? priorityStatus[productTaskData[index].priority].name : '普通' }}
        </span>
      </scroll-board>
    </div>
    <div class="item-box" :style="{ height: tableHeight + 'px'}">
      <div
        class="gradient-title"
        style="background: linear-gradient(90deg, #00751F 0%, rgba(96, 127, 255, 0) 100%);"
      >
        过程检（七日内）<span>（{{ weekCheckData.length }}）</span></div>
      <scroll-board :config="weekCheckConfig">
        <span slot="result" slot-scope="{ index }">
          {{ +weekCheckData[index].result===0 ? '不合格' : '合格' }}
        </span>
      </scroll-board>
    </div>
  </div>
</template>
<script>
import api from '@/api/statistic-statement/index'
import mix from './mixin'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
import dayjs from 'dayjs'
import { handleStatusMap, finalResultMap } from '../../data'
const scrollBoardSettingDefault = {
  carousel: 'page',
  headerHeight: 33,
  hoverPause: false,
  waitTime: 10000,
  headerBGC: '#032D2D',
  oddRowBGC: '#032D2D',
  evenRowBGC: 'transparent'
}
export default {
  name: 'Product',
  components: { scrollBoard },
  mixins: [mix],
  data() {
    return {
      handleStatusMap,
      finalResultMap,
      weekCount: {
        productionPlan: {
          weekPlanCount: 0,
          weekPlanFinishedCount: 0
        },
        procedure: {
          weekPlanCount: 0,
          weekPlanFinishedCount: 0
        }
      },
      prodListConfig: {
        ...scrollBoardSettingDefault,
        columnWidth: [60, 150, 150, 150, 120, 180, 150],
        header: ['序号', '生产工单', '物料编码', '物料名称', '状态', '生产进度', '交期'],
        data: []
      },
      exceptionListConfig: {
        ...scrollBoardSettingDefault,
        columnWidth: [60, 150, 120, 150, 150, 150, 150],
        header: ['序号', '异常单号', '状态', '异常类型', '异常现象', '责任人', '最终结果'],
        data: []
      },
      waitCheckConfig: {
        ...scrollBoardSettingDefault,
        columnWidth: [60, 160, 160, 160, 160, 160],
        header: ['序号', '任务编号', '生产工单', '工序', '质检方案', '执行人'],
        data: []
      },
      weekCheckConfig: {
        ...scrollBoardSettingDefault,
        columnWidth: [60, 180, 180, 160, 160, 160],
        header: ['序号', '任务编号', '生产工单', '工序', '质检方案', '结论判定'],
        data: []
      },
      weekCheckData: [],
      waitCheckData: [],
      exceptionData: [],
      prodOrderData: [],
      productionStatus: {
        0: {
          name: '进行中',
          color: '#00371A',
          background: 'rgba(0, 171, 41, 0.1)'
        },
        1: {
          name: '已完成',
          color: '#9597AE',
          background: 'rgba(149, 151, 174, 0.1)'
        }
      },
      exceptionPieConfig: {
        columns: ['name', 'quantity'],
        rows: []
      },
      tableHeight: 100,
      productTaskData: [],
      scrollBoardSettingDefault
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  mounted() {
    this.reloadData()
  },
  methods: {
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.tableHeight = ((window.innerHeight * (1 - 0.15)) * 0.333) / scale
    },
    async getWeekCount() {
      const res = await api.getWeekCount()
      if (res) {
        this.weekCount = res
      }
      console.log(res)
    },
    reloadData() {
      this.getWeekCount()
      this.getTableData('inspection/private/inspectionTask/screenList', {
        type: 1,
        status: 2,
        startTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD')
      }, {
        key: 'weekCheckConfig',
        key2: 'weekCheckData',
        columns: this.weekCheckConfig.header,
        rowNum: 5,
        columnsKey: ['index', 'inspectionTaskCode', 'producePlanNumber', 'procedureName', 'inspectionSchemeName', 'slot:result'],
        columnWidth: this.weekCheckConfig.columnWidth
      })

      this.getTableData('inspection/private/inspectionTask/screenList', {
        type: 1,
        status: 0
      }, {
        key: 'waitCheckConfig',
        key2: 'waitCheckData',
        columns: this.waitCheckConfig.header,
        rowNum: 5,
        columnsKey: ['index', 'inspectionTaskCode', 'producePlanNumber', 'procedureName', 'inspectionSchemeName', 'executorName'],
        columnWidth: this.waitCheckConfig.columnWidth
      })

      this.getTableData('abnormal/private/abnormalStatistics/abnormalTraceList', {}, {
        key: 'exceptionListConfig',
        key2: 'exceptionData',
        columns: this.exceptionListConfig.header,
        rowNum: 5,
        columnsKey: ['index', 'code', 'slot:status', 'abnormalTypeName', 'abnormalRemark', 'dutyUserName', 'slot:finalResult'],
        columnWidth: this.exceptionListConfig.columnWidth
      }, true)

      this.getTableData('production/private/productionPlan/screenList', {}, {
        key: 'prodListConfig',
        key2: 'prodOrderData',
        columns: this.prodListConfig.header,
        rowNum: 5,
        columnsKey: ['index', 'producePlanNumber', 'materialsCode', 'materialsName', 'slot:status', 'slot:productionTempo', 'deliveryTime'],
        columnWidth: this.prodListConfig.columnWidth
      })
      this.getExceptionPie()
    },
    async getExceptionPie() {
      const res = await api.getData('abnormal/private/abnormalStatistics/quantityStatistics', { status: '0,1,2'
      }, true)
      if (res) {
        const dataMap = {}
        res.rateList.forEach(({ name, quantity, abnormalTypeId, rate }) => {
          this.exceptionPieConfig.rows.push({ name, quantity })
          dataMap[name] = { name, quantity, abnormalTypeId, rate }
        })
        this.pieChartExtend.legend.formatter = (key) => {
          const { name, quantity, rate } = dataMap[key]
          const arr = [
            '{a|' + name + '}',
            '{a|' + rate + '%}',
            '{a|' + quantity + '单}'
          ]
          return arr.join('')
        }
        this.exceptionPieConfig.rows = res.rateList.map(({ name, quantity }) => ({ name, quantity }))
        this.pieChartExtend.series.label.formatter = `{a|总数/单}\n{b|${res.totalQuantity}}`
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./common";
.product-container {
  padding: 0 20px 20px 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .count {
    display: flex;
    justify-content: space-between;
  }
  .item-box {
    width: 49%;
    .count-box {
      width: 49%;
      height: 100%;
      background-size: 100% auto !important;
      padding: 32px 20px 2px 20px;
      .title {
        font-size: 25px;
        font-weight: 600;
        color: #ffffff;
        line-height: 34px;
        letter-spacing: 2px;
      }
      .size-66 {
        font-size: 66px;
        font-weight: 600;
        color: #ffffff;
        letter-spacing: 6px;
        margin-top: 70px;
      }
      .size-36 {
        font-size: 36px;
        color: #ababab;
      }
    }
  }
}

.gradient-title {
  margin-bottom: 10px;
  span {
    font-size: 14px;
  }
}
::v-deep .dv-scroll-board {
  height: 84%;
}
::v-deep .dv-scroll-board .ceil {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.status-board {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 44px;
}

::v-deep .el-progress {
  display: flex;
}
::v-deep .dv-scroll-board {
  div {
    .el-progress__text {
      color: #fff;
      font-size: 14px !important;
    }
  }
}
</style>
