<!--流程引擎文件上传处-->
<template>
  <div class="container" style="padding:0px;">
    <iframe src="https://www.ixms.tech/v3/customeroverview" frameborder="0"
            style="width:100vw;height:calc(100vh + 70px);margin-top: -70px;"></iframe>
  </div>
</template>
<script>
import XLSX from 'xlsx'
import api from '@/api/columnsSetApi'
import { getToken } from '@/utils/auth'
export default {
  name: 'SimpleImport',
  props: {

  },
  data() {
    return {

    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 48 - 12 - 220
    },
    loadingHeight() {
      return 270
    }
  },
  watch: {
  },
  mounted() {
    // this.launchFullscreen()
  },
  methods: {
    launchFullscreen() {
      const element = document.documentElement
      if (element.requestFullscreencreen) {
        element.requestFullScreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen()
      } else if (element.msRequestFullScreen) {
        element.msRequestFullScreen()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tip {
  padding: 12px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 20px 0;
  font-size: 13px;
  color: red;
  font-weight: bold;
}

.batchImport {
  padding: 10px;

  .down-template {
    background: #f6f7f9;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    margin: 30px 0 20px 0;

    .down-le {
      width: 6px;
      background: #607fff;
      border-radius: 4px 0px 0px 4px;
      margin-right: 20px;
      padding: 20px 0;
    }

    .down-ri {
      padding: 20px 0;

      .down-tit {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 20px;
        margin-bottom: 2px;
      }

      .down-des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9fa1b7;
        line-height: 20px;
      }

      .down-btn {
        margin-top: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          width: 21px;
          height: 15px;
          color: #607fff;
        }

        .download {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #607fff;
          line-height: 20px;
          margin-left: 8px;
        }

        .icons {
          color: #607fff;
          display: inline-flex;
          align-items: center;

          ::v-deep .el-icon-upload {
            font-size: 22px;
            color: #607fff;
          }
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0091ff;
          line-height: 20px;
          margin-left: 8px;
        }
      }
    }
  }

  .check-success {
    margin-top: 30px;

    .suc-top {
      background: rgba(0, 171, 41, 0.02);
      border-radius: 4px;
      border: 1px solid #00ab29;
      padding: 20px 0 20px 25px;

      .tit {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 22px;
      }

      .des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a9abbf;
        line-height: 22px;
      }
    }
  }

  .suc-tit {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #393d60;
    line-height: 20px;
    text-align: center;
    margin-top: -50px;
  }
}

.el-dialog__footer {
  padding: 0 !important;
  height: 57px;
}

.mubai-dialog-footer {
  position: absolute;
  height: 57px;
  width: 100%;
  border-top: 1px solid #dde3eb;
  padding: 10px 20px;
  bottom: 0;
  background: #ffffff;

  button {
    padding: 10px 40px !important;

    &:first-child {
      background: #f8f8fb;
      color: #8a8ca5;
      transition: all 0.5s;

      &:hover {
        border-color: #dadaf6;
        background: #f0f0f8;
      }
    }

    &:last-child {
      background: $primary-color;
      color: #ffffff;
      transition: all 0.5s;

      &:hover {
        background: #7691fc;
      }
    }
  }
}

.center-content {
  text-align: center;
}

::v-deep .has-error {
  background: rgba(224, 32, 32, 0.04);
}

.warning {
  border: 1px solid red;
  border-radius: 4px;
}
</style>
