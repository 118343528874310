// 列表
const Materials = [
  {
    type: 'selection',
    fixed: 'left',
    width: 80,
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'sitWarehouseName',
    label: '线边仓',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'recordType',
    label: '流水方向',
    sortable: false,
    hiddenSearch: true,
    slotName: 'recordType'
  },
  {
    prop: 'typeName',
    label: '出入库类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'type',
    minWidth: 150
  },
  {
    prop: 'billNumber',
    label: '出/入库单号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'sourceBillNumber',
    label: '源单据号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  // {
  //   prop: 'sequenceNumber',
  //   label: '序列号',
  //   hiddenSearch: true,
  //   sortable: false,
  //   minWidth: 180
  // },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    minWidth: 150,
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsCode'
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'quantity',
    label: '出/入库数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'operator',
    label: '制单人',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'operateTime',
    label: '制单时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'recordRemark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]

export default {
  Materials
}
