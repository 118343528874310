<template>
  <div class="base-print">
    <div id="qrcodes" ref="qrcodes" style="display: none">
      <div :style="{position: 'absolute', width: infos.style.width +'px', height: infos.style.height +'px', top:infos.style.top +'px', left: infos.style.left +'px'}">
        <vue-qr :correct-level="3" :auto-color="false" color-dark="#000000" :text="infos.texts" :size="infos.size" :margin="0" />
      </div>
    </div>
    <div v-if="isEdit" class="kr-designers">
      <viewport ref="viewportInit" class="kr-designer-view" :is-resize="isResize" />
      <div class="kr-designer-tool">
        <i class="el-icon-printer icons" />
        <el-form label-width="80px" :model="pageInfo" size="small" class="kr-form">
          <el-row>
            <el-col :span="15">
              <el-form-item label="打印模板">
                <el-select
                  v-model="pageInfo.title"
                  @change="selectTitles"
                >
                  <el-option v-for="val in getTemplateList" :key="val.id" :label="val.name" :value="val.name" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-left: 20px;">
              <el-button size="mini" type="text" @click="editTemplate">编辑模板</el-button>
            </el-col>
          </el-row>
        </el-form>
        <div class="all-btns">
          <el-button size="medium" @click="closeIn">取消</el-button>
          <el-button size="medium" type="primary" @click="printTemplate">打印</el-button>
        </div>
      </div>

    </div>
    <div v-else style="height: 100%;">
      <print-designer
        ref="printDesigner"
        :temp-value="value"
        :widget-options="widgets"
        :template-id="templateId"
        @save="handleSave"
        @returnTemp="returnTemp"
      />
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { getToken } from '@/utils/auth'
import printDesigner from '@/components/printDesigner/printDesigner'
import { bbelcWidgets, defaultTemp, widgets } from '@/config/defaultTemp'
import pApi from '@/api/print'
import Viewport from '@/components/printDesigner/components/viewport/index.vue'
import eApi from '@/api/equipment/equipment'


export default {
  name: 'BasePrint',
  components: { Viewport, printDesigner, VueQr },
  data() {
    return {
      bbelcWidgets,
      infos: {
        texts: '',
        size: 100,
        style: ''
      },
      showCode: true,
      defaultTemp,
      isEdit: true,
      value: {
        // title: 'demo',
        // width: 400,
        // height: 300,
        // pageWidth: 400,
        // pageHeight: 300,
        // tempItems: []
      },
      widgets,
      pageInfo: {
        title: '系统模板'
      },
      getTemplateList: [],
      equipmentDetail: {},
      templateId: undefined,
      htms: '',
      isResize: true,
      ifFirst: false
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  async mounted() {
    try {
      await this.getTemplate()
      await this.getEquipmentDetail()
      this.initTemplate()
    } catch (err) {
      console.error(err)
    }
  },
  methods: {
    closeIn() {
      window.opener = null
      window.open('about:blank', '_top').close()
    },
    async printTemplate() {
      const printContent = this.TempParser(this.value.tempItems)
      let str = ''
      printContent[0].map(printItem => {
        str += this.addTemplate(printItem)
      })
      this.$nextTick(() => {
        setTimeout(() => {
          const printHtml = str
          const new_iframe = document.createElement('IFRAME')
          let doc = null
          new_iframe.setAttribute('style', 'width:0px;height:0px;position:absolute;left:-2000px;top:-2000px;')
          new_iframe.setAttribute('align', 'center')
          document.body.appendChild(new_iframe)
          doc = new_iframe.contentWindow.document
          const html = '<div id="codes" style="width:' + this.value.width + 'px;height:' + this.value.height + 'px;position:relative;margin: 0 auto;border: 1px solid #eee;">' + printHtml + '</div>'
          doc.write(html)
          doc.body.style.zoom = '0.8'

          doc.close()
          new_iframe.contentWindow.focus()
          new_iframe.contentWindow.print()
        }, 0)
      })
    },
    addTemplate(printItem) {
      if (printItem.type === 'braid-txt') {
        const txt = `<div style="position:absolute;
        top:${printItem.top}px;
        left:${printItem.left}px;
        width:${printItem.width}px;
        height:${printItem.height}px;
        font-size:${printItem.style.FontSize}px;
        text-align:${printItem.style.Alignment};
        color:${printItem.style.FontColor};
        z-index:${printItem.style.zIndex};
        font-weight:${printItem.style.Bold ? 'bold' : 'normal'};
        font-style:${printItem.style.Italic ? 'itatic' : 'normal'};
        text-decoration:${printItem.style.Underline ? 'underline' : 'none'};
        font-family:${printItem.style.FontFamily ? printItem.style.FontFamily : ''}
        ">${printItem.value}</div>`
        return txt
      } else if (printItem.type === 'bar-code') {
        return document.getElementById('qrcodes').innerHTML
      } else if (printItem.type === 'braid-image') {
        const html = `<img style="
          position:absolute;
          top:${printItem.top}px;
          left:${printItem.left}px;
          width:${printItem.width}px;
          height:${printItem.height}px
        " src=${printItem.value} />`
        return html
      }
    },
    TempParser(tempItem, data) {
      const temp = this._.cloneDeep(tempItem)
      // 修改模板答应项顺序
      // 将自适应高度的打印项（item.style.AutoHeight == true）放在第一项
      const flag = temp.findIndex(item => item.style.AutoHeight)
      if (flag != -1) {
        const autoItem = temp[flag]
        temp.splice(flag, 1)
        temp.unshift(autoItem)
        // 处理位于自适应打印项下方的打印项
        temp.forEach(item => {
          // 位于自适应高度项下的打印项修改top、left,并添加关联属性（style.LinkedItem）
          if (item.top > autoItem.top && item.style.ItemType == 0) {
            item.top = item.top - autoItem.top - autoItem.height
            item.left = item.left - autoItem.left
            item.style.LinkedItem = 1
          }
        })
      }

      if (data && data.length > 0) {
        // 解析打印模板和数据，生成生成打印内容
        const tempContent = []
        data.forEach(dataItem => {
          const conItem = temp.map(tempItem => {
            const item = this._.cloneDeep(tempItem)
            if (item.name) {
              item.defaultValue = dataItem[item.name]
              item.value = this.strTempToValue(item.value, item.defaultValue)
            }
            return item
          })
          tempContent.push(conItem)
        })
        return tempContent
      } else {
        return [temp]
      }
    },
    strTempToValue(str, value) {
      const reg = /({[^}^{]*})/g
      return str.replace(reg, value || '')
    },
    editTemplate() {
      this.isEdit = false
      const tempList = this.getTemplateList.filter(item => item.name == this.pageInfo.title)
      const value = JSON.parse(tempList[0].info)
      const widgets = this.memberCode === 'bbelc' || this.memberCode === 'bbwz' ? this.bbelcWidgets : this.widgets
      this.$nextTick(() => {
        this.$refs.printDesigner.initTemp(value, widgets)
      })
    },
    initTemplate() {
      this.isResize = false
      const tempList = this.getTemplateList.filter(item => item.name == this.pageInfo.title)
      this.value = JSON.parse(tempList[0].info)
      this.infos.texts = this.equipmentDetail.code
      const defaults = this.value.tempItems.map(item => {
        if (item.title === '设备二维码') {
          this.infos.size = item.width
          this.infos.style = {
            top: item.top,
            left: item.left,
            size: item.width,
            width: item.width,
            height: item.height
          }
        }
        return {
          ...item,
          dragable: false,
          isEdit: false,
          resizable: false,
          value: this.equipmentDetail[item.name] ? this.equipmentDetail[item.name] : item.value
        }
      })
      this.value.tempItems = defaults
      const widgets = this.memberCode === 'bbelc' || this.memberCode === 'bbwz' ? this.bbelcWidgets : this.widgets
      this.$vptd.dispatch('designerInit', {
        tempValue: this._.cloneDeep(this.value),
        options: this._.cloneDeep(widgets)
      })
    },
    async returnTemp(id) {
      this.isResize = false
      this.isEdit = true
      await this.getTemplate()
      this.pageInfo.title = this.getTemplateList.filter(item => item.id === id)[0].name
      this.initTemplate()
    },
    selectTitles() {
      const data = this.getTemplateList.filter(item => item.name == this.pageInfo.title)
      this.templateId = data[0].id
      this.initTemplate()
    },
    async getTemplate() {
      const res = await pApi.getPrintTemplateList()
      if (res) {
        this.getTemplateList = res
        localStorage.setItem('getTemplateList', JSON.stringify(res))
      }
    },
    // 设备详情
    async getEquipmentDetail() {
      const res = await eApi.getEquipmentDetail({ id: this.$route.query.id })
      if (res) {
        this.equipmentDetail = res
        this.equipmentDetail.buyTime = this.equipmentDetail.buyTime ? this.equipmentDetail.buyTime:'-'
        this.equipmentDetail.fixedCode = this.equipmentDetail.fixedCode ? this.equipmentDetail.fixedCode:'-'
        this.equipmentDetail.fixedName = this.equipmentDetail.fixedName ? this.equipmentDetail.fixedName:'-'
        this.equipmentDetail.fixedPosition = this.equipmentDetail.fixedPosition ? this.equipmentDetail.fixedPosition:'-'
        this.equipmentDetail.unitType = this.equipmentDetail.unitType ? this.equipmentDetail.unitType:'-'
        this.equipmentDetail.manufacturingNumber = this.equipmentDetail.manufacturingNumber ? this.equipmentDetail.manufacturingNumber:'-'
        this.equipmentDetail.manufacturer = this.equipmentDetail.manufacturer ? this.equipmentDetail.manufacturer:'-'
        this.equipmentDetail.organizationName = this.equipmentDetail.organizationName ? this.equipmentDetail.organizationName:'-'
        this.equipmentDetail.equipmentOwnerName = this.equipmentDetail.equipmentOwnerName ? this.equipmentDetail.equipmentOwnerName:'-'
        this.equipmentDetail.customerName = this.equipmentDetail.customerName ? this.equipmentDetail.customerName:'-'
        this.equipmentDetail.stationName = this.equipmentDetail.stationName ? this.equipmentDetail.stationName:'-'
      }
    },
    async handleSave(item, id) {
      this.isResize = false
      this.isEdit = true
      await this.getTemplate()
      this.pageInfo.title = this.getTemplateList.filter(item => item.id === id)[0].name
      this.initTemplate()
    }
  }
}
</script>

<style scoped lang="scss">
.base-print {
  height: 100%;
  .kr-designers {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;

    .icons {
      font-size: 100px;
      color: #dadada;
      margin: 20px;
    }
    .all-btns {
      display: flex;
      justify-content: center;
      border-top: 1px solid rgba(0,0,0,0.09);
      padding-top: 12px;
    }
    .kr-designer-view {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .kr-designer-tool {
      width: 400px;
      height: 100%;
      display: flex;
      flex-direction: column;
      background: #fff;
      .el-scrollbar__wrap {
        overflow: auto;
      }
      &_con {
        flex: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;
      }
      &_bar {
        padding: 10px 10px 0 10px;
        text-align: center;
      }
    }
  }
}

</style>
