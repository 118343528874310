import { formListColumns } from './columns'
import { transformColumnsToForm } from '@/utils'
export const formList = transformColumnsToForm(formListColumns)
export const formListName = transformColumnsToForm([
  {
    prop: 'fieldName',
    label: '选项名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', colSpan: 24 }
  }
])

