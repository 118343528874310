import { render, staticRenderFns } from "./all-board-group.vue?vue&type=template&id=59a4f2dc&scoped=true&"
import script from "./all-board-group.vue?vue&type=script&lang=js&"
export * from "./all-board-group.vue?vue&type=script&lang=js&"
import style0 from "./all-board-group.vue?vue&type=style&index=0&id=59a4f2dc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a4f2dc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/mubai-mom-frontend-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59a4f2dc')) {
      api.createRecord('59a4f2dc', component.options)
    } else {
      api.reload('59a4f2dc', component.options)
    }
    module.hot.accept("./all-board-group.vue?vue&type=template&id=59a4f2dc&scoped=true&", function () {
      api.rerender('59a4f2dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/statistics-board/board-manage/all-board-group.vue"
export default component.exports