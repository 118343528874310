<template>
  <div class="add-travel-plan">
    <div class="detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">出差计划</span>
      </div>
    </div>
    <div class="detail-body">
      <div class="detail-card">
        <div class="card-tit">基础信息</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in columns" :key="item.id" :span="6">
              <div class="view-list__label">{{ item.label }}</div>
              <div class="view-list__value">{{ getValue(item) || '-' }}</div>
            </el-col>
          </el-row>
          <MFormBuilder v-else ref="baseForm" :form-list="formList" :form-data="baseFormData" label-position="right">
            <div slot="facilityDemandCode">
              {{ baseFormData.code }}
            </div>
          </MFormBuilder>
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">预算申请</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in descColumns" :key="item.id" :span="6">
              <div class="view-list__label">{{ item.label }}</div>
              <div v-if="item.key !== 'facilityRepairHistoryList'" class="view-list__value">{{ getValue(item) || '-' }}</div>
            </el-col>
          </el-row>

          <MFormBuilder v-else ref="descForm" :form-list="descFormList" :form-data="baseFormData" label-position="right">
            <div slot="totalFare">
              <div>{{ totalFare }}</div>
            </div>
          </MFormBuilder>
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">出差说明</div>
        <div class="card-bot">
          <el-row v-if="isView" class="view-list" :gutter="24">
            <el-col v-for="item in travelColumns" :key="item.id" :span="24">
              <div class="view-list__label">{{ item.label }}</div>
              <div v-if="item.key !== 'facilityRepairHistoryList'" class="view-list__value">{{ getValue(item) || '-' }}</div>
            </el-col>
          </el-row>

          <MFormBuilder v-else ref="travelForm" :form-list="travelFormList" :form-data="baseFormData" label-position="right" />
        </div>
      </div>

      <div v-if="(isView || params.type === 'modify' ) && baseFormData.updateRemark" class="detail-card">
        <div class="card-tit">修改意见</div>
        <div class="card-bot" style="color: #393d60">
          {{ baseFormData.updateRemark }}
        </div>
      </div>
    </div>
    <div v-if="!isView" class="detail-footer">
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">确定</el-button>
      </div>
    </div>
    <div v-if="params.status === 1" class="detail-footer">
      <div class="footer-ri">
        <el-button type="danger" size="small" @click="rejectPlan">驳回</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="passPlan(1)">通过</el-button>
      </div>
    </div>

    <MDialog v-model="dialogVisible" :append-to-body="true" title="驳回" width="500px" @onOk="submitFormReject">
      <MFormBuilder ref="modifyForm" :form-list="modifyFormList" label-position="right" />
    </MDialog>
  </div>
</template>

<script>
import api from '@/api/xiewei/service/demand-center'
import { columns, descColumns, travelColumns, modifyColumns, getFunctionList } from './columns'
import { formList, descFormList, travelFormList, modifyFormList } from './form-list'

export default {
  name: 'AddTravelPlan',
  data() {
    return {
      columns,
      descColumns,
      travelColumns,
      modifyColumns,
      formList,
      descFormList,
      travelFormList,
      modifyFormList,
      params: {},
      baseFormData: {},
      totalFare: '',
      messages: {
        add: '提交成功',
        modify: '修改成功',
        viewO: '审批出差计划',
        addO: '出差计划',
        modifyO: '修改出差计划'
      },
      buttonLoading: false,
      dialogVisible: false
    }
  },

  computed: {
    isView() {
      return this.$route.query.type === 'view' || this.$route.query.type === 'scan'
    },

    getValue() {
      return row => {
        const result = this.baseFormData[row.prop]
        if (row.form && row.form.options && row.form.options.length > 0) {
          const current = row.form.options.find(item => item.id === result)
          return current && current.name
        }
        return result
      }
    }
  },

  beforeMount() {
    getFunctionList({
      valueChange: this.valueChange
    })
  },

  mounted() {
    this.params = this.$route.query
    this.baseFormData = {
      code: this.params.code
    }
    if (this.params.type === 'view' || this.params.type === 'modify') {
      this.getDetail()
    }
    if (this.params.type === 'scan') {
      this.getDetails()
    }
  },

  methods: {
    returns() {
      this.$router.back()
    },

    async getDetail() {
      const res = await api.getFacilityDemandBusinessPlans(this.params.id)
      if (res) {
        this.baseFormData = {
          ...this.baseFormData,
          ...res
        }
        this.totalFare = this.baseFormData.totalFare
      }
    },

    async getDetails() {
      const res = await api.getFacilityDemandBusinessPlansDetail(this.params.planId)
      if (res) {
        this.baseFormData = {
          ...this.baseFormData,
          ...res
        }
        this.totalFare = this.baseFormData.totalFare
      }
    },
    valueChange() {
      const { carFare = 0, hotelFare = 0, otherFare = 0 } = this.$refs.descForm.form
      const totalFare = parseFloat((carFare + hotelFare + otherFare).toFixed(2))
      this.totalFare = totalFare
      this.$refs.descForm.setForm({
        totalFare
      })
    },

    rejectPlan() {
      this.dialogVisible = true
    },

    async passPlan(val, reason) {
      const res = await api.modifyFacilityDemandOperate({
        sign: 1,
        demandId: this.params.id,
        onBusinessPlanId: this.baseFormData.id,
        status: val,
        updateRemark: reason || '',
        operatorNames: this.baseFormData.creator,
        operatorIds: this.baseFormData.creatorId,
        content: +this.baseFormData.content === 3 ? 3 : 0
      })
      if (res) {
        this.returns()
      }
    },
    async submitFormReject(callback) {
      const { form } = this.$refs.modifyForm
      this.$refs.modifyForm.formRefs().validate(async(valid) => {
        if (valid) {
          this.passPlan(0, form.updateRemark)
          callback(true)
        } else {
          callback()
        }
      })
    },
    submitForm() {
      this.buttonLoading = true
      const { form: baseForm } = this.$refs.baseForm
      const { form: descForm } = this.$refs.descForm
      const { form: travelForm } = this.$refs.travelForm
      // console.log('ceshizhi=>', this.createSetData1(baseForm),
      // this.createSetData2(descForm), this.createSetData3(travelForm))
      this.$refs.baseForm.formRefs().validate(async valid => {
        if (valid) {
          const data = {
            facilityDemandId: this.params.id,
            facilityDemandCode: this.baseFormData.code,
            ...this.createSetData1(baseForm),
            ...this.createSetData2(descForm),
            ...this.createSetData3(travelForm)
          }
          this.saveData(data)
          this.buttonLoading = false
        } else {
          this.buttonLoading = false
        }
      })
    },

    async saveData(form) {
      const res = await api.addFacilityDemandBusinessPlans(form)
      if (res) {
        this.returns()
      }
    },
    createSetData1(raw) {
      return {
        startPoint: raw.startPoint,
        endPoint: raw.endPoint,
        vehicle: raw.vehicle,
        startTime: raw.startTime,
        arrivalTime: raw.arrivalTime,
        togetherUserName: raw.togetherUserName
      }
    },
    createSetData2(raw) {
      return {
        carFare: raw.carFare,
        hotelFare: raw.hotelFare,
        otherFare: raw.otherFare,
        fareRemark: raw.fareRemark,
        totalFare: this.totalFare
      }
    },
    createSetData3(raw) {
      return {
        remark: raw.remark
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-travel-plan {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }
    .el-form-item {
      margin-bottom: 15px !important;
    }
  }

  .card-bot {
    ::v-deep.el-row {
      display: flex;
      flex-wrap: wrap;
    }

    .view-list {
      padding: 0 15px;
      color: #393d60;
      font-size: 14px;

      &__label {
        margin-bottom: 4px;
        color: #9597ae;
      }

      .el-col {
        margin: 10px 0;
      }
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;

      .required-star {
        width: 10px;
        color: #f00;
      }

      .range {
        margin: 0 10px;
      }
    }

    .upload-view {
      ::v-deep.el-upload {
        &--text {
          display: none;
        }
      }
    }
  }

  .detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;

      .desc-table.edit {
        position: relative;
        top: -46px;
        left: 96px;
        width: calc(100% - 96px);
        margin-bottom: -46px;
      }

      .e-btn {
        margin-top: 10px;
      }
    }
  }
  .detail-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
