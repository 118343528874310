import { formatColumns, getUUid } from '@/utils'
import dayjs from 'dayjs'
import { Encrypt } from '@/utils/sercet'
export const getDateRange = (type) => {
  let startDate
  let endDate
  // 当月
  if (type === 0) {
    startDate = dayjs().startOf('month').format('YYYY-MM')
    endDate = dayjs().endOf('month').format('YYYY-MM')
  }
  // 季度
  if (type === 1) {
    startDate = dayjs().subtract(3, 'month').format('YYYY-MM')
    endDate = dayjs().startOf('month').format('YYYY-MM')
  }
  // 半年
  if (type === 2) {
    endDate = dayjs().startOf('month').format('YYYY-MM')
    startDate = dayjs().subtract(6, 'month').format('YYYY-MM')
  }
  // 当年
  if (type === 3) {
    startDate = dayjs().startOf('year').format('YYYY-MM')
    endDate = dayjs().endOf('year').format('YYYY-MM')
  }
  // 去年同月至当月
  if (type === 4) {
    endDate = dayjs().startOf('month').format('YYYY-MM')
    startDate = dayjs().subtract(1, 'year').format('YYYY-MM')
  }
  return [startDate, endDate]
}
const defaultTimeDuration = getDateRange(1)
export default [
  {
    name: '生产卡片',
    key: 'product',
    children: [
      {
        id: 1,
        name: '工单进度统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getProduceWorkOrderList',
        dataCondition: { statusList: '0^1' },
        tableColumns: [
          {
            prop: 'areaName',
            searchKey: 'areaIds',
            label: '适用产线',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'area',
            filterType: 'transfer',
            searchUrl: 'basicData/private/area/getAreaList',
            searchData: [{
              id: getUUid(),
              fieldName: 'type',
              fieldType: 'number',
              fieldValue: 2,
              operator: 'eq'
            }]
          },
          {
            prop: 'produceWorkOrderNumber',
            searchKey: 'produceWorkOrderIds',
            label: '生产工单号',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'workOrder',
            filterType: 'transfer',
            transferProps: {
              key: 'id',
              label: 'produceWorkOrderNumber'
            },
            searchUrl: 'production/kiki/private/produceWorkOrder/getProduceWorkOrderList'
          },
          {
            prop: 'produceOrderNumber',
            label: '关联生产订单号',
            searchKey: 'produceOrderIds',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'order',
            filterType: 'transfer',
            transferProps: {
              key: 'id',
              label: 'produceOrderNumber'
            },
            searchUrl: 'production/kiki/private/produceOrder/getProduceOrderList'
          },
          {
            prop: 'materialsCode',
            searchKey: 'materialsIds',
            label: '物料编码',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'materialsCode',
            filterType: 'materials'
          },
          {
            prop: 'materialsName',
            label: '物料名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'materialsSpecifications',
            label: '物料规格',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'quantity',
            label: '工单数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'reportWorkQuantity',
            label: '完工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'repairQuantity',
            label: '返修数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'finishRatio',
            label: '工单达成率',
            sortable: true,
            hiddenSearch: true,
            slotName: 'orderOverPercent'
          },
          {
            prop: 'procedure',
            searchKey: 'currentProcedureIds',
            label: '当前所处工序',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'procedure',
            filterType: 'transfer',
            searchUrl: 'basicData/production/private/procedure/getProcedureList',
            slotName: 'currentProcedure'
          },
          {
            prop: 'procedureGroupName',
            searchKey: 'currentProcedureGroupIds',
            label: '当前所处工序段',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'procedureGroupName',
            filterType: 'transfer',
            searchUrl: 'basicData/production/private/procedureGroup/getProcedureGroupList',
            slotName: 'currentProcedureGroupName'
          },
          {
            prop: 'procedureRatio',
            label: '工序进程',
            sortable: true,
            hiddenSearch: true,
            slotName: 'procedureRatio'
          },
          {
            prop: 'planStartTime',
            label: '计划开始时间',
            sortable: true,
            hiddenSearch: true,
            slotName: 'planStartTime'
          },
          {
            prop: 'planEndTime',
            label: '计划结束时间',
            sortable: true,
            hiddenSearch: true,
            slotName: 'planEndTime'
          },
          {
            prop: 'overTime',
            label: '距离计划结束时间还剩',
            sortable: false,
            hiddenSearch: true,
            slotName: 'remainPlanTime'
          },
          {
            prop: 'actualStartTime',
            label: '实际开始时间',
            sortable: true,
            hiddenSearch: true,
            slotName: 'actualStartTime'
          },
          {
            prop: 'deliveryTime',
            label: '交期',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'overActualTime',
            label: '距离交期还剩',
            sortable: false,
            hiddenSearch: true,
            slotName: 'remainDeliveryTime'
          },
          {
            prop: 'longTime',
            label: '工单持续时长',
            sortable: false,
            hiddenSearch: true,
            slotName: 'workOrderDuration'
          },
          {
            prop: 'statusList',
            searchKey: 'statusList',
            label: '工单状态',
            sortable: false,
            hiddenSearch: true,
            filters: formatColumns([
              {
                id: 0,
                name: '未开始'
              },
              {
                id: 1,
                name: '进行中'
              }
            ]),
            slotName: 'status'
          }
        ]
      },
      {
        id: 2,
        name: '月度订单达成率统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getProduceOrderMonthTotal',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        tableColumns: [
          {
            prop: 'month',
            label: '月份',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'month',
            filterType: 'dateRange'
          },
          {
            prop: 'total',
            label: '应完成订单数量',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'finishedTotal',
            label: '当月完成订单数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'finishedOnTimeTotal',
            label: '当月准时完成订单数量',
            sortable: true,
            hiddenSearch: true,
            width: 190
          },
          {
            prop: 'finishedRatio',
            label: '订单达成率',
            sortable: true,
            hiddenSearch: true,
            slotName: 'finishedRatio'
          },
          {
            prop: 'finishedOnTimeRatio',
            label: '订单准交率',
            sortable: true,
            hiddenSearch: true,
            slotName: 'finishedOnTimeRatio'
          }
        ]
      },
      {
        id: 3,
        name: '产线计划达成率统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getProducePlanMonthTotal',
        chartDataKey: 'finishedRatio',
        chartType: 'line',
        preDataUrl: 'basicData/private/area/getAreaList',
        preDataKey: 'areaIds',
        preDataCondition: {
          searchVal: Encrypt(JSON.stringify([{
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: 2,
            operator: 'eq'
          }]))
        },
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        columnSetting: {
          type: 'default',
          dataUrl: 'basicData/private/area/getAreaList',
          firstTitle: '产线设置',
          dataCondition: {
            searchVal: Encrypt(JSON.stringify([{
              id: getUUid(),
              fieldName: 'type',
              fieldType: 'number',
              fieldValue: 2,
              operator: 'eq'
            }]))
          }
        }
      },
      {
        id: 4,
        name: '产线计划准交率统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getProducePlanMonthTotal',
        chartDataKey: 'finishedOnTimeRatio',
        chartType: 'line',
        preDataUrl: 'basicData/private/area/getAreaList',
        preDataKey: 'areaIds',
        preDataCondition: {
          searchVal: Encrypt(JSON.stringify([{
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: 2,
            operator: 'eq'
          }]))
        },
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        columnSetting: {
          type: 'default',
          dataUrl: 'basicData/private/area/getAreaList',
          firstTitle: '产线设置',
          dataCondition: {
            searchVal: Encrypt(JSON.stringify([{
              id: getUUid(),
              fieldName: 'type',
              fieldType: 'number',
              fieldValue: 2,
              operator: 'eq'
            }]))
          }
        }
      },
      {
        id: 5,
        name: '物料产量统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getMaterialsOutputTotal',
        preDataUrl: 'basicData/materials/private/materials/getMaterialsListPage',
        preDataKey: 'materialsIds',
        preDataCondition: { page: 1, limit: 20 },
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        tableColumns: [
          {
            prop: 'info.code',
            searchKey: 'materialsIds',
            label: '物料编码',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'materialsCode',
            filterType: 'materials',
            slotName: 'getDeepName'
          },
          {
            prop: 'info.name',
            label: '物料名称',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'info.specifications',
            label: '规格',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'dayOutputTotal',
            label: '当日产量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekOutputTotal',
            label: '当周产量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthOutputTotal',
            label: '当月产量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 6,
        name: '产线产量统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getAreaOutputTotal',
        preDataUrl: 'basicData/private/area/getAreaList',
        preDataKey: 'areaIds',
        preDataCondition: {
          searchVal: Encrypt(JSON.stringify([{
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: 2,
            operator: 'eq'
          }]))
        },
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        tableColumns: [
          {
            prop: 'info.name',
            searchKey: 'areaIds',
            label: '产线',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'area',
            filterType: 'transfer',
            searchUrl: 'basicData/private/area/getAreaList',
            slotName: 'getDeepName',
            searchData: [{
              id: getUUid(),
              fieldName: 'type',
              fieldType: 'number',
              fieldValue: 2,
              operator: 'eq'
            }]
          },
          {
            prop: 'dayOutputTotal',
            label: '当日产量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekOutputTotal',
            label: '当周产量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthOutputTotal',
            label: '当月产量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 7,
        name: '物料分类产量统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getMaterialsClassOutputTotal',
        preDataUrl: 'basicData/materials/private/materialsClass/getMaterialsClassList',
        preDataKey: 'materialsClassIds',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        tableColumns: [
          {
            prop: 'info.name',
            label: '物料分类',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'area',
            searchKey: 'materialsClassIds',
            filterType: 'transfer',
            searchUrl: 'basicData/materials/private/materialsClass/getMaterialsClassList',
            slotName: 'getDeepName'
          },
          {
            prop: 'dayOutputTotal',
            label: '当日产量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekOutputTotal',
            label: '当周产量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthOutputTotal',
            label: '当月产量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 8,
        name: '物料分类产量统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getMaterialsClassMonthOutput',
        chartDataKey: 'quantity',
        chartType: 'bar',
        preDataUrl: 'basicData/materials/private/materialsClass/getMaterialsClassList',
        preDataKey: 'materialsClassIds',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        columnSetting: {
          type: 'tree',
          dataUrl: 'basicData/materials/private/materialsClass/getMaterialsClassList',
          firstTitle: '物料分类设置'
        }
      },
      {
        id: 9,
        name: '物料产量统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getMaterialsMonthOutput',
        chartDataKey: 'quantity',
        chartType: 'bar',
        preDataUrl: 'basicData/materials/private/materials/getMaterialsListPage',
        preDataKey: 'materialsIds',
        preDataCondition: { page: 1, limit: 20 },
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        columnSetting: {
          type: 'materials',
          firstTitle: '物料设置'
        }
      },
      {
        id: 10,
        name: '产线产量统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getAreaMonthOutput',
        chartDataKey: 'quantity',
        chartType: 'bar',
        preDataUrl: 'basicData/private/area/getAreaList',
        preDataKey: 'areaIds',
        preDataCondition: {
          searchVal: Encrypt(JSON.stringify([{
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: 2,
            operator: 'eq'
          }]))
        },
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        columnSetting: {
          type: 'default',
          firstTitle: '产线设置',
          dataUrl: 'basicData/private/area/getAreaList',
          dataCondition: {
            searchVal: Encrypt(JSON.stringify([{
              id: getUUid(),
              fieldName: 'type',
              fieldType: 'number',
              fieldValue: 2,
              operator: 'eq'
            }]))
          }
        }
      },
      {
        id: 18,
        name: '工序产量统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getProcedureMonthOutput',
        chartDataKey: 'quantity',
        chartType: 'bar',
        preDataUrl: 'basicData/production/private/procedure/getProcedureList',
        preDataKey: 'procedureIds',
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        columnSetting: {
          type: 'default',
          firstTitle: '工序设置',
          dataUrl: 'basicData/production/private/procedure/getProcedureList'
        }
      }
    ]
  },
  {
    name: '物料卡片',
    key: 'materials',
    children: [
      {
        id: 11,
        name: '物料库存统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getMaterialsInventoryTotal',
        preDataUrl: 'basicData/materials/private/materials/getMaterialsListPage',
        preDataCondition: { page: 1, limit: 20 },
        preDataKey: 'materialsIds',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        tableColumns: [
          {
            prop: 'materialsCode',
            searchKey: 'materialsIds',
            label: '物料编码',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'materialsCode',
            filterType: 'materials'
          },
          {
            prop: 'materialsName',
            label: '物料名称',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'materialsSpecifications',
            label: '规格',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'materialsUnit',
            label: '主单位',
            sortable: false,
            hiddenSearch: true
          },
          {
            prop: 'availableQuantity',
            label: '可用库存数量',
            sortable: true,
            hiddenSearch: true,
            slotName: 'availableQuantity'
          },
          {
            prop: 'currentQuantity',
            label: '当前存货',
            sortable: true,
            hiddenSearch: true,
            slotName: 'currentQuantity'
          },
          {
            prop: 'upperLimit',
            label: '库存上限',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'lowerLimit',
            label: '库存下限',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 12,
        name: '物料订单量统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getMaterialsOrderQuantityTotal',
        preDataUrl: 'basicData/materials/private/materials/getMaterialsListPage',
        preDataCondition: { page: 1, limit: 20 },
        preDataKey: 'materialsIds',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        tableColumns: [
          {
            prop: 'info.code',
            label: '物料编码',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'materialsCode',
            filterType: 'materials',
            searchKey: 'materialsIds',
            slotName: 'getDeepName'
          },
          {
            prop: 'info.name',
            label: '物料名称',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'info.specifications',
            label: '规格',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'info.mainUnitName',
            label: '主单位',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'weekOrderQuantity',
            label: '本周订单量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthOrderQuantity',
            label: '本月订单量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'lastMonthOrderQuantity',
            label: '上月订单量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'yearOrderQuantity',
            label: '本年订单量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 13,
        name: '物料订单量统计图',
        type: 'charts',
        dataUrl: 'statistics/private/statistics/getMaterialsMonthOrderQuantityTotal',
        chartDataKey: 'quantity',
        chartType: 'bar',
        preDataUrl: 'basicData/materials/private/materials/getMaterialsListPage',
        preDataKey: 'materialsIds',
        preDataCondition: { page: 1, limit: 20 },
        dataCondition: { beginTime: defaultTimeDuration[0], endTime: defaultTimeDuration[1] },
        chartLegendKey: 'info.name',
        chartXKey: 'month',
        columnSetting: {
          type: 'materials'
        }
      }
    ]
  },
  {
    name: '报工卡片',
    key: 'submit',
    children: [
      {
        id: 14,
        name: '员工报工统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getUserReportQuantityTotal',
        preDataUrl: 'authority/private/user/getUserList',
        preDataKey: 'userIds',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        tableColumns: [
          {
            prop: 'info.userName',
            searchKey: 'userIds',
            label: '员工工号',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'userName',
            slotName: 'getDeepName',
            filterType: 'transfer',
            searchUrl: 'authority/private/user/getUserList'
          },
          {
            prop: 'info.name',
            label: '员工姓名',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'dayReportQuantity',
            label: '当日报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekReportQuantity',
            label: '当周报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthReportQuantity',
            label: '当月报工数量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 15,
        name: '班组报工统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getUserGroupReportQuantityTotal',
        preDataUrl: 'basicData/settings/private/userGroup/getUserGroupList',
        preDataKey: 'userGroupIds',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        tableColumns: [
          {
            prop: 'info.name',
            label: '班组/用户组',
            searchKey: 'userGroupIds',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'userGroupCode',
            slotName: 'getDeepName',
            filterType: 'transfer',
            searchUrl: 'basicData/settings/private/userGroup/getUserGroupList'
          },
          {
            prop: 'info.name',
            label: '包含员工',
            sortable: false,
            hiddenSearch: true,
            slotName: 'includeUser'
          },
          {
            prop: 'dayReportQuantity',
            label: '当日报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekReportQuantity',
            label: '当周报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthReportQuantity',
            label: '当月报工数量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 16,
        name: '工位报工统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getStationReportQuantityTotal',
        preDataUrl: 'basicData/private/station/getStationList',
        preDataKey: 'stationIds',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        tableColumns: [
          {
            prop: 'info.code',
            label: '工位编码',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'stationCode',
            searchKey: 'stationIds',
            slotName: 'getDeepName',
            filterType: 'transfer',
            searchUrl: 'basicData/private/station/getStationList'
          },
          {
            prop: 'info.name',
            label: '工位名称',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'info.equipmentName',
            label: '工位设备',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'dayReportQuantity',
            label: '当日报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekReportQuantity',
            label: '当周报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthReportQuantity',
            label: '当月报工数量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      },
      {
        id: 17,
        name: '工序报工统计表',
        type: 'table',
        dataUrl: 'statistics/private/statistics/getProcedureReportQuantityTotal',
        preDataUrl: 'basicData/production/private/procedure/getProcedureList',
        preDataKey: 'procedureIds',
        dataCondition: { day: dayjs().format('YYYY-MM-DD') },
        tableColumns: [
          {
            prop: 'info.code',
            label: '工序编码',
            sortable: false,
            hiddenSearch: true,
            headerSlotName: 'userGroupCode',
            slotName: 'getDeepName',
            searchKey: 'procedureIds',
            filterType: 'transfer',
            searchUrl: 'basicData/production/private/procedure/getProcedureList'
          },
          {
            prop: 'info.name',
            label: '工序名称',
            sortable: false,
            hiddenSearch: true,
            slotName: 'getDeepName'
          },
          {
            prop: 'dayReportQuantity',
            label: '当日报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'weekReportQuantity',
            label: '当周报工数量',
            sortable: true,
            hiddenSearch: true
          },
          {
            prop: 'monthReportQuantity',
            label: '当月报工数量',
            sortable: true,
            hiddenSearch: true
          }
        ]
      }
    ]
  }
]
