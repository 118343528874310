export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'workOrderCode',
    label: '工单编号',
    hiddenSearch: true,
    slotName: 'workOrderCode'
  },
  {
    prop: 'consumer',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150
  },
  // {
  //   prop: 'executiveOutcome',
  //   label: '执行结果',
  //   sortable: false,
  //   hiddenSearch: true,
  //   slotName: 'executiveOutcome'
  // },
  {
    prop: 'facilityCode',
    label: '设备编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'facilityName',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'facilitySn',
    label: 'SN',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'source',
    label: '来源',
    sortable: false,
    hiddenSearch: true,
    slotName: 'source'
  },
  {
    prop: 'demandCode',
    label: '关联需求',
    sortable: false,
    hiddenSearch: true,
    slotName: 'demandCode'
  },
  {
    prop: 'facilityPlanCode',
    label: '保养计划编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'facilityPlanCode'
  },
  {
    prop: 'facilityPlanName',
    label: '计划名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'facilityPlanName'
  },
  {
    prop: 'level',
    label: '保养级别',
    sortable: false,
    hiddenSearch: true,
    slotName: 'level'
  },
  // {
  //   prop: 'remark',
  //   label: '备注',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  {
    prop: 'planExecutionStartTime',
    label: '计划执行时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'planExecutionStartTime',
    width: 280
  },
  {
    prop: 'executionStartTime',
    label: '实际服务时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'executionStartTime',
    width: 340
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'createTime'
  },
  {
    prop: 'executorName',
    label: '执行人',
    sortable: false,
    hiddenSearch: true,
    slotName: 'executorName'
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 180
  }
]
