var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    { attrs: { placement: "bottom" } },
    [
      _vm.iconShow
        ? _c(
            "template",
            { slot: "reference" },
            [
              _c("el-button", { attrs: { type: "text" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/statistics-board/高级筛选@2x.png"),
                    width: "14"
                  }
                })
              ])
            ],
            1
          )
        : _c(
            "el-button",
            { attrs: { slot: "reference", size: "small" }, slot: "reference" },
            [_vm._v(" " + _vm._s(_vm.showTitle) + " ")]
          ),
      _c(
        "el-checkbox-group",
        {
          on: { change: _vm.checkboxChange },
          model: {
            value: _vm.checkList,
            callback: function($$v) {
              _vm.checkList = $$v
            },
            expression: "checkList"
          }
        },
        [
          _c(
            "el-checkbox",
            { staticStyle: { "padding-bottom": "5px" }, attrs: { label: 0 } },
            [_vm._v("当月")]
          ),
          _c("br"),
          _c(
            "el-checkbox",
            { staticStyle: { "padding-bottom": "5px" }, attrs: { label: 1 } },
            [_vm._v("按季度")]
          ),
          _c("br"),
          _c(
            "el-checkbox",
            { staticStyle: { "padding-bottom": "5px" }, attrs: { label: 2 } },
            [_vm._v("按半年")]
          ),
          _c("br"),
          _c(
            "el-checkbox",
            { staticStyle: { "padding-bottom": "5px" }, attrs: { label: 3 } },
            [_vm._v("按当年")]
          ),
          _c("br"),
          _c(
            "el-checkbox",
            { staticStyle: { "padding-bottom": "5px" }, attrs: { label: 4 } },
            [_vm._v("按最近一年")]
          ),
          _c("br"),
          _c("el-checkbox", { attrs: { label: 5 } }, [_vm._v("自定义")])
        ],
        1
      ),
      _vm.checkList[0] === 5
        ? _c("el-date-picker", {
            staticClass: "custom-date-picker",
            attrs: {
              type:
                _vm.formatDateType === "YYYY-MM"
                  ? "monthrange"
                  : !_vm.dateType
                  ? "daterange"
                  : "datetimerange",
              "range-separator": "至",
              "value-format":
                _vm.formatDateType === "YYYY-MM"
                  ? "yyyy-MM"
                  : !_vm.dateType
                  ? "yyyy-MM-dd"
                  : "yyyy-MM-dd HH:mm:ss",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期"
            },
            on: { change: _vm.dateRangeChange },
            model: {
              value: _vm.dateRange,
              callback: function($$v) {
                _vm.dateRange = $$v
              },
              expression: "dateRange"
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }