// 列表
export const columnsAll = [
  {
    key: 1,
    prop: 'index',
    label: '序号',
    width: 70
  },
  {
    key: 2,
    prop: 'materialPreparationNumber',
    label: '备料单号',
    width: 200
  },
  {
    key: 3,
    prop: 'slot:materialPreparationStatus',
    label: '备料状态',
    width: 150
  },
  {
    key: 4,
    prop: 'slot:quantityPrepared',
    label: '待备数量',
    width: 150
  },
  {
    key: 5,
    prop: 'slot:quantityAvailable',
    label: '已备数量',
    width: 150
  },
  {
    key: 6,
    prop: 'slot:process',
    label: '备料进度',
    width: 150
  },
  {
    key: 7,
    prop: 'produceWorkOrderNumber',
    label: '关联工单',
    width: 200
  },
  {
    key: 8,
    prop: 'materialsName',
    label: '物料名称',
    width: 150
  },
  {
    key: 9,
    prop: 'materialsCode',
    label: '物料编号',
    width: 160
  },
  {
    key: 10,
    prop: 'quantity',
    label: '工单数量',
    width: 150
  },
  {
    key: 11,
    prop: 'deliveryTime',
    label: '工单交期',
    width: 150
  },
  {
    key: 12,
    prop: 'createTime',
    label: '创建日期',
    width: 150
  },
  {
    key: 13,
    prop: 'areaName',
    label: '产线',
    width: 150
  },
  {
    key: 14,
    prop: 'produceOrderNumber',
    label: '所属订单',
    width: 150
  },
  {
    key: 15,
    prop: 'slot:producePlanPriorityId',
    label: '计划优先级',
    width: 150
  }
]

// 工单进度列表
export const columnsWorkOrderProgress = [
  {
    key: 1,
    prop: 'workOrderNumber',
    label: '工单号',
    width: 200
  },
  {
    key: 2,
    prop: 'projectNumber',
    label: '项目号',
    width: 310
  },
  {
    key: 3,
    prop: 'materialsName',
    label: '产品名称',
    width: 200
  },
  {
    key: 4,
    prop: 'materialsCode',
    label: '产品编号',
    width: 200
  },
  {
    key: 5,
    prop: 'specifications',
    label: '产品规格',
    width: 200
  },
  {
    key: 6,
    prop: 'quantity',
    label: '工单数量',
    width: 120
  },
  {
    key: 7,
    prop: 'slot:status',
    label: '工单状态',
    width: 200
  },
  {
    key: 8,
    prop: 'deliveryTime',
    label: '交期',
    width: 200
  },
  {
    key: 9,
    prop: 'slot:reportProgress',
    label: '工单进度',
    width: 200
  },
  {
    key: 10,
    prop: 'slot:progress',
    label: '工序进度',
    width: 2000
  }
]
// 工单进度列表-02
export const columnsWorkOrderProgressNew = [
  {
    key: 1,
    prop: 'workOrderNumber',
    label: '工单号',
    width: 200
  },
  {
    key: 2,
    prop: 'projectNumber',
    label: '项目号',
    width: 200
  },
  {
    key: 3,
    prop: 'materialsName',
    label: '产品名称',
    width: 150
  },
  {
    key: 4,
    prop: 'materialsCode',
    label: '产品编号',
    width: 150
  },
  {
    key: 5,
    prop: 'specifications',
    label: '产品规格',
    width: 150
  },
  {
    key: 6,
    prop: 'quantity',
    label: '工单数量',
    width: 100
  },
  {
    key: 7,
    prop: 'slot:status',
    label: '工单状态',
    width: 120
  },
  {
    key: 8,
    prop: 'deliveryTime',
    label: '交期',
    width: 150
  },
  {
    key: 9,
    prop: 'slot:reportProgress',
    label: '工单进度',
    width: 150
  },
  {
    key: 10,
    prop: 'slot:progress',
    label: '工序进度'
  }
]

