export const columns = [
  {
    prop: 'createTime',
    label: '日期',
    sortable: false,
    hiddenSearch: true,
    slotName: 'createTime	'
  },
  {
    prop: 'organizationName',
    label: '部门',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '申请人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '内容说明',
    sortable: true,
    hiddenSearch: true,
    colSpan: 24
  },
  {
    prop: 'partList',
    label: '零件清单',
    sortable: true,
    hiddenSearch: true,
    slotName: 'partList',
    colSpan: 24
  },
  {
    prop: 'otherRemark',
    label: '其他特殊要求',
    sortable: true,
    hiddenSearch: true
  },
  {
    prop: 'locale',
    label: '样品完成后测试地点',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'assembles',
    label: '附件',
    sortable: false,
    hiddenSearch: true,
    colSpan: 24
  }
]

export const descColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'name',
    label: '名称',
    sortable: false,
    slotName: 'name',
    hiddenSearch: true,
    width: 250
  },
  {
    prop: 'parameter',
    label: '型号/参数/PN',
    sortable: false,
    hiddenSearch: true,
    slotName: 'parameter',
    width: 250
  },
  {
    prop: 'model',
    label: '适用SCR机型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'model',
    width: 250
  },
  {
    prop: 'quantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantity'
  },
  {
    prop: 'unitPrice',
    label: '原单价/元',
    sortable: false,
    hiddenSearch: true,
    slotName: 'unitPrice'
  },
  {
    prop: 'requestUnitPrice',
    label: '要求单价（含税）/元',
    sortable: false,
    hiddenSearch: true,
    slotName: 'requestUnitPrice'
  },
  {
    prop: 'delivery',
    label: '要求交货期/天',
    sortable: false,
    hiddenSearch: true,
    slotName: 'delivery'
  },
  {
    prop: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80,
    slotName: 'action'
  }
]
