var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-container" }, [
    _c(
      "div",
      {
        staticClass: "item-box count",
        style: { height: _vm.tableHeight + "px" }
      },
      [
        _c(
          "div",
          {
            staticClass: "count-box",
            style: {
              background: "url(" + require("./img/bg-1.png") + ") no-repeat"
            }
          },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("本月计划完工 | 工单数")
            ]),
            _c("div", { staticClass: "size-66" }, [
              _vm._v(
                _vm._s(_vm.weekCount.productionPlan.weekPlanFinishedCount)
              ),
              _c("span", { staticClass: "size-36" }, [
                _vm._v("/" + _vm._s(_vm.weekCount.productionPlan.weekPlanCount))
              ])
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "count-box",
            style: {
              background: "url(" + require("./img/bg-2.png") + ") no-repeat"
            }
          },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("本月计划完工 | 工序数")
            ]),
            _c("div", { staticClass: "size-66" }, [
              _vm._v(_vm._s(_vm.weekCount.procedure.weekPlanFinishedCount)),
              _c("span", { staticClass: "size-36" }, [
                _vm._v("/" + _vm._s(_vm.weekCount.procedure.weekPlanCount))
              ])
            ])
          ]
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "item-box", style: { height: _vm.tableHeight + "px" } },
      [
        _c(
          "div",
          {
            staticClass: "gradient-title",
            staticStyle: {
              background:
                "linear-gradient(90deg, #FF1010 0%, rgba(255,174,96,0) 100%)"
            }
          },
          [_vm._v("异常追踪 ")]
        ),
        _c("ve-pie", {
          attrs: {
            height: _vm.tableHeight + "px",
            settings: _vm.pieChartSettings,
            extend: _vm.pieChartExtend,
            data: _vm.exceptionPieConfig
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "item-box", style: { height: _vm.tableHeight + "px" } },
      [
        _c(
          "div",
          {
            staticClass: "gradient-title",
            staticStyle: {
              background:
                "linear-gradient(90deg, #1053FF 0%, rgba(96,127,255,0) 100%)"
            }
          },
          [
            _vm._v(" 生产工单"),
            _c("span", [_vm._v("(" + _vm._s(_vm.prodOrderData.length) + ")")])
          ]
        ),
        _c("scroll-board", {
          attrs: { config: _vm.prodListConfig },
          scopedSlots: _vm._u([
            {
              key: "status",
              fn: function(ref) {
                var index = ref.index
                return _c(
                  "div",
                  {
                    staticClass: "status-board",
                    style: {
                      background:
                        _vm.productionStatus[_vm.prodOrderData[index].status]
                          .color
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.productionStatus[_vm.prodOrderData[index].status]
                            .name
                        )
                    )
                  ]
                )
              }
            },
            {
              key: "productionTempo",
              fn: function(ref) {
                var index = ref.index
                return _c(
                  "div",
                  {},
                  [
                    _c("el-progress", {
                      staticStyle: { width: "120px" },
                      attrs: {
                        "stroke-width": 10,
                        percentage: _vm.prodOrderData[index].productionTempo
                      }
                    })
                  ],
                  1
                )
              }
            }
          ])
        })
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "item-box ",
        style: { height: _vm.tableHeight + "px", paddingTop: "46px" }
      },
      [
        _c("scroll-board", {
          staticStyle: { height: "calc(84% + 40px)" },
          attrs: { config: _vm.exceptionListConfig },
          scopedSlots: _vm._u([
            {
              key: "finalResult",
              fn: function(ref) {
                var index = ref.index
                return _c("div", {}, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.finalResultMap[
                          _vm.exceptionData[index].finalResult
                        ] || "-"
                      )
                  )
                ])
              }
            },
            {
              key: "status",
              fn: function(ref) {
                var index = ref.index
                return _c("div", {}, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.handleStatusMap[_vm.exceptionData[index].status] ||
                          "-"
                      )
                  )
                ])
              }
            }
          ])
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "item-box", style: { height: _vm.tableHeight + "px" } },
      [
        _c(
          "div",
          {
            staticClass: "gradient-title",
            staticStyle: {
              background:
                "linear-gradient(90deg, #00751F 0%, rgba(96, 127, 255, 0) 100%)"
            }
          },
          [
            _vm._v(" 过程检（待检）"),
            _c("span", [_vm._v("（" + _vm._s(_vm.waitCheckData.length) + "）")])
          ]
        ),
        _c("scroll-board", {
          attrs: { config: _vm.waitCheckConfig },
          scopedSlots: _vm._u([
            {
              key: "currentProcedure",
              fn: function(ref) {
                var index = ref.index
                return _c("span", {}, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.productTaskData[index].produceWorkOrderTaskList
                          ? _vm.productTaskData[index]
                              .produceWorkOrderTaskList[0].procedureName
                          : ""
                      ) +
                      " "
                  )
                ])
              }
            },
            {
              key: "priority",
              fn: function(ref) {
                var index = ref.index
                return _c("span", {}, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.priorityStatus[_vm.productTaskData[index].priority]
                          .name
                          ? _vm.priorityStatus[
                              _vm.productTaskData[index].priority
                            ].name
                          : "普通"
                      ) +
                      " "
                  )
                ])
              }
            }
          ])
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "item-box", style: { height: _vm.tableHeight + "px" } },
      [
        _c(
          "div",
          {
            staticClass: "gradient-title",
            staticStyle: {
              background:
                "linear-gradient(90deg, #00751F 0%, rgba(96, 127, 255, 0) 100%)"
            }
          },
          [
            _vm._v(" 过程检（七日内）"),
            _c("span", [_vm._v("（" + _vm._s(_vm.weekCheckData.length) + "）")])
          ]
        ),
        _c("scroll-board", {
          attrs: { config: _vm.weekCheckConfig },
          scopedSlots: _vm._u([
            {
              key: "result",
              fn: function(ref) {
                var index = ref.index
                return _c("span", {}, [
                  _vm._v(
                    " " +
                      _vm._s(
                        +_vm.weekCheckData[index].result === 0
                          ? "不合格"
                          : "合格"
                      ) +
                      " "
                  )
                ])
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }