<template>
  <div class="scrap-wrap">
    <!-- <el-radio-group v-model="tabParams" class="m-radio-group" @change="radioChange">
      <el-radio-button label="SpotCheck">待办任务</el-radio-button>
      <el-radio-button label="SpotCheckHistory">历史工单</el-radio-button>
    </el-radio-group> -->
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入工单编号/需求编号"
        :tags="!exportSelectionIds.length ? functionHeader.tags : []"
        :radio="params.radio"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :show-export="!exportSelectionIds.length ? true : false"
        :columns-key="columnsKey"
        :hidden-mul-search="true"
        @checkSearch="checkSearch"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all" v-show="exportSelectionIds.length">
          <el-button v-if="tabParams === 'SpotCheck'" type="primary" size="small" @click="allMore('batchSkipping')">批量跳过</el-button>
          <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
          <el-button size="small" type="primary"  @click="allMore('delBatchSpotCheck')">批量删除</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <div class="top">
        <SearchForm v-show="!isShow" ref="searchForm" :form-list-extend="getSearchFormList" :form-data="searchFormData" @search="searchTable" />
      </div>
      <MTable
        v-if="permission('List')"
        ref="mTable"
        :columns="getColumns"
        :height="height"
        :columns-key="columnsKey"
        :set-data-method="getTableData"
        :columns-setting="isShow"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <el-button v-if="isHistoryTab" slot="workOrderCode" slot-scope="{ row }" type="text" @click="toDetail(row, 'view')">
          {{ row.workOrderCode }}
        </el-button>
        <div v-else slot="workOrderCode" slot-scope="{ row }">{{ row.workOrderCode }}</div>

        <el-button v-if="row.demandCode && row.isDeleted === 0" slot="demandCode" slot-scope="{ row }" type="text" @click="toViewDetail(row)">
          {{ row.demandCode }}
        </el-button>
        <div v-else slot="demandCode" slot-scope="{ row }">{{ row.demandCode }}</div>

        <div v-if="row.isDeletePlan === 1" slot="facilitySpotCheckPlanCode" slot-scope="{ row }">{{ row.facilitySpotCheckPlanCode }}</div>
        <el-button v-else slot="facilitySpotCheckPlanCode" slot-scope="{ row }" type="text" @click="toFacilityDetail(row)">
          {{ row.facilitySpotCheckPlanCode }}
        </el-button>

        <div slot="source" slot-scope="{ row }">{{ getSourceDesc(row) || '-' }}</div>
        <div slot="consumer" slot-scope="{ row }">{{ row.consumer || '-' }}</div>
        <!-- <div slot="executiveOutcome" slot-scope="{ row }">{{ getExecutiveOutcomeDesc(row) }}</div> -->
        <div slot="planExecutionStartTime" slot-scope="{ row }">{{ getDayDesc(row.planExecutionStartTime, row.planExecutionEndTime) }}</div>
        <div slot="executionStartTime" slot-scope="{ row }">{{ getTimeDesc(row.executionStartTime, row.executionEndTime) }}</div>
        <div slot="createTime" slot-scope="{ row }">{{ getTimeDesc(row.createTime) }}</div>
        <div v-if="!isHistoryTab" slot="action" slot-scope="{ row }">
          <el-button v-if="permission('Print')" type="text">打印工单</el-button>
          <el-divider v-if="permission('Print')" direction="vertical" />
          <el-button v-if="permission('Execu')" :disabled="!row.executor.split('^').includes(userId)" type="text" @click="toDetail(row, 'add')">
            执行
          </el-button>
          <el-divider v-if="permission('Execu')" direction="vertical" />
          <el-button v-if="permission('Pass')" type="text" @click="skipping(row)">跳过</el-button>
        </div>

        <div v-else-if="permission('DelWorkOrder')" slot="action" slot-scope="{ row }">
          <template>
            <el-button v-if="permission('Modify') && fixUsers(row.updater, row.updatePermitUserId) && row.isUpdate === 1" :disabled="row.isDeletePlan === 1" type="text" @click="toModify(row, 'modify')">修改</el-button>
            <el-divider v-if="permission('Modify') && fixUsers(row.updater, row.updatePermitUserId) && row.isUpdate === 1" direction="vertical" />
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </div>
        <div v-else-if="row.isUpdate === 1" slot="action" slot-scope="{ row }">
          <el-button v-if="permission('Modify') && fixUsers(row.updater, row.updatePermitUserId)" :disabled="row.isDeletePlan === 1" type="text" @click="toModify(row, 'modify')">修改</el-button>
          <el-divider v-if="permission('Modify') && fixUsers(row.updater, row.updatePermitUserId)" direction="vertical" />
          <DelPopover v-if="permission('Del') && fixUsers(row.updater, row.updatePermitUserId)" @onOk="callback => del(row, callback)" />
          <div v-else slot="action">{{ '-' }}</div>
        </div>
        <div v-else slot="action">{{ '-' }}</div>
      </MTable>
    </div>

    <!-- 打印工单 -->
    <PrintOrder ref="printCodes" />
  </div>
</template>

<script>
import api from '@/api/xiewei/service/spot-check'
import keApi from '@/api/information/unit'
import dayjs from 'dayjs'
import userApi from '@/api/sets/user/user'
import DelPopover from '@/components/DelPopover/index'
import FunctionHeader from '@/components/FunctionHeader/index'
import { getUUid, setTreeData } from '@/utils'
import PrintOrder from '@/components/PrintCode/printPackage'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns } from './columns'
import { formList, searchFormList } from './form-list'
import batchExportFront from '@/mixin/batch-export-front'
import conventionMixin from '@/mixin/conventional-page'
import fixSearchNum from '@/mixin/fix-search-num'

export default {
  name: 'SpotCheck',
  components: { DelPopover, FunctionHeader, PrintOrder, SearchForm },
  mixins: [batchExportFront, conventionMixin, fixSearchNum],
  data() {
    return {
      api,
      params: {
        radio: 'SpotCheck'
      },
      tabParams: 'SpotCheckHistory',
      functionHeader: {
        searchVal: '',
        exportName: 'exportSpotCheckOrder',
        exportParams: {},
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ]
      },
      extraParams: [
        {
          id: getUUid(),
          fieldName: 'executiveOutcome',
          fieldType: 'number',
          fieldValue: '1^2^3',
          operator: 'in'
        }
      ],
      searchFormData: {},
      columns,
      formList,
      searchFormList,
      searchKeyword: true,
      allCustomer: [],
      userId: JSON.parse(sessionStorage.getItem('userInfo')).id.toString(),
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - (this.isShow ? -30 : 80)
    },
    isHistoryTab() {
      return this.tabParams === 'SpotCheckHistory'
    },
    // getExecutiveOutcomeDesc() {
    //   return row => {
    //     const { executiveOutcome } = row
    //     return {
    //       1: '全部正常',
    //       2: '存在异常',
    //       3: '已跳过'
    //     }[executiveOutcome]
    //   }
    // },
    getSourceDesc() {
      return row => {
        const { source } = row
        return {
          0: '用户新增',
          1: '点检计划'
        }[source]
      }
    },
    getSearchFormList() {
      if (!this.isShow) {
        if (this.isHistoryTab) {
          return this.searchFormList
        } else {
          return this.searchFormList.filter(item => {
            return item.key !== 'executiveOutcome' && item.key !== 'executionStartTime'
          })
        }
      } else {
        return []
      }
    },
    getColumns() {
      if (this.isHistoryTab) {
        return this.columns
      } else {
        return this.columns.filter(item => {
          return item.prop !== 'executiveOutcome' && item.prop !== 'executionStartTime'
        })
      }
    },
    getDayDesc() {
      return (start, end) => {
        const format = 'YYYY年MM月DD日'
        if (start && end) {
          const isSameYear = dayjs(start).isSame(end, 'year')
          if (isSameYear) {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format('MM月DD日')}`
          } else {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format(format)}`
          }
        } else if (start) {
          return `${dayjs(start).format(format)}`
        } else if (end) {
          return `${dayjs(end).format(format)}`
        }
        return '-'
      }
    },
    getTimeDesc() {
      return (start, end) => {
        const format = 'YYYY年MM月DD日 HH:mm'
        if (start && end) {
          const isSameDay = dayjs(start).isSame(end, 'day')
          const isSameYear = dayjs(start).isSame(end, 'year')
          if (isSameDay) {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format('HH:mm')}`
          } else if (isSameYear) {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format('MM月DD日 HH:mm')}`
          } else {
            return `${dayjs(start).format(format)} ~ ${dayjs(end).format(format)}`
          }
        } else if (start) {
          return `${dayjs(start).format(format)}`
        } else if (end) {
          return `${dayjs(end).format(format)}`
        }
        return '-'
      }
    },
    getTableDataParams() {
      const { form } = this.$refs.searchForm
      return {
        executorIds: (form.executor || []).join(',')
      }
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.exportSelectionList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  mounted() {
    this.getUserList()
    this.getAllCustomer()
  },

  methods: {
    resetSelectData() {
      this.exportSelectionList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
    },
    fixUsers(name, val) {
      if (val !== null && val !== undefined) {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        return +val === userInfo.id
      } else {
        return false
      }
    },
    // 获取客户和现场
    async getAllCustomer() {
      const res = await keApi.getAllCustomer()
      if (res) {
        const data = res.map(item => {
          return {
            ...item,
            name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
            id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
          }
        })
        this.allCustomer = data
        this.searchFormList[0].props.options = setTreeData(data)
      }
    },

    async getUserList() {
      const res = await userApi.getUserList()
      this.searchFormList[6].children[0].options = res
    },

    radioChange() {
      if (this.isHistoryTab) {
        this.extraParams[0].fieldValue = '1^2^3'
      } else {
        this.extraParams[0].fieldValue = '0'
      }
      this.exportSelectionIds = []
      this.$refs.mTable.tableRefs().clearSelection()
      this.$refs.searchForm.reset()
      this.$refs.mTable.setTableData(true)
    },

    searchTable(data) {
      const ids = data.map(item => item.fieldName)
      const hasIndex = ids.indexOf('customerName')
      this.searchFormatData = this._.cloneDeep(data)
      if (hasIndex !== -1) {
        this.searchFormatData.splice(hasIndex, 1)
        const id = data[hasIndex].fieldValue
        const arr = this.allCustomer.filter(item => item.id === this._.first(id))
        const list = this.allCustomer.filter(item => item.id === this._.last(id))
        this.searchFormatData = [
          ...this.searchFormatData,
          {
            id: getUUid(),
            fieldName: list[0].isCustomer === 1 ? 'customerSiteId' : 'consumerId',
            fieldType: 'number',
            fieldValue: list[0].isCustomer === 1 ? list[0].id : arr[0].id,
            operator: 'eq'
          }
        ]
      }
      this.$refs.mTable.setTableData(true)
    },

    allMore(command) {
      const functions = {
        batchSkipping: () => {
          this.batchSkipping()
        },
        delBatchSpotCheck: () => {
          this.delBatchSpotCheck()
        },
        batchExport: () => { 
          this.batchExport() 
        }
      }
      functions[command]()
    },

    async skipping(row) {
      await api.skippingSpotCheck({ id: row.id })
      this.$message.success('已跳过')
      this.exportSelectionIds = []
      this.$refs.mTable.tableRefs().clearSelection()
      this.$refs.mTable.setTableData()
    },

    async batchSkipping() {
      await api.batchSkippingSpotCheck(this.exportSelectionIds)
      this.$message.success('已跳过')
      this.exportSelectionIds = []
      this.$refs.mTable.tableRefs().clearSelection()
      this.$refs.mTable.setTableData()
    },

    toDetail(row, type) {
      this.$router.push({ name: 'spotCheckDetail', query: { id: row.id, type, source: row.source }})
    },

    // 查看需求详情
    toViewDetail(row) {
      this.$router.push({
        name: 'demandCenterDetail',
        query: { id: row.demandId }
      })
    },

    toModify(row, type) {
      if (row.source === 1) {
        this.$router.push({ name: 'spotCheckDetail', query: { id: row.id, type }})
      } else {
        this.$router.push({ name: 'addSpotCheck', query: { id: row.id, type }})
      }
    },

    toFacilityDetail(row) {
      this.$router.push({ name: 'facilityPointDetail', query: { id: row.facilitySpotCheckPlanId }})
    },

    delBatchSpotCheck(row) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delBatchSpotCheck(this.exportSelectionIds)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
        }
      })
    },

    del(row) {
      this.$confirm('删除后该工单不可恢复，是否确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delSpotCheck({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData(true)
        }
      })
    },

    modifyOrCopy(row) {
      this.$router.push({
        name: 'maintainDetail',
        query: { id: row.id, type: 'modify' }
      })
    },

    add() {
      this.$router.push({ name: 'addSpotCheck', query: { type: 'add' }})
    }
  }
}
</script>

  <style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;

  .search-form-wrap {
    background: #f0f2f5;
    margin-bottom: 10px;
    border: none;
  }

  .m-radio-group {
    margin-left: 10px;
  }

  .bottom {
    padding: 10px;
  }
}
</style>
