<template>
  <el-popover placement="bottom">
    <template slot="reference">
      <el-button type="text">
        <img src="@/assets/statistics-board/高级筛选@2x.png" width="14">
      </el-button>
    </template>
    <el-cascader
      ref="cascader"
      v-model="searchForm[tabInfo.key]"
      size="mini"
      collapse-tags
      filterable
      :placeholder="tabInfo.placeholder"
      :options="tabInfo.options || formOptions[tabInfo.dataKey]"
      v-bind="tabInfo.props"
      @change="() => { cascaderChange(false, tabInfo) }"
      @remove-tag="() => { cascaderChange(false, tabInfo) }"
    />
  </el-popover>
</template>

<script>

import api from '@/api/statistic-statement/index'
import { setTreeData } from '@/utils'

export default {
  name: 'DateRange',
  props: {
    tabInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      searchForm: {},
      formOptions: {},
      searchCondition: {}
    }
  },
  mounted() {
    this.setOptions(this.tabInfo)
  },
  methods: {
    async setOptions(condition) {
      const res = await api.getData(condition.dataUrl, condition.dataCondition)
      if (res) {
        this.$set(this.formOptions, condition.dataKey, setTreeData(res))
        this.$forceUpdate()
      }
    },
    cascaderChange(bol, search) {
      if (bol) return
      Object.assign(this.searchCondition, {
        [search.key]: search.props && search.props.props && search.props.props.multiple ? Array.from(new Set(this.searchForm[search.key].reduce((a, b) => a.concat([this._.last(b)]), []))).join('^') : this._.last(this.searchForm[search.key])
      })
      this.$emit('conditionChange', this.searchCondition)
    }
  }
}
</script>

<style scoped lang="scss">
.custom-date-picker {
  border: none;
  padding: 0;
  width: 150px;
  height: 20px;
  position: relative;
  left: 18px;
  ::v-deep {
    .el-input__icon {
      display: none;
    }
    .el-range-separator {
      width: 10%;
      font-size: 12px;
      line-height: 20px;
      padding: 0;
    }
    input {
      font-size: 12px;
    }
  }
}
</style>
