<template>
  <div class="orders">
    <!-- <el-radio-group
      v-model="params.radio"
      class="m-radio-group"
      @change="radioChange"
    >
      <el-radio-button v-if="permission('MaterialsList')" label="Materials"
        >物料</el-radio-button
      >
      <el-radio-button v-if="permission('PackageList')" label="Package"
        >包装</el-radio-button
      >
      <el-radio-button v-if="permission('TrayList')" label="Tray"
        >托盘</el-radio-button
      >
    </el-radio-group> -->
    <div class="top">
      <SearchForm
        ref="searchForms"
        :form-list-extend="searchFormList[params.radio]"
        class="searchs"
        @search="searchTable"
      >
        <SelectMultipleMaterialsNew
          slot="materialsM"
          ref="multipleMaterial"
          @selectChange="
            (key, val) => {
              handleSelect('materialsId', val && val.id);
            }
          "
        />
        <div slot="account">
          <el-autocomplete
            v-model="inOrOutType"
            popper-class="my-autocomplete"
            :fetch-suggestions="querySearch"
            :value-key="'name'"
            clearable
            placeholder="请输入内容"
            class="input-with-select"
            @clear="handleSelect('type', null)"
            @select="
              (val) => {
                handleSelect('type', val && val.id);
              }
            "
          >
            <el-select
              slot="prepend"
              v-model="type"
              clearable
              placeholder="选择类型"
              class="input-select"
              @change="
                (val) => {
                  handleSelect('recordType', val);
                }
              "
            >
              <el-option label="出库" :value="1" />
              <el-option label="入库" :value="0" />
            </el-select>
          </el-autocomplete>
        </div>
      </SearchForm>
    </div>
    <FunctionHeader
      ref="functionHeader"
      v-model="functionHeader.searchVal"
      :search-title="searchTitle"
      :tags="functionHeader.tags"
      :export-name="functionHeader.exportName"
      :export-params="functionHeader.exportParams"
      :show-export="!selectData.length ? true : false"
      style="margin-left:-10px;"
      @search="$refs.mTable.setTableData(true)"
    >
      <div slot="all" v-show="selectData.length">
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
      </div>
    </FunctionHeader>
    <MTable
      v-if="permission(params.radio + 'List')"
      ref="mTable"
      :columns="columns[params.radio]"
      :height="height"
      :set-data-method="getTableData"
      @selection-change="selectionChange"
    >
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="recordType" slot-scope="{ row }">{{ row.recordType ? '出库' : '入库' }}</div>
      <div slot="type" slot-scope="{ row }">{{ getType(row) }}</div>

      <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
    </MTable>
    <!-- 物料详情-->
    <edit-materials ref="editMaterials" />
  </div>
</template>
<script>
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'
import { Materials } from './form-list'
import {
  MaterialsWarehousingType,
  MaterialsIssueType
} from '@/config/options.config'
import api from '@/api/site-warehouse/sitWarehouseAccount'
import sitApi from '@/api/information/production/sit-warehouse'
import userApi from '@/api/sets/user/user'
import columns from './columns'
import { getUUid } from '@/utils/index'
import { Encrypt } from '@/utils/sercet'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import conventionMixin from '@/mixin/conventional-page'
import { export_json_to_excel2 } from '@/utils/plug/to-excel'

export default {
  name: 'SitWarehouseAccount',
  components: {
    EditMaterials,
    FunctionHeader,
    SearchForm,
    SelectMultipleMaterialsNew
  },
  mixins: [viewMaterialsDetail, conventionMixin],
  data() {
    return {
      api,
      columns,
      searchFormList: {
        Materials
      },
      functionHeader: {
        searchVal: '',
        exportName: 'exportSitWarehouseAccount',
        exportParams: {},
        tags: [
          // {
          //   hidden: !this.permission('BatchExport'),
          //   tagName: 'el-button',
          //   props: { type: 'primary', size: 'small' },
          //   style: { width: 'auto', background: '#607FFF' },
          //   innerText: '批量导出',
          //   on: {
          //     click: this.batchExport
          //   }
          // }
        ]
      },
      searchFormatData: [],
      params: {
        radio: 'Materials'
      },
      searchTitle: '批次码/出入库单号/源单据号',
      extraParams: [],
      searchKeyword: true,
      inOrOutTypeList: [MaterialsWarehousingType, MaterialsIssueType],
      inOrOutType: '',
      type: null,
      selectData: [],
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 62
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  mounted() {
    this.getUserList()
    this.getWareList()
  },
  methods: {
    allMore(command) {
      const functions = {
        batchExport: () => { this.batchExport() }
      }
      functions[command]()
    },
    // radioChange(label) {
    //   Object.assign(this.functionHeader, {
    //     exportName: 'exportSiteWarehouse'
    //   })
    //   this.searchFormatData = []
    //   this.selectKeyword = ''
    //   const titles = new Map([['Materials', '批次码/出入库单号/源单据号']])
    //   this.searchTitle = titles.get(this.params.radio)
    //   this.$refs.searchForms.reset()
    //   this.getWareList()
    // },
    // 获取制单人
    async getUserList() {
      const res = await userApi.getUserList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.searchFormList.Materials[3].children[0].options = res
      }
    },
    // 获取线边库
    async getWareList() {
      const res = await sitApi.getSiteWarehouseList()
      if (res) {
        this.searchFormList.Materials[0].children[0].options = res
      }
    },
    // 物料列表
    querySearch(queryString, cb) {
      const restaurants = this.inOrOutTypeList[this.type]
      const results = queryString
        ? restaurants.filter((item) => item.name.indexOf(queryString) !== -1)
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    handleSelect(key, val) {
      this.extraParams = this.extraParams.filter(
        (item) => item.fieldName !== key
      )
      if (val || val === 0) {
        this.extraParams.push({
          id: getUUid(),
          fieldName: key,
          fieldType: 'number',
          fieldValue: val,
          operator: 'eq'
        })
      }
      if (key === 'recordType') {
        this.inOrOutType = ''
        this.extraParams = this.extraParams.filter(item => item.fieldName !== 'type')
      }
      this.$refs.mTable.setTableData(true)
    },
    searchTable(data, type) {
      if (type === 1) {
        this.inOrOutType = null
        this.type = null
        this.$refs.multipleMaterial.keyword = ''
        this.$refs.multipleMaterial.sitWarehouseId = ''
        this.extraParams = []
      }
      if (data && data.length > 0) {
        this.searchFormatData = data.filter(item => item.fieldName !== 'keyword')
      } else {
        this.searchFormatData = data
      }

      this.$refs.mTable.setTableData(true)
    },
    getType(row) {
      const current = this.inOrOutTypeList[row.recordType].find(item => row.type === item.id)
      return current && current.name
    },
    selectionChange(data) {
      this.selectData = data
      this.exportSelectionIds = data.map(item => item.id)
    },
    resetSelectData() {
      this.selectData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectData = this.selectData.filter((item) => item.selectable)
    },
    // batchExport() {
    //   const exportData = this._.cloneDeep(this.selectData)
    //   exportData.forEach(item => {
    //     item.type = this.getType(item)
    //     item.recordType = item.recordType ? '出库' : '入库'
    //   })
    //   const params = {
    //     key: this.$refs.mTable.newColumns.filter(item => item.prop && item.prop !== 'index').map(item => item.prop),
    //     header: this.$refs.mTable.newColumns.filter(item => item.prop && item.prop !== 'index').map(item => item.label),
    //     merges: [],
    //     data: exportData,
    //     filename: '线边仓流水'
    //   }
    //   export_json_to_excel2(params)
    // }
  }
}
</script>

<style scoped lang="scss">
// ::v-deep .input-wrapper {
  // width: 336px;
  // display: inline-flex;
  // justify-content: space-between;
  // position: relative;
  // button {
  //   height: 32px;
  // }
  // .el-input {
  //   width: 226px;

  //   .el-input__inner {
  //     height: 32px;
  //   }
  // }
// }
.orders {
  padding: 10px;
  .li-btn {
    margin-right: 10px;
    color: #607fff;
  }
  .searchs {
    padding-left: 0;
    padding-top: 0;
  }
  .input-select {
    width: 120px;
    display: block;
  }
  .input-with-select {
    width: 100%;
  }
}
::v-deep {
  .customer-content div{
    width: 100%;
  }
  .customer-content2 div:first-child {
   display:block !important;
}
}
.input-with-select {
  width: 100%;

  .input-select {
    width: 105px !important;
  }
}
::v-deep {
  .el-input-group {
    width: 100%;

    .el-input-group__prepend {
      width: 105px;
    }
  }
}
</style>
