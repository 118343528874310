<template>
  <div class="procedure-detail-wrappers">
<!--    <div @click="showLog">1231321321231321321321231231231313213</div>-->
    <div class="procedure-detail-header flex-sbc">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px; cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
    </div>
    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot" v-show="allFlag">
          <el-form ref="formBase" :model="formBase" :rules="rulesBase" label-width="80px">
            <el-row>
              <el-col :span="6">
                <el-form-item label="对象" prop="eventObj">
                  <el-select :disabled="params.type === 'modify' || params.type === 'view'" v-model="formBase.eventObj"
                             placeholder="请选择事件" @change="eventChange">
                    <el-option v-for="item in eventObjects"
                               :label="item.name" :value="item.name"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="触发动作" prop="actionObj">
                  <el-select :disabled="!formBase.eventObj || params.type === 'modify' || params.type === 'view'"
                             v-model="formBase.actionObj" placeholder="请选择触发动作"
                             @change="actionChange">
                    <el-option v-for="item in eventObjectsOne"
                               :label="item.name" :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6"
                      v-if="(formBase.actionObj === 'update' || formBase.actionObj === '变更') && eventTypeObjects.length">
                <el-form-item label="监听类型" prop="typeObj">
                  <el-select :disabled="params.type === 'modify' || params.type === 'view'" v-model="formBase.typeObj"
                             placeholder="请选择监听类型" @change="typeChange">
                    <el-option v-for="item in eventTypeObjects"
                               :label="item.eventTypeName" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="formBase.actionObj === 'time_forewarn' || formBase.actionObj === '时间预警'">
                <el-form-item label="预警字段" prop="prewarnObj">
                  <el-select :disabled="params.type === 'view'" v-model="formBase.prewarnObj"
                             placeholder="请选择预警字段" @change="prewarnChange">
                    <el-option v-for="item in columnsInfoOne"
                               :label="item.name" :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6"
                      v-if="(formBase.actionObj === 'time_forewarn' || formBase.actionObj === '时间预警') && formBase.prewarnObj">
                <el-select :disabled="params.type === 'view'" v-model="formBase.preType" style="width: 30%">
                  <el-option label="之前" :value="1"></el-option>
                  <el-option label="之后" :value="2"></el-option>
                </el-select>
                <el-input-number :disabled="params.type === 'view'" style="width: 50%;margin-left: 15px"
                                 v-model="formBase.preTypeNum" :min="1" :max="30"
                                 label="几天？"></el-input-number>
                &nbsp;天
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">消息设置</div>
        <div class="card-bot" v-show="allFlag">
          <template>
            <div v-for="(item, index) in groupList" :key="index" class="groups">
              <div class="num-tit">
                <div class="num">#{{ index + 1 }}</div>
                <el-button v-if="params.type !== 'view'" type="text" icon="el-icon-delete" @click="delIcon(index)"/>
              </div>
              <el-form ref="formBase2" :model="item" :rules="rulesBase" label-width="100px">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="通知对象/动态" prop="itemObjDynamic">
                      <el-select :disabled="params.type === 'view'" multiple style="width: 90%"
                                 v-model="item.itemObjDynamic"
                                 placeholder="请选择通知对象/动态">
                        <el-option v-for="item in columnsInfoUser"
                                   :label="item.name" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="固定人员" prop="itemObjStatic">
                      <el-select :disabled="params.type === 'view'" multiple style="width: 90%"
                                 v-model="item.itemObjStatic"
                                 placeholder="请选择通知对象/固定">
                        <el-option v-for="item in usersList"
                                   :label="item.jobNumber + '-' + item.name" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="8">
                    <el-form-item label="固定部门" prop="itemOrg">
                      <el-select :disabled="params.type === 'view'" multiple style="width: 90%" v-model="item.itemOrg"
                                 placeholder="请选择固定部门">
                        <el-option v-for="item in organizationList"
                                   :label="item.name" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="固定角色" prop="itemRole">
                      <el-select :disabled="params.type === 'view'" multiple style="width: 90%" v-model="item.itemRole"
                                 placeholder="请选择固定角色">
                        <el-option v-for="item in roleList"
                                   :label="item.name" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="8">
                    <el-form-item label="通知渠道" prop="itemQudao">
                      <el-select :disabled="params.type === 'view'" multiple style="width: 90%" v-model="item.itemQudao"
                                 placeholder="请选择通知渠道">
                        <el-option v-for="item in qudaoMsg"
                                   :label="item.channelName" :value="item.notifyChannel"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="23">
                    <el-form-item label="主题模板" prop="themaTemplate">
                      <el-input :disabled="params.type === 'view'"
                                v-model="item.themaTemplate"
                                type="textarea"
                                :maxlength="100"
                                show-word-limit
                                :autosize="{ minRows: 1}"
                                placeholder="请输入内容"
                      />
                      <div class="tags" v-if="params.type !== 'view'">
                        <el-tag v-for="(tag, idx) in columnsInfo" :key="tag.id" class="tag"
                                @click="addTag2(tag, index)">
                          {{ tag.name }}
                        </el-tag>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="23">
                    <el-form-item label="消息模板" prop="messageTemplate">
                      <el-input :disabled="params.type === 'view'"
                                v-model="item.messageTemplate"
                                type="textarea"
                                :autosize="{ minRows: 3}"
                                placeholder="请输入内容"
                      />
                      <div class="tags" v-if="params.type !== 'view'">
                        <el-tag v-for="(tag, idx) in columnsInfo" :key="tag.id" class="tag"
                                @click="addTag(tag, index)">
                          {{ tag.name }}
                        </el-tag>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <el-button v-if="params.type !== 'view'" class="add-button flex-cc" @click="addGroup">
              <div class="flex-sbc">
                <img src="@/assets/information/procedure/新增.png" style="width: 22px">
                <span class="l10" style="color: #607FFF">添加</span>
              </div>
            </el-button>
          </template>
        </div>
      </div>
    </div>
    <div v-if="params.type !== 'view'" class="order-footer">
      <div class="footer-ri">
        <el-button size="small" @click="returns">取消</el-button>
        <el-button type="primary" :loading="buttonLoading" size="small" @click="submitForm">确定</el-button>
      </div>
    </div>
    <div v-else class="order-footer">
      <div class="footer-ri">
        <el-button size="small" @click="returns">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { formList, infoList } from './form-list'
import { columns, infoColumns, getFunctionList } from './columns'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import uApi from '@/api/sets/user/user'
import api from '@/api/sets/message'
import columnsSetApi from '@/api/columnsSetApi'
import { setTreeData } from '@/utils'
import { triggerActionType, notifyChannelType } from '@/config/options.sales'
import item from '@/layout/components/Sidebar/Item.vue'
import role from '@/api/sets/role/role'

export default {
  name: 'AddMessageNotificate',
  data() {
    return {
      allFlag:false,
      eventObjects: [],
      triggerActionObjects: [],
      eventTypeObjects: [],
      finalActionId: '',
      updateId: '',
      columnsInfo: [],
      qudaoMsg: [],
      organizationList: [],
      roleList: [],
      formBase: {
        preType: 1,
        preTypeNum: 1
      },
      rulesBase: {
        eventObj: [
          { required: true, message: '请选择事件', trigger: 'change' }
        ],
        actionObj: [
          { required: true, message: '请选择触发动作', trigger: 'change' }
        ],
        typeObj: [
          { required: true, message: '请选择监听类型', trigger: 'change' }
        ],
        prewarnObj: [
          { required: true, message: '请选择预警字段', trigger: 'change' }
        ],
        itemQudao: [
          { required: true, message: '请选择通知渠道', trigger: 'change' }
        ]
      },
      columns,
      notifyChannelType,
      triggerActionType,
      infoColumns,
      infoList,
      baseFormData: {},
      infoFormData: {},
      formList,
      params: {
        type: 'view'
      },
      messages: {
        add: '新增成功',
        modify: '修改成功',
        viewO: '消息通知详情',
        addO: '新增消息通知',
        modifyO: '修改消息通知'
      },
      buttonLoading: false,
      parametersList: [],
      usersList: [],
      groupList: [],
      allTags: [],
      tag1: ['需求编号', '服务内容', '客户', '当前负责人', '创建人', '创建时间'],
      tag2: ['仓库名', '物料编号名称', '当前库存']
    }
  },
  async created() {
    this.params = this.$route.query
    if(this.params.type === 'add'){
      this.allFlag = true
    }
    let initMsg = await this.getInitMsg() || []
    let qudaoMsg = await this.getQudaos() || []
    this.qudaoMsg = qudaoMsg
    let eventObjects = []
    let triggerActionObjects = []
    initMsg.forEach(item => {
      // 事件处理
      const len = eventObjects.filter(i => i.name === item.objectName && i.code === item.objectCode)
      if (!len.length) {
        eventObjects.push({
          name: item.objectName,
          code: item.objectCode
        })
      }

      // 触发动作处理
      const len2 = triggerActionObjects.filter(i => i.eventName === item.objectName && i.code === item.eventCode)
      if (len2.length) {
        triggerActionObjects.forEach(p => {
          if (p.eventName === item.objectName && p.code === item.eventCode) {
            if (item.eventCode === 'update') {
              p.eventTypes.push({
                id: item.id,
                eventType: item.eventType,
                eventTypeName: item.eventTypeName
              })
            }
          }
        })
      } else {
        let temObj = {
          id: item.id,
          eventName: item.objectName,
          eventCode: item.objectCode,
          name: item.eventName,
          code: item.eventCode,
          eventTypes: []
        }
        if (item.eventCode === 'update') {
          temObj.eventTypes.push({
            id: item.id,
            eventType: item.eventType,
            eventTypeName: item.eventTypeName
          })
        }
        triggerActionObjects.push(temObj)
      }
    })
    this.eventObjects = eventObjects
    this.triggerActionObjects = triggerActionObjects
    await this.getOrganizationList()
    await this.getRoleList()
    this.getSystemUser()
    if (this.params.type !== 'add') {
      await this.getMessageNotificationDetail()
    }
  },
  computed: {
    eventObjectsOne() {
      return this.triggerActionObjects.filter(item => item.eventName === this.formBase.eventObj)
    },
    columnsInfoOne() {
      return this.columnsInfo.filter(item => item.type === 4)
    },
    columnsInfoUser() {
      return this.columnsInfo.filter(item => item.type === 3 && item.typeFlag)
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  methods: {
    showLog() {
      consoleSelf.info('groupList', this.groupList)
      consoleSelf.info('事件信息', this.eventObjects)
      consoleSelf.info('触发动作信息', this.triggerActionObjects)
      consoleSelf.info('字段源数据信息', this.columnsInfo)
    },
    async eventChange() {
      this.$set(this, 'finalActionId', '')
      this.$set(this.formBase, 'actionObj', '')
      this.$set(this.formBase, 'typeObj', '')
      this.$set(this.formBase, 'prewarnObj', '')
      this.$set(this, 'groupList', [])
      this.eventTypeObjects = []
      const res = await this.getColumnsInfo()
      this.$set(this, 'columnsInfo', res)
      consoleSelf.info('事件对应的源数据字段信息', res)
    },
    actionChange() {
      this.$set(this.formBase, 'typeObj', '')
      this.$set(this.formBase, 'prewarnObj', '')
      if (this.formBase.actionObj === 'update') {
        this.$set(this, 'finalActionId', '')
        let eventTypes = this.eventObjectsOne.filter(item => item.eventName === this.formBase.eventObj && item.code === this.formBase.actionObj)
        this.eventTypeObjects = eventTypes[0].eventTypes
      } else {
        this.eventTypeObjects = []
        let one = this.eventObjectsOne.filter(item => item.eventName === this.formBase.eventObj && item.code === this.formBase.actionObj)
        this.finalActionId = one[0].id
        consoleSelf.info('最终选择的触发动作id', this.finalActionId)
      }
    },
    typeChange() {
      this.finalActionId = this.formBase.typeObj
      consoleSelf.info('最终选择的触发动作id', this.finalActionId)
    },
    prewarnChange() {
      this.$set(this.formBase, 'preType', 1)
      this.$set(this.formBase, 'preTypeNum', 1)
    },
    async getInitMsg() {
      return await api.getBaseInfoNew()
    },
    async getQudaos() {
      return await api.getQuDaosNew()
    },
    async getOrganizationList() {
      const res = await uApi.getOrganizationList()
      if (res) {
        this.organizationList = res
      }
    },
    async getRoleList() {
      const res = await role.getRoleList({
        searchVal: Encrypt(JSON.stringify([{
          id: getUUid(),
          fieldName: 'isEnabled',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }]))
      })
      if (res) {
        this.roleList = res
      }
    },
    async getColumnsInfo(code) {
      if (!code) {
        const event = this.eventObjects.filter(item => item.name === this.formBase.eventObj)
        return await columnsSetApi.getColumnsDetail({ code: event[0].code }) || []
      } else {
        return await columnsSetApi.getColumnsDetail({ code: code }) || []
      }
    },
    addTag(row, index) {
      const text = `{{${row.name}}}`
      this.groupList[index].messageTemplate = `${this.groupList[index].messageTemplate}${text}`
    },
    addTag2(row, index) {
      const text = `{{${row.name}}}`
      this.groupList[index].themaTemplate = `${this.groupList[index].themaTemplate}${text}`
    },
    delIcon(index) {
      this.groupList.splice(index, 1)
    },
    addGroup() {
      if (!this.finalActionId) {
        return this.$message.error('请先完善事件对象以及触发动作等信息')
      }
      this.groupList.push({ messageTemplate: '', themaTemplate: '' })
    },
    returns() {
      this.$router.push({ name: 'messageNotificateList' })
    },
    async getMessageNotificationDetail() {
      const res = await api.getMessageNotificationDetail({ id: this.$route.query.id })
      consoleSelf.info('getDetail详情信息', res)
      if (res) {
        const eventOne = res.triggerActionConfig
        this.$set(this.formBase, 'eventObj', eventOne.objectName)
        this.$set(this.formBase, 'actionObj', eventOne.eventName)
        const apl = await this.getColumnsInfo(eventOne.objectCode)
        this.$set(this, 'columnsInfo', apl)
        //时间预警
        if (res.timeForewarnFieldCode) {
          this.$set(this.formBase, 'prewarnObj', res.timeForewarnFieldCode)
          if (res.timeForewarnOffset > 0) {
            this.$set(this.formBase, 'preTypeNum', Math.abs(res.timeForewarnOffset))
            this.$set(this.formBase, 'preType', 2)
          } else {
            this.$set(this.formBase, 'preTypeNum', Math.abs(res.timeForewarnOffset))
            this.$set(this.formBase, 'preType', 1)
          }
        }
        // 变更
        if (eventOne.eventCode === 'update') {
          this.$set(this.formBase, 'typeObj', eventOne.eventTypeName)
          this.$set(this, 'eventTypeObjects', [{}])
        }
        //处理消息模板
        const groupList = res.userList.map(item => {
          return {
            itemObjDynamic: item.dynamicNotifyUser ? item.dynamicNotifyUser.split('^') : [],
            itemObjStatic: item.staticNotifyUserIds ? item.staticNotifyUserIds.split('^') : [],
            itemOrg: item.staticNotifyOrganizationIds ? item.staticNotifyOrganizationIds.split('^') : [],
            itemQudao: item.notifyChannel ? item.notifyChannel.split('^') : [],
            itemRole: item.staticNotifyRoleIds ? item.staticNotifyRoleIds.split('^') : [],
            themaTemplate: item.titleTemplateView,
            messageTemplate: item.messageTemplateView
          }
        })
        groupList.forEach(k => {
          k.itemObjDynamic = k.itemObjDynamic.map(item => {
            const ony = this.columnsInfo.filter(m => m.code === item)
            return ony[0].id
          })
          k.itemObjStatic = k.itemObjStatic.map(item => {
            return +item
          })
          k.itemOrg = k.itemOrg.map(item => {
            return +item
          })
          k.itemQudao = k.itemQudao.map(item => {
            return +item
          })
          k.itemRole = k.itemRole.map(item => {
            return +item
          })
        })
        this.finalActionId = res.triggerActionId
        this.updateId = res.id
        this.groupList = groupList
        this.allFlag = true
      }
    },
    // 获取系统用户
    async getSystemUser() {
      const res = await uApi.getUserList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            },
            {
              id: getUUid(),
              fieldName: 'isSystemUser',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.usersList = res
      }
    },
    // 获取消息列表
    async getList() {
      const res = await api.getMessageNotificationList()
      if (res) {
        const list = res.length > 0 ? this.triggerActionType.filter(items => !res.map(item => item.triggerAction).includes(items.id)) : this.triggerActionType
        this.formList[0].children[0].options = list
      }
    },
    async submitForm() {
      const that = this
      this.buttonLoading = true
      this.$refs.formBase.validate(async valid => {
        if (valid) {
          if (this.groupList.length === 0) {
            return this.$message.error('消息设置至少添加一项')
          }
          const finalMsg = this.handleAllMsg()
          if (finalMsg) {
            await that.saveData(finalMsg)
          }
          this.buttonLoading = false
        } else {
          this.buttonLoading = false
        }
      })
    },
    handleAllMsg() {
      for (let i = 0; i < this.groupList.length; i++) {
        const item = this.groupList[i]
        if (item.itemQudao && !item.itemQudao.length) {
          this.$message.error('请选择通知渠道')
          return false
        }
        if (!item.themaTemplate) {
          this.$message.error('请设置主题模板')
          return false
        }
        if (!item.messageTemplate) {
          this.$message.error('请设置消息模板')
          return false
        }
        if (item.itemObjDynamic.length === 0 && item.itemObjStatic.length === 0 && item.itemOrg.length === 0 && item.itemRole.length === 0) {
          this.$message.error('请设置通知对象')
          return false
        }
      }

      const obj = {}
      obj.triggerActionId = this.finalActionId
      if (this.params.type === 'modify') {
        obj.id = this.updateId
      }
      if (this.formBase.actionObj === 'time_forewarn' || this.formBase.actionObj === '时间预警') {
        obj.timeForewarnFieldCode = this.formBase.prewarnObj
        let num = 0
        if (this.formBase.preType === 1) {
          num = this.formBase.preTypeNum * -1
        } else {
          num = this.formBase.preTypeNum
        }
        obj.timeForewarnOffset = num
      }
      obj.userList = []
      this.groupList.forEach(item => {
        let temObj = {}
        //通知对象/动态
        {
          let arr = []
          arr = this.columnsInfoUser.filter(p => item.itemObjDynamic.includes(p.id))
          arr = arr.map(p => p.code)
          temObj.dynamicNotifyUser = arr.join('^')
        }
        //固定人员
        temObj.staticNotifyUserIds = item.itemObjStatic.join('^')
        //固定部门
        temObj.staticNotifyOrganizationIds = item.itemOrg.join('^')
        //固定角色
        temObj.staticNotifyRoleIds = item.itemRole.join('^')
        //通知渠道
        temObj.notifyChannel = item.itemQudao.join('^')
        //主题模板
        temObj.titleTemplate = this.handleTemplate(item.themaTemplate)
        temObj.titleTemplateView = item.themaTemplate
        //消息模板
        temObj.messageTemplate = this.handleTemplate(item.messageTemplate)
        temObj.messageTemplateView = item.messageTemplate

        obj.userList.push(temObj)
      })
      consoleSelf.info('最终的数据', obj)
      return obj
    },
    handleTemplate(temp) {
      const that = this
      return temp.replace(/{{.*?}}/g, function(str) {
        str = str.replace(/{{/g, '')
          .replace(/}}/g, '')
        const op = that.columnsInfo.filter(item => item.name === str)
        if (op.length) {
          if ((op[0].type === 3 || op[0].type === 5) && op[0].typeFlag) {
            return `{{${op[0].typeFlag}:${op[0].code}}}`
          } else {
            return `{{${op[0].code}}}`
          }
        } else {
          return `{{${str}}}`
        }
      })
    },
    async saveData(obj) {
      const res = this.params.type === 'add' ? await api.addMessageNotificationNew(obj) : await api.updateMessageNotificationNew(obj)
      if (res) {
        this.$message.success('操作成功')
        this.$router.push({ name: 'messageNotificateList' })
      }
    },
    createSetData(raw) {
      return {
        triggerAction: raw.triggerAction,
        triggerActionStr: this.triggerActionType.filter(item => item.id === +raw.triggerAction)[0].name,
        isPeriodicReminder: raw.isPeriodicReminder,
        reminderCycle: raw.reminderCycle
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && row.form.options.length > 0) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    getValues(row, item) {
      let result = item[row.prop]
      const list = [
        {
          id: 0,
          name: '当前负责人'
        },
        {
          id: 1,
          name: '创建人'
        }
      ]
      if (row.prop === 'dynamicNotifyUser') {
        result = item[row.prop] ? list.filter(items => item[row.prop].includes(items.id)).map(items => items.name).join('、') : ''
      }
      if (row.prop === 'staticNotifyUserIds') {
        result = item[row.prop] ? this.usersList.filter(items => item[row.prop].includes(items.id)).map(items => items.name).join('、') : ''
      }
      if (row.prop === 'notifyChannel') {
        result = this.notifyChannelType.filter(items => item[row.prop].includes(+items.id)).map(items => items.name).join('、')
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-select-dropdown.el-popper.is-multiple.multi-cascader-style .li-style .li-label-style {
    color: #607fff;
  }

  [v-clock] {
    display: none;
  }

  .el-select-dropdown.el-popper.is-multiple.multi-cascader-style .li-style.active-li {
    background-color: rgba(96, 127, 255, 0.1);
    color: #607fff;
  }
}

.tags {
  margin-top: 10px;
  cursor: pointer;

  .tag {
    margin-right: 10px;
  }
}

.groups {
  border-bottom: 1px dashed #eee;
  margin-bottom: 10px;

  .num-tit {
    display: flex;
    justify-content: space-between;
  }

  .num {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9597ae;
  }
}

.add-button {
  background: rgba(96, 127, 255, 0.1);
  width: 100%;
  border: 1px solid rgba(96, 127, 255, 1);
}

.lines {
  width: 100%;
  height: 20px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 20px;
}

.procedure-detail-wrappers {
  height: 100%;
  width: 100%;

  ::v-deep {
    .el-form-item__error {
      padding-top: 2px;
    }

    .el-form-item {
      margin-bottom: 15px !important;
    }
  }

  .card-bot {
    ::v-deep.el-row {
      display: flex;
      flex-wrap: wrap;
    }

    .view-list {
      padding: 0 15px;
      color: #393d60;
      font-size: 14px;

      &__label {
        margin-bottom: 4px;
        color: #9597ae;
      }

      .el-col {
        margin: 10px 0;
      }
    }

    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;

      .required-star {
        width: 10px;
        color: #f00;
      }

      .range {
        margin: 0 10px;
      }
    }
  }

  .procedure-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .procedure-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;

      .e-btn {
        margin-bottom: 10px;
      }
    }
  }

  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: calc(100% - 22px);
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;

    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;

      span {
        margin-right: 45px;
        display: inline-block;
      }
    }

    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.technical-table-input {
  width: 40%;
}
</style>
