<template>
  <div class="unit-wrap">
    <div class="top">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="请输入触发动作"
        :tags="functionHeader.tags"
        :columns-key="columnsKey"
        :hidden-search="true"
        :columns-setting="false"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        ref="mTable"
        :columns="columns"
        :height="height"
        :set-data-method="getTableData"
        :columns-setting="false"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <el-button slot="triggerAction" slot-scope="{ row }" type="text" @click="viewTechnical(row)">{{ row.triggerActionStr }}</el-button>
        <div slot="action" slot-scope="{ row }">
          <template>
            <el-button type="text" @click="modifyTechnical(row)">修改</el-button>
            <el-divider direction="vertical" />
            <DelPopover @onOk="callback => del(row, callback)" />
          </template>
        </div>
      </MTable>
    </div>
  </div>
</template>

<script>
import DelPopover from '@/components/DelPopover/index'
import FunctionHeader from '@/components/FunctionHeader/index'
import { columns } from './columns'
import { formList } from './form-list'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/sets/message'
import { triggerActionType } from '@/config/options.sales'

export default {
  name: 'MessageNotificate',
  components: { DelPopover, FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      triggerActionType,
      params: {
        radio: 'MessageNotification'
      },
      selectionData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.addTechnical
            }
          }
        ]
      },
      visible: false,
      columns,
      formList,
      formData: {}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 + 40
    }
  },
  methods: {
    searchTable() {},
    addTechnical() {
      this.$router.push({ name: 'addMessageNotificate', query: { type: 'add' }})
    },
    modifyTechnical(row) {
      this.$router.push({ name: 'addMessageNotificate', query: { id: row.id, type: 'modify' }})
    },
    viewTechnical(row) {
      this.$router.push({ name: 'addMessageNotificate', query: { id: row.id, type: 'view' }})
    }
  }
}
</script>

<style scoped lang="scss">
.unit-wrap {
  padding: 0 10px 10px 10px;
}
</style>
