var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "switch-shift-wrapper" },
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
        [_vm._v("选择设备")]
      ),
      _c("MTable", {
        ref: "mUnitTable",
        staticStyle: { "margin-top": "20px" },
        attrs: {
          height: 320,
          "show-page": false,
          data: _vm.list,
          columns: _vm.columnsPlan,
          "columns-setting": false
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var row = ref.row
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              var $index = ref.$index
              return _c(
                "div",
                {},
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.delEquipment(row.id)
                        }
                      }
                    },
                    [_vm._v(" 删除 ")]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("select-setting", {
        ref: "selectSetting",
        attrs: {
          visible: _vm.visible,
          "select-materials": _vm.selectEquipment
        },
        on: {
          "update:visible": function($event) {
            _vm.visible = $event
          },
          submitForm: _vm.submitSetting
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }