var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container", attrs: { id: "qwer" } }, [
    _c("iframe", {
      staticClass: "trend-container2",
      style: { width: _vm.width, marginLeft: _vm.marginLeft },
      attrs: {
        src: "http://dev.mubyte.cn:8100/#/de-link/3AU95Q0U",
        scrolling: "auto",
        frameborder: "0",
        id: "iframe"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }