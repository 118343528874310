var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "monitor-container" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "left" }, [
        _vm._m(0),
        _c("div", { staticClass: "t-bottom carder-border" }, [
          _c("div", { staticClass: "t-title" }, [_vm._v("陌生人检测")]),
          _c("div", { staticClass: "t-content" }, [
            _c("div", [
              _c("img", {
                attrs: { src: "https://oss.mubyte.cn/fsd/face/1.png", alt: "" }
              }),
              _c("div", { staticClass: "card-top" }, [
                _vm._v(
                  _vm._s(_vm.specMonth) +
                    "月" +
                    _vm._s(_vm.specDate) +
                    "日 9:59:53"
                )
              ])
            ]),
            _c("div", [
              _c("img", {
                attrs: { src: "https://oss.mubyte.cn/fsd/face/2.png", alt: "" }
              }),
              _c("div", { staticClass: "card-top" }, [
                _vm._v(
                  _vm._s(_vm.specMonth) +
                    "月" +
                    _vm._s(_vm.specDate) +
                    "日 10:06:27"
                )
              ])
            ]),
            _c("div", [
              _c("img", {
                attrs: { src: "https://oss.mubyte.cn/fsd/face/3.png", alt: "" }
              }),
              _c("div", { staticClass: "card-top" }, [
                _vm._v(
                  _vm._s(_vm.specMonth) +
                    "月" +
                    _vm._s(_vm.specDate) +
                    "日 10:23:05"
                )
              ])
            ]),
            _c("div", [
              _c("img", {
                attrs: { src: "https://oss.mubyte.cn/fsd/face/4.png", alt: "" }
              }),
              _c("div", { staticClass: "card-top" }, [
                _vm._v(
                  _vm._s(_vm.specMonth) +
                    "月" +
                    _vm._s(_vm.specDate) +
                    "日 11:37:07"
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._m(1),
      _c("div", { staticClass: "right" }, [
        _vm._m(2),
        _c("div", { staticClass: "t-bottom carder-border" }, [
          _c("div", { staticClass: "gradient-title" }, [_vm._v("违规")]),
          _c(
            "div",
            { staticClass: "gradient-chart" },
            [
              _c("ve-histogram", {
                attrs: {
                  height: "470px",
                  data: _vm.chartData,
                  settings: _vm.barChartSettings,
                  extend: _vm.barChartExtend
                }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t-top carder-border" }, [
      _c("div", { staticClass: "t-title" }, [_vm._v("今日告警")]),
      _c("div", { staticClass: "t-content" }, [
        _c("div", [
          _c("div", { staticClass: "card-top" }, [_vm._v("25")]),
          _c("div", { staticClass: "card-bottom" }, [
            _c("span", [_vm._v("今日告警总数")])
          ])
        ]),
        _c("div", [
          _c("div", { staticClass: "card-top" }, [_vm._v("10")]),
          _c("div", { staticClass: "card-bottom" }, [
            _c("span", [_vm._v("已处理")])
          ])
        ]),
        _c("div", [
          _c("div", { staticClass: "card-top" }, [_vm._v("15")]),
          _c("div", { staticClass: "card-bottom" }, [
            _c("span", [_vm._v("未处理")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "center carder-border" }, [
      _c("div", { staticClass: "t-top" }, [
        _c("div", { staticClass: "t-content" }, [
          _c("div", { staticClass: "t-card" }, [
            _c("img", {
              attrs: {
                src: require("../../../../assets/img/icon-video.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "card-top" }, [_vm._v("10")]),
            _c("div", { staticClass: "card-bottom" }, [
              _c("span", [_vm._v("摄像机总数")])
            ])
          ]),
          _c("div", { staticClass: "t-card" }, [
            _c("img", {
              attrs: {
                src: require("../../../../assets/img/icon-peoples.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "card-top" }, [_vm._v("171")]),
            _c("div", { staticClass: "card-bottom" }, [
              _c("span", [_vm._v("今日入场人数")])
            ])
          ]),
          _c("div", { staticClass: "t-card" }, [
            _c("img", {
              attrs: {
                src: require("../../../../assets/img/icon-people.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "card-top" }, [_vm._v("63")]),
            _c("div", { staticClass: "card-bottom" }, [
              _c("span", [_vm._v("厂内实时人数")])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "t-bottom" }, [
        _c("div", {
          attrs: { id: "ws-real-player", width: "100%", height: "700" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t-top carder-border" }, [
      _c("div", { staticClass: "gradient-title" }, [_vm._v("人员行为分析")]),
      _c("div", { staticClass: "gradient-box" }, [
        _c("img", {
          attrs: { src: require("../../../../assets/img/icon-jk.png"), alt: "" }
        }),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "title-blue" }, [_vm._v("监控")]),
          _c("div", { staticClass: "desc" }, [_vm._v("运行算法摄像头")])
        ]),
        _c("div", { staticClass: "num-blue" }, [_vm._v("2")]),
        _c("div", { staticClass: "line-blue" })
      ]),
      _c("div", { staticClass: "gradient-box" }, [
        _c("img", {
          attrs: {
            src: require("../../../../assets/img/icon-error.png"),
            alt: ""
          }
        }),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "title-yellow" }, [_vm._v("违规")]),
          _c("div", { staticClass: "desc" }, [_vm._v("违规抓拍")])
        ]),
        _c("div", { staticClass: "num-yellow" }, [_vm._v("17")]),
        _c("div", { staticClass: "line-yellow" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }