var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unit-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _vm.permission("OutInWarehouseType")
            ? _c(
                "el-radio-button",
                { attrs: { label: "OutInWarehouseType" } },
                [_vm._v("出入库类型")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("FunctionHeader", {
        attrs: {
          tags: _vm.functionHeader.tags,
          radio: _vm.params.radio,
          "columns-key": _vm.columnsKey,
          "hidden-search": true
        },
        on: {
          search: function($event) {
            return _vm.$refs.mTable.setTableData(true)
          }
        },
        model: {
          value: _vm.functionHeader.searchVal,
          callback: function($$v) {
            _vm.$set(_vm.functionHeader, "searchVal", $$v)
          },
          expression: "functionHeader.searchVal"
        }
      }),
      _vm.permission(_vm.params.radio + "List")
        ? _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.columnsAll,
              height: _vm.height,
              "columns-key": _vm.columnsKey,
              "set-data-method": _vm.getTableData,
              "columns-setting": false
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "index",
                  fn: function(ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "type",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _c("div", [
                        _vm._v(_vm._s(+row.type === 0 ? "入库" : "出库"))
                      ])
                    ])
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _vm.permission(_vm.params.radio + "Modify") &&
                        row.isFixed == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.modifyOrCopy(row, "modify")
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            )
                          : _vm._e(),
                        _vm.permission(_vm.params.radio + "Modify") &&
                        row.isFixed == 0
                          ? _c("el-divider", {
                              attrs: { direction: "vertical" }
                            })
                          : _vm._e(),
                        _vm.permission(_vm.params.radio + "Del") &&
                        row.isFixed == 0
                          ? _c("DelPopover", {
                              on: {
                                onOk: function(callback) {
                                  return _vm.del(row, callback)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }
                }
              ],
              null,
              false,
              524394750
            )
          })
        : _vm._e(),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: {
              "form-data": _vm.formData,
              "form-list": _vm.formList["OutInWarehouseType"]
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }