import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'

const formList = transformColumnsToForm(columns)

formList.push({
  tagName: 'div',
  colSpan: 24,
  children: [{
    customSlots: ['auth']
  }]
})

export default formList
